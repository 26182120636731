/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import AppProvider from "../../../api/appProvider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { useVisualizationState } from "../../../reducers/visualizations.reducer";
import { createCanvas, downloadImageData } from "../../../utils/canvasutils";
import { convertNumberToFeetInch, readJSON } from "../../../utils/utils";
import { readImage} from "../../../utils/domUtils";
import { getMaterialName, getPileheightText } from "../../molecules/AreaSwatch";
import RoomViewHelper from "../../organisms/RoomViewNew/roomviewhelper";
let rh = new RoomViewHelper();

const CatalogView = () => {
  const canvasRef = useRef();
  const [catData, setCatData] = useState({});
  const visualizationState = useVisualizationState();
  const { activeNode } = visualizationState;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const {
    designDetails,
    fullpath: designPath,
    hash,
    designDimsOrig,
    designName
  } = designDetailState;
   useEffect(() => {
    let testData = false;

    if (!activeNode && activeNode.id === "0") return;
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
    let config, baseUrl, roomData;
    const loadRoom = async () => {
      if (testData && process.env.NODE_ENV === "development") {
        roomData = {
          Dir: "temp/Test",
          Name: "Test",
          Files: ["/Shot_1.bg.jpg", "/Shot_1.m.png", "/Shot_1.sh.jpg"]
        };
        baseUrl = roomData.Dir;
        config = await readJSON(`${baseUrl}/config.json`);
      } else {
        try {
          await AppProvider.getRoomThumbnails({ rooms: [activeNode] });
          roomData = await AppProvider.fetchRoomDetails({ file: activeNode.FullPath });
          if (!roomData || !roomData.Dir) return;
        } catch (error) {
          return;
        }
        baseUrl = roomData.baseUrl;
        config = await readJSON(`${baseUrl}/config.json`);
      }
      setCatData(roomData);
      const { width, height } = config.dims;
      const bgCanvas = createCanvas(width, height);
      const threeCanvas = createCanvas(width, height);
      const maskCanvas = createCanvas(width, height);
      const shadowCanvas = createCanvas(width, height);
      const container = { clientWidth: width, clientHeight: height };
      const inputCanvas = createCanvas(width, height);
      const transitionCanvas = createCanvas(width, height);
      const canvasConfig = {
        bgCanvas,
        threeCanvas,
        maskCanvas,
        shadowCanvas,
        container,
        inputCanvas,
        transitionCanvas
      };
      rh.initCanvas(canvasConfig);
      await Promise.all(rh.initConfig({ baseUrl, config, files: roomData.Files }));
      rh.updateBackground();

      rh.updateMask();
      await rh.updatethreeCanvas();
      rh.setTileDetails(designDetailState.tileDetails);
      await rh.renderDesign({ designDetails, designPath, hash, designDimsOrig });
      await rh.updateShadow();
      const roomRenderCanvas = rh.renderinCanvas();
      const { catalogDims } = config.catalogProps;
      canvasRef.current.width = catalogDims.width;
      canvasRef.current.height = catalogDims.height;
      const ct = canvasRef.current.getContext("2d");
      ct.fillStyle = "#fff";
      ct.fillRect(0, 0, catalogDims.width, catalogDims.height);
      ct.drawImage(roomRenderCanvas, 898, 349, 1920, 1617);
      drawColorPatches();
      // rh.downloadRendering(downloadName, mime);
      writeDesignProps();
      await drawLogo();

      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
      return Promise.resolve();
    };
    loadRoom();
  }, [designDetails]);
  const getDesignDims = () => {
    const { PhysicalHeight, PhysicalWidth, Unit } = designDetails;
    const widthString = convertNumberToFeetInch(PhysicalWidth, Unit);
    const heightString = convertNumberToFeetInch(PhysicalHeight, Unit);
    const combinedStr = `${widthString} x ${heightString}`;
    return `${combinedStr} ${Unit === "ft" ? "" : Unit}`;
  };
  const writeDesignProps = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.font = "66px IBM Plex Sans";
    ctx.fillStyle = "#30363B";
    ctx.textBaseline = "top";
    ctx.fillText(designName, 897, 149);
    ctx.font = "35px IBM Plex Sans";
    const designDims = getDesignDims();
    ctx.fillText(`${designDims}`, 897, 247);
  };
  const drawLogo = async () => {
    const ctx = canvasRef.current.getContext("2d");
    const logourl = `${AppProvider.domain}${window.InterfaceElements.LogoUrl}`;
    const logoImage = await readImage(logourl);
    if(logoImage.width>601)
    ctx.drawImage(logoImage, 186, 133, 601, 150);
    else{
      const x = Math.round(186 + (601 - logoImage.width)/2);
      const y = Math.round(133 + (150 - logoImage.height)/2);
      
      ctx.drawImage(logoImage, x, y, logoImage.width, logoImage.height);
  
    }
  };
  const drawColorPatches = () => {
    const startX = 145;
    const startY = 349;
    const numCols = 3;
    const ct = canvasRef.current.getContext("2d");
    designDetails.DesignColors.forEach((designColor, i) => {
      const colorPatch = getColorPatch(designColor);
      const row = Math.floor(i / numCols);
      const col = i % numCols;
      const sx = startX + (218 + 15) * col;
      const sy = startY + (218 + 29) * row;
      console.log(sx, sy, row, col, i);
      ct.drawImage(colorPatch, sx, sy, 218, 218);
    });
  };
  const getColorPatch = designColor => {
    const { YarnDetails, Material, PileHeight } = designColor;
    const { Color, ColorName } = YarnDetails[0];
    const materialName = getMaterialName(Material);
    const pileHeightText = getPileheightText(PileHeight);
    const canvas = createCanvas(218, 218);
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = Color;
    ctx.fillRect(51, 0, 116, 116);
    ctx.font = "26px IBM Plex Sans";
    ctx.textAlign = "center";
    ctx.fillStyle = "#30363B";
    ctx.fillText(ColorName, 109, 145);
    ctx.font = "22px IBM Plex Sans";
    ctx.fillText(materialName, 109, 175);
    ctx.fillText(pileHeightText, 109, 204);
    return canvas;
  };
  window.downloadRenderedCatalog = () => {
    const mime = "jpg";
    const downloadName = `${designName} in ${catData.Name}.${mime}`;
    downloadImageData(canvasRef.current, downloadName, mime);
    return Promise.resolve();
  };
  return (
    <div className="illustration-container">
      <canvas className="catalog-canvas" ref={canvasRef} />
    </div>
  );
};

export default CatalogView;
