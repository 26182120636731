import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { useMount } from "react-use";

import LoginPage from "./components/pages/Login";
import AppProvider from "./api/appProvider";
import "./style.scss";
import EntryPage from "./components/pages/EntryPage";
import ErrorPage from "./components/pages/ErrorPage";
import { errorTypes } from "./utils/utils";
import InitialLogoPage from "./components/pages/InitialLogoPage";
// const version = process.env.REACT_APP_VERSION;

ReactGA.initialize("UA-165307296-1");
window.onerror = (msg, src, lineno, colno, error) => {
  console.log(error);
  var category = "JavaScript error";
  const page = sessionStorage.getItem("page");
  ReactGA.event({
    category: category,
    action: msg,
    label: page + ":" + src + ":" + lineno + ":" + colno
  });
  return false;
};
const App = props => {
  const [page, setPage] = useState({ page: null, error: null });
  const afterLogin = () => {
    setPage({ page: "entry" });
    const page = sessionStorage.getItem("page");
    ReactGA.set({ dimension3: page });

    ReactGA.ga("send", "event", {
      eventCategory: "Login", //required
      eventAction: "Logged in", //required
      eventLabel: `logged in user ${page}`
    });
    // AppAnalytics.sendLoginDetails({username: page}).then(status=>{
    //   //console.log("AppAnalytics.sendLoginDetails ~ status", status)
    // }).catch(error=>{
    //   console.log(" AppAnalytics Login ~ error", error)
    // });
  };
  useMount(() => {
    const loginKey = sessionStorage.getItem("apikey") || "";
    if (loginKey !== "") {
      afterLogin();
    } else {
      AppProvider.autoLogin()
        .then(key => {
          afterLogin();
        })
        .catch(error => {
          if (error === "NO_LOGIN_PAGE" || error === "INVALID_CREDENTIALS") {
            setPage({
              page: "login"
            });
          } else {
            let err;
            if (error.response)
              err =
                error.response.status === 404
                  ? errorTypes.NO_CONNECTION
                  : errorTypes.INTERFACE_ERROR;
            if (error.message === "Network Error") err = errorTypes.NO_CONNECTION;
            setPage({ page: "error", error: err });
          }
        });
    }
  });
  if (page.page === "entry") return <EntryPage />;
  if (page.page === "login") return <LoginPage />;
  if (page.page === "error") return <ErrorPage errorType={page.error} />;
  return (
    <>
      {/* <InitialLogoPage /> */}
    </>
  );
};
const root = document.getElementById("app-root");
document.addEventListener("contextmenu", event => event.preventDefault());
ReactDOM.render(<App page="entry" />, root);
