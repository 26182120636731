/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { AtSlider } from "../../molecules/Slider";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { pageViews, useUiState } from "../../../reducers/mainui.reducer";
import { usePrevious } from "../../../hooks";
import AppProvider from "../../../api/appProvider";
import { createCanvas, cropStitchCanvas, downloadImageData } from "../../../utils/canvasutils";
import { convertUnit, getCroppedSize } from "../../../utils/utils";
import { getZoomParameters, resolveZoomValue } from "../../../middleware/visualization.middleware";
import classNames from "classnames";
import { readImage } from "../../../utils/domUtils";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { getDesignName } from "../../../utils/treeutils";
import { useVisualizationState } from "../../../reducers/visualizations.reducer";

let prev = { x: 0, y: 0 };
let minHeight = window.innerHeight * 0.875;
const VisualizationJpeg = () => {
  const canvasRef = useRef();
  const originalCanvasRef = useRef();
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchDesignList = useDispatchDesignList();
  const designListState = useDesignListState();
  const visState = useVisualizationState();
  const uiState = useUiState();
  const {
    designDetails,
    fullpath,
    designName,
    hash,
    designDimsOrig,
    designViewZoomJpeg
  } = designDetailState;
  const zoomValue =
    window.flags.homeTemplate === pageViews.CREATEYOURRUG ? designViewZoomJpeg : resolveZoomValue();
  const [zoom, setZoom] = useState(zoomValue);
  const previousZoom = usePrevious(zoom);
  const previousFullpath = usePrevious(fullpath);
  const previousHash = usePrevious(hash);
  const [diff, setDiff] = useState({ x: 0, y: 0 });
  const [panning, setPanning] = useState(false);
  const [canbeZoomed, setCanbeZoomed] = useState(true);
  const [positionClassName, setPositionClassName] = useState("");
  const [canvasInnerHeight, setCanvasInnerHeight] = useState("");
  const { customDesignUrl } = window.initialData;

  useEffect(() => {
    if (window.flags.homeTemplate === pageViews.CREATEYOURRUG) {
      setZoom(designViewZoomJpeg);
    }
  }, [visState.viewMode]);

  let designUnit = window.initialData.unit,
    centerDesignUrl = window.initialData.centerDesignUrl,
    borderSize = window.initialData.borderSize || 0,
    borderDesignWid = window.initialData.borderDesignWid,
    borderDesignHgt = window.initialData.borderDesignHgt,
    designWidth = window.initialData.designWidth,
    designHeight = window.initialData.designHeight;

  const isBorderRugs =
    window.flags.visualizations.isBorderRugs && window.flags.ordersheet.repeatRugInArea;

  useEffect(() => {
    if (isBorderRugs && originalCanvasRef.current) {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_HASH,
        payload: designDetails
      });
    } else if (parseFloat(designWidth) && parseFloat(designHeight) && originalCanvasRef.current) {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_HASH,
        payload: designDetails
      });
    }
  }, [centerDesignUrl, borderDesignWid, borderDesignHgt, borderSize, designWidth, designHeight]);

  const repeatImg = (canvas, img, width, height) => {
    var ctx = canvas.getContext("2d");
    var pat = ctx.createPattern(img, "repeat");

    //find offset
    let repeat = [width / img.width, height / img.height];
    let offsetX = 0;
    let offsetY = 0;
    let halfRepeatX = repeat[0] / 2;
    offsetX = 0.5 - (halfRepeatX - Math.floor(halfRepeatX)); //offset to center the tile center as canvas center horizontally
    let halfRepeatY = repeat[1] / 2;
    offsetY = 0.5 - (halfRepeatY - Math.floor(halfRepeatY)); //offset to center the tile center as canvas center vertically
    let offsetXActual = Math.abs(offsetX * img.width);
    if (offsetX < 0) {
      offsetXActual = img.width - offsetXActual;
    }
    let offsetYActual = Math.abs(offsetY * img.height);
    if (offsetY < 0) {
      offsetYActual = img.height - offsetYActual;
    }

    ctx.save();
    ctx.translate(-offsetXActual, -offsetYActual);
    ctx.rect(0, 0, width + offsetXActual, height + offsetYActual);
    ctx.fillStyle = pat;
    ctx.fill();
    ctx.restore();
  };

  const PhysicalSizeShouldChange = (designWidth, designHeight) => {
    if (
      parseFloat(designWidth) &&
      parseFloat(designHeight) &&
      (parseFloat(designWidth) !== designDetails.PhysicalWidth ||
        parseFloat(designHeight) !== designDetails.PhysicalHeight)
    )
      return true;
    else return false;
  };
  const changePhysicalWidHgt = (designWidth, designHeight, designUnit) => {
    dispatchDesignDetails({
      type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
      payload: {
        PhysicalWidth: parseFloat(designWidth),
        PhysicalHeight: parseFloat(designHeight),
        Unit: designUnit ? designUnit : designDetails.Unit
      }
    });
  };

  useEffect(() => {
    let la = true;
    if (!fullpath || !designDetails) return;
    const loadVisualization = async () => {
      var designDimensions = designDetails;
      if (previousHash !== hash) {
        const { KLRatio } = customDesignUrl ? { KLRatio: 1 } : designDetails;
        const { Height } = designDimsOrig;
        dispatchDesignDetails({
          type: designDetailActions.SET_LOADING,
          payload: true
        });
        if (Height - minHeight <= 0 && !isBorderRugs) {
          setCanbeZoomed(false);
        } else {
          setCanbeZoomed(true);
        }
        if (customDesignUrl) {
          if (
            !originalCanvasRef.current ||
            (designListState.selectedFile &&
              designListState.selectedFile.fullPath !== customDesignUrl)
          ) {
            const image = await readImage(customDesignUrl);
            if (!la) return;
            designDimensions = {
              Height: image.height,
              Width: image.width,
              PhysicalHeight: designHeight || image.height,
              PhysicalWidth: designWidth || image.width,
              Unit: designUnit || "px",
              KLRatio: 1
            };
            const Height = image.height;
            if (Height - minHeight > 0) {
              const h = minHeight + (Height - minHeight) * zoom;
              canvasRef.current.style.height = `${h}px`;
              setCanvasInnerHeight(h);
            }
            const width = image.width;
            const height = image.height;
            originalCanvasRef.current = createCanvas(width, height);
            const tempCtx = originalCanvasRef.current.getContext("2d");
            tempCtx.drawImage(image, 0, 0);

            const name = getDesignName(customDesignUrl);
            const designProps = designDimensions;
            const fullPath = customDesignUrl;
            dispatchDesignList({
              type: designListActions.SELECT_DESIGN,
              payload: { name, designProps, fullPath, thumbUrl: customDesignUrl, id: 123 }
            });
          }
        } else if (previousFullpath !== fullpath) {
          const renderedDesignImage = await AppProvider.getRenderedDesign({
            designDetails: { ...designDetails, ...designDimsOrig },
            fullpath,
            hash,
            zoom: 1,
            applyKLRatio: false
          });

          if (!la) return;
          if (Height - minHeight > 0) {
            const h = minHeight + (Height - minHeight) * zoom;
            canvasRef.current.style.height = `${h}px`;
            setCanvasInnerHeight(h);
          }
          const width = renderedDesignImage.width / KLRatio;
          const height = renderedDesignImage.height;

          originalCanvasRef.current = createCanvas(width, height);
          const tempCtx = originalCanvasRef.current.getContext("2d");
          tempCtx.drawImage(renderedDesignImage, 0, 0);
        }
        if (isBorderRugs) {
          let borderDesignWid = window.initialData.borderDesignWid,
            borderDesignHgt = window.initialData.borderDesignHgt;
          if (
            designUnit &&
            designUnit !== designDetailState.designDetails.Unit &&
            borderDesignWid &&
            borderDesignHgt
          ) {
            borderDesignWid = convertUnit(
              designUnit,
              designDetailState.designDetails.Unit,
              borderDesignWid
            );
            borderDesignHgt = convertUnit(
              designUnit,
              designDetailState.designDetails.Unit,
              borderDesignHgt
            );
          }

          if (!borderDesignWid || !borderDesignHgt) {
            console.log("Please specify Border Design Width and Height");
          } else if (PhysicalSizeShouldChange(borderDesignWid, borderDesignHgt)) {
            changePhysicalWidHgt(borderDesignWid, borderDesignHgt, designUnit);
            return;
          }
        } else {
          if (
            designUnit &&
            designUnit !== designDetailState.designDetails.Unit &&
            designWidth &&
            designHeight
          ) {
            designWidth = convertUnit(
              designUnit,
              designDetailState.designDetails.Unit,
              designWidth
            );
            designHeight = convertUnit(
              designUnit,
              designDetailState.designDetails.Unit,
              designHeight
            );
          }
          if (PhysicalSizeShouldChange(designWidth, designHeight)) {
            changePhysicalWidHgt(parseFloat(designWidth), parseFloat(designHeight), designUnit);
            return;
          }
        }

        if (window.flags.ordersheet.repeatRugInArea) {
          applyRepeat();
        } else {
          applyCrop();
        }

        function applyRepeat() {
          const { Height, KLRatio, Width, Unit } = designDimensions;
          const designWidth = Width / KLRatio;
          const designHeight = Height;
          const { width, height } = { width: designWidth, height: designHeight };
          canvasRef.current.width = width;
          canvasRef.current.height = height;
          let canvasHeightPx;
          if (Height - minHeight > 0) {
            const h = minHeight + (Height - minHeight) * zoom;
            canvasHeightPx = `${h}px`;
          } else {
            canvasHeightPx = "unset";
          }
          canvasRef.current.style.height = canvasHeightPx;

          const img = new Image();
          img.onload = () => {
            repeatImg(canvasRef.current, img, width, height);
            if (isBorderRugs) {
              const centerPath =
                window.initialData.centerDesignUrl && window.initialData.centerDesignUrl !== ""
                  ? window.initialData.centerDesignUrl
                  : null;
              const borderWidth = window.initialData.borderSize || 0;

              const unit = window.initialData.unit || Unit;

              if (window.flags.ordersheet.repeatRugInArea && centerPath) {
                applyCenterPattern({
                  centerPath,
                  borderWidth,
                  unit,
                  designCanvas: canvasRef.current
                });
              }
            }
          };

          img.src = originalCanvasRef.current.toDataURL();
        }

        function applyCrop() {
          const { Height, KLRatio, Width } = designDimensions;
          const designWidth = Width / KLRatio;
          const designHeight = Height;
          const cropPadding = 100;
          let { width: canvasWidth, height: canvasHeight } = originalCanvasRef.current;
          const { width, height } = getCroppedSize(
            { Width: canvasWidth, Height: canvasHeight },
            { Width: designWidth, Height: designHeight },
            cropPadding
          );
          canvasRef.current.width = width;
          canvasRef.current.height = height;
          let canvasHeightPx;
          if (Height - minHeight > 0) {
            const h = minHeight + (Height - minHeight) * zoom;
            canvasHeightPx = `${h}px`;

            const currentPos = canvasRef.current.getBoundingClientRect();
            const newWid = (h * currentPos.width) / currentPos.height;
            if (newWid > window.innerWidth || h > window.innerHeight) {
              setPositionClassName(window.flags.designView.panTo);
            } else {
              setPositionClassName("");
              const { x, y } = panDesign("CENTER", currentPos, 50);
              setDiff({ x, y });
            }
          } else {
            canvasHeightPx = "unset";
            setPositionClassName("");
            setDiff({ x: 0, y: 0 });
          }
          canvasRef.current.style.height = canvasHeightPx;
          //TODO: check if needs to be drawn beforehand instead of comparing in the function
          cropStitchCanvas({ origCanvas: originalCanvasRef.current, canvas: canvasRef.current });
        }
      }
      if (previousZoom !== zoom) {
        const h = minHeight + (canvasRef.current.height - minHeight) * zoom;
        canvasRef.current.style.height = `${h}px`;
        setCanvasInnerHeight(h);

        const currentPos = canvasRef.current.getBoundingClientRect();
        const newWid = (h * currentPos.width) / currentPos.height;
        if (newWid < window.innerWidth || h < window.innerHeight) {
          setPositionClassName("");
          setDiff({ x: 0, y: 0 });
        }
      }
      dispatchDesignDetails({
        type: designDetailActions.SET_LOADING,
        payload: false
      });
    };
    loadVisualization();
    return () => {
      la = false;
    };
  }, [hash, zoom]);

  const placeCanvas = canvas => {
    const currentPos = canvas.getBoundingClientRect();

    if (currentPos.width > window.innerWidth) {
      setPositionClassName(window.flags.designView.panTo);
      // const { x, y } = panDesign(window.flags.designView.panTo, currentPos, 50);
      // console.log("VisualizationJpeg -> placeCanvas", currentPos, currentPos.width > window.innerWidth, x,y)

      // setDiff({ x, y });
    } else {
      setPositionClassName("");
      const { x, y } = panDesign("CENTER", currentPos, 50);
      setDiff({ x, y });
    }
  };

  const panDesign = (direction, currentPos, threshold = 50) => {
    let transformVal;
    switch (direction) {
      case "TOPLEFT":
        transformVal = { x: -currentPos.left + threshold, y: -currentPos.top + threshold };
        break;
      case "TOPRIGHT":
        transformVal = {
          x: -currentPos.right + window.innerWidth - threshold,
          y: -currentPos.top + threshold
        };
        break;
      case "BOTTOMLEFT":
        transformVal = {
          x: -currentPos.left + threshold,
          y: -currentPos.bottom + window.innerHeight - threshold
        };
        break;
      case "BOTTOMRIGHT":
        transformVal = {
          x: -currentPos.right + window.innerWidth - threshold,
          y: -currentPos.bottom + window.innerHeight - threshold
        };
        break;
      default:
        transformVal = { x: 0, y: 0 };
        break;
    }
    return transformVal;
  };

  const applyCenterPattern = ({
    centerPath = null,
    borderWidth = 0,
    unit,
    onComplete,
    designCanvas
  }) => {
    let centerDims, centerCanvas;
    const { Height, Width, Unit } = designDetails;
    if (centerPath) {
      if (unit && Unit !== unit) {
        borderWidth = convertUnit(unit, Unit, borderWidth);
      }
      let borderDesignWid = parseFloat(window.initialData.borderDesignWid) || Width;
      let borderDesignHgt = parseFloat(window.initialData.borderDesignHgt) || Height;

      let centerCanvasPhyWidth = borderDesignWid - borderWidth * 2;
      let centerCanvasPhyHeight = borderDesignHgt - borderWidth * 2;
      let centerCanvasWid = (centerCanvasPhyWidth * Width) / borderDesignWid;
      let centerCanvasHgt = (centerCanvasPhyHeight * Height) / borderDesignHgt;

      centerDims = {
        width: centerCanvasWid,
        height: centerCanvasHgt
      };
    }

    const imgCenter = new Image();

    if (centerPath) {
      imgCenter.src = centerPath;
      imgCenter.crossOrigin = "Anonymous";
      centerCanvas = createCanvas(centerDims.width, centerDims.height);
    }
    imgCenter.onload = () => {
      repeatImg(centerCanvas, imgCenter, centerDims.width, centerDims.height);
      if (centerPath) {
        const startx = (designCanvas.width - centerCanvas.width) / 2;
        const starty = (designCanvas.height - centerCanvas.height) / 2;
        const finalCanvasContext = designCanvas.getContext("2d");
        finalCanvasContext.drawImage(
          centerCanvas,
          startx,
          starty,
          centerCanvas.width,
          centerCanvas.height
        );

        //canvasRef.current.style.height = canvasHeightPx;
        placeCanvas(designCanvas);
      }
      if (onComplete) onComplete();
    };
  };
  const handleDownloadDesign = () => {
    downloadImageData(canvasRef.current, `${designName}.png`, "png");
    return Promise.resolve();
  };
  window.downloadRenderedDesign = handleDownloadDesign;
  const handleMouseDown = e => {
    const { clientX: x, clientY: y } = isTouchEvent(e) ? e.nativeEvent.targetTouches[0] : e;
    prev = { x, y };
    setPanning(true);
  };
  const handleMouseMove = e => {
    if (!panning) return;
    const { clientX: x, clientY: y } = isTouchEvent(e) ? e.nativeEvent.targetTouches[0] : e;
    const { X: translateX, Y: translateY } = getTransformValues(canvasRef.current);
    //setDiff({ x: diff.x + x - prev.x, y: diff.y + y - prev.y });
    if (translateX && translateY)
      setDiff({ x: translateX + x - prev.x, y: translateY + y - prev.y });
    else {
      setDiff({ x: diff.x + x - prev.x, y: diff.y + y - prev.y });
    }
    prev = { x, y };
  };
  const getTransformValues = elem => {
    const style = window.getComputedStyle(elem);
    const matrix = style["transform"] || style.webkitTransform || style.mozTransform;

    const matrixType = matrix.includes("3d") ? "3d" : "2d";
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");

    if (matrixType === "2d") {
      return {
        X: parseFloat(matrixValues[4]),
        Y: parseFloat(matrixValues[5]),
        Z: 0
      };
    }
    if (matrixType === "3d") {
      return {
        X: parseFloat(matrixValues[12]),
        Y: parseFloat(matrixValues[13]),
        Z: parseFloat(matrixValues[14])
      };
    } else {
      return {
        X: null,
        Y: null,
        Z: null
      };
    }
  };

  const handleMouseUp = () => {
    setPanning(false);
  };
  return (
    <div
      className="tile-container"
      style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}
    >
      <canvas
        style={{ transform: `translate(${diff.x}px, ${diff.y}px)` }}
        className={classNames("jpeg-canvasrender", positionClassName)}
        ref={canvasRef}
        onMouseLeave={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      />
      {canbeZoomed && (
        <AtSlider
          isIdle={uiState.isIdle}
          value={zoom}
          zoomFactor={zoom}
          onRelease={e => setZoom(Math.round(e * 100) / 100)}
          min={getZoomParameters().minZoom}
          max={getZoomParameters().maxZoom}
          stepSize={0.1}
          canvasInnerHeight={canvasInnerHeight}
        />
      )}
    </div>
  );
};
function isTouchEvent(event) {
  return event.nativeEvent.type === "touchstart" || event.nativeEvent.type === "touchmove";
}
export default VisualizationJpeg;
