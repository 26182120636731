import React, { useState, useEffect } from "react";
import AtIcon from "./../../../atoms/AtIcon";
import PropTypes from "prop-types";

const Checkbox = props => {
  const {
    defaultVal,
    inline,
    label,
    className,
    onChange,
    externalControl,
    val,
    onClick,
    ...otherProps
  } = props;
  const [checkboxstate, setcheckboxstate] = useState(defaultVal);

  const handleClick = () => {
    if (!externalControl) {
      setcheckboxstate(checkboxstate => !checkboxstate);
      onChange(!checkboxstate);
    } else {
      onClick();
    }
  };

  useEffect(() => {
    if (externalControl && (val !== undefined || val !== null)) {
      setcheckboxstate(val);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);
  return (
    <span
      className={`${className} checkbox-area ${inline ? "checkbox-area-inline" : ""}`}
      {...otherProps}
    >
      <AtIcon
        icon={checkboxstate ? "checked-square" : "unchecked-square"}
        label={label}
        onClick={handleClick}
        className="checkbox"
      ></AtIcon>
      {label !== "" && <span onClick={handleClick}>{label}</span>}
    </span>
  );
};

Checkbox.propTypes = {
  defaultVal: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  externalControl: PropTypes.bool,
  val: PropTypes.bool,
  onClick: PropTypes.func
};
Checkbox.defaultProps = {
  defaultVal: false,
  inline: false,
  label: "",
  className: "",
  externalControl: false
};

export default Checkbox;
