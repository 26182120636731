import React from "react";
const SET_FLOOR_OPTIONS = "SET_FLOOR_OPTIONS";
const SET_ACTIVE_FLOOR_OPTION = "SET_ACTIVE_FLOOR_OPTION";
const SET_SHOW_FLOOR_OPTION = "SET_SHOW_FLOOR_OPTION";
const SET_CARPET_OPTION = "SET_CARPET_OPTION";
const SET_ROOM_OPTIONS = "SET_ROOM_OPTIONS";
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const roomViewActions = {
  SET_ACTIVE_FLOOR_OPTION,
  SET_SHOW_FLOOR_OPTION,
  SET_FLOOR_OPTIONS,
  SET_CARPET_OPTION,
  SET_ROOM_OPTIONS
};

const roomViewState = {
  floorOptions: {
    show: false,
    floors: [],
    activeFloor: {}
  },
  carpetOptions: {
    rotation: null,
    position: null
  }
};

const visualizationReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_FLOOR_OPTIONS:
      return setFloorOptions(state, payload);
    case SET_ACTIVE_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, activeFloor: payload } };
    case SET_SHOW_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, show: !!payload } };
    case SET_CARPET_OPTION:
      return { ...state, carpetOptions: payload };
    case SET_ROOM_OPTIONS:
      return { ...state, ...payload }

    default:
      return state;
  }
};
const setFloorOptions = (state, payload) => {
  const { floorOptions } = state;
  const {
    floors = floorOptions.floors,
    activeFloor = floorOptions.activeFloor,
    show = floorOptions.show
  } = payload;
  return { ...state, floorOptions: { ...state.floorOptions, floors, activeFloor, show } };
};
function RoomviewStateProvider({ children }) {
  const { visualizations } = window.flags;
  const initState = { ...roomViewState, viewMode: visualizations.defaultView };
  const [state, dispatch] = React.useReducer(visualizationReducer, initState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useRoomViewState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchRoomView() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export {
  RoomviewStateProvider,
  useRoomViewState,
  useDispatchRoomView,
  roomViewActions,
};
