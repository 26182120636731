/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  H5,
  InputGroup,
  H3,
  EditableText,
  H6,
  Intent,
  FormGroup,
  TextArea,
  Toaster,
  Toast
} from "@blueprintjs/core";
import { AtSpinner } from "../../atoms/AtSpinner";
import AtDropdown from "../../atoms/AtDropdown";
import { usePrevious, usePriceCalculator } from "../../../hooks";
import AppProvider, { domain, sessKeys } from "../../../api/appProvider";
import AtButton from "../../atoms/AtButton";
import Divider from "../../atoms/Divider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { useColorListState } from "../../../reducers/colors.reducer";
import FadeIn from "react-fade-in";
import classNames from "classnames";
import {
  convertNumberToFeetInch,
  convertFeetInchToNumber,
  getMaterialNameArray,
  replaceAll,
  calculateTotalArea,
  displayAreaOfRug,
  displayAreaInFt
} from "../../../utils/utils";

import { getFromSessionStorage } from "../../../utils/domUtils";
import strings from "../../../strings";
import { getDesignPathInTitle, convertUnit_Arr } from "../../../utils/utils";

import AddedCustomFields from "./AddedCustomFields";
import { SectionHeading } from "../../molecules/CustomCollapse";
import OrdersheetPreview from "./OrdersheetPreview";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import {
  useOrdersheetDetailState,
  useDispatchordersheetDetail,
  ordersheetDetailActions
} from "../../../reducers/ordersheetdetails.reducer";

import DavisPriceEstimation from "./DavisPriceEstimation";
import { RugTypes } from "./Davisrugs/DavisRugsOptions";
import { getcurrentCollectionFilter, getFolderNameFromFullpath } from "../../../utils/treeutils";
import { dateFormat } from "../../pages/EntryPage/defaultflags";
import { getEmailMessages, renderDesignName } from "../../../utils/stringUtils";
import {
  getDefaultValueOfField,
  getExplorugData,
  getPileheightNamesList
} from "../../../middleware/ordersheet.middleware";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import { sendOrdersheetEmail } from "../../../middleware/ordersheet.middleware";
import PrintHelper from "./printHelper";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
import axios from "axios";
import { useDesignListState } from "../../../reducers/designlist.reducer";
let printHelper = new PrintHelper();
const convertfieldNameToID = name => {
  const newName = replaceAll(name, " ", "_");
  const newName2 = replaceAll(newName, "/", "_");
  return newName2;
};
const getStandardSizeArray = standardSizes => {
  let stdSizeArray = [];
  if (standardSizes.length) {
    stdSizeArray = standardSizes.map((elem, index) =>
      (elem.Width + " x " + elem.Height + " " + elem.UnitString).trim()
    );
    stdSizeArray.unshift(" ");
  }
  return stdSizeArray;
};
const hasWidthFilter = filterArray => {
  if (filterArray.length === 0) return false;
  let hasWidthList = false;
  filterArray.forEach(element => {
    hasWidthList = element.widthOptions && element.widthOptions.length > 0;
  });
  return hasWidthList;
};
const getWidthOptions = (collectionFilter, fullpath) => {
  if (!hasWidthFilter(collectionFilter)) return null;
  if (!fullpath || fullpath === "") return null;
  if (collectionFilter[0].folder === "" && collectionFilter[0].widthOptions !== null)
    return collectionFilter[0].widthOptions;

  const selectedCollectionFilter = getcurrentCollectionFilter(collectionFilter, fullpath);
  const widthOptions = selectedCollectionFilter.length
    ? selectedCollectionFilter[0].widthOptions || []
    : [];
  return widthOptions;
};
const OrderSheet = props => {
  const uiState = useUiState();
  return uiState.showOrderSheet && <OrderingSheet {...props} />;
};

const OrderingSheet = props => {
  const { handleShapeChange, openOrdersheetBydefault = false } = props;
  const { flags } = window;
  const { defaultQualityIndex } = flags;
  const { defaultStandardSize = 0 } = flags;
  let rugQualities = window.InterfaceElements.AvailableQualities;
  rugQualities = rugQualities.filter(quality => !quality.startsWith("~"));
  const standardSizes = window.InterfaceElements.StandardSizes;
  const showPrice = window.InterfaceElements.ShowPrice && !window.initialData.customDesignUrl;
  const hideSizeDetails = false;
  const showClose = !openOrdersheetBydefault;
  const state = useDesignListState();
  const designDetailState = useDesignDetailState();
  const {
    designDimsOrig,
    designDetails,
    designName,
    originalName,
    customFields: csfields,
    hash
  } = designDetailState;
  var { fullpath } = designDetailState;
  const dispatchDesignDetails = useDispatchDesignDetail();
  const colorListState = useColorListState();
  const visualizationState = useVisualizationState();
  const ordersheetDetailState = useOrdersheetDetailState();
  const dispatchOrdersheetDetail = useDispatchordersheetDetail();
  const uiState = useUiState();

  const customFields = csfields.fields;
  const {
    colorSelectionBox: CsbFlags,
    ordersheet: ordersheetFlags,
    email: emailTexts
  } = window.flags;
  const { collectionFilter } = CsbFlags;

  const [orderSheetDetails, setOrderSheetDetails] = useState(designDetails);
  const [showPreview, setShowPreview] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [ordersheetPath, setOrdersheetPath] = useState(null);
  const standardSizeArray = getStandardSizeArray(standardSizes);
  const [rugsizeUpdated, setRugsizeUpdated] = useState({ w: false, h: false });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [emailInfo, setEmailInfo] = useState(null);
  const [displayMaxErrorMessage, setDisplayMaxErrorMessage] = useState("");
  const [toastProps, setToastProps] = useState(false);
  const [selectedKnot, setSelectedKnot] = useState([0]);
  const emailMessages = getEmailMessages(strings.ordersheet.emailMessages);

  const { setCustomConversion = false, customInToCmFactor } = ordersheetFlags.customConversion;
  const [widthOptions, setWidthOptions] = useState(getWidthOptions(collectionFilter, fullpath));
  const isBorderRugs =
    window.flags.visualizations.isBorderRugs && window.flags.ordersheet.repeatRugInArea;
  let { borderDesignWid, borderDesignHgt } = window.initialData;

  const [rugSizeWidth, setRugSizeWidth] = useState(
    isBorderRugs && borderDesignWid ? borderDesignWid : designDetails.PhysicalWidth
  );
  const [rugSizeHeight, setRugSizeHeight] = useState(
    isBorderRugs && borderDesignHgt ? borderDesignHgt : designDetails.PhysicalHeight
  );

  const [rugSizeWidthFt, setRugSizeWidthFt] = useState(0);
  const [rugSizeWidthInch, setRugSizeWidthInch] = useState(0);
  const [rugSizeHeightFt, setRugSizeHeightFt] = useState(0);
  const [rugSizeHeightInch, setRugSizeHeightInch] = useState(0);
  const [reloadthisState, setReloadThisState] = useState(true);

  const dispatchUiState = useUiDispatch();
  const units = [
    { val: "ft", text: strings.ordersheet.orderUnits.ft },
    { val: "cm", text: strings.ordersheet.orderUnits.cm }
  ];
  const [showRugSize, setShowRugSize] = useState(flags.ordersheet.populateRugSize);
  const prevDesignPath = usePrevious(fullpath);
  const [selectedWidOption, setSelectedWidOption] = useState(
    flags.colorSelectionBox.defaultWidthOptionValue
  );

  const price = usePriceCalculator({
    designDetails: orderSheetDetails,
    fullpath,
    showPrice
  });

  const checkSession = fieldName => {
    fieldName = fieldName.toLowerCase().replace(/ /g, "");
    let val = sessionStorage.getItem(fieldName) || "";
    val = val !== "" && val.match(/[0-9]/g) ? parseFloat(val) : val;
    return val;
  };
  const customFieldsMapped = customFields.Fields.map(field => {
    return {
      fieldName: field.First,
      fieldValue: field.Second || checkSession(field.First)
    };
  });
  const getDefaultCustomFieldValue = field => {
    let defFieldValue = field.fieldValue || "";
    if (defFieldValue === "") {
      defFieldValue = getDefaultValueOfField({
        addedFields: ordersheetFlags.addedCustomFields,
        formFieldElement: field
      });
    }
    return defFieldValue;
  };
  const [formFields, setFormFields] = useState({
    fields: customFieldsMapped.map(field => {
      return {
        fieldName: field.fieldName,
        fieldValue: getDefaultCustomFieldValue(field)
      };
    }),
    loading: false
  });

  const getCustomFieldValue = ({ fieldName, fieldValue }) => {
    if (ordersheetFlags.autoPopulateFields.length === 0) return fieldValue;
    const fn = fieldName.toLowerCase();
    const findField = ordersheetFlags.autoPopulateFields.find(
      field => field.toLowerCase() === fn || field.toLowerCase() === "url_" + fn
    );
    let value = fieldValue;
    if (!findField) return value;
    const { OrderInfo, PhysicalHeight, PhysicalWidth, Unit } = orderSheetDetails;
    switch (fn) {
      case "shape": //in dalyn rugs
        return designName;
      case "order id":
        return "OR" + Math.round(Math.random(5) * 100000000);
      case "knots type": //linkoutdoor
      case "knots/sq in":
      case "pile height":
        return OrderInfo ? OrderInfo[fieldName] || "" : "";
      case "lead time":
        if (!OrderInfo) return "";
        const lt = OrderInfo[fieldName];
        if (!lt) return "";
        const ltSize =
          lt.find(item => {
            const { size, unit } = item;
            const [w, h] = convertUnit_Arr(Unit, unit, [PhysicalWidth, PhysicalHeight]);
            // const h = convertUnit(Unit, unit, PhysicalHeight);
            return Math.round(w) === Math.round(size[0]) && Math.round(h) === Math.round(size[1]);
          }) || {};
        return ltSize.value || "";
      case "construction":
        if (findField.toLowerCase() === "url_construction") {
          return getFromSessionStorage("construction") || "";
        } else {
          return ordersheetDetailState.accountSpecific.allInOneMaterialSelectionValue;
        }
      case "customer name":
        if (findField.toLowerCase() === "url_customer name") {
          return getFromSessionStorage("customerName") || "";
        } else {
          return "";
        }
      case "wilton rug size":
        if (designName && designName.toLowerCase().lastIndexOf("4 x 6") >= 0) return "4' X 6'";
        else return "9' X 12'";
      case "jp_shape":
        if (formFields) {
          const fieldval = getSummaryShapeText();
          if (fieldval && fieldval !== "") {
            return fieldval;
          } else {
            const { defaultOptionIndex, options } = ordersheetFlags.hasShapeOptions;
            return options[defaultOptionIndex];
          }
        } else {
          return "Rectangle";
        }
      case "jp_quality":
        if (fullpath && formFields) {
          return getFolderNameFromFullpath(fullpath);
        } else {
          return "";
        }
      default:
        return fieldValue;
    }
  };
  const getFieldValueFromLocal = ({ fieldName = "", fieldValue = "" }) => {
    const storeFieldsValueInLocal = ordersheetFlags.storeFieldsValueInLocal;
    if (
      storeFieldsValueInLocal &&
      storeFieldsValueInLocal.indexOf(fieldName.toLowerCase()) >= 0 &&
      fieldName !== ""
    ) {
      fieldValue = localStorage.getItem(fieldName.toLowerCase()) || fieldValue;
    }
    return fieldValue;
  };
  useEffect(() => {
    if (!fullpath) return;
    let OrderInfo = {};
    if (window.flags.designSpecific) {
      let design = window.flags.designSpecific.find(
        design => design.name.toLowerCase() === originalName.toLowerCase()
      );
      const origBorderDesign = sessionStorage.getItem(sessKeys.ORIG_BORDER_DESIGN);
      if (origBorderDesign) {
        const origdesign = window.flags.designSpecific.find(
          design => design.name.toLowerCase() === origBorderDesign.toLowerCase()
        );
        if (origdesign) design = origdesign;
      }
      if (design) {
        OrderInfo = design.orderInfo;
      }
    }
    const det = { ...designDetails, OrderInfo };
    setOrderSheetDetails(det);
    let selWidOpt = selectedWidOption;
    if (prevDesignPath !== fullpath) {
      selWidOpt = flags.colorSelectionBox.defaultWidthOptionValue;
    }
    resetSelectedWidthOpt(det, selWidOpt);
    setShowRugSize(flags.ordersheet.populateRugSize);
    if (flags.ordersheet.allowColorOnlyAfterSizeInput)
      dispatchUiState({ type: mainUiActions.SET_ALLOW_COLOR_CUSTOMIZATION, payload: false });

    setRugsizeUpdated({ w: false, h: false });
  }, [fullpath]);

  const resetSelectedWidthOpt = (det, selWidOpt = 0) => {
    const widthOpt = getWidthOptions(collectionFilter, fullpath);
    setWidthOptions(widthOpt);
    if (widthOpt && widthOpt.length) {
      if (selWidOpt !== -1) {
        handleWidthOptionChange(selWidOpt, det, widthOpt);
      } else {
        setSelectedWidOption(-1);
      }
    }
  };

  const updateOrdersheetDetails = () => {
    let det;
    if (window.flags.showOrdersheetOutside && orderSheetDetails.PhysicalWidth) {
      // when autocolor resets the ordersheet input value. Hence, set orderproperties, size and carving again
      let PhysicalHeight = orderSheetDetails.PhysicalHeight || designDetails.PhysicalHeight;
      let PhysicalWidth = orderSheetDetails.PhysicalWidth || designDetails.PhysicalWidth;
      let Unit = orderSheetDetails.Unit || designDetails.Unit;
      let Carving = orderSheetDetails.Carving || designDetails.Carving;
      let OrderProperties = orderSheetDetails.OrderProperties || designDetails.OrderProperties;

      PhysicalWidth = getSelectedWidOption(PhysicalWidth, Unit);

      det = {
        ...orderSheetDetails,
        ...designDetails,
        PhysicalHeight,
        PhysicalWidth,
        Unit,
        Carving,
        OrderProperties
      };
    } else {
      let PhysicalWidth = orderSheetDetails.PhysicalWidth || designDetails.PhysicalWidth;
      let Unit = orderSheetDetails.Unit || designDetails.Unit;
      PhysicalWidth = getSelectedWidOption(PhysicalWidth, Unit);

      det = { ...orderSheetDetails, ...designDetails, PhysicalWidth };
    }
    setOrderSheetDetails(det);
  };
  const getSelectedWidOption = (PhysicalWidth, Unit) => {
    const widthOpt = getWidthOptions(collectionFilter, fullpath);
    if (widthOpt && widthOpt.length && selectedWidOption !== -1) {
      const value = widthOpt[selectedWidOption];
      PhysicalWidth = convertFeetInchToNumber(value, Unit);
    }
    return PhysicalWidth;
  };

  useEffect(() => {
    updateOrdersheetDetails();
  }, [designDetails]);

  useEffect(() => {
    if (
      flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice &&
      uiState.allowPriceEstimation_Davisrugs
    ) {
      var { price, rugPadRate, totalRugPadPrice } = calculateTotalPrice_accountSpecific(
        ordersheetDetailState.accountSpecific.davisRugs.rugType,
        ordersheetDetailState.accountSpecific.davisRugs.rugTechnique
      );
      setDavisRugs({ price, rugPadRate, totalRugPadPrice });
      if (showRugSize) {
        formFields.fields[0].fieldValue = `${strings.ordersheet.rugPrice}: ${price}`; //for rug price
        formFields.fields[1].fieldValue = `${strings.ordersheet.rugPadPrice}: ${rugPadRate}`; //for rug pad price
        formFields.fields[2].fieldValue = `${strings.ordersheet.rugWithPadPrice}: ${totalRugPadPrice}`; //for rug price with rug pad
      } else {
        for (var i = 0; i < 3; i++) {
          formFields.fields[i].fieldValue = "";
        }
      }
    }
  }, [showRugSize, orderSheetDetails]);

  useEffect(() => {
    //for when area of rug is changed, the value in ordersheet must also change. When "changeActualRugSize" flag is true.
    if (!orderSheetDetails.PhysicalWidth || !orderSheetDetails.PhysicalHeight) return;
    const PhysicalWidth = designDetails.PhysicalWidth;
    const PhysicalHeight = designDetails.PhysicalHeight;
    const Unit = designDetails.Unit;
    var det = { ...orderSheetDetails, ...designDetails, PhysicalWidth, PhysicalHeight, Unit };
    if (ordersheetFlags.specificKnotForSpecificFolder.length != 0) {
      const specificKnotArrayForSpecificFolder = ordersheetFlags.specificKnotForSpecificFolder;
      specificKnotArrayForSpecificFolder.map(item => {
        if (state.selectedFile.location.includes(item[0])) {
          det = {
            ...det,
            OrderProperties: { ...orderSheetDetails.OrderProperties, QualityIndex: item[1][0] }
          };
          setSelectedKnot(item[1]);
        }
      });
    }
    setOrderSheetDetails(det);
  }, [designDetails.PhysicalWidth, designDetails.PhysicalHeight, state.selectedFile]);

  useEffect(() => {
    let fields = formFields.fields.map(field => ({
      fieldName: field.fieldName,
      fieldValue: getCustomFieldValue(field)
    }));

    if (window.flags.designSpecific && window.flags.designSpecific.length) {
      let fieldsToStore = ordersheetFlags.autoPopulateFields || []; //["knots/sq in", "lead time", "pile height", "knots type"];
      let origborderdesign = sessionStorage.getItem("origborderdesign") || "";
      let designOrigName = localStorage.getItem("designOrigName") || "";

      if (
        origborderdesign.toLowerCase() === designOrigName.toLowerCase() &&
        designOrigName !== ""
      ) {
        fields.forEach((elem, index) => {
          if (fieldsToStore.indexOf(elem.fieldName.toLowerCase()) >= 0) {
            const fieldVal = localStorage.getItem(elem.fieldName.toLowerCase()) || "";
            elem.fieldValue = fieldVal === "" ? elem.fieldValue : fieldVal;
            //localStorage.setItem(elem.fieldName.toLowerCase(), elem.fieldValue);
          }
        });
      }

      if (fullpath.indexOf("Border Designs") < 0) {
        //set the ordersheet value in localstorage
        localStorage.setItem("designOrigName", originalName);

        fields.forEach((elem, index) => {
          if (fieldsToStore.indexOf(elem.fieldName.toLowerCase()) >= 0) {
            localStorage.setItem(elem.fieldName.toLowerCase(), elem.fieldValue);
          }
        });
      }
    }
    if (ordersheetFlags.storeFieldsValueInLocal.length) {
      fields = fields.map(field => ({
        fieldName: field.fieldName,
        fieldValue: getFieldValueFromLocal({
          fieldName: field.fieldName,
          fieldValue: field.fieldValue
        })
      }));
    }

    setFormFields({ ...formFields, fields });
    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_ORDERSHEETDETAILS,
      payload: { ...orderSheetDetails }
    });

    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_ORDERSHEET_FORMFIELDS,
      payload: { ...formFields, fields }
    });
  }, [orderSheetDetails]);

  useEffect(() => {
    if (!ordersheetFlags.showDoubleBoxForFtInch) {
      setRugSizeWidth(designDetails.PhysicalWidth);
      setRugSizeHeight(designDetails.PhysicalHeight);
    } else {
      if (!reloadthisState) return; //this is so that the following code only runs in the first phase
      if (!designDetails.PhysicalHeight) return;
      if (designDetails.PhysicalHeight % 1 != 0) {
        setRugSizeHeightFt(parseInt(designDetails.PhysicalHeight.toString().split(".")[0]));
        setRugSizeHeightInch(
          Math.round(
            parseFloat(0 + "." + parseInt(designDetails.PhysicalHeight.toString().split(".")[1])) *
              12
          )
        );
      } else {
        setRugSizeHeightFt(designDetails.PhysicalHeight);
        setRugSizeHeightInch(0);
      }
      if (!designDetails.PhysicalWidth) return;
      if (designDetails.PhysicalWidth % 1 != 0) {
        setRugSizeWidthFt(parseInt(designDetails.PhysicalWidth.toString().split(".")[0]));
        setRugSizeWidthInch(
          Math.round(
            parseFloat(0 + "." + parseInt(designDetails.PhysicalWidth.toString().split(".")[1])) *
              12
          )
        );
      } else {
        setRugSizeWidthFt(designDetails.PhysicalWidth);
        setRugSizeWidthInch(0);
      }
      setReloadThisState(false);
    }
  }, [designDetails.PhysicalWidth, designDetails.PhysicalHeight]);

  useEffect(() => {
    if (flags.ordersheet.allowColorOnlyAfterSizeInput)
      dispatchUiState({ type: mainUiActions.SET_ALLOW_COLOR_CUSTOMIZATION, payload: false });
  }, []);
  useEffect(() => {
    if (flags.ordersheet.allowColorOnlyAfterSizeInput) {
      if (rugsizeUpdated.w && rugsizeUpdated.h) {
        dispatchUiState({ type: mainUiActions.SET_ALLOW_COLOR_CUSTOMIZATION, payload: true });
      } else {
        dispatchUiState({ type: mainUiActions.SET_ALLOW_COLOR_CUSTOMIZATION, payload: false });
      }
    }
    if (rugsizeUpdated.w && rugsizeUpdated.h) {
      setShowRugSize(true);
    } else {
      setShowRugSize(flags.ordersheet.populateRugSize);
    }
  }, [rugsizeUpdated.w, rugsizeUpdated.h]);
  const handleUnitChange = Unit => {
    const [PhysicalWidth, PhysicalHeight] = convertUnit_Arr(
      orderSheetDetails.Unit,
      Unit,
      [orderSheetDetails.PhysicalWidth, orderSheetDetails.PhysicalHeight],
      setCustomConversion,
      customInToCmFactor
    );
    setOrderSheetDetails({ ...orderSheetDetails, PhysicalHeight, PhysicalWidth, Unit });
  };

  const isWidHgtEqual = () => {
    if (!ordersheetFlags.sameWidNHgt) return false;
    if (!designDetails.PhysicalWidth || !designDetails.PhysicalHeight) {
      return false;
    }
    return designDetails.PhysicalWidth.toFixed(1) === designDetails.PhysicalHeight.toFixed(1);
  };

  const replaceLastDimension = filename => {
    // Match the last occurrence of digits followed by 'x' and more digits
    let regex = /(\d+\s*x\s*\d+)(?![\s\S]*\1)/;
    return filename.replace(regex, "1 x 2");
  };

  const handleWidthOptionChange = (i, det, widthOptions) => {
    const value = widthOptions[i];
    setSelectedWidOption(i);
    const details = det || orderSheetDetails;
    const PhysicalWidth = convertFeetInchToNumber(value, details.Unit);
    setRugsizeUpdated({ ...rugsizeUpdated, w: true });
    let PhysicalHeight = details.PhysicalHeight;
    if (isWidHgtEqual()) PhysicalHeight = PhysicalWidth;
    setOrderSheetDetails({ ...details, PhysicalWidth, PhysicalHeight });
  };
  const handleOrdersheetFetch = async (checkForStrikeOffField = false) => {
    var date = "";
    if (ordersheetFlags.dateFormat === dateFormat.DDMMYYYY) {
      date = new Date();
      date =
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
        "/" +
        date.getFullYear();
    }
    let view = "";

    if (ordersheetFlags.showRoomView && visualizationState.viewMode === visViewModes.ROOM_VIEW) {
      await window.getRoomRenderingCanvas().then(response => {
        view = response;
      });
    }
    var _orderSheetDetails = { ...orderSheetDetails };
    if (ordersheetFlags?.showAreaInM2InOrdersheetOnly && orderSheetDetails.Unit === "cm") {
      _orderSheetDetails = {
        ...orderSheetDetails,
        PhysicalHeight: orderSheetDetails.PhysicalHeight / 100,
        PhysicalWidth: orderSheetDetails.PhysicalWidth / 100,
        Unit: "m"
      };
    }

    if (ordersheetFlags.changeValueWithoutApplyButton) {
      _orderSheetDetails = {
        ...orderSheetDetails,
        PhysicalHeight: rugSizeHeight,
        PhysicalWidth: rugSizeWidth
      };
    }

    if (!showRugSize && ordersheetFlags.noRugSizeInPdfForBlankSizeInput) {
      _orderSheetDetails = {
        ...orderSheetDetails,
        PhysicalHeight: "",
        PhysicalWidth: ""
      };
    }
    if (window.flags.accountSpecific.isPaulig) {
      _orderSheetDetails.IsIrregular = true; //force irregular=true for paulig1750
    }
    if (checkForStrikeOffField) {
      formFields.fields.map(field => {
        if (field.fieldName == "Strikeoff" && field.fieldValue == "Yes") {
          fullpath = replaceLastDimension(state.activeShape.fullPath);
          _orderSheetDetails = {
            ..._orderSheetDetails,
            PhysicalHeight: 2,
            PhysicalWidth: 1,
            Width: 110,
            Height: 96,
            Unit: "ft"
          };
          AppProvider.getDesignThumbnails({ designs: [{ fullPath: fullpath }] }).then(async res => {
            await AppProvider.fetchTileDetails({ file: fullpath });
          });
        }
      });
    }

    var rx, ry;
    if (ordersheetFlags.changePdfSize) {
      rx = designDetails.Width / designDimsOrig.Width;
      ry = designDetails.Height / designDimsOrig.Height;
    }
    return AppProvider.fetchOrderSheet({
      file: fullpath,
      props: _orderSheetDetails,
      customFields: formFields.fields,
      materialNames: getMaterialNameArray(),
      watermark: window.flags.designView.hasDesignWaterMark,
      uppercasing: ordersheetFlags.upperCasedDesignNameinPDF,
      date,
      zoom: ordersheetFlags.designImageZoom,
      view,
      rx,
      ry,
      mode: ordersheetFlags.redirectToLink ? "json" : "",
      sameColorDifferentSymbol: ordersheetFlags.sameColorDifferentSymbol,
      pilenames: ordersheetFlags.showPileHeightNames ? getPileheightNamesList() : "",
      pilevalues: ordersheetFlags.showPileHeightNames
        ? window.TextureOptions.DefaultPileheightValues
        : ""
    }).then(data => {
      if (ordersheetFlags.redirectToLink) {
        axios
          .post(ordersheetFlags.redirectToLink, data)
          .then(response => {
            window.location.href = ordersheetFlags.redirectToLink;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        return data.Value;
      }
    });
  };

  const handleRequestQuote = () => {
    if (ordersheetFlags.checkMandatoryForRequestQuote && hasBlankMandatoryFields()) return;
    if (ordersheetFlags.hasPostback) {
      getExplorugData({
        designDetailState: designDetailState,
        ordersheetDetailState: ordersheetDetailState,
        showPrice: window.InterfaceElements.ShowPrice,
        price: price,
        origDesignColors: designDetailState.history[0]
          ? designDetailState.history[0].designDetails.DesignColors
          : designDetailState.designDetails.DesignColors
      });
      dispatchUiState({
        type: mainUiActions.SET_TOAST_PROPS,
        payload: emailMessages.orderPlaced
      });
    } else if (!ordersheetFlags.redirectToLink) {
      setPreviewLoading(true);
      handleOrdersheetFetch()
        .then(path => setOrdersheetPath(`${path}?${hash}`))
        .then(() => {
          setPreviewLoading(false);
          setShowPreview(true);
        });
    } else {
      handleOrdersheetFetch();
    }
    // AppAnalytics.sendOrdersheetData({
    //   designPath: fullpath,
    //   mode: "request quote"
    // })
    //   .then(status => {
    //     //console.log("sendOrdersheetData mode:request quote -> status", status);
    //   })
    //   .catch(error => {
    //     console.log(" AppAnalytics.sendOrdersheetData ~ error", error);
    //   });
  };
  const handlePrintOrdersheetPDF = async () => {
    setPreviewLoading(true);
    handleOrdersheetFetch().then(path => {
      const ordersheetFullPath = `${AppProvider.domain}${path}?${hash}`;
      setOrdersheetPath(ordersheetFullPath);
      setPreviewLoading(false);
      printHelper.clear();
      printHelper
        .printFile({ pdfToPrint: ordersheetFullPath })
        .then(() => {
          console.log("print success");
        })
        .catch(() => {
          console.log("print fail");
        });
    });
  };
  const hideEmailInfo = () => {
    setTimeout(() => {
      setEmailInfo({
        message: "",
        intent: Intent.NONE
      });
    }, 4000);
  };
  const handleSendEmailDirectly = () => {
    var note = "";
    var { name, email, phone = "" } = window.flags.ordersheet.sendDirectEmail;
    if (formFields && formFields.fields) {
      formFields.fields.forEach((field, index) => {
        if (field.fieldName === "Additional Instructions") {
          note = field.fieldValue;
        }
        if (field.fieldName === "Customer Name") {
          name = field.fieldValue;
        }
        if (field.fieldName === "Email Address") {
          //done for designerrugs
          email = field.fieldValue;
        }
      });
    }
    setEmailInfo(emailMessages.sending);
    sendOrdersheetEmail({
      emailTexts,
      name,
      email,
      phone,
      note,
      designPath: fullpath,
      orderSheetDetails,
      formFields: formFields.fields,
      ordersheetFlags
    })
      .then(response => {
        if (response === true) {
          setEmailInfo(emailMessages.sent);
          if (ordersheetFlags.closeAfterEmailSent) {
            dispatchUiState({
              type: mainUiActions.SET_TOAST_PROPS,
              payload: emailMessages.sent
            });
          }
          hideEmailInfo();
        } else {
          setEmailInfo(emailMessages.fail);
          hideEmailInfo();
        }
      })
      .catch(err => {
        setEmailInfo(emailMessages.fail);
        hideEmailInfo();
      });
  };

  const getCertainFormFieldValue = fieldName => {
    if (!formFields || !formFields.fields || formFields.fields.length === 0) return "";

    var formField = formFields.fields.filter(
      field => field.fieldName.toLowerCase() === fieldName.toLowerCase()
    );
    var formFieldValue = "";
    if (formField && formField.length > 0) {
      formFieldValue = formField[0].fieldValue ? formField[0].fieldValue : "";
    }
    return formFieldValue;
  };
  const handleSaveOrdersheet = () => {
    if (ordersheetFlags.checkMandatoryForSaveOrdersheet && hasBlankMandatoryFields()) return;

    var filename;
    if (ordersheetFlags.PDF_Filename === 1) {
      var date = new Date();
      date =
        (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "-" +
        date.getFullYear();
      const PO = getCertainFormFieldValue("Customer PO");
      const Project = getCertainFormFieldValue("Project");

      var middleWord = PO !== "" ? PO : Project !== "" ? Project : "";

      filename = `STARK - ${designName}_${middleWord}_${date}.pdf`;
    }
    handleOrdersheetFetch().then(path => {
      let pageview = getFromSessionStorage("pageview") || "";

      if (pageview.indexOf("app") !== -1 && window.isNativeApp) {
        //console.log(ReactNativeWebView)
        //alert("pageview is app - file to download " + path + "---" + window.isNativeApp);
        //window.parent.postMessage(path, "*");
        if (window.callWebView) {
          const filename = "catalog.pdf";
          const key = sessionStorage.getItem("apikey");
          var url = `${domain}/appproviderv3.aspx?action=downloadfile&key=${key}&file=${path}${
            filename !== "" ? "&filename=" + filename.replace(/[^a-z0-9 .]/gi, "_") : ""
          }`;
          const data = {
            pdf: url,
            fileName: "catalog"
          };
          // window.callWebView('{"pdf":"'+url+'"}');
          window.callWebView(JSON.stringify(data));
        }
        // window.webkit.messageHandlers.ReactNativeWebView.postMessage(path, "*")
        // if(window.ReactNativeWebView){
        //   window.ReactNativeWebView.postMessage(path, "*")
        // }
      } else {
        AppProvider.downloadCatalog({ file: path, filename });
        const isStrikeOffTrue = formFields.fields.some(
          field => field.fieldName === "Strikeoff" && field.fieldValue === "Yes"
        );
        if (window.flags.accountSpecific.tamarian.sendStrikeOffInOrdersheet && isStrikeOffTrue) {
          setTimeout(() => {
            handleOrdersheetFetch(true).then(path => {
              AppProvider.downloadCatalog({ file: path, filename });
            });
          }, [2000]);
        }
      }
    });
    // AppAnalytics.sendOrdersheetData({
    //   designPath: fullpath,
    //   mode: "save"
    // })
    //   .then(status => {
    //     //console.log("sendOrdersheetData mode:save -> status", status);
    //   })
    //   .catch(error => {
    //     console.log(" AppAnalytics OrdersheetData ~ error", error);
    //   });
  };
  const handleQualityChange = i => {
    setOrderSheetDetails({
      ...orderSheetDetails,
      OrderProperties: { ...orderSheetDetails.OrderProperties, QualityIndex: i }
    });
  };
  const handleStandardSizesChange = i => {
    if (parseFloat(i) === 0) return;

    let PhysicalWidth = standardSizes[i - 1].Width;
    let PhysicalHeight = standardSizes[i - 1].Height;
    let Unit = standardSizes[i - 1].UnitString.trim();
    if (Unit === "m") {
      PhysicalWidth = PhysicalWidth * 100;
      PhysicalHeight = PhysicalHeight * 100;
      Unit = "cm";
    }
    setRugsizeUpdated({ w: true, h: true });
    setOrderSheetDetails({ ...orderSheetDetails, PhysicalHeight, PhysicalWidth, Unit });
  };

  const _displayAreaOfRug = () => {
    return displayAreaOfRug(orderSheetDetails);
  };
  const displayUnitOfRug = () => {
    let displayUnit = orderSheetDetails.Unit;
    if (orderSheetDetails.Unit === "cm") displayUnit = "m";
    return displayUnit;
  };
  const pricePerUnit = () => {
    let priceStr = "";
    if (_displayAreaOfRug() > 0) {
      priceStr = `(${price.Currency}${(parseFloat(price.Value) / _displayAreaOfRug()).toFixed(
        2
      )}/ sq. ${displayUnitOfRug()})`;
    }
    return priceStr;
  };

  const rugQuality = () => {
    //TODO:implement rugqualities
    const { Width, Height, PhysicalWidth, PhysicalHeight, Unit } = designDetails;
    const [newwidth, newheight] = convertUnit_Arr(
      Unit,
      "cm",
      [PhysicalWidth, PhysicalHeight],
      setCustomConversion,
      customInToCmFactor
    );
    const knots = Math.round((Width / newwidth) * 10);
    const lines = Math.round((Height / newheight) * 10);
    const qualwithdist = ordersheetFlags.customRugQualities.map(item => ({
      ...item,
      distance: Math.sqrt(Math.pow(item.knots - knots, 2) + Math.pow(lines - item.lines, 2))
    }));
    const mindistqual = qualwithdist.reduce(
      (min, p) => (p.distance < min.distance ? p : min),
      qualwithdist[0]
    );
    return mindistqual ? mindistqual.quality : "";
  };

  const isFieldReadOnly = field => {
    if (!ordersheetFlags.readOnlyAutoPopulate) return false;
    return !!ordersheetFlags.autoPopulateFields.find(
      item => item.toLowerCase() === field.fieldName.toLowerCase()
    );
  };
  const handleClose = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: false });
  };
  const handleRugPhysicalSizeChange = () => {
    var height = parseFloat(rugSizeHeight);
    var width = parseFloat(rugSizeWidth);
    if (ordersheetFlags.showDoubleBoxForFtInch) {
      height = convertFeetInchToNumber(rugSizeHeightFt + `'` + rugSizeHeightInch + `"`, "ft");
      width = convertFeetInchToNumber(rugSizeWidthFt + `'` + rugSizeWidthInch + `"`, "ft");
    }
    dispatchDesignDetails({
      type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
      payload: {
        PhysicalHeight: height,
        PhysicalWidth: width
      }
    });
  };

  const allowResizeForSelectedRug = () => {
    var showRugSize = true;
    if (originalName && ordersheetFlags.designsToHideRugSizeFor.length) {
      showRugSize =
        ordersheetFlags.designsToHideRugSizeFor.indexOf(originalName) >= 0 ? false : true;
    }
    return showRugSize;
  };
  const handleWidthInputChange = PhysicalWidth => {
    if (PhysicalWidth && parseFloat(PhysicalWidth)) {
      setRugsizeUpdated({ ...rugsizeUpdated, w: true });
      //setShowRugSize(true);

      let PhysicalHeight = orderSheetDetails.PhysicalHeight;
      if (isWidHgtEqual()) {
        PhysicalHeight = PhysicalWidth;
      }
      setOrderSheetDetails({
        ...orderSheetDetails,
        PhysicalWidth,
        PhysicalHeight
      });
    } else {
      setRugsizeUpdated({ ...rugsizeUpdated, w: false });
    }
  };
  const handleHeightInputChange = PhysicalHeight => {
    if (PhysicalHeight && parseFloat(PhysicalHeight)) {
      setRugsizeUpdated({ ...rugsizeUpdated, h: true });
      // setShowRugSize(true);

      let PhysicalWidth = orderSheetDetails.PhysicalWidth;
      if (isWidHgtEqual()) {
        PhysicalWidth = PhysicalHeight;
      }
      setOrderSheetDetails({
        ...orderSheetDetails,
        PhysicalWidth,
        PhysicalHeight
      });
    } else {
      setRugsizeUpdated({ ...rugsizeUpdated, h: false });
    }
  };

  var {
    silkMaterialIndices,
    stocksilk
  } = flags.accountSpecific.priceEstimation_davisLandings.constants;
  var { variables } = flags.accountSpecific.priceEstimation_davisLandings;
  const RugTechniques = variables.RugTechniques.options;
  const rugTypes = variables.RugTypes.options;

  const [onlyPomBoxColors, setOnlyPomBoxColors] = useState([]);
  const [onlyPomChainColors, setOnlyPomChainColors] = useState([]);

  // const [RugType, setRugType] = useState("");
  // const [RugTechnique, setRugTechnique] = useState("");
  const [davisRugs, setDavisRugs] = useState({
    price: "",
    rugPadRate: "",
    totalRugPadPrice: ""
  });
  useEffect(() => {
    if (flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice) {
      var onlyPomBoxColors = DavisPriceEstimation.getOnlyPomboxColors({
        colorlist: [...colorListState.collections]
      });
      setOnlyPomBoxColors(onlyPomBoxColors);
      var onlyPomChainColors = DavisPriceEstimation.getOnlyPomChainColors({
        colorlist: [...colorListState.collections]
      });
      setOnlyPomChainColors(onlyPomChainColors);
    }
  }, []);

  const calculateTotalPrice_accountSpecific = (RugType, RugTechnique) => {
    console.log(
      "TCL: calculateTotalPrice_accountSpecific -> RugType, RugTechnique",
      RugType,
      RugTechnique
    );
    const area = Math.round(_displayAreaOfRug() * 100) / 100;

    if (!orderSheetDetails.OrderProperties) return "";
    const qualityindex = Number(orderSheetDetails.OrderProperties.QualityIndex);

    //change width, height for strikeoff

    if (DavisPriceEstimation.isSimilar(RugType, rugTypes[1])) {
      let PhysicalHeight = orderSheetDetails.PhysicalHeight;
      let PhysicalWidth = orderSheetDetails.PhysicalWidth;

      if (PhysicalHeight !== 1.5 && PhysicalWidth !== 1.5) {
        setShowRugSize(true);
        setOrderSheetDetails({
          ...orderSheetDetails,
          PhysicalWidth: 1.5,
          PhysicalHeight: 1.5
        });
      }
    }
    var { price, rugPadRate, totalRugPadPrice } = DavisPriceEstimation.getPrice_DavisRugs({
      designColors: designDetails.DesignColors,
      area,
      RugType,
      RugTypes: rugTypes,
      RugTechnique,
      RugTechniques,
      qualityindex,
      onlyPomBoxColors,
      onlyPomChainColors,
      stocksilk,
      silkMaterialIndices
    });
    const currency = flags.accountSpecific.priceEstimation_davisLandings.currency;
    return {
      price: `${currency} ${price}`,
      rugPadRate: `${currency} ${rugPadRate} per sq. ft.`,
      totalRugPadPrice: `${currency} ${totalRugPadPrice}`
    };
  };
  const onOptionChange = (RugTypeOpt, RugTechniqueOpt) => {
    let rugTecOption = RugTechniqueOpt;
    if (!rugTecOption) {
      rugTecOption = ordersheetDetailState.accountSpecific.davisRugs.rugTechnique;
    }
    setShowRugSize(true);
    if (flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice) {
      var { price, rugPadRate, totalRugPadPrice } = calculateTotalPrice_accountSpecific(
        RugTypeOpt,
        rugTecOption
      );
      if (uiState.allowPriceEstimation_Davisrugs) {
        setDavisRugs({ price, rugPadRate, totalRugPadPrice });
      }
    }
  };
  const isMandatory = fieldName => {
    let isMandatoryField = false;
    if (ordersheetFlags.mandatoryCustomFields.indexOf(fieldName) >= 0) {
      isMandatoryField = true;
    }
    return isMandatoryField;
  };
  const hasBlankMandatoryFields = () => {
    let warningMsg = "";

    const setToastProps = props => {
      dispatchUiState({ type: mainUiActions.SET_TOAST_PROPS, payload: props });
    };
    if (!flags.ordersheet.showRugSizeInOrderForm && (!rugsizeUpdated.w || !rugsizeUpdated.h)) {
      warningMsg = "Please enter rug size";
    } else {
      if (formFields.fields && formFields.fields.length) {
        formFields.fields.every((element, index) => {
          if (isMandatory(element.fieldName) && element.fieldValue === "") {
            warningMsg = `${"Please enter "} ${element.fieldName}`;
            return false;
          } else {
            return true;
          }
        });
      }
    }
    if (warningMsg !== "")
      setToastProps({
        message: warningMsg,
        intent: "warning"
      });

    if (warningMsg === "") return false;
    else return true;
  };
  const addWordCount = fieldName => {
    let wordCountText = "";
    if (
      ordersheetFlags.addMaxCharacterInFormFields &&
      ordersheetFlags.addMaxCharacterInFormFields.length
    ) {
      const selectedFormField = ordersheetFlags.addMaxCharacterInFormFields.filter(formfield => {
        return formfield.fieldName.toLowerCase() === fieldName.toLowerCase();
      });
      if (selectedFormField.length) {
        wordCountText = `(Maximum Characters: ${selectedFormField[0].count})`;
      }
    }
    return wordCountText;
  };
  const handleShapeOptionChange = i => {
    const fieldId = ordersheetFlags.hasShapeOptions.customFieldId;
    const selectedShapeValue = ordersheetFlags.hasShapeOptions.options[i];

    let fields = formFields.fields.map(field => {
      let fieldVal = field.fieldValue;
      if (field.fieldName.toLowerCase() === fieldId.toLowerCase()) {
        fieldVal = selectedShapeValue;
        return {
          fieldName: field.fieldName,
          fieldValue: fieldVal
        };
      } else {
        return {
          fieldName: field.fieldName,
          fieldValue: field.fieldValue
        };
      }
    });

    setFormFields({ ...formFields, fields });
    updateOrdersheetDetails();
    if (handleShapeChange) handleShapeChange(i);
  };
  const getSummarySizeText = () => {
    window.formFields = formFields;
    if (!orderSheetDetails || !orderSheetDetails.PhysicalWidth) return "";
    const { PhysicalHeight, PhysicalWidth, Unit } = orderSheetDetails;
    const widthString = convertNumberToFeetInch(PhysicalWidth, Unit);
    const heightString = ` x ${convertNumberToFeetInch(PhysicalHeight, Unit)}`;
    const combinedStr = `${widthString}${heightString}  ${Unit}`;
    const unit = Unit === "ft" ? "cm" : "ft";
    const [Width, Height] = convertUnit_Arr(
      orderSheetDetails.Unit,
      unit,
      [orderSheetDetails.PhysicalWidth, orderSheetDetails.PhysicalHeight],
      setCustomConversion,
      customInToCmFactor
    );
    const widthStr = convertNumberToFeetInch(Width, unit);
    const heightStr = ` x ${convertNumberToFeetInch(Height, unit)}`;

    const secondUnitStr = `${widthStr}${heightStr} ${unit}`;
    return `${combinedStr} / ${secondUnitStr}`;
  };
  const getSummaryShapeText = () => {
    const field = formFields["fields"].find(field => {
      return field.fieldName === ordersheetFlags.hasShapeOptions.customFieldId;
    });
    if (field) {
      return field.fieldValue || "";
    } else {
      return "";
    }
  };
  return (
    <div
      id="at-ordersheet-area"
      style={{
        maxHeight: "100vh",
        overflow: "auto"
      }}
    >
      <OrdersheetPreview
        {...props}
        fullpath={fullpath}
        orderSheetDetails={orderSheetDetails}
        formFields={formFields.fields}
        isOpen={showPreview}
        ordersheetPath={`${AppProvider.domain}${ordersheetPath}`}
        onClose={() => setShowPreview(false)}
        saveOrdersheet={handleSaveOrdersheet}
      />
      <FadeIn childClassName="at-ordersheet__container">
        <>
          <SectionHeading
            className="at-section-heading-area at-ordersheet__title"
            title={strings.ordersheet.orderSheet}
            additional={
              showOrdersheetOutside() ? (
                <AtButton
                  text={isCollapsed ? strings.tools.showMore : strings.tools.showLess}
                  minimal
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
              ) : (
                <></>
              )
            }
          />
          <div
            className={classNames("at-ordersheet__content", {
              collapsed: isCollapsed,
              outside: showOrdersheetOutside()
            })}
          >
            {!hideSizeDetails && (
              <>
                <div className="at-section-wrapper">
                  <H5 id="at-ordersheet-designName">
                    {flags.designDetailSection.showDesignPathInTitle
                      ? getDesignPathInTitle(fullpath)
                      : renderDesignName(designName)}
                  </H5>

                  {showPrice && parseFloat(price.Value) > 0 && (
                    <div className="at-ordersheet-price-area">
                      {price.Value.lastIndexOf("%") >= 0 ? (
                        <>
                          <H3>
                            {price.Value} {flags.ordersheet.strInPrice}
                          </H3>
                        </>
                      ) : (
                        <>
                          <H3>
                            {price.Currency}
                            {parseFloat(price.Value)}
                          </H3>
                          <H5 className="at-ordersheet-pua">{pricePerUnit()}</H5>
                        </>
                      )}
                    </div>
                  )}
                  <div className="at-ordersheet-dims-area">
                    <div
                      className="at-units-input at-units-input-field"
                      id="at-ordersheet-input-width"
                    >
                      <p>
                        {`${strings.ordersheet.width} ${
                          ordersheetFlags.isRugsizeMandatory ? "*" : ""
                        }`}
                      </p>

                      {hasWidthFilter(collectionFilter) ? (
                        <AtDropdown
                          dropdownItems={widthOptions}
                          defaultItemIndex={selectedWidOption}
                          onChange={i =>
                            handleWidthOptionChange(i, orderSheetDetails, widthOptions)
                          }
                          addPlaceholder={
                            window.flags.colorSelectionBox.addPlaceholderinWidthOptions
                          }
                          placeholderText={
                            window.flags.colorSelectionBox.placeholderTextinWidthOptions
                          }
                        />
                      ) : (
                        <EditableOrdersheetField
                          value={showRugSize ? orderSheetDetails.PhysicalWidth : ""}
                          unit={orderSheetDetails.Unit}
                          handleConfirm={PhysicalWidth => {
                            handleWidthInputChange(PhysicalWidth);
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="at-units-input at-units-input-field"
                      id="at-ordersheet-input-height"
                    >
                      <p>
                        {`${strings.ordersheet.height} ${
                          ordersheetFlags.isRugsizeMandatory ? "*" : ""
                        }`}
                      </p>

                      <EditableOrdersheetField
                        value={showRugSize ? orderSheetDetails.PhysicalHeight : ""}
                        unit={orderSheetDetails.Unit}
                        handleConfirm={PhysicalHeight => {
                          handleHeightInputChange(PhysicalHeight);
                        }}
                      />
                    </div>
                    <div
                      className="at-units-input at-units-input-dropdown"
                      id="at-ordersheet-input-unit"
                    >
                      <p>{strings.ordersheet.unit}</p>
                      {flags.ordersheet.changeableUnits ? (
                        <AtDropdown
                          dropdownItems={units.map(item => item.text)}
                          defaultItemIndex={units.findIndex(
                            item => item.val === orderSheetDetails.Unit
                          )}
                          onChange={i => handleUnitChange(units[i].val)}
                        />
                      ) : (
                        <div className="at-units-input__unit">
                          {units.find(item => item.val === flags.defaultUnit).text}
                        </div>
                      )}
                    </div>
                    <div
                      className="at-units-input at-units-input-dropdown"
                      id="at-ordersheet-input-quality"
                    >
                      <p>{strings.ordersheet.quality}</p>
                      {(() => {
                        if (ordersheetFlags.customRugQualities.length) {
                          return <p className="at-units-input__qualitytext">{rugQuality()}</p>;
                        } else if (ordersheetFlags.specificKnotForSpecificFolder.length != 0) {
                          return (
                            <>
                              {state.selectedFile && (
                                <>
                                  {selectedKnot.length == 1 ? (
                                    <p id="selectedKnotText">
                                      {window.InterfaceElements.AvailableQualities[selectedKnot]}
                                    </p>
                                  ) : (
                                    <AtDropdown
                                      dropdownItems={window.InterfaceElements.AvailableQualities.filter(
                                        (x, index) => selectedKnot.includes(index)
                                      )}
                                      onChange={handleQualityChange}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          );
                        } else {
                          return (
                            <AtDropdown
                              dropdownItems={rugQualities}
                              onChange={handleQualityChange}
                              defaultItemIndex={defaultQualityIndex}
                            />
                          );
                        }
                      })()}
                    </div>
                    {ordersheetFlags.hasShapeOptions &&
                      ordersheetFlags.hasShapeOptions.options &&
                      ordersheetFlags.hasShapeOptions.options.length && (
                        <div
                          className="at-units-input at-units-input-dropdown"
                          id="at-ordersheet-input-shape"
                        >
                          <p>{strings.ordersheet.shape}</p>
                          <AtDropdown
                            dropdownItems={ordersheetFlags.hasShapeOptions.options}
                            onChange={handleShapeOptionChange}
                            defaultItemIndex={ordersheetFlags.hasShapeOptions.defaultOptionIndex}
                          />
                        </div>
                      )}
                  </div>
                  {ordersheetFlags.changeActualRugSize && allowResizeForSelectedRug() && (
                    <div className={"at-ordersheet-rugresize-area"}>
                      <div
                        className="at-units-input at-units-input-field"
                        id="at-ordersheet-rugResize-input-width"
                      >
                        <p>{strings.ordersheet.width}</p>
                        {!ordersheetFlags.showDoubleBoxForFtInch ? (
                          <EditableOrdersheetField
                            value={rugSizeWidth}
                            unit={orderSheetDetails.Unit}
                            handleConfirm={PhysicalWidth => {
                              if (ordersheetFlags.maxWidth != null) {
                                if (PhysicalWidth > ordersheetFlags.maxWidth) {
                                  setDisplayMaxErrorMessage(
                                    `Width should not exceed ${ordersheetFlags.maxWidth}${orderSheetDetails.Unit}`
                                  );
                                  setRugSizeWidth(designDimsOrig.PhysicalWidth);
                                  setToastProps(true);
                                } else {
                                  setRugSizeWidth(PhysicalWidth);
                                  setDisplayMaxErrorMessage("");
                                }
                              } else if (PhysicalWidth && parseFloat(PhysicalWidth)) {
                                setRugSizeWidth(PhysicalWidth);
                              }
                            }}
                          />
                        ) : (
                          <div className="ordersheetDivForFtAndInch">
                            <div className="ordersheetSecondDivForFtAndInch">
                              <EditableText
                                selectAllOnFocus
                                value={rugSizeWidthFt}
                                onChange={setRugSizeWidthFt}
                                grow={false}
                                minWidth={25}
                                className="ordersheetBoxForFtAndInch"
                                onConfirm={val => {
                                  if (ordersheetFlags.maxWidth && val >= ordersheetFlags.maxWidth) {
                                    setRugSizeWidthFt(ordersheetFlags.maxWidth);
                                    setRugSizeWidthInch(0);
                                    setRugSizeWidth(parseFloat(ordersheetFlags.maxWidth));
                                    setDisplayMaxErrorMessage(
                                      `Width should not exceed ${ordersheetFlags.maxWidth}${orderSheetDetails.Unit}`
                                    );
                                    setToastProps(true);
                                  } else {
                                    setRugSizeWidthFt(val);
                                    setRugSizeWidth(parseFloat(val + "." + rugSizeWidthInch));
                                    setDisplayMaxErrorMessage("");
                                  }
                                }}
                              />
                              ft
                            </div>
                            <div className="ordersheetSecondDivForFtAndInch">
                              <EditableText
                                selectAllOnFocus
                                value={rugSizeWidthInch}
                                onChange={setRugSizeWidthInch}
                                grow={false}
                                minWidth={25}
                                className="ordersheetBoxForFtAndInch"
                                onConfirm={val => {
                                  if (ordersheetFlags.maxWidth) {
                                    if (rugSizeWidthFt == ordersheetFlags.maxWidth) {
                                      setRugSizeWidthInch(0);
                                      setRugSizeWidth(parseFloat(rugSizeWidthFt + "." + 0));
                                      setDisplayMaxErrorMessage(
                                        `Width should not exceed ${ordersheetFlags.maxWidth}${orderSheetDetails.Unit}`
                                      );
                                    } else if (val >= 12) {
                                      setRugSizeWidthInch(11);
                                      setRugSizeWidth(
                                        convertFeetInchToNumber(
                                          rugSizeWidthFt + `'` + 11 + `"`,
                                          "ft"
                                        )
                                      );
                                      setDisplayMaxErrorMessage(`Inch should be less than 12`);
                                    } else {
                                      setRugSizeWidthInch(val);
                                      setRugSizeWidth(parseFloat(rugSizeWidthFt + "." + val));
                                      setDisplayMaxErrorMessage("");
                                    }
                                  } else {
                                    if (val >= 12) {
                                      setRugSizeWidthInch(11);
                                      setRugSizeWidth(
                                        convertFeetInchToNumber(
                                          rugSizeWidthFt + `'` + 11 + `"`,
                                          "ft"
                                        )
                                      );
                                      setDisplayMaxErrorMessage(`Inch should be less than 12`);
                                    } else {
                                      setRugSizeWidthInch(val);
                                      setRugSizeWidth(parseFloat(rugSizeWidthFt + "." + val));
                                    }
                                  }
                                  setToastProps(true);
                                }}
                              />
                              in
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="at-units-input at-units-input-field"
                        id="at-ordersheet-rugResize-input-height"
                      >
                        <p>{strings.ordersheet.height}</p>
                        {!ordersheetFlags.showDoubleBoxForFtInch ? (
                          <EditableOrdersheetField
                            value={rugSizeHeight}
                            unit={orderSheetDetails.Unit}
                            handleConfirm={PhysicalHeight => {
                              if (ordersheetFlags.maxLength != null) {
                                if (PhysicalHeight > ordersheetFlags.maxLength) {
                                  setDisplayMaxErrorMessage(
                                    `Length should not exceed ${ordersheetFlags.maxLength}${orderSheetDetails.Unit}`
                                  );
                                  setRugSizeHeight(designDimsOrig.PhysicalHeight);
                                  setToastProps(true);
                                } else {
                                  setRugSizeHeight(PhysicalHeight);
                                  setDisplayMaxErrorMessage("");
                                }
                              } else if (PhysicalHeight && parseFloat(PhysicalHeight)) {
                                setRugSizeHeight(PhysicalHeight);
                              }
                            }}
                          />
                        ) : (
                          <div className="ordersheetDivForFtAndInch">
                            <div className="ordersheetSecondDivForFtAndInch">
                              <EditableText
                                selectAllOnFocus
                                value={rugSizeHeightFt}
                                onChange={setRugSizeHeightFt}
                                grow={false}
                                minWidth={25}
                                className="ordersheetBoxForFtAndInch"
                                onConfirm={val => {
                                  if (
                                    ordersheetFlags.maxLength &&
                                    val >= ordersheetFlags.maxLength
                                  ) {
                                    setRugSizeHeightFt(ordersheetFlags.maxLength);
                                    setRugSizeHeightInch(0);
                                    setRugSizeHeight(parseFloat(ordersheetFlags.maxLength));
                                    setDisplayMaxErrorMessage(
                                      `Length should not exceed ${ordersheetFlags.maxLength}${orderSheetDetails.Unit}`
                                    );
                                    setToastProps(true);
                                  } else {
                                    setRugSizeHeightFt(val);
                                    setRugSizeHeight(parseFloat(val + "." + rugSizeHeightInch));
                                    setDisplayMaxErrorMessage("");
                                  }
                                }}
                              />
                              ft
                            </div>
                            <div className="ordersheetSecondDivForFtAndInch">
                              <EditableText
                                selectAllOnFocus
                                value={rugSizeHeightInch}
                                onChange={setRugSizeHeightInch}
                                grow={false}
                                minWidth={25}
                                className="ordersheetBoxForFtAndInch"
                                onConfirm={val => {
                                  if (ordersheetFlags.maxLength) {
                                    if (rugSizeHeightFt == ordersheetFlags.maxLength) {
                                      setRugSizeHeightInch(0);
                                      setRugSizeHeight(parseFloat(rugSizeHeightFt + "." + 0));
                                      setDisplayMaxErrorMessage(
                                        `Length should not exceed ${ordersheetFlags.maxLength}${orderSheetDetails.Unit}`
                                      );
                                    } else if (val >= 12) {
                                      setRugSizeHeightInch(11);
                                      setRugSizeHeight(
                                        convertFeetInchToNumber(
                                          rugSizeHeightFt + `'` + 11 + `"`,
                                          "ft"
                                        )
                                      );
                                      setDisplayMaxErrorMessage(`Inch should be less than 12`);
                                    } else {
                                      setRugSizeHeightInch(val);
                                      setRugSizeHeight(
                                        convertFeetInchToNumber(
                                          rugSizeHeightFt + `'` + val + `"`,
                                          "ft"
                                        )
                                      );
                                      setDisplayMaxErrorMessage("");
                                    }
                                  } else {
                                    setRugSizeHeightInch(val);
                                    setRugSizeHeight(parseFloat(rugSizeHeightFt + "." + val));
                                  }
                                  setToastProps(true);
                                }}
                              />
                              in
                            </div>
                          </div>
                        )}
                      </div>

                      <AtButton
                        id="rugresize__apply"
                        intent="primary"
                        tertiary
                        onClick={handleRugPhysicalSizeChange}
                        text={strings.tools.apply}
                      />
                      <Divider className="at-ordersheet-divider-rugresize" horizontal />
                    </div>
                  )}
                  {standardSizeArray.length > 0 && (
                    <div
                      className="at-ordersheet__wrapper at-units-input"
                      id="at-ordersheet-input-standardsize"
                    >
                      <p>{strings.ordersheet.standardSizes}</p>
                      <AtDropdown
                        dropdownItems={standardSizeArray}
                        onChange={handleStandardSizesChange}
                        defaultItemIndex={defaultStandardSize}
                      />
                    </div>
                  )}

                  {flags.ordersheet.showRugSizeInOrderForm ? (
                    <div className="at-ordersheet-dims-totalarea">
                      {strings.ordersheet.totalArea} :{" "}
                      {_displayAreaOfRug() > 0 &&
                        (flags.ordersheet.populateRugSize ||
                          showRugSize ||
                          (rugsizeUpdated.w && rugsizeUpdated.h)) && (
                          <span
                            className="at-ordersheet-dims-totalarea__value"
                            dangerouslySetInnerHTML={{
                              __html: calculateTotalArea(orderSheetDetails)
                            }}
                          ></span>
                        )}
                      {ordersheetFlags.hasMinAreaCriteria > 0 &&
                        displayAreaInFt(
                          orderSheetDetails,
                          setCustomConversion,
                          customInToCmFactor
                        ) <= ordersheetFlags.hasMinAreaCriteria && (
                          <div className="at-ordersheet-area-message">
                            {ordersheetFlags.minAreaCriteriaMsg}
                          </div>
                        )}
                      {(ordersheetFlags.maxWidth != null || ordersheetFlags.maxLength != null) &&
                        displayMaxErrorMessage && (
                          <div className="at-ordersheet-max-area-message">
                            {/* {displayMaxErrorMessage} */}
                            <Toaster position="top-right">
                              {toastProps && (
                                <Toast
                                  message={displayMaxErrorMessage}
                                  intent="danger"
                                  onDismiss={() => setToastProps(false)}
                                />
                              )}
                            </Toaster>
                          </div>
                        )}
                    </div>
                  ) : null}
                  {flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice &&
                    uiState.allowPriceEstimation_Davisrugs &&
                    rugsizeUpdated.w &&
                    rugsizeUpdated.h && (
                      <div className="at-ordersheet-davisrugs-customize">
                        {strings.ordersheet.rugPrice} :{" "}
                        <span className="at-ordersheet-dims-rugPrice__value">
                          {davisRugs.price}
                        </span>
                        <br />
                        {strings.ordersheet.rugPadPrice} :{" "}
                        <span className="at-ordersheet-dims-rugPadPrice__value">
                          {davisRugs.rugPadRate}
                        </span>
                        <br />
                        {strings.ordersheet.rugWithPadPrice} :{" "}
                        <span className="at-ordersheet-dims-rugPriceWithPad__value">
                          {davisRugs.totalRugPadPrice}
                        </span>
                        <br />
                      </div>
                    )}
                </div>

                <Divider className="at-ordersheet-divider-wrapper" horizontal />
              </>
            )}
            {flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice && (
              <div className="at-ordersheet-accountSpecific-options">
                {/* <DavisRugsOrderOptions
                  onOptionChange={onOptionChange}
                  showRugTechniques={false}
                  showRugTypes={true}
                /> */}
                <RugTypes onOptionChange={onOptionChange} />
              </div>
            )}
            <div className="ordersheet-info-wrapper">
              <div className="at-section-wrapper">
                {ordersheetFlags.addedCustomFields.length > 0 && (
                  <AddedCustomFields formFields={formFields} setFormFields={setFormFields} />
                )}
                {formFields.fields.map((formField, i) => (
                  <div
                    className="bp3-form-group"
                    id={`at_ordersheet_${convertfieldNameToID(formField.fieldName, " ", "_")}`}
                    key={i}
                  >
                    <label className="bp3-label" htmlFor="form-group-input">
                      {formField.fieldName} {isMandatory(formField.fieldName) ? "*" : ""}{" "}
                      {addWordCount(formField.fieldName)}
                    </label>
                    <div className="bp3-form-content">
                      <InputGroup
                        readOnly={isFieldReadOnly(formField)}
                        value={formField.fieldValue}
                        autoComplete="on"
                        onChange={e => {
                          const fieldCp = [...formFields.fields];
                          fieldCp[i].fieldValue = e.target.value;
                          if (
                            ordersheetFlags.storeFieldsValueInLocal.length &&
                            ordersheetFlags.storeFieldsValueInLocal.indexOf(
                              fieldCp[i].fieldName.toLowerCase()
                            ) >= 0
                          ) {
                            localStorage.setItem(
                              fieldCp[i].fieldName.toLowerCase(),
                              fieldCp[i].fieldValue
                            );
                          }

                          setFormFields({ ...formFields, fields: fieldCp });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="at-ordersheet__wrapper at-ordersheet-infotext">
                <span dangerouslySetInnerHTML={{ __html: strings.ordersheet.infoText }}></span>
              </div>
              {emailInfo && emailInfo.message !== "" && (
                <div
                  className={`bp3-callout bp3-intent-${emailInfo.intent} at-ordersheet-form-area-message `}
                >
                  {emailInfo.message}
                </div>
              )}
            </div>
            {ordersheetFlags.showSummary &&
              orderSheetDetails &&
              rugsizeUpdated.w &&
              rugsizeUpdated.h && (
                <div className="at-summary">
                  <div className="at-summary-content">
                    <div className="at-summary-title">Size</div>
                    <div>{getSummarySizeText()}</div>
                  </div>

                  <div className="at-summary-content">
                    <div className="at-summary-title">Shape</div>
                    <div>{getSummaryShapeText()}</div>
                  </div>

                  <div className="at-summary-content">
                    <div className="at-summary-title">Quality</div>
                    <div>
                      {/* {rugQualities[Number(orderSheetDetails.OrderProperties.QualityIndex)]} */}
                      {getFolderNameFromFullpath(fullpath)}
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="at-ordersheet-buttons-area">
            {window.flags.showAddToCart && (
              <AtButton
                tertiary={window.sessionStorage.getItem("mode") === "stripedesign"}
                intent="primary"
                onClick={() => {
                  if (hasBlankMandatoryFields()) return;
                  props.handleAddtoCart();
                }}
                text={strings.tools.addToCart}
              ></AtButton>
            )}
            {window.flags.ordersheet.sendEmailDirectlyWithoutRequestQuote ? (
              <AtButton
                id="btnsendEmailDirectly"
                intent="primary"
                onClick={handleSendEmailDirectly}
              >
                <AtSpinner
                  size="xs"
                  className={(previewLoading ? "show " : "") + "signing-spinner "}
                />
                <H6 className={(previewLoading ? "shift-right " : "") + "signin-span-text "}>
                  {strings.ordersheet.sendEmail}
                </H6>
              </AtButton>
            ) : (
              <AtButton id="btnRequestQuote" intent="primary" onClick={handleRequestQuote}>
                <AtSpinner
                  size="xs"
                  className={(previewLoading ? "show " : "") + "signing-spinner "}
                />
                <H6 className={(previewLoading ? "shift-right " : "") + "signin-span-text "}>
                  {strings.ordersheet.requestQuote}
                </H6>
              </AtButton>
            )}

            {window.flags.ordersheet.showSendtoPrint && (
              <AtButton
                id="btnPrintOrdersheetPdf"
                intent="primary"
                onClick={handlePrintOrdersheetPDF}
              >
                <AtSpinner
                  size="xs"
                  className={(previewLoading ? "show " : "") + "signing-spinner "}
                />
                <H6 className={(previewLoading ? "shift-right " : "") + "signin-span-text "}>
                  {"Print Ordersheet"}
                </H6>
              </AtButton>
            )}

            {/* <AtButton id="btnRequestQuote" intent="primary" onClick={handleRequestQuote}>
              <AtSpinner
                size="xs"
                className={(previewLoading ? "show " : "") + "signing-spinner "}
              />
              <H6 className={(previewLoading ? "shift-right " : "") + "signin-span-text "}>
                {strings.ordersheet.requestQuote}
              </H6>
            </AtButton> */}
            {window.InterfaceElements.ShowOrderSheetSave ? (
              <AtButton
                id="btnSaveOrdersheet"
                intent="primary"
                tertiary
                onClick={handleSaveOrdersheet}
                text={strings.ordersheet.saveOrdersheet}
              />
            ) : null}
            {ordersheetFlags.showSaveImageInsideOrdersheet && (
              <AtButton
                id="btnshowOrdersheet"
                intent="primary"
                tertiary
                onClick={props.handleDownloadImage}
                text={strings.ordersheet.saveImage}
              />
            )}
            {showClose && (
              <AtButton
                id="at_ordersheet_buttons_cancel"
                intent="primary"
                tertiary
                onClick={handleClose}
                text={strings.tools.cancel}
              />
            )}
          </div>
        </>
      </FadeIn>
    </div>
  );
};

const EditableOrdersheetField = props => {
  const { value, unit, handleConfirm } = props;
  const [valueAsString, setValueAsString] = useState(null);
  const [changeState, setChangeState] = useState(false);
  useEffect(() => {
    value === "" ? setValueAsString("") : setValueAsString(convertNumberToFeetInch(value, unit));
  }, [value, unit, changeState]);

  return (
    <EditableText
      selectAllOnFocus
      value={valueAsString}
      onChange={setValueAsString}
      onConfirm={val => {
        if (window.flags.ordersheet.showDoubleBoxForFtInch) {
          setChangeState(!changeState); //done to reload state
        }
        const n = convertFeetInchToNumber(val, unit);
        if (handleConfirm) handleConfirm(n);
      }}
    ></EditableText>
  );
};
OrderSheet.propTypes = {
  isOpen: PropTypes.bool
};
export default OrderSheet;
