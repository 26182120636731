import LocalizedStrings from "react-localization";
import deepmerge from "deepmerge";

import english from "./languages/english.json";
import spanish from "./languages/spanish.json";
import french from "./languages/french.json";
import german from "./languages/german.json";
import italian from "./languages/italian.json";

const defaultStrings = {
  ...english,
  ...french,
  ...spanish,
  ...german,
  ...italian
};

const strings = new LocalizedStrings(defaultStrings);
export const initializeStrings = customStrings => {
  const obj = defaultStrings;
  let newObj = {};
  const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
  // newObj = deepmerge(defaultStrings, customStrings, { arrayMerge: overwriteMerge });
  let flag = false;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (customStrings.hasOwnProperty(key)) {
        flag = true;
      }
    }
  }
  if (!flag) {
    newObj = deepmerge(obj, { en: customStrings }, { arrayMerge: overwriteMerge });
  } else {
    newObj = deepmerge(obj, customStrings, { arrayMerge: overwriteMerge });
  }
  strings.setContent(newObj);
  //console.log("TCL: newObj", JSON.stringify(defaultStrings));
};
export default strings;
