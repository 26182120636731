import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { imgMutedBtn, imgVolumeBtn } from "./constants";
import classNames from "classnames";

const trackstyles = css`
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 0px;
  border: 0;
  background: ${props =>
    `linear-gradient(to right, var(--brand3) 0%, var(--brand3) ${props.trackStyle}%,  var(--text-primary-muted) ${props.trackStyle}% , var(--text-primary-muted) 100%)`};
`;
const thumbStyles = css`
  border: 0;
  -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: -7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--text-primary-muted);
  position: absolute;
  left: calc(${props => props.trackStyle}% - 8px);
`;
const Input = styled.input`
  position: relative;
  height: 0.2rem;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0.5rem 1rem;
  width: calc(100% - 2rem);
  background: transparent;
  width: calc(100% - 3.5rem);
  margin: 0.9rem 1rem 0.9rem 2.5rem;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    ${trackstyles}
  }
  &::-moz-range-track {
    ${trackstyles}
  }
  &::-ms-track {
    ${trackstyles}
  }
  &::-webkit-slider-thumb {
    ${thumbStyles}
  }
  &::-moz-range-thumb {
    ${thumbStyles}
    visibility: hidden;
  }
  &::-ms-thumb {
    ${thumbStyles}
  }
`;

const Imgdiv = styled.div`
  position: absolute;
  margin: 0.25rem 0.5rem;
`;

const VolumeSlider = props => {
  const {
    sliderVal,
    minVal,
    maxVal,
    step,
    handleSliderChange,
    showVolumeSlider
  } = props;
  const initialTrackStyle = ((sliderVal - minVal) * 100) / (maxVal - minVal);
  const [trackStyleVal, setTrackStyleVal] = useState(initialTrackStyle);

  useEffect(() => {
    return () => {};
  }, [trackStyleVal]);

  const handleSlider = e => {
    let curVal = e.target.value;
    handleSliderChange(curVal);
    setTrackStyleVal(((curVal - minVal) * 100) / (maxVal - minVal));
  };
  return (
    <div
      className={classNames("at-volume-slider", {
        hidden: !showVolumeSlider
      })}
    >
      <Imgdiv>
        <img src={trackStyleVal === 0 ? imgMutedBtn : imgVolumeBtn} alt=""></img>
      </Imgdiv>
      <Input
        type="range"
        min={minVal}
        max={maxVal}
        step={step}
        value={sliderVal}
        onChange={handleSlider}
        trackStyle={trackStyleVal}
      />
    </div>
  );
};

export default VolumeSlider;
