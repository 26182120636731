import React from "react";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { ButtonGroup, MenuItem, Menu, Popover } from "@blueprintjs/core";
import { getFromSessionStorage } from "../../../utils/domUtils";
import AtButton from "../../atoms/AtButton";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import AppProvider from "../../../api/appProvider";
import { useDispatchColorList, colorListActions } from "../../../reducers/colors.reducer";
import strings from "../../../strings";
import classNames from "classnames";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import { getAllFiles } from "../../../utils/treeutils";
import FavoriteButton from "./FavoriteButton";
import DownloadImageButton from "./DownloadImageButton";
import OrdersheetButton from "../../templates/OrderSheet/OrdersheetBtn";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";

const clearCacheModes = {
  DESIGNS: "designs",
  ALLDESIGNS: "alldesigns",
  OTHER: "other",
  TEXTURE: "texture",
  COLOR: "color",
  ROOMS: "rooms",
  ALLROOMS: "allrooms"
};
const DesignDetailControls = props => {
  const {
    handleRugShare,
    handleFavorite,
    handleDownloadImage,
    parentID = "at-des-details__title"
  } = props;
  const { flags, InterfaceElements } = window;
  const designDetailState = useDesignDetailState();
  const designListState = useDesignListState();
  const dispatchUiState = useUiDispatch();
  const dispatchColorListState = useDispatchColorList();
  const dispatchDesignDetailState = useDispatchDesignDetail();
  const { fullpath } = designDetailState;

  let langOptions = flags.language.langOptions;
  let langFromUrl = getFromSessionStorage("lang");

  let defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : "en";
  if (langOptions && langOptions.length) {
    defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : langOptions[0].val || null;
    if (!defLang) {
      defLang = "en";
      langOptions = [];
      window.flags.language.langOptions = [];
    }
  }
  const rugshareCondition = InterfaceElements.IsAdmin
    ? InterfaceElements.ShowRugshare
    : InterfaceElements.ShowRugshare && flags.hasRugshare;

  const handleClearCache = mode => {
    let files;
    if (mode === clearCacheModes.DESIGNS) {
      files = [fullpath];
      const { similarDesignsFolder } = designListState;
      if (similarDesignsFolder.files.length) {
        const paths = similarDesignsFolder.files.map(item => item.fullPath);
        files.push(...paths);
      }
      if (similarDesignsFolder.children) {
        const similarFiles = getAllFiles(similarDesignsFolder.children) || [];
        files.push(...similarFiles.map(item => item.fullPath));
      }
    }
    //console.log("files", files);
    AppProvider.clearCache({ mode, files }).then(async () => {
      switch (mode) {
        case clearCacheModes.COLOR:
          const colors = await AppProvider.fetchColorList({});
          dispatchColorListState({ type: colorListActions.SET_COLORS, payload: colors });
          break;
        case clearCacheModes.DESIGNS:
          const newDesignDetails = await AppProvider.getDesignThumbnails({
            designs: [{ fullPath: fullpath }]
          });
          dispatchDesignDetailState({
            type: designDetailActions.SET_DETAILS,
            payload: {
              ...newDesignDetails[0],
              label: designDetailState.designName,
              designDetails: newDesignDetails[0].designProps
            }
          });
          break;
        case clearCacheModes.TEXTURE:
          await AppProvider.clearCache({ mode: clearCacheModes.ALLDESIGNS });
          break;
        default:
          break;
      }
      dispatchUiState({
        type: mainUiActions.SET_TOAST_PROPS,
        payload: { message: `Cache Cleared for ${mode}`, intent: "success" }
      });
    });
  };
  const handleQuickShare = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_QUICKLINK_DIALOG, payload: true });
  };
  const hasLangOptions = flags.language.langOptions.length && flags.language.langOptions[0] !== "";

  return (
    <>
      <ButtonGroup
        className={classNames(`${parentID}${"__controls"}`, {
          fullWidth: hasLangOptions
        })}
      >
        {window.InterfaceElements.IsAdmin && (
          <Popover
            minimal
            position="bottom-left"
            portalContainer={document.getElementById("app-main")}
            interactionKind="hover"
            content={
              <Menu>
                <MenuItem
                  text={strings.tools.clearCacheThisDesign}
                  onClick={() => handleClearCache(clearCacheModes.DESIGNS)}
                />
                <MenuItem
                  text={strings.tools.clearCacheColor}
                  onClick={() => handleClearCache(clearCacheModes.COLOR)}
                />
                <MenuItem
                  text={strings.tools.clearCacheOther}
                  onClick={() => handleClearCache(clearCacheModes.OTHER)}
                />
              </Menu>
            }
          >
            <AtButton minimal tertiary icon="tools" />
          </Popover>
        )}
        {flags.ordersheet.showOrdersheetInNavbar && <OrdersheetButton />}
        <FavoriteButton
          handleFavorite={handleFavorite}
          buttonID={`${parentID}${"-favorite"}`}
        ></FavoriteButton>

        <DownloadImageButton
          handleDownloadImage={handleDownloadImage}
          buttonID={`${parentID}${"-saveImageIcon"}`}
          separateDownloadIconPath={window.flags.separateDownloadIconPath || ""}
        ></DownloadImageButton>

        {rugshareCondition && (
          <AtButton
            minimal
            title={strings.tools.publishOnline}
            intent={null}
            icon="share"
            onClick={handleRugShare}
          ></AtButton>
        )}
        {flaginCurrentMode(
          window.InterfaceElements.IsAdmin,
          window.flags.rugshare.showQuickLinkModes
        ) &&
          window.InterfaceElements.ShowRugshare && (
            <AtButton
              minimal
              title={strings.tools.quickShare}
              intent={null}
              icon="linked"
              onClick={handleQuickShare}
            />
          )}
        {window.InterfaceElements.IsAdmin && (
          <>
            <AtButton
              minimal
              title={strings.tools.fileManager}
              intent={null}
              icon="folder-closed"
              onClick={() => AppProvider.openFileManager()}
            />
          </>
        )}
      </ButtonGroup>
    </>
  );
};
export default DesignDetailControls;
