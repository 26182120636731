import React from "react";
import { RadioGroup } from "@blueprintjs/core";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "./checked-circle.svg";
import { ReactComponent as UncheckIcon } from "./unchecked-circle.svg";

const AtRadioButton = props => {
  const { radioLabel = "", checked, inline, name = "", value = "", onClick } = props;

  const handleClick = e => {
    onClick(e);
  };
  return (
    <label className={classNames("at-radio", { "bp3-inline": inline })}>
      <input type="radio" name={name} value={value} onClick={handleClick}></input>
      <span className="bp3-control-indicator">
        {checked ? (
          <CheckIcon
            className="radio-icon--checked"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              verticalAlign: "middle",
              marginRight: "0.5rem"
            }}
          />
        ) : (
          <UncheckIcon
            className="radio-icon"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              verticalAlign: "middle",
              marginRight: "0.5rem"
            }}
          />
        )}
      </span>
      {radioLabel}
    </label>
  );
};

const AtRadio = props => {
  const {
    groupLabel = "",
    onChange,
    selectedValue = 0,
    radioLabels = [],
    radioValues = [],
    inline,
    name = ""
  } = props;
  if (radioLabels.length !== radioValues.length) {
    const maxLength = Math.max(radioLabels.length, radioValues.length);
    for (var i = 0; i <= maxLength; i++) {
      radioLabels[i] = radioLabels[i] ? radioLabels[i] : "";
      radioValues[i] = radioValues[i] ? radioValues[i] : "";
    }
  }
  return (
    <RadioGroup
      className="at-radiogroup"
      label={groupLabel}
      onChange={onChange}
      selectedValue={selectedValue}
    >
      {radioLabels.length &&
        radioLabels.map((radioLabel, index) => (
          <AtRadioButton
            key={index}
            radioLabel={radioLabel}
            checked={selectedValue === radioLabel}
            inline={inline}
            name={name}
            value={radioValues[index]}
            onClick={onChange}
          />
        ))}
    </RadioGroup>
  );
};

AtRadio.propTypes = {};

export default AtRadio;
