import React from "react";
import AtIcon from "../../atoms/AtIcon";
import CircularColorSVG from "../../atoms/CircularColorSVG";
import Mixply1 from "../../atoms/CircularColorSVG/Mixply1";
import { Popover, Menu, MenuItem, Icon } from "@blueprintjs/core";
import {
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { getMaterialName } from "./index";
export const NestedAreaSwatch = props => {
  const { className, colorIndex, handleClick, style, designColor, colorNumber, yarnIndex } = props;
  const dispatchDesignDetails = useDispatchDesignDetail();
  const currentYarn = designColor.YarnDetails[yarnIndex];
  const { colorAreaSwatch: casFlags } = window.flags;
  const { DefaultTextureVisible, DefaultTextureNames } = window.TextureOptions;
  const handleyarnMaterialChange = ({ material, materialName }) => {
    dispatchDesignDetails({
      type: designDetailActions.CHANGE_YARN_MATERIAL,
      payload: { material, materialName, colorIndex }
    });
  };
  const shouldShowDropdown = designColor.YarnDetails.map(item => item.Material).reduce(
    (prev, current) => current === 0 || current === 1
  );
  return (
    <div className={`area-swatch bp3-text-small ${className}`} style={style} onClick={handleClick}>
      <span className="color-index">{colorNumber ? colorNumber : colorIndex + 1}</span>
      <div
        className="color-pie"
        style={{
          borderRadius: "50%",
          backgroundColor: currentYarn.Color
        }}
      >
        <CircularColorSVG className="color-pie__color" yarnDetails={currentYarn} />
        <Mixply1
          className="color-pie__color"
          yarnDetails={currentYarn}
          // handleClick={() => console.log("mixPly color clicked")}
          shape={casFlags.shape}
        />
      </div>

      <div className="color-details-area">
        {designColor && (
          <div className="color-details-name" title={currentYarn.ColorName}>
            <span className="color-details-name__title">{currentYarn.ColorName}</span>
            {designColor.Carving && <AtIcon icon="carving" size={1} />}
          </div>
        )}

        <div className="color-details-material">
          <Popover
            disabled={!shouldShowDropdown}
            content={
              <Menu>
                {DefaultTextureNames &&
                  DefaultTextureNames.slice(0, 2).map(
                    (textureName, i) =>
                      DefaultTextureVisible[i] && (
                        <MenuItem
                          key={i}
                          text={textureName}
                          onClick={e => {
                            handleyarnMaterialChange({ material: i, materialName: textureName });
                          }}
                        />
                      )
                  )}
              </Menu>
            }
            minimal
            position="bottom-left"
            portalContainer={document.getElementById("app-main")}
          >
            <div className="color-details-material-info">
              <span className="color-details-material-text">
                {getMaterialName(currentYarn.Material)}
              </span>
              {shouldShowDropdown && (
                <Icon className="color-details-material-icon" icon="caret-down" />
              )}
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};
