import React from "react";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import AtDialog from "../../molecules/AtDialog";
import { H5, Button } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import AtIcon from "../../atoms/AtIcon";
import strings from "../../../strings";
const InfoDialog = props => {
  const {
    isOpen,
    dialogTitle = strings.tools.saveAndReplaceOnserver,
    infoText = "Are you sure?",
    confirmText = "Confirm",
    handleConfirm,
    handleClose
  } = props;

  const dispatchUiState = useUiDispatch();

  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_INFO_DIALOG, payload: false });
    if(handleClose) handleClose();
  };
  const handlePrimaryClick = () => {
    if (handleConfirm) handleConfirm();
    handleCloseDialog();
  };
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={isOpen}
      className="at-confirmation-dialog info-dialog"
      size="xs"
    >
      <div className="at-dialog-area at-confirmation-dialog-area">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <H5 className="at-dialog-heading">{dialogTitle}</H5>
        <div className="at-dialog-content">
          {infoText !== "" && <div className="at-dialog-content-text"> {infoText}</div>}
          <div className="at-confirmation-btns-area">
            <AtButton intent="primary" onClick={handlePrimaryClick} text={confirmText} />
            {/* <AtButton intent="danger" onClick={handleSecondaryClick} text={cancelText} /> */}
          </div>
        </div>
      </div>
    </AtDialog>
  );
};

export default InfoDialog;
