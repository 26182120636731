import { CDN_domain } from "../../../api/appProvider";
window.printScript = false;
export default class PrintHelper {
  constructor() {
    this.print = null;
    this.pdfToPrint = null;
  }
  async init(options = {}) {
    const { pdfToPrint } = options;
    if (pdfToPrint) {
      this.pdfToPrint = pdfToPrint;
    }
    return new Promise((resolve, reject) => {
      if (!this.print) {
        if (!window.printScript) {
          const script = document.createElement("script");
          script.src =  `${CDN_domain}v3assets/scripts/print.min.js`;
          document.body.appendChild(script);
          script.onload = () => {
            window.printScript = true;
            this.print = window.printJS({
              printable: this.pdfToPrint,
              type: "pdf",
              honorColor: true,
              scanStyles: false
            });
            resolve();
          };
        }
        else {
          this.print = window.printJS({
            printable: this.pdfToPrint,
            type: "pdf",
            honorColor: true,
            scanStyles: false
          });
          resolve();
        }
      } else {
        resolve();
      }
    });
  }


  async printFile(options = {}) {
    const { pdfToPrint } = options;
    await this.init({ pdfToPrint });
    return;
  }


  clear() {
    if (this.print) {
      this.print.abort();
      this.print.pdfToPrint = null;
      this.print = null;
    }
  }
}
