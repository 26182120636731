/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CollapsibleSectionHeading } from "../../molecules/CustomCollapse";
import { Collapse, Popover } from "@blueprintjs/core";
import Thumbnail from "../../molecules/Thumbnail";
import strings from "../../../strings";
import {
  visViewModes,
  visualizationsActions,
  useVisualizationState,
  useDispatchVisualization
} from "../../../reducers/visualizations.reducer";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import classNames from "classnames";
import SelectSizeDropdown from "../SelectSizeDropdown";
import { pageViews } from "../../../reducers/mainui.reducer";

const Designviews = props => {
  const designListState = useDesignListState();
  const { selectedFile } = designListState;
  const visState = useVisualizationState();
  const dispatchVisState = useDispatchVisualization();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [viewModes, setviewModes] = useState([]);
  const pageView = window.flags.homeTemplate;

  const getviewModeText = mode => {
    const { designViews } = strings;
    switch (mode) {
      case visViewModes.DESIGN_VIEW_DEEPZOOM:
        return designViews.deepZoom;
      case visViewModes.DESIGN_VIEW_DEEPZOOM_RX:
        return designViews.deepZoomRx;
      case visViewModes.DESIGN_VIEW_PERSPECTIVE:
        return designViews.perspective;
      case visViewModes.DESIGN_VIEW_FOLDEDBACK:
        return designViews.foldedback;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!selectedFile || !selectedFile.designProps) return;
    let { IsIrregular } = selectedFile.designProps;
    const filterCondition = designView => {
      if (window.InterfaceElements.IsJpeg) return true;
      return (
        //done to not show perspective for irregular
        // !IsIrregular ||
        // ![visViewModes.DESIGN_VIEW_PERSPECTIVE, visViewModes.DESIGN_VIEW_FOLDEDBACK].includes(
        //   designView
        // )
        ![visViewModes.DESIGN_VIEW_FOLDEDBACK].includes(designView)
      );
    };
    if (pageView === pageViews.CONTROLLER && window.flags.controller.showTopViewOnly) {
      setviewModes(["DESIGN_VIEW_DEEPZOOM"]);
    } else {
      let views = window.flags.visualizations.designViews.filter(designView =>
        filterCondition(designView)
      );
      setviewModes([...views]);
      if (
        visState.viewMode === visViewModes.MY_ROOM ||
        visState.viewMode === visViewModes.ROOM_VIEW
      )
        return;
      if (!views.includes(visState.viewMode)) {
        dispatchVisState({
          type: visualizationsActions.SET_VIEW_MODE,
          payload: window.flags.visualizations.defaultView
        });
      }
    }
  }, [selectedFile]);

  return (
    <>
      <CollapsibleSectionHeading
        title={strings.design.designViews}
        isOpen={!isCollapsed}
        handleCollapseIconClick={() => setIsCollapsed(!isCollapsed)}
        handleHeadingClick={() => setIsCollapsed(!isCollapsed)}
      />
      <div className="at-designviews at-vis-thumbs">
        <Collapse isOpen={!isCollapsed}>
          <div className="thumb-container">
            {viewModes.map((mode, i) => (
              <Thumbnail
                key={i}
                thumb={{
                  ...selectedFile,
                  name:
                    window.flags.visualizations.designViews.length > 1
                      ? getviewModeText(mode)
                      : null
                }}
                aspect="landscape"
                active={visState.viewMode === mode}
                className={classNames(
                  visViewModes.DESIGN_VIEW_PERSPECTIVE === mode ? "at-perspective-thumb" : "",
                  visViewModes.DESIGN_VIEW_FOLDEDBACK === mode ? "at-folded-back-thumb" : ""
                )}
                doubleImage={visViewModes.DESIGN_VIEW_FOLDEDBACK === mode ? true : false}
                secondImageClass={
                  visViewModes.DESIGN_VIEW_FOLDEDBACK === mode ? "folded-back-part" : ""
                }
                onThumbnailClick={() => {
                  dispatchVisState({
                    type: visualizationsActions.SET_VIEW_MODE,
                    payload: mode
                  });
                }}
              />
            ))}
            {sessionStorage.getItem("sizeoption") &&
              sessionStorage.getItem("sizeoption").indexOf("|") !== -1 && (
                <div className="selectSizeDropdown">
                  <SelectSizeDropdown />
                </div>
              )}
          </div>
        </Collapse>
      </div>
    </>
  );
};

Designviews.propTypes = {};

export default Designviews;
