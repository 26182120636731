import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "./../Checkbox";
import AtIcon from "./../../../atoms/AtIcon";

const Div = styled.div`
  ${props => (!props.show ? "visibility:hidden;" : "")}
  height: 15rem;
  overflow: auto;
  & .at-icon {
    vertical-align: middle;
  }
`;
const IndentDiv = styled.div`
  margin: 0.25rem 0;
  margin-left: 1rem;
  ${props => (!props.show ? "display:none;" : "")}
  & > label {
    vertical-align: middle;
  }
  & > img {
    margin-left: 0.2rem;
    vertical-align: middle;
    display: inline;
    visibility: hidden;
    pointer-events: none;
  }
  &:hover > img {
    visibility: visible;
    pointer-events: unset;
  }
`;

const FolderTree = props => {
  const { treeContent, showFileTree, handleFolderSelection } = props;
  const [treeData, settreeData] = useState([]);
  const [selectedNodeData, setselectedNodeData] = useState({});

  useEffect(() => {
    if (treeContent) {
      let settree = [];
      settree.push({ label: "root", children: [...treeContent] });
      settree[0].children.forEach(node => (node.isSelected = true));
      settreeData(settree);
    }
  }, [treeContent]);

  const handleTree = (val, itemLabel, itemPath) => {
    let tempArr = { ...selectedNodeData };
    let tempArrKeys = Object.keys(tempArr);
    if (!val) {
      Object.values(tempArr).forEach((element, i) => {
        if (!(element.length < itemPath.length)) {
          let slicedArr = element.slice(0, itemPath.length);
          if (slicedArr === itemPath) {
            delete tempArr[tempArrKeys[i]];
          }
        }
      });
      tempArr[itemLabel] = itemPath;
    } else {
      Object.keys(tempArr).includes(itemLabel)
        ? tempArr[itemLabel] === itemPath
          ? delete tempArr[itemLabel]
          : console.log("itempath not equal")
        : console.log("itemlabel not included");
    }
    handleFolderSelection({ ...tempArr });
    setselectedNodeData({ ...tempArr });
  };

  return (
    <Div show={showFileTree}>
      {treeData.map((item, i) => (
        <EachNode key={i} nodeData={item} handleTree={handleTree} show={true} />
      ))}
    </Div>
  );
};
export default FolderTree;

const EachNode = props => {
  const { nodeData = { children: [] }, handleTree, show } = props;
  const [isExpanded, setisExpanded] = useState([]);
  const [isChecked, setisChecked] = useState([]);

  useEffect(() => {
    let temp = [];
    let temptrue = [];
    if (nodeData && nodeData.children.length > 0 && isExpanded.length === 0) {
      nodeData.children.forEach(element => {
        temp.push(false);
        temptrue.push(true);
      });
      setisExpanded([...temp]);
      setisChecked([...temptrue]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeData]);

  const handleExpand = (e, ind) => {
    e.stopPropagation();
    let temp = [...isExpanded];
    temp[ind] = !temp[ind];
    setisExpanded([...temp]);
  };

  const handleCheckboxClick = (val, ind, itemLabel, fullPath) => {
    let temp = [...isChecked];
    temp[ind] = !temp[ind];
    setisChecked([...temp]);
    handleTree(val, itemLabel, fullPath);
  };
  return (
    <React.Fragment>
      {nodeData.children.map((item, i) => (
        <IndentDiv key={i} show={show}>
          {/* defaultVal={item.isSelected} */}
          <Checkbox
            defaultVal={true}
            inline
            label={item.name}
            onChange={val => handleCheckboxClick(val, i, item.name, item.fullPath)}
          />
          {isChecked.length > 0 &&
            isChecked[i] &&
            isExpanded.length > 0 &&
            item.children &&
            item.children.length > 0 && (
              <AtIcon
                icon={isExpanded[i] ? "chevron-up" : "chevron-down"}
                onClick={e => handleExpand(e, i)}
              />
            )}
          {isChecked.length > 0 &&
            isChecked[i] &&
            isExpanded.length > 0 &&
            item.children &&
            item.children.length > 0 && (
              <EachNode nodeData={item} handleTree={handleTree} show={isExpanded[i]} />
            )}
        </IndentDiv>
      ))}
    </React.Fragment>
  );
};
