import React from "react";
import { useUiState } from "../../../reducers/mainui.reducer";
import classNames from "classnames";

const SidePanel = props => {
  const { side = "left", show = true, isIdle = false, children, className, ...otherprops } = props;
  const uiState = useUiState();
  return (
    <div
      {...otherprops}
      className={classNames(
        "sidebar",
        side,
        "ui",
        {
          "panel-hidden": !show,
          "at-idle-fadeout": isIdle && !uiState.mouseHoveredOnUi
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default SidePanel;
