import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { pomsUrlPrefixPaulig } from "../../../api/appProvider";

const isInViewPort = elem => {
  const bounding = elem.getBoundingClientRect();
  const parent = elem.parentElement;
  const { left, top, bottom, right } = parent.getBoundingClientRect();
  return (
    bounding.top + bounding.height >= top &&
    bounding.left + bounding.width >= left &&
    bounding.bottom - bounding.height <= bottom &&
    bounding.right - bounding.width <= right
  );
};
const SwatchBoxPaulig = props => {
  const { swatchSize, colorRow, onColorSwatchClick, active } = props;
  const disabledSwatch = colorRow.ColorName.toLowerCase() === "_blank";
  const [showColorSwatchBg, setShowColorSwatchBg] = useState(true);

  return (
    <div className="pauligSwatchBox">
      <img
        style={{
          width: `${swatchSize}rem`,
          height: `${swatchSize}rem`
        }}
        onClick={e => {
          if (onColorSwatchClick) onColorSwatchClick(e);
        }}
        className={classNames(
          "color-swatch",
          { "no-background": !showColorSwatchBg },
          {
            "hide-color-pom": showColorSwatchBg
          },
          { "color-swatch--disabled": disabledSwatch, active }
        )}
        src={`${pomsUrlPrefixPaulig}${colorRow.ColorName}.jpg`}
      />
      <p className="pauligSwatchBoxText">{colorRow.ColorName}</p>
    </div>
  );
};

export default SwatchBoxPaulig;
