import React from "react";
import PropTypes from "prop-types";
const Divider = props => {
  const { horizontal, vertical, className = "", ...otherProps } = props;
  return (
    <>
      {horizontal && (
        <div {...otherProps} className={`at-divider at-divider-horizontal ${className}`}></div>
      )}
      {vertical && (
        <div {...otherProps} className={`at-divider at-divider-vertical ${className}`}></div>
      )}
    </>
  );
};

Divider.propTypes = {
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  className: PropTypes.string
};

export default Divider;
