import React from "react";
import strings from "../../strings";
import classNames from "classnames";
const currentYear = new Date().getFullYear();
const CopyrightSection = props => {
  const { className } = props;
  const show = window.flags.showCopyright;
  
  const copyrightClick=()=>{
    // AppAnalytics.sendCopyrightClick().then().catch(error=>{
    //   console.log(" AppAnalytics CopyrightClick ~ error", error)
    // });
  }
  return (
    <div className={classNames("copyright-text", "bp3-text-small", className)}>
      {show && ( //flags global
        <span
          className="copyright-description"
          dangerouslySetInnerHTML={{ __html: strings.copyrightText }}
        ></span>
      )}
      {"Explorug.com ©2010-"+currentYear+' '}
      <a target="_blank" rel="noopener noreferrer" href="http://alternative.com.np"
       onClick={copyrightClick}>
        Alternative Technology
      </a>
    </div>
  );
};

export default CopyrightSection;
