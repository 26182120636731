import seedrandom from "seedrandom";
import { createCanvas } from "./canvasutils";

export const downloadAsJSON = (object, name) => {
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(object));
  const dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", `${name}.json`);
  dlAnchorElem.click();
};

export const loadCssFile = ({ cssUrl }) => {
  let link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute("href", `${cssUrl}`);
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const readImage = (url, i) => {
  let imageUrl = url;
  if (url instanceof Blob) {
    imageUrl = URL.createObjectURL(url);
  }
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = imageUrl;
    image.onload = () => {
      if (i) resolve({ image, ...i });
      else resolve(image);
    };
    image.onerror = reject;
  });
};

export function scrollIntoViewIfNeeded(target) {
  var rect = target.getBoundingClientRect();
  if (rect.bottom > window.innerHeight) {
    target.scrollIntoView(false);
  }
  if (rect.top < 0) {
    target.scrollIntoView();
  }
}

export const isIE = () =>
  window.navigator.userAgent.indexOf("MSIE ") > 0 ||
  // eslint-disable-next-line no-useless-escape
  !!navigator.userAgent.match(/Trident.*rv\:11\./);

export function getJsonFromUrl(url) {
  if (!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export const getFromSessionStorage = key => {
  const item = sessionStorage.getItem(key);
  if (item === "undefined") return undefined;
  if (item === "null") return null;
  try {
    return JSON.parse(item);
  } catch (error) {
    return item;
  }
};
export const getCustomClass = () => {
  let customClass = getFromSessionStorage("customclass") || "";
  return customClass;
};

export const overlayTextureinCanvas = ({ image, color, canvasSize, canvas }) => {
  const ctx = canvas.getContext("2d");
  var rng = seedrandom(color);
  const start = Math.round(rng() * (256 - canvasSize));
  const can = createCanvas(image.width, image.height);
  can.getContext("2d").drawImage(image, 0, 0);
  const imgData = can.getContext("2d").getImageData(start, start, canvasSize, canvasSize);
  ctx.globalCompositeOperation = "overlay";
  can.width = canvasSize;
  can.height = canvasSize;
  can.getContext("2d").putImageData(imgData, 0, 0);
  ctx.drawImage(can, 0, 0);
};
export const copyToClipboard = str => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

const stopPrntScr = () => {
  var inpFld = document.createElement("input");
  inpFld.setAttribute("value", ".");
  inpFld.setAttribute("width", "0");
  inpFld.style.height = "0px";
  inpFld.style.width = "0px";
  inpFld.style.border = "0px";
  document.body.appendChild(inpFld);
  inpFld.select();
  document.execCommand("copy");
  inpFld.remove(inpFld);
};

const AccessClipboardData = () => {
  try {
    window.clipboardData.setData("text", "Access   Restricted");
  } catch (err) {}
};
export const disableScreenshot = () => {
  document.addEventListener("keyup", function(e) {
    if (e.key === "PrintScreen") {
      stopPrntScr();
    }
  });
  //setInterval(() => {
  AccessClipboardData();
  //}, 1000);
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const handleSwipeOnEdges = data => {
  if (!data) return;
  const direction = data.dir;
  const startPos = data.initial[0];
  const velocity = data.velocity;
  if (velocity < 0.5) return;
  let detectableArea = window.innerWidth < 1000 ? window.innerWidth / 3 : window.innerWidth / 4;
  const detectableArea_left = detectableArea;
  const detectableArea_right = window.innerWidth - detectableArea;

  let payload;
  //swiped right near leftsidebar--> open leftsidebar
  //swiped right near rightsidebar--> close rightsidebar
  //swiped left near leftsidebar--> close leftsidebar
  //swiped left near rightsidebar--> open rightsidebar

  if (direction.toLowerCase() === "right") {
    if (startPos < detectableArea_left) {
      payload = { left: true };
    } else if (startPos > detectableArea_right) {
      payload = { right: false };
    }
  } else if (direction.toLowerCase() === "left") {
    if (startPos < detectableArea_left) {
      payload = { left: false };
    } else if (startPos > detectableArea_right) {
      payload = { right: true };
    }
  }
  return payload;
};

export const loadPomUrl = (color, material) => {
  return new Promise((resolve, reject) => {
    const matName = material === 1 ? "silk" : "wool";
    const color_hex = color.replace("#", "");
    const hex_r = color_hex.substr(0, 2);
    const hex_g = color_hex.substr(2, 2);

    var colorPomImageUrl = `https://v3.explorug.com/permanentcache/colorpoms/${matName}/${hex_r}/${hex_g}/${color_hex}.png`;
    readImage(colorPomImageUrl)
      .then(colorImage => {
        resolve(colorPomImageUrl);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const handleNavigation = ({ elem, direction }) => {
  if (!elem || !direction) return;
 // const elem = document.querySelector(querySelector);
  let offsetValue = direction === "right" ? elem.offsetWidth / 2 : (-1 * elem.offsetWidth) / 2;
  if (
    Math.abs(elem.scrollLeft + elem.clientWidth - elem.scrollWidth) < 2 &&
    direction === "right"
  ) {
    elem.scrollLeft = 0;
  } else if (elem.scrollLeft === 0 && direction === "left") {
    elem.scrollLeft = elem.scrollWidth;
  } else {
    elem.scrollLeft += offsetValue;
  }
};
