import { InputGroup } from '@blueprintjs/core';
import React, {useState } from 'react';
import AppProvider from '../../../api/appProvider';
import PropTypes from "prop-types";
import {
  useDesignDetailState
} from "../../../reducers/designdetails.reducer";
import { mainUiActions, useUiDispatch } from '../../../reducers/mainui.reducer';
import AtButton from '../../atoms/AtButton';

const SendToApp = props => {
  const {label="User ID", message ="Please enter User ID", buttonText="SEND TO APP"} = props;
  const designDetailState = useDesignDetailState();
  const dispatchUiState = useUiDispatch();

  const [userID, setUserID] = useState("");
  const [showWarningText, setShowWarningText] = useState(false);
  const handleUserIdInput = () => {
    var usernameRegex = /^[a-zA-Z0-9_ ]+$/;

    if (!userID || userID.trim() === "") {
      setShowWarningText(true);
      return;
    }
    if (!usernameRegex.test(userID)) {
      setUserID("");
      setTimeout(() => {
        setShowWarningText(true);
      }, 200);
      return;
    }
    AppProvider.sendUserIDToApp({
      file: designDetailState.fullpath,
      props: designDetailState.designDetails,
      mode: "postback",
      userid: userID
    }).then(data => {
      //console.log("TCL: handleUserIdInput -> data", data);
      if (data === "error") {
        window.parent.postMessage("error", "*");
      } else {
        window.parent.postMessage("success", "*");
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: { message: `The design has been saved.`, intent: "success" }
        });
      }
    });
  };
  const handleChange = e => {
    setUserID(e.target.value);
    if (showWarningText) setShowWarningText(false);
  };

  return (
    <div className="at-sendToApp-area">
    <div className="userIdText">{label}</div>
    <div className="at-sendToApp-area__inp">
      <InputGroup
        className="at-sendToApp-area-userIdInput"
        tag="User Id"
        onChange={handleChange}
        value={userID}
        autoFocus
      />
    </div>
    {showWarningText && <div className="userIdWarningText">{message}</div>}
    <AtButton
      id="ReturnToAppBtn"
      text={buttonText}
      type="submit"
      intent="primary"
      tertiary
      onClick={handleUserIdInput}
    />
  </div>

  );
};

SendToApp.propTypes = {
  label: PropTypes.string,
  message:PropTypes.string, 
  buttonText: PropTypes.string
};

export default SendToApp;