//this component shows ordersheet or ordersheet button when necessary
import React, { Suspense, useEffect } from "react";
import OrderSheet from ".";
import AppProvider from "../../../api/appProvider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
import TearSheet from "../../molecules/TearSheet";
import OrdersheetButton from "./OrdersheetBtn";
import { getExplorugData, postDetailsToParent } from "../../../middleware/ordersheet.middleware";
import { useOrdersheetDetailState } from "../../../reducers/ordersheetdetails.reducer";
import { usePriceCalculator } from "../../../hooks";

const OrdersheetArea = props => {
  const { openOrdersheetBydefault } = props;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const { customFields } = designDetailState;
  const dispatchUiState = useUiDispatch();
  const ordersheetDetailState = useOrdersheetDetailState();
  const uiState = useUiState();
  const setOpenOrderSheet = show =>
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: show });
  const setCustomFieldsLoading = value => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS_LOADING, payload: value });
  };
  const setCustomFields = fields => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS, payload: fields });
  };

  const displayOrderSheetButton = () => {
    return (
      window.flags.hasOrderSheet &&
      !showOrdersheetOutside() &&
      !uiState.showOrderSheet &&
      window.flags.createYourRug.showOrdersheetBtnBelowColorsArea
    );
  };

  useEffect(() => {
    if (showOrdersheetOutside()) {
      if (customFields.fields) {
        setOpenOrderSheet(true);
        return;
      }
      setCustomFieldsLoading(true);
      AppProvider.fetchCustomFieldOrdersheet()
        .then(data => {
          setCustomFieldsLoading(false);
          setCustomFields(data);
          setOpenOrderSheet(true);
        })
        .catch(err => {})
        .finally(() => {
          setCustomFieldsLoading(false);
        });
    }
  }, []);

  const price = usePriceCalculator({
    ...designDetailState,
    showPrice: window.InterfaceElements.ShowPrice && !window.initialData.customDesignUrl
  });

  const handleAddtoCart = (text = "") => {
    const {
      passDesignNameAsDesignId,
      passQualityFromFolderName,
      sendOrigDesignColors,
      passTotalArea
    } = window.flags.explorugData;
    getExplorugData({
      designDetailState: designDetailState,
      ordersheetDetailState: ordersheetDetailState,
      showPrice: window.InterfaceElements.ShowPrice,
      price: price,
      passDesignNameAsDesignId: passDesignNameAsDesignId,
      passQualityFromFolderName: passQualityFromFolderName,
      sendOrigDesignColors: sendOrigDesignColors,
      passTotalArea: passTotalArea,
      origDesignColors: designDetailState.history[0]
        ? designDetailState.history[0].designDetails.DesignColors
        : designDetailState.designDetails.DesignColors
    }).then(data => {
      if (text !== "") {
        data = Object.assign({}, data, { text: text });
      }
      postDetailsToParent(data);
    });
  };

  return (
    <div>
      {window.flags.hasOrderSheet &&
        (showOrdersheetOutside() || uiState.showOrderSheet) &&
        window.flags.createYourRug.showOrdersheetBtnBelowColorsArea && (
          <Suspense fallback={<></>}>
            <OrderSheet
              openOrdersheetBydefault={openOrdersheetBydefault}
              handleAddtoCart={handleAddtoCart}
            />
          </Suspense>
        )}
      <div className="cyr-sheet-btn">
        {displayOrderSheetButton() && (
          <div className="cyr-ordersheet-btn">
            <OrdersheetButton />
          </div>
        )}
        {window.flags.ordersheet.showTearSheet && (
          <TearSheet type={displayOrderSheetButton() ? "tertiary" : "primary"} />
        )}
      </div>
    </div>
  );
};

OrdersheetArea.propTypes = {};

export default OrdersheetArea;
