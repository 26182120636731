import uuid from "uuid";
import { getDesignStory, getFilenameFromPath, uncompressJSON } from "./stringUtils";
import { convertUnit, doesStringMatch, shuffleArray } from "./utils";

export const getPathOffile = fileFullPath => {
  const sp = fileFullPath.split("/");
  sp.pop();
  return sp.join("/");
};
export const getDesignName = fileFullPath => {
  if (!fileFullPath) return;
  const p = fileFullPath.split("/").filter(item => item && item !== "");
  const designNamewithExt = p[p.length - 1];
  const designNamewithExtArr = designNamewithExt.split(".");
  let designName = designNamewithExtArr.slice(0, designNamewithExtArr.length - 1).join(".");
  return designName;
};
export const processDesignPath = path => {
  if (!isNaN(path)) return `Designs/${path}`; //in case design path given is number
  const initpatharr = path.split("/").filter(item => item && item !== "");
  return `${initpatharr[0] === "Designs" ? "" : "Designs/"}${path}`;
};

export function findInTree({ rawTree, initDesignPath, initDesignFolder, initDesignName }) {
  if (!initDesignPath && !initDesignName) return;
  const arr = [...rawTree];
  let designPath;

  arr.forEach(function iter(a) {
    if (designPath) return;
    let des;
    des = a.Children.find(item => {
      const findType = initDesignFolder ? "folder" : "file";
      let requiredItem =
        item.Type === findType && item.FullPath.toLowerCase() === initDesignPath.toLowerCase();

      if (initDesignName && initDesignName !== "") {
        requiredItem =
          item.Type === findType &&
          (item.FullPath.toLowerCase() === initDesignPath.toLowerCase() ||
            item.FullPath.toLowerCase().indexOf(initDesignName.toLowerCase()) >= 0);
      }
      return requiredItem;
    });

    if (des) {
      designPath = des.FullPath;
    }
    Array.isArray(a.Children) && a.Children.forEach(iter);
  });

  return designPath;
}
const findVariations = (fileNode, parentNode) => {
  const { Children } = parentNode;
  const { Name } = fileNode;
  const filtered = Children.filter(item => item.Name[0] === ".");
  const varFolder = filtered.find(item => item.Name.toLowerCase() === `.${Name.toLowerCase()}`);
  if (!varFolder) return null;
  const varFiles = varFolder.Children.filter(child => child.Type === "file").map(item => ({
    name: item.Name,
    type: item.Type,
    fullPath: item.FullPath,
    location: item.Location,
    id: uuid.v4()
  }));
  if (varFiles.length) return { vars: varFiles };
  let colors = [];
  let shapes = [];
  const varColorsFolder = varFolder.Children.find(
    child => child.Type === "folder" && child.Name.toLowerCase() === "colors"
  );
  if (varColorsFolder) {
    colors = varColorsFolder.Children.filter(item => item.Type === "file").map(item => ({
      name: item.Name,
      type: item.Type,
      fullPath: item.FullPath,
      location: item.Location,
      id: uuid.v4()
    }));
  }
  const varShapesFolder = varFolder.Children.find(
    child => child.Type === "folder" && child.Name.toLowerCase() === "shapes"
  );
  if (varShapesFolder) {
    shapes = varShapesFolder.Children.filter(item => item.Type === "file").map(item => ({
      name: item.Name,
      type: item.Type,
      fullPath: item.FullPath,
      location: item.Location,
      id: uuid.v4()
    }));
  }
  return { colors, shapes };
};
//TODO: startup folder
export function arrangeTree({
  tree,
  initDesignPath,
  setActiveItem = true,
  designfromFolder = false,
  expandSelectedFolder = false,
  keepFoldersExpanded
}) {
  let defaultFileLocation;
  if (!!initDesignPath)
    defaultFileLocation = designfromFolder ? initDesignPath : getPathOffile(initDesignPath);
  return deepCopy(tree[0]);
  function deepCopy(node) {
    const { Children, Type, Name, FullPath, Location } = node;
    const fileNodes = Children.filter(child => child.Type === "file");
    const files = fileNodes.map(item => ({
      type: item.Type,
      name: item.Name,
      fullPath: item.FullPath,
      location: item.Location,
      id: uuid.v4(),
      variations: findVariations(item, node)
    }));
    let level = 0;
    let selectedFile = null;
    let selectedFolder = null;
    let isSelected = false;
    let isExpanded = false;
    let showThumbnails = false;
    if (!selectedFile) {
      if (initDesignPath) {
        if (!designfromFolder) {
          const item = files.find(
            item => item.fullPath.toLowerCase() === initDesignPath.toLowerCase()
          );
          showThumbnails = !!item;
          selectedFile = item;
          isSelected =
            setActiveItem && FullPath.toLowerCase() === defaultFileLocation.toLowerCase();
          isExpanded = setActiveItem && initDesignPath.split("/").includes(Name);
        } else {
          isSelected =
            setActiveItem && FullPath.toLowerCase() === defaultFileLocation.toLowerCase();
          isExpanded = setActiveItem && initDesignPath.split("/").includes(Name);
          if (isSelected) {
            if (files.length) {
              selectedFile = files[0];
              showThumbnails = true;
            } else {
              isSelected = false;
              initDesignPath = null;
            }
          }
        }
      } else {
        if (Name.charAt(0) !== "." && files.length) {
          selectedFile = files[0];
          isSelected = setActiveItem;
          isExpanded = showThumbnails = expandSelectedFolder && setActiveItem;
        } else {
          isExpanded = true;
        }
      }
    }
    if (keepFoldersExpanded) isExpanded = true;
    let copiedNode = {
      type: Type,
      name: Name,
      fullPath: FullPath,
      location: Location,
      children: [],
      showThumbnails,
      files,
      isSelected,
      isExpanded,
      level,
      id: uuid.v4()
    };
    const folderNodes = Children.filter(child => child.Type === "folder");
    copiedNode["children"] = Array(folderNodes.length);
    folderNodes.forEach((child, index) => {
      copiedNode.children[index] = {
        type: null,
        name: null,
        fullPath: null,
        location: null,
        children: [],
        files: null
      };
      traverse(child, copiedNode.children[index]);
    });
    if (isSelected) selectedFolder = copiedNode;

    function traverse(node, copyNode) {
      const { Children, Type, Name, FullPath, Location, Info } = node;
      let fileNodes = Children.filter(child => child.Type === "file");
      if (window.flags.designListTree.shuffleDesigns && Location.split("/").length == 1) {
        // Shuffle if the shuffleDesigns flag is true and location condition is met
        fileNodes = shuffleArray(fileNodes);
      } else if (window.flags.designListTree.sortDesignInDescending) {
        // Sort in descending order by date
        fileNodes.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      }
      const files = fileNodes.map(item => ({
        type: item.Type,
        name: item.Name,
        fullPath: item.FullPath,
        location: item.Location,
        id: uuid.v4(),
        variations: findVariations(item, node),
        filename: getFilenameFromPath(item.FullPath),
        story: getDesignStory(item, node)
      }));
      let isSelected = false;
      let isExpanded = false;
      let showThumbnails = false;
      if (!selectedFile)
        if (initDesignPath) {
          if (!designfromFolder) {
            const item = files.find(
              item => item.fullPath.toLowerCase() === initDesignPath.toLowerCase()
            );
            showThumbnails = !!item;
            selectedFile = item;
            isSelected =
              setActiveItem && FullPath.toLowerCase() === defaultFileLocation.toLowerCase();
            isExpanded = setActiveItem && initDesignPath.split("/").includes(Name);
          } else {
            isSelected =
              setActiveItem && FullPath.toLowerCase() === defaultFileLocation.toLowerCase();
            isExpanded = setActiveItem && initDesignPath.split("/").includes(Name);
            if (isSelected) {
              if (files.length) {
                selectedFile = files[0];
                showThumbnails = true;
              } else {
                isSelected = false;
                showThumbnails = false;
                initDesignPath = null;
              }
            }
          }
        } else {
          if (Name.charAt(0) !== "." && files.length) {
            isSelected = setActiveItem;
            isExpanded = showThumbnails = expandSelectedFolder && setActiveItem;
            selectedFile = files[0];
          } else {
            isExpanded = true;
          }
        }
      if (keepFoldersExpanded) isExpanded = true;
      copyNode["type"] = Type;
      copyNode["name"] = Name;
      copyNode["fullPath"] = FullPath;
      copyNode["location"] = Location;
      copyNode["children"] = Type;
      copyNode["files"] = files;
      copyNode["isSelected"] = isSelected;
      copyNode["isExpanded"] = isExpanded;
      copyNode["showThumbnails"] = showThumbnails;
      copyNode["level"] = Location.split("/").length;
      copyNode["id"] = uuid.v4();
      const folderNodes = Children.filter(child => child.Type === "folder");
      copyNode["children"] = Array(folderNodes.length);

      const info = uncompressJSON(Info);
      copyNode["story"] = info.story ? info.story : info;

      folderNodes.forEach((child, index) => {
        copyNode.children[index] = {
          type: null,
          name: null,
          fullPath: null,
          location: null,
          children: [],
          files: null
        };
        traverse(child, copyNode.children[index]);
      });
      if (isSelected) selectedFolder = copyNode;
    }
    return { copiedNode: [copiedNode], selectedFile, selectedFolder };
  }
}
export const updateFilesInEveryNode = (tree, fileList) => {
  const arr = [...tree];
  arr.forEach(function iter(a) {
    const files = fileList.filter(item => item.location.toLowerCase() === a.fullPath.toLowerCase());
    a.files = files;
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return arr;
};
export const searchFoldersInTree = (tree, searchKey) => {
  const arr = JSON.parse(JSON.stringify(tree));
  const folderArr = [];
  arr.forEach(function iter(a) {
    if (doesStringMatch(a.Name, searchKey)) {
      folderArr.push(a);
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return folderArr;
};
export const filterTreeFilesBySize = (tree, comparisionNodes) => {
  const arr = JSON.parse(JSON.stringify(tree));
  let filteredFiles = [];
  arr.forEach(function iter(a) {
    const f = a.files.filter(item => {
      const { designProps } = item;
      if (!designProps) return false;
      const { PhysicalWidth, PhysicalHeight, Unit } = designProps;
      let wid = PhysicalWidth;
      let hgt = PhysicalHeight;
      const temparr = Array(comparisionNodes.length);
      comparisionNodes.forEach((comparisionNode, index) => {
        if (Unit !== comparisionNode.Unit) {
          wid = convertUnit(Unit, comparisionNode.Unit, PhysicalWidth);
          hgt = convertUnit(Unit, comparisionNode.Unit, PhysicalHeight);
        }
        const { thresHoldWidth, thresHoldHeight } = comparisionNode;
        const isWid =
          comparisionNode.PhysicalWidth >= wid - thresHoldWidth &&
          comparisionNode.PhysicalWidth < wid + thresHoldWidth;
        const isHgt =
          comparisionNode.PhysicalHeight >= hgt - thresHoldHeight &&
          comparisionNode.PhysicalHeight < hgt + thresHoldHeight;
        temparr[index] = isWid && isHgt;
      });
      return temparr.some(item => !!item);
    });
    filteredFiles.push(...f);
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return filteredFiles;
};
export const getAllFiles = array => {
  const arr = [...array];
  const files = [];
  arr.forEach(function iter(a) {
    files.push(...a.files);
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return files;
};
export const updateNodeProps = (array, node, props) => {
  const arr = [...array];
  arr.forEach(function iter(a) {
    if (node.fullPath === a.fullPath) {
      a[props] = node[props];
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return arr;
};

// export const updatefiles = (array, node, props) => {
//   const arr = [...array]
//   arr.forEach(function iter(a) {
//     if (node.fullPath === a.fullPath) {
//       a[props] = node[props];
//     }
//     Array.isArray(a.children) && a.children.forEach(iter);
//   });
//   return arr
// };
export const expandTreeNode = (array, node, collapseOthers = true) => {
  const arr = [...array];
  arr.forEach(function iter(a, index) {
    a.isSelected = a.fullPath === node.fullPath;
    if (a.level >= node.level) {
      a.isExpanded = !collapseOthers;
    }
    a.showThumbnails = false;
    if (node.fullPath === a.fullPath) {
      a.isExpanded = a.showThumbnails = true;
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return arr;
};
export const updateSingleFileProp = (array, fileNode) => {
  const arr = [...array];
  let done;
  arr.forEach(function iter(a) {
    if (done) return;
    a.files.forEach(file => {
      if (fileNode.fullPath === file.fullPath) {
        file.thumbUrl = fileNode.thumbUrl;
        file.designProps = fileNode.designProps;
        return;
      }
    });
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return arr;
};
export const searchFileinTree = (array, searchKey, searchOnFolder = false) => {
  const arr = [...array];
  const filteredArr = [];
  arr.forEach(function iter(a) {
    if (!window.flags.designListTree.searchDesignVariations && a.name.charAt(0) === ".") return; //exclude folder with .
    const fil = a.files.filter(item => {
      const fstring = searchOnFolder ? item.fullPath : item.name;
      const matchName = doesStringMatch(fstring, searchKey);
      return matchName;
    });
    filteredArr.push(...fil);
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return filteredArr;
};
export const searchExactFileinTree = (array, searchKeyPath) => {
  const arr = [...array];
  const filteredArr = [];
  arr.forEach(function iter(a) {
    //if (a.name.charAt(0) === ".") return; //exclude folder with .
    const fil = a.files.filter(item => {
      return item.fullPath.toLowerCase() === searchKeyPath.toLowerCase();
    });
    filteredArr.push(...fil);
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return filteredArr;
};

export const searchByTags = (array, tag_designs, searchKey) => {
  let arr = [...array];
  const filteredArr = [];
  searchKey = searchKey.replace(/_/g, "&");
  var filteredTagDesign = tag_designs.filter(
    tag => tag.text.toLowerCase() === searchKey.toLowerCase()
  );
  if (filteredTagDesign.length) {
    let designsPathList = filteredTagDesign[0].designs;
    if (designsPathList.length) {
      designsPathList = designsPathList.map(path => path.toLowerCase());

      arr.forEach(function iter(a) {
        //if (a.name.charAt(0) === ".") return;
        const fil = a.files.filter(item => {
          const pathOfFile = item.fullPath.replace(".ctf", "").toLowerCase();
          return designsPathList.indexOf(pathOfFile) >= 0;
        });
        filteredArr.push(...fil);
        Array.isArray(a.children) && a.children.forEach(iter);
      });
    }
  }
  //console.log("searchByTags -> filteredArr", filteredArr)

  return filteredArr;
};
export const findFolderofFile = (array, findFile) => {
  const arr = [...array];
  let folder;
  arr.forEach(function iter(a) {
    const fil = a.files.findIndex(file => {
      return file.fullPath === findFile.fullPath;
    });
    if (fil !== -1) {
      //folder = a[fil];
      folder = a;
      return;
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return folder;
};
export const getNameFromFullpath = fullPath => {
  const split = fullPath.split("/");
  const fullName = split[split.length - 1];
  const fullNameSplit = fullName.split(".");
  fullNameSplit.pop();
  return fullNameSplit.join(".");
};
export const getFolderNameFromFullpath = fullPath => {
  const split = fullPath.split("/");
  const folderName = split[split.length - 2];
  return folderName;
};
export const getcurrentCollectionFilter = (collectionFilter, fullpath) => {
  const folderfullPath = fullpath.toLowerCase();
  const selectedCollectionFilter = collectionFilter.filter(
    filtermap => folderfullPath.indexOf(filtermap.folder.toLowerCase()) >= 0
  );
  return selectedCollectionFilter;
};
export const findSimilarDesigns = (tree, node) => {
  const arr = [...tree];
  let similarDesignFolder;
  arr.forEach(function iter(a) {
    if (similarDesignFolder) return;
    if (a.name === `.${node.name}` && a.location === node.location) {
      similarDesignFolder = a;
      return;
    }
    Array.isArray(a.children) && a.children.forEach(iter);
  });
  return similarDesignFolder;
};
export const trimString = string => {
  let str = string.trim();
  const fc = str.charAt(0);
  if (fc === "-" || fc === ".") {
    str = str.substring(1);
  }
  return str;
};
// const a = getNameFromFullpath("Designs/Cubinia.ctf")
// console.log("a", a)
// const b = getNameFromFullpath("Designs/.abc/Cubinia.ctf")
// console.log("b", b)
// const c = getNameFromFullpath("Designs/.abc/Cubinia.boasd.ctf")
// console.log("c", c)
