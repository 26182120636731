import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import AppProvider from "../../../api/appProvider";
import Thumbnail from "../../molecules/Thumbnail";
import {
  useDispatchDesignList,
  designListActions,
  useDesignListState
} from "../../../reducers/designlist.reducer";
import { findSimilarDesigns } from "../../../utils/treeutils";
import { thumbnailTemplates } from "../EntryPage/defaultflags";
import TemplateDesignVars from "../../molecules/Thumbnail/TemplateDesignVars";
import TemplateVarsBottomBar from "../../molecules/Thumbnail/TemplateVarsBottomBar";
import TemplateBottomBarVars from "../../molecules/Thumbnail/TemplateBottomBarVars";
import TemplateMainDesignWithSize from "../../molecules/Thumbnail/TemplateMainDesignWithSize";

const LazyThumbnail = props => {
  const { node, onThumbnailClick, ...otherProps } = props;
  const dispatch = useDispatchDesignList();
  const designListState = useDesignListState();
  const { tree, activeVariation, selectedFile } = designListState;
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25
  });
  useEffect(() => {
    if (!inView) return;

    const loadDesignThumbnail = async () => {
      if (window.flags.designVariations.preserveVariations) {
        if (selectedFile.id && activeVariation.id) {
          //find similar designs of current node
          const similarDesigns = findSimilarDesigns(tree, node);
          if (similarDesigns) {
            //find the selected variation
            const va = activeVariation.name.replace(selectedFile.name, "");
            const requiredVariation = similarDesigns.files.find(file => {
              if (file.name.includes(va)) return true;
              return false;
            });
            //if variation is found update thumburl with the variation
            const requiredDesignThumb = requiredVariation ? requiredVariation : node;
            let fileNode = {
              ...node,
              thumbUrl: requiredDesignThumb.thumbUrl,
              designProps: requiredDesignThumb.designProps
            };
            if (!requiredDesignThumb.thumbUrl || !requiredDesignThumb.designProps) {
              try {
                const thumbs = await AppProvider.getDesignThumbnails({
                  designs: [requiredDesignThumb]
                });
                fileNode = {
                  ...fileNode,
                  thumbUrl: thumbs[0].thumbUrl,
                  designProps: thumbs[0].designProps
                };
              } catch (error) {}
            }
            dispatch({
              type: designListActions.UPDATE_FILE_PROP,
              payload: fileNode
            });
          }
        }
      } else {
        if (node.thumbUrl) return;
        const thumbs = await AppProvider.getDesignThumbnails({ designs: [node] });
        // dispatch({
        //   type: designListActions.UPDATE_FILE_PROP,
        //   payload: { ...thumbs[0] }
        // });
        if (window.flags.thumbnailTemplate === thumbnailTemplates.DesignOnly) {
          dispatch({
            type: designListActions.UPDATE_FILE_PROP,
            payload: { ...thumbs[0] }
          });
        } else {
          let newNode = thumbs[0];

          let designVariations = [];
          if (node.variations) {
            designVariations =
              node.variations.vars && node.variations.vars.length
                ? node.variations.vars
                : node.variations.colors && node.variations.colors.length
                ? node.variations.colors
                : [];
          }

          if (designVariations.length) {
            const variationThumbs = await AppProvider.getDesignThumbnails({
              designs: designVariations.slice(0, 3)
            });
            let remainingThumbs = designVariations.slice(3);

            if (node.variations.colors)
              newNode.variations.colors = [...variationThumbs, ...remainingThumbs];
            else newNode.variations.vars = [...variationThumbs, ...remainingThumbs];
          }

          dispatch({
            type: designListActions.UPDATE_FILE_PROP,
            payload: newNode
          });
        }
      }
    };
    loadDesignThumbnail();
  }, [inView, node, dispatch, selectedFile, activeVariation]);
  const handleThumbClick = (node, e) => {
    if (onThumbnailClick) {
      onThumbnailClick(node, e);
    }
  };
  return (
    <div ref={ref}>
      {renderThumbnails({
        template: window.flags.thumbnailTemplate,
        templateOptions: thumbnailTemplates,
        node,
        handleThumbClick,
        otherProps
      })}
      {/* <Thumbnail {...otherProps} thumb={node} onThumbnailClick={handleThumbClick} /> */}
    </div>
  );
};
const renderThumbnails = ({ template, templateOptions, node, handleThumbClick, otherProps }) => {
  switch (template) {
    case templateOptions.DesignOnly:
      return <Thumbnail {...otherProps} thumb={node} onThumbnailClick={handleThumbClick} />;
    case templateOptions.DesignVars:
      return (
        <TemplateDesignVars {...otherProps} thumb={node} onThumbnailClick={handleThumbClick} />
      );
    case templateOptions.VarsBottomBar:
      return (
        <TemplateVarsBottomBar {...otherProps} thumb={node} onThumbnailClick={handleThumbClick} />
      );
    case templateOptions.BottomBarVars:
      return (
        <TemplateBottomBarVars {...otherProps} thumb={node} onThumbnailClick={handleThumbClick}/>
      );
      case templateOptions.MainDesignWithSize:
        return (
          <TemplateMainDesignWithSize {...otherProps} thumb={node} onThumbnailClick={handleThumbClick}/>
        );
    default:
      return <Thumbnail {...otherProps} thumb={node} onThumbnailClick={handleThumbClick} />;
  }
};

export default LazyThumbnail;
