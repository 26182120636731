import React from "react";
import AppProvider from "../../../api/appProvider";
import DesignList from "../DesignList";
import Designviews from "../Designviews";
import RoomList from "../RoomList";

const IllustrationList = () => {
  return (
    <>
      {window.flags.visualizations &&
        window.flags.visualizations.renderDesignsInVisualizationTab &&
        !AppProvider.isEcatMode && <DesignList className="at-visualization-tab-designs" />}
      {window.flags.visualizations && window.flags.visualizations.renderDesignViews && (
        <Designviews />
      )}
      <RoomList />
    </>
  );
};

export default IllustrationList;
