import React, { useState } from "react";
import { Collapse, H5 } from "@blueprintjs/core";
import PropTypes from "prop-types";
import AtIcon from "../../atoms/AtIcon";
import classNames from "classnames";

export const SectionHeading = props => {
  const { title, additional, icon, handleHeadingClick, className } = props;
  return (
    <div className={classNames("at-section-heading-area", className)}>
      <span className="at-section-label" onClick={handleHeadingClick}>
        <H5>{title}</H5>
      </span>
      {additional}
      {icon}
    </div>
  );
};
export const CollapsibleSectionHeading = props => {
  const { isOpen, handleCollapseIconClick } = props;
  const collapseIcon = (
    <AtIcon
      title={`${isOpen ? "Collapse" : "Expand"}`}
      className={`at-collapse-toggle ${isOpen ? "open" : ""}`}
      icon="chevron-down"
      onClick={handleCollapseIconClick}
    />
  );
  return <SectionHeading {...props} icon={collapseIcon}></SectionHeading>;
};
const CustomCollapse = props => {
  const { isOpenByDefault, children, onCollapse, onExpand } = props;
  const [isOpen, setisOpen] = useState(isOpenByDefault);
  const handleCollapseIconClick = e => {
    if (isOpen && onCollapse) onCollapse(e);
    if (!isOpen && onExpand) onExpand(e);
    setisOpen(!isOpen);
  };
  const collapseIcon = (
    <AtIcon
      title={`${isOpen ? "Collapse" : "Expand"}`}
      className={`at-collapse-toggle ${isOpen ? "open" : ""}`}
      icon="chevron-down"
      onClick={handleCollapseIconClick}
    />
  );
  return (
    <>
      <SectionHeading
        {...props}
        handleHeadingClick={handleCollapseIconClick}
        icon={collapseIcon}
      ></SectionHeading>
      <div className="at-section-container">
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    </>
  );
};
CustomCollapse.propTypes = {
  title: PropTypes.string,
  isOpenByDefault: PropTypes.bool
};
CustomCollapse.defaultProps = {
  isOpenByDefault: true
};
export default CustomCollapse;
