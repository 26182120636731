import React from "react";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_ORDERSHEETDETAILS = "SET_ORDERSHEETDETAILS";
const SET_ORDERSHEET_FORMFIELDS = "SET_ORDERSHEET_FORMFIELDS";
const SET_DAVISRUGS_RUGTECHNIQUE = "SET_DAVISRUGS_RUGTECHNIQUE";
const SET_DAVISRUGS_RUGTYPE = "SET_DAVISRUGS_RUGTYPE";
const SET_MATERIAL_SELECTIONVALUE = "SET_MATERIAL_SELECTIONVALUE";
const SET_PRICEDETAILS_JAIPUR = "SET_PRICEDETAILS_JAIPUR";

const ordersheetDetailActions = {
  SET_ORDERSHEETDETAILS,
  SET_ORDERSHEET_FORMFIELDS,
  SET_DAVISRUGS_RUGTECHNIQUE,
  SET_DAVISRUGS_RUGTYPE,
  SET_MATERIAL_SELECTIONVALUE,
  SET_PRICEDETAILS_JAIPUR
};

const ordersheetDetailState = {
  ordersheetDetails: {},
  formFields: {},
  accountSpecific: {
    davisRugs: {
      rugTechnique: "",
      rugType: ""
    },
    allInOneMaterialSelectionValue: "",
    priceDetails_Jaipur:null
  }
};

const ordersheetDetailReducer = (state, action) => {
  // console.log(action.type, action.payload)
  switch (action.type) {
    case SET_ORDERSHEETDETAILS:
      return { ...state, ordersheetDetails: action.payload };
    case SET_ORDERSHEET_FORMFIELDS:
      return { ...state, formFields: action.payload };
    case SET_DAVISRUGS_RUGTECHNIQUE:
      return setDavisrugsTechnique(state, action);
    case SET_DAVISRUGS_RUGTYPE:
      return {
        ...state,
        accountSpecific: {
          ...state.accountSpecific,
          davisRugs: { ...state.accountSpecific.davisRugs, rugType: action.payload }
        }
      };
    case SET_MATERIAL_SELECTIONVALUE:
      return {
        ...state,
        accountSpecific: {
          ...state.accountSpecific,
          allInOneMaterialSelectionValue: action.payload
        }
      };
    case SET_PRICEDETAILS_JAIPUR:
      return{
        ...state,
        priceDetails_Jaipur:  action.payload
      }
    default:
      return state;
  }
};
const setDavisrugsTechnique = (state, action) => {
  var data = {
    ...state,
    accountSpecific: {
      ...state.accountSpecific,
      davisRugs: { ...state.accountSpecific.davisRugs, rugTechnique: action.payload }
    }
  };
  //console.log("TCL: setDavisrugsTechnique -> data", data);

  return data;
};
function OrdersheetDetailStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(ordersheetDetailReducer, ordersheetDetailState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useOrdersheetDetailState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchordersheetDetail() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export {
  OrdersheetDetailStateProvider,
  useOrdersheetDetailState,
  useDispatchordersheetDetail,
  ordersheetDetailActions
};
