import React, { useRef } from "react";
import { useMount } from "react-use";
import { overlayTextureinCanvas, readImage } from "../../../utils/domUtils";
import { getPomUrl } from "../../../utils/colorutils";
const ColorPreview = props => {
  const {
    style,
    colorName,
    color,
    material,
    colorTextures,
    renderTexturedPreview,
    colorTab,
    showColorPoms = false,
    colorPomsShape
  } = props;
  const canvasRef = useRef();
  useMount(() => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 160, 160);

    // console.log(color, colorName);
    if (!renderTexturedPreview && !showColorPoms) return;
    const { tabs, baseUrl } = window.flags.colorSelectionBox.customColorPreviews;
    const imageurl = showColorPoms
      ? getPomUrl(color, material, colorPomsShape)
      : `${baseUrl}/${colorTab}/${colorName}.jpg`;
    //console.log("useMount -> colorTextures", colorTextures);

    if (tabs.includes(colorTab) || showColorPoms) {
      readImage(imageurl)
        .then(colorImage => {
          ctx.drawImage(colorImage, 0, 0, 160, 160);
        })
        .catch(err => {});
    } else {
      const img = colorTextures.find(item => item.index === material);
      if (img && img.image)
        overlayTextureinCanvas({
          image: img.image,
          color,
          canvasSize: 160,
          canvas: canvasRef.current
        });
    }
  });
  return (
    <div className="at-texture-sample-area popover" style={style}>
      <canvas className="at-texture-sample-box" width={160} height={160} ref={canvasRef}></canvas>
      <div className="at-texture-sample-colorname">{colorName}</div>
    </div>
  );
};

export default ColorPreview;
