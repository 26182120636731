import React, { useState } from "react";
import AtDialog from "../../molecules/AtDialog";
import { Button, Toast, Toaster } from "@blueprintjs/core";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { copyToClipboard } from "../../../utils/domUtils";
var QRCode = require("qrcode.react");
const QRDialog = props => {
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const designDetailState = useDesignDetailState();
  const [toastProps, setToastProps] = useState(false);
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_QR_DIALOG, payload: false });
    setToastProps(false);
  };

  const handleClickQR = () => {
    copyToClipboard(designDetailState.encodedUrl);
  };

  const handleDownloadQR = () => {
    const canvas = document.getElementById("QRcodeCanvas");
    canvas.toBlob(function(blob) {
      // Create a link to download the image
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${designDetailState.designName}-QR.png`; // You can change the file format here (e.g., 'captured_image.jpg')
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showQRDialog}
      className="at-confirmation-dialog"
      size="xs"
    >
      <Toaster position="bottom">
        {toastProps && (
          <Toast
            message={strings.toastMessages.linkCopied}
            intent="success"
            onDismiss={() => setToastProps(false)}
          />
        )}
      </Toaster>
      <div className="at-dialog-area at-confirmation-dialog-area">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <div className="QRDialogMainDiv">
          <div className="QRDialogTopDiv">
            <div className="QRTopLeftDiv">
              <h2 className="QRTopHeading">Scan QR code</h2>
            </div>
            <div className="QRTopRightDiv" onClick={handleClickQR}>
              <QRCode
                value={designDetailState.encodedUrl}
                size={150}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
              />
              <QRCode
                value={designDetailState.encodedUrl}
                size={660}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                id="QRcodeCanvas"
                style={{ display: "none" }}
              />
            </div>
            <p className="QRpara">{strings.createYourRug.QRScanText}</p>
            <div className="cyr-qrTextDiv">
              <AtButton text="Copy link" intent="primary" onClick={handleClickQR} />
              <AtButton text="Download" intent="primary" onClick={handleDownloadQR} />
            </div>
          </div>
        </div>
      </div>
    </AtDialog>
  );
};

QRDialog.propTypes = {};

export default QRDialog;
