import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
// import {  } from "./../../constants";

const LogoImg = styled.img`
  position: absolute;
  top: 43%;
  left: 50%;
  z-index: 21;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  width: 15rem;
  max-height: 4.5rem;
  max-width: 16rem;
  width: unset;
  animation: entry 2s ease-in-out forwards;
  padding: 0.5rem;

  background: var(--bg-primary);

  @keyframes entry {
    0% {
      top: 50%;
      transform: translate(-50%, -50%) scale(1.2, 1.2);
    }
    50% {
      top: 50%;
      transform: translate(-50%, -50%) scale(1, 1);
    }
    100% {
      top: 43%;
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }

  &.exit {
    animation: logoTotop 2s ease-in-out forwards;
    @keyframes logoTotop {
      0% {
        top: 40%;
      }
      100% {
        top: 4rem;
      }
    }
  }
  &.show {
    top: 4rem;
    animation: showLogo 1s ease-in-out forwards;
    opacity: 1;
  }
  &.hide {
    top: 4rem;
    animation: hideLogo 1s ease-in-out forwards;
    opacity: 0;
  }
  @keyframes showLogo {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes hideLogo {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const LoaderWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  opacity: 0;
  z-index: 20;
  &.entry {
    opacity: 1;
    animation: loaderwrapanimation 2s ease-in-out forwards;
    @keyframes loaderwrapanimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  &.exit {
    animation: exitLoaderWrap 2s ease-in-out forwards;
    @keyframes exitLoaderWrap {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;

const LoaderSpan = styled.span`
  display: block;
  color: white;
  position: relative;
  &.entry {
    animation: loaderspananimation 2s ease-in-out forwards;
    @keyframes loaderspananimation {
      0% {
        top: 0;
      }
      100% {
        top: 10px;
      }
    }
  }
`;
const GradientDiv = styled.div`
  height: 100%;
  width: 100%;
  ${props => (props.firstBlurImg !== "" ? "opacity:0.6" : "opacity: 0.8")};
  transition: opacity 1s;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, #444 100%);
  display: block;
  opacity: 1;
  pointer-events: none;
  background: ${props => (props.firstBlurImg ? `url(${props.firstBlurImg})` : `black`)};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: 19;
  &.exit {
    animation: exitGradient 3s ease-in-out forwards;
    @keyframes exitGradient {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
const LdsRing = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  &.exit {
    animation: exitLoaderAnimation 2s ease-in-out forwards;
    @keyframes exitLoaderAnimation {
      0% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1.5, 1.5);
      }
    }
  }

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffffff08 #ffffff08 #ffffff #ffffff08;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const IntroLoader = props => {
  const { logoUrl, logoVal, exit, firstBlurImg } = props;
  const [loaderClass, setloaderClass] = useState("");
  // const [loaderSpanText, setloaderSpanText] = useState("Preparing Cinematic Mode...");
  const loaderSpanText = "Preparing Cinematic Mode...";
  const [logoClass, setlogoClass] = useState("");
  const imgRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setloaderClass("entry");
      setlogoClass("entry");
      if (exit) {
        setTimeout(() => {
          setloaderClass("exit");
          setlogoClass("exit");
        }, 300);
      }
    }, 1100);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (exit) {
      setloaderClass("exit");
      setlogoClass("exit");
    } else {
      setloaderClass("entry");
    }
    return () => {};
  }, [exit]);

  useEffect(() => {
    if (logoVal) {
      if (logoClass === "exit" || logoClass === "hide") {
        setlogoClass("show");
      }
    } else {
      if (logoClass === "exit" || logoClass === "show") {
        setlogoClass("hide");
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoVal]);

  return (
    <React.Fragment>
      <LogoImg ref={imgRef} src={logoUrl} className={logoClass}></LogoImg>
      <GradientDiv className={loaderClass} firstBlurImg={firstBlurImg} />
      <LoaderWrap className={loaderClass}>
        <LdsRing className={loaderClass}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LdsRing>
        <LoaderSpan className={loaderClass}>{loaderSpanText}</LoaderSpan>
      </LoaderWrap>
    </React.Fragment>
  );
};
export default IntroLoader;
