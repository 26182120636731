import classNames from "classnames";
import React, { useEffect, useState } from "react";

const AtRuler = props => {
  const {
    startingValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    segments = 10,
    distancePixels,
    width = 650,
    height = 100,
    unit = "cm",
    font = "0.875rem Arial",
    color = "#fff",
    changeRulerUnit
  } = props;
  const [currentState, setCurrentState] = useState(unit);
  const [toggleSequence, setToggleSequence] = useState(["cm", "ft", ""]);

  useEffect(() => {
    if (unit === "cm") {
      setToggleSequence(["cm", "ft", ""]);
    } else {
      setToggleSequence(["ft", "cm", ""]);
    }
  }, []);
  const drawScale = (x, y) => {
    const canvas = document.getElementById("myRulerCanvas");

    if (canvas) {
      let ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.font = font;

      let linesInASegment = 10;
      let totalLinesNum = linesInASegment * segments;
      for (let i = 0; i <= totalLinesNum; i++) {
        const pos = i;
        ctx.moveTo(x, y);
        const longerLines = linesInASegment;
        if (pos % longerLines === 0) {
          ctx.lineTo(x, height * 0.5 + y);
          if (Math.abs(canvas.width - x) > 40) {
            const xPos = i === 0 ? x : x - 3;
            ctx.fillText(`${startingValues[pos / linesInASegment]}`, xPos, height);
          }
        } else if (pos % (longerLines / 2) === 0) ctx.lineTo(x, height * 0.4 + y);
        else if (pos % (longerLines / 10) === 0) ctx.lineTo(x, height * 0.2 + y);
        if (pos === totalLinesNum) {
          ctx.lineTo(x, 0);
        }
        const gap = distancePixels ? distancePixels / linesInASegment : 20;
        const getGap = window.flags.visualizations.maximizeGapInRulerForHighRes;
        x += gap * getGap;
      }
      const unitText = unit === "ft" ? "inch" : unit;
      ctx.fillText(`${unitText}`, canvas.width - 25, height);

      ctx.lineTo(0, 0);
      ctx.strokeStyle = color;
      ctx.stroke();

      if (unit === "") {
        const canvas = document.getElementById("myRulerCanvas");
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  useEffect(() => {
    drawScale(0, 0);
  }, [distancePixels, startingValues, width]);

  const handleRulerClick = () => {
    let index = toggleSequence.indexOf(currentState);
    index = index === -1 || index === toggleSequence.length - 1 ? 0 : index + 1;
    const newState = toggleSequence[index];
    setCurrentState(newState);
    if (newState !== "") {
      changeRulerUnit(newState);
    } else {
      const canvas = document.getElementById("myRulerCanvas");
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      changeRulerUnit("");
    }
  };

  return (
    <div className={classNames("at-ruler-container")}>
      <canvas
        id="myRulerCanvas"
        className={classNames({ invisible: currentState === "" })}
        width={width}
        height={height}
        onClick={() => {
          if (changeRulerUnit) handleRulerClick();
        }}
      ></canvas>
    </div>
  );
};

AtRuler.propTypes = {};

export default AtRuler;
