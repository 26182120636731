import { Classes } from "@blueprintjs/core";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { handleNavigation } from "../../../utils/domUtils";
import AtIcon from "../../atoms/AtIcon";
import LazyThumbnail from "../../pages/DesignBrowser/LazyThumbnail";
import { thumbnailTemplates } from "../../pages/EntryPage/defaultflags";
import DesignSearchBar from "../DesignSearch/DesignSearchBar";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import strings from "../../../strings";
import DesignSearchResults from "../DesignSearch/DesignSearchResults";

const Collection = props => {
  const {
    Tree,
    handleThumbnailClick,
    level = 0,
    defaultFolder,
    selectedFile,
    className = "",
    showNavigatorArrows = true
  } = props;
  const [nagivatorArrowsNeeded, setNagivatorArrowsNeeded] = useState(false);
  const thumbContainerRef = useRef(null);
  const designListState = useDesignListState();
  const { sizeFilter, tagFilter } = designListState;
  const Folders = Tree.children || [];
  const nodeItems = Folders.filter(
    node => node && (node.children.length || node.files.length) && node.name.charAt(0) !== "."
  );
  const [selectedFolder, setSelectedFolder] = useState(
    defaultFolder ? defaultFolder : nodeItems.length ? nodeItems[0] : null
  );
  const [textToSearch, setTextToSearch] = useState("");
  const [showSearchResults, setShowSearchResults] = useState("none");
  useEffect(() => {
    setTimeout(() => {
      let isNagivatorArrowsNeeded = false;
      const collectionContainer = document.querySelector(
        ".design-controller__thumbs-area__container"
      );
      if (
        collectionContainer &&
        collectionContainer.clientWidth < collectionContainer.scrollWidth
      ) {
        isNagivatorArrowsNeeded = true;
      }
      setNagivatorArrowsNeeded(isNagivatorArrowsNeeded);
    }, 1000);

    const folderName = selectedFile?.location.split("/").pop();
    const node = Folders.find(item => item.name === folderName);
    if (node) {
      setSelectedFolder(node);
    }
  }, []);
  const onFolderClick = node => {
    setSelectedFolder(node);
  };
  const handleNavigator = direction => {
    const elem = thumbContainerRef.current;
    handleNavigation({ elem, direction });
  };

  const handleSearch = e => {
    let text = e.target.value;
    setTextToSearch(text);
    if (text.length > 2) {
      setShowSearchResults("design");
    } else if (text === "") {
      if (!sizeFilter.find(item => !!item.selected)) setShowSearchResults("none");
      //else if (tagFilter === "") setShowSearchResults("none");
      else {
        setShowSearchResults("design");
      }
      setTextToSearch("");
    }
  };

  const handleCancel = () => {
    setTextToSearch("");
    if (tagFilter === "") setShowSearchResults("none");
  };

  const handleFilterDesigns = (filter, e) => {
    if (!filter && textToSearch.length <= 2) {
      setShowSearchResults("none");
    }
    if (showSearchResults !== "design") setShowSearchResults("design");
  };

  return (
    <>
      <div className="searchBarDiv">
        <DesignSearchBar
          handleInputChange={e => handleSearch(e)}
          placeholder={strings.design.searchDesigns}
          textToSearch={textToSearch}
          handleCancel={handleCancel}
          handleFilter={handleFilterDesigns}
          type="design"
        />
      </div>
      <DesignSearchResults
        hidden={showSearchResults !== "design"}
        id="designSearchResults"
        textToSearch={textToSearch}
        type="design"
      />

      {showSearchResults == "none" && (
        <>
          <ul
            className={classNames(
              Classes.TREE_NODE_LIST,
              "tree-content-folders-container",
              `${Classes.TREE_NODE_LIST}-${level}`,
              className
            )}
          >
            {Tree && Tree.files?.length ? (
              <div
                className={classNames("tree-content-folders base-folder", {
                  selected: selectedFolder.name === Tree.name
                })}
                onClick={() => onFolderClick(Tree)}
                datatitle={"Home"}
              >
                <li className={classNames(Classes.TREE_NODE, `${Classes.TREE_NODE}-${level}`)}>
                  <span className={"bp3-tree-node-label"}>{"Home"}</span>
                </li>
              </div>
            ) : null}
            {nodeItems.map(
              (node, index) =>
                node.files.length != 0 && (
                  <div
                    className={classNames("tree-content-folders", {
                      selected: selectedFolder.name === node.name
                    })}
                    key={index}
                    onClick={() => onFolderClick(node)}
                    datatitle={node.name || ""}
                  >
                    <li className={classNames(Classes.TREE_NODE, `${Classes.TREE_NODE}-${level}`)}>
                      <span className={"bp3-tree-node-label"}>{node.name}</span>
                    </li>
                  </div>
                )
            )}
          </ul>

          <div className="cyr-thumbs-container">
            {selectedFolder && (
              <div
                className={classNames("design-controller__thumbs-area__container", {
                  templateGrid: window.flags.thumbnailTemplate !== thumbnailTemplates.DesignOnly
                })}
                ref={thumbContainerRef}
              >
                {selectedFolder.files.map((file, index) => (
                  <LazyThumbnail
                    aspect="portrait"
                    onThumbnailClick={() =>
                      handleThumbnailClick && handleThumbnailClick(file, index)
                    }
                    key={index}
                    node={file}
                    className={classNames({ active: file.fullPath === selectedFile?.fullPath })}
                  />
                ))}
              </div>
            )}
            {showNavigatorArrows && nagivatorArrowsNeeded && (
              <div className="cyr-arrows-container">
                <AtIcon
                  className="navigator-arrows right"
                  icon="chevron-right"
                  onClick={() => handleNavigator("right")}
                />
                <AtIcon
                  className="navigator-arrows"
                  icon="chevron-left"
                  onClick={() => handleNavigator("left")}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

Collection.propTypes = {};

export default Collection;
