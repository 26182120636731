import React, { useEffect, useState } from "react";
import { calculateColorDelta, hexToRGB, previewMaterial } from "../../../utils/colorutils";
import strings from "../../../strings";
import { H6, Portal } from "@blueprintjs/core";
import SwatchBox from "./SwatchBox";
import ColorPreview from "./ColorPreview";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";

const SimilarColors = props => {
  const {
    activeColor,
    colorList,
    onColorSwatchClick,
    numColors = 5,
    collectionName,
    colorTextures,
    swatchSize,
    renderTexturedPreview = true
  } = props;
  const [similarColors, setSimilarColors] = useState(null);
  const [hoveredBox, setHoveredBox] = useState(null);
  const designDetailState = useDesignDetailState();
  const { designDetails, selectedColor, yarnIndex } = designDetailState;

  useEffect(() => {
    if (!activeColor) return;
    let color = activeColor;
    if (!activeColor.r || !activeColor.g || !activeColor.b) {
      const { r = 0, g = 0, b = 0 } = hexToRGB(activeColor.Color);
      activeColor.r = r;
      activeColor.g = g;
      activeColor.b = b;
    }
    const deltaArr = colorList.map(item => {
      return { ...item, delta: calculateColorDelta(item, color) };
    });
    var similarColorsList = deltaArr.sort((a, b) => b.delta - a.delta).slice(1, 1 + numColors);
    setSimilarColors(similarColorsList);
  }, [activeColor, colorList, numColors]);

  return (
    similarColors && (
      <div className="at-similar-color-area">
        <H6>{strings.color.similarColors}</H6>
        <div className="at-similar-color-content">
          {similarColors.map((colorRow, index) => (
            <SwatchBox
              key={index}
              swatchSize={swatchSize || 2.5}
              swatchSpace={0.15}
              colorRow={colorRow}
              onColorSwatchClick={e => {
                onColorSwatchClick(colorRow, e);
              }}
              active={colorRow === activeColor}
              handleHover={elem => setHoveredBox({ elem, colorRow })}
              showColorPoms={window.flags.colorSelectionBox.showColorPomsImages}
              colorPomsShape="round"
            />
          ))}

          <Portal container={document.getElementById("app-main")}>
            {hoveredBox && hoveredBox.elem && (
              <ColorPreview
                renderTexturedPreview={renderTexturedPreview}
                material={previewMaterial({
                  texture: hoveredBox.colorRow.Texture,
                  hoveredBox,
                  selectedColor,
                  yarnIndex,
                  DesignColors: designDetails.DesignColors
                })}
                colorTextures={colorTextures}
                colorName={hoveredBox.colorRow.ColorName}
                color={hoveredBox.colorRow.Color}
                colorTab={collectionName}
                style={{
                  top: `${hoveredBox.elem.getBoundingClientRect().top +
                    window.scrollY +
                    swatchSize * 16 +
                    5}px`,
                  left: `${hoveredBox.elem.getBoundingClientRect().left + window.scrollX}px`
                }}
                showColorPoms={window.flags.colorSelectionBox.showColorPomsImages}
                colorPomsShape="square"
              />
            )}
          </Portal>
        </div>
      </div>
    )
  );
};
export default SimilarColors;
