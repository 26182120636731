/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import RoomViewHelper from "./roomviewhelper";
import { useMount, useWindowSize } from "react-use";
import InputCanvas from "../../atoms/InputCanvas";
import classNames from "classnames";
import { useFullscreen, useToggle } from "react-use";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { canvasToBlobPromise, createCanvas, downloadImageData } from "../../../utils/canvasutils";
import { makeid } from "../../../utils/utils";
import AppProvider, { myrugStudioSaveUrl } from "../../../api/appProvider";
import {
  roomViewActions,
  useDispatchRoomView,
  useRoomViewState
} from "../../../reducers/roomview.reducer";
import { AreaSwatch } from "../../molecules/AreaSwatch";
import { colorListActions, useDispatchColorList } from "../../../reducers/colors.reducer";
import AtIcon from "../../atoms/AtIcon";
import {
  mainUiActions,
  pageViews,
  useUiDispatch,
  useUiState
} from "../../../reducers/mainui.reducer";
import { convertUnit } from "../../../utils/utils";
import GifHelper from "./gifHelper";
import AtButton from "../../atoms/AtButton";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { MD5 } from "../../../utils/md5";
import {
  useDispatchVisualization,
  useVisualizationState,
  visualizationsActions
} from "../../../reducers/visualizations.reducer";
let roomViewHelper = new RoomViewHelper();
let gifHelper = new GifHelper();
let designRendered = false;
let designRendering = false;
let sizeAnimationLoop;
const Container = styled.div`
  width: 100%;
  height: 100%;
  & > * {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: inherit !important;
    height: auto !important; */
  }
`;
const Canvas = styled.canvas`
  z-index: ${props => props.zIndex};
  pointer-events: ${props => (props.pointerEvent ? "all" : "none")};
`;
const BgVideo = styled.video`
  z-index: ${props => props.zIndex};
  pointer-events: ${props => (props.pointerEvent ? "all" : "none")};
  opacity: ${props => (props.show ? 1 : 0)};
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-image: none;
`;

function usePrevious(value) {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
const getDominantColor = designDetails => {
  if (!designDetails || !designDetails.DesignColors) return null;
  const smartKnot = designDetails.DesignColors.map(c => c.Knots).sort((a, b) => b - a);
  const smartIndex = smartKnot.length > 2 ? (smartKnot.length === 3 ? 1 : 2) : smartKnot.length - 1;
  const domC = designDetails.DesignColors.find(item => item.Knots === smartKnot[smartIndex]);
  if (domC) return domC.Color;
  else return null;
};
let roomsIndex = 0;
let createCombinationRunning = false;
let totalFilesCount = 0;
let origFiles = [];
let tilesUpdating = false;

let carpetScale = [1, 1];

const RoomViewNew = props => {
  const {
    roomData,
    isIdle,
    handleActiveClick,
    activeColor,
    customDesignUrl,
    customDesignOutline,
    setFirstLoadingOnRender,
    onRenderingComplete,
    setScaledUpHeight,
    setScaledUpWidth,
    setIsFullScreen = () => {}
  } = props;
  const { Name: roomName, Files: files, config, baseUrl } = roomData;
  const [isRendering, setIsRendering] = useState(true);

  const containerRef = useRef(null);
  const fullScreenRef = useRef(null);
  const bgCanvasRef = useRef(null);
  const threeCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const shadowCanvasRef = useRef(null);
  const inputCanvasRef = useRef(null);
  const transitionCanvasRef = useRef();
  const floatingOptionsRef = useRef();
  const videoRef = useRef();

  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();

  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();

  const illustrationListState = useVisualizationState();
  const illustrationListDispatch = useDispatchVisualization();

  const designDetailState = useDesignDetailState();
  const prevDesignDetails = usePrevious(designDetailState.designDetails);
  const prevRoomDetails = usePrevious(roomData);
  const prevRoomType = usePrevious(config.roomType);
  const dispatchDesignDetails = useDispatchDesignDetail();
  const roomviewState = useRoomViewState();
  const dispatchRoomView = useDispatchRoomView();
  const dispatchColorList = useDispatchColorList();
  const [mouseDownPosition, setMouseDownPosition] = useState(null);
  const windowSize = useWindowSize();
  const {
    designDetails,
    updateDesignTiles,
    updateNormapTiles,
    hash,
    fullpath,
    designDimsOrig
  } = designDetailState;
  const { floorOptions, carpetOptions } = roomviewState;
  const { rotation: carpetRotation, position: carpetPosition } = carpetOptions;
  const { activeFloor } = floorOptions;
  const [isRoomviewInFullScreen, toggleIsRoomviewInFullScreen] = useToggle(false);
  const { similarDesignsFolder, selectedFile } = designListState;
  const [isPaused, setIsPaused] = useState(false);
  const [isActiveRoomValue, setIsActiveRoomValue] = useState(0);
  const isStudioTemplate = window.flags.homeTemplate === pageViews.STUDIOENTRY;

  useFullscreen(fullScreenRef, isRoomviewInFullScreen, {
    onClose: () => {
      toggleIsRoomviewInFullScreen(false);
      setIsPaused(false);
    }
  });
  const isBorderRugs =
    window.flags.visualizations.isBorderRugs && window.flags.ordersheet.repeatRugInArea;

  let designUnit = window.initialData.unit,
    centerDesignUrl = window.initialData.centerDesignUrl,
    borderSize = window.initialData.borderSize || 0,
    borderDesignWid = window.initialData.borderDesignWid,
    borderDesignHgt = window.initialData.borderDesignHgt,
    designWidth = window.initialData.designWidth,
    designHeight = window.initialData.designHeight;

  useMount(() => {
    const canvasConfig = {
      bgCanvas: bgCanvasRef.current,
      threeCanvas: threeCanvasRef.current,
      maskCanvas: maskCanvasRef.current,
      shadowCanvas: shadowCanvasRef.current,
      container: containerRef.current,
      inputCanvas: inputCanvasRef.current,
      transitionCanvas: transitionCanvasRef.current,
      floatingOptionsContainer: floatingOptionsRef.current,
      bgVideo: videoRef.current
    };
    roomViewHelper.initCanvas(canvasConfig);

    const onWindowMessage = async e => {
      if (e.origin === window.location.origin) return;
      const {
        runSizeAnimation,
        stopSizeAnimation,
        downloadGifNum,
        ratio,
        sizeArray,
        Unit,
        animationDuration
      } = e.data;
      if (downloadGifNum) {
        gifHelper.clear();
        await gifHelper
          .render({ download: true, totalFrames: downloadGifNum, frameDuration: animationDuration })
          .then(() => {
            if (window.flags.postToParentWhenLoaded)
              window.parent.postMessage("gif render success", "*");
          })
          .catch(() => {
            if (window.flags.postToParentWhenLoaded)
              window.parent.postMessage("gif render fail", "*");
          });
        return;
      }
      if (stopSizeAnimation) {
        startSizeAnimation({ stop: true });
        return;
      }
      if (!runSizeAnimation) return;
      startSizeAnimation({ ratio, sizeArray, Unit, animationDuration });
    };
    window.addEventListener("message", onWindowMessage);

    return () => {
      window.removeEventListener("message", onWindowMessage);
    };
  });

  useEffect(() => {
    roomViewHelper.resize(windowSize);
  }, [windowSize]);
  useEffect(() => {
    if (isRendering) return;
    if (!activeColor) return;
    roomViewHelper.updateBackground({ dominantColorHex: activeColor.Color });
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
  }, [activeColor]);
  useEffect(() => {
    carpetScale = [1, 1];
  }, [illustrationListState.activeNode]);

  useEffect(() => {
    if (isRendering || !activeFloor || designDetailState.loading) return;

    const updateFloor = async () => {
      roomViewHelper.makeTransitionCanvas();
      setLoading(true);
      await roomViewHelper.renderFloor(activeFloor);
      await roomViewHelper.updateShadow();
      setLoading(false);
    };
    updateFloor();
  }, [activeFloor]);
  useEffect(() => {
    if (isRendering || designDetailState.loading || carpetRotation.length !== 3) return;
    roomViewHelper.updateCarpetRotation(carpetRotation);
    roomViewHelper.updateShadow();
  }, [carpetRotation]);
  useEffect(() => {
    if (isRendering || designDetailState.loading || carpetPosition.length !== 3) return;
    roomViewHelper.updateCarpetPosition(carpetPosition);
    roomViewHelper.updateShadow();
  }, [carpetPosition]);

  const startSizeAnimation = async ({ stop, ratio, sizeArray, Unit, animationDuration }) => {
    const renderFloorInRoom = floorOptions => {
      if (!floorOptions) return Promise.resolve();
      const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
      return roomViewHelper.renderFloor(floor);
    };
    if (!stop) {
      gifHelper.clear();
      sizeAnimationLoop = null;
    } else {
      return;
    }
    let index = 0;
    let thisloopid = makeid(5);
    sizeAnimationLoop = thisloopid;
    let loop = setInterval(async () => {
      if (sizeAnimationLoop !== thisloopid) {
        clearInterval(loop);
      }
      let { PhysicalWidth, PhysicalHeight } = sizeArray[index];
      if (!PhysicalWidth && !PhysicalHeight) return;
      if ((!PhysicalWidth || !PhysicalHeight) && !ratio) return;
      if (!PhysicalWidth) {
        PhysicalWidth = PhysicalHeight * ratio;
      }
      if (!PhysicalHeight) {
        PhysicalHeight = PhysicalWidth / ratio;
      }
      await roomViewHelper.changeRenderedDesignSize({ PhysicalWidth, PhysicalHeight, Unit });
      if (activeFloor) await renderFloorInRoom(config.floorOptions);
      await roomViewHelper.updateShadow();
      if (gifHelper.canaddFrame()) {
        const renderCanvas = await roomViewHelper.renderinCanvas({ width: 1920, height: 1080 });
        gifHelper.addFrame(renderCanvas);
      }
      if (index === sizeArray.length - 1) index = 0;
      else ++index;
    }, animationDuration * 1000);
  };

  const generateHash = (details, fullpath = "") => {
    const des = JSON.stringify(details);
    return MD5(des + fullpath);
  };

  window.getDesigns = async ({ folder }) => {
    const nestedDesignList = await AppProvider.fetchDesignList({ struct: false });
    let origFiles = nestedDesignList
      .filter(file => file.Type === "file" && file.FullPath.indexOf("/.") === -1)
      .map(file => {
        return { ...file, fullPath: file.FullPath, name: file.Name };
      });
    if (folder) {
      const foldername = folder.toLowerCase();
      origFiles = origFiles.filter(file => {
        if (file.FullPath.toLowerCase().indexOf(foldername) !== -1) return file;
      });
    }
    return origFiles;
  };
  window.changeDesign = async ({ folder }) => {
    let files;
    if (!origFiles.length) {
      files = await window.getDesigns({ folder }); //tree[0].files;
    } else {
      files = origFiles;
    }
    let newFile;
    if (totalFilesCount === 0) {
      let nextFileIndex = files.findIndex(file => file.FullPath === selectedFile.fullPath);
      totalFilesCount = nextFileIndex + 1;
      newFile = files[totalFilesCount];
    } else {
      ++totalFilesCount;
      newFile = files[totalFilesCount];
    }
    if (!newFile) {
      totalFilesCount = 0;
      alert("all designs complete");
      return;
    }
    if (!newFile.designProps) {
      const designProps = await AppProvider.getDesignThumbnails({ designs: [newFile] });
      newFile = { ...newFile, ...designProps };

      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: { selectedFile: newFile, activeVariation: null }
      });
    } else {
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: { selectedFile: newFile, activeVariation: null }
      });
    }
  };

  useEffect(() => {
    if (selectedFile && createCombinationRunning) {
      window.createCombination({});
    }
  }, [selectedFile]);

  window.pauseCombinationCreation = () => {
    createCombinationRunning = false;
  };
  window.createCombination = async props => {
    const folder = props && props.folder ? props.folder : null;
    console.log("window.createCombination for -> folder", folder);
    createCombinationRunning = false;
    const roomsList = illustrationListState.illustrations;
    console.log("window.createCombination= -> roomsList", roomsList);
    const variationsList = [selectedFile, ...similarDesignsFolder.files];
    const variationsPropsList = await AppProvider.getDesignThumbnails({ designs: variationsList });
    console.log("selected file and variations List", variationsPropsList);

    const renderFloorInRoom = floorOptions => {
      if (!floorOptions) return Promise.resolve();
      const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
      return roomViewHelper.renderFloor(floor);
    };
    let index = 0;
    let thisloopid = makeid(5);
    const animationLoop = thisloopid;
    let loop = setInterval(async () => {
      if (animationLoop !== thisloopid) {
        clearInterval(loop);
      }
      console.log("tilesUpdating", tilesUpdating);
      if (tilesUpdating) {
        return;
      }
      if (!variationsPropsList[index] || !variationsPropsList[index].designProps) return;

      tilesUpdating = true;
      if (roomViewHelper.patchImage) {
        roomViewHelper.selectedColorCode = null;
        const dominantColorHex = getDominantColor(variationsPropsList[index].designProps);
        roomViewHelper.updateBackground({ dominantColorHex });
        roomViewHelper.updateMask();
      }
      await roomViewHelper.renderDesign({
        designDetails: variationsPropsList[index].designProps,
        designPath: variationsPropsList[index].fullPath,
        hash: generateHash(
          variationsPropsList[index].designProps,
          variationsPropsList[index].fullPath
        ),
        designDimsOrig: {
          Width: variationsPropsList[index].designProps.Width,
          Height: variationsPropsList[index].designProps.Height
        }
      });
      if (activeFloor) await renderFloorInRoom(config.floorOptions);
      await roomViewHelper.updateShadow();
      const renderCanvas = await roomViewHelper.renderinCanvas({ width: 1920, height: 1080 });
      downloadImageData(renderCanvas, `${variationsPropsList[index].name}-${roomName}.jpg`, "jpg");
      tilesUpdating = false;
      if (index === variationsList.length - 1) {
        clearInterval(loop);
        const nextRoom = roomsList[++roomsIndex];
        createCombinationRunning = true;

        if (nextRoom) {
          illustrationListDispatch({
            type: visualizationsActions.SET_ACTIVE_NODE,
            payload: nextRoom
          });
        } else {
          roomsIndex = 0;
          window.changeDesign({ folder });
        }
      } else ++index;
    }, 2 * 1000);
  };
  useEffect(() => {
    if (!isRendering && createCombinationRunning) {
      window.createCombination({});
    }
  }, [isRendering]);

  useEffect(() => {
    if (isRendering) return;
    if (isStudioTemplate) {
      if (isRoomviewInFullScreen) return;
      if (isPaused) {
        roomViewHelper.container.style.animationPlayState = "paused";
      }
      const rightContainerWidth = document.getElementsByClassName("illustration-container")[0]
        .clientWidth;
      const carpetCenter = roomViewHelper.threeView.getGizmoCordinates();
      const { width, height, xOffset, yOffset } = roomViewHelper.dimensionPixels;
      const pixelsToLeft = xOffset * 2 * (carpetCenter.canvasCenter.x / width);
      const pixelsToRight =
        parseFloat(roomViewHelper.container.clientWidth) - (parseFloat(pixelsToLeft) + width);
      const pixelsToTop = yOffset * 2 * (carpetCenter.canvasCenter.y / height);
      var newKeyframes = `
      0% {
        left: 0px
      }
      25% {
        left:${parseFloat(pixelsToLeft)}px;
        scale:1.2
      }
      50% {
        left:-${parseFloat(width) - parseFloat(rightContainerWidth)}px;
        scale:1
      }
      75% {
        left:${parseFloat(pixelsToLeft)}px;
        scale:1.2
      }
      100% {
        left:0px;
        scale:1
      }
  `;
      roomViewHelper.container.style.position = "absolute";
      roomViewHelper.container.style.animation = "run 36s infinite";
      roomViewHelper.container.style.animationDirection = "alternate";
      const styleElement = document.createElement("style");
      styleElement.textContent = `@keyframes run { ${newKeyframes} }`;
      styleElement.setAttribute("data-animation-name", "run");
      document.head.appendChild(styleElement);
      roomViewHelper.container.style.setProperty("left", `${parseFloat(pixelsToLeft)}px`);
      roomViewHelper.container.style.setProperty("top", `${parseFloat(pixelsToTop)}px`);
      if (xOffset > -20) return;

      roomViewHelper.updateGizmo();
    }
  }, [roomViewHelper.dimensionPixels, isRendering, isRoomviewInFullScreen]);

  useEffect(() => {
    if (isRendering || designDetailState.loading) return;
    const renderFloorInRoom = floorOptions => {
      if (!floorOptions) return Promise.resolve();
      const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
      return roomViewHelper.renderFloor(floor);
    };
    const load = async () => {
      if (customDesignUrl && customDesignUrl !== "") {
        roomViewHelper.makeTransitionCanvas();
        setLoading(true);
        try {
          await roomViewHelper.renderDesignFromCustomUrl({
            customUrl: customDesignUrl,
            customDesignOutline,
            physicalWidth: designWidth,
            physicalHeight: designHeight,
            unit: designUnit
          });
          if (activeFloor) await renderFloorInRoom(activeColor);
          await roomViewHelper.updateShadow();
          setIsRendering(false);
          setLoading(false);
        } catch (error) {
          console.log("load loading custom design -> error", error);
          setIsRendering(false);
          setLoading(false);
        }
        if (window.flags.postToParentWhenLoaded) window.parent.postMessage("explorug loaded", "*");
      } else if (designWidth && designHeight) {
        if (designUnit && designUnit !== designDetailState.designDetails.Unit) {
          designWidth = convertUnit(designUnit, designDetailState.designDetails.Unit, designWidth);
          designHeight = convertUnit(
            designUnit,
            designDetailState.designDetails.Unit,
            designHeight
          );
        }
        console.log("changePhysicalWidHgt -> ", designWidth, designHeight);

        changePhysicalWidHgt(parseFloat(designWidth), parseFloat(designHeight), designUnit);
      } else {
        if (window.flags.visualizations.changeCanvasSizeInCtf) {
          await roomViewHelper.renderDesign({
            designDetails,
            designPath: fullpath,
            hash,
            designDimsOrig
          });
        }
      }
    };
    load();
  }, [customDesignUrl, designWidth, designHeight, designDetails]);

  const renderFloorInRoom = floorOptions => {
    if (!floorOptions) return Promise.resolve();
    const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
    return roomViewHelper.renderFloor(floor);
  };
  useEffect(() => {
    let la = true;
    resetScout();
    const setRoomTileDetails = async () => {
      try {
        const tileData = await AppProvider.fetchTileDetails({ file: fullpath });
        if (!la) return;
        dispatchDesignDetails({
          type: designDetailActions.SET_TILE_DETAILS,
          payload: tileData
        });
        roomViewHelper.setTileDetails(tileData);
      } catch (error) {}
    };
    const loadRoom = async () => {
      try {
        //if room has been changed
        const { floorOptions } = config;
        if (isStudioTemplate) {
          setIsPaused(false);
          roomViewHelper.container.style.animationPlayState = "paused";
        }
        if (prevRoomDetails !== roomData || isRendering) {
          setLoading(true);

          roomViewHelper.makeTransitionCanvas();
          setIsRendering(true);
          await Promise.all(roomViewHelper.initConfig({ baseUrl, config, files }));
          if (!la) return;
          const dominantColorHex = getDominantColor(designDetails);
          roomViewHelper.updateBackground({ dominantColorHex });
          roomViewHelper.updateShadow({ clear: true });
          roomViewHelper.updateMask();
          //if custom design url passed, render the design in room and return
          if (customDesignUrl) {
            await roomViewHelper.updatethreeCanvas();
            showLoadingAnimation(60);
            await roomViewHelper.renderDesignFromCustomUrl({
              customUrl: customDesignUrl,
              customDesignOutline,
              physicalWidth: designWidth,
              physicalHeight: designHeight,
              unit: designUnit
            });
            await renderFloorInRoom(floorOptions);
            await roomViewHelper.updateShadow();
            designRendered = true;
            setIsRendering(false);
            setLoading(false);
            setFirstLoadingOnRender(false);
            onRenderingComplete();
            if (window.flags.postToParentWhenLoaded)
              window.parent.postMessage("explorug loaded", "*");
            return;
          }
          //if only room is changed
          if (prevDesignDetails === designDetails && !customDesignUrl) {
            const { designScale: designScalePrev } = prevRoomDetails.config[
              prevRoomDetails.config.scenes[0]
            ];
            const { designScale } = config[config.scenes[0]];
            let designScaleCheck = false;
            if (!designScale && !designScalePrev) {
              designScaleCheck = false;
            } else if (!designScale || !designScalePrev) {
              designScaleCheck = true;
            } else if (designScale != designScalePrev) {
              designScaleCheck = true;
            }
            await roomViewHelper.updatethreeCanvas();
            if (!la) return;
            if (
              (!designRendering && !designRendered) ||
              config.roomType === "illustration" ||
              prevRoomType !== config.roomType ||
              (!prevRoomType && !config.roomType) ||
              designScaleCheck
            ) {
              designRendering = true;
              if (!roomViewHelper.tileDetails) {
                await setRoomTileDetails();
              }
              await roomViewHelper.renderDesign({
                designDetails,
                designPath: fullpath,
                hash,
                designDimsOrig
              });
              designRendering = false;
              designRendered = true;
              onRenderingComplete();
            } else if (window.InterfaceElements.IsWallToWall) {
              await roomViewHelper.updateDesignWallToWall();
            }
            await renderFloorInRoom(floorOptions);
            if (!la) return;
          }
        }
        //if designdetails has been changed and room is same
        if (prevDesignDetails !== designDetails && !customDesignUrl) {
          roomViewHelper.makeTransitionCanvas();
          setIsRendering(true);
          carpetScale = [1, 1];
          setLoading(true);
          if (isBorderRugs) {
            if (
              designUnit &&
              designUnit !== designDetailState.designDetails.Unit &&
              borderDesignWid &&
              borderDesignHgt
            ) {
              borderDesignWid = convertUnit(
                designUnit,
                designDetailState.designDetails.Unit,
                borderDesignWid
              );
              borderDesignHgt = convertUnit(
                designUnit,
                designDetailState.designDetails.Unit,
                borderDesignHgt
              );
            }

            if (PhysicalSizeShouldChange(borderDesignWid, borderDesignHgt)) {
              changePhysicalWidHgt(borderDesignWid, borderDesignHgt, designUnit);
              return;
            }
          } else if (designWidth && designHeight) {
            if (designUnit && designUnit !== designDetailState.designDetails.Unit) {
              designWidth = convertUnit(
                designUnit,
                designDetailState.designDetails.Unit,
                designWidth
              );
              designHeight = convertUnit(
                designUnit,
                designDetailState.designDetails.Unit,
                designHeight
              );
            }

            if (PhysicalSizeShouldChange(designWidth, designHeight)) {
              console.log(
                "change phy size ",
                designWidth,
                designHeight,
                designUnit,
                designDetailState.designDetails.Unit
              );
              changePhysicalWidHgt(designWidth, designHeight, designUnit);
              return;
            }
          }

          //update bg layers if room is smart/active
          if (roomViewHelper.patchImage) {
            roomViewHelper.selectedColorCode = null;
            const dominantColorHex = getDominantColor(designDetailState.designDetails);
            roomViewHelper.updateBackground({ dominantColorHex });
            roomViewHelper.updateMask();
          }
          designRendered = false;
          designRendering = false;
          if (
            roomViewHelper.designPath !== designDetailState.fullpath ||
            prevDesignDetails.PhysicalHeight !== designDetails.PhysicalHeight ||
            prevDesignDetails.PhysicalWidth !== designDetails.PhysicalWidth
          ) {
            await setRoomTileDetails();
            await roomViewHelper.updatethreeCanvas();
            if (!la) return;
            designRendering = true;
            await roomViewHelper.renderDesign({
              designDetails,
              designPath: fullpath,
              hash,
              designDimsOrig
            });
            await renderFloorInRoom(floorOptions);
            designRendered = true;
            designRendering = false;
            if (!la) return;
          } else {
            if (!window.flags.visualizations.changeCanvasSizeInCtf) {
              await roomViewHelper.updateTiles({
                designDetails,
                updateDesignTiles,
                updateNormapTiles,
                designDimsOrig,
                hash: hash
              });
            }
          }
          designRendered = true;
        }
        await roomViewHelper.updateShadow();
        // await roomViewHelper.makeTransitionCanvas({ clear: true });
        setIsRendering(false);
        if (isStudioTemplate) {
          roomViewHelper.container.style.animationPlayState = "running";
        }
        if (designRendered) {
          setLoading(false);
          if (window.flags.postToParentWhenLoaded) window.parent.postMessage("loadFinish", "*");
          setFirstLoadingOnRender(false);
        }
      } catch (error) {
        console.error(error);
        return;
      }
    };
    loadRoom();
    return () => {
      la = false;
    };
  }, [roomData, designDetails]);
  useEffect(() => {
    if (!centerDesignUrl || centerDesignUrl === "") return;

    if (!designDetails || !prevDesignDetails.PhysicalWidth) return;
    //https://v3dev.explorug.com/build/explorug.html?page=borderrugs&initdesign=Designs/Border/Cotton Border.jpg&initview=Rooms/Bedroom.crf3d&centerDesignUrl=https://assets.explorug.com/6EA963515DE99CC94B3735E76C1E1F36/Designs/Center/Jute.thumb.jpg&borderDesignWid=8&borderDesignHgt=10&unit=ft
    const updateBorderRugTiles = async () => {
      await roomViewHelper.updateTiles({
        designDetails,
        updateDesignTiles,
        updateNormapTiles,
        designDimsOrig,
        hash: hash
      });
      await roomViewHelper.updateShadow();
      await roomViewHelper.makeTransitionCanvas({ clear: true });
    };
    updateBorderRugTiles();
  }, [centerDesignUrl, borderSize]);

  useEffect(() => {
    if (!isBorderRugs) return;
    if (
      !parseFloat(borderDesignWid) ||
      !parseFloat(borderDesignHgt) ||
      !prevDesignDetails.PhysicalWidth ||
      !prevDesignDetails.PhysicalHeight
    )
      return;

    if (!designDetails || !designDetails.PhysicalWidth) return;

    if (borderDesignHgt && borderDesignWid)
      changePhysicalWidHgt(parseFloat(borderDesignWid), parseFloat(borderDesignHgt), designUnit);
  }, [borderDesignWid, borderDesignHgt]);

  window.downloadRendered3dIll = async (returnCanvas = false) => {
    if (designDetailState.loading) return;
    const mime = "jpg";
    const name = customDesignUrl ? "customDesign" : `${designDetailState.designName}`;
    const downloadName = `${name} in ${roomName}.${mime}`;
    const { floorOptions } = config;
    const { width, height } = config.dims;
    const bgCanvas = createCanvas(width, height);
    const threeCanvas = createCanvas(width, height);
    const maskCanvas = createCanvas(width, height);
    const shadowCanvas = createCanvas(width, height);
    const container = { clientWidth: width, clientHeight: height };
    const inputCanvas = createCanvas(width, height);
    const transitionCanvas = createCanvas(width, height);
    const canvasConfig = {
      bgCanvas,
      threeCanvas,
      maskCanvas,
      shadowCanvas,
      container,
      inputCanvas,
      transitionCanvas
    };
    const rh = new RoomViewHelper();
    rh.initCanvas(canvasConfig);
    await Promise.all(rh.initConfig({ baseUrl, config, files, sizeFromConfig: true }));
    rh.updateBackground();
    if (roomViewHelper.currentActiveColors) {
      roomViewHelper.currentActiveColors.forEach(col => {
        const { dominantColorHex, annotationColor } = col;
        rh.selectedColorCode = annotationColor;
        rh.updateBackground({ dominantColorHex });
      });
    }

    rh.updateMask();
    await rh.updatethreeCanvas();
    if (customDesignUrl) {
      await rh.updatethreeCanvas();
      showLoadingAnimation(60);
      await rh.renderDesignFromCustomUrl({
        customUrl: customDesignUrl,
        customDesignOutline,
        physicalWidth: designWidth,
        physicalHeight: designHeight,
        unit: designUnit
      });
      await renderFloorInRoom(floorOptions);
    } else {
      rh.setTileDetails(designDetailState.tileDetails);
      await rh.renderDesign({
        designDetails,
        designPath: fullpath,
        hash,
        designDimsOrig
      });
    }
    const objectConfig = roomViewHelper.threeView.getObjectConfig();
    if (objectConfig) {
      rh.threeView.carpetMesh.position.copy(objectConfig.position);
      rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
      rh.threeView.carpetMesh.scale.copy(objectConfig.scale);
      rh.threeView.render();
    }

    if (floorOptions) {
      const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
      await rh.renderFloor(floor);
    }
    await rh.updateShadow();
    if (!returnCanvas) {
      rh.downloadRendering(downloadName, mime);
      return Promise.resolve();
    } else {
      const renderCanvas = rh.renderinCanvas();
      return Promise.resolve(renderCanvas);
    }
  };

  window.downloadPdfOrImageForRugStudio = async (
    roomName,
    description,
    qrurl,
    room,
    type,
    isWatermark,
    paypalName,
    paypalEmail,
    purchasedDate
  ) => {
    if (designDetailState.loading) return;
    const mime = "jpg";
    const name = customDesignUrl ? "customDesign" : `${designDetailState.designName}`;
    const downloadName = `${name} in ${roomName}.${mime}`;
    const { floorOptions } = config;
    const { width, height } = config.dims;
    const bgCanvas = createCanvas(width, height);
    const threeCanvas = createCanvas(width, height);
    const maskCanvas = createCanvas(width, height);
    const shadowCanvas = createCanvas(width, height);
    const container = { clientWidth: width, clientHeight: height };
    const inputCanvas = createCanvas(width, height);
    const transitionCanvas = createCanvas(width, height);
    const canvasConfig = {
      bgCanvas,
      threeCanvas,
      maskCanvas,
      shadowCanvas,
      container,
      inputCanvas,
      transitionCanvas
    };
    const rh = new RoomViewHelper();
    rh.initCanvas(canvasConfig);
    await Promise.all(rh.initConfig({ baseUrl, config, files, sizeFromConfig: true }));
    rh.updateBackground();
    if (roomViewHelper.currentActiveColors) {
      roomViewHelper.currentActiveColors.forEach(col => {
        const { dominantColorHex, annotationColor } = col;
        rh.selectedColorCode = annotationColor;
        rh.updateBackground({ dominantColorHex });
      });
    }

    rh.updateMask();
    await rh.updatethreeCanvas();
    if (customDesignUrl) {
      await rh.updatethreeCanvas();
      showLoadingAnimation(60);
      await rh.renderDesignFromCustomUrl({
        customUrl: customDesignUrl,
        customDesignOutline,
        physicalWidth: designWidth,
        physicalHeight: designHeight,
        unit: designUnit
      });
      await renderFloorInRoom(floorOptions);
    } else {
      rh.setTileDetails(designDetailState.tileDetails);
      await rh.renderDesign({
        designDetails,
        designPath: fullpath,
        hash,
        designDimsOrig
      });
    }
    const objectConfig = roomViewHelper.threeView.getObjectConfig();
    if (objectConfig) {
      rh.threeView.carpetMesh.position.copy(objectConfig.position);
      rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
      rh.threeView.carpetMesh.scale.copy(objectConfig.scale);
      rh.threeView.render();
    }

    if (floorOptions) {
      const floor = activeFloor.path ? activeFloor : floorOptions.activeFloor;
      await rh.renderFloor(floor);
    }
    await rh.updateShadow();
    rh.downloadRenderingForRugStudio(
      downloadName,
      roomName,
      description,
      qrurl,
      room,
      type,
      isWatermark,
      paypalName,
      paypalEmail,
      purchasedDate
    );
    return Promise.resolve();
  };

  window.getRoomRenderingCanvas = async (inActualSize = false) => {
    return new Promise(async (resolve, reject) => {
      const downloadName = `${designDetailState.designName} in ${roomName}`;
      const renderCanvas = inActualSize
        ? await window.downloadRendered3dIll(true)
        : roomViewHelper.renderinCanvas();

      canvasToBlobPromise(renderCanvas)
        .then(roomCanvasBlob => {
          AppProvider.uploadRoomviewBlob({ blob: roomCanvasBlob, filename: downloadName }).then(
            response => {
              return resolve(response);
            }
          );
        })
        .catch(() => {
          console.log("error while getting room view canvas");
          return reject("error");
        });
    });
  };
  window.downloadCustomDesignRoomDirectly = async () => {
    //above getRoomRenderingCanvas passes to backend to save, this function doesn't
    return new Promise(async (resolve, reject) => {
      const downloadName = `customDesign in ${roomName}`;
      const renderCanvas = roomViewHelper.renderinCanvas();
      const link = document.createElement("a");
      link.href = renderCanvas.toDataURL("image/jpeg");
      link.download = downloadName;
      link.click();
    });
  };
  window.returnDataUrlForCurrentCanvas = async () => {
    //above getRoomRenderingCanvas passes to backend to save, this function doesn't
    return new Promise(async (resolve, reject) => {
      const renderCanvas = roomViewHelper.renderinCanvas();
      renderCanvas.toBlob(function(blob) {
        const canvasFile = new File([blob], "canvas_image.jpg", { type: "image/jpeg" });
        window.parent.postMessage({ imageFile: canvasFile }, "*");
      });
    });
  };
  window.downloadPdfOrImageForRugStudioCustomDesign = async (
    roomName,
    description,
    qrurl,
    room,
    type,
    isWatermark,
    paypalName,
    paypalEmail,
    purchasedDate
  ) => {
    roomViewHelper.downloadPdfOrImageForRugStudioCustomDesign(
      roomName,
      description,
      qrurl,
      room,
      type,
      isWatermark,
      paypalName,
      paypalEmail,
      purchasedDate
    );
  };
  const handleInputStart = e => {
    if (isStudioTemplate) return;
    if (isRendering) return;
    roomViewHelper.mouseDownTouchStart(e);
    setMouseDownPosition(e);
  };
  const handleinputMove = e => {
    if (isStudioTemplate) return;
    if (isRendering) return;

    roomViewHelper.mouseDownTouchMove(e);
  };
  const handleInputEnd = e => {
    if (isStudioTemplate) return;
    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_ID_TO_HIDE,
      payload: "areaSwatchVisualization"
    });
    if (isRendering) return;
    const opt = roomViewHelper.mouseDownTouchEnd(e);
    if (window.flags.homeTemplate == pageViews.STUDIOENTRY) return;
    const { showColorSelectionBox, rotation, position, texCoordinates, isActiveValue } = opt;
    if (isActiveValue == null) {
      setIsActiveRoomValue(0);
    } else {
      setIsActiveRoomValue(isActiveValue);
    }
    let option = carpetOptions;
    if (rotation) option = { ...option, rotation };
    if (position) option = { ...option, position };
    if (position || rotation)
      dispatchRoomView({
        type: roomViewActions.SET_CARPET_OPTION,
        payload: option
      });
    if (
      texCoordinates &&
      mouseDownPosition &&
      e.x === mouseDownPosition.x &&
      e.y === mouseDownPosition.y
    ) {
      if (window.flags.homeTemplate == pageViews.MYRUGSTUDIO) return;
      dispatchDesignDetails({
        type: designDetailActions.SET_CUSTOSCOUT_COORDS,
        payload: {
          containerPoint: e,
          designPoint: texCoordinates,
          clickedDivElement: inputCanvasRef.current.className
        }
      });
    } else if (mouseDownPosition) toggleScout(false);

    setMouseDownPosition(null);
    if (!showColorSelectionBox) return;
    else handleActiveClick && handleActiveClick();
  };
  useEffect(() => {
    if (window.flags.homeTemplate == pageViews.STUDIOENTRY) return;
    if (
      window.flags.homeTemplate == pageViews.CREATEYOURRUG &&
      designDetailState.custoScout.clickedDivElement == "tile-container"
    )
      return;
    const { designPoint } = designDetailState.custoScout;
    if (!designPoint) return;
    if (!designDetailState.designDetails || !designDetailState.designDetails.DesignColors.length)
      return;
    if (isActiveRoomValue != 0) return;
    toggleScout(false);
    AppProvider.getColorAt({
      file: designDetailState.fullpath,
      x:
        designPoint.x -
        (designDetailState.designDetails.Width - designDetailState.designDimsOrig.Width) / 2,
      y:
        designPoint.y -
        (designDetailState.designDetails.Height - designDetailState.designDimsOrig.Height) / 2,
      Width: designDetailState.designDimsOrig.Width,
      Height: designDetailState.designDimsOrig.Height
    }).then(color => {
      if (color === 255) return;
      dispatchDesignDetails({ type: designDetailActions.SELECT_DESIGN_COLOR, payload: color });
      toggleScout(true);
    });
  }, [designDetailState.custoScout.designPoint]);

  const toggleScout = value => {
    dispatchDesignDetails({ type: designDetailActions.TOOGLE_CUSTOSCOUT, payload: value });
  };
  const resetScout = () => {
    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_COORDS,
      payload: { show: false, designPoint: null, containerPoint: null }
    });
  };

  const PhysicalSizeShouldChange = (designWidth, designHeight) => {
    if (
      parseFloat(designWidth) &&
      parseFloat(designHeight) &&
      (parseFloat(designWidth) !== designDetails.PhysicalWidth ||
        parseFloat(designHeight) !== designDetails.PhysicalHeight)
    )
      return true;
    else return false;
  };
  const changePhysicalWidHgt = (designWidth, designHeight, designUnit) => {
    if (!designWidth || !designHeight) return;

    if (
      parseFloat(designWidth) &&
      parseFloat(designHeight) &&
      (parseFloat(designWidth) !== designDetailState.designDetails.PhysicalWidth ||
        parseFloat(designHeight) !== designDetailState.designDetails.PhysicalHeight)
    ) {
      dispatchDesignDetails({
        type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
        payload: {
          PhysicalWidth: parseFloat(designWidth),
          PhysicalHeight: parseFloat(designHeight),
          Unit: designUnit ? designUnit : designDetailState.designDetails.Unit
        }
      });
    }
  };

  const setLoading = loading =>
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: loading
    });

  const showLoadingAnimation = (setPercent, timer = 2000) => {
    let percentValue = setPercent;

    if (uiState.showLoadingOverlay)
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: percentValue });
    setTimeout(() => {
      percentValue = setPercent >= 90 ? setPercent : setPercent + 30;
      if (uiState.showLoadingOverlay)
        dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: percentValue });
    }, timer);
  };

  const handlePause = () => {
    if (isPaused) {
      roomViewHelper.container.style.animationPlayState = "running";
    } else {
      roomViewHelper.container.style.animationPlayState = "paused";
    }
    setIsPaused(!isPaused);
  };

  window.changeCarpetSize = (width = 3, height = 3) => {
    const currentScale = roomViewHelper.threeView.carpetMesh.scale;
    roomViewHelper.threeView.carpetMesh.scale.set(width, height, currentScale.z);
    roomViewHelper.threeView.render();
    roomViewHelper.updateShadow();
  };

  window.scaleCarpet = (scaleUp = 1) => {
    const scaleStep = 0.2;
    const currentScale = roomViewHelper.threeView.carpetMesh.scale;
    const origScale = [currentScale.x / carpetScale[0], currentScale.y / carpetScale[1]];
    if (
      scaleUp == -1 &&
      (parseFloat(carpetScale[0]).toFixed(1) == scaleStep ||
        parseFloat(carpetScale[1]).toFixed(1) == scaleStep)
    )
      return;
    if (carpetScale[0] + scaleUp * scaleStep >= 4) return;
    carpetScale[0] += scaleUp * scaleStep;
    carpetScale[1] += scaleUp * scaleStep;
    if (scaleUp === 0) {
      carpetScale = [1, 1];
    }
    roomViewHelper.threeView.carpetMesh.scale.set(
      origScale[0] * carpetScale[0],
      origScale[1] * carpetScale[1],
      currentScale.z
    );
    setScaledUpWidth(parseFloat(origScale[0] * carpetScale[0]).toFixed(2));
    setScaledUpHeight(parseFloat(origScale[1] * carpetScale[1]).toFixed(2));
    roomViewHelper.threeView.render();
    roomViewHelper.updateShadow();
  };

  var eventClickPending = 0;
  const handleDoubleClick = () => {
    const tempRoomName = illustrationListState.activeNode.Name.split(" ");
    tempRoomName.pop();
    const roomName = tempRoomName.join(" ");
    const roomView = illustrationListState.activeNode.label;
    var fileName = "";
    var fileVarName = "&variation=";
    if (selectedFile.fullPath.includes("/")) {
      const designName = selectedFile.fullPath.split("/").pop();
      if (selectedFile.variations == null && selectedFile.location.includes("/")) {
        fileVarName = fileVarName + designName.split(".")[0];
        fileName = selectedFile.location.split("/.")[1];
      } else {
        fileName = designName.split(".")[0];
        fileVarName = "";
      }
    }
    window.open(
      `https://myrug.studio/studio?room=${roomName}&type=2D&rug=${fileName}&view=${roomView}${fileVarName}`,
      "_blank"
    );
  };
  const handleClick = event => {
    if (!isStudioTemplate) return;
    var EVENT_DOUBLE_CLICK_DELAY = 220; // Adjust max delay btw two clicks (ms)
    if (event.detail === 1 && eventClickPending == 0) {
      if (isRoomviewInFullScreen) return;
      eventClickPending = setTimeout(function() {
        eventClickPending = 0;
        if (
          roomViewHelper.container.style.animationPlayState == "paused" ||
          roomViewHelper.container.style.animationPlayState == ""
        ) {
          roomViewHelper.container.style.animationPlayState = "running";
          setIsPaused(false);
        } else {
          roomViewHelper.container.style.animationPlayState = "paused";
          setIsPaused(true);
        }
      }, EVENT_DOUBLE_CLICK_DELAY);
    } else if (event.detail == 2 && eventClickPending != 0) {
      clearTimeout(eventClickPending);
      eventClickPending = 0;
      handleDoubleClick();
    }
  };

  const handleFullScreen = () => {
    let fcDelay = 1000;
    if (!isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (document.body.requestFullscreen) {
      setTimeout(() => {
        if (!isRoomviewInFullScreen) {
          if (window.orientation !== 0) {
            setIsPaused(true);
            handlePause();
          }
          roomViewHelper.container.style.animation = "none";
          roomViewHelper.container.style.left = "0px";
          setIsPaused(true);
          toggleIsRoomviewInFullScreen(true);
          setIsFullScreen(true);
        } else {
          roomViewHelper.container.style.animationPlayState = "running";
          setIsPaused(false);
          toggleIsRoomviewInFullScreen(false);
          setIsFullScreen(false);
        }
      }, fcDelay);
    } else {
      toggleIsRoomviewInFullScreen();
    }
  };

  return !isStudioTemplate ? (
    <Container ref={containerRef}>
      <Canvas ref={bgCanvasRef} zIndex={1} className={isRendering ? "three-canvas-loading" : ""} />
      <Canvas
        ref={threeCanvasRef}
        zIndex={2}
        pointerEvent
        className={classNames("three-canvas", { "three-canvas-loading": isRendering })}
      />
      <Canvas
        ref={maskCanvasRef}
        zIndex={3}
        className={classNames("mask-canvas", { "three-canvas-loading": isRendering })}
      />

      <BgVideo
        width="100%"
        height="100%"
        loop
        muted
        autoPlay
        playsInline
        disablePictureInPicture
        ref={videoRef}
        show={config.backgroundVideo && !isRendering}
        zIndex={4}
      ></BgVideo>
      <Canvas
        ref={shadowCanvasRef}
        zIndex={4}
        className={classNames("shadow-canvas", { "three-canvas-loading": isRendering })}
      />
      <InputCanvas
        className={classNames("input-canvas", { "at-idle-fadeout": isIdle })}
        zIndex={50}
        pointerEvent={
          window.flags.visualizations.donotMoveDesignOnMouseEventInRoomView ? false : true
        }
        ref={inputCanvasRef}
        onStart={handleInputStart}
        onMove={handleinputMove}
        onEnd={handleInputEnd}
      />
      <Canvas
        className={classNames("transition-canvas", { "three-canvas-loading": !isRendering })}
        ref={transitionCanvasRef}
        zIndex={5}
      />
      <div ref={floatingOptionsRef} style={{ pointerEvents: "none" }}>
        {videoRef?.current &&
          (videoRef.current.webkitAudioDecodedByteCount || videoRef.current.mozHasAudio) && (
            <AtButton
              minimal
              className={
                window.flags.homeTemplate == pageViews.MYRUGSTUDIO
                  ? "at-btn-sound-toggle-myrugStudio"
                  : "at-btn-sound-toggle"
              }
              icon={videoRef.current.muted ? "sound-x" : "sound-full"}
              onClick={() => {
                if (videoRef) videoRef.current.muted = !videoRef.current.muted;
              }}
              text={"Seashore sound by MRQZ"}
            ></AtButton>
          )}
        {designDetailState.custoScout.show &&
          designDetailState.custoScout.containerPoint &&
          designDetailState.custoScout.custoScoutIdforCyr !== "areaSwatchRoomView" && (
            <div
              style={{
                position: "absolute",
                left: `${designDetailState.custoScout.containerPoint.x}px`,
                top: `${designDetailState.custoScout.containerPoint.y}px`,
                zIndex: 100
              }}
              className="at-custo-assistant-scout ui"
              id="areaSwatchRoomView"
            >
              <AreaSwatch
                designColor={
                  designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
                }
                colorIndex={designDetailState.selectedColor}
                popoverPosition="auto"
                colorNumber={designDetailState.colorNumbers[designDetailState.selectedColor]}
                handleClick={e => e.stopPropagation()}
                onColorPieClick={(color, e) => {
                  dispatchColorList({ type: colorListActions.SELECT_COLOR, payload: color });
                }}
              />
              <AtIcon
                icon="close"
                onClick={() =>
                  dispatchDesignDetails({
                    type: designDetailActions.TOOGLE_CUSTOSCOUT,
                    payload: false
                  })
                }
              />
            </div>
          )}
      </div>
    </Container>
  ) : (
    //square layout
    <div ref={fullScreenRef}>
      <Container
        ref={containerRef}
        style={{
          position: isStudioTemplate ? "absolute" : "",
          animation: isStudioTemplate ? "run 36s infinite" : "none",
          animationDirection: isStudioTemplate ? "alternate" : "none"
        }}
        onClick={event => handleClick(event)}
      >
        <Canvas
          ref={bgCanvasRef}
          zIndex={1}
          className={isRendering ? "three-canvas-loading" : ""}
        />
        <Canvas
          ref={threeCanvasRef}
          zIndex={2}
          pointerEvent
          className={classNames("three-canvas", { "three-canvas-loading": isRendering })}
        />
        <Canvas
          ref={maskCanvasRef}
          zIndex={3}
          className={classNames("mask-canvas", { "three-canvas-loading": isRendering })}
        />

        <BgVideo
          width="100%"
          height="100%"
          loop
          muted
          autoPlay
          playsInline
          disablePictureInPicture
          ref={videoRef}
          show={config.backgroundVideo && !isRendering}
          zIndex={4}
        ></BgVideo>
        <Canvas
          ref={shadowCanvasRef}
          zIndex={4}
          className={classNames("shadow-canvas", { "three-canvas-loading": isRendering })}
        />
        <InputCanvas
          className={classNames("input-canvas", { "at-idle-fadeout": isIdle })}
          zIndex={50}
          pointerEvent
          ref={inputCanvasRef}
          onStart={handleInputStart}
          onMove={handleinputMove}
          onEnd={handleInputEnd}
        />
        <Canvas
          className={classNames("transition-canvas", { "three-canvas-loading": !isRendering })}
          ref={transitionCanvasRef}
          zIndex={5}
        />
        <div ref={floatingOptionsRef} style={{ pointerEvents: "none" }}>
          {videoRef?.current &&
            (videoRef.current.webkitAudioDecodedByteCount || videoRef.current.mozHasAudio) && (
              <AtButton
                minimal
                className={
                  window.flags.homeTemplate == pageViews.MYRUGSTUDIO
                    ? "at-btn-sound-toggle-myrugStudio"
                    : "at-btn-sound-toggle"
                }
                icon={videoRef.current.muted ? "sound-x" : "sound-full"}
                onClick={() => {
                  if (videoRef) videoRef.current.muted = !videoRef.current.muted;
                }}
                text={"Seashore sound by MRQZ"}
              ></AtButton>
            )}
          {designDetailState.custoScout.show &&
            designDetailState.custoScout.containerPoint &&
            designDetailState.custoScout.custoScoutIdforCyr !== "areaSwatchRoomView" && (
              <div
                style={{
                  position: "absolute",
                  left: `${designDetailState.custoScout.containerPoint.x}px`,
                  top: `${designDetailState.custoScout.containerPoint.y}px`,
                  zIndex: 100
                }}
                className="at-custo-assistant-scout ui"
                id="areaSwatchRoomView"
              >
                <AreaSwatch
                  designColor={
                    designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
                  }
                  colorIndex={designDetailState.selectedColor}
                  popoverPosition="auto"
                  colorNumber={designDetailState.colorNumbers[designDetailState.selectedColor]}
                  handleClick={e => e.stopPropagation()}
                  onColorPieClick={(color, e) => {
                    dispatchColorList({ type: colorListActions.SELECT_COLOR, payload: color });
                  }}
                />
                <AtIcon
                  icon="close"
                  onClick={() =>
                    dispatchDesignDetails({
                      type: designDetailActions.TOOGLE_CUSTOSCOUT,
                      payload: false
                    })
                  }
                />
              </div>
            )}
        </div>
      </Container>
      {/* done for studioEntry */}
      {!isRendering && isStudioTemplate && (
        <div className="studioButton">
          {!isRoomviewInFullScreen && (
            <button className="studioButtonPausePlay" onClick={handlePause}>
              <AtIcon icon={isPaused ? "play" : "pause"} color="#F05223" />
            </button>
          )}
          <button
            className="studioButtonPausePlay"
            id="studioFullScreenButton"
            onClick={handleDoubleClick}
          >
            <AtIcon icon="myrugstudio" color="#F05223" />
            <span className="tooltiptext">Explore</span>
          </button>
          <button
            className="studioButtonPausePlay"
            id="studioFullScreenButton"
            onClick={handleFullScreen}
          >
            <AtIcon
              icon={isRoomviewInFullScreen ? "exit-fullscreen" : "enter-fullscreen"}
              color="#F05223"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomViewNew;
