import React from "react";
import { useDesignListState } from "../../../reducers/designlist.reducer";

import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";

const FavoriteButton = props => {
  const { handleFavorite, buttonID = "at-des-details__title-favorite" } = props;
  const { flags } = window;
  const designListState = useDesignListState();

  return (
    <>
      {window.sessionStorage.getItem("mode") !== "stripedesign" && (
        <AtButton
          minimal
          id={buttonID}
          title={strings.favorite.addToFavorite}
          intent={null}
          icon={`${!!designListState.selectedFile.fav ? "heart-full-of-love" : "heart"}`}
          onClick={handleFavorite}
        >
          {flags.designDetailSection.showAddToFavoriteText && (
            <span className={`${buttonID}${"__text"}`}>{strings.favorite.addToFavorite}</span>
          )}
        </AtButton>
      )}
    </>
  );
};

export default FavoriteButton;
