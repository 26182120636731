/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import VolumeSlider from "./VolumeSlider";
import { ReactComponent as MusicOn } from "./music.svg";
import { ReactComponent as MusicOff } from "./music-off.svg";
import { Popover } from "@blueprintjs/core";
const MusicImgWrapper = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  /*background: white;
  text-align: center;
  box-sizing: border-box;
  padding: 0.75rem 0.25rem;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #fff;
  &:hover {
    background-color: #dde0e3;
  }*/
  & .musicImg {
    background: white;
    padding: 0;

    border-radius: 0;
    width: 1rem;
    height: 1rem;
    background-color: transparent;
  }
`;

const MusicDiv = styled.div`
  height: auto;
  margin: 0 0.5rem;
`;

const AudioTest1 = styled.audio`
  position: fixed;
  top: 0;
  left: 0;
`;
const AudioTest2 = styled.audio`
  position: fixed;
  top: 10rem;
  left: 0;
`;

const AudioPlayer = props => {
  const { audioArr, startPlay } = props;
  const [audioSrc1, setaudioSrc1] = useState("");
  const [audioSrc2, setaudioSrc2] = useState("");
  const [audio1Loaded, setaudio1Loaded] = useState(false);
  const [audio2Loaded, setaudio2Loaded] = useState(false);
  const [firstPlay, setfirstPlay] = useState(true);
  const [audioIndex, setaudioIndex] = useState(0);
  const [showVolumeSlider, setshowVolumeSlider] = useState(false);
  const [paused, setpaused] = useState(true);
  const [autoPlayFailed, setautoPlayFailed] = useState(false);
  const [isIOS, setisIOS] = useState(false);
  const [changedMusic, setchangedMusic] = useState(0);

  const [volume, setVolume] = useState(0.5);
  useEffect(() => {
    if (navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i)) {
      setisIOS(true);
      // alert("true");
    } else {
      // alert("false");
    }
  }, []);

  const audio1Ref = useRef(null);
  const audio2Ref = useRef(null);

  useEffect(() => {
    if (startPlay && audio1Loaded) {
      audio1Ref.current
        .play()
        .then(() => {
          setpaused(false);
          setfirstPlay(false);
        })
        .catch(err => {
          setpaused(true);
          setautoPlayFailed(true);
        });
    }
    if (!startPlay && !paused) {
      imageClickFunc();
    }
    return () => {};
  }, [startPlay]);

  useEffect(() => {
    setaudioSrc1(audioArr[0].music);
    if (audioArr.length > 1) {
      setaudioSrc2(audioArr[1].music);
      setaudioIndex(1);
    } else {
      setaudioSrc2(audioArr[0].music);
    }

    return () => {};
  }, []);

  useEffect(() => {
    setTimeout(() => {
      audio1Ref.current.load();
    }, 200);
  }, [audioSrc1]);
  useEffect(() => {
    setTimeout(() => {
      audio2Ref.current.load();
    }, 200);
  }, [audioSrc2]);

  useEffect(() => {
    if (audioArr.length <= 0) {
      audio1Ref.current.pause();
      audio2Ref.current.pause();
      setaudioSrc1("");
      setaudioSrc2("");
    } else if (audioSrc1 === "" && audioSrc2 === "") {
      setaudioSrc1(audioArr[0].music);
      if (audioArr.length > 1) {
        setaudioSrc2(audioArr[1].music);
        setaudioIndex(1);
      } else {
        setaudioSrc2(audioArr[0].music);
      }
    } else {
      let musiconlyarr = Array.from(audioArr, x => x.music);

      if (audio1Ref.current.currentTime > 0) {
        if (!musiconlyarr.includes(audioSrc1)) {
          setaudioSrc1(audioArr[0].music);
          setchangedMusic(1);
          if (audioArr.length > 1) {
            setaudioSrc2(audioArr[1].music);
            setaudioIndex(1);
          } else {
            setaudioSrc2(audioArr[0].music);
            setaudioIndex(0);
          }
        } else if (audioIndex >= audioArr.length) {
          if (audioArr.length < 2) {
            setaudioIndex(0);
            setaudioSrc2(audioArr[0].music);
          } else if (audioSrc1 === audioArr[0].music) {
            setaudioIndex(1);
            setaudioSrc2(audioArr[1].music);
          } else {
            setaudioIndex(0);
            setaudioSrc2(audioArr[0].music);
          }
        } else {
          if (audioSrc1 === audioArr[audioIndex].music) {
            if (audioArr[audioIndex + 1]) {
              setaudioIndex(audioIndex + 1);
              setaudioSrc2(audioArr[audioIndex + 1].music);
            } else if (audioArr[audioIndex - 1]) {
              setaudioIndex(audioIndex - 1);
              setaudioSrc2(audioArr[audioIndex - 1].music);
            }
          } else {
            setaudioSrc2(audioArr[audioIndex].music);
          }
        }
      } else if (audio2Ref.current.currentTime > 0) {
        if (!musiconlyarr.includes(audioSrc2)) {
          setaudioSrc2(audioArr[0].music);
          setchangedMusic(2);
          if (audioArr.length > 1) {
            setaudioSrc1(audioArr[1].music);
            setaudioIndex(1);
          } else {
            setaudioSrc1(audioArr[0].music);
            setaudioIndex(0);
          }
        } else if (audioIndex >= audioArr.length) {
          if (audioArr.length < 2) {
            setaudioIndex(0);
            setaudioSrc1(audioArr[0].music);
          } else if (audioSrc2 === audioArr[0].music) {
            setaudioIndex(1);
            setaudioSrc1(audioArr[1].music);
          } else {
            setaudioIndex(0);
            setaudioSrc1(audioArr[0].music);
          }
        } else {
          if (audioSrc2 === audioArr[audioIndex].music) {
            if (audioArr[audioIndex + 1]) {
              setaudioIndex(audioIndex + 1);
              setaudioSrc1(audioArr[audioIndex + 1].music);
            } else if (audioArr[audioIndex - 1]) {
              setaudioIndex(audioIndex - 1);
              setaudioSrc1(audioArr[audioIndex - 1].music);
            }
          } else {
            setaudioSrc1(audioArr[audioIndex].music);
          }
        }
      }
    }
    return () => {};
  }, [audioArr]);

  useEffect(() => {
    if (paused) {
      setshowVolumeSlider(false);
    }
    return () => {};
  }, [paused]);

  const audio1Ready = () => {
    setaudio1Loaded(true);

    if (startPlay && firstPlay) {
      audio1Ref.current
        .play()
        .then(() => {
          setpaused(false);
          setfirstPlay(false);
        })
        .catch(err => {
          setpaused(true);
          setautoPlayFailed(true);
        });
    } else if (
      !(audio1Ref.current.currentTime > 0 || audio2Ref.current.currentTime > 0) &&
      changedMusic !== 0
    ) {
      if (changedMusic === 1) {
        audio1Ref.current
          .play()
          .then(() => {
            // setchangedMusic(0);
          })
          .catch(err => {
            setchangedMusic(0);
          });
      }
    }
    // setTimeout(() => {
    //   audio1Ref.current.pause();
    // }, 10000);
    // }
  };
  const audio2Ready = () => {
    setaudio2Loaded(true);
    if (
      !(audio1Ref.current.currentTime > 0 || audio2Ref.current.currentTime > 0) &&
      changedMusic !== 0
    ) {
      if (changedMusic === 2) {
        audio2Ref.current
          .play()
          .then(() => {
            // setchangedMusic(0);
          })
          .catch(err => {
            setchangedMusic(0);
          });
      }
    }
  };
  const audio1Ended = () => {
    audio1Ref.current.currentTime = 0;
    setchangedMusic(0);
    if (audio2Loaded) {
      audio2Ref.current.play().then(() => {});
      setaudio1Loaded(false);
      if (audioIndex + 1 >= audioArr.length) {
        setaudioIndex(0);
        setaudioSrc1(audioArr[0].music);
      } else {
        setaudioSrc1(audioArr[audioIndex + 1].music);
        setaudioIndex(audioIndex + 1);
      }
    } else {
      audio1Ref.current.play().then({});
    }
  };
  const audio2Ended = () => {
    audio2Ref.current.currentTime = 0;
    setchangedMusic(0);
    if (audio1Loaded) {
      audio1Ref.current.play().then({});
      setaudio2Loaded(false);
      if (audioIndex + 1 >= audioArr.length) {
        setaudioIndex(0);
        setaudioSrc2(audioArr[0].music);
      } else {
        setaudioSrc2(audioArr[audioIndex + 1].music);
        setaudioIndex(audioIndex + 1);
      }
    } else {
      audio2Ref.current.play().then({});
    }
  };

  const imageClickFunc = () => {
    if (autoPlayFailed) {
      audio2Ref.current.play().then(() => {
        audio2Ref.current.pause();
        audio2Ref.current.currentTime = 0;
      });
      audio1Ref.current.play().then(() => {
        setpaused(false);
        setautoPlayFailed(false);
      });
    } else {
      if (audio1Ref.current.paused && audio1Ref.current.currentTime > 0) {
        audio1Ref.current.play();
        setpaused(false);
      } else if (!audio1Ref.current.paused) {
        audio1Ref.current.pause();
        setpaused(true);
      } else if (audio2Ref.current.paused && audio2Ref.current.currentTime > 0) {
        audio2Ref.current.play();
        setpaused(false);
      } else if (!audio2Ref.current.paused) {
        audio2Ref.current.pause();
        setpaused(true);
      }
    }
  };

  const changeVolume = val => {
    audio1Ref.current.volume = val;
    audio2Ref.current.volume = val;
    setVolume(val);
  };

  const showVolumeSliderFunc = () => {
    if (!showVolumeSlider && !paused) {
      setshowVolumeSlider(true);
    }
  };
  const hideVolumeSliderFunc = () => {
    if (showVolumeSlider) {
      setshowVolumeSlider(false);
    }
  };

  return (
    <React.Fragment>
      <MusicDiv
        showVolumeSlider={showVolumeSlider}
        onMouseOver={showVolumeSliderFunc}
        onTouchStart={showVolumeSliderFunc}
        onMouseOut={hideVolumeSliderFunc}
        slideToShow={startPlay}
      >
        <Popover
          popoverClassName="at-cine-music-slider"
          interactionKind="hover"
          minimal
          boundary="viewport"
          content={
            <VolumeSlider
              headerText={""}
              sliderVal={volume}
              minVal={0}
              maxVal={1}
              step={0.05}
              showVolumeSlider={!isIOS && showVolumeSlider}
              handleSliderChange={changeVolume}
            />
          }
        >
          <MusicImgWrapper id="test" onClick={imageClickFunc} className={"at-button"}>
            {!paused ? <MusicOn className="musicImg" /> : <MusicOff className="musicImg" />}
            {/* <MusicImg src={paused ? imgMusicOffBtn : imgMusicBtn} /> */}
          </MusicImgWrapper>
        </Popover>
      </MusicDiv>
      {audioSrc1 && (
        <AudioTest1
          id="audio1"
          ref={audio1Ref}
          src={audioSrc1}
          onCanPlayThrough={audio1Ready}
          onEnded={audio1Ended}
          muted={false}
        />
      )}

      {audioSrc2 && (
        <AudioTest2
          ref={audio2Ref}
          src={audioSrc2}
          onCanPlayThrough={audio2Ready}
          onEnded={audio2Ended}
          muted={false}
        />
      )}
    </React.Fragment>
  );
};
export default AudioPlayer;
