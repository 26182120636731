import React, { useEffect } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import { H3 } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";

const MapColorsDialog = () => {
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SHOW_MAP_COLOR_DIALOG, payload: false });
  };
  useEffect(() => {
    if (!uiState.showMapColorDialog) return;
  }, [uiState.showMapColorDialog]);
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showMapColorDialog}
      className="at-confirmation-dialog"
      size="sm"
    >
      <div className="at-dialog-area ">
        <AtButton
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon="close"
        />
        <H3 className="at-dialog-heading">{strings.tools.mapColorHeading}</H3>
        {/* <div className="at-dialog-content"> found some designs </div> */}
      </div>
    </AtDialog>
  );
};

export default MapColorsDialog;
