import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import * as THREE from "three";
const svgLoader = new SVGLoader();

const getSVGText = (path) => {
    const svg = `<?xml version="1.0" standalone="no"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
     "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
     <svg viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg"><path d="${path}" /></svg>`
    return svg
}

const UVGenerator = {

    generateTopUV: function (geometry, vertices, indexA, indexB, indexC) {

        const a_x = vertices[indexA * 3];
        const a_y = vertices[indexA * 3 + 1];
        const b_x = vertices[indexB * 3];
        const b_y = vertices[indexB * 3 + 1];
        const c_x = vertices[indexC * 3];
        const c_y = vertices[indexC * 3 + 1];

        return [
            new THREE.Vector2(a_x, a_y),
            new THREE.Vector2(b_x, b_y),
            new THREE.Vector2(c_x, c_y)
        ];

    },

    generateSideWallUV: function (geometry, vertices, indexA, indexB, indexC, indexD) {

        const a_x = vertices[indexA * 3];
        const a_y = vertices[indexA * 3 + 1];
        const a_z = vertices[indexA * 3 + 2];
        const b_x = vertices[indexB * 3];
        const b_y = vertices[indexB * 3 + 1];
        const b_z = vertices[indexB * 3 + 2];
        const c_x = vertices[indexC * 3];
        const c_y = vertices[indexC * 3 + 1];
        const c_z = vertices[indexC * 3 + 2];
        const d_x = vertices[indexD * 3];
        const d_y = vertices[indexD * 3 + 1];
        const d_z = vertices[indexD * 3 + 2];
        return [
            new THREE.Vector2(a_x, a_y),
            new THREE.Vector2(b_x, b_y),
            new THREE.Vector2(c_x, c_y),
            new THREE.Vector2(d_x, d_y)
        ];
    }

};



export const getExtrudedCarpet = ({ svgOutline, depth = 0.05, material }) => {
    if (!svgOutline) return
    // let svgText = svgOutline.replace(/[\r\n]/gm, '')
    let svgText = getSVGText(svgOutline)
    const data = svgLoader.parse(svgText);
    const paths = data.paths;
    let shapes = [];
    for (let i = 0; i < paths.length; i++) {
        Array.prototype.push.apply(shapes, paths[i].toShapes()); // catenate, so we can create a single geometry and mesh
    }
    const extrusionSettings = {
        curveSegments: 100,
        depth: depth,
        bevelEnabled: false,
        bevelThickness: 0,
        bevelSize: 0.01,
        bevelSegments: 1,
        bevelOffset: 0,
        UVGenerator: UVGenerator
    };
    const geometry = new THREE.ExtrudeBufferGeometry(shapes, extrusionSettings);
    geometry.center();
    geometry.needsUpdate = true;
    const mesh = new THREE.Mesh(geometry, material);
    return mesh;
}