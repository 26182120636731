//Component to render the button to take a picture
import React from "react";
import AtButton from ".";
import strings from "../../../strings";
const InputButton = props => {
  let { id, onChange, children, labelClassName, inputRef, onClick, ...buttonprops } = props;
  return (
    <>
      <input
        type="file"
        accept=".png,.jpg"
        id={id}
        className="cameraInputs"
        style={{ display: "none" }}
        onChange={onChange}
        ref={inputRef}
      />
      <AtButton
        className="camera-options__button"
        minimal
        title={strings.myRoom.uploadImage}
        intent={null}
        onClick={onClick}
        text={strings.myRoom.uploadImage}
        {...buttonprops}
      ></AtButton>
    </>
  );
};

export default InputButton;
