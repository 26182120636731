import React, { useState, useRef, useEffect } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import { Button, H5, Tooltip } from "@blueprintjs/core";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import AppProvider from "../../../api/appProvider";
import classNames from "classnames";
const QuickLinkDialog = () => {
  const input = useRef(null);
  const [quickLinkData, setQuickLinkData] = useState();
  const [linkCopied, setLinkCopied] = useState(false);
  const uiState = useUiState();

  const designDetailState = useDesignDetailState();
  const visualizationState = useVisualizationState();
  const dispatchUiState = useUiDispatch();
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_QUICKLINK_DIALOG, payload: false });
  };
  useEffect(() => {
    if (!uiState.showQuickLinkDialog) {
      return;
    }
    setQuickLinkData(null);
    AppProvider.getInstanceUrl({
      file: designDetailState.fullpath,
      props: designDetailState.designDetails,
      view:
        visualizationState.viewMode === visViewModes.ROOM_VIEW
          ? visualizationState.activeNode.FullPath
          : ""
    }).then(response => {
      const { dataurl, url } = response;
      const imgData = `data:image/png;base64,${dataurl}`;
      setQuickLinkData({ url, imgData });
    });
  }, [uiState.showQuickLinkDialog]); //eslint-disable-line
  const handleCopytoClipboard = () => {
    if (!quickLinkData) return;
    input.current.select();
    document.execCommand("copy");
    setLinkCopied(true);
  };
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showQuickLinkDialog}
      className="at-confirmation-dialog"
      size="xs"
    >
      <div className="at-dialog-area">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <H5 className="at-dialog-heading">{strings.tools.quicklinkTitle}</H5>
        <div className="at-dialog-content">
          <div className="at-qr-description">{strings.tools.quicklinkText}</div>
          <img
            className={classNames("at-vr-qrcode", {
              "bp3-skeleton": !quickLinkData
            })}
            src={quickLinkData ? quickLinkData.imgData : ""}
            alt="qr code"
          />
          <Tooltip
            disabled={!quickLinkData}
            position="bottom"
            className="bp3-fill"
            portalContainer={document.getElementById("app-main")}
            content={`${linkCopied ? "Link copied to clipboard" : "Click to copy"}`}
            onClose={() => setLinkCopied(false)}
          >
            <div
              className={classNames("bp3-input-group", "bp3-fill", {
                "bp3-skeleton": !quickLinkData
              })}
              onClick={handleCopytoClipboard}
            >
              <input
                style={{ cursor: "pointer" }}
                ref={input}
                type="text"
                className="bp3-input"
                value={quickLinkData ? quickLinkData.url : ""}
                onChange={() => {}}
                readOnly
              />
              <AtButton className="bp3-button" minimal icon="copy" />
            </div>
          </Tooltip>
        </div>
      </div>
    </AtDialog>
  );
};

export default QuickLinkDialog;
