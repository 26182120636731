/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Slider } from "@blueprintjs/core";
import classNames from "classnames";
import strings from "../../../strings";
import AtRuler from "../../atoms/AtRuler/index";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { calculateKLratio, convertUnit } from "../../../utils/utils";

const AtSlider = props => {
  const {
    onValueChange,
    value,
    max = 100,
    min = 0,
    isIdle,
    zoomFactor,
    canvasInnerHeight,
    ...otherProps
  } = props;

  const designDetailState = useDesignDetailState();
  const { designDetails } = designDetailState;
  const [oneUnit, setOneUnit] = useState(null);
  const [startingValues, setStartingValues] = useState([1, 2, 3, 4, 5]);
  const [segments, setSegments] = useState(5);
  const [rulerUnit, setRulerUnit] = useState(null);
  useEffect(() => {
    document.getElementsByClassName("bp3-slider-handle")[0].style.left = `${((value - min) /
      (max - min)) *
      100}%`;
  }, [value]);
  const getSliderFullWidth = () => {
    const elemStyle = window.getComputedStyle(
      document.getElementsByClassName("sliderContainer")[0]
    );
    let sliderWidth = parseFloat(elemStyle.width);
    const padding = elemStyle.padding.split("px");
    const horizontalPadding = parseFloat(padding[1]) + parseFloat(padding[3]);
    sliderWidth = sliderWidth - horizontalPadding;
    return sliderWidth;
  };
  useEffect(() => {
    if (designDetails && window.flags.visualizations.showRuler) {
      let defaultUnit = designDetails.Unit;
      let rulerDefaultUnit = window.flags.visualizations.rulerDefaultUnit;
      if (
        (rulerDefaultUnit === "cm" || rulerDefaultUnit === "ft") &&
        defaultUnit !== rulerDefaultUnit
      ) {
        defaultUnit = rulerDefaultUnit;
      }
      const KLRatio = calculateKLratio({
        Height: designDetails.Height,
        PhysicalHeight: designDetails.PhysicalHeight,
        Width: designDetails.Width,
        PhysicalWidth: designDetails.PhysicalWidth
      });
      setRulerValues({
        width: designDetails.Width,
        PhysicalWidth: designDetails.PhysicalWidth,
        unit: rulerUnit || rulerUnit === "" ? rulerUnit : defaultUnit,
        zoomFactor,
        KLRatio: KLRatio,
        hideRuler: rulerDefaultUnit === "" && !rulerUnit
      });
    }
  }, [designDetails, zoomFactor, canvasInnerHeight]);

  const setRulerValues = ({
    width,
    PhysicalWidth,
    unit,
    zoomFactor = 1,
    hideRuler = false,

    KLRatio = 1
  }) => {
    if (unit === "") {
      setRulerUnit("");
      return;
    }
    if (window.InterfaceElements.IsJpeg) {
      if (canvasInnerHeight) {
        zoomFactor = canvasInnerHeight / designDetails.Height;
      }
    } else {
      zoomFactor = zoomFactor ? zoomFactor : 1;
    }
    if (width) {
      const sliderWidth = getSliderFullWidth(); // parseFloat( window.getComputedStyle(document.getElementsByClassName('sliderContainer')[0]).width);
      let canvasWid = width * zoomFactor;
      canvasWid = window.InterfaceElements.IsWallToWall ? canvasWid / KLRatio : canvasWid;
      if (unit !== designDetails.Unit) {
        PhysicalWidth = convertUnit(designDetails.Unit, unit, PhysicalWidth);
      }
      let widPerUnit = canvasWid / PhysicalWidth;

      if (unit === "ft") {
        widPerUnit = widPerUnit / 12;
      }

      const markingsLimit = Math.floor(sliderWidth / widPerUnit) || 1;
      const multiplierValues = [
        { limit: 400, multiplier: 200 },
        { limit: 120, multiplier: 60 },
        { limit: 40, multiplier: 20 },
        { limit: 30, multiplier: 10 },
        { limit: 20, multiplier: 5 },
        { limit: 15, multiplier: 2 },
        { limit: 0, multiplier: 1 }
      ];
      const limit = multiplierValues.filter(elem => elem.limit < markingsLimit);
      let multiplier = limit[0].multiplier; //markingsLimit<12? 1:2;
      let markingsValues = [];
      for (var i = 0; i <= markingsLimit / multiplier; i++) {
        markingsValues.push(i * multiplier);
      }
      setRulerUnit(hideRuler ? "" : unit);
      setStartingValues(markingsValues);
      setSegments(markingsValues.length * multiplier);
      setOneUnit(widPerUnit * multiplier);
    }
  };

  const changeRulerUnit = unit => {
    if (designDetails) {
      const KLRatio = calculateKLratio({
        Height: designDetails.Height,
        PhysicalHeight: designDetails.PhysicalHeight,
        Width: designDetails.Width,
        PhysicalWidth: designDetails.PhysicalWidth
      });
      setRulerValues({
        width: designDetails.Width,
        PhysicalWidth: designDetails.PhysicalWidth,
        unit: unit,
        zoomFactor,
        KLRatio: KLRatio
      });
    }
  };
  return (
    <div
      className={classNames(
        "sliderContainer",
        { "at-idle-fadeout": isIdle },
        { hasRuler: window.flags.visualizations.showRuler }
      )}
    >
      {oneUnit && window.flags.visualizations.showRuler && (
        <AtRuler
          distancePixels={oneUnit}
          height={30}
          unit={rulerUnit}
          startingValues={startingValues}
          segments={segments} // {startingValues.length}
          width={getSliderFullWidth()}
          font={window.getComputedStyle(document.getElementsByClassName("sliderContainer")[0]).font}
          color={
            window.getComputedStyle(document.getElementsByClassName("sliderContainer")[0]).color
          }
          changeRulerUnit={changeRulerUnit}
        />
      )}
      <div
        style={{
          width: oneUnit ? oneUnit : "",
          height: "20px",
          backgroundColor: "red",
          display: "none"
        }}
      ></div>
      <Slider {...otherProps} className="visualizationSlider" value={value} max={max} min={min} />
      <div className={"SliderLabelsContainer"}>
        <span className="at-slider-text-visualization">{strings.visualization.visualization}</span>
        <span className="at-slider-text-photorealistic">
          {strings.visualization.photorealistic}
        </span>
      </div>
    </div>
  );
};

export { AtSlider };
