import axios from "axios";
import React, { useEffect, useState } from "react";
import AppProvider, { getPageName } from "../../../api/appProvider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import { useVisualizationState } from "../../../reducers/visualizations.reducer";
import classNames from "classnames";

import AtIcon from "../../atoms/AtIcon";
import { Classes } from "@blueprintjs/core";

const sections = {
  messagePlatforms: [
    {
      title: "Viber",
      id: "ViberPreview",
      previewLink: false,
      themeColor: "#4C33FF",
      iconPath: "viber",
      link: url => `viber://forward?text=${url}`
    },
    {
      title: "WhatsApp",
      id: "WhatsAppPreview",
      previewLink: true,
      themeColor: "#25D366",
      iconPath: "whatsapp",
      link: url => `whatsapp://send?text=${url}`
    },
    // {
    //   title: "Skype",
    //   id: "SkypePreview",
    //   previewLink: false,
    //   themeColor: "#28C5FF",
    //   iconPath: "skype",
    //   link: url => url
    // },
    {
      title: "Email",
      id: "EmailPreview",
      previewLink: false,
      themeColor: "#FF9E23",
      iconPath: "email",
      link: url => `mailto:?subject=Customize My Rug&body= Please visit ${url} to customize my rug.`
    },
    {
      title: "QR Code",
      id: "QRPreview",
      previewLink: false,
      themeColor: "#28C5FF",
      iconPath: "qr-code",
      link: url => url
    }
  ],
  postPlatforms: [
    {
      title: "Facebook",
      id: "FacebookPreview",
      previewLink: true,
      themeColor: "#4495FF",
      iconPath: "facebook",
      link: url => `http://www.facebook.com/sharer.php?u=${url}`
    },
    {
      title: "Twitter",
      id: "TwitterPreview",
      previewLink: true,
      themeColor: "#50BCFF",
      iconPath: "x",
      link: url => `http://twitter.com/share?url=${url}`
    },
    {
      title: "LinkedIn",
      id: "LinkedInPreview",
      previewLink: false,
      themeColor: "#5FA8FF",
      iconPath: "linkedin",
      link: url => `http://www.linkedin.com/shareArticle?mini=true&url=${url}`
    },
    {
      title: "Pinterest",
      id: "PinterestPreview",
      previewLink: false,
      themeColor: "#FF7388",
      iconPath: "pinterest",
      link: url => `http://pinterest.com/pin/create/button/?url=${url}`
    }
  ]
};

const isSkype = title => {
  return title === "Skype";
};
const isFacebook = title => {
  return title === "Facebook";
};
const SocialMediaShare = props => {
  const roomState = useVisualizationState();
  const dispatchUiState = useUiDispatch();
  const state = useDesignListState();
  const designDetailState = useDesignDetailState();
  const dispatchDesignState = useDispatchDesignDetail();
  const [catUrl, setCatUrl] = useState();

  useEffect(() => {
    const { selectedFile, activeColorScheme, activeShape, activeVariation } = state;
    const selectedFileFullPath = selectedFile?.fullPath;
    var activeColorSchemeFullPath = activeColorScheme.fullPath?.split("/").pop();
    var activeShapeFullPath = activeShape.fullPath?.split("/").pop();
    var activeVariationFullPath = activeVariation?.fullPath;

    var initview;
    var initvar = activeVariationFullPath ? activeVariationFullPath : activeColorSchemeFullPath;
    if (initvar) {
      const initvarParts = initvar.split(".");
      initvarParts.pop();
      initvar = initvarParts
        .join(".")
        .split("/")
        .pop();
    }
    var initShapeVar = activeShapeFullPath || "original";
    if (initShapeVar) {
      const initShapeVarParts = initShapeVar.split(".");
      initShapeVarParts.pop();
      initShapeVar = initShapeVarParts.join(".");
    }
    // console.log("initvar", initvar, "initShapeVar", initShapeVar);
    let url = `${window.location.href}/explorug.html?initdesign=${selectedFileFullPath}`;
    if (initvar) {
      url += `&initvar=${initvar}`;
    }
    if (initShapeVar) {
      url += `&initshapevar=${initShapeVar}`;
    }
    if (roomState.viewMode === "MY_ROOM") {
      initview = "myroom";
    } else {
      initview = roomState.activeNode.FullPath;
    }
    url += `&initview=${initview}`;
    url += "&mode=ecat&pageview=createyourrug&page=" + getPageName()?.page;
    // console.log("url", url);
    url = encodeURIComponent(url);
    let selectedFileFullPathTemp = designDetailState.fullpath;
    if (
      window.flags.accountSpecific.tamarian.enableCustomShare &&
      designDetailState.customSize.x != 0
    ) {
      selectedFileFullPathTemp = designDetailState.customSize.currentCustomSizeFilename;
    }
    let data = new FormData();
    data.append("url", url);
    axios.post("https://explorug.com/go/shortener.aspx", data).then(res => {
      if (res && res.data) {
        AppProvider.publishDesign({
          file: selectedFileFullPathTemp,
          props: window.flags.rugshare.shareCustomizedDesign
            ? designDetailState.designDetails
            : null,
          view: initview,
          title: selectedFile.name,
          desc: window.flags.rugshare.socialMediaShareDescription,
          fullurl:
            window.flags.accountSpecific.tamarian.enableCustomShare &&
            designDetailState.customSize.x != 0
              ? ""
              : res.data,
          mode: "CYR"
        }).then(data => {
          setCatUrl(data.CatalogCache);
          // if (data.Url.includes("/")) {
          //   const getCid = data.Url.split("/").pop();
          //   const urlWithCid = url + "&cid=" + getCid;
          //   let data1 = new FormData();
          //   data1.append("url", urlWithCid);
          //   setCatUrl(urlWithCid);
          //   // axios.post("https://explorug.com/go/shortener.aspx", data1, {}).then(res => {
          //   //   if ((res && res.data) || window.flags.createYourRug.saveColorPropsinQr) {
          //   //     const shortenUrl = res.data;
          //   //     var shareurl = "";
          //   //     shareurl = platform.link(shortenUrl);
          //   //     console.log(shortenUrl);
          //   //     dispatchDesignState({ type: designDetailActions.SET_URL_QR, payload: shortenUrl });
          //   //   }
          //   // });
          // }
        });
      }
    });
  }, []);

  const getCurrentSelectedSet = platform => {
    var shareurl = "";
    shareurl = platform.link(catUrl);
    dispatchDesignState({ type: designDetailActions.SET_URL_QR, payload: catUrl });
    if (platform.title === "QR Code") {
      dispatchUiState({ type: mainUiActions.SET_QR_DIALOG, payload: true });
      return;
    }
    window.open(shareurl, "_blank");

    if (isSkype(platform.title)) {
      const skypeShareButton = document.getElementById("skypeshare");
      console.log("skypeShareButton", skypeShareButton);
      skypeShareButton.setAttribute("data-href", shareurl);
      skypeShareButton.click();
    }
  };
  return (
    <div className="cyr-socialmediashare-area">
      <div className="cyr-heading">Share This View</div>

      <div className={classNames("cyr-socialmediashare", !catUrl && Classes.SKELETON)}>
        {sections &&
          sections.messagePlatforms.length &&
          sections.messagePlatforms.map((platform, index) => (
            <div key={index} className="cyr-social-media">
              {platform.title === "Skype" && (
                <>
                  <div
                    className="skype-share"
                    data-href={""}
                    data-lang=""
                    data-text=""
                    id="skypeshare"
                  ></div>
                  {setSkypeBtn()}
                </>
              )}
              <div onClick={() => getCurrentSelectedSet(platform)}>
                <div className="platform-icon-area">
                  <AtIcon icon={platform.iconPath} />
                </div>
                <div className="platform-title">{platform.title}</div>
              </div>
            </div>
          ))}
      </div>

      <div className="cyr-heading" style={{ marginTop: "1.2rem" }}>
        Post to Social Media
      </div>

      <div className={classNames("cyr-socialmediashare", !catUrl && Classes.SKELETON)}>
        {sections &&
          sections.postPlatforms.length &&
          sections.postPlatforms.map((platform, index) => (
            <div
              key={index}
              className="cyr-social-media"
              onClick={() => getCurrentSelectedSet(platform)}
            >
              <div className="platform-icon-area">
                <AtIcon icon={platform.iconPath} />
              </div>
              <div className="platform-title">{platform.title}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

SocialMediaShare.propTypes = {};

export default SocialMediaShare;

function setSkypeBtn() {
  (function(r, d, s) {
    r.loadSkypeWebSdkAsync =
      r.loadSkypeWebSdkAsync ||
      function(p) {
        var js,
          sjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(p.id)) {
          return;
        }
        js = d.createElement(s);
        js.id = p.id;
        js.src = p.scriptToLoad;
        js.onload = p.callback;
        sjs.parentNode.insertBefore(js, sjs);
      };
    var p = {
      scriptToLoad: "https://swx.cdn.skype.com/shared/v/latest/skypewebsdk.js",
      id: "skype_web_sdk"
    };
    r.loadSkypeWebSdkAsync(p);
  })(window, document, "script");
}
