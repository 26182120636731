import { CDN_domain } from "../../../../api/appProvider";

export const rootAssetPath = "https://g-helper.s3.amazonaws.com/Cinematic/";

export const musicPath = rootAssetPath + "Music";

export const iconPath = CDN_domain+"v3assets/cinematic/";
// https://g-helper.s3.amazonaws.com/Cinematic/Music/bensound-adventure.mp3

export const imgMutedBtn = iconPath + "muted.png";
export const imgVolumeBtn = iconPath + "volume.png";

export const music1 = musicPath + "/bensound-adventure.mp3";
export const music2 = musicPath + "/bensound-allthat.mp3";
export const music3 = musicPath + "/bensound-betterdays.mp3";
export const music4 = musicPath + "/bensound-tomorrow.mp3";
export const music5 = musicPath + "/bensound-love.mp3";
export const music6 = musicPath + "/bensound-memories.mp3";
export const music7 = musicPath + "/bensound-newdawn.mp3";
export const music8 = musicPath + "/bensound-pianomoment.mp3";
export const music9 = musicPath + "/bensound-slowmotion.mp3";
export const music10 = musicPath + "/bensound-thejazzpiano.mp3";



export const defaultMusicArr = [
  {
    type: "Default",
    music: music1
  },
  {
    type: "Default",
    music: music2
  },
  {
    type: "Default",
    music: music3
  },
  {
    type: "Default",
    music: music4
  },
  {
    type: "Default",
    music: music5
  },
  {
    type: "Default",
    music: music3
  },
  {
    type: "Default",
    music: music6
  },
  {
    type: "Default",
    music: music7
  },
  {
    type: "Default",
    music: music8
  },
  {
    type: "Default",
    music: music9
  },
  {
    type: "Default",
    music: music10
  }
];