import React from "react";
import { ButtonGroup } from "@blueprintjs/core";
import { useUiState, useUiDispatch, mainUiActions } from "../../reducers/mainui.reducer";
import AtButton from "../atoms/AtButton";
import {
  useDispatchVisualization,
  useVisualizationState,
  visualizationsActions,
  visViewModes
} from "../../reducers/visualizations.reducer";
import { ReactComponent as CinematicSvg } from "../atoms/AtIcon/video.svg";
import DesignDetailControls from "./DesignDetailSection/DesignDetailControls";
import strings from "../../strings";

const SidebarActionButtons = props => {
  const {
    isFullScreen,
    handleFullScreen,
    handleCinematic,
    handleFavorite,
    handleDownloadImage,
    handleRugShare
  } = props;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();

  const visualizationState = useVisualizationState();
  const dispatchVisualization = useDispatchVisualization();
  // const handleMyroomHelp = () => {
  //   //dispatchMyroom({ type: myRoomActions.SHOW_WALKTHROUGH, payload: true });
  //   window.open(
  //     "https://alternativetechnology.zendesk.com/hc/en-us/articles/900000724783",
  //     "_blank"
  //   );
  // };
  const handleCloseMyRoom = () => {
    dispatchVisualization({
      type: visualizationsActions.SWITCH_PREV_VIEW
    });
  };
  const backtoDesignView = ()=>{
    dispatchVisualization({
      type: visualizationsActions.SET_VIEW_MODE,
      payload:visViewModes.DESIGN_VIEW_DEEPZOOM
    });
  }
  const showBackToDesignBtn = ()=>{
    let show =visualizationState.viewMode === visViewModes.ROOM_VIEW && 
    window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_DEEPZOOM) && 
    window.flags.visualizations.showBackToDesignViewInRoomView;
    return show;        
  }
  return (
    <ButtonGroup className="at-sidebar__action-btns">
      {uiState.showVrBtn && (
        <AtButton
          minimal
          intent={null}
          icon="vr-glasses"
          onClick={() => {
            dispatchUiState({ type: mainUiActions.SET_SHOW_VR_DIALOG, payload: true });
          }}
        />
      )}
      <AtButton
      className="custom-dialog-toggle"
      title={strings.tools.help}
          minimal
          intent={null}
          icon="question-circle"
          onClick={() => {
            dispatchUiState({ type: mainUiActions.SET_SHOW_ENTRY_DIALOG, payload: true });
          }}
        />
      {/* {visualizationState.viewMode === visViewModes.MY_ROOM && (
        <AtButton
          minimal
          title="Tutorial"
          intent={null}
          icon="question"
          onClick={handleMyroomHelp}
        />
      )} */}
      {/* {(window.flags.hasArp || window.InterfaceElements.IsAdmin) && (
          <AtButton
            minimal
            title="Augmented Rug Presentation"
            intent={null}
            icon="augmented-rug"
            onClick={handleArp}
          />
        )} */}
    
      {window.flags.hasCinematic && (
        <AtButton minimal title="Cinematic" onClick={handleCinematic}>
          <span className="bp3-icon at-icon">
            <CinematicSvg />
          </span>
        </AtButton>
      )}
      {
        showBackToDesignBtn() && 
        <AtButton className="at-back-to-design"
        intent={null}
        minimal
        onClick={backtoDesignView}
        >Back to Design</AtButton>
      }

      <AtButton
        className="at-fullscreen"
        minimal
        title={`${isFullScreen ? "Exit" : "Enter"} Fullscreen`}
        intent={null}
        icon={`${isFullScreen ? "exit" : "enter"}-fullscreen`}
        onClick={handleFullScreen}
      ></AtButton>
      {visualizationState.viewMode === visViewModes.MY_ROOM && (
        <AtButton minimal title="Close" intent={null} icon="close" onClick={handleCloseMyRoom} />
      )}

      {window.flags.designDetailSection.showAsSidebarActionBtns && (
        <DesignDetailControls
          handleRugShare={handleRugShare}
          handleFavorite={handleFavorite}
          handleDownloadImage={handleDownloadImage}
          parentID="at-sidebar__action-btns"
        ></DesignDetailControls>
      )}
    </ButtonGroup>
  );
};

export default SidebarActionButtons;
