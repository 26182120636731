import React, { useEffect } from "react";
import AtButton from "../AtButton";
const AtSizeDropdown = ({ handleCustomSize, handleSizeChange, dropDownValue, designSizes }) => {
  const defaultUnit = window.flags.defaultUnit;
  const minmaxCm = window.flags.createYourRug.enableCustomSize.minMaxCustomSizeCm;
  const minmaxFt = window.flags.createYourRug.enableCustomSize.minMaxCustomSizeFt;

  useEffect(() => {
    const inputs = document.querySelectorAll(".checkVal");
    const inputsInch = document.querySelectorAll(".checkValForInch");

    inputs.forEach(input => {
      input.addEventListener("blur", function() {
        const minValue = parseInt(this.min);
        const maxValue = parseInt(this.max);
        if (this.value < minValue) {
          this.value = minValue; // Reset to min if lower value is entered
        }
        if (this.value > maxValue) {
          this.value = maxValue; // Reset to max if higher value is entered
        }
      });
    });
    inputsInch.forEach(input => {
      input.addEventListener("blur", function() {
        const minValue = parseInt(this.min);
        const maxValue = parseInt(this.max);
        if (this.value < minValue) {
          this.value = minValue; // Reset to min if lower value is entered
        }
        if (this.value > maxValue) {
          this.value = maxValue; // Reset to max if higher value is entered
        }
      });
    });
  }, []);
  return (
    <div>
      <div className="thumb-image-container portrait selectColorInSimilarColorsTab">
        <div className="plusIconDiv">
          <div id="cyr-sizeDropdown">
            <div className="sizeInputDiv">
              <div>
                <select id="sizes" name="sizes" value={dropDownValue} onChange={handleSizeChange}>
                  {designSizes.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <p className="sizeInputText">Width</p>
                {defaultUnit == "cm" ? (
                  <div className="sizeInputWidthLength">
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkVal"
                        id="customSizeWidthCm"
                        min={minmaxCm.width[0]}
                        max={minmaxCm.width[1]}
                      />
                      <p>cm</p>
                    </div>
                  </div>
                ) : (
                  <div className="sizeInputWidthLength dflex">
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkVal"
                        id="customSizeWidthFt"
                        pattern="^[0-9]"
                        min={minmaxFt.width[0]}
                        max={minmaxFt.width[1]}
                      />
                      <p>ft</p>
                    </div>
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkValForInch"
                        id="customSizeWidthIn"
                        pattern="^[0-9]"
                        min="0"
                        max="11"
                      />
                      <p>in</p>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                <p className="sizeInputText">Length</p>

                {defaultUnit == "cm" ? (
                  <div className="sizeInputWidthLength">
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkVal"
                        id="customSizeHeightCm"
                        min={minmaxCm.height[0]}
                        max={minmaxCm.height[1]}
                      />
                      <p>cm</p>
                    </div>
                  </div>
                ) : (
                  <div className="sizeInputWidthLength dflex">
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkVal"
                        id="customSizeHeightFt"
                        pattern="^[0-9]"
                        min={minmaxFt.height[0]}
                        max={minmaxFt.height[1]}
                      />
                      <p>ft</p>
                    </div>
                    <div className="sizeInputWidthLengthInput">
                      <input
                        type="number"
                        className="sizeInput checkValForInch"
                        id="customSizeHeightIn"
                        pattern="^[0-9]"
                        min="0"
                        max="11"
                      />
                      <p>in</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <AtButton className="sizeConfirmButton" onClick={handleCustomSize} intent="primary">
              Confirm
            </AtButton>
          </div>
        </div>
      </div>
      <span className="thumb-title" title="Customize" style={{ visibility: "hidden" }}>
        <p>Customize</p>
      </span>
    </div>
  );
};

export default AtSizeDropdown;
