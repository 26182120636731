import React from "react";
import AtIcon from "../AtIcon";

const CircularButton = props => {
  const { icon, onClick, className = "", size = "medium" } = props;
  return (
    <div className={`circular-button ui ${className} ${size}`} onClick={onClick}>
      <AtIcon icon={icon} />
    </div>
  );
};

export default CircularButton;
