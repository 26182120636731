import React from "react";
import classNames from "classnames";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import Stage from "../../organisms/StageNew";
import LeftSidebarStepper from "../../templates/LeftSidebar/LeftSidebarStepper";
import SidebarActionButtons from "../../organisms/SidebarActionButtons";
import { getFromSessionStorage } from "../../../utils/domUtils";

const AppContainerStepper = props => {
  const { handleDownloadImage,handleCinematic, isFullScreen, handleFullScreen, customClass='' } = props;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  return (
    <div
      className={classNames(
        "app-container",
        "at-container-stepper",
        {
          transparent: uiState.showLoadingOverlay,
          busyState: uiState.isBusy
        },
        {
          "admin-mode": window.InterfaceElements.IsAdmin
        },
        {
          "multiple-mode": !window.InterfaceElements.IsAdmin
        },
        {
          "ecat-mode": getFromSessionStorage("mode") === "ecat"
        },
        {"isFelt": window.flags.isFelt},
        customClass
      )}
      onClick={() => {
        if (uiState.showCustoScout)
          dispatchUiState({ type: mainUiActions.SET_SHOW_CUST_SCOUT, payload: false });
      }}
    >
      <LeftSidebarStepper isFullScreen={isFullScreen} handleDownloadImage={handleDownloadImage} />
      <Stage className={!uiState.showLeftSidebar ? "panel-hidden" : ""} />
      <SidebarActionButtons
        isFullScreen={isFullScreen}
        handleFullScreen={handleFullScreen}
        handleCinematic ={handleCinematic}

      />
    </div>
  );
};

export default AppContainerStepper;
