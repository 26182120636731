import AppProvider from "../api/appProvider";
import { findWhere } from "../utils/arrayUtils";
import { isMobileDevice } from "../utils/utils";

export const resolveZoomValue = () => {
  let zoom = window.flags.designView.defaultZoom;
  if (AppProvider.isStripedDesignMode || (isMobileDevice && window.flags.designView.zoomForMobile))
    zoom = 0.75;
  else if (window.InterfaceElements.IsJpeg) {
    zoom = window.flags.designView.defaultZoom <= window.flags.designView.maxZoomJpeg ? window.flags.designView.defaultZoom : 0.75;
  }
  return zoom;
};

const isSmallerThan = (value, maxvalue) => {
  var x = value <= maxvalue ? value : maxvalue;
  return x;
};
const isGreaterThan = (value, minvalue) => {
  var x = value >= minvalue ? value : minvalue;
  return x;
};
const minZoomForJpeg = (minZoomByFlag, defaultZoom)=>{
  const zoom = isGreaterThan(minZoomByFlag, 0) && isSmallerThan(minZoomByFlag, defaultZoom) ? minZoomByFlag : 0;
  return zoom;
}

export const getZoomParameters = () => {
  const isJpeg = window.InterfaceElements.IsJpeg;
  const w2w = window.InterfaceElements.IsWallToWall;
  const minZoom =  window.flags.designView.minZoom;
  const maxZoom = window.flags.designView.maxZoom;
  const defaultZoom = window.flags.designView.defaultZoom;
  const minZoomJpeg  = window.flags.designView.minZoomJpeg;
  const maxZoomJpeg = window.flags.designView.maxZoomJpeg;

  let zoomValues = {
    minZoom: isJpeg && w2w ? 0.5 : isJpeg ? minZoomForJpeg(minZoomJpeg, defaultZoom) : minZoom,
    maxZoom: window.InterfaceElements.IsJpeg ? maxZoomJpeg : maxZoom,
    step: window.InterfaceElements.IsJpeg ? 0.1 : 0.25
  };
  window.flags.designView.minZoom = zoomValues.minZoom;
  window.flags.designView.maxZoom = zoomValues.maxZoom;

  return zoomValues;
};

export const getnewIconIfReplaceable = value => {
  const replaceableIconList = window.flags.interface?.iconsReplace;
  let newIcon = value;
  if (replaceableIconList && replaceableIconList.length) {
    const icon = findWhere(replaceableIconList, "orig", value);
    if (icon) {
      newIcon = icon.new;
    }
  }
  return newIcon;
};