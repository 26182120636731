import * as THREE from "three";
import { OrbitControls } from "../../../utils/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { convertArrIntoRad, convertUnit } from "../../../utils/utils";
import { readImage } from "../../../utils/domUtils";
import { createCanvas } from "../../../utils/canvasutils";
import CameraControls from "./cameracontrols/camera-controls";

CameraControls.install({ THREE: THREE });

export default class PhotographicView {
  constructor() {
    this.scene = new THREE.Scene();

    this.textureLoader = new THREE.TextureLoader();
    this.fbxLoader = new FBXLoader();
    this.raycaster = new THREE.Raycaster();
    this.offset = new THREE.Vector3();
    this.objectLoaded = false;
    this.object = null;
    this.w = null;
    this.h = null;
    this.hasBackSurface = false;
  }
  init({ canvas, config = {}, shot, dims = {}, resolution, roomType, baseUrl }) {
    console.log(this.scene.children);
    let { width = window.innerWidth, height = window.innerHeight } = dims;
    this.w = width;
    this.h = height;
    this.sceneConfig = config;
    let { orbitControl = false } = config;
    this.baseUrl = baseUrl;
    if (roomType === "illustration") {
      canvas.style.visibility = "hidden";
    } else {
      canvas.style.visibility = "inherit";
    }
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: false
    });
    this.renderer.setPixelRatio(resolution);
    this.renderer.setSize(width, height);
    const camConfig = config[shot];
    this.camera = perspectiveCamera({ ...camConfig, width, height });
    this.scene.add(this.camera);
    window.scene = this.scene;
    // this.orbit = addCameraControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit = addOrbitControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit.enabled = true;
    // this.orbit.screenSpacePanning = true;
  }
  setup3dObject({ fbxUrl }) {
    return new Promise((resolve, reject) => {
      if (!this.sceneConfig) return;
      const { objects3d, surfaces } = this.sceneConfig;
      const setupObjects = () => {
        let objectsLoaded = 0;
        objects3d.forEach(object3d => {
          console.log(object3d);
          const object = this.objectFbx.getObjectByName(object3d);
          if (!object) {
            console.warn("PHOTOGRAPHIC VIEW: object", object3d, "does not exist");
            return;
          }

          this.scene.add(object);
          const objectConfig = this.sceneConfig[object3d];
          const {
            position = [0, 0, 0],
            rotation = [90, 0, 0],
            scale = [1, 1, 1],
            preset = false
          } = objectConfig;
          object.position.fromArray(position);
          object.scale.fromArray(scale);
          object.rotation.fromArray(convertArrIntoRad(rotation));
          if (!preset) {
            this.objProps = objectConfig;
            if (surfaces) {
              const { back, front } = surfaces;
              this.object = object.getObjectByName(front);
              if (back) {
                this.objectBack = object.getObjectByName(back);
                this.hasBackSurface = true;
              } else {
                this.hasBackSurface = false;
              }
            } else {
              this.object = object;
            }
            if (this.material) {
              this.object.material = this.material;
              this.object.material.needsUpdate = true;
              this.render();
            }
            objectsLoaded++;

            if (objectsLoaded === objects3d.length) resolve();
          } else {
            const { defaultTexture, defaultScale = [9, 12] } = objectConfig;
            const { width: texWidth = 9, height: texHeight = 12, path } = defaultTexture;
            const textureUrl = `${this.baseUrl}/${path}`;
            let repeat = [1, 1];
            const rx = defaultScale[0] / texWidth;
            const ry = defaultScale[1] / texHeight;
            repeat = [rx, ry];
            console.log("setupObjects -> repeat", repeat);
            readImage(textureUrl).then(image => {
              const { width, height } = image;
              const canv = createCanvas(width, height);
              canv.getContext("2d").drawImage(image, 0, 0, width, height);

              const texture = new THREE.CanvasTexture(canv);
              texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
              texture.colorSpace = THREE.SRGBColorSpace;

              texture.repeat.fromArray(repeat);

              let material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                side: THREE.DoubleSide,
                alphaTest: 0.5
              });

              object.material = material;
              object.material.needsUpdate = true;
              this.render();

              objectsLoaded++;
              if (objectsLoaded === objects3d.length) resolve();
            });
          }
        });
      };
      if (!this.objectLoaded)
        this.fbxLoader.load(
          fbxUrl,
          obj => {
            this.objectFbx = obj;
            setupObjects();
          },
          undefined,
          console.error
        );
      else setupObjects();
    });
  }
  setObjectTexture({ designDetails, designCanvas, backDesignCanvas }) {
    return new Promise((resolve, reject) => {
      const { surfaceUnit = "in", doubleSide } = this.objProps;
      const PhysicalWidth = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalWidth
      );
      const PhysicalHeight = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalHeight
      );
      this.designDetails = {
        ...designDetails,
        PhysicalHeight,
        PhysicalWidth,
        Unit: surfaceUnit
      };
      const designTexture = new THREE.CanvasTexture(designCanvas);
      designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
      designTexture.colorSpace = THREE.SRGBColorSpace;
      // designTexture.flipY = false;
      this.material = new THREE.MeshBasicMaterial({
        map: designTexture,
        transparent: true,
        side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
        alphaTest: 0.5
      });
      if (!this.object) {
        console.error("could not find the object");
        resolve();
        return;
      }
      this.object.material = this.material;
      this.object.material.needsUpdate = true;
      if (this.hasBackSurface && backDesignCanvas) {
        const designTextureBack = new THREE.CanvasTexture(backDesignCanvas);
        designTextureBack.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        designTextureBack.wrapS = designTextureBack.wrapT = THREE.RepeatWrapping;
        designTextureBack.colorSpace = THREE.SRGBColorSpace;
        this.materialBack = new THREE.MeshBasicMaterial({
          map: designTextureBack,
          transparent: true,
          side: THREE.DoubleSide,
          alphaTest: 0.5,
          needsUpdate: true
        });
        this.objectBack.material = this.materialBack;
        this.objectBack.material.needsUpdate = true;
      }
      this.render();
      resolve();
    });
  }
  updateMap() {
    if (this.object && this.object.material.map) {
      this.object.material.map.needsUpdate = true;
      this.object.material.needsUpdate = true;
    }
    if (this.objectBack && this.objectBack.material.map) {
      this.objectBack.material.needsUpdate = true;
      this.objectBack.material.map.needsUpdate = true;
    }
    this.render();
  }
  clearScene() {
    var objsToRemove = this.scene.children;
    objsToRemove.forEach(object => {
      this.scene.remove(object);
    });
    if (this.renderer) this.render();
  }
  // clearScene() {
  //   while (this.scene.children.length > 0) {
  //     this.scene.remove(this.scene.children[0]);
  //   }
  //   if (this.renderer) this.render();
  // }
  clearrenderer() {
    if (this.renderer) this.renderer.clear();
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  resizeRenderer({ width, height }) {
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
}

export const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new THREE.Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};

export const addOrbitControl = function(renderer, scene, camera, config = {}) {
  let { target = [0, 0, 0] } = config;
  const control = new OrbitControls(camera, renderer.domElement);
  control.enableKeys = false;
  control.target = new THREE.Vector3(...target);
  control.addEventListener("change", () => {
    renderer.render(scene, camera);
  });
  control.update();
  return control;
};
const addCameraControl = function(renderer, scene, camera, config = {}) {
  let { target = [0, 0, 0], boundingBox: bounds } = config;
  const clock = new THREE.Clock();

  const cameraControls = new CameraControls(camera, renderer.domElement);
  cameraControls.setTarget(target[0], target[1], target[2], false);
  const bb = new THREE.Box3(
    new THREE.Vector3(-1000, -500, -1500),
    new THREE.Vector3(500, 500, 1000)
  );
  if (bounds) {
    cameraControls.setBoundary(bb);
    cameraControls.verticalDragToForward = true;
    cameraControls.polarRotateSpeed = 0;
    cameraControls.azimuthRotateSpeed = 0;
    cameraControls.minDistance = 1200;
    cameraControls.maxDistance = 2000;
    cameraControls.boundaryFriction = 0.2;
  }
  console.log(cameraControls);

  renderer.render(scene, camera);

  (function anim() {
    const delta = clock.getDelta();
    const elapsed = clock.getElapsedTime();
    const updated = cameraControls.update(delta);
    requestAnimationFrame(anim);
    if (updated) {
      // cameraControls.getTarget(centerHelper.position);
      renderer.render(scene, camera);
      // console.log("rendered");
    }
  })();
  return cameraControls;
};
export const loadFbx = url => {
  return new Promise((resolve, reject) => {
    new FBXLoader().load(url, resolve, undefined, reject);
  });
};
