import classNames from "classnames";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useFullscreen, useToggle } from "react-use";
import ReactGA from "react-ga";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { getCustomClass, getFromSessionStorage, handleNavigation } from "../../../utils/domUtils";
import { useHomeMount } from "../Home/hooks";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import Stage from "../../organisms/StageNew";
import BrandLogo from "../../molecules/BrandLogo";
import strings from "../../../strings";
import RoomList from "../../organisms/RoomList";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import ColorAreaSwatch from "../../molecules/ColorAreaSwatch";
import Collection from "../../organisms/CollectionSection";
import CopyrightSection from "../../molecules/CopyrightSection";
import { useInitDesignMount } from "../../../hooks/initDesignListHook";
import { findSimilarDesigns } from "../../../utils/treeutils";
import {
  useDispatchVisualization,
  useVisualizationState,
  visualizationsActions,
  visViewModes
} from "../../../reducers/visualizations.reducer";
import DesignVariations from "../../organisms/DesignVariations";
import AppProvider, { CDN_domain, getPageName } from "../../../api/appProvider";
import SocialMediaShare from "../../organisms/SocialMediaShare";
import AtButton from "../../atoms/AtButton";
import AtIcon from "../../atoms/AtIcon";
import OrdersheetArea from "../../templates/OrderSheet/OrdersheetArea";
import SidebarActionButtons from "../../organisms/SidebarActionButtons";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import {
  convertUnits,
  getDesignDimensionsString,
  getDesignPathInTitle
} from "../../../utils/utils";
import { renderDesignName } from "../../../utils/stringUtils";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
import DesignViewsCYR from "../../organisms/DesignViewsCYR";
import { resolveZoomValue } from "../../../middleware/visualization.middleware";
import QRDialog from "../../organisms/QRDialog";
import LoadingOverlay from "../LoadingOverlay/index";
import { H4, Icon, Intent, Menu, MenuItem, Popover, Toast, Toaster } from "@blueprintjs/core";
import CinematicStager from "../../cinematic/CinematicStager";

var scrollBarsToggle = {
  illustrations: false
};
var roomContainerClassName = ".at-vis-thumbs";
var isInitialLoad = true;
const CreateYourRug = props => {
  const dispatchDesignList = useDispatchDesignList();
  const visualizationState = useVisualizationState();
  const visualizationDispatch = useDispatchVisualization();
  const designDetailsState = useDesignDetailState();
  const dispatchDesignState = useDispatchDesignDetail();
  const { designDetails, designName, fullpath } = designDetailsState;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const state = useDesignListState();
  const [showScrollBars, setShowScrollBars] = useState(scrollBarsToggle);
  const [hasVariations, setHasVariations] = useState(true);
  const refCreateYourRug = useRef(null);
  const [toastProps, setToastProps] = useState(null);
  const [detailViewsOptions, setDetailViewsOptions] = useState(
    window.flags.createYourRug.detailViewsOptions
  );
  const [currentDetailView, setCurrentDetailView] = useState(
    sessionStorage.getItem("defaultDesignView") || window.flags.createYourRug.defaultDesignView //url link priority first,then flags
  );
  const [isRoomviewInFullScreen, toggleIsRoomviewInFullScreen] = useToggle(false);
  const refRoomViewContainer = useRef(null);
  const [hasInitialScrollDiv, setHasInitialScrollDiv] = useState(true);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const [displayIcons, setDisplayIcons] = useState(true);
  const [dimensions, setDimensions] = useState("");
  const {
    createYourRug: cyrFlags,
    language,
    designDetailSection,
    ordersheet: ordersheetFlags
  } = window.flags;
  const [customTamarianUpdate, setCustomTamarianUpdate] = useState(false);
  let langFromUrl = getFromSessionStorage("lang");
  let defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : "en";
  const hasLangOptions = language.langOptions.length && language.langOptions[0] !== "";
  const [currentLanguage, setCurrentLanguage] = useState(defLang);
  const [isCinematic, setisCinematic] = useState(sessionStorage.getItem("cinematic") || false);
  const { initDesignColors } = window.initialData;
  useFullscreen(refRoomViewContainer, isRoomviewInFullScreen, {
    onClose: () => toggleIsRoomviewInFullScreen(false)
  });
  useHomeMount();
  useInitDesignMount();

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "create-your-rug";
    dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 30 });

    if (window.TextureOptions?.AdditionalTextureNames?.indexOf("Back") !== -1) {
      setDetailViewsOptions([
        visViewModes.DESIGN_VIEW_DEEPZOOM,
        visViewModes.DESIGN_VIEW_PERSPECTIVE,
        visViewModes.DESIGN_VIEW_FOLDEDBACK
      ]);
    }

    document.getElementsByTagName("body")[0].className = "create-your-rug";
    let scrollTo = sessionStorage.getItem("scrollTo") || "";
    if (scrollTo) setHasInitialScrollDiv(true);
    setTimeout(() => {
      let illustrations = scrollBarsToggle.illustrations;
      const elem = document.querySelector(roomContainerClassName);
      if (elem && elem.clientWidth < elem.scrollWidth) {
        illustrations = true;
      }
      setShowScrollBars({ ...showScrollBars, illustrations });
      const elemVariations = document.querySelector("#atDesignVariations");
      //console.log("setTimeout -> elemVariations", elemVariations)
      elemVariations ? setHasVariations(true) : setHasVariations(false);
      if (scrollTo !== "") {
        scrollIntoView("#" + scrollTo);
      }
      setHasInitialScrollDiv(false);
    }, 3000);
    setTimeout(() => {
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 60 });
    }, 1500);
    dispatchDesignState({
      type: designDetailActions.SET_DESIGN_VIEW_ZOOM,
      payload: window.flags.designView.minZoom
    });
  }, []);

  useEffect(() => {
    let { designProps: designDetails, name: designName, fullPath } = state.selectedFile;
    if (!designDetails) return;
    if (initDesignColors && initDesignColors.length === designDetails.DesignColors.length) {
      dispatchDesignState({
        type: designDetailActions.SET_DETAILS,
        payload: {
          designDetails: { ...designDetails, DesignColors: initDesignColors },
          customizationFlag: true,
          label: designName,
          fullpath: fullPath
        }
      });
    }
  }, [state.selectedFile.id]);

  useEffect(() => {
    if (!state.selectedFile) return;
    if (state && state.selectedFile && state.selectedFile.variations) {
      setHasVariations(true);
      if (
        state.selectedFile.variations?.colors?.length === 0 &&
        state.selectedFile.variations?.shapes?.length === 0
      ) {
        setHasVariations(false);
      }
    } else {
      setHasVariations(false);
    }
  }, [state.selectedFile]);

  useEffect(() => {
    if (!designDetails) return;
    const isIrregular = designDetails.IsIrregular;
    if (
      isIrregular &&
      detailViewsOptions[currentDetailView] === visViewModes.DESIGN_VIEW_FOLDEDBACK
    ) {
      dispatchUiState({
        type: mainUiActions.SET_TOAST_PROPS,
        payload: {
          message: "Sorry, folded view is unavailable for this design.",
          intent: "warning"
        }
      });
      setCurrentDetailView(0);
    }
  }, [designDetails]);

  useEffect(() => {
    if (!visualizationState.activeNode) return;
    var thumb = document
      .querySelector(".designThumb-template0.thumb-item.active")
      ?.getBoundingClientRect();
    var container = document.querySelector(roomContainerClassName);
    if (thumb && container) {
      if (thumb.left > container?.clientWidth || thumb.left < 0) {
        container.scrollLeft = thumb.left;
      }
    }
  }, [visualizationState.activeNode]);

  useEffect(() => {
    if (visualizationState.viewMode == visViewModes.MY_ROOM) return;
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: detailViewsOptions[currentDetailView]
    });
  }, [currentDetailView]);

  const handleFullScreen = () => {
    let fcDelay = 1000;
    if (!isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (isRoomviewInFullScreen) {
      fcDelay = 0;
    }
    if (document.body.requestFullscreen) {
      setTimeout(() => {
        toggleIsRoomviewInFullScreen();
      }, fcDelay);
    } else {
      toggleIsRoomviewInFullScreen();
    }
  };

  const onThumbnailClick = (node, e) => {
    if (!node.designProps) return;
    if (state.selectedFile && node.id === state.selectedFile.id) {
      console.info("clicked in active design thumbnail");
      return;
    }
    dispatchDesignState({
      type: designDetailActions.SET_CUSTOM_SIZE,
      payload: {
        x: 0,
        y: 0
      }
    });

    let requiredVariation = {};
    if (window.flags.designVariations.preserveVariations) {
      if (state.activeVariation.id) {
        const similarDesigns = findSimilarDesigns(state.tree, node);
        if (similarDesigns) {
          const va = state.activeVariation.name.replace(state.selectedFile.name, "");
          if (va)
            requiredVariation = similarDesigns.files.find(file => {
              if (file.name.includes(va)) return true;
              return false;
            });
        }
      }
    }

    dispatchDesignList({
      type: designListActions.SELECT_DESIGN,
      payload: { selectedFile: node, activeVariation: requiredVariation }
    });

    setTimeout(() => {
      scrollIntoView(cyrFlags.scrollToDesignViewAfterCollectionOnClick);
    }, 1000);
  };
  useEffect(() => {
    if (!hasInitialScrollDiv) {
      if (isInitialLoad) {
        //do not scroll to designview if it's initial load.
        isInitialLoad = false;
        return;
      }
    }
  }, [state.activeVariation]);

  const handleLanguageOptionChange = i => {
    const newLanguage = language.langOptions[i].val;
    setCurrentLanguage(newLanguage);
    dispatchUiState({ type: mainUiActions.SET_LANGUAGE, payload: newLanguage });
    strings.setLanguage(newLanguage);
    if (langFromUrl && langFromUrl !== "") sessionStorage.setItem("lang", newLanguage);
  };

  const getCurrentLanguageText = () => {
    var index = language.langOptions.findIndex(item => item.val === currentLanguage);
    const currentLanguageText = language.langOptions[index].text;
    return currentLanguageText;
  };

  const handleOpenMyroom = () => {
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked My Room", //required
      eventLabel: "Clicked on My Room"
    });
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.MY_ROOM
    });

    scrollIntoView("#room-view-container");
    // var url = window.location + "&initview=myroom";
    // if (state.selectedFile) {
    //   url = url + "&initdesign=" + state.selectedFile.fullPath;
    // }
    // window.open(url, "_blank");
  };

  const scrollIntoView = querySelector => {
    if (querySelector) {
      const elem = document.querySelector(querySelector);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" }); //block:center for center
      } else {
        console.log(querySelector, " not found");
      }
    }
  };
  const handleNavigator = ({ querySelector, direction }) => {
    if (!querySelector || !direction) return;
    const elem = document.querySelector(querySelector);
    handleNavigation({ elem, direction });
  };
  const convertToFeetAndInches = decimalValue => {
    const feet = Math.floor(decimalValue); // Extract the feet part
    const inches = Math.round((decimalValue - feet) * 12); // Calculate the inches part
    if (inches === 0) {
      return `${feet}'`;
    } else {
      return `${feet}'${inches}"`;
    }
  };

  const changeViewIndex = (direction, currentDetailView, viewsOptions) => {
    let newcurrentDetailView =
      direction === "right" ? currentDetailView + 1 : currentDetailView - 1;
    newcurrentDetailView =
      newcurrentDetailView > viewsOptions.length - 1 ? 0 : newcurrentDetailView;
    newcurrentDetailView =
      newcurrentDetailView < 0 ? viewsOptions.length - 1 : newcurrentDetailView;
    return newcurrentDetailView;
  };

  const rugshareCondition = window.InterfaceElements.ShowRugshare && window.flags.hasRugshare;

  const openNextDetailView = ({ direction = "right" }) => {
    setDisplayIcons(false);
    var newcurrentDetailView = changeViewIndex(direction, currentDetailView, detailViewsOptions);
    const isIrregular =
      state.selectedFile?.designProps?.IsIrregular ||
      state.activeShape?.designProps?.IsIrregular ||
      state.activeVariation?.designProps?.IsIrregular;
    if (
      isIrregular &&
      detailViewsOptions[newcurrentDetailView] === visViewModes.DESIGN_VIEW_FOLDEDBACK
    )
      newcurrentDetailView = changeViewIndex(direction, newcurrentDetailView, detailViewsOptions);
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: detailViewsOptions[newcurrentDetailView]
    });
    if (newcurrentDetailView === 0) {
      //incase of zerozoom
      dispatchDesignState({
        type: designDetailActions.SET_DESIGN_VIEW_ZOOM,
        payload: window.flags.designView.minZoom
      });
      {
        window.InterfaceElements.IsJpeg &&
          dispatchDesignState({
            type: designDetailActions.SET_DESIGN_VIEW_ZOOM_JPEG,
            payload: window.flags.designView.minZoomJpeg
          });
      }
    } else {
      dispatchDesignState({
        type: designDetailActions.SET_DESIGN_VIEW_ZOOM,
        payload: resolveZoomValue()
      });
    }
    setCurrentDetailView(newcurrentDetailView);
    setTimeout(() => {
      setDisplayIcons(true);
    }, 2000);
  };

  const showNextRoom = ({ direction }) => {
    const currentRoomIndex = visualizationState.illustrations.indexOf(
      visualizationState.activeNode
    );
    var newRoomViewIndex = changeViewIndex(
      direction,
      currentRoomIndex,
      visualizationState.illustrations
    );
    const newRoom = visualizationState.illustrations[newRoomViewIndex];
    visualizationDispatch({ type: visualizationsActions.SET_ACTIVE_NODE, payload: newRoom });
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.ROOM_VIEW
    });
  };
  const handleToggleSidebar = () => {
    setShowRightColumn(!showRightColumn);
  };

  const handleCinematic = () => {
    setisCinematic(true);
  };

  useEffect(() => {
    if (Object.keys(designDetails).length === 0) return;
    setDimensions(
      getDesignDimensionsString({
        designDetails: designDetails,
        showDesignDimensions: designDetailSection.showDesignDimensions,
        showOneDimensionIfSquare: designDetailSection.showOneDimensionIfSquare,
        showDimensionInInches: designDetailSection.showDimensionInInches
      })
    );
  }, [designDetails]);

  useEffect(() => {
    if (window.innerWidth < 1320 && sessionStorage.getItem("page") == "tamariancustom") {
      setCustomTamarianUpdate(true); //to show right sidebar in the detail view section
    }
  }, []);

  return (
    <>
      <Icons />
      <QRDialog />
      <Toaster position="bottom">
        {toastProps && (
          <Toast
            message={toastProps.message}
            intent={toastProps.intent}
            onDismiss={() => setToastProps(null)}
          />
        )}
      </Toaster>
      <div
        ref={refCreateYourRug}
        id="app-main"
        className={classNames(
          "app-main multiple-mode",
          {
            "ecat-mode": getFromSessionStorage("mode") === "ecat"
          },
          getCustomClass()
        )}
      >
        <>
          {/* <div className="cyr-section-1">
        <div>
          <div className="stage-wrapper" id="room-view-container">
            <Stage
              defaultViewMode={
                visualizationState.viewMode === visViewModes.MY_ROOM
                  ? visViewModes.MY_ROOM
                  : visViewModes.ROOM_VIEW
              }
            />
          </div>
          <div className="illustration-wrapper">
            <RoomList />

            {showScrollBars && showScrollBars.illustrations && (
              <div className="cyr-arrows-container">
                <AtIcon
                  className="navigator-arrows right"
                  icon="chevron-right"
                  onClick={() =>
                    handleNavigator({ querySelector: ".at-vis-thumbs", direction: "right" })
                  }
                />
                <AtIcon
                  className="navigator-arrows"
                  icon="chevron-left"
                  onClick={() =>
                    handleNavigator({ querySelector: ".at-vis-thumbs", direction: "left" })
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="right-column">
          {getFromSessionStorage("showlogo") !== 0 && (
            <div className="at-sidebar__content__logo">
              <BrandLogo />
              <div className="logo-tagline">{strings.tagline}</div>
            </div>
          )}

          <div>
            <div className="cyr-long-buttons-area">
              <AtButton
                className="cyr-long-buttons"
                intent="primary"
                text={"Collection"}
                onClick={() => scrollIntoView("#cyr-collection")}
              ></AtButton>

              <AtButton
                className={classNames("cyr-long-buttons", { hidden: !hasVariations })}
                intent="primary"
                text={"Design Variations"}
                onClick={() => scrollIntoView("#atDesignVariations")}
              ></AtButton>
            </div>
          </div>

          <div className="cyr-color-area">
            {(!uiState.showOrderSheet || showOrdersheetOutside()) &&
              !window.InterfaceElements.IsJpeg && (
                <>
                  <ColorAreaSwatch />
                </>
              )}
            <OrdersheetArea openOrdersheetBydefault={window.InterfaceElements.IsJpeg} />
          </div>

          <SocialMediaShare />
        </div>
      </div> */}
        </>
        {cyrFlags?.showIFrame?.hasIFrame && cyrFlags?.showIFrame?.iFrameUrl !== "" && (
          <div className="cyr-iframeContainer">
            <iframe src={cyrFlags?.showIFrame?.iFrameUrl} height="100%" width="100%"></iframe>
          </div>
        )}

        <div className="cyr-roomViewContainer" ref={refRoomViewContainer}>
          {isCinematic && window.flags.hasCinematic && state.tree != null ? (
            <Suspense fallback={<></>}>
              <CinematicStager
                logoUrl={`${AppProvider.domain}${window.InterfaceElements.LogoUrl}`}
                close={() => setisCinematic(false)}
              />
            </Suspense>
          ) : (
            <>
              <div className="stage-wrapper" id="room-view-container">
                <Stage
                  defaultViewMode={
                    visualizationState.viewMode === visViewModes.MY_ROOM
                      ? visViewModes.MY_ROOM
                      : visViewModes.ROOM_VIEW
                  }
                />
                <div className="cyr-arrows-container">
                  <AtIcon
                    className="navigator-arrows right"
                    icon="chevron-right"
                    onClick={() => showNextRoom({ direction: "right" })}
                  />
                  <AtIcon
                    className="navigator-arrows"
                    icon="chevron-left"
                    onClick={() => showNextRoom({ direction: "left" })}
                  />
                </div>
              </div>

              <SidebarActionButtons
                isFullScreen={isRoomviewInFullScreen}
                handleFullScreen={handleFullScreen}
                handleCinematic={handleCinematic}
              />
            </>
          )}
        </div>
        <div className="illustration-wrapper">
          <RoomList scrollIntoView={scrollIntoView} />

          {showScrollBars && showScrollBars.illustrations && (
            <div className="cyr-arrows-container">
              <AtIcon
                className="navigator-arrows right"
                icon="chevron-right"
                onClick={() =>
                  handleNavigator({ querySelector: roomContainerClassName, direction: "right" })
                }
              />
              <AtIcon
                className="navigator-arrows"
                icon="chevron-left"
                onClick={() =>
                  handleNavigator({ querySelector: roomContainerClassName, direction: "left" })
                }
              />
            </div>
          )}
        </div>
        <div className="cyr-section cyr-detailView">
          <div className="cyr-heading">
            <div>
              <div className="title">Detail View</div>
              <div className="cyr-detailView-designname">
                {designDetailsState.customSize.x != 0 || designDetailsState.customSize.y != 0
                  ? `${state?.activeColorScheme?.name +
                      " " +
                      designDetailsState.customSize.newname}`
                  : window.flags.designDetailSection.showDesignPathInTitle
                  ? getDesignPathInTitle(fullpath)
                  : renderDesignName(designName)}
              </div>
            </div>
            {getFromSessionStorage("showlogo") !== 0 && (
              <div className="at-sidebar__content__logo">
                <BrandLogo />
                <div className="logo-tagline">{strings.tagline}</div>
              </div>
            )}
          </div>
          <div
            className={
              window.InterfaceElements.IsJpeg
                ? "design-view-main-container-jpeg"
                : "design-view-main-container"
            }
          >
            <div className="stage-wrapper deepzoom " id="design-view-container">
              <Stage defaultViewMode={detailViewsOptions[currentDetailView]} />
              {displayIcons && (
                <div className="cyr-arrows-container">
                  <AtIcon
                    className="navigator-arrows right"
                    icon="chevron-right"
                    onClick={() => openNextDetailView({ direction: "right" })}
                  />
                  <AtIcon
                    className="navigator-arrows"
                    icon="chevron-left"
                    onClick={() => openNextDetailView({ direction: "left" })}
                  />
                </div>
              )}
              <div className="cyr-overlay left"></div>
              <div className="cyr-overlay right"></div>
            </div>

            {!window.InterfaceElements.IsJpeg && (
              <div className="cyr-color-area">
                <ColorAreaSwatch />
              </div>
            )}
            <div
              className={classNames(`right-column1`, {
                show: showRightColumn
              })}
            >
              <div>
                <div className="cyr-long-buttons-area">
                  {!AppProvider.isEcatMode && (
                    <>
                      <AtButton
                        className="cyr-long-buttons collection-longButton"
                        intent="primary"
                        text={strings.createYourRug.collections}
                        onClick={() => scrollIntoView("#cyr-collection")}
                      ></AtButton>
                    </>
                  )}
                  <AtButton
                    className={classNames("cyr-long-buttons", { hidden: !hasVariations })}
                    intent="primary"
                    text={strings.createYourRug.designVariations}
                    onClick={() => scrollIntoView("#atDesignVariations")}
                  ></AtButton>
                  <div className="cyr-dimension-div">
                    <h3 style={{ maxWidth: "50%" }}>
                      {designDetailsState.customSize.x != 0 || designDetailsState.customSize.y != 0
                        ? `${state?.activeColorScheme?.name +
                            " " +
                            designDetailsState.customSize.newname}`
                        : window.flags.designDetailSection.showDesignPathInTitle
                        ? getDesignPathInTitle(fullpath)
                        : renderDesignName(designName)}
                    </h3>
                    |
                    {ordersheetFlags.saveIconActAsOrdersheetSave ? (
                      <AtIcon
                        icon="save"
                        onClick={() => {
                          document.getElementById("btnSaveOrdersheet").click();
                        }}
                        className="saveDesignOnlyIcon"
                      />
                    ) : (
                      <div style={{ cursor: "pointer" }}>
                        <Popover
                          content={
                            <Menu>
                              <MenuItem
                                text="Save Design"
                                onClick={() => window.downloadRenderedDesign()}
                              ></MenuItem>
                              <MenuItem
                                text="Save Room"
                                onClick={() => window.downloadRendered3dIll()}
                              ></MenuItem>
                            </Menu>
                          }
                        >
                          <AtIcon icon="save" />
                        </Popover>
                      </div>
                    )}
                    {rugshareCondition && (
                      <>
                        |
                        <div style={{ cursor: "pointer" }}>
                          <Popover
                            placement="bottom"
                            content={rugshareCondition && <SocialMediaShare />}
                          >
                            <AtIcon icon="share" />
                          </Popover>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {hasLangOptions && cyrFlags.showLanguageOptionsInCYR ? (
                  <div className="at-des-details__title" style={{ padding: "0 20px" }}>
                    <H4 className="at-des-details__title__heading">
                      {designDetailSection.showDesignPathInTitle
                        ? getDesignPathInTitle(fullpath)
                        : renderDesignName(designName)}
                    </H4>
                    <div className="at-des-details__title__languageDropdown">
                      <Popover
                        boundary="viewport"
                        content={
                          <Menu className="langoptions-menu">
                            {language.langOptions.map((language, i) => (
                              <MenuItem
                                className="langOptions-menuitem"
                                key={i}
                                text={language.text}
                                onClick={e => handleLanguageOptionChange(i)}
                              />
                            ))}
                          </Menu>
                        }
                        minimal
                        position="bottom-left"
                        portalContainer={document.getElementById("app-main")}
                      >
                        <div className="at-des-langOptions">
                          <span className="bp3-heading">{getCurrentLanguageText()}</span>
                          <Icon icon="caret-down" />
                        </div>
                      </Popover>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="cyr-color-area">
                <OrdersheetArea openOrdersheetBydefault={window.InterfaceElements.IsJpeg} />
                {/* <OrderSheet openOrdersheetBydefault={true} /> */}
              </div>
            </div>
          </div>
        </div>
        {!window.InterfaceElements.IsWallToWall && (
          <div className="cyr-section" id="cyr-designviews">
            <DesignViewsCYR
              detailViewsOptions={detailViewsOptions}
              currentDetailView={currentDetailView}
              setCurrentDetailView={setCurrentDetailView}
              scrollIntoView={scrollIntoView}
            />
          </div>
        )}
        {state && state.tree && !AppProvider.isEcatMode && (
          <div className="cyr-section" id="cyr-collection">
            <div className="cyr-heading">{strings.createYourRug.collections}</div>
            <Collection
              className="cyr-collection-section"
              Tree={state.tree[0]}
              defaultFolder={state.selectedFolder}
              selectedFile={state.selectedFile}
              handleThumbnailClick={onThumbnailClick}
              showNavigatorArrows={true}
            />
          </div>
        )}
        <div className="cyr-section" id="cyr-designvariations">
          <DesignVariations showScrollBars={showScrollBars} scrollIntoView={scrollIntoView} />
        </div>
        <div className="cyr-section services-section">
          <div className="cyr-heading">{strings.createYourRug.personalizedServices} </div>
          <div
            className="double-column-section cyr-large-card-wrapper"
            id={cyrFlags.showCustomizeRug ? "notSingleCYRCard" : "singleCYRCard"}
          >
            <CyrCard
              className={cyrFlags.showCustomizeRug ? "" : "myroom-card"}
              onCardClick={handleOpenMyroom}
              videoSrc={`${CDN_domain + "v3assets/CreateYourRug/MyRoomPreview.webm"}`}
              imageAlt="myroom-gif"
              mainTitle={strings.createYourRug.myRoomTitle}
              subTitle={strings.createYourRug.myRoomSubtitle}
            ></CyrCard>

            {/* <CyrCard
            onCardClick={() => scrollIntoView(".cyr-color-area")}
            imageSrc={`${CDN_domain + "v3assets/CreateYourRug/designAdvice.jpg"}`}
            imageAlt="customize-rug"
            mainTitle="Customize your rug"
            subTitle="You can simply choose a design from our collections and alter the color, material,
   shape, and size to make a rug completely of your choice"
          ></CyrCard> */}

            {cyrFlags.showCustomizeRug &&
              (cyrFlags.showInteractiveCatalog?.hasInteractiveCatalog &&
              cyrFlags.showInteractiveCatalog?.interactiveCatalogUrl !== "" ? (
                <CyrCard
                  onCardClick={() =>
                    window.open(cyrFlags.showInteractiveCatalog?.interactiveCatalogUrl, "_blank")
                  }
                  imageSrc={
                    cyrFlags.showInteractiveCatalog?.interactiveCatalogThumbUrl ||
                    `${CDN_domain + "v3assets/CreateYourRug/catalog.png"}`
                  }
                  imageAlt="catalog"
                  mainTitle={strings.createYourRug.interactiveCatalogCardTitle}
                  subTitle={strings.createYourRug.interactiveCatalogCardSubtitle}
                ></CyrCard>
              ) : (
                <CyrCard
                  onCardClick={() => {
                    setShowRightColumn(true);
                    scrollIntoView(".cyr-color-area");
                  }}
                  imageSrc={`${CDN_domain + "v3assets/CreateYourRug/designAdvice.jpg"}`}
                  imageAlt="customize-rug"
                  mainTitle={strings.createYourRug.customizeRugTitle}
                  subTitle={strings.createYourRug.customizeRugSubtitle}
                ></CyrCard>
              ))}
          </div>
        </div>
        <div className="cyr-section cyr-copyright">
          <CopyrightSection />
        </div>
      </div>
      {uiState.showLoadingOverlay && <LoadingOverlay />}
    </>
  );
};

CreateYourRug.propTypes = {};

export default CreateYourRug;

const CyrCard = props => {
  const {
    onCardClick,
    className = "",
    videoSrc = "",
    imageSrc,
    imageAlt = "card-image",
    mainTitle = "",
    subTitle = ""
  } = props;
  return (
    <div
      className={classNames(`double-row-section full-height ${className}`, { className })}
      onClick={onCardClick}
    >
      <div className="cyr-image-wrapper">
        {videoSrc !== "" ? (
          <video src={videoSrc} autoPlay loop muted />
        ) : (
          <img alt={imageAlt} src={imageSrc} />
        )}
      </div>
      <div className="cyr-card-body">
        <div className="cyr-card-body-title">{mainTitle}</div>
        <p className="cyr-card-body-text">{subTitle}</p>
      </div>
    </div>
  );
};
