/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "./../../reducers/designlist.reducer";
import { useVisualizationState } from "./../../reducers/visualizations.reducer";
import TileCanvas from "./../../tilecanvasnew";
import RoomViewHelper from "./../organisms/RoomViewNew/roomviewhelper";
import AppProvider from "./../../api/appProvider";
import { ButtonGroup } from "@blueprintjs/core";
// import IndexedDbApi from "../../api/indexedDb";
import { MD5 } from "./../../utils/md5";
import { readJSON, shuffle } from "./../../utils/utils";
import { createCanvas, dataURLtoBlobURL } from "./../../utils/canvasutils";
import EngineFeeder from "./components/EngineFeeder";
import DataControlSettings from "./components/DataControlSettings";
import BottomController from "./components/BottomController";
import IntroLoader from "./components/IntroLoader";
// import { AtSpinnerOverlay } from "./../atoms/AtSpinner";
import AtButton from "./../atoms/AtButton";
import { useIdle, useFullscreen, useToggle } from "react-use";
import classNames from "classnames";

import {
  DataControlSettingsProvider,
  useDataControlSettingsState
} from "./components/DataControlSettings/datacontrolsettings.reducer";
import { hexToHSL } from "../../utils/colorutils";

const tilecanvas = new TileCanvas();
let hasStartedLoading = false;
const CinematicStager = props => {
  return (
    <React.Fragment>
      <DataControlSettingsProvider>
        <CinematicStagerInner {...props} />
      </DataControlSettingsProvider>
    </React.Fragment>
  );
};
const getDesignRepreColor = designColors => {
  if (!designColors || !designColors.length) {
    return null;
  }
  const colorarr = designColors.sort((a, b) => (a.Knots > b.Knots ? -1 : 1));
  const i = colorarr.length > 1 ? 1 : 0;
  return hexToHSL(colorarr[i].Color)[0] + 1;
};
const CinematicStagerInner = props => {
  const { logoUrl, close } = props;
  const [array, setarray] = useState([]);
  const designList = useDesignListState();
  const dispatchdesignList = useDispatchDesignList();
  const roomList = useVisualizationState();
  const dataControlState = useDataControlSettingsState();
  const [filteredDesignList, setfilteredDesignList] = useState();
  const [designIndexG, setdesignIndexG] = useState(-1);
  const [pause, setpause] = useState(true);
  const [showDataControls, setshowDataControls] = useState(false);
  const [startShow, setstartShow] = useState(false);
  const [duration, setduration] = useState(10);
  const [logoVal, setlogoVal] = useState(true);
  const [firstRenderingImage, setFirstRenderingImage] = useState("");
  // const [assets, setAssets] = useState([]);
  const width = window.screen.width;
  const height = window.screen.height;

  const isIdle = useIdle(5e3);

  const cineamticContainerRef = useRef(null);

  const [fullscreenstatus, togglefullscreen] = useToggle(false);
  const isFullScreen = useFullscreen(cineamticContainerRef, fullscreenstatus, {
    onClose: () => togglefullscreen(false)
  });

  const makeVisualization = async (designDetailState, fullDesign = false) => {
    let zoom = 2;
    if (window.InterfaceElements.IsJpeg) {
      zoom = 1;
    }
    let dW = zoom * designDetailState.designDetails.Width;
    let dH =
      zoom * designDetailState.designDetails.Height * designDetailState.designDetails.KLRatio;
    let cW = window.devicePixelRatio * width;
    let cH = window.devicePixelRatio * height;
    let boundsMin, boundsMax;
    if (dW >= cW && dH >= cH) {
      boundsMin = { x: (dW - cW) / 2, y: (dH - cH) / 2 };
      boundsMax = { x: dW - (dW - cW) / 2, y: dH - (dH - cH) / 2 };
    } else if (dW < cW && dH < cH) {
      boundsMin = { x: 0, y: 0 };
      boundsMax = { x: dW, y: dH };
    } else if (dW >= cW && dH < cH) {
      boundsMin = { x: (dW - cW) / 2, y: 0 };
      boundsMax = { x: dW - (dW - cW) / 2, y: dH };
    } else if (dW < cW && dH >= cH) {
      boundsMin = { x: 0, y: (dH - cH) / 2 };
      boundsMax = { x: dW, y: dH - (dH - cH) / 2 };
    }
    let tileSize = 256;
    let x = Math.floor(boundsMin.x / tileSize);
    let y = Math.floor(boundsMin.y / tileSize);
    let endX = Math.floor(boundsMax.x / tileSize);
    let endY = Math.floor(boundsMax.y / tileSize);
    const canvasSize = !fullDesign
      ? {
          width: boundsMax.x - boundsMin.x,
          height: boundsMax.y - boundsMin.y
        }
      : {
          width: designDetailState.designDetails.Width * zoom,
          height: designDetailState.designDetails.Height * zoom
        };

    tilecanvas.init({
      tileSize: tileSize,
      designDetails: designDetailState.designDetails,
      zoom: zoom,
      offset: [0.5, 0.5],
      canvasSize
    });
    const tiles = fullDesign ? { x: 0, y: 0 } : { x: x, y: y, endX: endX, endY: endY };
    return new Promise(resolve => {
      tilecanvas.drawVisTiles(
        {
          designPath: designDetailState.designPath,
          designDetails: { ...designDetailState.designDetails },
          hash: designDetailState.hash,
          ...tiles,
          forceOriginalDesigns: true
        },
        () => {
          resolve(getCanvasData({ canvas: tilecanvas.canvasVis }));
        }
      );
    });
  };

  const getCanvasData = ({ canvas, blob = false }) => {
    return new Promise(resolve => {
      if (!blob) {
        resolve(canvas.toDataURL());
      }
      canvas.toBlob(function(blob) {
        const url = URL.createObjectURL(blob);
        //todo need to revoke after using
        resolve(url);
      });
    });
  };

  // function dataURLtoBlobURL(dataurl) {
  //   return new Promise(resolve => {
  //     var arr = dataurl.split(","),
  //       mime = arr[0].match(/:(.*?);/)[1],
  //       bstr = atob(arr[1]),
  //       n = bstr.length,
  //       u8arr = new Uint8Array(n);
  //     while (n--) {
  //       u8arr[n] = bstr.charCodeAt(n);
  //     }
  //     let blob = new Blob([u8arr], { type: mime });
  //     const url = URL.createObjectURL(blob);
  //     resolve(url);
  //   });
  // }

  const makeIllustrationView = async (illustration, designDetailState) => {
    const buildRoomView = async roomData => {
      const { Files: files, config, baseUrl } = roomData;
      const { width, height } = config.dims;
      const bgCanvas = createCanvas(width, height);
      const threeCanvas = createCanvas(width, height);
      const maskCanvas = createCanvas(width, height);
      const shadowCanvas = createCanvas(width, height);
      const container = { clientWidth: width, clientHeight: height };
      const inputCanvas = createCanvas(width, height);
      const canvasConfig = {
        bgCanvas,
        threeCanvas,
        maskCanvas,
        shadowCanvas,
        container,
        inputCanvas
      };
      const rh = new RoomViewHelper();
      rh.initCanvas(canvasConfig);
      await Promise.all(rh.initConfig({ baseUrl, config, files }));
      rh.updateBackground();

      rh.updateMask();
      await rh.updatethreeCanvas();
      rh.setTileDetails(designDetailState.tileData);
      await rh.renderDesign({
        designDetails: designDetailState.designDetails,
        designPath: designDetailState.designPath,
        hash: designDetailState.hash,
        designDimsOrig: designDetailState.designDimsOrig
      });
      const objectConfig = rh.threeView.getObjectConfig();
      if (objectConfig) {
        rh.threeView.carpetMesh.position.copy(objectConfig.position);
        rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
        rh.threeView.render();
      }
      await rh.updateShadow();
      return Promise.resolve(getCanvasData({ canvas: rh.renderinCanvas() }));
    };
    return new Promise(resolve => {
      AppProvider.fetchRoomDetails({
        file: illustration.FullPath
      }).then(roomData => {
        if (!roomData && !roomData.dir) return;
        const s = roomData.Dir.split("/");
        let baseUrl;
        if (s[1] === "Assets") {
          const ss = s.slice(2);
          baseUrl = `${AppProvider.assetsDomain}/${ss.join("/")}`;
        } else {
          baseUrl = `${AppProvider.domain}${roomData.Dir}`;
        }
        readJSON(`${baseUrl}/config.json`).then(config => {
          // buildIllustrationView({ ...roomData, config, baseUrl }).then(src => {
          //   return src;
          // });
          buildRoomView({ ...roomData, config, baseUrl }).then(src => {
            resolve(src);
          });
        });
      });
    });

    // const buildIllustrationView = async roomData => {
    //   const { Name: roomName, Files: files, config, baseUrl } = roomData;
    //   const { dims, roomType = defaultRoomType } = config;
    //   const { width, height } = dims;
    //   const bgCanvas = createCanvas(width, height);
    //   const threeCanvas = createCanvas(width, height);
    //   const maskCanvas = createCanvas(width, height);
    //   const shadowCanvas = createCanvas(width, height);
    //   const container = { clientWidth: width, clientHeight: height };
    //   const inputCanvas = createCanvas(width, height);
    //   const transitionCanvas = createCanvas(width, height);
    //   const objCanvasContainer = document.createElement("div");
    //   config.roomelements.objects &&
    //     Object.keys(config.roomelements.objects).map((object, index) => {
    //       const objCanvas = createCanvas(width, height);
    //       objCanvasContainer.appendChild(objCanvas);
    //     });
    //   const canvasConfig = {
    //     bgCanvas,
    //     threeCanvas,
    //     maskCanvas,
    //     shadowCanvas,
    //     container,
    //     inputCanvas,
    //     transitionCanvas,
    //     objCanvasContainer
    //   };
    //   const rh = new RoomViewHelper();
    //   rh.initCanvas(canvasConfig);
    //   rh.initConfig({ baseUrl, config, files });
    //   const isinitialized = rh.preinitShot({
    //     shot: 0
    //   });
    //   if (!isinitialized) {
    //     await Promise.all(rh.initShot());
    //   }
    //   rh.updateBackground();

    //   rh.updateMask();
    //   await rh.updatethreeCanvas(0);
    //   rh.setTileDetails(designDetailState.tileDetails);
    //   await rh.renderDesign({
    //     designDetails: designDetailState.designDetails,
    //     designPath: designDetailState.fullpath,
    //     hash: designDetailState.hash
    //   });
    //   if (roomType === defaultRoomType) {
    //     const objectConfig = rh.threeView.getObjectConfig();
    //     if (objectConfig) {
    //       rh.threeView.carpetMesh.position.copy(objectConfig.position);
    //       rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
    //       rh.threeView.render();
    //     }
    //   }

    //   await rh.updateShadow();
    //   config.roomelements.objects && rh.updateObjects({ ...config.roomelements.objects });
    //   await rh.makeTransitionCanvas({ objects: { ...config.roomelements.objects } });
    //   return Promise.resolve(transitionCanvas.toDataURL());
    // };
  };
  // const buildAssets = async () => {
  //   let arr = [];
  //   let designs = filteredDesignList;
  //   const desWithoutThumbs = filteredDesignList.filter(item => !item.designProps || item.thumbUrl);
  //   if (desWithoutThumbs.length)
  //     designs = await AppProvider.getDesignThumbnails({ designs: filteredDesignList });
  //   for (let designIndex = 0; designIndex < designs.length; designIndex++) {
  //     const nodeDesign = designs[designIndex];
  //     let hash = generateHash(nodeDesign.designProps, nodeDesign.fullPath);

  //     const designDetailState = {
  //       designPath: nodeDesign.fullPath,
  //       designDetails: { ...nodeDesign.designProps },
  //       hash: hash
  //     };
  //     const colorarr = [...designDetailState.designDetails.DesignColors].sort((a, b) =>
  //       a.Knots > b.Knots ? -1 : 1
  //     );
  //     const color = getHue(colorarr[1].Color) + 1;
  //     let addData = {
  //       id: designDetailState.designPath,
  //       name: nodeDesign.name,
  //       hash: designDetailState.hash,
  //       color: color
  //     };

  //     if (dataControlState.shownRenderings.photorealisticDesigns) {
  //       addData.visualization = {};
  //       let vis = await makeVisualization(designDetailState);
  //       let visIntanceUrl = await AppProvider.getInstanceUrl({
  //         file: designDetailState.designPath,
  //         props: designDetailState.designDetails
  //       });
  //       visIntanceUrl.dataurl = `data:image/png;base64,${visIntanceUrl.dataurl}`;
  //       addData.visualization = {
  //         src: vis,
  //         instanceUrl: visIntanceUrl.url,
  //         qrSrc: visIntanceUrl.dataurl
  //       };
  //       let visurl = await dataURLtoBlobURL(vis);
  //       let visqrurl = await dataURLtoBlobURL(visIntanceUrl.dataurl);
  //       const isLast = designIndex + 1 === designs.length;
  //       arr.push({
  //         src: visurl,
  //         name: nodeDesign.name,
  //         index: designIndex,
  //         instanceUrl: visIntanceUrl.url,
  //         qrSrc: visqrurl,
  //         color: color,
  //         class: isLast ? "exit" : "entry"
  //       });
  //       if (isLast) {
  //         setAssets(arr);
  //         setarray(arr);
  //       }
  //       // loaded++;
  //     }
  //   }
  //   // designs.forEach(async (nodeDesign, designIndex) => {});
  // };
  const buildDesignAssets = async designIndex => {
    let arr = [];
    let nodeDesign = filteredDesignList[designIndex];
    // !nodeDesign.designProps &&(  ------>this line changes the color list of first design in cinematic view.
    await AppProvider.getDesignThumbnails({ designs: [nodeDesign] }).then(items => {
      nodeDesign = items[0];
      dispatchdesignList({
        type: designListActions.UPDATE_NODE,
        payload: { ...items[0] }
      });
    });
    // );
    if (!nodeDesign.designProps.DesignColors) {
      nodeDesign.designProps.DesignColors = [];
    }
    let hash = generateHash(nodeDesign.designProps, nodeDesign.fullPath);

    // let dataindb = await IndexedDbApi.getDatafromDatabase({ dataid: nodeDesign.fullPath });
    let dataindb;
    if (dataindb && hash === dataindb.hash) {
      let tileData;
      let designDetailState;
      let color = dataindb.color;
      let dbupdate = false;
      let addData = { ...dataindb };
      if (dataControlState.shownRenderings.photorealisticDesigns) {
        if (dataindb.visualization) {
          let visurl = await dataURLtoBlobURL(dataindb.visualization.src);
          let visqrurl = await dataURLtoBlobURL(dataindb.visualization.qrSrc);
          arr.push({
            src: visurl,
            name: nodeDesign.name,
            index: designIndex,
            instanceUrl: dataindb.visualization.instanceUrl,
            qrSrc: visqrurl,
            color: color
          });
        } else {
          tileData = await AppProvider.fetchTileDetails({
            file: nodeDesign.fullPath
          });
          designDetailState = {
            tileData: tileData,
            designPath: nodeDesign.fullPath,
            designDetails: { ...nodeDesign.designProps },
            hash: hash,
            designDimsOrig: {
              Width: nodeDesign.designProps.Width,
              Height: nodeDesign.designProps.Height,
              PhysicalWidth: nodeDesign.designProps.PhysicalWidth,
              PhysicalHeight: nodeDesign.designProps.PhysicalHeight
            }
          };
          if (!color) {
            color = getDesignRepreColor(designDetailState.designDetails.DesignColors);
          }
          let vis = await makeVisualization(designDetailState);
          let visIntanceUrl = await AppProvider.getInstanceUrl({
            file: designDetailState.designPath,
            props: designDetailState.designDetails
          });
          visIntanceUrl.dataurl = `data:image/png;base64,${visIntanceUrl.dataurl}`;
          addData.visualization = {
            src: vis,
            instanceUrl: visIntanceUrl.url,
            qrSrc: visIntanceUrl.dataurl
          };
          let visurl = await dataURLtoBlobURL(vis);
          let visqrurl = await dataURLtoBlobURL(visIntanceUrl.dataurl);
          arr.push({
            src: visurl,
            name: nodeDesign.name,
            index: designIndex,
            instanceUrl: visIntanceUrl.url,
            qrSrc: visqrurl,
            color: color
          });
        }
      }
      if (dataControlState.shownRenderings.roomViews) {
        let illustrations = getRandom(3, roomList.illustrations);
        await asyncForEach(illustrations, async element => {
          if (dataindb.illustrations && dataindb.illustrations[element.FullPath]) {
            let rm = dataindb.illustrations[element.FullPath];
            let rmurl = await dataURLtoBlobURL(rm.src);
            let rmqrurl = await dataURLtoBlobURL(rm.qrSrc);
            arr.push({
              src: rmurl,
              name: nodeDesign.name,
              index: designIndex,
              instanceUrl: rm.instanceUrl,
              qrSrc: rmqrurl,
              color: color
            });
          } else {
            if (!tileData) {
              tileData = await AppProvider.fetchTileDetails({
                file: nodeDesign.fullPath
              });
              designDetailState = {
                tileData: tileData,
                designPath: nodeDesign.fullPath,
                designDetails: { ...nodeDesign.designProps },
                hash: hash,
                designDimsOrig: {
                  Width: nodeDesign.designProps.Width,
                  Height: nodeDesign.designProps.Height,
                  PhysicalWidth: nodeDesign.designProps.PhysicalWidth,
                  PhysicalHeight: nodeDesign.designProps.PhysicalHeight
                }
              };
              if (!color) {
                color = getDesignRepreColor(designDetailState.designDetails.DesignColors);
              }
            }
            let rm = await makeIllustrationView(element, designDetailState);
            let rmInstanceUrl = await AppProvider.getInstanceUrl({
              file: designDetailState.designPath,
              props: designDetailState.designDetails,
              view: element.FullPath
            });
            rmInstanceUrl.dataurl = `data:image/png;base64,${rmInstanceUrl.dataurl}`;
            let rmurl = await dataURLtoBlobURL(rm);
            let rmqrurl = await dataURLtoBlobURL(rmInstanceUrl.dataurl);

            arr.push({
              src: rmurl,
              name: nodeDesign.name,
              index: designIndex,
              instanceUrl: rmInstanceUrl.url,
              qrSrc: rmqrurl,
              color: color
            });
            if (!addData.illustrations) addData.illustrations = {};
            addData.illustrations[element.FullPath] = {
              label: element.label,
              src: rm,
              instanceUrl: rmInstanceUrl.url,
              qrSrc: rmInstanceUrl.dataurl
            };
            dbupdate = true;
          }
        });
      }

      if (dbupdate) {
        // IndexedDbApi.updateDataInDatabase({
        //   data: {
        //     ...addData
        //   }
        // });
      }
      if (arr.length > 1) {
        arr[0].class = "entry";
        arr[arr.length - 1].class = "exit";
      } else {
        arr[0].class = "entry";
        arr[0].isDesignOnly = true;
      }
      setarray([...arr]);
    } else {
      const designDetailState = {
        // tileData: tileData,
        designPath: nodeDesign.fullPath,
        designDetails: { ...nodeDesign.designProps },
        hash: hash,
        designDimsOrig: {
          Width: nodeDesign.designProps.Width,
          Height: nodeDesign.designProps.Height,
          PhysicalWidth: nodeDesign.designProps.PhysicalWidth,
          PhysicalHeight: nodeDesign.designProps.PhysicalHeight
        }
      };
      let color = getDesignRepreColor(designDetailState.designDetails.DesignColors);
      let addData = {
        id: designDetailState.designPath,
        name: nodeDesign.name,
        hash: designDetailState.hash,
        color: color
      };
      if (dataControlState.shownRenderings.photorealisticDesigns) {
        addData.visualization = {};
        let fullDesign = Math.random() < 0.5 ? true : false;
        let vis = await makeVisualization(designDetailState, fullDesign);
        let visIntanceUrl = await AppProvider.getInstanceUrl({
          file: designDetailState.designPath
        });
        visIntanceUrl.dataurl = `data:image/png;base64,${visIntanceUrl.dataurl}`;
        addData.visualization = {
          src: vis,
          instanceUrl: visIntanceUrl.url,
          qrSrc: visIntanceUrl.dataurl,
          fullDesign: fullDesign
        };
        let visurl = await dataURLtoBlobURL(vis);
        let visqrurl = await dataURLtoBlobURL(visIntanceUrl.dataurl);
        arr.push({
          src: visurl,
          name: nodeDesign.name,
          index: designIndex,
          instanceUrl: visIntanceUrl.url,
          qrSrc: visqrurl,
          color: color,
          fullDesign: fullDesign
        });
      }
      if (dataControlState.shownRenderings.roomViews) {
        addData.illustrations = {};
        let illustrations = getRandom(3, roomList.illustrations);
        await asyncForEach(illustrations, async element => {
          let rm = await makeIllustrationView(element, designDetailState);
          let rmInstanceUrl = await AppProvider.getInstanceUrl({
            file: designDetailState.designPath,
            props: designDetailState.designDetails,
            view: element.FullPath
          });

          rmInstanceUrl.dataurl = `data:image/png;base64,${rmInstanceUrl.dataurl}`;
          let rmurl = await dataURLtoBlobURL(rm);
          let rmqrurl = await dataURLtoBlobURL(rmInstanceUrl.dataurl);
          arr.push({
            src: rmurl,
            name: nodeDesign.name,
            index: designIndex,
            instanceUrl: rmInstanceUrl.url,
            qrSrc: rmqrurl,
            color: color
          });
          addData.illustrations[element.FullPath] = {
            label: element.label,
            src: rm,
            instanceUrl: rmInstanceUrl.url,
            qrSrc: rmInstanceUrl.dataurl
          };
        });
      }
      // if (dataindb) {
      //   IndexedDbApi.updateDataInDatabase({
      //     data: {
      //       ...addData
      //     }
      //   });
      // } else {
      //   IndexedDbApi.addDatatoDatabase({
      //     data: {
      //       ...addData
      //     }
      //   });
      // }
      if (arr.length > 1) {
        arr[0].class = "entry";
        arr[arr.length - 1].class = "exit";
      } else {
        arr[0].class = "entry";
        arr[0].isDesignOnly = true;
      }
      setarray([...arr]);
    }
  };
  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };
  const getRandom = (n, arr) => {
    var len = arr.length,
      taken = new Array(len);
    if (n > len) n = len;
    var result = new Array(n);
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };

  const generateHash = (details, fullPath = "") => {
    const des = JSON.stringify(details);
    return MD5(des + fullPath);
  };
  const loadNext = () => {
    if (designIndexG > filteredDesignList.length - 1) {
      return;
    }
    // setarray([assets[designIndexG]]);
    buildDesignAssets(designIndexG);
    if (designIndexG < filteredDesignList.length - 1) setdesignIndexG(designIndexG + 1);
    else setdesignIndexG(0); //for relooping
  };

  useEffect(() => {
    if (roomList && filteredDesignList && filteredDesignList.length > 0) {
      const build = async () => {
        // await buildAssets();
        buildDesignAssets(0);
        if (filteredDesignList.length > 1) setdesignIndexG(1);
        else setdesignIndexG(0);
      };
      build();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDesignList, roomList]);

  useEffect(() => {
    filterSelectedDesigns(true);
    return () => {
      hasStartedLoading = false;
      setpause(true);
      setstartShow(false);
    };
  }, []);

  // This section is used to display rendered image in the loading section instead of black window.
  // useEffect(() => {
  //   const GetFirstRenderingImage = async () => {
  //     let get1xView = await AppProvider.get1xDesign({
  //       file: designList.selectedFile.fullPath
  //     });
  //     setFirstRenderingImage(get1xView);
  //   };
  //   if (designList) {
  //     GetFirstRenderingImage();
  //   }
  // }, []);

  const createfileArrFromTree = (treenode, excludeArr) => {
    let filesList = [];
    const traverseDFS = node => {
      if (excludeArr && typeof excludeArr !== "object") {
        let entriesArr = Object.entries(excludeArr);
        if (entriesArr[0].length > 0 && entriesArr[0].indexOf(node.fullPath) > -1) return;
      }
      if (node.files && node.files.length > 0) {
        filesList = [...filesList, ...node.files];
      }
      node.children.forEach((childNode, index) => {
        traverseDFS(childNode);
      });
    };
    traverseDFS(treenode);
    return filesList;
  };
  const filterSelectedDesigns = async changed => {
    if (!changed) {
      setshowDataControls(false);
      if (startShow) {
        setpause(false);
      } else {
        close();
      }
      return;
    }
    hasStartedLoading = true;
    setarray([]);
    setdesignIndexG(-1);
    setstartShow(false);
    setshowDataControls(false);
    setpause(true);
    // await IndexedDbApi.init();
    // let prevUser = await IndexedDbApi.getDatafromDatabase({ dataid: "currentUserId" });
    // let prevUser;
    // const prevUserId = prevUser ? prevUser.val : null;
    // if (!prevUserId || window.InterfaceElements.CurrentUserID !== prevUserId) {
    //   await IndexedDbApi.resetObjectStore();
    //   IndexedDbApi.addDatatoDatabase({
    //     data: {
    //       id: "currentUserId",
    //       val: window.InterfaceElements.CurrentUserID,
    //       type: "otherdata"
    //     }
    //   });
    // }
    let validityArr = [];
    let prevDesignListHash;
    // let prevDesignListHash = await IndexedDbApi.getDatafromDatabase({ dataid: "designListHash" });
    let designListArr = createfileArrFromTree(designList.tree[0]).map(
      eachdesign => eachdesign.fullPath
    );
    let designListHash = MD5(
      designListArr.reduce((acc, eachdesignpath) => {
        acc += eachdesignpath;
        return acc;
      }, "")
    );
    if (!prevDesignListHash || prevDesignListHash !== designListHash) {
      validityArr.push({ type: "design", key: "id", valueArr: [...designListArr] });
      // IndexedDbApi.addDatatoDatabase({
      //   data: {
      //     id: "designListHash",
      //     val: designListHash,
      //     type: "otherdata"
      //   }
      // });
    }
    let prevRoomListHash = null;
    // let prevRoomListHash = await IndexedDbApi.getDatafromDatabase({ dataid: "roomListHash" });
    let roomListArr = roomList.illustrations.map(eachroom => eachroom.FullPath);
    let roomListHash = MD5(
      roomListArr.reduce((acc, eachroompath) => {
        acc += eachroompath;
        return acc;
      }, "")
    );
    if (!prevRoomListHash || prevRoomListHash !== roomListHash) {
      validityArr.push({ type: "illustration", key: "illustrations", valueArr: [...roomListArr] });
      // IndexedDbApi.addDatatoDatabase({
      //   data: {
      //     id: "roomListHash",
      //     val: roomListHash,
      //     type: "otherdata"
      //   }
      // });
    }
    // IndexedDbApi.cleanDb({ validityData: validityArr.length > 0 ? [...validityArr] : [] });
    let newfiltereddesigns;
    if (dataControlState.selectedDesigns.thisDesign) {
      newfiltereddesigns = [designList.activeNode];
    } else if (dataControlState.selectedDesigns.thisFolder) {
      newfiltereddesigns = createfileArrFromTree(designList.selectedFolder);
    } else if (dataControlState.selectedDesigns.allDesigns) {
      newfiltereddesigns = createfileArrFromTree(designList.tree[0]);
    } else if (dataControlState.selectedDesigns.advancedSelection) {
      newfiltereddesigns = createfileArrFromTree(
        designList.tree[0],
        dataControlState.advancedSelectionTree.excludedFolders
      );
    }
    if (dataControlState.shuffle) {
      setfilteredDesignList(shuffle(newfiltereddesigns));
    } else {
      setfilteredDesignList(newfiltereddesigns);
    }
  };

  const handleShowStart = val => {
    setstartShow(val);
  };

  const cinematicMainClass = classNames("cinematic-main", { isIdle: isIdle });
  const topButtonGroupClass = classNames("topPanel hideOnIdle", { show: true });

  return (
    <React.Fragment>
      <div
        id={"cinematic-main"}
        className={cinematicMainClass}
        ref={cineamticContainerRef}
        style={hasStartedLoading || startShow ? { display: "block" } : { display: "none" }}
      >
        <ButtonGroup className={topButtonGroupClass}>
          <AtButton
            minimal
            title={`${isFullScreen ? "Exit" : "Enter"} Fullscreen`}
            intent={null}
            icon={`${isFullScreen ? "exit" : "enter"}-fullscreen`}
            onClick={() => togglefullscreen()}
          />
          <AtButton minimal title={`Close`} intent={null} icon={`close`} onClick={close}></AtButton>
        </ButtonGroup>
        {hasStartedLoading && (
          <IntroLoader
            logoUrl={logoUrl}
            logoVal={logoVal}
            exit={startShow}
            // firstBlurImg={array[0] ? array[0].src : ""} //for multiple design choice on first loading
            firstBlurImg={firstRenderingImage}
          />
        )}
        {designIndexG > -1 && (
          <EngineFeeder
            images={array}
            loadNext={loadNext}
            pause={pause}
            handleShowStart={handleShowStart}
            duration={duration}
          />
        )}
        <DataControlSettings
          onDone={filterSelectedDesigns}
          show={showDataControls}
          enableChangeRenderings={window.flags.showCinematicRoomRenderings}
        />
        <BottomController
          handlePlay={val => {
            setpause(!val);
          }}
          handleDuration={val => {
            setduration(val);
          }}
          handleLogo={val => {
            setlogoVal(val);
          }}
          handleSettings={() => {
            setpause(true);
            setshowDataControls(true);
          }}
          pause={pause}
          startPlay={startShow}
        />
        {/* <AtSpinnerOverlay show={!showDataControls && !startShow} /> */}
      </div>
    </React.Fragment>
  );
};

export default CinematicStager;
