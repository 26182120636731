import React from "react";
import classNames from "classnames";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import Stage from "../../organisms/StageNew";
import RightSidebarTabbed from "../../templates/RightSidebar/RightSidebarTabed";
import { getFromSessionStorage } from "../../../utils/domUtils";
import LeftSidebarTabed from "../../templates/LeftSidebar/LeftSidebarTabed";

const AppContainerPaneled = props => {
  const { handleDownloadImage, handleArp, handleCinematic, isFullScreen, handleFullScreen, customClass='' } = props;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  return (
    <div
      className={classNames(
        "app-container",
        {
          transparent: uiState.showLoadingOverlay,
          busyState: uiState.isBusy
        },
        {
          "admin-mode": window.InterfaceElements.IsAdmin
        },
        {
          "multiple-mode": !window.InterfaceElements.IsAdmin
        },
        {
          "ecat-mode": getFromSessionStorage("mode") === "ecat"
        },
        { isFelt: window.flags.isFelt },
        customClass
      )}
      onClick={() => {
        if (uiState.showCustoScout)
          dispatchUiState({ type: mainUiActions.SET_SHOW_CUST_SCOUT, payload: false });
      }}
    >
      <Stage />
      <LeftSidebarTabed />
      <RightSidebarTabbed
        handleDownloadImage={handleDownloadImage}
        handleArp={handleArp}
        handleCinematic={handleCinematic}
        isFullScreen={isFullScreen}
        handleFullScreen={handleFullScreen}
      />
    </div>
  );
};

export default AppContainerPaneled;
