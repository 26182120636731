import React from 'react'

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_CONTROL_MODE = "SET_CONTROL_MODE"
const SHOW_WALKTHROUGH = "SHOW_WALKTHROUGH"
const SAVE_ROOM = "SAVE_ROOM"
const controlModes = {
  TRANSFORM: "TRANSFORM",
  ADJUST_PLANE: "ADJUST_PLANE",
  MARK: "MARK"
}
const myRoomActions = {
  SET_CONTROL_MODE,
  SHOW_WALKTHROUGH,
  SAVE_ROOM
}
const initialMyroomState = {
  controlMode: controlModes.TRANSFORM,
  showWalkthrough: false,
  savedRooms: []
}
const myroomReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_CONTROL_MODE:
      return { ...state, controlMode: payload }
    case SHOW_WALKTHROUGH:
      return { ...state, showWalkthrough: payload }
    case SAVE_ROOM:
      return { ...state, savedRooms: [...state.savedRooms, payload] }
    default:
      return state;
  }
}
function MyroomStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(myroomReducer, initialMyroomState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}
function useMyroomState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}
function useMyroomDispatch() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}
export { MyroomStateProvider, useMyroomState, useMyroomDispatch, myRoomActions, controlModes }
