import React, { useEffect, useState, useRef } from "react";
import { isMobileDevice } from "../../../utils/utils";
import classNames from "classnames";
import { getPomUrl } from "../../../utils/colorutils";

const isInViewPort = elem => {
  const bounding = elem.getBoundingClientRect();
  const parent = elem.parentElement;
  const { left, top, bottom, right } = parent.getBoundingClientRect();
  return (
    bounding.top + bounding.height >= top &&
    bounding.left + bounding.width >= left &&
    bounding.bottom - bounding.height <= bottom &&
    bounding.right - bounding.width <= right
  );
};
const SwatchBox = props => {
  const {
    swatchSize,
    colorRow,
    onColorSwatchClick,
    active,
    handleHover,
    swatchSpace,
    showColorPoms = false,
    colorPomsShape
  } = props;
  const disabledSwatch = colorRow.ColorName.toLowerCase() === "_blank";
  const { showColorName } = window.flags.colorSelectionBox;
  const [hover, setHover] = useState(null);
  const [showColorSwatchBg, setShowColorSwatchBg] = useState(true);
  //const colorPomImgRef = useRef(null);

  // useEffect(() => {
  //   loadPomUrl(colorRow.Color, 0).then(colorPomImageUrl => {
  //     if (colorPomImageUrl && colorPomImgRef.current) colorPomImgRef.current.src = colorPomImageUrl;
  //   });
  // }, [colorRow]);
  // const [scrollTo, setScrollTo] = useState(true);
  const ref = useRef(null);
  useEffect(() => {
    // const props = { block: "center", inline: "center" };
    // const props = {};
    const isInVP = isInViewPort(ref.current);
    if (active && !isInVP) {
      // ref.current.scrollIntoView(props)
      const areaSelectBox = document.getElementsByClassName("colorSwatchBox")[0];
      const { offsetLeft } = ref.current;
      areaSelectBox.scrollLeft = offsetLeft - areaSelectBox.offsetWidth / 2;
    }
  }, [ref, active]);
  useEffect(() => {
    if (!ref.current || !handleHover) return;
    if (hover === true) handleHover(ref.current);
    if (hover === false) handleHover(null);
  }, [hover]); // eslint-disable-line

  return (
    <div
      onMouseEnter={() => !disabledSwatch && setHover(true)}
      onMouseOver={() => !disabledSwatch && setHover(true)}
      onMouseLeave={() => !disabledSwatch && setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
      ref={ref}
      style={{
        margin: `${swatchSpace}rem`,
        width: `${swatchSize}rem`,
        height: `${swatchSize}rem`,
        backgroundColor: `${!disabledSwatch ? colorRow.Color : "transparent"}`
      }}
      onClick={e => {
        // setScrollTo(false);
        if (onColorSwatchClick) onColorSwatchClick(e);
      }}
      className={classNames(
        "color-swatch",
        { "no-background": !showColorSwatchBg },
        {
          "hide-color-pom": showColorSwatchBg
        },
        { "color-swatch--disabled": disabledSwatch, active }
      )}
    >
      {showColorPoms && (
        // <img className={classNames(`color-pom-image`)} ref={colorPomImgRef} alt="color pom" />
        <img
          className={classNames(`color-pom-image`)}
          alt="color pom"
          src={getPomUrl(colorRow.Color, colorRow.Texture, colorPomsShape)}
          onError={({ currentTarget }) => {
            currentTarget.style.display = "none";
            setShowColorSwatchBg(true);
          }}
          onLoad={() => setShowColorSwatchBg(false)}
        />
      )}
      {showColorName && <span className="at-swatchbox__text">{colorRow.ColorName}</span>}
    </div>
  );
};

export default SwatchBox;
