export const autocolorOptions = [
  {
    title: "Any Color", //string to show if string is not found in english.json or any language file
    value: "AnyColor", //value to search in strings (english.json) file
    id: "",
    background:
      "linear-gradient(90deg, #AD0808 0%, #DF9300 16.9%, #00F043 31.78%, #20FCFF 48.08%, #1E42FF 66.02%, #E800DE 82.83%, #DC0000 100%)"
  },
  {
    title: "Reds and Pinks",
    value: "RedsPinks",
    id: "Reds_Pinks",
    background: "linear-gradient(90deg, #9D1834 0%, #FFC4CA 100%) "
  },
  {
    title: "Oranges and Rusts",
    value: "OrangesRusts",
    id: "Oranges_Rusts",
    background: "linear-gradient(90deg, #975633 0%, #F56107 100%)"
  },
  {
    title: "Beiges and Browns",
    value: "BeigesBrowns",
    id: "Browns_Beiges",
    background: "linear-gradient(90deg, #774B3D 0%, #EEDDD0 100%)"
  },
  {
    title: "Browns and Yellows",
    value: "BrownsYellows",
    id: "Yellows_LightBrowns",
    background: "linear-gradient(90deg, #805823 0%, #DDC700 100%)"
  },
  {
    title: "Earths and Greens",
    value: "EarthsGreens",
    id: "Greens_Earth",
    background: "linear-gradient(90deg, #49483D 0%, #5F8C11 100%)"
  },
  {
    title: "Greens and Turquoise",
    value: "GreensTurquoise",
    id: "Greens_Turquoise",
    background: "linear-gradient(90deg, #098328 0%, #23C1C1 100%)"
  },

  {
    title: "Blues and Indigos",
    value: "BluesIndigos",
    id: "Blues_Indigos",
    background: "linear-gradient(90deg, #2428AC 0%, #6D8DB1 100%)"
  },

  {
    title: "Purples and Violets",
    value: "PurplesViolets",
    id: "Purples_Violets",
    background: "linear-gradient(90deg, #751382 0%, #9473B0 100%)"
  },
  {
    title: "Burgundy and Maroons",
    value: "BurgundyMaroons",
    id: "Burgundy_Maroons",
    background: "linear-gradient(90deg, #4E202B 0%, #C23263 100%)"
  },
  {
    title: "Blacks and Whites",
    value: "BlacksWhites",
    id: "Blacks_Whites",
    background: "linear-gradient(90deg, #2B2B2B 0%, #C1C1C1 100%)"
  }
];
