import { convertArrintoDeg, resizeKeepingAspect } from "../../../utils/utils";
import { readImage } from "../../../utils/domUtils";
import { hexToRGB } from "../../../utils/colorutils";
import { clearCanvas, createCanvas, downloadImageData } from "../../../utils/canvasutils";
import ThreeViewHelper from "./threeviewhelper";
import TileCanvas from "../../../tilecanvasnew";
import PhotographicView from "./photographicviewhelper";
import AppProvider, { CDN_domain } from "../../../api/appProvider";

const tileCanvas = new TileCanvas();
const tileCanvasipv = tileCanvas;
// const tileCanvasipv = new TileCanvas();
export const roomTypes = {
  ILLUSTRATION: "illustration",
  ROOMVIEW: "roomview",
  PHOTOGRAPHIC: "photographic",
  PERSPECTIVE: "perspective"
};
export const defaultRoomType = roomTypes.ROOMVIEW;

function makeUrl() {
  let res = "";
  Array.from(arguments).forEach((argument, index) => {
    if (argument) {
      res = `${res}${index === 0 ? "" : "/"}${argument}`;
    }
  });
  return res;
}
const rgbFromHex = hex => {
  let rgb = [0, 0, 0];
  rgb[0] = parseInt(hex.substring(1, 3), 16);
  rgb[1] = parseInt(hex.substring(3, 5), 16);
  rgb[2] = parseInt(hex.substring(5, 7), 16);
  return rgb;
};

const patchRgb = [45, 24, 18];

export default class RoomViewHelper {
  constructor () {
    this.config = {};
    this.roomAssets = {};
    this.currentState = { shot: null, light: null, view: null };
    this.currentAssets = {};
    this.baseUrl = null;
    this.dimension = { width: null, height: null };
    this.dimensionPixels = { width: null, height: null };
    this.bgImage = null;
    this.maskImage = null;
    this.bgPatchImage = null;
    this.bgPatchShadowImage = null;
    this.shadowImage = null;
    this.highlightImage = null;
    this.canvasArray = Array(4);

    this.carpetURL = "";
    this.fbxLoaded = false;
    this.threeView = new ThreeViewHelper();
    this.phview = new PhotographicView();
    this.phviewipv = this.phview;
    // this.phviewipv = new PhotographicView();

    this.realTimeDynamicRendering = true;
    this.savedRoomState = {};

    this.zoom = 2;
  }

  initCanvas(options) {
    this.bgCanvas = options.bgCanvas;
    this.threeCanvas = options.threeCanvas;
    this.maskCanvas = options.maskCanvas;
    this.shadowCanvas = options.shadowCanvas;
    this.transitionCanvas = options.transitionCanvas;
    this.container = options.container;
    this.inputCanvas = options.inputCanvas;
    this.objCanvasContainer = options.objCanvasContainer;
  }
  clearAll() {
    this.clearAllCanvases();
    this.config = {};
    this.roomAssets = {};
    this.currentState = { shot: null, light: null, view: null };
    this.currentAssets = {};
    this.baseUrl = null;
    this.dimension = { width: null, height: null };
    this.dimensionPixels = { width: null, height: null };
    this.bgImage = null;
    this.maskImage = null;
    this.bgPatchImage = null;
    this.bgPatchShadowImage = null;
    this.shadowImage = null;
    this.highlightImage = null;
    this.canvasArray = Array(4);

    // this.carpetURL = "";
    // this.fbxLoaded = false;
  }
  clearAllCanvases() {
    const { width, height } = this.dimension;
    console.info("Clearing canvases", this.shadowCanvas);
    clearCanvas(this.bgCanvas, width, height);
    clearCanvas(this.maskCanvas, width, height);
    clearCanvas(this.shadowCanvas, width, height);
    clearCanvas(this.inputCanvas, width, height);
    // clearCanvas(this.transitionCanvas, width, height);
  }

  buildRoomAssets({ config }) {
    this.roomAssets = { ...config.roomAssets };
  }

  initConfig({ baseUrl, config, files, sizeFromConfig=false }) {
    this.realTimeDynamicRendering =
      config.realTimeDynamicRendering !== undefined || config.realTimeDynamicRendering !== null
        ? config.realTimeDynamicRendering
        : true;
    this.buildRoomAssets({ config });
    this.baseUrl = baseUrl;
    this.config = config;
    this.files = normalizeDirNames(files);
    const illustrationDims = this.config.dims;
    const containerDims = {
      width: this.container.clientWidth,
      height: this.container.clientHeight
    };
    this.currentActiveColors = [];
    this.selectedColorCode = null;
    this.annotationCanvas = null;
    this.resolution = window.devicePixelRatio;
    this.dimensionPixels = resizeKeepingAspect(illustrationDims, containerDims, "fit_inside");
    this.dimension = {
      width: sizeFromConfig && illustrationDims.width ? illustrationDims.width: window.screen.width * this.resolution,
      height:sizeFromConfig && illustrationDims.height ? illustrationDims.height: window.screen.height * this.resolution
   };
    this.resolution = this.dimension.width / this.dimensionPixels.width;
    this.inputCanvas.width = this.dimensionPixels.width;
    this.inputCanvas.height = this.dimensionPixels.height;
  }

  getShotAssets({ shot, light, view }) {
    if (light && view) {
      if (view) return this.roomAssets[shot][light][view];
      else return this.roomAssets[shot][light][this.config.defaultview];
    } else {
      return this.roomAssets[shot];
    }
  }

  updateRoomAssets({ shot, light, view, assets }) {
    if (light && view) {
      if (view) {
        this.roomAssets[shot][light][view] = {};
        this.roomAssets[shot][light][view] = { ...assets };
        if (assets && assets !== {}) this.roomAssets[shot][light][view]["init"] = true;
      } else {
        this.roomAssets[shot][light][this.config.defaultview] = {};
        this.roomAssets[shot][light][this.config.defaultview] = { ...assets };
        if (assets && assets !== {})
          this.roomAssets[shot][light][this.config.defaultview]["init"] = true;
      }
    } else if (shot) {
      this.roomAssets[shot] = {};
      this.roomAssets[shot] = { ...assets };
      if (assets && assets !== {}) this.roomAssets[shot]["init"] = true;
    }
  }

  preinitShot({ shot, light, view }) {
    this.updateRoomAssets({
      ...this.currentState,
      assets: { ...this.currentAssets }
    });

    let x = {
      shot: shot ? shot : this.currentState.shot,
      light: light ? light : this.currentState.light
    };
    if (x.light)
      x.view = !view
        ? this.currentState.view
          ? this.config.roomElements.lights[x.light].views.includes(this.currentState.view)
            ? this.currentState.view
            : this.config.defaultview
          : this.config.defaultview
        : view;

    this.currentState = { ...x };
    this.currentAssets = { ...this.getShotAssets({ ...x }) };

    return this.currentAssets.init;
  }

  initShot() {
    const promises = [];
    const bgUrl = this.currentAssets.background && this.currentAssets.background.url;
    const bgPatchUrl = this.currentAssets.backgroundpatch && this.currentAssets.backgroundpatch.url;
    const bgPatchGreyUrl = this.currentAssets.backgroundpatchgrey && this.currentAssets.backgroundpatchgrey.url;
    const bgPatchShadowUrl =
      this.currentAssets.backgroundpatchshadow && this.currentAssets.backgroundpatchshadow.url;
    const maskUrl = this.currentAssets.mask && this.currentAssets.mask.url;
    const shadowUrl = this.currentAssets.shadow && this.currentAssets.shadow.url;
    const highlightUrl = this.currentAssets.highlight && this.currentAssets.highlight.url;
    const glowUrl = this.currentAssets.glow && this.currentAssets.glow.url;
    const blurmapUrl = this.currentAssets.blurmap && this.currentAssets.blurmap.url;

    let objects = { ...this.currentAssets.objects };

    if (!bgUrl || !this.files.includes(bgUrl)) promises.push(Promise.reject("no background image"));
    else {
      promises.push(
        readImage(makeUrl(this.baseUrl, bgUrl)).then(
          img => (this.currentAssets.background.image = img)
        )
      );
      if (bgPatchUrl && this.files.includes(bgPatchUrl))
        promises.push(
          this.currentAssets.backgroundpatch.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, bgPatchUrl)).then(img => (this.currentAssets.backgroundpatch.image = img))
        );
      if (bgPatchGreyUrl && this.files.includes(bgPatchGreyUrl))
        promises.push(
          this.currentAssets.backgroundpatchgrey.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, bgPatchGreyUrl)).then(img => (this.currentAssets.backgroundpatchgrey.image = img))
        );
      if (bgPatchShadowUrl && this.files.includes(bgPatchShadowUrl))
        promises.push(
          this.currentAssets.backgroundpatchshadow.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, bgPatchShadowUrl)).then(
              img => (this.currentAssets.backgroundpatchshadow.image = img)
            )
        );
      if (maskUrl && this.files.includes(maskUrl))
        promises.push(
          this.currentAssets.mask.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, maskUrl)).then(
              img => (this.currentAssets.mask.image = img)
            )
        );
      if (shadowUrl && this.files.includes(shadowUrl))
        promises.push(
          this.currentAssets.shadow.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, shadowUrl)).then(
              img => (this.currentAssets.shadow.image = img)
            )
        );
      if (highlightUrl && this.files.includes(highlightUrl))
        promises.push(
          this.currentAssets.highlight.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, highlightUrl)).then(
              img => (this.currentAssets.highlight.image = img)
            )
        );
      if (glowUrl && this.files.includes(glowUrl))
        promises.push(
          this.currentAssets.glow.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, glowUrl)).then(
              img => (this.currentAssets.glow.image = img)
            )
        );
      if (blurmapUrl && this.files.includes(blurmapUrl))
        promises.push(
          this.currentAssets.blurmap.image
            ? Promise.resolve()
            : readImage(makeUrl(this.baseUrl, blurmapUrl)).then(
              img => (this.currentAssets.blurmap.image = img)
            )
        );

      Object.keys(objects).forEach(object => {
        if (
          objects[object].object &&
          objects[object].object.url &&
          this.files.includes(objects[object].object.url)
        ) {
          promises.push(
            this.currentAssets.objects[object].object.image
              ? Promise.resolve()
              : readImage(makeUrl(this.baseUrl, objects[object].object.url)).then(
                img => (this.currentAssets.objects[object].object.image = img)
              )
          );
        }
        if (
          objects[object].objectmask &&
          objects[object].objectmask.url &&
          this.files.includes(objects[object].objectmask.url)
        ) {
          promises.push(
            this.currentAssets.objects[object].objectmask.image
              ? Promise.resolve()
              : readImage(makeUrl(this.baseUrl, objects[object].objectmask.url)).then(
                img => (this.currentAssets.objects[object].objectmask.image = img)
              )
          );
        }

        if (
          objects[object].objectshadow &&
          objects[object].objectshadow.url &&
          this.files.includes(objects[object].objectshadow.url)
        ) {
          promises.push(
            this.currentAssets.objects[object].objectshadow.image
              ? Promise.resolve()
              : readImage(makeUrl(this.baseUrl, objects[object].objectshadow.url)).then(
                img => (this.currentAssets.objects[object].objectshadow.image = img)
              )
          );
        }

        if (
          objects[object].patchgrey &&
          objects[object].patchgrey.url &&
          this.files.includes(objects[object].patchgrey.url)
        ) {
          promises.push(
            this.currentAssets.objects[object].patchgrey.image
              ? Promise.resolve()
              : readImage(makeUrl(this.baseUrl, objects[object].patchgrey.url)).then(
                img => (this.currentAssets.objects[object].patchgrey.image = img)
              )
          );
        }

        if (
          objects[object].patchshadow &&
          objects[object].patchshadow.url &&
          this.files.includes(objects[object].patchshadow.url)
        ) {
          promises.push(
            this.currentAssets.objects[object].patchshadow.image
              ? Promise.resolve()
              : readImage(makeUrl(this.baseUrl, objects[object].patchshadow.url)).then(
                img => (this.currentAssets.objects[object].patchshadow.image = img)
              )
          );
        }
      });
    }

    return promises;
  }

  updateBackground(options = {}) {
    const { clear = false, dominantColorHex, canvas = this.bgCanvas } = options;
    const { width, height } = this.dimension;

    const bgCtx = canvas.getContext("2d");
    clearCanvas(canvas, width, height);
    setCanvasDimensions(canvas, this.dimension, this.dimensionPixels);
    if (clear) {
      return "clear";
    }
    bgCtx.drawImage(this.currentAssets.background.image, 0, 0, width, height);

    if (this.currentAssets.backgroundpatch &&
      this.currentAssets.backgroundpatch.image &&
      !this.annotationCanvas) {
      this.annotationCanvas = createCanvas(this.dimensionPixels.width, this.dimensionPixels.height);
      this.annotationCanvas
        .getContext("2d")
        .drawImage(this.currentAssets.backgroundpatch.image, 0, 0, this.dimensionPixels.width, this.dimensionPixels.height);
    }
    if (
      this.currentAssets.backgroundpatch &&
      this.currentAssets.backgroundpatch.image &&
      dominantColorHex
    ) {
      const activeColor = rgbFromHex(dominantColorHex);
      const patchCanvas = createCanvas(width, height);
      const patchCtx = patchCanvas.getContext("2d");
      patchCtx.drawImage(this.currentAssets.backgroundpatch.image, 0, 0, width, height);
      let patchData = patchCtx.getImageData(0, 0, width, height);
      if (!this.selectedColorCode) this.selectedColorCode = patchRgb;
      // console.log(patchData.data)
      let counter = 0, ifcounter = 0;
      let t1 = performance.now();
      for (let i = 0; i < patchData.data.length; i += 4) {
        counter++;
        if (
          patchData.data[i] === this.selectedColorCode[0] &&
          patchData.data[i + 1] === this.selectedColorCode[1] &&
          patchData.data[i + 2] === this.selectedColorCode[2]
        ) {
          ifcounter++;
          patchData.data[i] = activeColor[0];
          patchData.data[i + 1] = activeColor[1];
          patchData.data[i + 2] = activeColor[2];
        } else {
          patchData.data[i + 3] = 0;
        }
      }
      let t2 = performance.now();
      console.log(t2 - t1, " ms ", counter, " counter ", ifcounter);
      const sel = this.currentActiveColors.findIndex(
        item => item.annotationColor === this.selectedColorCode
      );
      if (!sel || sel === -1) {
        this.currentActiveColors.push({
          annotationColor: this.selectedColorCode,
          dominantColorHex
        });
      } else
        this.currentActiveColors[sel] = {
          annotationColor: this.selectedColorCode,
          dominantColorHex
        };

      patchCtx.putImageData(patchData, 0, 0);

      const patchGreyCanvas = createCanvas(width, height);
      const patchGreyCtx = patchGreyCanvas.getContext("2d");
      if (this.currentAssets.backgroundpatchgrey.image) {
        patchGreyCtx.drawImage(this.currentAssets.backgroundpatchgrey.image, 0, 0, width, height);
      }

      patchGreyCtx.globalCompositeOperation = "overlay";
      patchGreyCtx.drawImage(patchCanvas, 0, 0, width, height);
      if (
        this.currentAssets.backgroundpatchshadow &&
        this.currentAssets.backgroundpatchshadow.image
      ) {
        patchGreyCtx.globalCompositeOperation = "multiply";
        patchGreyCtx.drawImage(this.currentAssets.backgroundpatchshadow.image, 0, 0, width, height);
      }
      patchGreyCtx.globalCompositeOperation = "destination-in";
      patchGreyCtx.drawImage(patchCanvas, 0, 0, width, height);

      bgCtx.drawImage(patchGreyCanvas, 0, 0, width, height);
    }
    return "successfully updated bg";
  }
  async updateCameraShot(shot) {
    // console.log(this.config);
    // console.log(this.config.scene1);
    // console.log(this.config.scene1[this.config.shots[shot]]);
    await this.threeView.changeShot({ ...this.config.scene1[shot] });
    // await this.threeView.setupCarpet({ fbxUrl: "rug.fbx" })
    return;
  }
  updatethreeCanvas(shot) {
    if (!this.config.scenes) return;
    const scene = this.config.scenes[0];
    const { roomType = defaultRoomType } = this.config;
    const sceneConfig = this.config[scene];

    // this.threeView.init({
    //   canvas: this.threeCanvas,
    //   config: sceneConfig,
    //   shot: shot,
    //   dims: this.dimensionPixels,
    //   resolution: this.resolution,
    //   roomType,
    //   baseUrl: this.baseUrl
    // });
    // this.phview.clearScene();
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        this.threeView.clearScene();
        this.threeView.init({
          canvas: this.threeCanvas,
          config: sceneConfig,
          shot: shot,
          dims: this.dimensionPixels,
          resolution: this.resolution,
          roomType,
          baseUrl: this.baseUrl
        });
        return this.threeView.setup3dObject({
          fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl)
        });
      case roomTypes.ROOMVIEW:
        this.threeView.init({
          canvas: this.threeCanvas,
          config: sceneConfig,
          shot: shot,
          dims: this.dimensionPixels,
          resolution: this.resolution,
          roomType,
          baseUrl: this.baseUrl
        });
        let fbx = window.flags.visualizations.usePlainFbx || window.InterfaceElements.IsWallToWall ? "plain_rug.fbx":'rug.fbx';
        let fbxUrl = `${CDN_domain}v3assets/${fbx}`;
        return this.threeView.setupCarpet({ fbxUrl});
      case roomTypes.PHOTOGRAPHIC:
        if (this.config.name === "PerspectiveView") {
          this.phviewipv.init({
            canvas: this.threeCanvas,
            config: sceneConfig,
            shot: shot,
            dims: this.dimensionPixels,
            resolution: this.resolution,
            roomType,
            baseUrl: this.baseUrl
          });
          return this.phviewipv.setup3dObject({
            fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl)
          });
        } else {
          this.phview.init({
            canvas: this.threeCanvas,
            config: sceneConfig,
            shot: shot,
            dims: this.dimensionPixels,
            resolution: this.resolution,
            roomType,
            baseUrl: this.baseUrl
          });
          return this.phview.setup3dObject({
            fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl)
          });
        }

      default:
        console.warn("ROOMVIEW: no roomtype specified");
        break;
    }
  }

  renderDesign({ designDetails, designPath, hash }) {
    return new Promise((resolve, reject) => {
      this.designDetails = designDetails;
      this.designPath = designPath;
      const { roomType = roomTypes.ROOMVIEW } = this.config;
      const { designScale, canvasSize, renderBounds, offset } = this.config[this.config.scenes[0]];
      console.log("renderDesign -> renderBounds", renderBounds)
      if (designScale) this.zoom = designScale;
      if (window.InterfaceElements.IsJpeg) this.zoom = 1
      if (this.config.name === "PerspectiveView") {
        tileCanvasipv.init({
          tileSize: 256,
          zoom: this.zoom,
          designDetails,
          canvasSize,
          renderBounds,
          offset
        });
      } else {
        tileCanvas.init({
          tileSize: 256,
          zoom: this.zoom,
          designDetails,
          canvasSize,
          renderBounds,
          offset
        });
      }

      const tileTransparency = this.tileDetails[`tileTransparency${this.zoom}`];
      let commonTileProps = {
        designPath,
        zoom: this.zoom,
        designDetails,
        hash,
        tileTransparency
      };
      switch (roomType) {
        case roomTypes.ILLUSTRATION:
          this.updateGizmo();
          this.threeView
            .setObjectTexture({
              designDetails,
              designCanvas: tileCanvas.canvas
            })
            .then(size => {
              tileCanvas.drawCanvasTiles(
                {
                  ...commonTileProps,
                  drawNormap: tileTransparency.length
                },
                undefined,
                () => {
                  this.threeView.updateMap();
                  if (!size) resolve();
                  else {
                    this.updateShadowAssetFromSize(size).then(() => {
                      resolve();
                    });
                  }
                }
              );
            });

          break;
        case roomTypes.ROOMVIEW:
          this.threeView.setCarpetTexture({
            designDetails,
            designCanvas: tileCanvas.canvas,
            normapCanvas: tileCanvas.canvasNorm
          });
          this.threeView.setCarpetVisibility(false);
          const { DesignColors } = designDetails;
          tileCanvas.drawCanvasTiles(
            {
              ...commonTileProps,
              drawNormap:
                !DesignColors.every(color => color.PileHeight === DesignColors[0].PileHeight) ||
                tileTransparency.length
            },
            undefined,
            () => {
              this.threeView.updateMap();
              this.threeView.setCarpetVisibility(true);
              this.updateGizmo();

              resolve();
            }
          );
          break;
        case roomTypes.PHOTOGRAPHIC:
          if (this.config.name === "PerspectiveView") {
            this.phviewipv.setObjectTexture({
              designDetails,
              designCanvas: tileCanvasipv.canvas
            });
            tileCanvasipv.drawCanvasTiles(
              {
                ...commonTileProps,
                drawNormap: tileTransparency.length
              },
              undefined,
              () => {
                this.phviewipv.updateMap();
                resolve();
              }
            );
          } else {
            let backrendered, frontrendered;
            if (this.config[this.config.scenes[0]].surfaces.back) {
              let renderBounds =
                this.config[this.config.scenes[0]].back &&
                this.config[this.config.scenes[0]].back.renderBounds;
              if (!renderBounds)
                renderBounds = {
                  p1: { x: 0, y: 0 },
                  p2: { x: 1024, y: 1024 }
                };
              this.phview.setObjectTexture({
                designDetails,
                designCanvas: tileCanvas.canvas,
                backDesignCanvas: tileCanvas.canvasBack
              });
              tileCanvas.drawCanvasBackTiles(
                {
                  ...commonTileProps,
                  renderBounds: renderBounds
                },
                undefined,
                () => {
                  this.phview.updateMap();
                  backrendered = true;
                  resolvethis();
                }
              );
            } else {
              backrendered = true;
              this.phview.setObjectTexture({
                designDetails,
                designCanvas: tileCanvas.canvas
              });
            }

            tileCanvas.drawCanvasTiles(
              {
                ...commonTileProps,
                drawNormap: tileTransparency.length
              },
              undefined,
              () => {
                this.phview.updateMap();
                frontrendered = true;
                resolvethis();
              }
            );

            const resolvethis = () => {
              if (frontrendered && backrendered) {
                resolve();
              }
            };
          }
          break;
        default:
          console.warn("ROOMVIEW: no roomtype specified");
          break;
      }
    });
  }
  updateCarpetPosition(position) {
    this.threeView.setCarpetPositon(position);
    this.updateGizmo();
  }
  updateCarpetRotation(rotation) {
    this.threeView.setCarpetRotation(rotation);
  }
  setTileDetails(tileDetails) {
    this.tileDetails = tileDetails;
  }
  updateTiles({ designDetails, updateDesignTiles, updateNormapTiles, hash }) {
    if (!this.tileDetails) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      let colorIndex;
      if (updateDesignTiles) colorIndex = updateDesignTiles.colorIndex;
      if (updateNormapTiles) colorIndex = updateNormapTiles.colorIndex;

      this.designDetails = designDetails;

      let colorTileData = null;
      if (colorIndex && colorIndex !== -1) {
        const tileData = this.tileDetails[`colorTileData${this.zoom}`];
        colorTileData = tileData[colorIndex].tiles;
      } else {
        //all tiles
      }
      const tileTransparency = this.tileDetails[`tileTransparency${this.zoom}`];

      const props = {
        tiles: colorTileData,
        zoom: this.zoom,
        designDetails,
        designPath: this.designPath,
        hash,
        tileTransparency
      };
      if (!updateNormapTiles) {
        tileCanvas.designTilesUpdated = true;
        tileCanvas.updateDesignTiles(
          props,
          () => {
            this.threeView.updateMap();
          },
          () => {
            this.threeView.updateMap();
            setTimeout(() => {
              resolve();
            }, 500);
          }
        );
      } else {
        tileCanvas.normapTilesUpdated = true;
        tileCanvas.updateNormapTiles(
          props,
          () => {
            this.threeView.updateMap();
          },
          () => {
            this.threeView.updateMap();
            setTimeout(() => {
              resolve();
            }, 500);
          }
        );
        // this.tileCanvas1x.updateNo(props, () => this.render())
      }
    });
  }
  updateShadowAssetFromSize(size) {
    if (!size || !this.currentAssets.shadow) return Promise.resolve();
    const { key } = size;
    const shad = this.currentAssets.shadow[key];
    if (!shad) return Promise.resolve();

    const setAssets = img => {
      this.currentAssets.shadow.image = img;
      this.currentAssets.shadow.url = img;
    };
    if (shad.url && !shad.image) {
      return readImage(makeUrl(this.baseUrl, shad.url)).then(img => {
        this.currentAssets.shadow[key].image = img;
        setAssets(img);
      });
    } else {
      setAssets(shad.image);
      return Promise.resolve();
    }
  }
  change3dObjectScale(selectedSize) {
    const { roomType = roomTypes.ROOMVIEW } = this.config;
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        this.threeView.change3dObjectScalePhysical(selectedSize);
        this.updateShadowAssetFromSize(selectedSize).then(() => {
          this.updateShadow();
        });
        break;

      default:
        break;
    }
  }
  updateMask(options = {}) {
    const { clear = false } = options;
    const { width, height } = this.dimension;
    setCanvasDimensions(this.maskCanvas, this.dimension, this.dimensionPixels);
    clearCanvas(this.maskCanvas, width, height);
    if (clear) return "clear";

    // if (this.currentAssets.maskCanvasData) {
    //   this.maskCanvas
    //     .getContext("2d")
    //     .drawImage(this.currentAssets.maskCanvasData, 0, 0, width, height);
    //   return;
    // }

    if (!(this.currentAssets.mask && this.currentAssets.mask.image)) return;

    const tmpCanvas = createCanvas(width, height);
    tmpCanvas.getContext("2d").drawImage(this.bgCanvas, 0, 0, width, height);
    // makeMask(tmpCanvas, width, height, this.currentAssets.mask.image);
    tmpCanvas.getContext("2d").globalCompositeOperation = "destination-in";
    tmpCanvas.getContext("2d").drawImage(this.currentAssets.mask.image, 0, 0, width, height);
    // this.currentAssets.maskCanvasData = tmpCanvas;
    this.maskCanvas.getContext("2d").drawImage(tmpCanvas, 0, 0, width, height);
  }

  makeTransitionCanvas({ objects }) {
    setCanvasDimensions(this.transitionCanvas, this.dimension, this.dimensionPixels);
    const transitionctx = this.transitionCanvas.getContext("2d");
    if (!this.realTimeDynamicRendering && this.savedRoomState[this.config.name]) {
      transitionctx.drawImage(
        this.savedRoomState[this.config.name],
        0,
        0,
        this.dimension.width,
        this.dimension.height
      );
      return Promise.resolve();
    }
    transitionctx.drawImage(this.bgCanvas, 0, 0, this.dimension.width, this.dimension.height);
    transitionctx.drawImage(this.threeCanvas, 0, 0, this.dimension.width, this.dimension.height);
    transitionctx.drawImage(this.maskCanvas, 0, 0, this.dimension.width, this.dimension.height);
    transitionctx.drawImage(this.shadowCanvas, 0, 0, this.dimension.width, this.dimension.height);
    console.log(objects);
    Object.keys(objects).forEach(object => {
      const index = objects[object].order - 1;
      let objectcanvas = this.objCanvasContainer.children[index];
      transitionctx.drawImage(objectcanvas, 0, 0, this.dimension.width, this.dimension.height);
    });

    if (!this.realTimeDynamicRendering && !this.savedRoomState[this.config.name]) {
      const tempCanvas = createCanvas(this.dimension.width, this.dimension.height);
      const tCtx = tempCanvas.getContext("2d");
      setCanvasDimensions(tempCanvas, this.dimension, this.dimensionPixels);
      tCtx.drawImage(this.transitionCanvas, 0, 0, this.dimension.width, this.dimension.height);
      this.savedRoomState[this.config.name] = tempCanvas;
    }
    return Promise.resolve();
  }
  // saveRoomState(){
  //   if(!this.savedRoomState){
  //     this.savedRoomState =
  //   }
  // }
  renderSavedRoomState() {
    if (!this.savedRoomState || !this.savedRoomState[this.config.name]) return false;
    setCanvasDimensions(this.transitionCanvas, this.dimension, this.dimensionPixels);
    const transitionctx = this.transitionCanvas.getContext("2d");
    transitionctx.drawImage(
      this.savedRoomState[this.config.name],
      0,
      0,
      this.dimension.width,
      this.dimension.height
    );
    return true;
  }
  async updateShadow(options = {}) {
    const { clear = false } = options;
    clearCanvas(this.shadowCanvas, this.shadowCanvas.width, this.shadowCanvas.height);
    if (clear) return "clear";
    const { width, height } = this.dimension;
    const tempCanvas = createCanvas(width, height);
    const tCtx = tempCanvas.getContext("2d");
    setCanvasDimensions(this.shadowCanvas, this.dimension, this.dimensionPixels);
    const { roomType = defaultRoomType } = this.config;
    // let threeLayer;
    // switch (roomType) {
    //   case roomTypes.ILLUSTRATION:
    //     this.threeView.render();
    //     tCtx.drawImage(this.bgCanvas, 0, 0, width, height);
    //     let strMime = "image/png";
    //     let imgData = this.threeView.renderer.domElement.toDataURL(strMime);
    //     threeLayer = await readImage(imgData);
    //     break;
    //   default:
    //     threeLayer = this.threeCanvas;
    //     // console.warn("ROOMVIEW: no roomtype specified");
    //     break;
    // }

    tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
    tCtx.drawImage(this.maskCanvas, 0, 0, width, height);

    if (this.currentAssets.shadow && this.currentAssets.shadow.image) {
      tCtx.globalCompositeOperation = "multiply";
      tCtx.drawImage(this.currentAssets.shadow.image, 0, 0, width, height);
    }
    if (this.currentAssets.highlight && this.currentAssets.highlight.image) {
      tCtx.globalCompositeOperation = "screen";
      tCtx.drawImage(this.currentAssets.highlight.image, 0, 0, width, height);
    }
    if (this.currentAssets.glow && this.currentAssets.glow.image) {
      tCtx.globalCompositeOperation = "overlay";
      tCtx.drawImage(this.currentAssets.glow.image, 0, 0, width, height);
    }
    if (this.currentAssets.blurmap && this.currentAssets.blurmap.image) {
      tCtx.globalCompositeOperation = "source-over";
      makeBlur(tempCanvas, width, height, this.currentAssets.blurmap.image);
    }
    tCtx.globalCompositeOperation = "destination-in";
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
        break;
      default:
        tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
        break;
    }
    this.shadowCanvas.getContext("2d").drawImage(tempCanvas, 0, 0, width, height);
    if (window.flags.designView.hasDesignWaterMark) {
      const logoUrl = `${AppProvider.domain}${window.InterfaceElements.LogoUrl}`;
      const img = await readImage(logoUrl);
      const imgwidth = 100;
      const imgheight = (img.height * imgwidth) / img.width;

      const startx = width - (imgwidth + 15);
      const starty = height - (imgheight + 15);
      const context = this.shadowCanvas.getContext("2d");
      context.globalAlpha = 0.5;
      context.drawImage(img, startx, starty, imgwidth, imgheight);
    }
  }

  updateObjects(options = {}) {
    const { width, height } = this.dimension;
    this.objCanvasContainer.style.width = `${this.dimensionPixels.width}px`;
    this.objCanvasContainer.style.height = `${this.dimensionPixels.height}px`;

    Object.keys(options).forEach(object => {
      const indexindiv = options[object].order - 1;
      const ref = this.objCanvasContainer.children[indexindiv];
      const refCtx = ref.getContext("2d");
      setCanvasDimensions(ref, this.dimension, this.dimensionPixels);
      if (
        options[object] &&
        (options[object].active || options[object].mystic) &&
        this.currentAssets.objects[object].object.image
      ) {
        refCtx.drawImage(this.currentAssets.objects[object].object.image, 0, 0, width, height);
      }

      if (options[object] && options[object].active && options[object].active !== "init") {
        refCtx.drawImage(this.currentAssets.objects[object].patchgrey.image, 0, 0, width, height);

        const patchCanvas = createCanvas(width, height);
        const patchCtx = patchCanvas.getContext("2d");
        patchCtx.fillStyle = options[object].active;
        patchCtx.fillRect(0, 0, width, height);

        patchCtx.globalCompositeOperation = "destination-in";
        patchCtx.drawImage(this.currentAssets.objects[object].patchgrey.image, 0, 0, width, height);

        refCtx.globalCompositeOperation = "color";
        refCtx.drawImage(patchCanvas, 0, 0, width, height);

        const shadowCanvas = createCanvas(width, height);
        const shadowCtx = shadowCanvas.getContext("2d");
        shadowCtx.drawImage(
          this.currentAssets.objects[object].patchshadow.image,
          0,
          0,
          width,
          height
        );
        shadowCtx.globalCompositeOperation = "destination-in";
        shadowCtx.drawImage(patchCanvas, 0, 0, width, height);

        refCtx.globalCompositeOperation = "multiply";
        refCtx.drawImage(shadowCanvas, 0, 0, width, height);
      }
    });
  }

  mouseDownTouchStart(e) {
    this.updateGizmo();
    this.intersectsGizmo = this.findGizmoIntersection(e);
    console.log(this.intersectsGizmo);
    this.prev = { ...e };
    if (!this.intersectsGizmo) {
      const intersectsCarpet = this.threeView.mouseDownTouchStart(e);
      this.shadowCleared = false;
      if (intersectsCarpet) {
        this.updateShadow({ clear: true });
        this.shadowCleared = true;
        return true;
      }
    } else {
      this.prev = { ...e };
      this.updateShadow({ clear: true });
      this.shadowCleared = true;
      return true;
    }
  }
  mouseDownTouchMove(e) {
    if (!this.intersectsGizmo) {
      this.threeView.mouseTouchMove(e);
      this.updateGizmo();
    } else {
      const difference = e.x - this.prev.x;
      this.threeView.rotateCarpet(difference, "z");
      this.prev = { ...e };
    }
  }
  mouseDownTouchEnd(e) {
    if (this.shadowCleared) this.updateShadow();
    let showColorSelectionBox = null;
    if (!this.moved && this.annotationCanvas) {
      const imgData = this.annotationCanvas.getContext("2d").getImageData(e.x, e.y, 1, 1);
      if (!imgData.data.every(data => data === 255 || data === 0))
        showColorSelectionBox = this.selectedColorCode = imgData.data.slice(0, 3);
    }
    let rotation = null;
    let position = null;
    const object = this.threeView.getObjectConfig();
    if (object) {
      if (this.intersectsGizmo) {
        rotation = convertArrintoDeg(object.rotation.toArray().slice(0, 3));
      } else {
        position = object.position;
      }
    }
    let texCoordinates;
    const intersect = this.threeView.raycastMouseOnSurface(e);
    if (intersect && this.designDetails) {
      const x = this.designDetails.Width * intersect.uv.x;
      const y = this.designDetails.Height * (1 - intersect.uv.y);
      texCoordinates = { x, y };
    }

    return { showColorSelectionBox, rotation, position, texCoordinates };
  }
  findGizmoIntersection(e) {
    const { x, y } = e;
    if (!this.inputCanvas) return;
    var imgData = this.inputCanvas.getContext("2d").getImageData(x - 10, y - 10, 20, 20);
    var ingizmo = false;
    for (var i = 0; i < imgData.data.length; i += 4) {
      if (imgData.data[i + 3] !== 0) {
        ingizmo = true;
        break;
      }
    }
    return ingizmo;
  }
    selectColorCode(colorCode) {
    const { r, g, b } = hexToRGB(colorCode)
    const color = [r, g, b]
    if (color.every(value => value === 0) || color.every(value => value === 255)) return
    this.selectedColorCode = color
  }
  updateGizmo() {
    const { roomType = defaultRoomType } = this.config;
    if (roomType !== roomTypes.ROOMVIEW) return;
    const diamondHeight = 10;
    const context = this.inputCanvas.getContext("2d");
    const { width, height } = this.inputCanvas;
    if (roomType !== roomTypes.ROOMVIEW) {
      context.clearRect(0, 0, width, height);
      return;
    }
    const gizmoCoordinates = this.threeView.getGizmoCordinates();
    if (!gizmoCoordinates) return;
    let { radX, radY, canvasCenter } = gizmoCoordinates;
    const rgb = {
      r: 250,
      g: 250,
      b: 250,
      a: 0.8
    };
    const colorStr = "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a + ")";
    var radiusX;
    var radiusY;
    if (radX > radY) {
      radiusX = radX;
      radiusY = radY;
    } else {
      radiusX = radY;
      radiusY = radX;
    }
    // Draw the ellipse
    context.strokeStyle = colorStr;
    context.fillStyle = colorStr;
    context.lineWidth = 1;
    context.shadowOffsetX = 0;
    context.shadowColor = "black";
    context.shadowOffsetY = 1;
    context.clearRect(0, 0, width, height);
    context.beginPath();
    context.ellipse(canvasCenter.x, canvasCenter.y, radiusX, radiusY, 0, 0, 2 * Math.PI);
    context.stroke();
    context.beginPath();
    context.moveTo(canvasCenter.x, canvasCenter.y + radiusY - 5);
    context.lineTo(canvasCenter.x + diamondHeight, canvasCenter.y + radiusY);
    context.lineTo(canvasCenter.x, canvasCenter.y + radiusY + 5);
    context.lineTo(canvasCenter.x - diamondHeight, canvasCenter.y + radiusY);
    context.fill();
  }
  resize(newDims) {
    if (!this.config.dims || !this.threeView.renderer) return;
    this.dimensionPixels = resizeKeepingAspect(this.config.dims, newDims, "fit_inside");
    setCanvasDimensionsStyle(this.bgCanvas, this.dimensionPixels);
    const { roomType } = this.config;
    switch (roomType) {
      case roomTypes.PERSPECTIVE:
        this.phview.resizeRenderer(this.dimensionPixels);
        break;

      default:
        this.threeView.resizeRenderer(this.dimensionPixels);
        break;
    }
    setCanvasDimensionsStyle(this.maskCanvas, this.dimensionPixels);
    setCanvasDimensionsStyle(this.shadowCanvas, this.dimensionPixels);
    this.inputCanvas.width = this.dimensionPixels.width;
    this.inputCanvas.height = this.dimensionPixels.height;
    this.objCanvasContainer.style.width = `${this.dimensionPixels.width}px`;
    this.objCanvasContainer.style.height = `${this.dimensionPixels.height}px`;
    let objectlength = this.objCanvasContainer.children.length;
    for (let i = 0; i < objectlength; i++) {
      setCanvasDimensionsStyle(this.objCanvasContainer.children[i], this.dimensionPixels);
    }
    this.updateGizmo();
  }
  renderinCanvas() {
    const { width: w, height: h } = this.dimensionPixels;
    const renderCanvas = createCanvas(w, h);
    const renderCtx = renderCanvas.getContext("2d");
    renderCtx.drawImage(this.bgCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.threeCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.maskCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.shadowCanvas, 0, 0, w, h);
    Array.from(this.objCanvasContainer.children).forEach(item => {
      console.log(item.nodeName);
      if (item.nodeName === "CANVAS") {
        renderCtx.drawImage(item, 0, 0, w, h);
      }
    });
    return renderCanvas;
  }
  downloadRendering(name, mime) {
    // const renderCanvas = this.renderinCanvas();
    downloadImageData(this.transitionCanvas, name, "image/" + mime);
  }
}

function makeMask(canvas, w, h, maskImg, flag = false) {
  const tCanvas = createCanvas(w, h);

  const shCtx = canvas.getContext("2d");
  const tmpCtx = tCanvas.getContext("2d");
  tmpCtx.drawImage(maskImg, 0, 0, w, h);
  let imgData = shCtx.getImageData(0, 0, w, h);
  let maskData = tmpCtx.getImageData(0, 0, w, h);
  for (let i = 0; i < maskData.data.length; i += 4) {
    if (flag) {
      imgData.data[i + 3] = maskData.data[i];
    } else {
      imgData.data[i + 3] = maskData.data[i];
    }
  }
  shCtx.putImageData(imgData, 0, 0);
  return maskData;
}
function makeBlur(canvas, w, h, blurMap) {
  const blurCanvas = createCanvas(w, h);
  const blurredCanvas = createCanvas(w, h);
  const ctx = canvas.getContext("2d");
  const blurCtx = blurCanvas.getContext("2d");
  const blurredCtx = blurredCanvas.getContext("2d");
  blurredCtx.filter = "blur(6px)";
  blurredCtx.drawImage(canvas, 0, 0, w, h);
  blurCtx.drawImage(blurMap, 0, 0, w, h);
  let imageData = ctx.getImageData(0, 0, w, h);
  let blurData = blurCtx.getImageData(0, 0, w, h);
  let blurredData = blurredCtx.getImageData(0, 0, w, h);
  for (let i = 0; i < blurData.data.length; i += 4) {
    imageData.data[i + 3] = 255 - blurData.data[i];
    blurredData.data[i + 3] = blurData.data[i];
  }
  ctx.putImageData(imageData, 0, 0);
  blurredCtx.putImageData(blurredData, 0, 0);
  ctx.drawImage(blurredCanvas, 0, 0, w, h);
}
const setCanvasDimensionsStyle = (canvas, dimensionPixels) => {
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
};
const setCanvasDimensions = (canvas, dimension, dimensionPixels) => {
  const { width, height } = dimension;
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.width = width;
  canvas.height = height;
  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
};
const normalizeDirNames = files =>
  files.map(item => (item.charAt(0) === "/" ? item.substring(1) : item));
