import React from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import { Button, H5 } from "@blueprintjs/core";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";

const VrDialog = () => {
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_VR_DIALOG, payload: false });
  };
  const handleViewinBrowser = () => {};
  const handleEmailLink = () => {};
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showVrDialog}
      className="at-confirmation-dialog"
      size="xs"
    >
      <div className="at-dialog-area at-vr-dialog">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <H5 className="at-dialog-heading">{strings.vr.viewInVRDevice}</H5>
        <div className="at-dialog-content">
          <div className="at-qr-description">{strings.vr.qrDesc}</div>
          <img
            className="at-vr-qrcode"
            src="https://www.qrcode-monkey.com/img/default-preview-qr.svg"
            alt="qr code"
          ></img>
          <div className="at-confirmation-btns-area">
            <AtButton
              intent="primary"
              tertiary
              onClick={handleViewinBrowser}
              text={strings.vr.viewInBrowser}
            />
            <AtButton
              intent="primary"
              tertiary
              onClick={handleEmailLink}
              text={strings.vr.emailLink}
            />
          </div>
        </div>
      </div>
    </AtDialog>
  );
};

export default VrDialog;
