import React from "react";
import LazyThumbnail from "../../pages/DesignBrowser/LazyThumbnail";
const DesignThumblist = props => {
  const { onThumbnailClick, selectedFile = {}, files = [] } = props;
  return (
    <div className="thumb-container ">
      {files.map((file, index) => (
        <LazyThumbnail
          {...props}
          node={file}
          key={index}
          onThumbnailClick={() => onThumbnailClick && onThumbnailClick(file, index)}
          active={file.id === selectedFile.id}
        />
      ))}
    </div>
  );
};

export default DesignThumblist;
