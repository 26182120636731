import React from "react";
const SET_VIEW_MODE = "SET_VIEW_MODE";
const SET_ILLUSTRATIONS = "SET_ILLUSTRATIONS";
const SET_ACTIVE_NODE = "SET_ACTIVE_ILLUSTRATION_NODE";
const SELECT_NEXT_ILLUSTRATION = "SELECT_NEXT_ILLUSTRATION";
const SELECT_PREV_ILLUSTRATION = "SELECT_PREV_ILLUSTRATION";
const FILTER_ILLUSTRATIONS_BY_PATH = "FILTER_ILLUSTRATIONS_BY_PATH";
const RESET_ROOMS_FILTER = "RESET_ROOMS_FILTER";
const SET_ROOMS_CONTROL = "SET_ROOMS_CONTROL";
const FILTER_ILLUSTRATIONS_FOR_DESIGN = "FILTER_ILLUSTRATIONS_FOR_DESIGN";
const SET_FLOOR_OPTIONS = "SET_FLOOR_OPTIONS";
const SET_ACTIVE_FLOOR_OPTION = "SET_ACTIVE_FLOOR_OPTION";
const SET_SHOW_FLOOR_OPTION = "SET_SHOW_FLOOR_OPTION";
const SET_CARPET_OPTION = "SET_CARPET_OPTION";
const SWITCH_PREV_VIEW = "SWITCH_PREV_VIEW";
const SET_ROOM_FOLDERS = "SET_ROOM_FOLDERS";
const SET_SIMILAR_ROOMS = "SET_SIMILAR_ROOMS";
const visViewModes = {
  ROOM_VIEW: "ROOM_VIEW",
  MY_ROOM: "MY_ROOM",
  DESIGN_VIEW_DEEPZOOM: "DESIGN_VIEW_DEEPZOOM", //deep zoom view
  DESIGN_VIEW_DEEPZOOM_RX: "DESIGN_VIEW_DEEPZOOM_RX", //deep zoom with repeat x
  DESIGN_VIEW_ZERO_ZOOM: "DESIGN_VIEW_ZERO_ZOOM",
  DESIGN_VIEW_PERSPECTIVE: "DESIGN_VIEW_PERSPECTIVE",
  DESIGN_VIEW_FOLDEDBACK: "DESIGN_VIEW_FOLDEDBACK",
  DESIGN_VIEW_PHOTOGRAPHIC: "DESIGN_VIEW_PHOTOGRAPHIC",
  CATALOG_VIEW: "CATALOG_VIEW"
};

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const visualizationsActions = {
  SET_VIEW_MODE,
  SET_ILLUSTRATIONS,
  SET_ACTIVE_NODE,
  SELECT_NEXT_ILLUSTRATION,
  SELECT_PREV_ILLUSTRATION,
  FILTER_ILLUSTRATIONS_BY_PATH,
  RESET_ROOMS_FILTER,
  FILTER_ILLUSTRATIONS_FOR_DESIGN,
  SET_ROOMS_CONTROL,
  SET_ACTIVE_FLOOR_OPTION,
  SET_SHOW_FLOOR_OPTION,
  SET_FLOOR_OPTIONS,
  SET_CARPET_OPTION,
  SWITCH_PREV_VIEW,
  SET_ROOM_FOLDERS,
  SET_SIMILAR_ROOMS
};

const visualizationState = {
  viewMode: visViewModes.DESIGN_VIEW_DEEPZOOM,
  history: [visViewModes.DESIGN_VIEW_DEEPZOOM],
  illustrations: [],
  initialIllustrations: [],
  activeNode: {
    id: "0"
  },
  roomsControl: null,
  floorOptions: {
    show: false,
    floors: [],
    activeFloor: {}
  },
  carpetOptions: {
    rotation: null
  },
  roomFolders: [], //done for studioentry
  similarRooms: [] //done for studioentry
};

const visualizationReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_VIEW_MODE:
      return { ...state, viewMode: action.payload, history: [...state.history, action.payload] };
    case SWITCH_PREV_VIEW:
      const his = [...state.history];
      if (!his.length) return state;
      const popped = his.pop();
      let viewMode = his[his.length - 1];
      if (!his.length) viewMode = popped;
      return { ...state, viewMode, history: his };
    case SET_ILLUSTRATIONS:
      return setIllustrations(state, payload);
    case SET_ACTIVE_NODE:
      if (state.activeNode) {
        if (
          state.activeNode.id !== action.payload.id ||
          state.viewMode !== visViewModes.ROOM_VIEW
        ) {
          return { ...state, activeNode: action.payload };
        } else return state;
      } else return { ...state, activeNode: action.payload };
    case SELECT_PREV_ILLUSTRATION:
      return selPrevIll(state);
    case SELECT_NEXT_ILLUSTRATION:
      return selNextIll(state);
    case FILTER_ILLUSTRATIONS_BY_PATH:
      return filterIllustrationByPath(state, action.payload);
    case SET_ROOMS_CONTROL:
      return { ...state, roomsControl: action.payload };
    case FILTER_ILLUSTRATIONS_FOR_DESIGN:
      return filterIllForDesign(state, payload);
    case SET_FLOOR_OPTIONS:
      return setFloorOptions(state, payload);
    case SET_ACTIVE_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, activeFloor: payload } };
    case SET_SHOW_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, show: !!payload } };
    case SET_CARPET_OPTION:
      return { ...state, carpetOptions: payload };
    case SET_ROOM_FOLDERS:
      return { ...state, roomFolders: payload };
    case SET_SIMILAR_ROOMS:
      return { ...state, similarRooms: payload };
    default:
      return state;
  }
};
const setFloorOptions = (state, payload) => {
  const { floorOptions } = state;
  const {
    floors = floorOptions.floors,
    activeFloor = floorOptions.activeFloor,
    show = floorOptions.show
  } = payload;
  return { ...state, floorOptions: { ...state.floorOptions, floors, activeFloor, show } };
};
// const filterIllForDesign = (state, payload) => {
//   const designFullPath = payload;
//   const { roomsControl, initialIllustrations, activeNode } = state
//   if (!designFullPath || !roomsControl) return state
//   const filteredRooms =
//     roomsControl.find(
//       item => item.design.toLowerCase() === designFullPath.toLowerCase()
//     ) || {};
//   if (filteredRooms.rooms) {
//     const filterPaths = filteredRooms.rooms.map(item => item.toLowerCase());
//     const filtered = initialIllustrations.filter(item => filterPaths.includes(item.FullPath.toLowerCase()))
//     if (activeNode.id === "0")
//       return { ...state, illustrations: filtered };
//     const hasCurrentRoom = filtered.find(item => item.FullPath.toLowerCase() === activeNode.FullPath.toLowerCase())
//     if (hasCurrentRoom)
//       return { ...state, illustrations: filtered };
//     else
//       return { ...state, illustrations: filtered, viewMode: visViewModes.DESIGN_VIEW_DEEPZOOM }
//   } else {
//     return { ...state, illustrations: initialIllustrations };
//   }
// }
const filterIllForDesign = (state, payload) => {
  const designFullPath = payload;
  const { roomsControl, initialIllustrations, activeNode } = state;
  if (!designFullPath || !roomsControl) return state;
  const filteredRooms =
    roomsControl.find(item => {
      //check if the design path contains "Designs/ in it or contains file extension"
      const pathArr = item.design.split("/").filter(i => i && i !== "");
      const filename = pathArr[pathArr.length - 1];
      const filenameArr = filename.split(".");
      const ext = filenameArr[filenameArr.length - 1];

      let name = pathArr.join("/");
      const desfp = designFullPath.split(".").filter(i => i && i !== "");
      const desfpext = desfp[desfp.length - 1];
      if (ext.toLowerCase() !== desfpext) name = `${name}.${desfpext}`;
      const designPath = designFullPath.replace("Designs/", "");
      return name.toLowerCase() === designPath.toLowerCase();
    }) || {};
  if (!filteredRooms.rooms && !window.flags.showAllRoomsWhenNoRoomsSelected) {
    filteredRooms.rooms = [];
  }
  if (filteredRooms.rooms) {
    const filterPaths = filteredRooms.rooms.map(item => {
      //check if the room path contains "Rooms/ in it or contains file extension"
      let path = item.toLowerCase();
      const pathArr = item.split("/").filter(item => item && item !== "");
      if (pathArr[0] !== "rooms") path = `rooms/${path}`;
      const name = pathArr[pathArr.length - 1];
      const extArr = name.split(".");
      const ext = extArr[extArr.length - 1];
      if (ext !== "crf3d") path = `${path}.crf3d`;
      return path;
    });
    const filtered = initialIllustrations.filter(item =>
      filterPaths.includes(item.FullPath.toLowerCase())
    );
    if (activeNode.id === "0") return { ...state, illustrations: filtered };
    const hasCurrentRoom = filtered.find(item => {
      return item.FullPath.toLowerCase() === activeNode.FullPath.toLowerCase();
    });
    if (hasCurrentRoom) return { ...state, illustrations: filtered };
    else return { ...state, illustrations: filtered, viewMode: visViewModes.DESIGN_VIEW_DEEPZOOM };
  } else {
    return { ...state, illustrations: initialIllustrations };
  }
};

const setIllustrations = (state, payload) => {
  let illustrations = payload;
  if (state.illustrations.length) {
    const filterPaths = state.illustrations.map(item => item.FullPath.toLowerCase());
    illustrations = payload.filter(item => filterPaths.includes(item.FullPath.toLowerCase()));
  }
  return { ...state, illustrations, initialIllustrations: payload };
};
const filterIllustrationByPath = (state, payload) => {
  const filterPaths = payload.map(item => item.toLowerCase());
  const filteredIllustrations = state.initialIllustrations.filter(item =>
    filterPaths.includes(item.FullPath.toLowerCase())
  );
  return { ...state, illustrations: filteredIllustrations };
};
const selPrevIll = state => {
  const ind = state.illustrations.findIndex(item => item.id === state.activeNode.id);
  if (ind < 0 || ind === 0) return state;
  return { ...state, activeNode: state.illustrations[ind - 1] };
};
const selNextIll = state => {
  const ind = state.illustrations.findIndex(item => item.id === state.activeNode.id);
  if (ind < 0 || ind >= state.illustrations.length - 1) return state;
  return { ...state, activeNode: state.illustrations[ind + 1] };
};
function VisualizationStateProvider({ children }) {
  const { visualizations } = window.flags;
  const initState = { ...visualizationState, viewMode: visualizations.defaultView };
  const [state, dispatch] = React.useReducer(visualizationReducer, initState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useVisualizationState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchVisualization() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export {
  VisualizationStateProvider,
  useVisualizationState,
  useDispatchVisualization,
  visualizationsActions,
  visViewModes
};
