/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AnimateSubject from "../AnimateSubject";
import InfoBubble from "./../InfoBubble";
import "./_engine.scss";

let firststate = true;
let refillasked = false;
let reloopStopIndex = -1;
let hasShowStarted = false;

const stockTolerance = 4;
const backupTolerance = 4;
const maxStateLength = 10;

function Engine(props) {
  const { subjects, duration, askRefill, pause, handleShowStart } = props;
  const [state, setstate] = useState([]);
  const [loopingsubjects, setloopingsubjects] = useState([]);
  const [activeDuration, setactiveDuration] = useState();
  const [animationClasses, setanimationClasses] = useState(["zoomin", "zoomout"]);
  const [currentPushIndex, setcurrentPushIndex] = useState();
  const [startSessionTrigger, setstartSessionTrigger] = useState(false);
  const [holdTime, setholdTime] = useState(-1);
  const [playTime, setplayTime] = useState(0);
  const [activeIndex, setactiveIndex] = useState();

  useEffect(() => {
    if (subjects && subjects.length > 0) {
      setstate([...state, ...subjects]);
      refillasked = false;
    } else {
      reset();
    }
  }, [subjects]);

  const reset = () => {
    firststate = true;
    refillasked = false;
    reloopStopIndex = -1;
    hasShowStarted = false;
    setstate([]);
    setloopingsubjects([]);
    setstartSessionTrigger(false);
    setholdTime(-1);
    setplayTime(0);
    setcurrentPushIndex(null);
    setactiveDuration(null);
  };

  useEffect(() => {
    if (duration && !activeDuration) setactiveDuration(duration);
  }, [duration]);

  useEffect(() => {
    if (!firststate || !state || state.length < 1) return;
    if (state.length < stockTolerance) {
      if (!refillasked) {
        askRefill();
        refillasked = true;
      }
      return;
    }
    firststate = false;
    let temp = [];
    let firstThreeState = ["", ...state.slice(0, 2)];
    firstThreeState.forEach((element, index) => {
      if (index === 1) {
        temp.push({
          ...element,
          subjectstate: animatesubjectstates[index],
          animationClass: animationClasses[0],
          infoData: {
            class: element.class,
            isDesignOnly: element.isDesignOnly,
            designLink: element.instanceUrl,
            color: element.color,
            qrSrc: element.qrSrc
          }
        });
        setanimationClasses([...animationClassesRef]);
      } else
        temp.push({
          ...element,
          subjectstate: animatesubjectstates[index],
          animationClass: animationClassesRef[2],
          infoData: {
            class: element.class,
            isDesignOnly: element.isDesignOnly,
            designLink: element.instanceUrl,
            color: element.color,
            qrSrc: element.qrSrc
          }
        });
      if (index === firstThreeState.length - 1) {
        setactiveIndex(1);
        setloopingsubjects([...temp]);
        setcurrentPushIndex(temp.length - 1);
      }
    });
  }, [state]);

  useEffect(() => {
    let la = true;
    let holdInterval;
    // if (initPlay && !pause) {
    //   initPlay = false;
    //   setstartSessionTrigger(true);
    // }
    if (pause) {
      if (holdTime < 0 && playTime !== 0) setholdTime(playTime);
      return;
    }
    if (holdTime > 0) {
      let remtime = activeDuration - holdTime;
      holdInterval = setInterval(() => {
        if (la) {
          remtime--;
          setholdTime(activeDuration - remtime);
          if (remtime === 0) {
            startNextSession();
            setholdTime(-1);
            clearInterval(holdInterval);
          }
        } else {
          clearInterval(holdInterval);
        }
      }, 1000);
    }
    return () => {
      la = false;
      clearInterval(holdInterval);
    };
  }, [pause]);

  useEffect(() => {
    let animInterval;
    setplayTime(0);
    if (loopingsubjects && loopingsubjects.length > 0) {
      if (!hasShowStarted) {
        hasShowStarted = true;
        handleShowStart(true);
      }
      let count = 0;
      animInterval = setInterval(() => {
        count++;
        setplayTime(count);
        if (count === activeDuration) {
          clearInterval(animInterval);
          setstartSessionTrigger(true);
        }
      }, 1000);
    }
  }, [loopingsubjects]);

  useEffect(() => {
    if (startSessionTrigger) {
      setstartSessionTrigger(false);
      if (holdTime <= 0 && !pause) {
        startNextSession();
      }
    }
  }, [startSessionTrigger]);
  const animatesubjectstates = ["backup", "active", "standby"];
  const animationClassesRef = ["slide", "zoomin", "zoomout"];
  // const animationClassesRef = ["zoomout", "zoomout", "zoomout"];

  const startNextSession = () => {
    let pushElement, backupElement, activeElement;
    let currentind = -1;
    loopingsubjects.forEach((element, index) => {
      if (element.subjectstate === animatesubjectstates[0]) {
      } else if (element.subjectstate === animatesubjectstates[1]) {
        currentind = index;
        backupElement = { ...element, subjectstate: animatesubjectstates[0] };
      } else {
        activeElement = { ...element, subjectstate: animatesubjectstates[1] };
      }
    });

    let animationClassesTemp = [...animationClasses];
    let index = Math.floor(Math.random() * animationClassesTemp.length + 0);
    let randomAnimCat = animationClassesTemp[index];
    animationClassesTemp.splice(index, 1);
    if (animationClassesTemp.length === 0) {
      setanimationClasses([...animationClassesRef]);
    } else {
      setanimationClasses([...animationClassesTemp]);
    }
    let thispushindex = currentPushIndex;

    if (reloopStopIndex > -1 && state.length > reloopStopIndex + 1) {
      if (thispushindex === 0 || state[thispushindex].index !== state[thispushindex - 1].index) {
        thispushindex = reloopStopIndex;
        reloopStopIndex = -1;
      }
    }

    let pushindexelem;
    let newcurrentPushIndex;
    if (state[thispushindex]) {
      pushindexelem = { ...state[thispushindex] };
      newcurrentPushIndex = thispushindex + 1;
      setcurrentPushIndex(newcurrentPushIndex);
    } else {
      pushindexelem = { ...state[0] };
      newcurrentPushIndex = 1;
      setcurrentPushIndex(newcurrentPushIndex);
      reloopStopIndex = thispushindex;
    }
    if (state.length - thispushindex < stockTolerance) {
      if (!refillasked) {
        askRefill();
        refillasked = true;
      }
    }
    if (state.length > maxStateLength && newcurrentPushIndex - 2 >= backupTolerance) {
      setstate([...state.slice(backupTolerance, state.length)]);
      setcurrentPushIndex(newcurrentPushIndex - backupTolerance);
    }
    pushElement = {
      ...pushindexelem,
      subjectstate: animatesubjectstates[2],
      animationClass: randomAnimCat,
      infoData: {
        class: pushindexelem.class,
        isDesignOnly: pushindexelem.isDesignOnly,
        designLink: pushindexelem.instanceUrl,
        color: pushindexelem.color,
        qrSrc: pushindexelem.qrSrc
      }
    };

    let temp =
      currentind === 1
        ? [backupElement, pushElement, activeElement]
        : [backupElement, activeElement, pushElement];
    let newactiveIndex = currentind === 1 ? 2 : 1;
    if (duration !== activeDuration) setactiveDuration(duration);
    setactiveIndex(newactiveIndex);
    setloopingsubjects([...temp]);
  };

  return (
    <div className={`${pause ? "paused" : ""} animation-wrapper `}>
      {loopingsubjects &&
        loopingsubjects.length > 0 &&
        loopingsubjects.map((item, i) => {
          return (
            <AnimateSubject
              key={i}
              subject={{ src: item.subject, fullDesign: item.fullDesign }}
              animationState={item.subjectstate}
              animationClass={item.animationClass}
              duration={activeDuration}
              infoData={item.infoData}
            ></AnimateSubject>
          );
        })}
      {loopingsubjects && loopingsubjects.length > 0 && (
        <InfoBubble
          DesignName={loopingsubjects[activeIndex].name}
          DesignData={loopingsubjects[activeIndex].infoData}
          animationDur={duration}
          showSettings={false}
          hasinstanceurl={true}
          pause={pause}
          remTime={activeDuration - holdTime}
        />
      )}
    </div>
  );
}

Engine.propTypes = {};

export default Engine;
