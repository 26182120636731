import React, { useRef, useEffect } from "react";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import classNames from "classnames";
import { useMount } from "react-use";
import { designListActions, useDispatchDesignList } from "../../../reducers/designlist.reducer";

const size = [600, 400];
const options = {
  colors: [
    "#1f77b4",
    "#ff7f0e",
    "cadetblue",
    "darkcyan",
    "darkmagenta",
    "#8c564b",
    "cadetblue",
    "blueviolet",
    "coral"
  ],
  rotations: 2,
  rotationAngles: [0, 90],
  padding: 3,
  fontFamily: "IBM Plex Sans",
  fontSizes: [25, 60],
  fontWeight:"bold",
  deterministic: false, //same arrangement for any input
  maxWords: 120
};

const WordCloud = props => {
  const { className, WordCloudData } = props;
  
  const dispatch = useDispatchDesignList();

  const wordCloudRef = useRef(null);

  // const handleClose = e => {
  //     dispatchUiState({
  //       type: mainUiActions.SET_SHOW_WORDCLOUD,
  //       payload: false
  //     });
  // };


  useMount(() => {
    console.log("wordcloud rendered");
  });

  useEffect(() => {
    console.log("wordcloud useeffect");
  }, []);
  
const callbacks = {
  getWordColor: function(word) {
    //console.log("word.color", word.text, word.color)

    if (word.color && word.color !== "") return word.color;
    const wordColor =
      options.colors[Math.round(Math.random(options.colors.length) * options.colors.length)];
    return wordColor;
  },
  onWordClick: function(word){
    console.log(word);
    if(word && word.text && word.text!=='')
    dispatch({ type: designListActions.SET_TAG_FILTER, payload: word.text });
 
  },
  //onWordMouseOver: console.log,
  getWordTooltip: word => {
    return `${word.text} (${word.value})`;
  }
};

  return (
    <div className={classNames("bd-wordcloud", className)} ref={wordCloudRef}>
      {/* <p onClick={handleClose}>Close</p> */}
      {WordCloudData && (
        <ReactWordcloud
          callbacks={callbacks}
          options={options}
          words={WordCloudData.Tag_Designs}
          size={size}
        />
      )}
    </div>
  );
};

WordCloud.propTypes = {};

export default React.memo(WordCloud);
