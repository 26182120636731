/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ThumbList from "../../molecules/ThumbList";
import AppProvider from "../../../api/appProvider";
import { useDebouncedCallback } from "use-debounce/lib";
import { useVisualizationState } from "../../../reducers/visualizations.reducer";
import strings from "../../../strings";
import classNames from "classnames";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import { searchFileinTree, filterTreeFilesBySize, searchByTags } from "../../../utils/treeutils";
import { doesStringMatch } from "../../../utils/utils";
import { AtSpinner } from "../../atoms/AtSpinner";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";

const DesignSearchResults = props => {
  const { textToSearch = "", tagToSearch = "", type, handleThumbnailClick, id, hidden } = props;
  const { hasFilterBySize, searchInFolder } = window.flags.designListTree;

  const uiState = useUiState();
  const uiDispatch = useUiDispatch();
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const { selectedFile } = designListState;
  const VisualizationState = useVisualizationState();

  const [results, setResults] = useState([]);

  const [debouncedThumbnailClick] = useDebouncedCallback((node, e) => {
    if (!!handleThumbnailClick) handleThumbnailClick(node, e);
  }, 800);
  const [isLoading, setIsLoading] = useState(false);
  const onThumbnailClick = (node, e) => {
    if (!node.designProps) return;
    const activeNode = isDesignSearch(type) ? selectedFile : VisualizationState.activeNode;

    if (activeNode)
      if (node.id === activeNode.id) {
        console.info("clicked in active node");
        return;
      }
    uiDispatch({ type: mainUiActions.SET_SHOW_WORDCLOUD, payload: false });
    dispatchActiveNode(node, type);
    debouncedThumbnailClick(node, e);
  };

  const dispatchActiveNode = (node, type) => {
    dispatchDesignList({
      type: designListActions.SELECT_DESIGN,
      payload: { selectedFile: node }
    });
  };

  const isDesignSearch = () => {
    const isDesign = type === "design" ? true : false;
    return isDesign;
  };

  const showSearchResults = (textToSearch, tagFilter) => {
    let nonce = true;
    const startSearch = async () => {
      //let searchResults = await getSearchResults(textToSearch, tagToSearch)

      const searchText = textToSearch.trim();
      const { tree, sizeFilter } = designListState;
      if (!tree || !tree.length) return;
      let filteredDesigns;
      const compNodes = sizeFilter.filter(item => !!item.selected);
      // console.log("startSearch -> compNodes", compNodes, "  hasFilterBySize  ", hasFilterBySize)

      if (compNodes.length && hasFilterBySize) {
        filteredDesigns = filterTreeFilesBySize(tree, compNodes);
        // console.log("startSearch -> filteredDesigns", filteredDesigns)
      }
      const tagToSearch = tagFilter;

      if (tagToSearch !== "") {
        const tag_designs =
          window.WordCloudData && window.WordCloudData !== ""
            ? window.WordCloudData.Tag_Designs
            : "";

        if (tag_designs !== "") filteredDesigns = searchByTags(tree, tag_designs, tagToSearch);
      }

      let searchResults;
      if (filteredDesigns) {
        searchResults = filteredDesigns.filter(item => {
          const fstring = searchInFolder ? item.fullPath : item.name;
          const matchFolder = doesStringMatch(fstring, searchText);
          return matchFolder;
        });
      } else {
        if (searchText.length < 3) return;
        searchResults = searchFileinTree(tree, searchText, searchInFolder);
      }

      if (!searchResults.length) setResults(searchResults);
      else {
        setResults(searchResults);
        const filtered = searchResults.filter(item => !item.designProps);
        // console.log("startSearch -> filtered", filtered)
        if (filtered.length) {
          if (window.flags.designListTree.consolidateSearch) setIsLoading(true);
          try {
            const thumblist = await AppProvider.getDesignThumbnails({
              designs: filtered
            });
            if (nonce)
              thumblist.forEach(thumb => {
                const i = searchResults.findIndex(item => item.fullPath === thumb.fullPath);
                if (i !== -1) {
                  searchResults[i] = thumb;
                }
              });
          } catch (error) {}
        }
        if (window.flags.designListTree.consolidateSearch) {
          const uniqueThumblist = Array.from(
            new Set(searchResults.map(a => JSON.stringify(a.designProps)))
          ).map(propStr => {
            return searchResults.find(a => JSON.stringify(a.designProps) === propStr);
          });
          setResults(uniqueThumblist);
        } else {
          setResults(searchResults);
        }
        if (!uiState.showWordCloud && tagToSearch !== "") {
          dispatchActiveNode(searchResults[0]);
        }
        setIsLoading(false);
      }
    };
    startSearch();
    return () => {
      nonce = false;
    };
  };
  useEffect(() => {
    showSearchResults(textToSearch, tagToSearch);
  }, [textToSearch, designListState.sizeFilter, tagToSearch]);

  const getItemType = () => {
    const item = `${type}${results.length > 1 ? "s" : ""}`;
    // console.log(item, results.length, type);
    return item;
  };
  const getNoResultText = () => {
    let txt =
      type === "design"
        ? `${strings.search.noDesignFound.firstLine} <br/> ${strings.search.noDesignFound.secondLine}`
        : `${strings.search.noVisualizationFound.firstLine} <br/> ${strings.search.noVisualizationFound.secondLine}`;
    return txt;
  };
  return (
    <div className={classNames("at-search", { hidden: hidden })} id={id}>
      {isLoading ? (
        <span>
          Searching designs...
          <AtSpinner size="xs" />
        </span>
      ) : results.length ? (
        <>
          <span className="at-search__text">
            {" "}
            {`${strings.search.found} ${results.length} ${
              isDesignSearch()
                ? strings.design[getItemType()]
                : strings.visualization[getItemType()]
            }`}
          </span>
          <ThumbList
            thumbList={results}
            onThumbnailClick={onThumbnailClick}
            activeId={selectedFile.id}
            lazyDesignThumbnail={true}
          />
        </>
      ) : (
        <span
          className="at-search__no-results"
          dangerouslySetInnerHTML={{ __html: getNoResultText() }}
        ></span>
      )}
    </div>
  );
};

export default DesignSearchResults;
