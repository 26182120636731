import { Toast, Toaster } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import strings from "../../../strings";
import AtDropdown from "../../atoms/AtDropdown";

const SelectSizeDropdown = props => {
  const dispatchDesignDetails = useDispatchDesignDetail();
  const designDetailsState = useDesignDetailState();
  const [toastProps, setToastProps] = useState(false);
  const [designSizeOptions, setDesignSizeOptions] = useState(null);
  const [defaultIndex, setDefaultIndex] = useState(null);

  useEffect(() => {
    var unit = sessionStorage.getItem("unit");
    var sizeOptions = sessionStorage.getItem("sizeoption")?.split("|");
    sizeOptions = sizeOptions.map(item => {
      if (item.indexOf("x") === -1) {
        item = item + "x" + item;
      }
      return `${item} ${unit}`;
    });
    setDesignSizeOptions(sizeOptions);
    var size =
      sessionStorage.getItem("width") + "x" + sessionStorage.getItem("height") + " " + unit;
    setDefaultIndex(sizeOptions.findIndex(item => item === size));
  }, []);

  const handleSelectOptionChangeSize = value => {
    setDefaultIndex(designSizeOptions.findIndex(item => item === value));
    if (!value || value.toLowerCase().indexOf("x") === -1) {
      setToastProps(true);
    } else {
      var dimensions = value.split("x");
      if (!dimensions[0] || !dimensions[1]) {
        setToastProps(true);
      } else {
        window.initialData.designWidth = parseFloat(dimensions[0]);
        window.initialData.designHeight = parseFloat(dimensions[1]);
        window.sessionStorage.setItem("width", parseFloat(dimensions[0]));
        window.sessionStorage.setItem("height", parseFloat(dimensions[1]));
        //hash is changed for rerender as on window change above it doesnot rerender
        dispatchDesignDetails({
          type: designDetailActions.CHANGE_HASH,
          payload: designDetailsState.designDetails
        });
      }
    }
  };

  return (
    <div className="at-dialog-area at-selectsize-dialog-area">
      <div className="dropdown">
        {designSizeOptions && (
          <AtDropdown
            dropdownItems={designSizeOptions}
            defaultItemIndex={defaultIndex}
            onChange={i => handleSelectOptionChangeSize(designSizeOptions[i])}
          />
        )}
      </div>
      <Toaster position="bottom">
        {toastProps && (
          <Toast
            message={strings.toastMessages.selectSizeError}
            intent="danger"
            onDismiss={() => setToastProps(false)}
          />
        )}
      </Toaster>
    </div>
  );
};

SelectSizeDropdown.propTypes = {};

export default SelectSizeDropdown;
