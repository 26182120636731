import classNames from "classnames";
import React, { useState } from "react";
import AppProvider from "../../api/appProvider";
const BrandLogo = props => {
  const [showImage, setShowImage] = useState(false);
  const logoUrl = props.logourl
    ? props.logourl
    : `${AppProvider.domain}${window.InterfaceElements.LogoUrl}`;

  const handleClick = () => {
    //   AppAnalytics.sendLogoClick().then(status=>  {
    //     //console.log("onLogoClick -> status", status);
    // }
    //   ).catch(error=>{
    //     console.log(" AppAnalytics LogoClick ~ error", error)
    //   });
    if (window.InterfaceElements.CompanyUrl)
      window.open(window.InterfaceElements.CompanyUrl, "_blank");
  };

  return (
    <img
      src={logoUrl}
      alt="brand-logo"
      className={classNames("logo-image", { show: showImage })}
      onClick={handleClick}
      onLoad={e => {
        setShowImage(true);
        if (props.onLoad) props.onLoad();
      }}
      onError={event => {
        setShowImage(false);
        if (props.onError) props.onError();
        event.onerror = null;
      }}
    />
  );
};
export default BrandLogo;
