import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import "./_StudioEntry.scss";
import ReactGA from "react-ga";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { getCustomClass, getFromSessionStorage, handleNavigation } from "../../../utils/domUtils";
import { useHomeMount } from "../Home/hooks";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import Stage from "../../organisms/StageNew";
import strings from "../../../strings";
import RoomList from "../../organisms/RoomList";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import CopyrightSection from "../../molecules/CopyrightSection";
import { useInitDesignMount } from "../../../hooks/initDesignListHook";
import { findSimilarDesigns } from "../../../utils/treeutils";
import {
  useDispatchVisualization,
  useVisualizationState,
  visualizationsActions,
  visViewModes
} from "../../../reducers/visualizations.reducer";
import AppProvider from "../../../api/appProvider";
import AtIcon from "../../atoms/AtIcon";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { getDesignDimensionsString } from "../../../utils/utils";
import QRDialog from "../../organisms/QRDialog";
import { useSwipeable } from "react-swipeable";
import { AtSpinnerOverlay } from "../../atoms/AtSpinner";

var scrollBarsToggle = {
  illustrations: false
};
var roomContainerClassName = ".at-vis-thumbs";
var isInitialLoad = true;
const StudioEntry = props => {
  const dispatchDesignList = useDispatchDesignList();
  const visualizationState = useVisualizationState();
  const visualizationDispatch = useDispatchVisualization();
  const designDetailsState = useDesignDetailState();
  const dispatchDesignState = useDispatchDesignDetail();
  const { designDetails, designName, fullpath } = designDetailsState;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const state = useDesignListState();
  const [showScrollBars, setShowScrollBars] = useState(scrollBarsToggle);
  const [hasVariations, setHasVariations] = useState(true);
  const refCreateYourRug = useRef(null);
  const [detailViewsOptions, setDetailViewsOptions] = useState(
    window.flags.createYourRug.detailViewsOptions
  );
  const [currentDetailView, setCurrentDetailView] = useState(
    sessionStorage.getItem("defaultDesignView") || window.flags.createYourRug.defaultDesignView //url link priority first,then flags
  );
  const refRoomViewContainer = useRef(null);
  const [hasInitialScrollDiv, setHasInitialScrollDiv] = useState(true);
  const [showRightColumn, setShowRightColumn] = useState(false);
  const [dimensions, setDimensions] = useState("");
  const { createYourRug: cyrFlags, language, designDetailSection } = window.flags;

  let langFromUrl = getFromSessionStorage("lang");
  let defLang = langFromUrl && langFromUrl !== "" ? langFromUrl : "en";
  const hasLangOptions = language.langOptions.length && language.langOptions[0] !== "";
  const [currentLanguage, setCurrentLanguage] = useState(defLang);
  const [isCinematic, setisCinematic] = useState(sessionStorage.getItem("cinematic") || false);

  const { initDesignColors, initDesignPath } = window.initialData;
  const { useOriginalName } = window.flags.designVariations;
  const [similarDesigns, setSimilarDesigns] = useState({ files: [], children: [] });
  const [isSwipeInProgress, setIsSwipeInProgress] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useHomeMount();
  useInitDesignMount();
  const [render, setRender] = useState(false);
  const [loadingDesign, setLoadingDesign] = useState(true);
  const [removeArrowNoVariation, setRemoveArrowNoVariation] = useState(false);
  const [removeArrowRoomNoVariation, setRemoveArrowRoomNoVariation] = useState(false);
  const [isNextDesignClicked, setIsNextDesignClicked] = useState(true);

  useEffect(() => {
    if (designDetailsState.loading) return;
    setLoadingDesign(false);
    dispatchUiState({
      type: mainUiActions.SET_SHOW_LOAD_OVERLAY,
      payload: false
    });
  }, [designDetailsState.loading]);
  const [currentImagePosition, setCurrentImagePosition] = useState(1);
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  useEffect(() => {
    if (state.selectedFile.fullPath == undefined) return;
    setRender(false);

    async function get1xDesign() {
      await AppProvider.get1xDesign({
        file: state.selectedFile.fullPath
      }).then(getDesign => {
        fetch(getDesign).then(() => {
          if (currentImagePosition == 1) {
            document.getElementById("currentImage2").style.removeProperty("animation");
            setCurrentImagePosition(2);
            if (!image2) {
              document.getElementById("currentImage2").src = getDesign;
            } else {
              setImage2(image2);
            }
            setImage1(getDesign);
            document.getElementById("currentImage1").src = getDesign;
            setTimeout(() => {
              document.getElementById("currentImage1").style.animation = "fadeIn 3s";
              document.getElementById("currentImage1").style.zIndex = 2;
              document.getElementById("currentImage2").style.zIndex = 1;
            }, 1000);
          } else {
            document.getElementById("currentImage1").style.removeProperty("animation");
            setCurrentImagePosition(1);
            setImage1(image1);
            setImage2(getDesign);
            document.getElementById("currentImage1").src = image1;
            document.getElementById("currentImage2").src = getDesign;
            setTimeout(() => {
              document.getElementById("currentImage2").style.animation = "fadeIn 3s";
              document.getElementById("currentImage1").style.zIndex = 1;
              document.getElementById("currentImage2").style.zIndex = 2;
            }, 1000);
          }
        });
      });
    }
    get1xDesign();
    async function getSimilarDesigns() {
      if (isNextDesignClicked) {
        if (state.similarDesignsFolder.files.length != 0) {
          setRemoveArrowNoVariation(false);
          let resVar = state.similarDesignsFolder.files;
          const index = resVar.findIndex(obj => obj.id === state.selectedFile.id);
          if (index == -1) {
            resVar.push(state.selectedFile);
          }
          //since we are rearranging the files, name should be set to null initially
          setSimilarDesigns({
            ...similarDesigns,
            files: resVar.map(item => ({ ...item, name: null }))
          });
        } else {
          setRemoveArrowNoVariation(true);
        }
      }
    }
    getSimilarDesigns();
  }, [state.selectedFile]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].id = "studioentry";
    dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 30 });
    if (window.TextureOptions?.AdditionalTextureNames?.indexOf("Back") !== -1) {
      setDetailViewsOptions([
        visViewModes.DESIGN_VIEW_DEEPZOOM,
        visViewModes.DESIGN_VIEW_PERSPECTIVE,
        visViewModes.DESIGN_VIEW_FOLDEDBACK
      ]);
    }

    let scrollTo = sessionStorage.getItem("scrollTo") || "";
    if (scrollTo) setHasInitialScrollDiv(true);
    setTimeout(() => {
      let illustrations = scrollBarsToggle.illustrations;
      const elem = document.querySelector(roomContainerClassName);
      if (elem && elem.clientWidth < elem.scrollWidth) {
        illustrations = true;
      }
      setShowScrollBars({ ...showScrollBars, illustrations });
      const elemVariations = document.querySelector("#atDesignVariations");
      elemVariations ? setHasVariations(true) : setHasVariations(false);
      if (scrollTo !== "") {
        scrollIntoView("#" + scrollTo);
      }
      setHasInitialScrollDiv(false);
    }, 3000);
    setTimeout(() => {
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 60 });
    }, 1500);
  }, []);

  useEffect(() => {
    if (!state.selectedFile) return;
    if (state && state.selectedFile && state.selectedFile.variations) {
      setHasVariations(true);
      if (
        state.selectedFile.variations?.colors?.length === 0 &&
        state.selectedFile.variations?.shapes?.length === 0
      ) {
        setHasVariations(false);
      }
    } else {
      setHasVariations(false);
    }
  }, [state.selectedFile]);

  useEffect(() => {
    if (!designDetails) return;
    const isIrregular = designDetails.IsIrregular;
    if (
      isIrregular &&
      detailViewsOptions[currentDetailView] === visViewModes.DESIGN_VIEW_FOLDEDBACK
    ) {
      dispatchUiState({
        type: mainUiActions.SET_TOAST_PROPS,
        payload: {
          message: "Sorry, folded view is unavailable for this design.",
          intent: "warning"
        }
      });
      setCurrentDetailView(0);
    }
  }, [designDetails]);

  useEffect(() => {
    if (visualizationState.roomFolders.length == 0) return;
    if (visualizationState.roomFolders[0].files.length == 1) {
      setRemoveArrowRoomNoVariation(true);
    }
    if (visualizationState.similarRooms == 0) {
      visualizationDispatch({
        type: visualizationsActions.SET_SIMILAR_ROOMS,
        payload: visualizationState.roomFolders[0].files
      });
    }
  }, [visualizationState.roomFolders]);

  useEffect(() => {
    if (!visualizationState.activeNode) return;
    var thumb = document
      .querySelector(".designThumb-template0.thumb-item.active")
      ?.getBoundingClientRect();
    var container = document.querySelector(roomContainerClassName);
    if (thumb && container) {
      if (thumb.left > container?.clientWidth || thumb.left < 0) {
        container.scrollLeft = thumb.left;
      }
    }
  }, [visualizationState.activeNode]);

  useEffect(() => {
    if (visualizationState.viewMode == visViewModes.MY_ROOM) return;
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: detailViewsOptions[currentDetailView]
    });
  }, [currentDetailView]);

  const getDesProps = defaultVar => {
    if (initDesignColors)
      return {
        ...defaultVar,
        designProps: { ...defaultVar.designProps, DesignColors: initDesignColors }
      };
    else return defaultVar;
  };

  const handleThumbClick = details => {
    const { selectedFile } = state;
    const { name: originalName } = state.selectedFile;
    const { designProps, name, fullPath } = details;
    let designDetails;
    if (initDesignPath !== fullPath) {
      designDetails = designProps;
    } else {
      designDetails = getDesProps(details).designProps;
    }
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked Design Variation", //required
      eventLabel: `Clicked on ${fullPath}`
    });
    const useOrigName = fullPath === selectedFile.fullPath || useOriginalName;
    dispatchDesignState({
      type: designDetailActions.SET_DETAILS,
      payload: {
        designDetails,
        label: useOrigName ? originalName : name,
        fullpath: fullPath,
        originalName,
        setCustomConversion: window.flags.ordersheet.customConversion.setCustomConversion || false,
        customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
      }
    });
  };

  const onThumbnailClick = (node, e) => {
    if (!node.designProps) return;
    if (state.selectedFile && node.id === state.selectedFile.id) {
      console.info("clicked in active design thumbnail");
      return;
    }

    let requiredVariation = {};
    if (window.flags.designVariations.preserveVariations) {
      if (state.activeVariation.id) {
        const similarDesigns = findSimilarDesigns(state.tree, node);
        if (similarDesigns) {
          const va = state.activeVariation.name.replace(state.selectedFile.name, "");
          if (va)
            requiredVariation = similarDesigns.files.find(file => {
              if (file.name.includes(va)) return true;
              return false;
            });
        }
      }
    }

    dispatchDesignList({
      type: designListActions.SELECT_DESIGN,
      payload: { selectedFile: node, activeVariation: requiredVariation }
    });

    setTimeout(() => {
      const scrollToDiv = cyrFlags.scrollToDesignViewAfterCollectionOnClick
        ? ".stage-wrapper.deepzoom"
        : "#room-view-container";
      scrollIntoView(scrollToDiv);
    }, 1000);
  };
  useEffect(() => {
    if (!hasInitialScrollDiv) {
      if (isInitialLoad) {
        //do not scroll to designview if it's initial load.
        isInitialLoad = false;
        return;
      }
      setTimeout(() => {
        const scrollToDiv = cyrFlags.scrollToDesignViewAfterVariationOnClick
          ? ".stage-wrapper.deepzoom"
          : "#room-view-container";
        scrollIntoView(scrollToDiv);
      }, 1000);
    }
  }, [state.activeVariation]);

  const handleLanguageOptionChange = i => {
    const newLanguage = language.langOptions[i].val;
    setCurrentLanguage(newLanguage);
    dispatchUiState({ type: mainUiActions.SET_LANGUAGE, payload: newLanguage });
    strings.setLanguage(newLanguage);
    if (langFromUrl && langFromUrl !== "") sessionStorage.setItem("lang", newLanguage);
  };

  const getCurrentLanguageText = () => {
    var index = language.langOptions.findIndex(item => item.val === currentLanguage);
    const currentLanguageText = language.langOptions[index].text;
    return currentLanguageText;
  };

  const handleOpenMyroom = () => {
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked My Room", //required
      eventLabel: "Clicked on My Room"
    });
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.MY_ROOM
    });

    scrollIntoView("#room-view-container");
    // var url = window.location + "&initview=myroom";
    // if (state.selectedFile) {
    //   url = url + "&initdesign=" + state.selectedFile.fullPath;
    // }
    // window.open(url, "_blank");
  };

  const scrollIntoView = querySelector => {
    if (querySelector) {
      const elem = document.querySelector(querySelector);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      } else {
        console.log(querySelector, " not found");
      }
    }
  };
  const handleNavigator = ({ querySelector, direction }) => {
    if (!querySelector || !direction) return;
    const elem = document.querySelector(querySelector);
    handleNavigation({ elem, direction });
  };

  const changeViewIndex = (direction, currentDetailView, viewsOptions) => {
    let newcurrentDetailView =
      direction === "right" ? currentDetailView + 1 : currentDetailView - 1;
    newcurrentDetailView =
      newcurrentDetailView > viewsOptions.length - 1 ? 0 : newcurrentDetailView;
    newcurrentDetailView =
      newcurrentDetailView < 0 ? viewsOptions.length - 1 : newcurrentDetailView;
    return newcurrentDetailView;
  };

  const rugshareCondition = window.InterfaceElements.ShowRugshare && window.flags.hasRugshare;

  const openNextDetailView = ({ direction = "right" }) => {
    // const imageDiv = document.getElementById("imageDiv");
    // imageDiv.classList.remove("fadeInAnimation");
    // document.getElementById("rendered1xDesign3").style.display = "block";
    setIsNextDesignClicked(true);
    setLoadingDesign(true);
    var currentDesignIndex = state.tree[0].files.findIndex(obj => obj.id === state.selectedFile.id);
    if (currentDesignIndex == -1) {
      currentDesignIndex = state.tree[0].files.findIndex(
        obj => obj.name === state.selectedFile.location.split(".")[1]
      );
    }
    var newDesignViewIndex = changeViewIndex(direction, currentDesignIndex, state.tree[0].files);

    const newDesign = state.tree[0].files[newDesignViewIndex];
    AppProvider.getDesignThumbnails({
      designs: [{ fullPath: newDesign.fullPath }]
    }).then(newDesignDetails => {
      const mergedDesignDetails = {
        ...newDesign,
        ...newDesignDetails[0]
      };
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: {
          selectedFile: mergedDesignDetails,
          activeVariation: {}
        }
      });
    });
  };
  const showNextVariation = ({ direction = "right" }) => {
    setLoadingDesign(true);
    setIsNextDesignClicked(false);
    const currentDesignIndex = similarDesigns.files.findIndex(
      obj => obj.id === state.selectedFile.id
    );
    var newDesignViewIndex = changeViewIndex(direction, currentDesignIndex, similarDesigns.files);
    const newDesign = similarDesigns.files[newDesignViewIndex];
    AppProvider.getDesignThumbnails({
      designs: [{ fullPath: newDesign.fullPath }]
    }).then(newDesignDetails => {
      const mergedDesignVariationDetails = {
        ...newDesign,
        ...newDesignDetails[0]
      };
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: {
          selectedFile: mergedDesignVariationDetails,
          activeVariation: {}
        }
      });
    });
  };

  const showNextRoom = async ({ direction }) => {
    const tempGetMainRoomFolder = visualizationState.activeNode.FullPath.split("/");
    tempGetMainRoomFolder.pop();
    const getMainRoomFolder = tempGetMainRoomFolder.join("/");
    const currentRoomIndex = visualizationState.roomFolders.findIndex(
      obj => obj.FullPath == getMainRoomFolder
    );

    var newRoomViewIndex = changeViewIndex(
      direction,
      currentRoomIndex,
      visualizationState.roomFolders
    );
    const newRoom = visualizationState.illustrations.find(
      obj => obj.FullPath == visualizationState.roomFolders[newRoomViewIndex].files[0]
    );
    const roomWithThumbs = await AppProvider.getRoomThumbnails({ rooms: [newRoom] });
    visualizationDispatch({
      type: visualizationsActions.SET_ACTIVE_NODE,
      payload: roomWithThumbs[0]
    });
    visualizationDispatch({
      type: visualizationsActions.SET_SIMILAR_ROOMS,
      payload: visualizationState.roomFolders[newRoomViewIndex].files
    });

    if (visualizationState.roomFolders[newRoomViewIndex].files.length == 1) {
      setRemoveArrowRoomNoVariation(true);
    } else {
      setRemoveArrowRoomNoVariation(false);
    }
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.ROOM_VIEW
    });
  };
  const showSameRoomVariation = async ({ direction }) => {
    const currentRoomIndex = visualizationState.similarRooms.findIndex(
      obj => obj == visualizationState.activeNode.FullPath
    );
    var newRoomViewIndex = changeViewIndex(
      direction,
      currentRoomIndex,
      visualizationState.similarRooms
    );
    const newRoom = visualizationState.illustrations.find(
      obj => obj.FullPath == visualizationState.similarRooms[newRoomViewIndex]
    );
    const roomWithThumbs = await AppProvider.getRoomThumbnails({ rooms: [newRoom] });
    visualizationDispatch({
      type: visualizationsActions.SET_ACTIVE_NODE,
      payload: roomWithThumbs[0]
    });
    visualizationDispatch({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.ROOM_VIEW
    });
  };
  const handleToggleSidebar = () => {
    setShowRightColumn(!showRightColumn);
  };

  const handleCinematic = () => {
    setisCinematic(true);
  };

  const changeRoomHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isSwipeInProgress && !designDetailsState.loading && !isFullScreen) {
        setIsSwipeInProgress(true);
        showNextRoom({ direction: "right" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedRight: () => {
      if (!isSwipeInProgress && !designDetailsState.loading && !isFullScreen) {
        setIsSwipeInProgress(true);
        showNextRoom({ direction: "left" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedUp: () => {
      if (
        !isSwipeInProgress &&
        !designDetailsState.loading &&
        !removeArrowRoomNoVariation &&
        !isFullScreen
      ) {
        setIsSwipeInProgress(true);
        showSameRoomVariation({ direction: "left" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedDown: () => {
      if (
        !isSwipeInProgress &&
        !designDetailsState.loading &&
        !removeArrowRoomNoVariation &&
        !isFullScreen
      ) {
        setIsSwipeInProgress(true);
        showSameRoomVariation({ direction: "right" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    swipeDuration: 2000,
    preventScrollOnSwipe: true,
    trackMouse: false
  });
  const changeDesignHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isSwipeInProgress && !designDetailsState.loading) {
        setIsSwipeInProgress(true);
        openNextDetailView({ direction: "right" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedRight: () => {
      if (!isSwipeInProgress && !designDetailsState.loading) {
        setIsSwipeInProgress(true);
        openNextDetailView({ direction: "left" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedUp: () => {
      if (!isSwipeInProgress && !designDetailsState.loading && !removeArrowNoVariation) {
        setIsSwipeInProgress(true);
        showNextVariation({ direction: "left" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    onSwipedDown: () => {
      if (!isSwipeInProgress && !designDetailsState.loading && !removeArrowNoVariation) {
        setIsSwipeInProgress(true);
        showNextVariation({ direction: "right" }); // Reset the flag after 2 seconds (or your desired duration)
        setTimeout(() => {
          setIsSwipeInProgress(false);
        }, [1500]);
      }
    },
    swipeDuration: 2000,
    preventScrollOnSwipe: true,
    trackMouse: false
  });

  useEffect(() => {
    if (Object.keys(designDetails).length === 0) return;
    setDimensions(
      getDesignDimensionsString({
        designDetails: designDetails,
        showDesignDimensions: designDetailSection.showDesignDimensions,
        showOneDimensionIfSquare: designDetailSection.showOneDimensionIfSquare,
        showDimensionInInches: designDetailSection.showDimensionInInches
      })
    );
  }, [designDetails]);

  return (
    <>
      <Icons />
      <QRDialog />
      {uiState.showLoadingOverlay && !window.InterfaceElements.IsAdmin && (
        <div id="loadingDiv">
          <img
            id="loadingIcon"
            src="https://emotion.explorug.com/loading.webp"
            loading="lazy"
            alt="wool_loading"
          />
        </div>
      )}
      <div
        ref={refCreateYourRug}
        id="app-main-studioEntry"
        className={classNames("app-main multiple-mode", getCustomClass())}
      >
        <div {...changeDesignHandlers}>
          <div className="cyr-section cyr-detailView">
            <div className="stage-wrapper deepzoom " id="design-view-container">
              <img src="" alt="" className="rendered1xDesign1" id="currentImage1" />
              <img src="" alt="" className="rendered1xDesign1" id="currentImage2" />
              {!window.initialData.hideLoadingOverlay ? (
                <AtSpinnerOverlay show={loadingDesign} />
              ) : null}
              {/* <Stage defaultViewMode="DESIGN_VIEW_ZERO_ZOOM" /> */}
              {/* {designDetailsState.loading && <AtSpinnerOverlay show="true" />} */}
              {/* <img src="" alt="" id="rendered1xDesign" onLoad={applyFadeInAnimation} />
              <img src="" alt="" id="rendered1xDesign1" /> */}
              {/* <img src="" alt="" id="rendered1xDesign3" className="" /> */}
              {/* <img
                src={prevImg}
                alt=""
                id="rendered1xDesign3"
                style={{ display: "none" }}
                className="fade fade-out"
              /> */}
              {/* <div id="imageDiv">
              </div>
              <div id="imageDiv1">
              </div> */}
              {!designDetailsState.loading && (
                <div className="cyr-arrows-container">
                  <AtIcon
                    className="navigator-arrows right"
                    icon="chevron-right"
                    onClick={() => openNextDetailView({ direction: "right" })}
                    color="#F05223"
                  />
                  <AtIcon
                    className="navigator-arrows left"
                    icon="chevron-left"
                    onClick={() => openNextDetailView({ direction: "left" })}
                    color="#F05223"
                  />
                  {!removeArrowNoVariation && (
                    <>
                      <AtIcon
                        className="navigator-arrows up"
                        icon="chevron-up"
                        onClick={() => showNextVariation({ direction: "right" })}
                        color="#F05223"
                      />
                      <AtIcon
                        className="navigator-arrows down"
                        icon="chevron-down"
                        onClick={() => showNextVariation({ direction: "left" })}
                        color="#F05223"
                      />
                    </>
                  )}
                </div>
              )}
              <div className="cyr-overlay left"></div>
              <div className="cyr-overlay right"></div>
            </div>
          </div>
        </div>
        <div {...changeRoomHandlers}>
          <div className="cyr-roomViewContainer" ref={refRoomViewContainer}>
            <div className="stage-wrapper deepzoom" id="room-view-container-studioEntry">
              <Stage
                defaultViewMode={
                  visualizationState.viewMode === visViewModes.MY_ROOM
                    ? visViewModes.MY_ROOM
                    : visViewModes.ROOM_VIEW
                }
                setIsFullScreen={setIsFullScreen}
              />
              {!designDetailsState.loading && (
                <div className="cyr-arrows-container">
                  <AtIcon
                    className="navigator-arrows right rightRoom"
                    icon="chevron-right"
                    onClick={() => showNextRoom({ direction: "right" })}
                    color="#F05223"
                  />
                  <AtIcon
                    className="navigator-arrows left leftRoom"
                    icon="chevron-left"
                    onClick={() => showNextRoom({ direction: "left" })}
                    color="#F05223"
                  />
                  {!removeArrowRoomNoVariation && (
                    <>
                      <AtIcon
                        className="navigator-arrows up topRoom"
                        icon="chevron-up"
                        onClick={() => showSameRoomVariation({ direction: "right" })}
                        color="#F05223"
                      />
                      <AtIcon
                        className="navigator-arrows down downRoom"
                        icon="chevron-down"
                        onClick={() => showSameRoomVariation({ direction: "left" })}
                        color="#F05223"
                      />
                    </>
                  )}
                </div>
              )}
            </div>

            {/* <SidebarActionButtons
                isFullScreen={isRoomviewInFullScreen}
                handleFullScreen={handleFullScreen}
                handleCinematic={handleCinematic}
              /> */}
          </div>
        </div>

        <div className="cyr-section cyr-copyright">
          <CopyrightSection />
        </div>
        <div className="illustration-wrapper">
          <RoomList scrollIntoView={scrollIntoView} />

          {showScrollBars && showScrollBars.illustrations && (
            <div className="cyr-arrows-container">
              <AtIcon
                className="navigator-arrows right"
                icon="chevron-right"
                onClick={() =>
                  handleNavigator({ querySelector: roomContainerClassName, direction: "right" })
                }
              />
              <AtIcon
                className="navigator-arrows"
                icon="chevron-left"
                onClick={() =>
                  handleNavigator({ querySelector: roomContainerClassName, direction: "left" })
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

StudioEntry.propTypes = {};

export default StudioEntry;
