import React, { useEffect, useState } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import { H5 } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import classNames from "classnames";
import AtIcon from "../../atoms/AtIcon";
var QRCode = require("qrcode.react");

const ArCarpetDialog = props => {
  const { qrForARUrl } = props;
  const dispatchUiState = useUiDispatch();
  const [showAppStoreQR, setShowAppStoreQR] = useState(false);
  const uiState = useUiState();
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_ARCARPET_INFO_DIALOG, payload: false });
    setShowAppStoreQR(false);
  };
  useEffect(() => {
    if (!uiState.showArCarpetInfoDialog) return;
  }, [uiState.showArCarpetInfoDialog]);
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showArCarpetInfoDialog}
      className="at-arcarpet-dialog"
      size="sm"
    >
      <div className="at-dialog-area ">
        <AtButton
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon="close"
        />
        <H5 className="at-dialog-heading">{"See it live in your room"}</H5>
        <div className="at-dialog-content ar-dialog-content">
          <div className={classNames("ar-scan-wrapper", { hidden: showAppStoreQR })}>
            {qrForARUrl && (
              <div
                className="qr-code"
                onClick={() => {
                  window.location.href = qrForARUrl;
                  // setTimeout(function() {
                  //   window.location.href =
                  //     isAppleDevice || isIos
                  //       ? "https://apps.apple.com/np/app/ar-carpet/id1602660181"
                  //       : "https://play.google.com/store/apps/details?id=com.alternative.atarcarpet";
                  // }, 1000);
                }}
              >
                <QRCode
                  value={qrForARUrl}
                  size={128}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                />
              </div>
            )}
            <span className="scan-text">
              <div className="bp3-form-helper-text">
                This feature requires AR Carpet app. If you have the app installed on your phone,
                scan or tap this QR code to view this design live in your room.
                <br />
                <div
                  className="no-ar-app-title"
                  onClick={() => {
                    setShowAppStoreQR(!showAppStoreQR);
                  }}
                >
                  If not, click here to install the app.
                </div>
              </div>
            </span>
          </div>

          <div className="no-ar-app">
            <div className={classNames("no-ar-app-content", { show: showAppStoreQR })}>
              <div className="bp3-form-helper-text">
                If you do not have AR Carpet app. You can install it from:
              </div>
              <div className="ar-app-store-area">
                <span className="ar-app-store">
                  {" "}
                  <a
                    href="https://apps.apple.com/np/app/ar-carpet/id1602660181"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <AtIcon icon={"apple"} /> App store
                    <QRCode
                      value={"https://apps.apple.com/np/app/ar-carpet/id1602660181"}
                      size={128}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"L"}
                    />
                  </a>
                </span>
                <span className="ar-stores-separator"> or </span>
                <span className="ar-play-store">
                  {" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.alternative.atarcarpet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AtIcon icon={"android"} />
                    Play Store
                    <QRCode
                      value={
                        "https://play.google.com/store/apps/details?id=com.alternative.atarcarpet"
                      }
                      size={128}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"L"}
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="at-confirmation-btns-area">
            <AtButton intent="primary" onClick={handleCloseDialog} text={strings.tools.close} />
          </div>
        </div>
      </div>
    </AtDialog>
  );
};

export default ArCarpetDialog;
