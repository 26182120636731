import React, { useEffect, useState } from "react";
import draftToHtml from "draftjs-to-html";
import AtDialog from "../../molecules/AtDialog";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import AtButton from "../../atoms/AtButton";
import { H5 } from "@blueprintjs/core";
import strings from "../../../strings";

const FolderStoryDialog = ({ story = "", portalContainerId = "app-main" }) => {
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const [isOpen, setIsOpen] = useState(uiState.showFolderStoryDialog);

  const [storyHtml, setStoryHtml] = useState(null);

  const handleCloseDialog = () => {
    setIsOpen(false);
    dispatchUiState({ type: mainUiActions.SET_SHOW_FOLDER_STORY_DIALOG, payload: false });
  };
  useEffect(() => {
    setIsOpen(uiState.showFolderStoryDialog);
    if (!uiState.showFolderStoryDialog) return;
  }, [uiState.showFolderStoryDialog]);

  useEffect(() => {
    let html = "";
    if (story && story !== "") {
      html = draftToHtml(story);
    }
    setStoryHtml(html);
  }, [story]);

  return (
    <AtDialog
      portalContainer={document.getElementById(portalContainerId)}
      onClose={handleCloseDialog}
      isOpen={isOpen}
      className="at-confirmation-dialog folder-story-dialog"
    >
      <div className="at-dialog-area at-confirmation-dialog-area">
        <AtButton
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon="close"
        />
        <H5 className="at-dialog-heading">{strings.design.aboutTheCollection}</H5>
        <div className="at-dialog-content" dangerouslySetInnerHTML={{ __html: storyHtml }}></div>
      </div>
    </AtDialog>
  );
};

export default FolderStoryDialog;
