import React from "react";
import { Classes, Icon, Collapse, H6 } from "@blueprintjs/core";
import classNames from "classnames";
import DesignThumblist from "../DesignThumblist";
import strings from "../../../strings";
import AtIcon from "../../atoms/AtIcon";
const TreeNode = props => {
  const {
    node,
    onClick,
    onCollapse,
    onExpand,
    onThumbnailClick,
    children,
    renderThumbnails = true, //forwarded prop
    renderCaret = true, //forwarded prop
    selectedFile,
    handleInfoCircleClick
  } = props;
  const {
    children: childNodes,
    disabled = false,
    showThumbnails,
    isExpanded,
    isSelected,
    name,
    level,
    secondaryLabel,
    files,
    story
  } = node;

  const eventHandlers = disabled
    ? {}
    : {
        onClick
        // onContextMenu,
        // onDoubleClick,
        // onMouseEnter,
        // onMouseLeave
      };

  const maybeRenderCaret = () => {
    const hasCaret = childNodes.length || files.length;
    if (hasCaret && renderCaret) {
      const caretClasses = `${Classes.TREE_NODE_CARET} ${
        isExpanded ? Classes.TREE_NODE_CARET_OPEN : Classes.TREE_NODE_CARET_CLOSED
      }`;
      return (
        <Icon
          className={caretClasses}
          onClick={e => {
            e.stopPropagation();
            if (!disabled)
              if (isExpanded) onCollapse(e);
              else onExpand(e);
          }}
          icon={"chevron-down"}
        />
      );
    }
    return <span className={Classes.TREE_NODE_CARET_NONE} />;
  };
  const maybeRenderInfo = () => {
    if (window.flags.interface.showStory.folder && story && story !== "" && handleInfoCircleClick) {
      return (
        <AtIcon
          className="at-folder-story-icon"
          icon="info-circle"
          onClick={e => {
            e.stopPropagation();
            if (handleInfoCircleClick) handleInfoCircleClick(story);
          }}
        ></AtIcon>
      );
    }
    return <></>;
  };

  const maybeRenderSecondaryLabel = () => {
    if (secondaryLabel != null) {
      return <span className={Classes.TREE_NODE_SECONDARY_LABEL}>{secondaryLabel}</span>;
    } else {
      return undefined;
    }
  };
  const maybeRenderThumbNails = () => {
    if (!isExpanded || !showThumbnails || !renderThumbnails || !files.length) return undefined;
    return (
      <DesignThumblist
        files={files}
        onThumbnailClick={onThumbnailClick}
        selectedFile={selectedFile}
      />
    );
  };
  const contentClasses = classNames(
    Classes.TREE_NODE_CONTENT,
    `${Classes.TREE_NODE_CONTENT}-${level}`,
    { active: isSelected }
  );
  const handleClick = e => {
    if (isExpanded && showThumbnails) onCollapse(e);
    else if (isExpanded && files.length && showThumbnails) onExpand(e);
    else onExpand(e);
  };
  const getName = () => {
    var nodeName = name;
    if (!window.flags.language.translateNames) return name;
    if (strings.names && strings.names.hasOwnProperty(name.toUpperCase())) {
      nodeName = strings.names[name.toUpperCase()] || name;
    }
    return nodeName;
  };
  return (
    <li
      className={classNames(Classes.TREE_NODE, `${Classes.TREE_NODE}-${level}`)}
      datatitle={getName() || ""}
    >
      <div className={contentClasses} {...eventHandlers} onClick={handleClick}>
        <div
          className={classNames(Classes.TREE_NODE, "tree-node-content-inside", {
            active: isSelected
          })}
        >
          {maybeRenderCaret()}
          {maybeRenderInfo()}
          <span className={Classes.TREE_NODE_LABEL}>
            <H6> {getName()}</H6>
          </span>
          {maybeRenderSecondaryLabel()}
        </div>
      </div>
      {maybeRenderThumbNails()}
      <Collapse isOpen={isExpanded}>{children}</Collapse>
    </li>
  );
};

export default TreeNode;
