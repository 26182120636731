import { useEffect } from "react";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import {
  useDispatchDesignList,
  designListActions,
  useDesignListState
} from "../../../reducers/designlist.reducer";
import AppProvider from "../../../api/appProvider";
import { useDispatchColorList, colorListActions } from "../../../reducers/colors.reducer";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import {
  arrangeTree,
  findInTree,
  getDesignName,
  getPathOffile,
  searchExactFileinTree
} from "../../../utils/treeutils";
import { decodeColorsFromString, errorTypes, getExtension } from "../../../utils/utils";
import uuid from "uuid";
import {
  useVisualizationState,
  useDispatchVisualization,
  visualizationsActions,
  visViewModes
} from "../../../reducers/visualizations.reducer";
import { getFromSessionStorage } from "../../../utils/domUtils";
import { processDesignPath } from "../../../utils/treeutils";
import strings from "../../../strings";

export const useHomeMount = () => {
  const dispatchUiState = useUiDispatch();
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchColorListState = useDispatchColorList();
  const visState = useVisualizationState();
  const dispatchVisState = useDispatchVisualization();
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  let {
    initDesignPath,
    eCatalogDetails,
    initRoomPath,
    initDesignFolder,
    triggeredDesign,
    borderDesignWid,
    borderDesignHgt,
    unit,
    designWidth,
    designHeight,
    props
  } = window.initialData;
  const showAllRooms = window.flags.visualizations.showAllRoomFiles;
  useEffect(() => {
    var url =
      window.location !== window.parent.location ? document.referrer : document.location.href;

    // console.log(window.location !== window.parent.location, url, document.referrer);

    AppProvider.fetchColorList({}).then(colors => {
      if (colors && colors.length) {
        if (
          window.flags.colorSelectionBox.showColorPomsImages ||
          window.flags.colorAreaSwatch.showColorPomsImages
        ) {
          var colorListStr = "";
          colors.forEach(colorList => {
            const colorRows = colorList.ColorRows;
            const colorHexList = colorRows.map(colorRow => colorRow.Color);
            for (let i = 0; i < colorHexList.length; i++) {
              colorListStr += colorHexList[i].replace("#", "") + "|";
            }
          });
          colorListStr = colorListStr.slice(0, -1);
          AppProvider.createColorPoms(colorListStr).then(response => {});
        }
      }
      dispatchColorListState({ type: colorListActions.SET_COLORS, payload: colors });
    });
    const { TextureOptions } = window;
    const texArr = [];
    TextureOptions.DefaultTextureVisible.forEach((tex, i) => {
      if (tex) texArr.push(i);
    });
    TextureOptions.AdditionalTextureNames.forEach((item, i) => {
      texArr.push(4 + i);
    });
    TextureOptions.CustomTextureIndices.forEach(item => {
      texArr.push(item);
    });
    AppProvider.readTextureImages(texArr).then(colorTextures => {
      dispatchColorListState({ type: colorListActions.SET_COLOR_TEXTURES, payload: colorTextures });
    });
    if (eCatalogDetails && initDesignPath) {
      const p = initDesignPath.split("/").filter(item => item && item !== "");
      const designNamewithExt = p[p.length - 1];
      const designNamewithExtArr = designNamewithExt.split(".");
      let designName = designNamewithExtArr.slice(0, designNamewithExtArr.length - 1).join(".");

      // const name = initDesignPath.split("/").find(item => item.substr(0, 1) === ".");
      // if (name && !window.flags.designVariations.appendDesignName) {
      //   designName = `${name.substr(1)} ${designName}`;
      // }

      p.pop();
      const path = "/" + p.join("/");
      AppProvider.fetchDesignList({ struct: true }).then(nestedDesignList => {
        const initDesignName = window.flags.initDesignNameInUrl
          ? getDesignName(initDesignPath)
          : "";
        const initDesignPathInTree = findInTree({
          rawTree: nestedDesignList,
          initDesignPath,
          initDesignFolder,
          initDesignName
        });

        const designPath = window.flags.initDesignNameInUrl ? initDesignPathInTree : initDesignPath;
        const { copiedNode: tree, selectedFile, selectedFolder } = arrangeTree({
          tree: nestedDesignList,
          initDesignPath: designPath
        });

        AppProvider.getDesignThumbnails({ designs: [{ fullPath: designPath }] })
          .then(thum => {
            const thumbnail = thum[0];
            const { thumbUrl, designProps, fullPath } = thumbnail;
            if (!thumbUrl || !designProps) {
              dispatchUiState({ type: mainUiActions.SET_ERROR, payload: errorTypes.INVALID_LINK });
              return;
            }
            let designDetails = designProps;

            if (fullPath.toLowerCase() === designPath.toLowerCase()) {
              dispatchDesignList({
                type: designListActions.SET_TREE,
                payload: {
                  tree,
                  selectedFile: {
                    id: uuid.v4(),
                    name: designName,
                    location: path,
                    type: "file",
                    fullPath,
                    thumbUrl,
                    designProps: designDetails
                  },
                  selectedFolder
                }
              });
              if (!selectedFile) {
                dispatchDesignList({
                  type: designListActions.SELECT_DESIGN,
                  payload: {
                    id: uuid.v4(),
                    name: designName,
                    location: path,
                    type: "file",
                    fullPath,
                    thumbUrl,
                    designProps: designDetails
                  }
                });
              }
              //postmessage
              if (window.flags.postToParentWhenLoaded)
                window.parent.postMessage("explorug loaded", "*");
            }
          })
          .catch(error => {
            dispatchUiState({ type: mainUiActions.SET_ERROR, payload: errorTypes.INVALID_LINK });
          });
      });
    }
    // if (customDesignUrl && initRoomPath) {
    //   const loadRoom = async () => {
    //     const room = { FullPath: initRoomPath };
    //     const thumbs = await AppProvider.getRoomThumbnails({ rooms: [room] });
    //     const roomWithThumb = { ...thumbs[0], id: uuid.v4() };
    //     if (!roomWithThumb.Thumb) return;
    //     dispatchVisState({
    //       type: visualizationsActions.SET_ACTIVE_NODE,
    //       payload: roomWithThumb
    //     });
    //     dispatchVisState({
    //       type: visualizationsActions.SET_VIEW_MODE,
    //       payload: visViewModes.ROOM_VIEW
    //     });
    //   };
    //   loadRoom();
    // }

    // document.addEventListener('keydown', function(event){

    //   console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
    //   if(event.key ==='w'){
    //     triggeredDesign = window.initialData.triggeredDesign;//  'Designs/Internal/Fillpattern/Scamet Hoph.ctf';
    //     //window.initialData.triggeredDesign = 'Designs/Internal/Fillpattern/Scamet Hoph.ctf';
    //     console.log("document.addEventListener -> triggeredDesign", triggeredDesign)

    //   }
    // }, false )

    // return () => {
    //   window.removeEventListener("storage");
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!triggeredDesign || triggeredDesign === "") return;
    let designPath = processDesignPath(triggeredDesign);
    if (!initDesignFolder) {
      const sp = designPath.split(".").filter(item => item && item !== "");
      const ext = sp[sp.length - 1];
      if (ext.toLowerCase() !== "ctf" && ext.toLowerCase() !== "jpg") {
        designPath = `${designPath}.${window.InterfaceElements.IsJpeg ? "jpg" : "ctf"}`;
      }
    }

    if (!designListState || !designListState.tree) return;

    const searchResults = searchExactFileinTree(designListState.tree, designPath);

    if (designListState.selectedFile?.fullPath === designPath) return;
    //if (searchResults && searchResults.length) {
    AppProvider.getDesignThumbnails({ designs: [{ fullPath: designPath }] })
      .then(thum => {
        const thumbnail = thum[0];
        const { thumbUrl, designProps, fullPath } = thumbnail;
        if (!thumbUrl || !designProps) {
          dispatchUiState({ type: mainUiActions.SET_ERROR, payload: errorTypes.INVALID_LINK });
          return;
        }
        let resetUI = getFromSessionStorage("resetUI");
        resetUI = resetUI !== undefined || resetUI !== null ? resetUI : true;
        if (fullPath.toLowerCase() === designPath.toLowerCase()) {
          const triggeredVariation = window.initialData.initDesignVariation;
          let str = getFromSessionStorage("props");

          if (
            !triggeredVariation ||
            triggeredVariation === "" ||
            triggeredVariation === "original"
          ) {
            let designToOpen;
            if (searchResults && searchResults.length) {
              designToOpen = { ...searchResults[0], thumbUrl, designProps };
            } else {
              const p = fullPath.split("/").filter(item => item && item !== "");
              p.pop();
              const path = "/" + p.join("/");

              designToOpen = {
                id: uuid.v4(),
                name: getDesignName(fullPath),
                location: path,
                type: "file",
                fullPath,
                thumbUrl,
                designProps
              };
            }
            dispatchDesignList({
              type: designListActions.SELECT_DESIGN,
              payload: { selectedFile: designToOpen, activeVariation: {}, activeColorScheme: {} }
            });
          } else {
            console.log(
              "triggeredDesign",
              triggeredDesign,
              "-> triggeredVariation",
              triggeredVariation
            );
          }

          const isRoomviewTriggered = initRoomPath && initRoomPath !== "";
          if (resetUI) {
            dispatchUiState({ type: mainUiActions.SET_MAINUIRESET, payload: true });
            if (!isRoomviewTriggered || isRoomviewTriggered === "") {
              if (resetUI === 2) {
                // if resetui ===2, show triggered design in current room view
                const isRoomView = visState.activeNode && visState.activeNode.id !== "0";
                if (!isRoomView) {
                  dispatchVisState({
                    type: visualizationsActions.SET_VIEW_MODE,
                    payload: visViewModes.DESIGN_VIEW_DEEPZOOM
                  });
                }
              } else {
                dispatchVisState({
                  type: visualizationsActions.SET_VIEW_MODE,
                  payload: visViewModes.DESIGN_VIEW_DEEPZOOM
                });
              }

              // if (!isRoomviewTriggered || isRoomviewTriggered === "") {
              //   if(resetUI === 2){ // if resetui ===2, show triggered design in current room view
              //     const isRoomView = visState.activeNode && visState.activeNode.id !== "0";
              //      if(!isRoomView){
              //       dispatchVisState({
              //         type: visualizationsActions.SET_VIEW_MODE,
              //         payload: visViewModes.DESIGN_VIEW_DEEPZOOM
              //       });
              //      }
              //      else{
              //       dispatchVisState({
              //         type: visualizationsActions.SET_VIEW_MODE,
              //         payload: visViewModes.DESIGN_VIEW_DEEPZOOM
              //       });
              //       setTimeout(() => {
              //         dispatchVisState({
              //           type: visualizationsActions.SET_VIEW_MODE,
              //           payload: visViewModes.ROOM_VIEW
              //         });
              //       }, 500);
              //      }
              //   }
              //   else{
              //     dispatchVisState({
              //       type: visualizationsActions.SET_VIEW_MODE,
              //       payload: visViewModes.DESIGN_VIEW_DEEPZOOM
              //     });

              //   }
            }
          }
          // if (str && str !== "") {
          //   str = decodeURIComponent(str);
          //   const triggeredDesignColors = str ? decodeColorsFromString(str) : null;

          //   if (triggeredDesignColors !== designDetailState.designDetails.DesignColors) {
          //     if (triggeredDesignColors) {
          //       dispatchDesignDetails({
          //         type: designDetailActions.SET_DESIGNCOLORS,
          //         payload: triggeredDesignColors
          //       });
          //     }
          //   }
          // }
          if (
            borderDesignWid &&
            borderDesignHgt &&
            (borderDesignWid !== designDetailState.designDetails.PhysicalWidth ||
              borderDesignHgt !== designDetailState.designDetails.PhysicalHeight)
          ) {
            dispatchDesignDetails({
              type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
              payload: {
                PhysicalHeight: parseFloat(borderDesignHgt),
                PhysicalWidth: parseFloat(borderDesignWid)
              }
            });
          }
          if (
            designWidth &&
            designHeight &&
            (designWidth !== designDetailState.designDetails.PhysicalWidth ||
              designHeight !== designDetailState.designDetails.PhysicalHeight)
          ) {
            dispatchDesignDetails({
              type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
              payload: {
                PhysicalHeight: parseFloat(designWidth),
                PhysicalWidth: parseFloat(designHeight),
                Unit: unit
              }
            });
          }
        }
      })
      .catch(error => {
        console.log(designPath, "is not found");
        let designName = designPath;
        if (designPath && designPath !== "" && designPath[designPath.length - 1] === "/") {
          designPath = designPath.substring(0, designPath.length - 1);
          designName = designPath.split("/").pop();
        }
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: {
            message: `The design ${designName} could not be found in the specified location.`,
            intent: "warning"
          }
        });
      });
  }, [triggeredDesign]);

  useEffect(() => {
    if (!props) return;
    if (!designListState || !designListState.tree) return;
    let str = props; //getFromSessionStorage("props");

    if (str && str !== "") {
      str = decodeURIComponent(str);
      const triggeredDesignColors = str ? decodeColorsFromString(str) : null;
      if (!triggeredDesignColors) {
        console.log("props is invalid");
        return;
      }
      const currentDesignColors = designDetailState.designDetails.DesignColors;
      console.log(
        "useEffect -> currentDesignColors",
        currentDesignColors,
        "triggeredDesignColors",
        triggeredDesignColors
      );
      if (
        JSON.stringify(triggeredDesignColors) !== JSON.stringify(currentDesignColors) &&
        triggeredDesignColors.length === currentDesignColors.length
      ) {
        if (triggeredDesignColors) {
          dispatchDesignDetails({
            type: designDetailActions.SET_DESIGNCOLORS,
            payload: triggeredDesignColors
          });
        }
      } else {
        console.log("passed props str does not match for the current design");
      }
    }
  }, [props]);

  const getSelectedRoomFromRoomList = (illustrations, initRoomPath) => {
    var roomWithThumb = null;
    const roomsWithThumbs = illustrations;
    let ind = 0;
    if (initRoomPath && roomsWithThumbs) {
      ind = roomsWithThumbs.findIndex(
        item => item.FullPath.toLowerCase() === initRoomPath.toLowerCase()
      );
      if (ind < 0) {
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: {
            message: strings.toastMessages.triggeredRoomNotFound,
            intent: "warning",
            timeout: 7000
          }
        });
        ind = 0;
      }
      roomWithThumb = roomsWithThumbs[ind];
    }
    return roomWithThumb;
  };
  const setRoomList = async () => {
    var roomList = null;
    const rooms = await AppProvider.fetchRoomList({});
    if (!rooms || !rooms.length) return null;
    const roomsControl = window.flags.roomsControl || [];
    if (roomsControl.length) {
      dispatchVisState({
        type: visualizationsActions.SET_ROOMS_CONTROL,
        payload: roomsControl
      });
    }
    var filteredRooms;
    if (showAllRooms) {
      filteredRooms = rooms.filter(
        item => item.Type === "file" && getExtension(item.FullPath) === "crf3d"
      );
    } else {
      filteredRooms = rooms.filter(
        item =>
          item.Type === "file" &&
          getExtension(item.FullPath) === "crf3d" &&
          getPathOffile(item.FullPath) === "Rooms"
      );
    }
    if (filteredRooms.length) {
      roomList = filteredRooms.map(item => ({ ...item, label: item.Name }));

      dispatchVisState({
        type: visualizationsActions.SET_ILLUSTRATIONS,
        payload: roomList
      });
    }
    return roomList;
  };
  const loadRoom = async () => {
    var roomWithThumb = getSelectedRoomFromRoomList(visState.illustrations, initRoomPath);
    if (!roomWithThumb) {
      const room = { FullPath: initRoomPath };
      const thumbs = await AppProvider.getRoomThumbnails({ rooms: [room] });
      roomWithThumb = { ...thumbs[0], id: uuid.v4() };
      if (!roomWithThumb.Thumb) {
        const roomlist = setRoomList();
        if (roomlist) {
          roomWithThumb = getSelectedRoomFromRoomList(roomlist, initRoomPath);
        } else {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.toastMessages.noRoomShowdesign,
              intent: "warning",
              timeout: 7000
            }
          });
          dispatchVisState({
            type: visualizationsActions.SET_VIEW_MODE,
            payload: visViewModes.DESIGN_VIEW_DEEPZOOM
          });
          return;
        }
      }
    }

    dispatchVisState({
      type: visualizationsActions.SET_ACTIVE_NODE,
      payload: roomWithThumb
    });
    dispatchVisState({
      type: visualizationsActions.SET_VIEW_MODE,
      payload: visViewModes.ROOM_VIEW
    });
  };

  useEffect(() => {
    if (initRoomPath && initRoomPath !== "") {
      if (visState.activeNode && visState.activeNode.id === "0") return;
      if (initRoomPath === "myroom") {
        dispatchVisState({
          type: visualizationsActions.SET_VIEW_MODE,
          payload: visViewModes.MY_ROOM
        });
      } else if (initRoomPath === "perspectiveview") {
        if (
          window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_PERSPECTIVE)
        ) {
          window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_PERSPECTIVE;
          dispatchVisState({
            type: visualizationsActions.SET_VIEW_MODE,
            payload: window.flags.visualizations.defaultView
          });
        }
      } else if (initRoomPath === "foldedbackview") {
        if (window.flags.visualizations.designViews.includes(visViewModes.DESIGN_VIEW_FOLDEDBACK)) {
          window.flags.visualizations.defaultView = visViewModes.DESIGN_VIEW_FOLDEDBACK;
          dispatchVisState({
            type: visualizationsActions.SET_VIEW_MODE,
            payload: window.flags.visualizations.defaultView
          });
        }
      } else {
        loadRoom();
      }
    } else if (initRoomPath === "") {
      dispatchVisState({
        type: visualizationsActions.SET_VIEW_MODE,
        payload: visViewModes.DESIGN_VIEW_DEEPZOOM
      });
    }
  }, [initRoomPath]);
};
