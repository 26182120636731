import React, { useState, useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
// import pdfjsLib from "pdfjs-dist";
import styled from "styled-components";
const pdfjsLib = window.pdfjsLib;
// pdfjsLib.GlobalWorkerOptions.workerSrc = `scripts/pdf.worker.js`;
// var CMAP_URL = "scripts/pdf.js.map";
// var CMAP_PACKED = true;
let renderTask, loadingTask;

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  padding: 3em 0;
  & > canvas {
    top: 50%;
    left: 50%;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
    cursor: ${props =>
      props.grab ? (props.isGrabbing ? "grabbing !important" : "grab !important") : "default"};
    width: ${props => props.wid && `${props.wid}px`};
    visibility: ${props => (props.visibilityprop ? "visible" : "hidden")};
    margin: auto;
  }
`;
const PdfRenderer = props => {
  const { grab, isGrabbing, file, scale = 0.8, visibility = true, onRenderComplete } = props;
  const container = useRef(null);
  const [scaleHeight, setScaleHeight] = useState(1);
  const [heightRatio, setheightRatio] = useState(1);
  const size = useWindowSize();

  useEffect(() => {
    if (!file) return;
    //console.log(file);
    renderPdf();
    // eslint-disable-next-line
  }, [file]);
  const renderPdf = () => {
    if (loadingTask) loadingTask.destroy();
    if (renderTask) renderTask.cancel();
    loadingTask = pdfjsLib.getDocument({
      url: file,
      // cMapUrl: CMAP_URL,
      // cMapPacked: CMAP_PACKED,
      disableFontFace: false
    });
    loadingTask.promise
      .then(pdfDocument => {
        const { current } = container;

        return pdfDocument.getPage(1).then(function(pdfPage) {
          var viewport = pdfPage.getViewport({
            scale: window.innerWidth / pdfPage.getViewport({ scale: 1.0 }).width
          });
          const { width, height } = viewport;
          if (height > width) {
            setScaleHeight((width / height) * 0.8);
          } else {
            setScaleHeight(1);
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          setheightRatio(height / width);
          var ctx = canvas.getContext("2d");

          renderTask = pdfPage.render({
            canvasContext: ctx,
            viewport: viewport
          });
          renderTask.promise
            .then(() => {
              while (current.firstChild) {
                current.removeChild(current.firstChild);
              }
              current.appendChild(canvas);
              if (onRenderComplete) onRenderComplete();
            })
            .catch(err => {
              //console.log(err);
            });

          return renderTask.promise;
        });
      })
      .catch(function(reason) {
        //console.log("Error: " + reason);
      });
  };
  return (
    <Container
      ref={container}
      wid={size.width * 0.8 * 0.8 * scale * scaleHeight}
      visibilityprop={visibility}
      isGrabbing={isGrabbing}
      grab={grab}
      heightRatio={heightRatio}
      hgt={size.height}
    />
  );
};
export default PdfRenderer;
