import classNames from "classnames";
import React, { Suspense, useEffect, useRef } from "react";
import { usePrevious } from "../../../hooks";
import { useInitSetupMount } from "../../../hooks/initSetupHook";
import { fetchNewPropsAndPostToParent } from "../../../middleware/ordersheet.middleware";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { pageViews, useUiState } from "../../../reducers/mainui.reducer";
import { getCustomClass } from "../../../utils/domUtils";
import { showOrdersheetOutside } from "../../../utils/flagUtils";
import { decodeColorsFromString } from "../../../utils/utils";
import ColorAreaSwatch from "../../molecules/ColorAreaSwatch";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import OrderSheet from "../../templates/OrderSheet";
import OrdersheetButton from "../../templates/OrderSheet/OrdersheetBtn";

const ColorsPage = () => {
  const uiState = useUiState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const designDetailState = useDesignDetailState();

  const { designDetails, fullpath } = designDetailState;
  const prevFullpath = usePrevious(fullpath);
  const prevDesignColors = usePrevious(designDetails?.DesignColors);

  const refColorsPage = useRef(null);
  const { props, initDesignColors } = window.initialData;
  useInitSetupMount();

  useEffect(() => {
    if (window.flags.postToParentOnEveryColorChange && fullpath && fullpath === prevFullpath) {
      if (
        designDetails?.DesignColors &&
        JSON.stringify(prevDesignColors) !== JSON.stringify(designDetails.DesignColors)
      ) {
        fetchNewPropsAndPostToParent({ designDetailState: designDetailState });
      }
    }
  }, [designDetails]);
  useEffect(() => {
    if (initDesignColors) {
      dispatchDesignDetails({
        type: designDetailActions.SET_DESIGNCOLORS,
        payload: initDesignColors
      });
    }
  }, [initDesignColors]);

  useEffect(() => {
    if (props && props !== "") {
      const decodedProps = decodeURIComponent(props);
      const triggeredDesignColors = decodedProps ? decodeColorsFromString(decodedProps) : null;
      console.log("useEffect -> triggeredDesignColors", triggeredDesignColors);
      const currentDesignColors = designDetailState.designDetails.DesignColors;

      if (
        triggeredDesignColors &&
        JSON.stringify(triggeredDesignColors) !== JSON.stringify(currentDesignColors) &&
        triggeredDesignColors.length === currentDesignColors.length
      ) {
        dispatchDesignDetails({
          type: designDetailActions.SET_DESIGNCOLORS,
          payload: triggeredDesignColors
        });
      }
    }
  }, [designDetails.DesignColors, props]);

  const showOrdersheetBtnWithColors = () => {
    var show = window.flags.ordersheet.panel === "right";
    if (uiState.pageView === pageViews.COLORS) {
      const { pageViewsOptions } = window.flags;
      show =
        pageViewsOptions?.colors?.showOrdersheetBtnBelowColorsArea ||
        window.flags.ordersheet.panel === "right";
    }
    return show;
  };
  const renderOrdersheet = <OrderSheet />;
  return (
    <div id="app-main" className={classNames("app-main", getCustomClass())}>
      <div className="colors-page" ref={refColorsPage}>
        <Icons />
        {(!uiState.showOrderSheet || showOrdersheetOutside() || !showOrdersheetBtnWithColors()) && (
          <>
            <ColorAreaSwatch />
          </>
        )}
        {window.flags.hasOrderSheet &&
          !showOrdersheetOutside() &&
          showOrdersheetBtnWithColors() && <Suspense fallback={<></>}>{renderOrdersheet}</Suspense>}
        {window.flags.hasOrderSheet &&
          !showOrdersheetOutside() &&
          !uiState.showOrderSheet &&
          showOrdersheetBtnWithColors() && <OrdersheetButton />}
      </div>
    </div>
  );
};

ColorsPage.propTypes = {};

export default ColorsPage;
