import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import classNames from "classnames";
import { useKey } from "react-use";

const SearchBar = props => {
  const { textToSearch, handleInputChange, placeholder, handleCancel, className, hidden } = props;
  const [focused, setFocused] = useState(false);

  const input = useRef();
  const onInputChange = e => {
    if (e.target.value.length) {
      setFocused(true);
    } else {
      setFocused(false);
    }
    handleInputChange(e);
  };
  useKey("Escape", () => {
    input.current.blur();
  });
  return (
    <div
      className={classNames(
        "at-input-group",
        "at-search-bar",
        { focused: focused, hidden: hidden },
        className
      )}
    >
      <AtIcon icon="search" />
      <input
        ref={input}
        type="text"
        className="bp3-input at-input"
        placeholder={placeholder}
        onFocus={e => {
          e.target.select();
          if (e.target.value.length) setFocused(true);
        }}
        value={textToSearch}
        onChange={onInputChange}
      />
      <AtButton
        size="small"
        onClick={e => {
          setFocused(false);
          handleCancel(e);
        }}
        icon="close"
        minimal
      ></AtButton>
    </div>
  );
};

SearchBar.propTypes = {
  handleInputChange: PropTypes.func
};

export default SearchBar;
