import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@blueprintjs/core";
import AppProvider from "../../../api/appProvider";
import { useInitDesignMount } from "../../../hooks/initDesignListHook";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designlist.reducer";
import { getCustomClass } from "../../../utils/domUtils";
import { useHomeMount } from "../Home/hooks";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import Stage from "../../organisms/StageNew";
import { useVisualizationState, visViewModes } from "../../../reducers/visualizations.reducer";
import RoomList from "../../organisms/RoomList";
import Collection from "../../organisms/CollectionSection";
import { findSimilarDesigns } from "../../../utils/treeutils";
import strings from "../../../strings";
import Designviews from "../../organisms/Designviews";
import { usePrevious } from "../../../hooks";
import { canvasToBlobPromise } from "../../../utils/canvasutils";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";

const ExplorugController = () => {
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const designDetailState = useDesignDetailState();
  const { selectedFile, tree } = designListState;
  const visualizationState = useVisualizationState();
  const previousSelectedFile = usePrevious(selectedFile);
  const [selectedTab, setSelectedTab] = useState(0);
  const [designImage1x, setDesignImage1x] = useState(null);
  const [viewImage1x, setViewImage1x] = useState(null);

  const [Folder1Content, setFolder1Content] = useState(null);
  const [Folder2Content, setFolder2Content] = useState(null);
  const [Folder1SelectedFile, setFolder1SelectedFile] = useState(null);
  const [Folder2SelectedFile, setFolder2SelectedFile] = useState(null);
  const [Folder2DefaultFolder, setFolder2DefaultFolder] = useState(null);
  const [Folder2DefaultThumbUrl, setFolder2DefaultThumbUrl] = useState(null);

  useHomeMount();
  useInitDesignMount();
  const { controller } = window.flags;

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "explorug-controller";
  }, []);
  useEffect(() => {
    if (!tree || !tree.length) {
      return;
    }
    if (Folder1Content && Folder2Content) return; //return if folder1 and folder2 content is already set;

    if (!controller.showDesignsNRooms) {
      const nodeItems = tree.filter(
        node => node && (node.children.length || node.files.length) && node.name.charAt(0) !== "."
      );
      const Frames = nodeItems[0].children.filter(
        node => node.name.toLowerCase() === controller.Folder1Name.toLowerCase()
      );
      if (Frames.length) setFolder1Content(Frames[0]);
      const Pictures = nodeItems[0].children.filter(
        node => node.name.toLowerCase() === controller.Folder2Name.toLowerCase()
      );
      if (Pictures.length) {
        setFolder2Content(Pictures[0]);
        const defaultFolder =
          Pictures[0].children && Pictures[0].children.length ? Pictures[0].children[0] : null;
        if (defaultFolder) setFolder2DefaultFolder(defaultFolder);

        if (defaultFolder && defaultFolder.files?.length) {
          setFolder2SelectedFile(defaultFolder.files[0]);
          AppProvider.getDesignThumbnails({ designs: [defaultFolder.files[0]] }).then(details => {
            console.log(details);
            if (details && details.length) {
              const thumburl = details[0].thumbUrl;
              setFolder2DefaultThumbUrl(thumburl);
            }
          });
        }
      }
    }
  }, [tree]);

  useEffect(() => {
    if (
      selectedFile &&
      selectedFile.fullPath &&
      selectedFile.fullPath !== previousSelectedFile?.fullPath
    ) {
      if (!controller.showDesignsNRooms) {
        // if it's showing two folders content
        if (selectedFile.location.includes(controller.Folder1Name)) {
          setFolder1SelectedFile(selectedFile);
        } else if (selectedFile.location.includes(controller.Folder2Name)) {
          setFolder2SelectedFile(selectedFile);
        }
      }

      if (controller.passJsonOnDesignChange === 1) {
        const msgProps = {
          triggeredDesign: selectedFile.fullPath
        };
        console.log("useEffect -> msgProps", msgProps);
        window.parent.postMessage(msgProps, "*");
      }

      AppProvider.get1xDesign({ file: selectedFile.fullPath }).then(design => {
        if (!controller.passJsonOnDesignChange) {
          console.log("post to parent ", design);
          window.parent.postMessage(design, "*");
        } else if (controller.passJsonOnDesignChange === 2) {
          const data = {
            design: design,
            room: viewImage1x
          };
          console.log("post to parent ", data);
          window.parent.postMessage(data, "*");
        }

        setDesignImage1x(design);
      });
    }
  }, [selectedFile]);

  useEffect(() => {
    if (controller.passJsonOnDetailViewChange === 1) {
      var viewMode = "";
      switch (visualizationState.viewMode) {
        case visViewModes.DESIGN_VIEW_DEEPZOOM:
          viewMode = "";
          break;
        case visViewModes.DESIGN_VIEW_PERSPECTIVE:
          viewMode = "perspectiveview";
          break;
        case visViewModes.MY_ROOM:
          viewMode = "myroom";
          break;
        case visViewModes.ROOM_VIEW:
          viewMode = "roomview";
          break;
        default:
          break;
      }
      if (viewMode !== "roomview") {
        const msgProps = {
          initview: viewMode
        };
        console.log("useEffect -> msgProps", msgProps);

        window.parent.postMessage(msgProps, "*");
      }
    } else {
      var imgToSend =
        visualizationState.viewMode === visViewModes.DESIGN_VIEW_DEEPZOOM ? designImage1x : "";
      if (imgToSend) {
        if (controller.passJsonOnDesignChange === 2) {
          const data = {
            design: imgToSend,
            room: ""
          };
          setViewImage1x("");
          setDesignImage1x(imgToSend)
          console.log("post to parent ", data);
          window.parent.postMessage(data, "*");
        } else {
          console.log("post to parent ", imgToSend);
          window.parent.postMessage(imgToSend, "*");
        }
      }
    }
  }, [visualizationState.viewMode]);

  useEffect(() => {
    if (designDetailState.loading) return;
    if (visualizationState.viewMode === visViewModes.ROOM_VIEW) {
      if (
        !visualizationState ||
        !visualizationState.activeNode ||
        !visualizationState.activeNode.FullPath
      )
        return;
      const roomPath = visualizationState.activeNode.FullPath;
      if (controller.passJsonOnRoomViewChange === 1) {
        const msgProps = {
          initview: roomPath
        };
        console.log("useEffect -> msgProps", msgProps);
        window.parent.postMessage(msgProps, "*");
      } else {
        if (window.getRoomRenderingCanvas) {
          const renderInActualSize = window.innerWidth >= 1024 ? false : true;
          window.getRoomRenderingCanvas(renderInActualSize).then(response => {
            console.log("data posted to parent", response);
            setViewImage1x(response);
            if (controller.passJsonOnDesignChange === 2) {
              const data = {
                design: designImage1x,
                room: response
              };
              console.log("post to parent ", data);
              window.parent.postMessage(data, "*");
            } else window.parent.postMessage(response, "*");
          });
        }
      }
    } else if (visualizationState.viewMode === visViewModes.DESIGN_VIEW_PERSPECTIVE) {
      const perspectiveRenderCanvas = window.getPerspectiveCanvas();
      const downloadName = `${designDetailState.designName} in ${strings.designViews.perspective}`;

      canvasToBlobPromise(perspectiveRenderCanvas)
        .then(roomCanvasBlob => {
          AppProvider.uploadRoomviewBlob({ blob: roomCanvasBlob, filename: downloadName }).then(
            response => {
              console.log("data posted to parent", response);
              setViewImage1x(response);
              if (controller.passJsonOnDesignChange === 2) {
                const data = {
                  design: designImage1x,
                  room: response
                };
                console.log("post to parent ", data);
                window.parent.postMessage(data, "*");
              } else window.parent.postMessage(response, "*");
            }
          );
        })
        .catch(() => {
          console.log("error while getting perspective blob image");
        });
    }
  }, [designDetailState.loading]);

  const onThumbnailClick = (node, e) => {
    if (!node.designProps) return;
    if (designListState.selectedFile && node.id === designListState.selectedFile?.id) {
      console.info("clicked in active design thumbnail");
      return;
    }

    let requiredVariation = {};
    if (window.flags.designVariations.preserveVariations) {
      if (designListState.activeVariation.id) {
        const similarDesigns = findSimilarDesigns(designListState.tree, node);
        if (similarDesigns) {
          const va = designListState.activeVariation.name.replace(
            designListState.selectedFile?.name,
            ""
          );
          if (va)
            requiredVariation = similarDesigns.files.find(file => {
              if (file.name.includes(va)) return true;
              return false;
            });
        }
      }
    }
    dispatchDesignList({
      type: designListActions.SELECT_DESIGN,
      payload: { selectedFile: node, activeVariation: requiredVariation }
    });
  };

  return (
    <div id="app-main" className={classNames("app-main", getCustomClass())}>
      <Icons />
      <div className="exp-controller-header">
        <div className="exp-controller-title">{strings.controller.mainTitle}</div>
        {controller.showDesignsNRooms ? (
          <div className="stage-wrapper" id="room-view-container">
            {visualizationState.viewMode === visViewModes.DESIGN_VIEW_DEEPZOOM && designImage1x && (
              <div className="exp-controller-design-wrapper">
                <img src={designImage1x} alt="design" />
              </div>
            )}
            {visualizationState.viewMode === visViewModes.ROOM_VIEW && (
              <Stage defaultViewMode={visViewModes.ROOM_VIEW} />
            )}
            {visualizationState.viewMode === visViewModes.DESIGN_VIEW_PERSPECTIVE && (
              <Stage defaultViewMode={visViewModes.DESIGN_VIEW_PERSPECTIVE} />
            )}
          </div>
        ) : (
          <>
            <div className="stage-wrapper framePictureWrapper">
              <div className="pictureImageWrapper">
                {(Folder2SelectedFile?.thumbUrl || Folder2DefaultThumbUrl) && (
                  <img
                    alt="pic below frame"
                    src={Folder2SelectedFile?.thumbUrl || Folder2DefaultThumbUrl}
                  />
                )}
              </div>
              <div className="frameImageWrapper">
                <img alt="frame part" src={Folder1SelectedFile?.thumbUrl || ""} />
              </div>
            </div>
          </>
        )}
      </div>

      {controller.showDesignsNRooms ? (
        <Tabs
          className="bp3-focus-disabled at-sidebar__content__tabs at-sidebar-tabs bp4-large"
          selectedTabId={`tab-${selectedTab}`}
        >
          <Tab
            id="tab-0"
            title={strings.design.designs}
            onClickCapture={() => setSelectedTab(0)}
            panel={
              <>
                {designListState && designListState.tree && (
                  <div className="cyr-section" id="cyr-collection">
                    <div className="cyr-heading">Collection</div>
                    <Collection
                      className="cyr-collection-section"
                      Tree={designListState.tree[0]}
                      defaultFolder={designListState.selectedFolder}
                      selectedFile={designListState.selectedFile}
                      handleThumbnailClick={onThumbnailClick}
                      showNavigatorArrows={false}
                    />
                  </div>
                )}
              </>
            }
          ></Tab>
          <Tab
            className="bp4-large"
            id="tab-1"
            title={strings.visualization.visualizations}
            onClickCapture={() => setSelectedTab(1)}
            panel={<RoomList />}
          ></Tab>
          <Tab
            id="tab-2"
            title={strings.design.designViews}
            onClickCapture={() => setSelectedTab(2)}
            panel={<Designviews />}
          ></Tab>
        </Tabs>
      ) : (
        <Tabs
          className="bp3-focus-disabled at-sidebar__content__tabs at-sidebar-tabs bp4-large"
          selectedTabId={`tab-${selectedTab}`}
        >
          {Folder1Content && (
            <Tab
              id="tab-0"
              title={controller.Folder1Name || strings.design.designs}
              onClickCapture={() => setSelectedTab(0)}
              panel={
                <>
                  {
                    <div className="cyr-section" id="cyr-collection">
                      <div className="cyr-heading">Frames</div>
                      <Collection
                        className="cyr-collection-section"
                        Tree={Folder1Content}
                        defaultFolder={null}
                        selectedFile={Folder1SelectedFile}
                        handleThumbnailClick={onThumbnailClick}
                        showNavigatorArrows={false}
                      />
                    </div>
                  }
                </>
              }
            ></Tab>
          )}
          {Folder2Content && (
            <Tab
              id="tab-1"
              title={controller.Folder2Name || strings.visualization.visualizations}
              onClickCapture={() => setSelectedTab(1)}
              panel={
                <>
                  {
                    <div className="cyr-section" id="cyr-collection">
                      <div className="cyr-heading">Frames</div>
                      <Collection
                        className="cyr-collection-section"
                        Tree={Folder2Content}
                        defaultFolder={Folder2DefaultFolder}
                        selectedFile={Folder2SelectedFile}
                        handleThumbnailClick={onThumbnailClick}
                        showNavigatorArrows={false}
                      />
                    </div>
                  }
                </>
              }
            ></Tab>
          )}
        </Tabs>
      )}
    </div>
  );
};

ExplorugController.propTypes = {};

export default ExplorugController;
