import React, { useEffect, useReducer } from "react";
import Engine from "./../Engine";

function feedSubjectsReducer(state, action) {
  switch (action.type) {
    case "add":
      return [...action.payload].sort((a, b) => (a.seqIndex > b.seqIndex ? 1 : -1));
    case "empty":
      return [];
    // return [...state, ...action.payload].sort((a, b) => (a.index > b.index ? 1 : -1));
    default:
      throw new Error();
  }
}

function EngineFeeder(props) {
  const { images, loadNext, pause, handleShowStart, duration } = props;
  const [feedsubjects, dispatchfeedsubjects] = useReducer(feedSubjectsReducer, []);

  useEffect(() => {
    if (!images && images.length === 0) {
      dispatchfeedsubjects({
        type: "empty"
      });
      return;
    }
    let imgarr = [];
    images.forEach((srcobj, index) => {
      let formattedsrcobj = { ...srcobj };
      formattedsrcobj.subject = srcobj.src;
      formattedsrcobj.seqIndex = index;
      delete formattedsrcobj.src;
      let toload = 1;
      const img = new Image();
      let qrimg;
      if (formattedsrcobj.qrSrc) {
        qrimg = new Image();
        toload++;
      }
      const resolveLoad = () => {
        if (toload > 0) return;
        imgarr.push({ ...formattedsrcobj });
        if (imgarr.length === images.length) {
          dispatchfeedsubjects({
            type: "add",
            payload: imgarr
          });
        }
      };
      img.onload = function() {
        toload--;
        resolveLoad();
      };
      qrimg.onload = function() {
        toload--;
        resolveLoad();
      };
      qrimg.onerror = function(err) {
        console.log("error ", err);
      };
      img.src = formattedsrcobj.subject;
      if (formattedsrcobj.qrSrc) qrimg.src = formattedsrcobj.qrSrc;
    });

    return () => {};
  }, [images]);

  return (
    <React.Fragment>
      <Engine
        subjects={feedsubjects}
        duration={duration}
        askRefill={loadNext}
        pause={pause}
        handleShowStart={handleShowStart}
      />
    </React.Fragment>
  );
}

EngineFeeder.propTypes = {};

export default EngineFeeder;
