import { useMount } from "react-use";
import AppProvider from "../api/appProvider";
import { colorListActions, useDispatchColorList } from "../reducers/colors.reducer";
import {
  designDetailActions,
  useDispatchDesignDetail
} from "../reducers/designdetails.reducer";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../reducers/designlist.reducer";
import { mainUiActions, useUiDispatch } from "../reducers/mainui.reducer";
import { arrangeTree, findInTree, getDesignName } from "../utils/treeutils";
import uuid from "uuid";
import { errorTypes } from "../utils/utils";
import { useEffect } from "react";

export const useInitSetupMount = () => {
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchColorListState = useDispatchColorList();
  const dispatchUiState = useUiDispatch();

  const { initDesignPath, initDesignFolder } = window.initialData;

  useMount(() => {
    if (designListState.tree) {
      return;
    }
    AppProvider.fetchColorList({}).then(colors => {
      if (colors && colors.length) {
        if (
          window.flags.colorSelectionBox.showColorPomsImages ||
          window.flags.colorAreaSwatch.showColorPomsImages
        ) {
          var colorListStr = "";
          colors.forEach((colorList, index) => {
            const colorRows = colorList.ColorRows;
            const colorHexList = colorRows.map(colorRow => colorRow.Color);
            for (let i = 0; i < colorHexList.length; i++) {
              colorListStr += colorHexList[i].replace("#", "") + "|";
            }
          });
          colorListStr = colorListStr.slice(0, -1);
          AppProvider.createColorPoms(colorListStr).then(response => {});
        }
      }
      dispatchColorListState({ type: colorListActions.SET_COLORS, payload: colors });
    });

    
    const initDesignList = () => {
      const p = initDesignPath.split("/").filter(item => item && item !== "");
      const designNamewithExt = p[p.length - 1];
      const designNamewithExtArr = designNamewithExt.split(".");
      let designName = designNamewithExtArr.slice(0, designNamewithExtArr.length - 1).join(".");

      p.pop();
      const path = "/" + p.join("/");
      AppProvider.fetchDesignList({ struct: true }).then(nestedDesignList => {
        const initDesignName = window.flags.initDesignNameInUrl
          ? getDesignName(initDesignPath)
          : "";
        const initDesignPathInTree = findInTree({
          rawTree: nestedDesignList,
          initDesignPath,
          initDesignFolder,
          initDesignName
        });

        const designPath = window.flags.initDesignNameInUrl ? initDesignPathInTree : initDesignPath;
        const { copiedNode: tree, selectedFile, selectedFolder } = arrangeTree({
          tree: nestedDesignList,
          initDesignPath: designPath
        });

        AppProvider.getDesignThumbnails({ designs: [{ fullPath: designPath }] })
          .then(thum => {
            const thumbnail = thum[0];
            const { thumbUrl, designProps, fullPath } = thumbnail;
            if (!thumbUrl || !designProps) {
              dispatchUiState({ type: mainUiActions.SET_ERROR, payload: errorTypes.INVALID_LINK });
              return;
            }
            let designDetails = designProps;

            if (fullPath.toLowerCase() === designPath.toLowerCase()) {
              dispatchDesignList({
                type: designListActions.SET_TREE,
                payload: {
                  tree,
                  selectedFile: {
                    id: uuid.v4(),
                    name: designName,
                    location: path,
                    type: "file",
                    fullPath,
                    thumbUrl,
                    designProps: designDetails
                  },
                  selectedFolder
                }
              });
              if (!selectedFile) {
                dispatchDesignList({
                  type: designListActions.SELECT_DESIGN,
                  payload: {
                    id: uuid.v4(),
                    name: designName,
                    location: path,
                    type: "file",
                    fullPath,
                    thumbUrl,
                    designProps: designDetails
                  }
                });
              }
              //postmessage
              if (window.flags.postToParentWhenLoaded)
                window.parent.postMessage("explorug loaded", "*");
            }
          })
          .catch(error => {
            dispatchUiState({ type: mainUiActions.SET_ERROR, payload: errorTypes.INVALID_LINK });
          });
      });
    };
    initDesignList();
  });

  useEffect(() => {
    const { selectedFile, activeVariation } = designListState;
    let {
      designProps: designDetails,
      name: designName,
      fullPath,
      customizationFlag
    } = selectedFile;
    if (!designDetails) return;
    if (window.flags.designVariations.preserveVariations || activeVariation.id) {
      if (activeVariation.designProps) designDetails = activeVariation.designProps;
      designName = activeVariation.name;
      fullPath = activeVariation.fullPath;
    }
    dispatchDesignDetails({
      type: designDetailActions.SET_DETAILS,
      payload: {
        designDetails,
        label: designName,
        fullpath: fullPath,
        customizationFlag,
        setCustomConversion: window.flags.ordersheet.customConversion.setCustomConversion || false,
        customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
      }
    });
  }, [designListState.selectedFile.id]);
};
