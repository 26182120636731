window.gifScript = false;
export default class GifHelper {
    constructor() {
        this.gif = null;
        this.totalFrames = 3;
        this.renderGif = false;
        this.frameDuration = 1;
    }

    async init(options = {}) {
        const { totalFrames, frameDuration } = options;
        if (totalFrames) {
            this.totalFrames = totalFrames;
        }
        if (frameDuration) {
            this.frameDuration = frameDuration;
        }
        return new Promise((resolve, reject) => {
            if (!this.gif) {
                const createWorkers = () => {
                    this.gif = new window.GIF({
                        workers: 8,
                        quality: 2,
                        workerScript: "gifScripts/gif.worker.js"
                    });
                    resolve();
                }
                if (!window.gifScript) {
                    const script = document.createElement('script');
                    script.src = "gifScripts/gif.js";
                    document.body.appendChild(script);
                    script.onload = () => {
                        window.gifScript = true;
                        createWorkers()
                    };
                }
                else {
                    createWorkers()
                }
            }
            else {
                resolve();
            }
        });

    }

    canaddFrame() {
        return ((!this.gif || this.gif.frames.length < this.totalFrames) && this.renderGif);
    }

    async addFrame(frame) {
        if (!frame || (this.gif && this.gif.frames.length >= this.totalFrames)) return;
        if (!this.gif)
            await this.init();
        const frameDelay = this.frameDuration * 1000;
        if (Array.isArray(frame)) {
            for (let index = 0; index < frame.length; index++) {
                await this.gif.addFrame(frame[index], { delay: frameDelay });
            }
        }
        else {
            this.gif.addFrame(frame, { delay: frameDelay });
        }

    }

    render(options = {}) {
        const { download = true, totalFrames, frameDuration } = options;
        this.init({ totalFrames, frameDuration });
        this.renderGif = true;
        return new Promise(async (resolve, reject) => {
            while (!this.gif || this.gif.frames.length < this.totalFrames) {
                await (new Promise(resolve => setTimeout(resolve, 1000)));
            }
            this.renderGif = false;
            this.gif.on('finished', (function (blob) {
                this.clear();
                if (download) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.setAttribute('download', 'test.gif');
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                    resolve();
                }
                else {
                    resolve(URL.createObjectURL(blob));
                }
            }).bind(this));
            this.gif.render();
        })
    }

    clear() {
        if (this.gif) {
            this.gif.freeWorkers.forEach(w => w.terminate());
            this.gif.abort();
            this.gif.frames = [];
            delete this.gif.imageParts;
            delete this.gif.finishedFrames;
            delete this.gif.nextFrame;
            this.gif = null;
        }
    }
}