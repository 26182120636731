import space from "color-space";
import { pomsUrlPrefix } from "../api/appProvider";
const getRGBValue = color => {
  let R = color.R || color.r;
  let G = color.G || color.g;
  let B = color.B || color.b;
  return { R, G, B };
};

export const calculateColorDelta = (color1, color2) => {
  let { R: r1, G: g1, B: b1 } = color1;
  let { R: r2, G: g2, B: b2 } = getRGBValue(color2);
  // calculate differences between reds, greens and blues
  var r = 255 - Math.abs(r1 - r2);
  var g = 255 - Math.abs(g1 - g2);
  var b = 255 - Math.abs(b1 - b2);
  // limit differences between 0 and 1
  r /= 255;
  g /= 255;
  b /= 255;
  // 0 means opposit colors, 1 means same colors
  return (r + g + b) / 3;
};
export const calculateColorDifference = (color1, color2) => {
  var colorIntensity0 = (color1.r + color1.g + color1.b) / 3;
  var colorIntensity1 = (color2.r + color2.g + color2.b) / 3;
  var delta1 = Math.round(colorIntensity1 - colorIntensity0);
  return delta1;
};

export function compare(a, sortingKey, b, sortingOrder) {
  const item1 = a[sortingKey];
  const item2 = b[sortingKey];
  return item1 === item2 ? 0 : item1 < item2 ? -sortingOrder : sortingOrder;
}

export const hexToRGB = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : {}; //handle the return value in function calling this function
};

export const hextoLum = hex => {
  const hsl = hexToHSL(hex);
  return hsl[2];
};

export const hexToHSL = hex => {
  const { r = 0, g = 0, b = 0 } = hexToRGB(hex);
  const hsl = space.rgb.hsl([r, g, b]);
  return hsl;
};
const getNewColor = (color, delta) => {
  var newColor = color + delta;
  newColor = newColor > 255 ? 255 : newColor;
  newColor = newColor < 0 ? 0 : newColor;
  return newColor;
};
export const getNewTonalColor = ({ newColor, origColor1, origColor2 }) => {
  const delta = calculateColorDifference(hexToRGB(origColor1), hexToRGB(origColor2));
  const newColor1 = getNewTonalColorValues({ newColor, delta });
  const newColorName = `R${newColor1.RGB.r} G${newColor1.RGB.g} B${newColor1.RGB.b}`;
  return { ColorName: newColorName, Color: newColor1.HEX };
};
const getNewTonalColorValues = ({ newColor, delta }) => {
  var newColorHEX = newColor.Color;
  var newColorRGB = hexToRGB(newColorHEX);
  var tonalColor = {
    r: getNewColor(newColorRGB.r, delta),
    g: getNewColor(newColorRGB.g, delta),
    b: getNewColor(newColorRGB.b, delta)
  };
  var tonalColorHEX = rgbToHex([tonalColor.r, tonalColor.g, tonalColor.b]);

  return { RGB: tonalColor, HEX: tonalColorHEX };
};
export const previewMaterial = ({ texture, selectedColor, yarnIndex, DesignColors }) => {
  const tex = parseInt(texture || "-1");
  if (tex !== -1) return tex;
  if (selectedColor && selectedColor === -1) return -1;
  let yi = yarnIndex || 0;
  yi = yi === -1 ? 0 : yi;
  if (yarnIndex === -1) return DesignColors[selectedColor].YarnDetails[yi].Material;
};
export const getPomUrl = (color, material, colorPomsShape = "round") => {
  const matName = material && parseInt(material) === 1 ? "silk" : "wool";
  const color_hex = color.replace("#", "");
  const hex_r = color_hex.substr(0, 2);
  const hex_g = color_hex.substr(2, 2);

  var colorPomImageUrl = `${pomsUrlPrefix}${matName}/${colorPomsShape}/${hex_r}/${hex_g}/${color_hex}.png`;
  return colorPomImageUrl;
};
export const rgbToHex = ([r, g, b]) => {
  return (
    "#" +
    [r, g, b]
      .map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
};

export const sortByKnots = (colorPalette, DesignColors) => {
  var sorted = colorPalette.sort(function(a, b) {
    var x = colorPalette.indexOf(a),
      y = colorPalette.indexOf(b);
    return DesignColors[x].Knots > DesignColors[y].Knots
      ? -1
      : DesignColors[x].Knots < DesignColors[y].Knots
      ? 1
      : 0;
  });
  return sorted;
};
export const RGB2HEX = (r, g, b) => {
  // eslint-disable-next-line no-array-constructor
  var hex = new Array(
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "0A",
    "0B",
    "0C",
    "0D",
    "0E",
    "0F",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "1A",
    "1B",
    "1C",
    "1D",
    "1E",
    "1F",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "2A",
    "2B",
    "2C",
    "2D",
    "2E",
    "2F",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "3A",
    "3B",
    "3C",
    "3D",
    "3E",
    "3F",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "4A",
    "4B",
    "4C",
    "4D",
    "4E",
    "4F",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "5A",
    "5B",
    "5C",
    "5D",
    "5E",
    "5F",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "6A",
    "6B",
    "6C",
    "6D",
    "6E",
    "6F",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "7A",
    "7B",
    "7C",
    "7D",
    "7E",
    "7F",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "8A",
    "8B",
    "8C",
    "8D",
    "8E",
    "8F",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    "9A",
    "9B",
    "9C",
    "9D",
    "9E",
    "9F",
    "A0",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "AA",
    "AB",
    "AC",
    "AD",
    "AE",
    "AF",
    "B0",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "BA",
    "BB",
    "BC",
    "BD",
    "BE",
    "BF",
    "C0",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "CA",
    "CB",
    "CC",
    "CD",
    "CE",
    "CF",
    "D0",
    "D1",
    "D2",
    "D3",
    "D4",
    "D5",
    "D6",
    "D7",
    "D8",
    "D9",
    "DA",
    "DB",
    "DC",
    "DD",
    "DE",
    "DF",
    "E0",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "EA",
    "EB",
    "EC",
    "ED",
    "EE",
    "EF",
    "F0",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "FA",
    "FB",
    "FC",
    "FD",
    "FE",
    "FF"
  );
  return "#" + hex[r] + hex[g] + hex[b];
};
