import { H3 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { infoText1, infoText2 } from "./../constants";

let infoText1 = "Available for custom orders";
let infoText2 = [
  "Scan the QR code with",
  "your phone to customize",
  "and view this design in",
  "your room"
];
const EllipseDiv = styled.div`
  z-index: 10;
  width: 60vh;
  height: 60vh;
  width: 50vh;
  height: 50vh;
  position: absolute;
  bottom: 0;
  ${props => (!props.hasinstanceurl ? " width: 40vh;height: 40vh;" : "")}
  left: ${props => (props.position === 0 ? "0px" : "calc(100% - 60vh)")};
  left: ${props => (props.position === 0 ? "0px" : "calc(100% - 50vh)")};
  ${props =>
    !props.hasinstanceurl ? (props.position === 0 ? "left:0px;" : "left:calc(100% - 40vh);") : ""}
  margin-left: ${props => (props.position !== 0 ? (props.showSettings ? " -19.5rem" : "0") : "0")};
  @media (max-width: 1700px) {
    ${props =>
      props.position !== 0
        ? props.showSettings
          ? "margin-left: -20vw"
          : "margin-left:0"
        : "margin-left:0"};
  }
  @media (max-width: 1350px) {
    ${props =>
      props.position !== 0
        ? props.showSettings
          ? "margin-left: -15rem"
          : "margin-left:0"
        : "margin-left:0"};
  }

  transition: ${props => (props.position !== 0 ? "margin-left 1.5s" : "")};
  transform: ${props => (props.position === 0 ? "translate(-25%, 8%)" : "translate(25%, 8%)")};
  overflow: visible;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0.8;
    clip-path: circle(50% at 50% 50%);
    position: absolute;
    background-color: #4e4e4e;
    background-color: hsl(${props => props.newColor}, 80%, 39%);
    transition: background-color 0.5s ease
      ${props => (props.position !== 0 ? ", margin-left 1.5s" : "")};
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:before {
      border-radius: 50%;
    }
  }

  @media screen and (min-width: 640px), screen\9 {
    &:before {
      border-radius: 50%;
    }
  }

  @media \0screen {
    &:before {
      border-radius: 50%;
    }
  }
  @supports (-ms-ime-align: auto) {
    &:before {
      border-radius: 50%;
    }
  }

  &.nullClass {
    opacity: 0;
  }
  

  &.changeColor {
  }
`;
const ContentDiv = styled.div`
  width: fit-content;
  position: absolute;
  top: 8%;
  ${props => (!props.hasinstanceurl ? "top: calc(8% + 1rem);" : "")}
  ${props => (props.position === 0 ? "left: 25%" : "right: 25%")};
  transition: right 1.5s;
  text-align: ${props => (props.position === 0 ? "left" : "right")};
  color: ${props => props.textcolor};
  padding: 2rem 2.3rem;
  height: calc(52vh - 4rem);
  height: calc(45vh - 4rem);
  z-index: 25;
  width: 75%;
  box-sizing: border-box;

  @media (max-height: 900px) {
    padding: 1rem 2.3rem;
  }
  @media (max-height: 500px) {
    padding: 1rem;
  }
`;
const DesignHead = styled.div`
  font-size: 2rem;
  line-height: 2.2rem !important;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0.5rem;
  border-bottom: 3px solid #fff;
  width: fit-content;
  position: relative;
  top: 0px;
  ${props => (props.position === 0 ? "" : "margin-right: 0;margin-left: auto;")}
  ${props => (!props.hasinstanceurl ? " font-size: 1.8rem;" : "")}
 
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;

  & > div {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  & span {
    margin: 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  & span.second {
    -webkit-line-clamp: 1;
  }
  @media (max-height: 900px) {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding-bottom: 0.2rem;
  }
  @media (max-height: 800px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-bottom: 0;
  }
  ${props => (props.count > 32 ? "font-size: 1.5rem;" : "")}
  @media (max-height: 500px) {
    font-size: 12px;
    line-height: 12px;
    border-bottom: 1px solid #fff;
    margin-bottom: 4px;
  }
  &.entry {
    animation: animateDesignHead 1.8s forwards;
  }
  &.exit {
    animation: animateDesignHeadRev 1.8s forwards;
  }

  @keyframes animateDesignHead {
    0% {
      top: -20px;
      opacity: 0;
    }
    100% {
      top: 0px;
      opacity: 1;
    }
  }
  @keyframes animateDesignHeadRev {
    0% {
      top: 0px;
      opacity: 1;
    }
    100% {
      top: -20px;
      opacity: 0;
    }
  }
`;
const DesignContents = styled.div`
  position: relative;
  bottom: 0px;
  transition: top 1s;

  ${props => (!props.hasinstanceurl ? "margin-top: 1rem;" : "")}

  &.entry {
    animation: animateDesignContents 1.8s forwards;
  }
  &.exit {
    animation: animateDesignContentsRev 1.8s forwards;
  }
  @keyframes animateDesignContents {
    0% {
      bottom: -20px;
      opacity: 0;
    }
    100% {
      bottom: 0px;
      opacity: 1;
    }
  }
  @keyframes animateDesignContentsRev {
    0% {
      bottom: 0px;
      opacity: 1;
    }
    100% {
      bottom: -20px;
      opacity: 0;
    }
  }
`;

const CustomOrderInfo = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 1rem;
  ${props => (!props.show ? "display: none;" : "")}
  @media (max-height: 900px) {
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
  }
  @media (max-height: 800px) {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
  }
  @media (max-height: 500px) {
    font-size: 8px;
    line-height: 8px;
  }
`;
const ScanInfo = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 700;
  width: fit-content;
  ${props => (!props.show ? "display: none;" : "")}
  @media (max-height: 900px) {
    font-size: 0.9rem;
  }
  @media (max-height: 500px) {
    font-size: 8px;
    line-height: 8px;
    margin-bottom: 4px;
  }

  ${props => (props.position === 0 ? "" : "margin-right: 0;margin-left: auto;")}

  & p {
    margin: 0;
  }
`;
const QRCodeDiv = styled.div`
  height: 7.55rem;
  margin-bottom: 0.625rem;
  margin-bottom: 1rem;
  overflow: hidden;
  @media (max-height: 800px) {
    height: 5rem;
    margin-bottom: 0.75rem;
  }
  @media (max-height: 500px) {
    font-size: 8px;
    line-height: 8px;
    margin-bottom: 4px;
    height: 3.5rem;
  }
`;
const QRImg = styled.img`
  position: absolute;
  ${props => (props.position === 0 ? "left:0;" : "right:0;")}
  width: 7.55rem;
  height: 7.55rem;
  padding: 0.5rem;
  background: white;
  box-sizing: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  animation: ${props => (props.showp === 1 ? "imgtransitionfwd 1.5s" : "imgtransitionrev 1.2s")}
    forwards;
  @media (max-height: 800px) {
    height: 5rem;
    width: 5rem;
  }

  @media (max-height: 500px) {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 4px;
  }
  @keyframes imgtransitionfwd {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes imgtransitionrev {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
const DesignLink = styled.a`
  position: absolute;
  font-size: 0.75rem;
  @media (max-height: 900px) {
    font-size: 0.7rem;
  }
  @media (max-height: 800px) {
    font-size: 0.65rem;
    line-height: 0.7rem;
  }
  
  font-weight: 600;
  line-height: 0.8rem;
  display:block;
  color: ${props => props.textcolor};
  text-decoration:none;
  ${props => (props.position === 0 ? "left:0;" : "right:0;")}
  animation: ${props =>
    props.showp === 1 ? "linktransitionfwd 1.5s" : "linktransitionrev 1.2s"} forwards;

      @media (max-height: 500px){
  font-size: 6px;
    line-height: 6px;
}
@keyframes linktransitionfwd{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes linktransitionrev{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
`;

const InfoBubble = props => {
  const {
    DesignName,
    DesignData,
    animationDur,
    showSettings,
    hasinstanceurl,
    pause,
    remTime
  } = props;
  const [classforEllipse, setclassforEllipse] = useState("nullClass");
  const [position, setposition] = useState(1);
  const [textcolor, settextcolor] = useState("#FFF");
  const [designNameState, setdesignNameState] = useState([]);
  const [headCharacterCount, setheadCharacterCount] = useState(0);
  const [count, setcount] = useState(0);
  const [exitTrigger, setexitTrigger] = useState(false);

  useEffect(() => {
    setcount(count + 1);
    getTextColor();
    if (DesignData.class === "entry") {
      let temp;
      if (position === 0) {
        temp = 1;
      } else {
        temp = 0;
      }

      setTimeout(() => {
        setposition(temp);
        setclassforEllipse("entry");
        // let nottildename = DesignName.replace(/~/g, "{'\\n'}");
        let count = 0;
        let nottildename = DesignName.split("~");
        let firstline = nottildename.shift();
        let secondline = "";
        if (nottildename.length > 0) {
          secondline = nottildename.join(" ");
        }
        nottildename = [];
        nottildename.push(firstline);
        nottildename.push(secondline);
        nottildename.forEach(element => {
          count += element.length > 16 ? element.length : 16;
        });
        setheadCharacterCount(count);
        setdesignNameState(nottildename);
      }, 10);
      if (DesignData.isDesignOnly) {
        setTimeout(() => {
          setexitTrigger(true);
        }, (animationDur - 1) * 1000);
      }
    } else if (DesignData.class === "exit") {
      setTimeout(() => {
        setexitTrigger(true);
      }, (animationDur - 1) * 1000);
    } else {
      setclassforEllipse("changeColor");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DesignData]);

  useEffect(() => {
    setTimeout(() => {
      let count = 0;
      let nottildename = DesignName.split("~");
      let firstline = nottildename.shift();
      let secondline = "";
      if (nottildename.length > 0) {
        secondline = nottildename.join(" ");
      }
      nottildename = [];
      nottildename.push(firstline);
      nottildename.push(secondline);
      nottildename.forEach(element => {
        count += element.length > 16 ? element.length : 16;
      });
      setheadCharacterCount(count);
      setdesignNameState(nottildename);
    }, 2000);
  }, [DesignName]);

  useEffect(() => {
    if (remTime === 1 && (DesignData.isDesignOnly || DesignData.class === "exit")) {
      setclassforEllipse("exit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remTime]);

  useEffect(() => {
    if (!pause && exitTrigger) {
      setclassforEllipse("exit");
      setexitTrigger(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitTrigger]);

  const handleqrclick = () => {
    window.open(DesignData.designLink, "_blank");
  };
  const getTextColor = h => {
    // Must be fractions of 1
    let s = 80;
    let l = 39;
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;
    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
    let intensity = (r + g + b) / 3;
    let returnColor = intensity > 127 ? "#000" : "#fff";
    settextcolor(returnColor);
  };

  return (
    <React.Fragment>
      <EllipseDiv
        className={`${classforEllipse} ellipseDiv`}
        newColor={DesignData.color}
        showSettings={showSettings}
        position={position}
        hasinstanceurl={hasinstanceurl}
      >
        <ContentDiv
          position={position}
          showSettings={showSettings}
          textcolor={textcolor}
          hasinstanceurl={hasinstanceurl}
        >
          <DesignHead
            position={position}
            className={classforEllipse}
            count={headCharacterCount}
            hasinstanceurl={hasinstanceurl}
          >
            {/* <span>{designNameState[0]}</span>
            <br></br>
            {designNameState[1] !== '' && <span className={"second"}>{designNameState[1]}</span> } */}
            <H3>
              {designNameState[0]}
              <br></br>
              {designNameState[1] !== "" && designNameState[1]}
            </H3>
            {/* {designNameState.map((eachname, index)=>{
              return(
                <p key = {index}>
                {eachname}<br></br>
                </p>
              )
            })} */}
          </DesignHead>
          <DesignContents className={classforEllipse} hasinstanceurl={hasinstanceurl}>
            <CustomOrderInfo count={headCharacterCount} show={hasinstanceurl}>
              {infoText1}
            </CustomOrderInfo>
            <ScanInfo position={position} count={headCharacterCount} show={hasinstanceurl}>
              {infoText2.map((eachtext, index) => {
                return <p key={index}>{eachtext}</p>;
              })}
            </ScanInfo>
            <QRCodeDiv count={headCharacterCount}>
              <QRImg
                src={DesignData.qrSrc}
                showp={count % 2 === 0 ? 0 : 1}
                position={position}
                onClick={handleqrclick}
              ></QRImg>
              <QRImg
                src={DesignData.qrSrc}
                showp={count % 2 !== 0 ? 0 : 1}
                position={position}
                onClick={handleqrclick}
              ></QRImg>
            </QRCodeDiv>

            <DesignLink
              href={DesignData.designLink}
              target="_blank"
              showp={count % 2 === 0 ? 0 : 1}
              position={position}
              textcolor={textcolor}
            >
              {DesignData.designLink}{" "}
            </DesignLink>
            <DesignLink
              href={DesignData.designLink}
              target="_blank"
              showp={count % 2 !== 0 ? 0 : 1}
              position={position}
              textcolor={textcolor}
            >
              {DesignData.designLink}{" "}
            </DesignLink>
          </DesignContents>
        </ContentDiv>
      </EllipseDiv>
      {/* <GradientDiv className={classforEllipse}  position={position}/> */}
    </React.Fragment>
  );
};
export default InfoBubble;
