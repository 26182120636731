import * as THREE from "three";
import { OrbitControls } from "../../../utils/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import {
  convertArrIntoRad,
  convertArrintoDeg,
  convertUnit,
  areaOfellipse,
  createVector
} from "../../../utils/utils";

import { readImage } from "../../../utils/domUtils";
import { CanvasTexture } from "three";
import { createCanvas } from "../../../utils/canvasutils";

export default class ThreeViewHelper {
  constructor() {
    this.scene = new THREE.Scene();

    this.textureLoader = new THREE.TextureLoader();
    this.fbxLoader = new FBXLoader();
    this.raycaster = new THREE.Raycaster();
    this.offset = new THREE.Vector3();
    this.carpetLoaded = false;
    this.objectLoaded = false;
    this.objects3d = [];
  }
  init({ canvas, config = {}, shot, dims = {}, surfaceName = "surface1", resolution, roomType }) {
    let { width = window.innerWidth, height = window.innerHeight } = dims;
    this.w = width;
    this.h = height;
    this.surfaceName = surfaceName;
    this.sceneConfig = config;
    this.objProps = config[surfaceName];
    if (roomType === "illustration") {
      canvas.style.visibility = "hidden";
    } else {
      canvas.style.visibility = "inherit";
    }
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: false
    });
    this.renderer.setPixelRatio(resolution);
    this.renderer.setSize(width, height);
    const camConfig = config[shot];
    this.camera = perspectiveCamera({ ...camConfig, width, height });
    this.scene.add(this.camera);
    window.scene = this.scene;
    this.orbit = addOrbitControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit.enabled = true;
    // this.orbit.screenSpacePanning = true;
  }
  setupLights() {
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.9);
    this.scene.add(this.directionalLight);
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(this.ambientLight);
  }
  removeLights() {
    if (this.directionalLight) this.scene.remove(this.directionalLight);
    if (this.ambientLight) this.scene.remove(this.ambientLight);
  }
  setup3dObject({ fbxUrl }) {
    if (this.carpetLoaded) {
      this.scene.remove(this.carpetMesh);
      const tarObj = this.scene.getObjectByName("TargetObject");
      this.scene.remove(tarObj);

      this.carpetLoaded = false;
      this.removeLights();
    }
    return new Promise((resolve, reject) => {
      if (!this.sceneConfig) {
        reject();
        return;
      }
      const { objects3d, surfaces, availableSizes = [] } = this.sceneConfig;
      const setupObjects = () => {
        let objectsLoaded = 0;
        objects3d.forEach(object3d => {
          const object = this.objectFbx.getObjectByName(object3d);
          if (!object) {
            console.warn("PHOTOGRAPHIC VIEW: object", object3d, "does not exist");
            return;
          }

          const objectConfig = this.sceneConfig[object3d];
          const {
            position = [0, 0, 0],
            rotation = [90, 0, 0],
            scale = [1, 1, 1],
            preset = false
          } = objectConfig;
          object.position.fromArray(position);
          object.scale.fromArray(scale);
          object.rotation.fromArray(convertArrIntoRad(rotation));
          this.scene.add(object);
          if (!preset) {
            this.objProps = objectConfig;
            if (surfaces) {
              const { back, front } = surfaces;
              this.objects3d.push(object.getObjectByName(front));
              if (back) {
                this.objectBack = object.getObjectByName(back);
                this.hasBackSurface = true;
              } else {
                this.hasBackSurface = false;
              }
            } else {
              if (this.material) {
                object.material = this.material;
                object.material.needsUpdate = true;
                this.render();
              }
              this.objects3d.push(object);
            }

            objectsLoaded++;

            if (objectsLoaded === objects3d.length) resolve(availableSizes);
          } else {
            const { defaultTexture, defaultScale = [9, 12] } = objectConfig;
            const { width: texWidth = 9, height: texHeight = 12, path } = defaultTexture;
            const textureUrl = `${this.baseUrl}/${path}`;
            let repeat = [1, 1];
            const rx = defaultScale[0] / texWidth;
            const ry = defaultScale[1] / texHeight;
            repeat = [rx, ry];
            readImage(textureUrl).then(image => {
              const { width, height } = image;
              const canv = createCanvas(width, height);
              canv.getContext("2d").drawImage(image, 0, 0, width, height);

              const texture = new THREE.CanvasTexture(canv);
              texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
              texture.colorSpace = THREE.SRGBColorSpace;
              texture.repeat.fromArray(repeat);

              let material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                side: THREE.DoubleSide,
                alphaTest: 0.5
              });

              object.material = material;
              object.material.needsUpdate = true;
              this.render();

              objectsLoaded++;
              if (objectsLoaded === objects3d.length) {
                this.objectLoaded = true;
                resolve(availableSizes);
              }
            });
          }
        });
      };
      if (!this.objectLoaded)
        this.fbxLoader.load(
          fbxUrl,
          obj => {
            this.objectFbx = obj;
            setupObjects();
          },
          undefined,
          console.error
        );
      else setupObjects();
    });
  }
  setupCarpet({ fbxUrl }) {
    this.objConf = this.sceneConfig[this.surfaceName];
    const { position = [0, 0, 0], rotation = [90, 0, 0] } = this.objConf;
    if (this.objectLoaded) {
      this.objects3d.forEach(object3d => {
        this.scene.remove(object3d);
      });
      const tarObj = this.scene.getObjectByName("TargetObject");
      if (tarObj) this.scene.remove(tarObj);
      this.removeLights();
      this.objectLoaded = false;
    }
    return new Promise((resolve, reject) => {
      const setup = () => {
        // this.originalMesh =
        this.carpetMesh.position.fromArray(position);
        const { flagged } = this.sceneConfig;
        let fact = flagged ? 10 : 1;
        this.directionalLight.position.set(
          position[0] - 3000 / fact,
          position[1] + 3000 / fact,
          position[2]
        );
        var targetObject = new THREE.Object3D();
        targetObject.name = "TargetObject";
        targetObject.position.set(...position);
        this.scene.add(targetObject);
        this.directionalLight.target = targetObject;
        // let helper = new THREE.DirectionalLightHelper(this.directionalLight, 100, 0xff0000);
        // this.scene.add(helper);

        this.carpetMesh.rotation.fromArray(convertArrIntoRad(rotation.slice(0, 3)));
        if (this.designDetails) this.setCarpetScale(this.designDetails);

        if (this.material) {
          this.carpetMesh.material = this.material;
          this.carpetMesh.material.needsUpdate = true;
          this.render();
        }
        this.carpetLoaded = true;
        this.render();
        resolve();
      };
      if (!this.carpetLoaded)
        this.fbxLoader.load(
          fbxUrl,
          obj => {
            this.carpetMesh = obj.getObjectByName(this.surfaceName);
            this.scene.add(this.carpetMesh);
            this.setupLights();
            setup();
          },
          undefined,
          console.error
        );
      else setup();
    });
  }
  setCarpetRotation(rotation) {
    const rot = convertArrIntoRad(rotation);
    if (!this.carpetLoaded) return;
    this.carpetMesh.rotation.fromArray(rot);
    this.render();
  }
  setCarpetPositon(position) {
    if (!this.carpetLoaded) return;
    this.carpetMesh.position.fromArray(position);
    this.render();
  }
  setCarpetScale(designDetails) {
    const { PhysicalWidth, PhysicalHeight, Unit, IsIrregular } = designDetails;
    if (!PhysicalWidth || !PhysicalHeight || !Unit) {
      console.error("Could not set carept scale");
      return;
    }
    const { flagged } = this.sceneConfig;
    let fact = flagged ? 10 : 1;

    const wid = convertUnit(Unit, "ft", PhysicalWidth);
    const hgt = convertUnit(Unit, "ft", PhysicalHeight);
    this.carpetMesh.scale.set(wid / fact, hgt / fact, IsIrregular ? 0.01 : 2 / fact);
  }
  set3dObjectScale(designDetails) {
    const { unit: sceneUnit = "in", availableSizes = [] } = this.sceneConfig;
    const { PhysicalWidth, PhysicalHeight, Unit } = designDetails;
    if (!PhysicalWidth || !PhysicalHeight || !Unit) {
      console.error("Could not set carept scale");
      return;
    }
    const wid = convertUnit(Unit, sceneUnit, PhysicalWidth);
    // console.log("set3dObjectScale -> wid", wid);
    const hgt = convertUnit(Unit, sceneUnit, PhysicalHeight);
    // console.log("set3dObjectScale -> hgt", hgt);
    const size = availableSizes.find(
      item => item.PhysicalWidth === wid && item.PhysicalHeight === hgt
    );
    // console.log("set3dObjectScale -> size", size);
    if (size) {
      this.change3dObjectScalePhysical(size);
      return size;
    }
  }
  change3dObjectScalePhysical(selectedSize) {
    this.objects3d.forEach(object3d => {
      const { name } = object3d;
      const { availableSizes = {} } = this.sceneConfig[name];
      const transformData = availableSizes[selectedSize.key] || {};
      const { position, rotation, scale } = transformData;
      if (position) object3d.position.fromArray(position);
      if (rotation) object3d.rotation.fromArray(convertArrIntoRad(rotation));
      if (scale) object3d.scale.fromArray(scale);
    });
    this.render();
  }
  setCarpetVisibility(visibility) {
    this.carpetMesh.visible = visibility;
    this.render();
  }
  setObjectTexture({ designDetails, designCanvas, surfaceName }) {
    return new Promise((resolve, reject) => {
      const { surfaceUnit = "in", doubleSide } = this.objProps;

      console.log("setObjectTexture -> designDetails", designDetails, surfaceUnit);
      const PhysicalWidth = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalWidth
      );
      const PhysicalHeight = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalHeight
      );
      this.designDetails = {
        ...designDetails,
        PhysicalHeight,
        PhysicalWidth,
        Unit: surfaceUnit
      };
      console.log("returnnewPromise -> designDetails", this.designDetails);
      const designTexture = new CanvasTexture(designCanvas);
      designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
      designTexture.colorSpace = THREE.SRGBColorSpace;
      this.material = new THREE.MeshBasicMaterial({
        map: designTexture,
        transparent: true,
        side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
        alphaTest: 0.5
      });
      if (!this.objects3d.length) {
        console.error("could not find the object");
        resolve();
        return;
      }
      const size = this.set3dObjectScale(this.designDetails);
      console.log("setObjectTexture -> size", size);
      this.objects3d.forEach(object3d => {
        object3d.material = this.material;
        object3d.material.needsUpdate = true;
      });

      this.render();
      resolve(size);
    });
  }
  setCarpetTexture({ designDetails, designCanvas, normapCanvas }) {
    this.designDetails = designDetails;

    const designTexture = new CanvasTexture(designCanvas);
    const normalTexture = new CanvasTexture(normapCanvas);
    // designTexture.magFilter = THREE.LinearFilter;
    // designTexture.minFilter = THREE.LinearFilter;
    designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    normalTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    designTexture.colorSpace = THREE.SRGBColorSpace;
    this.material = new THREE.MeshStandardMaterial({
      map: designTexture,
      normalMap: normalTexture,
      roughness: 1,
      metalness: 0.1,
      needsUpdate: true,
      transparent: true,
      side: THREE.FrontSide
    });
    if (!this.carpetMesh) return;
    this.setCarpetScale(this.designDetails);
    this.carpetMesh.material = this.material;
    this.carpetMesh.material.needsUpdate = true;
    this.render();
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  setFov(value) {
    const { camera } = this;
    camera.fov = value;
    camera.updateProjectionMatrix();
    this.render();
  }
  mouseDownTouchStart(e) {
    if (!this.carpetMesh) return;
    let intersect = this.raycastMouseOnCarpet(e);
    if (!intersect) return;
    const objPos = this.carpetMesh.position.clone();
    this.offset.copy(intersect.point).sub(objPos);
    return intersect;
  }

  mouseTouchMove(e) {
    if (!this.carpetMesh) return;
    //TODO:instead of casting on carpet, cast on an infinite plane
    let intersect = this.raycastMouseOnCarpet(e);
    if (!intersect) return;
    const objPos = this.carpetMesh.position.clone();
    const sub = intersect.point.sub(this.offset);
    sub.y = objPos.y;
    this.carpetMesh.position.copy(sub);
    this.render();
  }
  raycastMouseOnCarpet(e) {
    const { x, y } = e;
    let { mouseX, mouseY } = this.convMouseCord(x, y);
    var mouse = new THREE.Vector3(mouseX, mouseY, 0.99);
    this.raycaster.setFromCamera(mouse, this.camera);
    var intersects = this.raycaster.intersectObject(this.carpetMesh);
    return intersects[0];
  }
  raycastMouseOnSurface(e) {
    const { x, y } = e;
    let { mouseX, mouseY } = this.convMouseCord(x, y);
    var mouse = new THREE.Vector3(mouseX, mouseY, 0.99);
    this.raycaster.setFromCamera(mouse, this.camera);
    var intersects = this.raycaster.intersectObject(this.scene.getObjectByName(this.surfaceName));
    return intersects[0];
  }
  convMouseCord(x, y) {
    // const { offsetX, offsetY } = this.getRendererOffset();
    const vec = new THREE.Vector2();
    const { width, height } = this.renderer.getSize(vec);

    var mouseX = (x / width) * 2 - 1;
    var mouseY = -(y / height) * 2 + 1;
    return { mouseX, mouseY };
  }
  getCameraConfig() {
    const position = this.camera.position.toArray();
    const rotation = convertArrintoDeg(this.camera.rotation.toArray());
    const target = this.orbit.target.position.toArray();
    return { position, rotation, target };
  }
  rotateCarpet(rotationInDegrees, axis) {
    if (!this.carpetMesh) return;
    const { rotationFlag } = this.sceneConfig;
    if (!rotationFlag) this.carpetMesh.rotation[axis] += (rotationInDegrees * Math.PI) / 180;
    else this.carpetMesh.rotation[axis] -= (rotationInDegrees * Math.PI) / 180;
    this.render();
  }
  scaleObject(surfaceName, scaleFactor, axis) {
    let object = this.scene.getObjectByName(surfaceName);
    object.scale[axis] += scaleFactor;
    this.render();
  }
  attachTransformControls(surfaceName) {
    let object = this.scene.getObjectByName(surfaceName);

    this.transform.attach(object);
    this.scene.add(this.transform);
  }
  getRendererOffset() {
    var offsetY = this.renderer.domElement.offsetTop;
    var offsetX = this.renderer.domElement.offsetLeft;
    return { offsetX, offsetY };
  }
  async clearScene() {
    var objsToRemove = [...this.scene.children];
    for (let i = 0; i < objsToRemove.length; i++) {
      this.scene.remove(objsToRemove[i]);
    }
    if (this.renderer) this.render();
  }
  // clearScene() {
  //   while (this.scene.children.length > 0) {
  //     this.scene.remove(this.scene.children[0]);
  //   }
  //   if (this.renderer) this.render();
  // }
  clearrenderer() {
    if (this.renderer) this.renderer.clear();
  }

  toScreenXY(position, camera) {
    var pos = position.clone();
    let projScreenMat = new THREE.Matrix4();
    projScreenMat.multiply(camera.projectionMatrix, camera.matrixWorldInverse);
    projScreenMat.multiplyVector3(pos);
    const { offsetX, offsetY } = this.getRendererOffset();
    return {
      x: ((pos.x + 1) * this.w) / 2 + offsetX,
      y: ((-pos.y + 1) * this.h) / 2 + offsetY
    };
  }

  getCarpetPositions() {
    this.carpetMesh.geometry.computeBoundingBox();
    let box = this.carpetMesh.geometry.boundingBox;
    const widthheight = box.max.sub(box.min);

    const plane = new THREE.PlaneGeometry(widthheight.x, widthheight.y);
    const mat = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const m = new THREE.Mesh(plane, mat);
    m.scale.copy(this.carpetMesh.scale);
    m.position.copy(this.carpetMesh.position);
    m.rotation.copy(this.carpetMesh.rotation);
    const a = [];
    plane.vertices.forEach(vertex => {
      const v = vertex.clone();
      v.applyMatrix4(this.carpetMesh.matrixWorld);
      a.push(v);
    });
    const b = a.map(vertex => createVector(vertex, this.camera, this.w, this.h));
    return [b[0], b[1], b[3], b[2]];
  }
  getCarpetMask() {
    const object = this.carpetMesh.clone();
    // this.scene.background = new THREE.Color(0xffffff);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
    object.material = material;
    object.material.needsUpdate = true;
    this.render();
    const dataurl = this.renderer.domElement.toDataURL();
    // this.scene.background = originalBg;
    // object.material = originalMat;
    // this.render();
    return dataurl;
  }
  toggleOrbitControls(enable) {
    this.orbit.enabled = enable;
    this.orbit.update();
  }
  toggleScreenSpacePanning(enable) {
    this.orbit.screenSpacePanning = enable;
    this.orbit.update();
  }
  toggleOrbitLockAxis(axis, enable) {
    if (axis === 0) {
      this.orbit.lockVertical = enable;
      this.orbit.update();
    } else if (axis === 1) {
      this.orbit.lockHorizontal = enable;
      this.orbit.update();
    }
  }
  changeShot(shotConfig) {
    const { position, rotation, target, fov } = shotConfig;
    const { camera } = this;
    camera.fov = fov;
    camera.updateProjectionMatrix();
    camera.position.fromArray(position);
    camera.rotation.fromArray(rotation);
    // camera.lookAt(...target);
    this.orbit.target.fromArray(target);
    this.orbit.update();
    this.render();
  }
  updateMap() {
    if (this.carpetMesh && this.carpetMesh.material.map) {
      this.carpetMesh.material.map.needsUpdate = true;
      this.carpetMesh.material.normalMap.needsUpdate = true;
      this.carpetMesh.material.needsUpdate = true;
    }
    this.objects3d.forEach(object3d => {
      if (object3d && object3d.material.map) {
        object3d.material.map.needsUpdate = true;
        object3d.material.needsUpdate = true;
      }
    });
    this.render();
  }
  resizeRenderer({ width, height }) {
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
  getObjectConfig() {
    if (this.objectLoaded) {
      return null;
    } else {
      return this.carpetMesh;
    }
  }
  calculateCarpetSize() {
    if (!this.carpetMesh) return;
    const carpetSize = new THREE.Vector3();
    var box = new THREE.Box3();
    box.setFromObject(this.carpetMesh);
    box.getSize(carpetSize);
    // this.carpetMesh.geometry.computeBoundingBox();
    // this.carpetMesh.geometry.boundingBox.getSize(carpetSize);
    return carpetSize;
  }
  distbetween2Vertices(vertex1, vertex2, axis) {
    const { camera, renderer } = this;
    const vec = new THREE.Vector2();
    renderer.getSize(vec);
    const { x: width, y: height } = vec;
    const v1 = createVector(vertex1, camera, width, height);
    const v2 = createVector(vertex2, camera, width, height);
    const xDist = Math.abs(Math.abs(v2.x) - Math.abs(v1.x));
    const yDist = Math.abs(Math.abs(v2.y) - Math.abs(v1.y));
    return { xDist: xDist, yDist: yDist };
  }
  getGizmoCordinates() {
    const carpetSize = this.calculateCarpetSize();
    if (!carpetSize) return;
    const smallerDim = carpetSize.x > carpetSize.y ? carpetSize.x : carpetSize.y;
    const carpetRadius = smallerDim / 5;
    const carpetCenter = this.carpetMesh.position.clone();

    const vertex1 = carpetCenter.clone();
    const vertex2 = new THREE.Vector3(
      carpetCenter.x,
      carpetCenter.y,
      carpetCenter.z + carpetRadius
    );

    const dist1 = this.distbetween2Vertices(vertex1, vertex2);
    const radYY = dist1.yDist;
    const radYX = dist1.xDist;

    const vertex3 = new THREE.Vector3(
      carpetCenter.x + carpetRadius,
      carpetCenter.y,
      carpetCenter.z
    );
    //TODO:this could be point of failure
    const vertex4 = carpetCenter.clone();
    const dist2 = this.distbetween2Vertices(vertex3, vertex4);
    const radXX = dist2.xDist;
    const radXY = dist2.yDist;

    const area1 = areaOfellipse(radYY, radXX);
    const area2 = areaOfellipse(radXY, radYX);
    let radX, radY;
    if (area1 > area2) {
      radX = radXX;
      radY = radYY;
    } else {
      radX = radXY;
      radY = radYX;
    }
    const canvasCenter = createVector(carpetCenter, this.camera, this.w, this.h);
    return { radX, radY, canvasCenter };
  }
  // downloadImageData() {
  //   const dataurl = this.renderer.domElement.toBlob(blob => {
  //     var strData = URL.createObjectURL(blob);
  //     var link = document.createElement("a");
  //     document.body.appendChild(link); //Firefox requires the link to be in the body
  //     link.setAttribute("download", "download.png");
  //     link.href = strData;
  //     link.click()
  //     document.body.removeChild(link); //remove the link when done
  //   })

  // }
}
export const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new THREE.Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};
const renderer = (canvas, config = {}) => {
  const { innerWidth, innerHeight, devicePixelRatio } = window;
  let {
    width = innerWidth,
    height = innerHeight,
    preserveDrawingBuffer = true,
    alpha = true,
    // antialias = false,
    resolution = 1
  } = config;
  //console.log(width, height);
  const renderer = new THREE.WebGLRenderer({
    // canvas,
    preserveDrawingBuffer,
    alpha
    // autoClear: false
  });
  // renderer.autoClear = false;
  // renderer.setClearColor(0x000000);
  // renderer.sortObjects = false;
  // renderer.autoClearColor = false;
  renderer.setPixelRatio(resolution);
  renderer.setSize(width, height);
  return renderer;
};

export const addOrbitControl = function(renderer, scene, camera, config = {}) {
  let { target = [0, 0, 0] } = config;
  const control = new OrbitControls(camera, renderer.domElement);
  control.enableKeys = false;
  control.target = new THREE.Vector3(...target);
  control.addEventListener("change", () => {
    renderer.render(scene, camera);
  });
  control.update();
  return control;
};
export const loadFbx = url => {
  return new Promise((resolve, reject) => {
    new FBXLoader().load(url, resolve, undefined, reject);
  });
};
