import React, { useState, useEffect } from "react";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import draftToHtml from "draftjs-to-html";

const DesignStory = props => {
  const designListState = useDesignListState();
  const { similarDesignsFolder } = designListState;
  const [storyHtml, setStoryHtml] = useState(null);
  useEffect(() => {
    const rawStory = similarDesignsFolder.story;
    let html = "";
    if (rawStory) {
      html = draftToHtml(rawStory);
    }
    setStoryHtml(html);
  }, [similarDesignsFolder]);

  return (
    <div className={"at-des-details__design-story"}>
      <div dangerouslySetInnerHTML={{ __html: storyHtml }}></div>
    </div>
  );
};

DesignStory.propTypes = {};

export default DesignStory;
