import * as THREE from "three";
import { MeshStandardMaterial, PerspectiveCamera, Vector2, Vector3 } from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { convertArrIntoRad, convertUnit, resizeKeepingAspect } from "../../../utils/utils";
import { readImage } from "../../../utils/domUtils";
import CameraControls from "../../organisms/IllustrationView/cameracontrols/camera-controls";
import TileCanvas from "../../../tilecanvasnew";
import { createCanvas } from "../../../utils/canvasutils";

CameraControls.install({ THREE: THREE });
let width = window.innerWidth,
  height = window.innerHeight;
function makeUrl() {
  let res = "";
  Array.from(arguments).forEach((argument, index) => {
    if (argument) {
      res = `${res}${index === 0 ? "" : "/"}${argument}`;
    }
  });
  return res;
}
const tileCanvas = new TileCanvas();

export default class PerspectiveView {
  constructor() {
    this.lightsInScene = [];
  }
  reset() {
    return new Promise(async (resolve, reject) => {
      const length = this.scene?.children?.length;
      if (length) {
        for (let index = length - 1; index >= 0; index--) {
          this.scene.remove(this.scene.children[index]);
          if (index === 0) {
            this.objectLoaded = false;
            this.objProps = null;
            this.objectPositonInitial = null;
            this.object = null;
            this.objectBack = null;
            this.hasBackSurface = false;
            this.floor = null;
            this.floorprops = null;
            this.scene = null;
            this.renderer = null;
            this.camera = null;
            resolve();
          }
        }
      } else {
        resolve();
      }
    });
  }
  async init({ canvas, config, baseUrl }) {
    await this.reset();
    this.scene = new THREE.Scene();
    this.baseUrl = baseUrl;
    this.fbxLoader = new FBXLoader();
    this.raycaster = new THREE.Raycaster();
    this.roomName = config.name;
    this.w = width;
    this.h = height;
    canvas.width = width;
    canvas.height = height;
    this.sceneConfig = config[config.scenes[0]];
    const {
      Shot_1: shot1,
      directionalLight = {
        position: [-3000, 3000, 1000],
        intensity: 1.1
      }
    } = this.sceneConfig;
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: false
    });
    if (this.sceneConfig.shadowMap) {
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE[this.sceneConfig.shadowMap];
    }
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.w, this.h);
    this.camera = perspectiveCamera({ ...shot1, width, height });
    const enableRightClick = config.enableRightClick;
    this.orbit = addCameraControl.call(
      this,
      this.renderer,
      this.scene,
      this.camera,
      shot1,
      enableRightClick
    );
    // this.orbit = addOrbitControl(this.renderer, this.scene, this.camera, shot1);
    this.orbit.enabled = true;

    if (!this.sceneConfig.lights) {
      this.dirLightConfig = directionalLight;
      let ambient = new THREE.AmbientLight(0xffffff, 0.3);
      ambient.name = "amblight";
      if (!this.scene.getObjectByName("amblight")) this.scene.add(ambient);
      this.light = new THREE.DirectionalLight(0xffffff, this.dirLightConfig.intensity);

      this.light.castShadow = true;
      this.light.name = "dirlight";
      if (!this.scene.getObjectByName("dirlight")) this.scene.add(this.light);
    }
  }
  setup3dObject({ fbxUrl }) {
    return new Promise((resolve, reject) => {
      if (!this.sceneConfig) return;
      const { objects3d, surfaces } = this.sceneConfig;

      const resolveFunc = (async () => {
        await this.setupLights();
        resolve();
      }).bind(this);

      const setupObjects = () => {
        let objectsLoaded = 0;
        objects3d.forEach(object3d => {
          if (
            window.flags &&
            window.flags.perspectiveView &&
            !window.flags.perspectiveView.renderDefaultFloor
          ) {
            if (object3d.toLowerCase() === "floor") {
              this.render();

              objectsLoaded++;
              if (objectsLoaded === objects3d.length) {
                this.objectLoaded = true;
                resolveFunc();
              }
              return;
            }
          }
          const object = this.objectFbx.getObjectByName(object3d);
          if (!object) {
            console.warn("PHOTOGRAPHIC VIEW: object", object3d, "does not exist");
            return;
          }
          this.scene.add(object);
          const objectConfig = this.sceneConfig[object3d];
          const {
            position = [0, 0, 0],
            rotation = [90, 0, 0],
            scale = [1, 1, 1],
            preset = false
          } = objectConfig;

          var targetObject = new THREE.Object3D();
          targetObject.name = "TargetObject";
          targetObject.position.set(...position);
          this.scene.add(targetObject);
          if (this.dirLightConfig) {
            const { position: lightPos } = this.dirLightConfig;
            this.light.position.set(
              position[0] + lightPos[0],
              position[1] + lightPos[1],
              position[2] + lightPos[2]
            );
            this.light.target = targetObject;
          }
          object.position.fromArray(position);
          object.scale.fromArray(scale);
          object.rotation.fromArray(convertArrIntoRad(rotation));
          object.castShadow = objectConfig.shadow ? objectConfig.shadow.cast : true;
          object.receiveShadow = objectConfig.shadow ? objectConfig.shadow.receive : true;
          if (!preset) {
            this.objProps = objectConfig;
            if (surfaces) {
              const { back, front } = surfaces;
              this.object = object.getObjectByName(front);
              this.objectPositonInitial = position;
              if (this.sceneConfig[front].shadow) {
                this.object.castShadow = this.sceneConfig[front].shadow.cast;
                this.object.receiveShadow = this.sceneConfig[front].shadow.receive;
              }
              if (back) {
                this.objectBack = object.getObjectByName(back);
                this.hasBackSurface = true;
                this.objectPositonInitial = null;
                if (this.sceneConfig[back].shadow) {
                  this.objectBack.castShadow = this.sceneConfig[back].shadow.cast;
                  this.objectBack.receiveShadow = this.sceneConfig[back].shadow.receive;
                }
              } else {
                this.hasBackSurface = false;
              }
            } else {
              this.object = object;
              this.objectPositonInitial = position;
            }
            if (this.designDetails) this.setCarpetScale(this.designDetails);

            if (this.material) {
              this.object.material = this.material;
              this.object.material.needsUpdate = true;
              this.render();
            }
            objectsLoaded++;

            if (objectsLoaded === objects3d.length) {
              this.objectLoaded = true;
              resolveFunc();
            }
          } else {
            this.floor = object;
            this.floorprops = objectConfig;
            const { textureOptions, defaultScale = [9, 12] } = objectConfig;
            const {
              width: texWidth = 9,
              height: texHeight = 12,
              path
            } = textureOptions.defaultTexture;
            const textureUrl = path;
            let repeat = [1, 1];
            const rx = defaultScale[0] / texWidth;
            const ry = defaultScale[1] / texHeight;
            repeat = [rx, ry];
            let material = new THREE.MeshStandardMaterial({
              transparent: true,
              side: THREE.DoubleSide,
              metalness: 0,
              roughness: 1
            });
            object.material = material;
            object.material.needsUpdate = true;
            if (path)
              readImage(makeUrl(this.baseUrl, textureUrl)).then(image => {
                const { width, height } = image;
                const canv = createCanvas(width, height);
                canv.getContext("2d").drawImage(image, 0, 0, width, height);

                const texture = new THREE.CanvasTexture(canv);
                texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.colorSpace = THREE.SRGBColorSpace;
                texture.repeat.fromArray(repeat);

                material.map = texture;

                object.material.needsUpdate = true;
                this.render();

                objectsLoaded++;
                if (objectsLoaded === objects3d.length) {
                  this.objectLoaded = true;
                  resolveFunc();
                }
              });
            else {
              this.render();

              objectsLoaded++;
              if (objectsLoaded === objects3d.length) {
                this.objectLoaded = true;
                resolveFunc();
              }
            }
          }
        });
      };
      if (!this.objectLoaded)
        this.fbxLoader.load(
          fbxUrl,
          obj => {
            this.objectFbx = obj;
            setupObjects();
          },
          undefined,
          console.error
        );
      else setupObjects();
    });
  }
  setCarpetScale(designDetails) {
    if (!this.objProps.resizable) return;
    const { PhysicalWidth, PhysicalHeight, Unit, IsIrregular } = designDetails;
    if (!PhysicalWidth || !PhysicalHeight || !Unit) {
      console.error("Could not set carept scale");
      return;
    }
    const { flagged } = this.sceneConfig;
    let fact = flagged ? 10 : 1;

    const wid = convertUnit(Unit, "ft", PhysicalWidth);
    const hgt = convertUnit(Unit, "ft", PhysicalHeight);
    this.object.scale.set(wid / fact, hgt / fact, IsIrregular ? 0.1 : 2 / fact);
    if (this.objectBack) {
      this.objectBack.scale.set(wid / fact, hgt / fact, IsIrregular ? 0.1 : 2 / fact);
    }
  }
  setObjectTexture({ designDetails, designCanvas, backDesignCanvas, normapCanvas }) {
    return new Promise((resolve, reject) => {
      const { surfaceUnit = "in" } = this.objProps;
      const PhysicalWidth = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalWidth
      );
      const PhysicalHeight = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalHeight
      );
      this.designDetails = {
        ...designDetails,
        PhysicalHeight,
        PhysicalWidth,
        Unit: surfaceUnit
      };
      const designTexture = new THREE.CanvasTexture(designCanvas);
      const normalTexture = new THREE.CanvasTexture(normapCanvas);
      designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      normalTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
      designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
      designTexture.colorSpace = THREE.SRGBColorSpace;
      // designTexture.flipY = false;
      const { normalScale: normArr = [1, 1] } = this.sceneConfig;
      const normalScale = new Vector2(...normArr);
      this.material = new THREE.MeshStandardMaterial({
        map: designTexture,
        normalMap: normalTexture,
        // color: 0xff0000,
        roughness: 1,
        metalness: 0,
        needsUpdate: true,
        transparent: true,
        normalScale,
        side: THREE.DoubleSide,
        alphaTest: 0.5
      });
      if (!this.object) {
        console.error("could not find the object");
        resolve();
        return;
      }
      this.object.material = this.material;
      this.object.material.needsUpdate = true;
      if (this.hasBackSurface && backDesignCanvas) {
        const designTextureBack = new THREE.CanvasTexture(backDesignCanvas);
        designTextureBack.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        designTextureBack.wrapS = designTextureBack.wrapT = THREE.RepeatWrapping;
        designTextureBack.colorSpace = THREE.SRGBColorSpace;
        this.materialBack = new THREE.MeshBasicMaterial({
          map: designTextureBack,
          transparent: true,
          side: THREE.DoubleSide,
          alphaTest: 0.5,
          needsUpdate: true
        });
        this.objectBack.material = this.materialBack;
        this.objectBack.material.needsUpdate = true;
      }
      this.setCarpetScale(designDetails);
      if (this.objectPositonInitial) {
        if (designDetails.IsIrregular) {
          this.object.position.y = this.objectPositonInitial[1] + 10;
        } else {
          this.object.position.y = this.objectPositonInitial[1];
        }
      }
      this.render();
      resolve();
    });
  }
  async setupLights() {
    const { lights: lightsConfig } = this.sceneConfig;
    if (!lightsConfig || !Object.keys(lightsConfig).length) return;
    this.lightsInScene.forEach(light => {
      this.scene.remove(light);
    });
    this.lightsInScene = [];
    const { shadowCamSize = 5, sources } = lightsConfig;

    for (let index = 0; index < sources.length; index++) {
      const {
        type,
        intensity = 1,
        position = [0, 0, 0],
        shadowRadius = 1,
        name = "light",
        color = "#ffffff"
      } = sources[index];
      const lightColor = new THREE.Color(color);
      const secondColor = "#808080";
      let light;
      switch (type) {
        case "DIRECTIONAL":
          light = new THREE.DirectionalLight(lightColor, intensity);
          light.shadow.radius = shadowRadius;
          //** thorai vaye shadow half render hunxa dherai vaye carpet mathi shadow auxa*/
          const d = shadowCamSize;

          light.shadow.camera.left = -d;
          light.shadow.camera.right = d;
          light.shadow.camera.top = d;
          light.shadow.camera.bottom = -d;

          light.shadow.camera.far = 500;
          light.shadow.camera.near = 0.5;
          light.castShadow = true;
          if (process.env.NODE_ENV === "development") {
            const helper = new THREE.DirectionalLightHelper(light);
            this.scene.add(helper);
          }

          break;
        case "POINTLIGHT":
          light = new THREE.PointLight(lightColor, intensity);
          light.shadow.radius = shadowRadius;

          const e = shadowCamSize;
          light.shadow.camera.left = -e;
          light.shadow.camera.right = e;
          light.shadow.camera.top = e;
          light.shadow.camera.bottom = -e;

          light.shadow.camera.far = 500;
          light.shadow.camera.near = 0.5;
          light.castShadow = true;
          if (process.env.NODE_ENV === "development") {
            const helper = new THREE.PointLightHelper(light);
            this.scene.add(helper);
          }
          break;
        case "HEMISPHERELIGHT":
          light = new THREE.HemisphereLight(lightColor, secondColor, intensity);
          break;
        default:
          light = new THREE.AmbientLight(lightColor, intensity);
          break;
      }
      light.name = name;
      light.position.fromArray(position);
      this.lightsInScene.push(light);
      this.scene.add(light);
    }
  }
  renderDesign({ designDetails, designPath, hash }) {
    this.designPath = designPath;
    this.zoom = this.sceneConfig.designScale || 4;
    let tileTransparency = [];
    try {
      tileTransparency = this.tileDetails[`tileTransparency${this.zoom}`];
    } catch (error) {
      console.log(error);
    }
    if (window.InterfaceElements.IsJpeg) {
      this.zoom = 1;
      tileTransparency = [1];
    }
    const { DesignColors } = designDetails;
    let drawNormap =
      !DesignColors.every(
        color => color.PileHeight === DesignColors[0].PileHeight && !color.Carving
      ) ||
      tileTransparency.length ||
      !DesignColors.length;
    if (!window.flags.applyNormalMapInJPEG && window.InterfaceElements.IsJpeg) {
      drawNormap = false;
    }

    return new Promise(async (resolve, reject) => {
      tileCanvas.init({
        designDetails,
        tileSize: 256,
        zoom: this.zoom,
        tileTransparency
      });
      if (!this.objectLoaded)
        await this.setup3dObject({
          fbxUrl: makeUrl(this.baseUrl, this.sceneConfig.modelUrl)
        });
      const { canvasPadding = [0, 0] } = this.sceneConfig;
      this.designCanvasMod = createCanvas(
        tileCanvas.width + canvasPadding[0] * 2,
        tileCanvas.height + canvasPadding[1] * 2
      );
      this.normalCanvasMod = createCanvas(
        tileCanvas.width + canvasPadding[0] * 2,
        tileCanvas.height + canvasPadding[1] * 2
      );

      let backrendered = false,
        frontrendered = false;

      const resolvethis = () => {
        if (backrendered && frontrendered) resolve();
      };

      const options = {
        designDetails,
        designPath,
        hash,
        drawNormap,
        tileTransparency
      };

      if (this.sceneConfig.surfaces.back) {
        let renderBounds = this.sceneConfig[this.sceneConfig.surfaces.back]?.renderBounds;
        if (!renderBounds)
          renderBounds = {
            p1: { x: 0, y: 0 },
            p2: { x: 1024, y: 1024 }
          };
        await this.setObjectTexture({
          designDetails,
          designCanvas: this.designCanvasMod,
          normapCanvas: this.normalCanvasMod,
          backDesignCanvas: tileCanvas.canvasBack
        });
        tileCanvas.drawCanvasBackTiles(
          {
            ...options,
            renderBounds: renderBounds
          },
          undefined,
          () => {
            this.updateMap();
            backrendered = true;
            resolvethis();
          }
        );
      } else {
        backrendered = true;
        await this.setObjectTexture({
          designDetails,
          designCanvas: this.designCanvasMod,
          normapCanvas: this.normalCanvasMod,
          backDesignCanvas: tileCanvas.canvasBack
        });
        //set bounds
        this.object.geometry.computeBoundingBox();
        const box = this.object.geometry.boundingBox;
        const cameraControls = window.cameraControls;
        const scale = { ...this.object.scale };
        const bb = new THREE.Box3(
          new THREE.Vector3(box.min.x * scale.x, -500, box.min.y * scale.y),
          new THREE.Vector3(box.max.x * scale.x, 500, box.max.y * scale.y)
        );
        cameraControls.setBoundary(bb);
      }
      tileCanvas.drawCanvasTiles(options, undefined, () => {
        this.designCanvasMod
          .getContext("2d")
          .drawImage(tileCanvas.canvas, canvasPadding[0], canvasPadding[1]);
        this.normalCanvasMod
          .getContext("2d")
          .drawImage(tileCanvas.canvasNorm, canvasPadding[0], canvasPadding[1]);
        this.updateMap();
        frontrendered = true;
        resolvethis();
      });
    });
  }

  renderDesignFromCustomUrl({ customUrl, physicalWidth, physicalHeight, unit = "cm" }) {
    return new Promise((resolve, reject) => {
      readImage(customUrl)
        .then(async image => {
          const { width, height } = image;

          if (!this.objectLoaded)
            await this.setup3dObject({
              fbxUrl: makeUrl(this.baseUrl, this.sceneConfig.modelUrl)
            });
          const { canvasPadding = [0, 0] } = this.sceneConfig;

          const designCanvas = createCanvas(
            width + canvasPadding[0] * 2,
            height + canvasPadding[1] * 2
          );
          const ctx = designCanvas.getContext("2d");
          ctx.drawImage(image, canvasPadding[0], canvasPadding[1]);

          const normapCanvas = createCanvas(
            width + canvasPadding[0] * 2,
            height + canvasPadding[1] * 2
          );
          const ctxNorm = normapCanvas.getContext("2d");
          ctxNorm.fillStyle = "rgb(127,127,255)";
          ctxNorm.fillRect(canvasPadding[0], canvasPadding[1], width, height);
          let PhysicalWidth, PhysicalHeight;
          if (!physicalWidth || !physicalHeight) {
            const maxDims = { width: 1200, height: 1500 };
            const { width: newWidth, height: newHeight } = resizeKeepingAspect(
              { width, height },
              maxDims,
              "fit_inside"
            );
            PhysicalWidth = convertUnit("in", "ft", newWidth / 10);
            PhysicalHeight = convertUnit("in", "ft", newHeight / 10);
          } else {
            PhysicalWidth = convertUnit(unit, "ft", physicalWidth);
            PhysicalHeight = convertUnit(unit, "ft", physicalHeight);
          }
          const designDetails = {
            Width: width,
            Height: height,
            PhysicalWidth,
            PhysicalHeight,
            Unit: "ft"
          };
          await this.setObjectTexture({
            designDetails,
            designCanvas,
            normapCanvas
          });
          //set bounds
          this.object.geometry.computeBoundingBox();
          const box = this.object.geometry.boundingBox;
          const cameraControls = window.cameraControls;
          const scale = { ...this.object.scale };
          const bb = new THREE.Box3(
            new THREE.Vector3(box.min.x * scale.x, -500, box.min.y * scale.y),
            new THREE.Vector3(box.max.x * scale.x, 500, box.max.y * scale.y)
          );
          cameraControls.setBoundary(bb);
          this.updateMap();
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updateTiles({ designDetails, updateDesignTiles, updateNormapTiles, hash }) {
    if (!this.tileDetails) {
      return Promise.resolve();
    }
    if (designDetails.DesignColors === this.designDetails.DesignColors) return Promise.resolve();
    return new Promise((resolve, reject) => {
      let colorIndex;
      if (updateDesignTiles) colorIndex = updateDesignTiles.colorIndex;
      if (updateNormapTiles) colorIndex = updateNormapTiles.colorIndex;

      this.designDetails = designDetails;

      let colorTileData = null;
      if (colorIndex && colorIndex !== -1 && !window.flags.isFelt) {
        const tileData = this.tileDetails[`colorTileData${this.zoom}`];
        colorTileData = tileData[colorIndex].tiles;
      } else {
        //all tiles
      }
      const tileTransparency = this.tileDetails[`tileTransparency${this.zoom}`];
      const { canvasPadding = [0, 0] } = this.sceneConfig;
      const props = {
        tiles: colorTileData,
        zoom: this.zoom,
        designDetails,
        designPath: this.designPath,
        hash,
        tileTransparency
      };
      if (!updateNormapTiles) {
        tileCanvas.designTilesUpdated = true;
        tileCanvas.updateDesignTiles(props, undefined, () => {
          this.designCanvasMod
            .getContext("2d")
            .drawImage(tileCanvas.canvas, canvasPadding[0], canvasPadding[1]);
          this.updateMap();
          resolve();
        });
      } else {
        tileCanvas.normapTilesUpdated = true;
        tileCanvas.updateNormapTiles(props, undefined, () => {
          this.normalCanvasMod
            .getContext("2d")
            .drawImage(tileCanvas.canvasNorm, canvasPadding[0], canvasPadding[1]);
          this.updateMap();
          resolve();
        });
        // this.tileCanvas1x.updateNo(props, () => this.render())
      }
    });
  }
  setFloor({ texture }) {
    const { textureOptions, defaultScale = [9, 12] } = this.floorprops;
    const { width: texWidth = 9, height: texHeight = 12, path } = textureOptions[texture];
    let repeat = [1, 1];
    const rx = defaultScale[0] / texWidth;
    const ry = defaultScale[1] / texHeight;
    repeat = [rx, ry];
    readImage(makeUrl(this.baseUrl, path)).then(image => {
      const { width, height } = image;
      const canv = createCanvas(width, height);
      canv.getContext("2d").drawImage(image, 0, 0, width, height);

      const texture = new THREE.CanvasTexture(canv);
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.repeat.fromArray(repeat);
      texture.rotation = Math.PI;
      let material = new MeshStandardMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
        alphaTest: 0.5
      });

      this.floor.material = material;
      this.floor.material.needsUpdate = true;
      this.render();
    });
  }
  interseectsFloor(e) {
    const { x, y } = e;
    let { mouseX, mouseY } = this.convMouseCord(x, y);
    var mouse = new THREE.Vector3(mouseX, mouseY, 0.99);
    this.raycaster.setFromCamera(mouse, this.camera);
    var intersects = this.raycaster.intersectObjects(this.scene.children);
    return intersects[0].object.name === "Floor";
  }
  convMouseCord(x, y) {
    const vec = new THREE.Vector2();
    const { width, height } = this.renderer.getSize(vec);
    var mouseX = (x / width) * 2 - 1;
    var mouseY = -(y / height) * 2 + 1;
    return { mouseX, mouseY };
  }
  resizeRenderer({ width, height }) {
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
  getRenderedDesignImage({ designDetails, designPath, hash }) {
    const { IsIrregular } = designDetails;
    return new Promise(async (resolve, reject) => {
      tileCanvas.init({
        designDetails,
        tileSize: 256,
        zoom: 1,
        canvasSize: { width: 3600, height: 4800 },
        renderBounds: { p1: { x: 0, y: 0 }, p2: { x: 1800, y: 2400 } },
        tileTransparency: IsIrregular ? [] : [1]
      });
      if (!this.objectLoaded)
        await this.setup3dObject({
          fbxUrl: makeUrl(this.baseUrl, this.sceneConfig.modelUrl)
        });
      this.setObjectTexture({
        designDetails,
        designCanvas: tileCanvas.canvas,
        normapCanvas: tileCanvas.canvasNorm
      });
      tileCanvas.drawCanvasTiles({ designDetails, designPath, hash }, undefined, () => {
        this.updateMap();
        resolve(this.renderer.domElement.toDataURL());
      });
    });
  }
  updateMap() {
    if (this.object && this.object.material.map) {
      this.object.material.map.needsUpdate = true;
      this.object.material.normalMap.needsUpdate = true;
      this.object.material.needsUpdate = true;
    }
    if (this.objectBack && this.objectBack.material.map) {
      this.objectBack.material.needsUpdate = true;
      this.objectBack.material.map.needsUpdate = true;
    }
    this.render();
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  clearScene() {
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    if (this.renderer) {
      this.render();
    }
  }
}
// const addOrbitControl = (renderer, scene, camera, config = {}) => {
//   let { target = [0, 0, 0] } = config;
//   const control = new OrbitControls(camera, renderer.domElement);
//   control.enableKeys = false;
//   control.target = new Vector3(...target);
//   control.addEventListener("change", () => {
//     renderer.render(scene, camera);
//   });
//   control.update();
//   return control;
// };

const addCameraControl = function(renderer, scene, camera, config = {}, enableRightClick) {
  let {
    target = [0, 0, 0],
    minDistance = 1200,
    maxDistance = 3000,
    enableLeftClick = true,
    // enableRotate = true,
    // enableDamping,
    // autoRotate = false,
    // autoRotateSpeed,
    // panSpeed,
    boundingBox: bounds
  } = config;
  const clock = new THREE.Clock();

  const cameraControls = new CameraControls(camera, renderer.domElement);
  cameraControls.setTarget(target[0], target[1], target[2], false);
  cameraControls.verticalDragToForward = true;
  cameraControls.polarRotateSpeed = enableRightClick ? 1 : 0;
  cameraControls.azimuthRotateSpeed = enableRightClick ? 1 : 0;
  cameraControls.truckSpeed = enableLeftClick ? 1 : 0;
  cameraControls.minPolarAngle = convertArrIntoRad([0])[0]; // radians
  cameraControls.maxPolarAngle = convertArrIntoRad([65])[0]; // radians
  let bb;
  if (bounds.start && bounds.end) {
    bb = new THREE.Box3(new Vector3(...bounds.start), new Vector3(...bounds.end));
  } else {
    bb = new THREE.Box3(new THREE.Vector3(-1000, -500, -1500), new THREE.Vector3(500, 500, 1000));
  }
  if (bounds) {
    cameraControls.setBoundary(bb);
    cameraControls.minDistance = minDistance;
    cameraControls.maxDistance = maxDistance;
    cameraControls.boundaryFriction = 0.2;
  }
  window.cameraControls = cameraControls;

  renderer.render(scene, camera);

  const currentRoom = this.roomName;

  const anim = (() => {
    if (currentRoom !== this.roomName) return;
    const delta = clock.getDelta();
    // const elapsed = clock.getElapsedTime();
    const updated = cameraControls.update(delta);
    requestAnimationFrame(anim);
    if (updated) {
      renderer.render(scene, camera);
    }
  }).bind(this);
  anim();
  return cameraControls;
};
const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};
