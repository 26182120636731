import React from "react";
import Mixply4 from "./Mixply4";
import Mixply3 from "./Mixply3";
import Mixply2 from "./Mixply2";
import Mixply1 from "./Mixply1";
import { hexToRGB } from "../../../utils/colorutils";

const isRGBsimilarToGray = colorHex => {
  const { r = 0, g = 0, b = 0 } = hexToRGB(colorHex);
  const isRGBsimilar = isSimilar(r) && isSimilar(g) && isSimilar(b);
  return isRGBsimilar;
};
const isSimilar = colorVal => {
  const similar = Math.abs(colorVal - 128) < 8;
  return similar;
};

const CircularColorSVG = props => {
  const { yarnDetails } = props;
  let strokeColor = "#808080";
  var yarns
  if(yarnDetails && yarnDetails.length ){
    yarnDetails.forEach(yarn => {
      if (!yarn) return;
      if (isRGBsimilarToGray(yarn.Color)) {
        strokeColor = "#333";
      }
    });
    yarns = yarnDetails.map(yarn => yarn.Color);
  }
  else{
    yarns = [yarnDetails.Color];
  }
  
  const renderView = () => {
    switch (yarnDetails.length) {
      case 1:
        return <Mixply1 strokeColor={strokeColor} {...props} />;
      case 2:
        return <Mixply2 strokeColor={strokeColor} yarns={yarns} {...props} />;
      case 3:
        return <Mixply3 strokeColor={strokeColor} yarns={yarns} {...props} />;
      case 4:
        return <Mixply4 strokeColor={strokeColor} yarns={yarns} {...props} />;
      default:
        return <></>;
    }
  };
  return <>{renderView()}</>;
};

export default CircularColorSVG;
