import { useMount } from "react-use";
import AppProvider from "../api/appProvider";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../reducers/designlist.reducer";
import { mainUiActions, useUiDispatch } from "../reducers/mainui.reducer";
import { arrangeTree, findInTree, getDesignName, updateNodeProps } from "../utils/treeutils";
import { getAllFiles, updateFilesInEveryNode } from "../utils/treeutils";
import { getFromSessionStorage } from "../utils/domUtils";
import strings from "../strings";
import { useEffect } from "react";
import { designDetailActions, useDispatchDesignDetail } from "../reducers/designdetails.reducer";

export const useInitDesignMount = props => {
  const primaryList = props?.primaryList ? props.primaryList : true;
  //const { primaryList = true } = props;
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchDesignDetails = useDispatchDesignDetail();

  const dispatchUiState = useUiDispatch();

  const {
    expandSelectedFolderInit,
    hasFilterBySize,
    keepFoldersExpanded
  } = window.flags.designListTree;

  const { initDesignPath, initDesignFolder } = window.initialData;

  useMount(() => {
    if (designListState.tree || !primaryList) return;
    if (
      initDesignPath &&
      window.flags.accountSpecific.tamarian.enableCustomShare &&
      initDesignPath.includes("CustomSized")
    )
      return;
    const initDesignList = async () => {
      const nestedDesignList = await AppProvider.fetchDesignList({ struct: true });
      const initDesignName = window.flags.initDesignNameInUrl ? getDesignName(initDesignPath) : "";

      const initDesignPathInTree = findInTree({
        rawTree: nestedDesignList,
        initDesignPath,
        initDesignFolder,
        initDesignName
      });

      //code to get rooms control list of specific folder in talisman. Need to use to update their roomscontrol.
      //do NOT remove.
      // const testtree=  searchFoldersInTree(nestedDesignList[0].Children, "sudhirkadam");
      // console.log("initDesignList ->", testtree);
      // let ctrlHtml = '[';
      // testtree[0].Children.forEach(element => {
      //   console.log("initDesignList -> element.type", element.Type)

      //   if(element.Type==='file'){
      //     const design = (element.FullPath.replace('Designs','').replace('.ctf',''));
      //     ctrlHtml+='{ "rooms": [],"design": "'+design+'"},'
      //   }
      //   else{
      //     if(element.Children.length){
      //       element.Children.forEach(variationFile => {
      //         if(variationFile.Type==='file'){
      //           const design = (variationFile.FullPath.replace('Designs','').replace('.ctf',''));
      //           ctrlHtml+='{ "rooms": [],"design": "'+design+'"},'
      //         }
      //       });
      //     }
      //   }
      // });
      // console.log("initDesignList -> ctrlHtml", ctrlHtml)

      //expand the collection panels if present
      //keepFoldersExpanded overwrites expandSekectedFolderInit
      if (initDesignPath && initDesignPath !== "" && !initDesignPathInTree) {
        const message = strings.toastMessages.initDesignNotFound.replace(
          "{initDesignPath}",
          initDesignPath
        );
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: { message, intent: "warning", timeout: 7000 }
        });
      }

      const { copiedNode: tree, selectedFile, selectedFolder } = arrangeTree({
        tree: nestedDesignList,
        initDesignPath: initDesignPathInTree,
        expandSelectedFolder: expandSelectedFolderInit,
        designfromFolder: initDesignFolder,
        keepFoldersExpanded
      });
      window.getDesignTree = () => {
        console.log("initDesignList -> tree", JSON.stringify(tree));
      };

      const { files } = selectedFolder;
      const items = await AppProvider.getDesignThumbnails({ designs: [selectedFile] });
      let updatedSelectedFile = selectedFile;
      const item = items[0];
      const index = files.findIndex(fileInNode => item.fullPath === fileInNode.fullPath);
      if (index !== -1) {
        files[index] = item;
        updatedSelectedFile = item;
      }
      const updatedSelectedFolder = { ...selectedFolder, files };
      let designTree;
      designTree = updateNodeProps(tree, updatedSelectedFolder, "files");
      if (hasFilterBySize) {
        const x = getAllFiles(designTree);
        const thumbs = await AppProvider.getDesignThumbnails({ designs: x });
        designTree = updateFilesInEveryNode(designTree, thumbs);
      }

      dispatchDesignList({
        type: designListActions.SET_TREE,
        payload: {
          tree: designTree,
          selectedFolder: updatedSelectedFolder,
          selectedFile: updatedSelectedFile
        }
      });

      var tagFromUrl = getFromSessionStorage("wordcloudtag") || "";
      if (tagFromUrl !== "") {
        tagFromUrl = tagFromUrl.replace(/_/g, "&");
        dispatchDesignList({
          type: designListActions.SET_TAG_FILTER,
          payload: tagFromUrl
        });
      }
    };
    initDesignList();
  });

  useEffect(() => {
    const { selectedFile, activeVariation } = designListState;
    let {
      designProps: designDetails,
      name: designName,
      fullPath,
      customizationFlag
    } = selectedFile;
    if (!designDetails) return;
    if (window.flags.designVariations.preserveVariations || activeVariation.id) {
      if (activeVariation.designProps) designDetails = activeVariation.designProps;
      designName = activeVariation.name;
      fullPath = activeVariation.fullPath;
    }
    dispatchDesignDetails({
      type: designDetailActions.SET_DETAILS,
      payload: {
        designDetails,
        label: designName,
        fullpath: fullPath,
        customizationFlag,
        setCustomConversion: window.flags.ordersheet.customConversion.setCustomConversion || false,
        customInToCmFactor: window.flags.ordersheet.customConversion.customInToCmFactor
      }
    });
  }, [designListState.selectedFile.id]);
};
