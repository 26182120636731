import React, { useState } from "react";
import Checkbox from "./../Checkbox";
import AtDialog from "./../../../molecules/AtDialog";
import AtButton from "./../../../atoms/AtButton";
import _ from "lodash";
import {
  useDataControlSettingsState,
  useDispatchDataControlSettings,
  dataControlSettingsActions
} from "./datacontrolsettings.reducer";

import { useDesignListState } from "./../../../../reducers/designlist.reducer";
import FolderTree from "../FolderTree";

// const DataControlSettings = props => {
//   return (
//     <React.Fragment>
//       <DataControlSettingsProvider>
//         <DataControlSettingsWithProvider {...props} />
//       </DataControlSettingsProvider>
//     </React.Fragment>
//   );
// };
const DataControlSettings = props => {
  const { onDone, show, enableChangeRenderings = false } = props;
  const dataControlState = useDataControlSettingsState();
  const dispatchdataControl = useDispatchDataControlSettings();

  const designListState = useDesignListState();

  const [appliedFormData, setappliedFormData] = useState({});

  // useEffect(() => {
  //   console.log(appliedFormData);
  // }, [appliedFormData]);

  const applyFormChanges = () => {
    if (_.isEqual(dataControlState, appliedFormData)) {
      onDone(false);
    } else {
      setappliedFormData({ ...dataControlState });
      onDone(true);
    }
  };

  return (
    <AtDialog
      portalContainer={document.getElementById("app-root")}
      isOpen={show}
      onClose={() => {}}
      className=" data-control-settings-dialog"
      size="md"
    >
      <div className="data-control-settings-area">
        <AtButton
          icon="close"
          className={"data-control-close"}
          onClick={() => onDone(false)}
          minimal
          small
        />
        <section className={"data-control-left-section"}>
          <section className="data-control-settings-subsection shuffle-section">
            <h2>Shuffle options</h2>
            <Checkbox
              defaultVal={dataControlState.shuffle}
              inline
              label="Shuffle the order of designs"
              onChange={val => {
                dispatchdataControl({
                  type: dataControlSettingsActions.TOGGLE_SHUFFLE,
                  payload: val
                });
              }}
            />
          </section>
          {enableChangeRenderings && (
            <section className="data-control-settings-subsection showwhat-section">
              <h2>Show what</h2>
              <Checkbox
                defaultVal={dataControlState.shownRenderings.photorealisticDesigns}
                label="Photorealistic design views"
                onChange={val =>
                  dispatchdataControl({
                    type: dataControlSettingsActions.SET_SHOWN_RENDERINGS,
                    payload: { photorealisticDesigns: val }
                  })
                }
              />

              <Checkbox
                defaultVal={dataControlState.shownRenderings.roomViews}
                label="Room views"
                onChange={val =>
                  dispatchdataControl({
                    type: dataControlSettingsActions.SET_SHOWN_RENDERINGS,
                    payload: { roomViews: val }
                  })
                }
              />
              <div
                style={{
                  visibility: dataControlState.shownRenderings.qualifiedSelection
                    ? "hidden"
                    : "visible"
                }}
                className="input-warning"
              >
                Select at least one
              </div>
            </section>
          )}
        </section>
        <section className={"data-control-right-section"}>
          <section className={"data-control-settings-subsections"}>
            <h2>Choose designs from:</h2>
            <Checkbox
              defaultVal={dataControlState.selectedDesigns.thisDesign}
              label="This Design"
              externalControl={true}
              val={dataControlState.selectedDesigns.thisDesign}
              onClick={() =>
                dispatchdataControl({
                  type: dataControlSettingsActions.SET_SELECTED_DESIGNS,
                  payload: { thisDesign: true }
                })
              }
            />

            <Checkbox
              defaultVal={dataControlState.selectedDesigns.thisFolder}
              label="This Folder"
              externalControl={true}
              val={dataControlState.selectedDesigns.thisFolder}
              onClick={() =>
                dispatchdataControl({
                  type: dataControlSettingsActions.SET_SELECTED_DESIGNS,
                  payload: { thisFolder: true }
                })
              }
            />

            <Checkbox
              defaultVal={dataControlState.selectedDesigns.allDesigns}
              label="All Designs"
              externalControl={true}
              val={dataControlState.selectedDesigns.allDesigns}
              onClick={() =>
                dispatchdataControl({
                  type: dataControlSettingsActions.SET_SELECTED_DESIGNS,
                  payload: { allDesigns: true }
                })
              }
            />

            <Checkbox
              defaultVal={dataControlState.selectedDesigns.advancedSelection}
              label="Advanced Selection"
              externalControl={true}
              val={dataControlState.selectedDesigns.advancedSelection}
              onClick={() =>
                dispatchdataControl({
                  type: dataControlSettingsActions.SET_SELECTED_DESIGNS,
                  payload: { advancedSelection: true }
                })
              }
            />

            {designListState.tree && (
              <FolderTree
                treeContent={designListState.tree}
                showFileTree={dataControlState.selectedDesigns.advancedSelection}
                handleFolderSelection={val =>
                  dispatchdataControl({
                    type: dataControlSettingsActions.SET_SELECTION_TREE,
                    payload: { excludedFolders: val }
                  })
                }
                handleDone={() => {}}
                handleFilteredFolders={() => {}}
              />
            )}
          </section>
          <section>
            <AtButton
              id="cinematic_start_btns"
              title="Start Cinematic Mode"
              text="Apply"
              intent="primary"
              onClick={applyFormChanges}
            ></AtButton>
          </section>
        </section>
      </div>
    </AtDialog>
  );
};

export default DataControlSettings;
