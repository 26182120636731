import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CircularButton from "../../atoms/CircularButton";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";
import {
  getDesignDimensionsString
} from "../../../utils/utils";

const TemplateMainDesignWithSize = props => {
  const {
    className,
    thumb,
    onRemoveClicked,
    onThumbnailClick,
    hasRemove,
    imageRotated,
    aspect = "portrait",
    showTitle = true,
    active,
    thumbType
  } = props;
  const { thumbUrl, name, designProps, variations } = thumb;
  const [isHovering, setIsHovering] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const thumbImg = useRef(null);
  // const [scrollTo, setScrollTo] = useState(true);
  const { flags } = window;
  const [mainThumbUrl, setMainThumbUrl] = useState(thumbUrl);

  const [selectedVariation] = useState(null);
  let designVariations = [];
  if (variations) {
    designVariations =
      variations.vars && variations.vars.length
        ? variations.vars
        : variations.colors && variations.colors.length
        ? variations.colors
        : [];
  }
  useEffect(() => {
    setMainThumbUrl(thumbUrl);
  }, [thumbUrl]);
  useEffect(() => {
    setIsloading(true);
    if (!mainThumbUrl) return;
    const image = new Image();
    image.src = mainThumbUrl;
    image.onload = () => {
      try {
        thumbImg.current.src = image.src;
        thumbImg.current.backgroundImage = image.src;
      } catch (error) {
        console.error(error);
      }
      setIsloading(false);
    };
  }, [mainThumbUrl]);
  const rendername = name => {
    return (
      <>
        {name &&
          name.split("~").map((item, i) => {
            return <p key={i}>{item}</p>;
          })}
      </>
    );
  };
  const getMainBgImage = () => {
    if (
      !flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.designThumbsAsBg) ||
      thumbType === "ROOM_THUMBS"
    )
      return "none";
    const thumbSrc = thumbImg.current !== null ? "url(" + thumbImg.current.src + ")" : "none";
    return thumbSrc;
  };

  const onMainThumbClick = e => {
    if (selectedVariation) {
      const activeVariation = designVariations[selectedVariation];
      if (onThumbnailClick) onThumbnailClick(activeVariation);
    } else {
      if (onThumbnailClick) onThumbnailClick(e);
    }
  };
  return (
    <div className={classNames("designThumb-template4 thumb-item")}>
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={classNames("mainThumbArea", { active: active }, className)}
        onClick={onMainThumbClick}
      >
        <div
          className={classNames("thumb-image-container", "imageThumb", aspect, {
            "bp3-skeleton": !thumbUrl || isloading
          })}
          style={{
            backgroundImage: getMainBgImage(),
            backgroundSize: "contain"
          }}
        >
          <img
            className={classNames(`thumb-image fit-width`, {
              rotated: imageRotated,
              "thumb-hidden": getMainBgImage() !== "none"
            })}
            ref={thumbImg}
            alt="thumbnail"
          />
        </div>

        {/* <div className="newTag">New</div> */}
      </div>
      <div className="detailsContainer">
        {showTitle && (
          <div className="designName" title={name}>
            {rendername(name)}
          </div>
        )}

        {designProps && (
          <div className="sizeInfo">
            {getDesignDimensionsString({
              designDetails: designProps,
              showDesignDimensions: flags.designDetailSection.showDesignDimensions,
              showOneDimensionIfSquare: flags.designDetailSection.showOneDimensionIfSquare
            })}
          </div>
        )}
      </div>
     
      {hasRemove && isHovering && (
        <CircularButton
          icon="close"
          size="small"
          className="close-button"
          onClick={e => {
            e.stopPropagation();
            if (onRemoveClicked) onRemoveClicked(e);
          }}
        />
      )}
    </div>
  );
};

TemplateMainDesignWithSize.propTypes = {
  className: PropTypes.string,
  thumb: PropTypes.object,
  hasRemove: PropTypes.bool,
  onRemoveClicked: PropTypes.func,
  onThumbnailClick: PropTypes.func,
  thumbnailType: PropTypes.oneOf(["portrait", "landscape"]),
  showTitle: PropTypes.bool
};
export default TemplateMainDesignWithSize;
