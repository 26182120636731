import React from "react";
import PropTypes from "prop-types";
import strings from "../../../strings";
import { SectionHeading } from "../../molecules/CustomCollapse";
import Thumbnail from "../../molecules/Thumbnail";
import {
  useDispatchVisualization,
  visViewModes,
  visualizationsActions
} from "../../../reducers/visualizations.reducer";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import classNames from "classnames";
import {
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { resolveZoomValue } from "../../../middleware/visualization.middleware";

const DesignViewsCYR = props => {
  const { detailViewsOptions, currentDetailView, setCurrentDetailView, scrollIntoView } = props;
  const designListState = useDesignListState();
  const { selectedFile } = designListState;
  const dispatchVisState = useDispatchVisualization();
  const dispatchDesignState = useDispatchDesignDetail();

  const getviewModeText = mode => {
    const { designViews } = strings;
    switch (mode) {
      case visViewModes.DESIGN_VIEW_DEEPZOOM:
        return designViews.deepZoomCYR;
      case visViewModes.DESIGN_VIEW_PERSPECTIVE:
        return designViews.interactiveCYR;
      case visViewModes.DESIGN_VIEW_ZERO_ZOOM:
        return designViews.zeroZoomCYR;
      default:
        return null;
    }
  };
  return (
    <div className="at-design-views" id="atDesignViews">
      <SectionHeading
        className="at-design-views-heading at-design-views-similardesigns"
        title={strings.design.designViews}
      />

      <div className={classNames("at-color-schemes", "at-design-views-similardesigns")}>
        {detailViewsOptions.map((mode, i) => (
          <Thumbnail
            key={i}
            thumb={{
              ...selectedFile,
              name: getviewModeText(mode)
            }}
            aspect="landscape"
            active={detailViewsOptions[currentDetailView] === mode}
            className={classNames(
              visViewModes.DESIGN_VIEW_DEEPZOOM === mode ? "at-deepzoomDesign-thumb" : "",
              visViewModes.DESIGN_VIEW_PERSPECTIVE === mode ? "at-perspective-thumb" : "",
              visViewModes.DESIGN_VIEW_ZERO_ZOOM === mode ? "at-zero_zoom-thumb" : ""
            )}
            onThumbnailClick={() => {
              scrollIntoView(".cyr-detailView");
              setCurrentDetailView(i);
              dispatchVisState({
                type: visualizationsActions.SET_VIEW_MODE,
                payload: mode
              });
              if (mode === visViewModes.DESIGN_VIEW_ZERO_ZOOM) {
                dispatchDesignState({
                  type: designDetailActions.SET_DESIGN_VIEW_ZOOM,
                  payload: window.flags.designView.minZoom
                });
                {
                  window.InterfaceElements.IsJpeg &&
                    dispatchDesignState({
                      type: designDetailActions.SET_DESIGN_VIEW_ZOOM_JPEG,
                      payload: window.flags.designView.minZoomJpeg
                    });
                }
              } else {
                dispatchDesignState({
                  type: designDetailActions.SET_DESIGN_VIEW_ZOOM,
                  payload: resolveZoomValue()
                });
                {
                  window.InterfaceElements.IsJpeg &&
                    dispatchDesignState({
                      type: designDetailActions.SET_DESIGN_VIEW_ZOOM_JPEG,
                      payload: resolveZoomValue()
                    });
                }
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

DesignViewsCYR.propTypes = {};

export default DesignViewsCYR;
