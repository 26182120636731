import React, { useState, useEffect } from "react";
import ThumbList from "../../molecules/ThumbList";
import AppProvider from "../../../api/appProvider";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  useVisualizationState,
  useDispatchVisualization,
  visualizationsActions
} from "../../../reducers/visualizations.reducer";
import strings from "../../../strings";
import classNames from "classnames";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import { searchFileinTree } from "../../../utils/treeutils";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";

const searchInList = (list, text) => {
  let results = [];
  results = list.filter(i => {
    return i.label.toLowerCase().indexOf(text.toLowerCase()) !== -1;
  });
  return results;
};

const SearchResults = props => {
  const { textToSearch, type, handleThumbnailClick, id, hidden } = props; 
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const { selectedFile } = designListState;
  const VisualizationState = useVisualizationState();
  const VisualizationDispatch = useDispatchVisualization();

  const uiDispatch = useUiDispatch();
  const [results, setResults] = useState([]);

  const [debouncedThumbnailClick] = useDebouncedCallback((node, e) => {
    if (!!handleThumbnailClick) handleThumbnailClick(node, e);
  }, 800);

  const onThumbnailClick = (node, e) => {
    const activeNode = isDesignSearch(type) ? selectedFile : VisualizationState.activeNode;
  
    if (activeNode)
      if (node.id === activeNode.id) {
        console.info("clicked in active node");
        return;
      }
    uiDispatch({type: mainUiActions.SET_SHOW_WORDCLOUD, payload:false});
    dispatchActiveNode(node, type);
    debouncedThumbnailClick(node, e);
  };

  const dispatchActiveNode = (node, type) => {
    if (isDesignSearch(type)) {
      dispatchDesignList({
        type: designListActions.SELECT_DESIGN,
        payload: node
      });
    } else {
      VisualizationDispatch({
        type: visualizationsActions.SET_ACTIVE_NODE,
        payload: node
      });
    }
  };

  const isDesignSearch = () => {
    const isDesign = type === "design" ? true : false;
    return isDesign;
  };
  const startSearch = () => {
    if (textToSearch.length < 3) return;
    let searchResults;
    if (!isDesignSearch())
      searchResults = searchInList(VisualizationState.illustrations, textToSearch);
    else searchResults = searchFileinTree(designListState.tree, textToSearch);
    if (!searchResults.length) setResults(searchResults);
    else {
      if (isDesignSearch()) {
        setResults(searchResults);
        AppProvider.getDesignThumbnails({ designs: searchResults }).then(thumblist => {
          setResults(thumblist);
        });
      } else {
        setResults(searchResults);
      }
    }
  };
  useEffect(() => {
    startSearch(textToSearch);
  }, [textToSearch]);
  const getItemType = () => {
    const item = `${type}${results.length > 1 ? "s" : ""}`;
    return item;
  };
  const getNoResultText = () => {
    let txt =
      type === "design"
        ? `${strings.search.noDesignFound.firstLine} <br/> ${strings.search.noDesignFound.secondLine}`
        : `${strings.search.noVisualizationFound.firstLine} <br/> ${strings.search.noVisualizationFound.secondLine}`;
    return txt;
  };
  return (
    <div className={classNames("at-search", { hidden: hidden })} id={id}>
      {results.length ? (
        <>
          <span className="at-search__text">
            {" "}
            {`${strings.search.found} ${results.length} ${
              isDesignSearch()
                ? strings.design[getItemType()]
                : strings.visualization[getItemType()]
            }`}
          </span>
          <ThumbList
            thumbType= {!isDesignSearch() ?"ROOM_THUMBS":null}
            aspect = "landscape"
            thumbList={results}
            onThumbnailClick={onThumbnailClick}
            activeId={selectedFile.id}
          />
        </>
      ) : (
        <span
          className="at-search__no-results"
          dangerouslySetInnerHTML={{ __html: getNoResultText() }}
        ></span>
      )}
    </div>
  );
};

export default SearchResults;
