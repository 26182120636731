/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import AtDialog from "../../molecules/AtDialog";
import { H5, FormGroup, InputGroup, Button } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import AtIcon from "../../atoms/AtIcon";
import strings from "../../../strings";

const FavoritesDialog = props => {
  const { handleFavorite, favorites } = props;
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const designDetailState = useDesignDetailState();
  const [val, setVal] = useState(
    `${designDetailState.designName} (${new Date().toLocaleString()})`
  );
  const [canbeadded, setCanbeadded] = useState(true);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    setVal(`${designDetailState.designName} (${new Date().toLocaleString()})`);
  }, [designDetailState.designName]);
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_FAV_DIALOG, payload: false });
  };
  const handleSave = () => {
    if (!canbeadded) return;
    handleFavorite({ newName: val });
    handleCloseDialog();
  };
  useEffect(() => {
    if (!uiState.showFavoritesDialog) return;
    const favDes = favorites.filter(item => item.fullPath === designDetailState.fullpath);
    let shouldAdd = false;
    if (designDetailState.customizationFlag) {
      shouldAdd = true;
    }
    const notCustomized = favDes.find(item => !item.customizationFlag);
    if (!notCustomized) shouldAdd = true;
    if (shouldAdd) {
      setCanbeadded(true);
      setMessage(strings.favorite.dialogText);
    } else {
      setCanbeadded(false);
      setMessage(strings.favorite.designExistsTxt);
    }
  }, [uiState.showFavoritesDialog, designDetailState.customizationFlag]);
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showFavoritesDialog}
      className="at-confirmation-dialog"
      size="xs"
    >
      <div className="at-dialog-area at-confirmation-dialog-area">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <H5 className="at-dialog-heading">{strings.favorite.saveAsFavorite}</H5>
        <div className="at-dialog-content">
          <FormGroup helperText={message}>
            <InputGroup
              onFocus={e => {
                e.target.select();
              }}
              value={val}
              onChange={e => {
                setVal(e.target.value);
                setCanbeadded(true);
                setMessage(strings.favorite.dialogText);
              }}
            />
          </FormGroup>
          <div className="at-confirmation-btns-area">
            <AtButton intent="primary" onClick={handleSave} text={strings.tools.save} />
            <AtButton intent="danger" onClick={handleCloseDialog} text={strings.tools.cancel} />
          </div>
        </div>
      </div>
    </AtDialog>
  );
};

export default FavoritesDialog;
