import { H4, H5 } from '@blueprintjs/core';
import React from 'react';
import Divider from '../../atoms/Divider';

const PriceOrDimension = props => {
  const {showPrice, price, strInPrice, showDesignDimensions, designDimensionsStr=''} = props;

  return (
    <div className="at-des-details__price-area">
        {showPrice && (
          <>
            {price.Value.lastIndexOf("%") >= 0 ? (
              <H4 className="at-des-details__price-area__price">
                {price.Value} {strInPrice}
              </H4>
            ) : (
              <H4 className="at-des-details__price-area__price">
                {price.Currency}
                {parseFloat(price.Value)}
              </H4>
            )}
          </>
        )}
        {showPrice && showDesignDimensions ? (
          <Divider vertical style={{ margin: "0 0.25rem" }} />
        ) : null}
        {showDesignDimensions && (
          <H5 className="at-des-details__price-area__dims">{designDimensionsStr}</H5>
        )}
      </div>
  );
};

PriceOrDimension.propTypes = {
  
};

export default PriceOrDimension;