import { convertTilePointToName } from "../../../utils/utils";

let clickCounter = 0;
export function makeDoubleClick(doubleClickCallback, singleClickCallback) {
  clickCounter++;
  return function () {
    setTimeout(() => {
      if (clickCounter === 1) {
        singleClickCallback && singleClickCallback.apply(this, arguments);
      } else {
        doubleClickCallback && doubleClickCallback.apply(this, arguments);
      }
      clickCounter = 0;
    }, 300);
  };
}

export const calculateZoomLevelFromZoom = (zoom, clampZoom) => {
  //zoom value 0.75 to 3
  //returns 1 2 or 4
  let zoomlevel;
  if (clampZoom) zoom = Math.ceil(zoom);
  if (zoom <= 1.25) {
    zoomlevel = 1
  } else if (zoom <= 2.25)
    zoomlevel = 2
  else
    zoomlevel = 4
  return zoomlevel

}
export const getTileCenter = ({ Width, Height, KLRatio, heightRatio, tileSize, dpi }) => {
  const ratio = Width / Height;
  const canvasWidth = Width;
  const canvasHeight = canvasWidth / ratio;
  return {
    x: ((canvasWidth / 4) * heightRatio) / KLRatio / dpi,
    y: (canvasHeight * heightRatio) / 4 / dpi
  }
}
export const getTilepoints = ({ Width, Height, KLRatio, heightRatio, tileSize, dpi }) => {
  let tilecenter;
  const getPoints = zoom => {
    const ratio = Width / Height;
    const canvasWidth = Width * zoom;
    const canvasHeight = canvasWidth / ratio;
    let xTotal = Math.floor((canvasWidth - 1) / tileSize) + 1;
    let yTotal = Math.floor((canvasHeight - 1) / tileSize) + 1;
    let tilePoints = [];
    if (zoom === 1) {
      tilecenter = {
        x: ((canvasWidth / 4) * heightRatio) / dpi,
        y: (canvasHeight * heightRatio * KLRatio) / 4 / dpi
      };
    }
    for (let x = 0; x < xTotal; x++) {
      for (let y = 0; y < yTotal; y++) {
        const width = x !== xTotal - 1 ? tileSize : canvasWidth - x * tileSize - 2
        const height = y !== yTotal - 1 ? tileSize : canvasHeight - y * tileSize - 2
        // console.log(height)
        tilePoints.push({ x, y, z: zoom, name: convertTilePointToName(x, y), size: { width, height }, });
      }
    }
    return tilePoints;
  };
  const tilepoint1 = getPoints(1);
  const tilepoint2 = getPoints(2);
  const tilepoint4 = getPoints(4);
  return { tilePoints: [...tilepoint1, ...tilepoint2, ...tilepoint4], tilecenter };
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
export const getWatermarkBounds = ({ tilecenter, offset, height, padding, width }) => {
  let x1 = -tilecenter.y * 2 * offset[0] - padding;
  let x2 = -tilecenter.y * 2 * offset[0] - padding;
  let y1 = tilecenter.x * 2 * offset[1] + padding;
  let y2 = tilecenter.x * 2 * offset[1] + padding;
  if (offset[0] === 0) {
    x2 = x2 - height;
  }
  if (offset[0] === 0.5) {
    x1 = x1 - height / 2 + padding;
    x2 = x2 + height / 2 + padding;
  }
  if (offset[0] === 1) {
    x1 = x1 + height + padding * 2;
    x2 = x2 + padding * 2;
  }
  if (offset[1] === 0) {
    y2 = y2 + width;
  }
  if (offset[1] === 0.5) {
    y1 = y1 - width / 2 - padding;
    y2 = y2 + width / 2 - padding;
  }
  if (offset[1] === 1) {
    y1 = y1 - width - padding * 2;
    y2 = y2 - padding * 2;
  }
  return [
    [x1, y1],
    [x2, y2]
  ]
}