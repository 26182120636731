import React, { useEffect, useState } from "react";
import { H5, ButtonGroup, Popover, Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import Divider from "../../atoms/Divider";
import strings from "../../../strings";
import AppProvider from "../../../api/appProvider";
import {
  useColorListState,
  useDispatchColorList,
  colorListActions
} from "../../../reducers/colors.reducer";
import { AreaSwatch } from "../AreaSwatch";
import AtButton from "../../atoms/AtButton";
import { shuffle, getPalette, shuffleForLocked } from "../../../utils/utils";
import { sortByKnots } from "../../../utils/colorutils";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";
import { ReactComponent as MapColors } from "../../atoms/AtIcon/map-colors.svg";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import { getFolderNameFromFullpath } from "../../../utils/treeutils";
import { getNewTonalColor } from "../../../utils/colorutils";
import AllInOneMatSelection from "./AllInOneMatSelection";
import AtCheckbox from "../../atoms/AtCheckbox";
import AutoColor from "../AutoColor";
import { autocolorOptions } from "../AutoColor/autocolorList";

const ColorAreaSwatch = props => {
  const {
    designDetails,
    totalKnots,
    selectedColor,
    colorNumbers,
    history,
    future,
    undoHistory,
    carvingColorsIndex,
    fullpath,
    lockedColorIndexArray
  } = useDesignDetailState();
  const { colorAreaSwatch: casFlags, accountSpecific } = window.flags;

  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const colorListState = useColorListState();
  const dispatchColorListState = useDispatchColorList();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [colorSelectionVisible, setColorSelectionVisible] = useState(false);
  const [showColorPercent, setShowColorPercent] = useState(casFlags.showColorPercentage);
  const [showCarving, setShowCarving] = useState(true);
  const isLockRequired = window.flags.colorAreaSwatch.showLockIcon;

  useEffect(() => {
    if (accountSpecific.oneAct.hasOneActFolder && fullpath && fullpath !== "") {
      const oneActFolder = accountSpecific.oneAct.folder.toLowerCase();
      const currentFolder = getFolderNameFromFullpath(fullpath || "").toLowerCase();
      if (oneActFolder === currentFolder) {
        dispatchUiState({ type: mainUiActions.SET_IS_ONEACT_FOLDER, payload: true });
      } else {
        dispatchUiState({ type: mainUiActions.SET_IS_ONEACT_FOLDER, payload: false });
      }
    }
  }, [fullpath]);

  const handleColorPieClick = color => {
    dispatchColorListState({
      type: colorListActions.SELECT_COLOR,
      payload: color
    });
  };
  const handleDesignColorReset = params => {
    dispatchDesignDetails({ type: designDetailActions.RESET_COLOR_SELECTION });
  };
  const handleDesignColorUndo = params => {
    dispatchDesignDetails({ type: designDetailActions.UNDO_COLOR_SELECTION });
  };

  const handleDesignColorRedo = params => {
    dispatchDesignDetails({ type: designDetailActions.REDO_COLOR_SELECTION });
  };

  const handleAutoRecolor = option => {
    const category = option && option.id ? option.id : "";
    const colorNumber = isLockRequired
      ? designDetails.DesignColors.length - lockedColorIndexArray.length
      : designDetails.DesignColors.length;
    AppProvider.changeColorCombination({
      numColors: colorNumber,
      colorTab: !casFlags.selectAutoColorFromAllColorTabs
        ? colorListState.filteredCollection.Name
        : "",
      category: category
    })
      .then(result => {
        if (!isLockRequired) {
          var colorPalette = sortByKnots(result, designDetails.DesignColors);
        } else {
          var colorPalette = getPalette(designDetails.DesignColors);
          var resultCounter = 0;
          colorPalette.map((item, index) => {
            if (!lockedColorIndexArray.includes(index)) {
              colorPalette[index] = result[resultCounter];
              resultCounter++;
            }
          });
        }
        dispatchDesignDetails({
          type: designDetailActions.CHANGE_COLOR_PALETTE,
          payload: colorPalette
        });
      })
      .catch(() => {
        AppProvider.changeColorCombination({
          numColors: designDetails.DesignColors.length,
          colorTab: "",
          category: category
        })
          .then(result => {
            var colorPalette = sortByKnots(result, designDetails.DesignColors);
            dispatchDesignDetails({
              type: designDetailActions.CHANGE_COLOR_PALETTE,
              payload: colorPalette
            });
          })
          .catch(() => {});
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: {
            message: `The active color set does not have enough colors for Autocolor. Using colors from all color sets.`,
            intent: "warning"
          }
        });
      });
  };

  // const getNewColorPalette = (designDetails, colorPalette) => {
  //   //const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  //   const d = JSON.parse(JSON.stringify({ ...designDetails }));
  //   colorPalette.sort(function(a, b) {
  //     var x = colorPalette.indexOf(a),
  //       y = colorPalette.indexOf(b);
  //     return d.DesignColors[x].Knots > d.DesignColors[y].Knots
  //       ? -1
  //       : d.DesignColors[x].Knots < d.DesignColors[y].Knots
  //       ? 1
  //       : 0;
  //   });

  //   colorPalette.forEach((item, index) => {
  //     if (d.DesignColors[index] && d.DesignColors[index].YarnDetails.length === 1) {
  //       d.DesignColors[index].Color = item.Value;
  //       d.DesignColors[index].ColorName = item.Name;
  //       d.DesignColors[index].YarnDetails[0].Color = item.Value;
  //       d.DesignColors[index].YarnDetails[0].ColorName = item.Name;
  //     }
  //   });
  //   return d;
  // };
  //let optionIndex = 0;
  // const handleBulkAutoRecolor = () => {
  //   console.log("handleBulkAutoRecolor", autocolorOptions);
  //   downloadColorCombination(autocolorOptions[optionIndex].id);
  // };

  // const generateHash = (details, fullPath = "") => {
  //   const des = JSON.stringify(details);
  //   return MD5(des + fullPath);
  // };

  // const downloadColorCombination = (category = "") => {
  //   AppProvider.changeColorCombination({
  //     numColors: designDetails.DesignColors.length,
  //     colorTab: colorListState.filteredCollection.Name,
  //     category: category,
  //     seed: 10,
  //     map: 1
  //   }).then(result => {
  //     //dispatchDesignDetails({ type: designDetailActions.CHANGE_COLOR_PALETTE, payload: result });
  //     const newDesignDetails = getNewColorPalette(designDetails, result);

  //     const watermarkOptions = {
  //       hasWatermark: window.flags.designView.hasDesignWaterMark,
  //       logoUrl: window.InterfaceElements.LogoUrl,
  //       ...window.flags.designView.watermarkOptions
  //     };
  //     AppProvider.getRenderedDesign({
  //       designDetails: newDesignDetails,
  //       designName,
  //       fullpath,
  //       hash: generateHash(newDesignDetails),
  //       felt: 0,
  //       zoom: 1,
  //       watermarkOptions
  //       // logoUrl: window.flags.designView.hasDesignWaterMark ? window.InterfaceElements.LogoUrl : null
  //     }).then(canvas => {
  //         if (optionIndex < autocolorOptions.length - 1) {
  //         optionIndex++;
  //         downloadColorCombination(autocolorOptions[optionIndex].id);
  //       }
  //     });
  //   });
  // };
  const handleCarving = () => {
    setShowCarving(!showCarving);
    //let carvingColorNumbers = window.InterfaceElements.IsAdmin ? []: carvingColorsIndex;
    let carvingColorNumbers = [];
    if (!window.InterfaceElements.IsAdmin && !window.flags.colorSelectionBox.showCarving) {
      carvingColorNumbers = carvingColorsIndex;
    }
    dispatchDesignDetails({
      type: designDetailActions.TOGGLE_COLOR_CARVING,
      payload: carvingColorNumbers
    });
  };
  const handleMapcolorsCurr = () => {
    AppProvider.mapColors({
      props: designDetails,
      colorTab: colorListState.filteredCollection.Name
    }).then(result => {
      if (JSON.stringify(designDetails.DesignColors) === JSON.stringify(result.DesignColors))
        return;
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_COLOR_PALETTE,
        payload: result.DesignColors.map(item => ({
          Name: item.ColorName,
          Value: item.Color
        }))
      });
    });
  };

  const handleShufflecolor = () => {
    shuffleColors();
  };
  const shuffleColors = () => {
    let colors = [...designDetails.DesignColors];
    let shuffledColors = [];
    let uniqueColors = [...new Map(colors.map(item => [item["Color"], item])).values()];
    let shuffledUniqueColors = isLockRequired
      ? shuffleForLocked(uniqueColors, lockedColorIndexArray)
      : shuffle(uniqueColors);
    let colorArr = [];
    colors.forEach((designColor, i) => {
      if (colorArr.indexOf(designColor.ColorName) === -1) {
        colorArr.push(designColor.ColorName);
        shuffledColors.push(shuffledUniqueColors[colorArr.length - 1]);
      } else {
        let index = colorArr.indexOf(designColor.ColorName);
        shuffledColors.push(shuffledUniqueColors[index]);
      }
    });
    let newColorPalette = getPalette(shuffledColors);
    dispatchDesignDetails({
      type: designDetailActions.CHANGE_COLOR_PALETTE,
      payload: newColorPalette
    });
  };
  const handleColorPercentToggle = () => {
    setShowColorPercent(!showColorPercent);
  };
  const handleSaveColorPalette = () => {
    var paletteColors = JSON.stringify(designDetails.DesignColors);
    sessionStorage.setItem("paletteColors", paletteColors);
    //$('#applypaletteBtn').removeClass('disabled');
  };
  const handleApplyColorPalette = () => {
    const paletteColorsStr = sessionStorage.getItem("paletteColors") || "";
    if (paletteColorsStr !== "") {
      var savedPaletteColors = JSON.parse(paletteColorsStr);
      var paletteColors = [...designDetails.DesignColors];
      if (savedPaletteColors.length > 0) {
        var len = Math.min(savedPaletteColors.length, paletteColors.length);

        for (var i = 0; i < len; i++) {
          paletteColors[i] = savedPaletteColors[i];
        }
      }
      let newColorPalette = getPalette(paletteColors);
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_COLOR_PALETTE,
        payload: newColorPalette
      });
    }
  };
  const isOneActMainColor = index => {
    var isMainColor = uiState.isOneActFolder && index === 0 ? true : false;
    return isMainColor;
  };
  const hiddenOneActColors = index => {
    const hideThis = uiState.isOneActFolder && (index === 1 || index === 2);
    return hideThis;
  };
  const handleOneActMainColorChange = color => {
    const origDesignColors = history.length
      ? history[0].designDetails.DesignColors
      : designDetails.DesignColors;

    const tonalColor1 = getNewTonalColor({
      newColor: color,
      origColor1: origDesignColors[0].Color,
      origColor2: origDesignColors[1].Color
    });
    const tonalColor2 = getNewTonalColor({
      newColor: color,
      origColor1: origDesignColors[0].Color,
      origColor2: origDesignColors[2].Color
    });
    let colors = [...designDetails.DesignColors];
    if (colors.length > 2) {
      colors[0].Color = color.Color;
      colors[1].Color = tonalColor1.Color;
      colors[2].Color = tonalColor2.Color;
      colors[0].ColorName = color.ColorName;
      colors[1].ColorName = tonalColor1.ColorName;
      colors[2].ColorName = tonalColor2.ColorName;
      let newColorPalette = getPalette(colors);
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_COLOR_PALETTE,
        payload: newColorPalette
      });
    }
  };
  const handlePaletteFromFileInput = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_IMAGE_INPUT_DIALOG, payload: true });
  };
  const renderDesignColorOptions = (
    <div
      className={classNames("at-design-color-options", {
        collapsed: isCollapsed,
        disabled: !uiState.allowColorCustomization
      })}
    >
      <AtButton small minimal onClick={handleDesignColorUndo} disabled={!history.length}>
        {strings.tools.undo}
      </AtButton>
      {casFlags.showRedoButton && (
        <>
          <Divider vertical style={{ height: "1rem" }} />
          <AtButton small minimal onClick={handleDesignColorRedo} disabled={!future.length}>
            {strings.tools.redo}
          </AtButton>
        </>
      )}
      <Divider vertical style={{ height: "1rem" }} />
      <AtButton
        small
        minimal
        onClick={handleDesignColorReset}
        disabled={!undoHistory.length && !history.length}
      >
        {strings.tools.reset}
      </AtButton>
      <Divider vertical style={{ height: "1rem" }} />

      {/* <AtButton id="at-design-color-options-autocolor" small minimal onClick={handleAutoRecolor}>
        {strings.tools.autoRecolor}
        {casFlags.showAutoColorDropdown && (
          <Icon className="color-details-material-icon" icon="caret-down" />
        )}
      </AtButton> */}
      <AutoColor
        showAutoColorDropdown={casFlags.showAutoColorDropdown}
        handleAutoRecolor={handleAutoRecolor}
        autoColorOptions={casFlags.autoColorOptions ? casFlags.autoColorOptions : autocolorOptions}
        onOptionClick={handleAutoRecolor}
        allowPaletteFromImage={casFlags.allowPaletteFromImage}
        handlePaletteFromFileInput={handlePaletteFromFileInput}
      />
      <Divider className="colortools-divider" vertical style={{ height: "1rem" }} />
      <AtButton
        id="at-design-color-options-shufflecolors"
        small
        minimal
        disabled={uiState.isOneActFolder}
        onClick={handleShufflecolor}
      >
        {strings.tools.shuffleColors}
      </AtButton>
      {flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.hasMasterCarving) && (
        <>
          <Divider vertical style={{ height: "1rem" }} />
          <AtButton
            id="at-masterCarving"
            small
            minimal
            onClick={handleCarving}
            className={!showCarving ? "at-masterCarving-Fade" : ""}
          >
            {strings.tools.carving}
          </AtButton>
        </>
      )}

      {window.flags.colorCustomizationTools.addPaletteTools == 2 && (
        <>
          <Divider vertical style={{ height: "1rem" }} />
          <AtButton
            className="at-design-color-options-colorpalette"
            small
            onClick={handleSaveColorPalette}
          >
            {strings.tools.saveColorPalette}
          </AtButton>
          <Divider vertical style={{ height: "1rem" }} />
          <AtButton
            className="at-design-color-options-colorpalette"
            small
            disabled={sessionStorage.getItem("paletteColors") ? false : true}
            onClick={handleApplyColorPalette}
          >
            {strings.tools.applyColorPalette}
          </AtButton>
        </>
      )}
    </div>
  );
  const isCarvingPresent = designDetails => {
    if (!designDetails) return false;
    let isCarved = false;
    if (designDetails.DesignColors && designDetails.DesignColors.length) {
      isCarved = designDetails.DesignColors.every(elem => {
        return elem.Carving;
      });
    }
    return isCarved;
  };
  return (
    <>
      <>
        <div className="at-section-heading-area color-area-swatch">
          <span className="at-section-label">
            <H5>{strings.color.colorCustomization}</H5>
          </span>
          {casFlags.isCollapsible && (
            <AtButton
              text={isCollapsed ? strings.tools.showMore : strings.tools.showLess}
              minimal
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          )}

          {(window.flags.colorCustomizationTools.showColorCustomizationTools ||
            window.InterfaceElements.IsAdmin) && (
            <ButtonGroup className="at-section__title__controls">
              {(window.flags.colorCustomizationTools.showMapColors ||
                window.InterfaceElements.IsAdmin) && (
                <Popover
                  minimal
                  position="bottom-right"
                  portalContainer={document.getElementById("app-main")}
                  interactionKind="hover"
                  content={
                    <Menu>
                      <MenuDivider title={strings.tools.mapColors} />
                      <MenuItem onClick={handleMapcolorsCurr} text={strings.design.thisDesign} />
                    </Menu>
                  }
                >
                  <AtButton id="at-section-tools-mapcolors" minimal>
                    <span className="bp3-icon at-icon">
                      <MapColors width="1.5rem" height="1.5rem" />
                    </span>
                  </AtButton>
                </Popover>
              )}
              {flaginCurrentMode(
                window.InterfaceElements.IsAdmin,
                window.flags.colorCustomizationTools.showColorCustomizationTools
              ) && (
                <Popover
                  minimal
                  position="bottom-right"
                  portalContainer={document.getElementById("app-main")}
                  interactionKind="hover"
                  content={
                    <Menu>
                      {window.flags.colorCustomizationTools.addShuffleColors && (
                        <MenuItem
                          id="at-section-tools-shufflecolors"
                          text={strings.tools.shuffleColors}
                          onClick={handleShufflecolor}
                        />
                      )}
                      {window.flags.colorCustomizationTools.addAutocolor && (
                        <MenuItem
                          id="at-section-tools-autocolor"
                          text={strings.tools.autoRecolor}
                          onClick={handleAutoRecolor}
                        />
                      )}
                      {window.flags.colorCustomizationTools.addColorPercentToggle && (
                        <MenuItem
                          id="at-section-tools-colorpercent"
                          text={
                            showColorPercent
                              ? strings.tools.hideColorPercentage
                              : strings.tools.showColorPercentage
                          }
                          onClick={handleColorPercentToggle}
                        />
                      )}
                      {window.flags.colorCustomizationTools.addPaletteTools == 1 && (
                        <>
                          <MenuItem
                            id="at-section-tools-savecolorpalette"
                            text={strings.tools.saveColorPalette}
                            onClick={handleSaveColorPalette}
                          />
                          <MenuItem
                            disabled={sessionStorage.getItem("paletteColors") ? false : true}
                            id="at-section-tools-applycolorpalette"
                            text={strings.tools.applyColorPalette}
                            onClick={handleApplyColorPalette}
                          />
                        </>
                      )}
                    </Menu>
                  }
                >
                  <AtButton
                    minimal
                    title="More Tools"
                    intent={null}
                    icon="context-menu-vertical"
                    // onClick={handleFavorite}
                  />
                </Popover>
              )}

              {/* TODO: add more buttons here*/}
            </ButtonGroup>
          )}
        </div>
        {!uiState.allowColorCustomization && (
          <p className="at-section-allowcolortext">
            Please specify rugsize before color customization
          </p>
        )}
        {renderDesignColorOptions}
        {casFlags.hasAllInOneMaterialSelection && (
          <div className="at-section-allInOneMatSelection">
            <AllInOneMatSelection
              groupLabel={casFlags.allInOneMaterialSelection.groupLabel}
              selectedValue={casFlags.allInOneMaterialSelection.selectedValue}
              radioLabels={casFlags.allInOneMaterialSelection.labels}
              radioValues={casFlags.allInOneMaterialSelection.values}
              formFieldAssociated={casFlags.allInOneMaterialSelection.formFieldAssociated}
            ></AllInOneMatSelection>
          </div>
        )}
        {window.flags.showMasterCarvingAsCheckbox && !isCollapsed ? (
          <AtCheckbox
            className="at-carving-checkbox"
            disabled={!uiState.allowColorCustomization}
            checked={designDetails && isCarvingPresent(designDetails) ? true : false}
            onChange={() => {
              handleCarving();
            }}
            label={strings.tools.carving}
            element={{
              type: "checkbox",
              label: strings.tools.carving,
              fieldId: "carving"
            }}
          />
        ) : null}
        <div
          id="color-area"
          className={classNames("at-section-container", {
            collapsed: isCollapsed,
            disabled: !uiState.allowColorCustomization
          })}
        >
          <div className="color-area-container">
            {designDetails.DesignColors &&
              designDetails.DesignColors.map((designColor, index) => (
                <AreaSwatch
                  popoverPosition={casFlags.colorSelectionDialogBoxPosition}
                  key={index}
                  {...props}
                  colorIndex={index}
                  designColor={designColor}
                  colorNumber={colorNumbers[index]}
                  className={classNames(
                    {
                      active: colorSelectionVisible && selectedColor === index
                    },
                    { hidden: hiddenOneActColors(index) }
                  )}
                  onColorSelectionClose={() => setColorSelectionVisible(false)}
                  onColorSelectionOpen={() => setColorSelectionVisible(true)}
                  onColorPieClick={handleColorPieClick}
                  totalKnots={totalKnots}
                  isOneActMainColor={isOneActMainColor(index)}
                  showColorSelection={!(uiState.isOneActFolder && index === 3)}
                  handleOneActMainColorChange={handleOneActMainColorChange}
                  showColorPercent={showColorPercent}
                />
              ))}
          </div>
        </div>
      </>
    </>
  );
};
export default ColorAreaSwatch;
