import React from "react";

const UiStateContext = React.createContext();
const UiDispatchContext = React.createContext();

const SET_SHOW_CUST_SCOUT = "SET_SHOW_CUSTOMIZATION_SCOUT";
const SET_SHOW_LOAD_OVERLAY = "SET_SHOW_LOAD_OVERLAY";
const SET_LOADING_OVERLAY_PERCENT = "SET_LOADING_OVERLAY_PERCENT";
const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
const SET_SHOW_RUGSHARE = "SET_SHOW_RUGSHARE";
const SET_SHOW_ORDERSHEET = "SET_SHOW_ORDERSHEET";
const SETMOUSE_HOVERED_ONUI = "SETMOUSE_HOVERED_ONUI";
const SET_SHOW_FAV_DIALOG = "SET_SHOW_FAV_DIALOG";
const SET_SHOW_VR_BTN = "SET_SHOW_VR_BTN";
const SET_SHOW_VR_DIALOG = "SET_SHOW_VR_DIALOG";
const SET_SHOW_QUICKLINK_DIALOG = "SET_SHOW_QUICKLINK_DIALOG";
const SET_ISBUSY = "SET_ISBUSY";
const SET_SHOW_RENAME_DIALOG = "SET_SHOW_RENAME_DIALOG";
const SET_TOAST_PROPS = "SET_TOAST_PROPS";
const SET_IS_IDLE = "SET_IS_IDLE";
const SET_PAGE_VIEW = "SET_PAGE_VIEW";
const SET_CONFIRMATION_DIALOG = "SET_CONFIRMATION_DIALOG";
const SET_QR_DIALOG = "SET_QR_DIALOG";
const SET_INFO_DIALOG = "SET_INFO_DIALOG";
const SET_ERROR = "SET_ERROR";
const SET_LANGUAGE = "SET_LANGUAGE";
const SHOW_MAP_COLOR_DIALOG = "SHOW_MAP_COLOR_DIALOG";
const SET_ALLOW_COLOR_CUSTOMIZATION = "SET_ALLOW_COLOR_CUSTOMIZATION";
const SET_ALLOWPRICEESTIMATION_DAVISRUGS = "SET_ALLOWPRICEESTIMATION_DAVISRUGS";
const SET_IS_ONEACT_FOLDER = "SET_IS_ONEACT_FOLDER";
const SET_MYROOM_MSG_DIALOG = "SET_MYROOM_MSG_DIALOG";
const SET_ARCARPET_INFO_DIALOG = "SET_ARCARPET_INFO_DIALOG";
const SET_SHOW_ENTRY_DIALOG = "SET_SHOW_ENTRY_DIALOG";
const SET_SHOW_WORDCLOUD = "SET_SHOW_WORDCLOUD";
const SET_MAINUIRESET = "SET_MAINUIRESET";
const SET_SESSIONVALUE = "SET_SESSIONVALUE";
const SET_SHOW_FOLDER_STORY_DIALOG = "SET_SHOW_FOLDER_STORY_DIALOG";
const SET_SHOW_IMAGE_INPUT_DIALOG = "SET_SHOW_IMAGE_INPUT_DIALOG";
const SET_FOLDER_STORY_CONTENT = "SET_FOLDER_STORY_CONTENT";

export const pageViews = {
  HOME: "HOMEPAGE",
  STEPPER: "STEPPER",
  FULL_PAGE_THUMB: "FULL_PAGE_THUMB",
  INTERMEDIATE: "INTERMEDIATE",
  CONTROLLER: "CONTROLLER",
  COLORS: "COLORS",
  CREATEYOURRUG: "CREATEYOURRUG",
  MYRUGSTUDIO: "MYRUGSTUDIO", //done for myrugstudio. show only room stage
  STUDIOENTRY: "STUDIOENTRY" //done for swiping design and room as a entry point for myrugstudio and mydesign
};
const mainUiActions = {
  SET_SHOW_CUST_SCOUT,
  SET_SHOW_LOAD_OVERLAY,
  SET_LOADING_OVERLAY_PERCENT,
  SET_SHOW_SIDEBAR,
  SET_SHOW_RUGSHARE,
  SET_SHOW_ORDERSHEET,
  SETMOUSE_HOVERED_ONUI,
  SET_SHOW_FAV_DIALOG,
  SET_SHOW_VR_BTN,
  SET_SHOW_VR_DIALOG,
  SET_SHOW_QUICKLINK_DIALOG,
  SET_ISBUSY,
  SET_SHOW_RENAME_DIALOG,
  SET_TOAST_PROPS,
  SET_IS_IDLE,
  SET_PAGE_VIEW,
  SET_CONFIRMATION_DIALOG,
  SET_QR_DIALOG,
  SET_INFO_DIALOG,
  SET_ERROR,
  SET_LANGUAGE,
  SHOW_MAP_COLOR_DIALOG,
  SET_ALLOW_COLOR_CUSTOMIZATION,
  SET_ALLOWPRICEESTIMATION_DAVISRUGS,
  SET_IS_ONEACT_FOLDER,
  SET_MYROOM_MSG_DIALOG,
  SET_ARCARPET_INFO_DIALOG,
  SET_SHOW_ENTRY_DIALOG,
  SET_SHOW_WORDCLOUD,
  SET_MAINUIRESET,
  SET_SESSIONVALUE,
  SET_SHOW_FOLDER_STORY_DIALOG,
  SET_SHOW_IMAGE_INPUT_DIALOG,
  SET_FOLDER_STORY_CONTENT
};
const initialUiState = {
  showRugShare: false,
  showOrderSheet: false,
  showCustoScout: false,
  showLoadingOverlay: true,
  loadingOverlayPercent: 0,
  showLeftSidebar: false,
  showRightSidebar: false,
  mouseHoveredOnUi: false,
  showFavoritesDialog: false,
  showVrBtn: false,
  showVrDialog: false,
  showQuickLinkDialog: false,
  isBusy: false,
  showRenameDialog: false,
  toastProps: null,
  isIdle: false,
  pageView: pageViews.HOME,
  showConfirmationDialog: false,
  showQRDialog: false,
  showInfoDialog: false,
  error: null,
  currentLanguage: "en",
  showMapColorDialog: false,
  allowColorCustomization: true,
  allowPriceEstimation_Davisrugs: false,
  isOneActFolder: false,
  showMyroomMsgDialog: false,
  showArCarpetInfoDialog: false,
  showEntryDialog: false,
  showWordCloud: false,
  setSessionValue: true,
  showFolderStoryDialog: false,
  folderStoryDialogContent: "",
  showImageInputDialog: false
};
const mainUiReducer = (state, action) => {
  const { type, payload } = action;
  // console.log(type)
  switch (type) {
    case SET_SHOW_CUST_SCOUT:
      return { ...state, showCustoScout: payload };
    case SET_SHOW_LOAD_OVERLAY:
      return { ...state, showLoadingOverlay: payload };
    case SET_LOADING_OVERLAY_PERCENT:
      return { ...state, loadingOverlayPercent: payload };
    case SET_SHOW_SIDEBAR:
      return {
        ...state,
        showLeftSidebar: payload.left === undefined ? state.showLeftSidebar : payload.left,
        showRightSidebar: payload.right === undefined ? state.showRightSidebar : payload.right
      };
    case SET_SHOW_RUGSHARE:
      return { ...state, showRugShare: payload };
    case SET_SHOW_ORDERSHEET:
      return { ...state, showOrderSheet: payload };
    case SETMOUSE_HOVERED_ONUI:
      return { ...state, mouseHoveredOnUi: payload };
    case SET_SHOW_FAV_DIALOG:
      return { ...state, showFavoritesDialog: payload };
    case SET_SHOW_VR_BTN:
      return { ...state, showVrBtn: payload };
    case SET_SHOW_VR_DIALOG:
      return { ...state, showVrDialog: payload };
    case SET_SHOW_QUICKLINK_DIALOG:
      return { ...state, showQuickLinkDialog: payload };
    case SET_ISBUSY:
      return { ...state, isBusy: payload };
    case SET_SHOW_RENAME_DIALOG:
      return { ...state, showRenameDialog: payload };
    case SET_TOAST_PROPS:
      return { ...state, toastProps: payload };
    case SET_IS_IDLE:
      return { ...state, isIdle: payload };
    case SET_PAGE_VIEW:
      return { ...state, pageView: payload };
    case SET_CONFIRMATION_DIALOG:
      return { ...state, showConfirmationDialog: payload };
    case SET_QR_DIALOG:
      return { ...state, showQRDialog: payload };
    case SET_INFO_DIALOG:
      return { ...state, showInfoDialog: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_LANGUAGE:
      return { ...state, currentLanguage: payload };
    case SHOW_MAP_COLOR_DIALOG:
      return { ...state, showMapColorDialog: payload };
    case SET_ALLOW_COLOR_CUSTOMIZATION:
      return { ...state, allowColorCustomization: payload };
    case SET_ALLOWPRICEESTIMATION_DAVISRUGS:
      return { ...state, allowPriceEstimation_Davisrugs: payload };
    case SET_IS_ONEACT_FOLDER:
      return { ...state, isOneActFolder: payload };
    case SET_MYROOM_MSG_DIALOG:
      return { ...state, showMyroomMsgDialog: payload };
    case SET_ARCARPET_INFO_DIALOG:
      return { ...state, showArCarpetInfoDialog: payload };
    case SET_SHOW_ENTRY_DIALOG:
      return { ...state, showEntryDialog: payload };
    case SET_SHOW_WORDCLOUD:
      return {
        ...state,
        showWordCloud: payload,
        showRightSidebar: payload ? false : state.showRightSidebar
      };
    case SET_MAINUIRESET:
      return resetUI(state);
    case SET_SHOW_FOLDER_STORY_DIALOG:
      return { ...state, showFolderStoryDialog: payload };
    case SET_FOLDER_STORY_CONTENT:
      return { ...state, folderStoryDialogContent: payload };
    case SET_SHOW_IMAGE_INPUT_DIALOG:
      return { ...state, showImageInputDialog: payload };
    default:
      return state;
  }
};
function resetUI(state) {
  return {
    ...initialUiState,
    showLeftSidebar: state.showLeftSidebar,
    showRightSidebar: state.showRightSidebar
  };
}
function UiStateProvider({ children, initPage, homeTemplate }) {
  let uiState = initialUiState;
  if (homeTemplate) {
    uiState.pageView = homeTemplate;
  }
  switch (initPage) {
    case true:
      uiState.pageView = pageViews.FULL_PAGE_THUMB;
      break;
    default:
      break;
  }
  const [state, dispatch] = React.useReducer(mainUiReducer, uiState);
  return (
    <UiStateContext.Provider value={state}>
      <UiDispatchContext.Provider value={dispatch}>{children}</UiDispatchContext.Provider>
    </UiStateContext.Provider>
  );
}
function useUiState() {
  const context = React.useContext(UiStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useUiDispatch() {
  const context = React.useContext(UiDispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export { UiStateProvider, useUiState, useUiDispatch, mainUiActions };
