import React from "react";
import AtIcon from "../AtIcon";

const AtDropdown = ({
  dropdownItems,
  defaultItemIndex = 0,
  onChange,
  minimal,
  addPlaceholder = false,
  placeholderText
}) => {
  const val = addPlaceholder && defaultItemIndex=== -1 ? '':defaultItemIndex;
  const [value, setValue] = React.useState(val);

  React.useEffect(() => {
    setValue(val);
  }, [defaultItemIndex]);
  return (
    <div className={`bp3-select at-select ${minimal ? "minimal" : ""}`}>
      <select
        value={value}
        onChange={e => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
      >
        {addPlaceholder && (
          <option value="" disabled hidden>
           {placeholderText && placeholderText!=='' ? placeholderText : "Please Choose..."}
          </option>
        )}
        {dropdownItems &&
          dropdownItems.map((cat, index) => (
            <option className="at-option" value={index} label={cat} key={index}>
              {cat}
            </option>
          ))}
      </select>
      <AtIcon icon="triangle-down" />
    </div>
  );
};

export default AtDropdown;
