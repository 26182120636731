import React, { Suspense, useEffect, useState } from "react";
import DesignList from "../../organisms/DesignList";
import Designviews from "../../organisms/Designviews";
import RoomList from "../../organisms/RoomList";
import SidePanel from "../SidePanel";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import BrandLogo from "../../molecules/BrandLogo";
import CopyrightSection from "../../molecules/CopyrightSection";
import DesignDetailSection from "../../organisms/DesignDetailSection";
import { usePriceCalculator } from "../../../hooks";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { H6, Tabs, Tab } from "@blueprintjs/core";
import ColorAreaSwatch from "../../molecules/ColorAreaSwatch";
import DesignVariations from "../../organisms/DesignVariations";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
import OrdersheetButton from "../OrderSheet/OrdersheetBtn";
import classNames from "classnames";
import { isMobileDevice } from "../../../utils/utils";
import { getFromSessionStorage } from "../../../utils/domUtils";
import SaveOnServer from "../../organisms/SaveOnServer";
const OrderSheet = React.lazy(() => import("../OrderSheet"));
const containerClass = "at-sidebar__step-container";

const LeftSidebarStepper = props => {
  const { handleDownloadImage } = props;
  const uiState = useUiState();
  const dispatchuiState = useUiDispatch();

  const { showLeftSidebar } = uiState;
  const designDetailState = useDesignDetailState();
  const price = usePriceCalculator({
    ...designDetailState,
    showPrice: window.InterfaceElements.ShowPrice && !window.initialData.customDesignUrl
  });

  const stepHeading = title => <H6 className="at-sidebar__step-container__title">{title}</H6>;
  const handleToggleSidebar = () => {
    dispatchuiState({ type: mainUiActions.SET_SHOW_SIDEBAR, payload: { left: !showLeftSidebar } });
  };
  const handleFavorite = () => {
    dispatchuiState({
      type: mainUiActions.SET_SHOW_FAV_DIALOG,
      payload: true
    });
  };

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [showLeftSidebar]);
  const ecatMode = window.initialData.eCatalogDetails;

  const step1 = (
    <div className={classNames(containerClass, { dimmed: uiState.showOrderSheet })}>
      {stepHeading("1: Choose a Design")}
      <DesignList />
    </div>
  );
  const step2 = (
    <div className={classNames(containerClass, { dimmed: uiState.showOrderSheet })}>
      {stepHeading(`${!ecatMode ? 2 : 1}: Choose an Illustration`)}
      <div className="at-section-container">
        <Designviews />
        <RoomList />
      </div>
    </div>
  );
  const step3 = (
    <div className={containerClass}>
      {stepHeading(`${!ecatMode ? 3 : 2}: Customize & Order`)}
      {window.flags.hasOrderSheet && window.flags.ordersheet.panel === "right" && (
        <Suspense fallback={<></>}>
          <OrderSheet />
        </Suspense>
      )}
      {!uiState.showOrderSheet && (
        <>
          <DesignDetailSection price={price} handleFavorite={handleFavorite} />
          <div className="at-section-container at-customize-area ">
            <DesignVariations />
            <ColorAreaSwatch />
          </div>
        </>
      )}
    </div>
  );
  const defaultLayout = (
    <>
      {!window.initialData.eCatalogDetails && step1}
      {step2}
      {step3}
    </>
  );
  return (
    <SidePanel
      id="left-sidebar"
      className={classNames("at-sidebar-stepper", {
        "stepper-tab-layout": isMobileDevice
      })}
      side="left"
      show={showLeftSidebar}
    >
      <AtButton
        className="at-sidebar__togglebtn togglebtn-left"
        icon={`panel-to-the-${showLeftSidebar ? "left" : "right"}`}
        onClick={handleToggleSidebar}
      />
      <div className="at-sidebar-area">
        {getFromSessionStorage("showlogo") !== 0 && (
          <div className="at-sidebar__content__logo">
            <BrandLogo />
            <div className="logo-tagline">{strings.tagline}</div>
          </div>
        )}
        <div className="at-sidebar__content">
          {isMobileDevice ? <TabContent price={price} /> : defaultLayout}
        </div>
      </div>
      <div className="at-sidebar__footer">
        <CopyrightSection />
        {!uiState.showOrderSheet && (
          <div className="at-footer-buttons-area">
            <OrdersheetButton />
            <AtButton
              intent="primary"
              tertiary
              text={strings.tools.saveAsImage}
              onClick={handleDownloadImage}
            ></AtButton>
            {window.InterfaceElements.IsAdmin && <SaveOnServer />}
          </div>
        )}
      </div>
    </SidePanel>
  );
};
const TabContent = props => {
  const { price } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const uiState = useUiState();
  return (
    <>
      {!!uiState.showOrderSheet && (
        <Suspense fallback={<></>}>
          <OrderSheet />
        </Suspense>
      )}
      {!uiState.showOrderSheet && (
        <Tabs
          className="bp3-focus-disabled at-sidebar__content__tabs at-sidebar-tabs"
          selectedTabId={`tab-${selectedTab}`}
        >
          <Tab
            id="tab-0"
            panel={
              <div className={classNames(containerClass)}>
                <DesignList />
              </div>
            }
            onClickCapture={() => setSelectedTab(0)}
            title={<H6>{strings.design.designs}</H6>}
          ></Tab>
          <Tab
            id="tab-1"
            panel={
              <div className={classNames(containerClass)}>
                <div className="at-section-container">
                  <Designviews />
                  <RoomList />
                </div>
              </div>
            }
            onClickCapture={() => setSelectedTab(1)}
            title={<H6>{strings.visualization.illustrations}</H6>}
          ></Tab>
          <Tab
            id="tab-2"
            panel={
              <div className={containerClass}>
                <DesignDetailSection price={price} />
                <div className="at-section-container at-customize-area ">
                  <DesignVariations />
                  <ColorAreaSwatch />
                </div>
              </div>
            }
            onClickCapture={() => setSelectedTab(2)}
            title={<H6>{strings.color.customize}</H6>}
          ></Tab>
        </Tabs>
      )}
    </>
  );
};

export default LeftSidebarStepper;
