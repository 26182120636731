import React, { useEffect, useState } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import AtButton from "../../atoms/AtButton";

const EntryDialog = ({ content = "", portalContainerId='app-main' }) => {
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const [isOpen, setIsOpen]= useState(uiState.showEntryDialog);
  const handleCloseDialog = () => {
    setIsOpen(false)
    dispatchUiState({ type: mainUiActions.SET_SHOW_ENTRY_DIALOG, payload: false });
  };
  useEffect(() => {
    setIsOpen(uiState.showEntryDialog)
    if (!uiState.showEntryDialog) return;
  }, [uiState.showEntryDialog]);

  return (
    <AtDialog
      portalContainer={document.getElementById(portalContainerId)}
      onClose={handleCloseDialog}
      isOpen={isOpen}
      className="at-confirmation-dialog entry-dialog"
      size="lg"
    >
      <div className="at-dialog-area at-confirmation-dialog-area">
        <div className="at-dialog-area ">
          <AtButton
            className="at-close-dialog-button"
            onClick={handleCloseDialog}
            minimal
            icon="close"
          />
        </div>
        <div className="at-dialog-content" dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </AtDialog>
  );
};

export default EntryDialog;
