/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  useDispatchordersheetDetail,
  ordersheetDetailActions,
  useOrdersheetDetailState
} from "../../../../reducers/ordersheetdetails.reducer";
import AtRadio from "../../../atoms/AtRadio";

const DavisRugsOptions = props => {
  const { flags } = window;
  const {
    groupID = "rugtypes",
    groupLabel = "Rug Techniques",
    onOptionChange,
    selectedValue,
    radioLabels,
    radioValues
  } = props;

  const [RugOption, setRugOption] = useState(selectedValue);

  const changeRugOption = event => {
    //setShowRugSize(true);
    if (onOptionChange) onOptionChange(event);
    setRugOption(event.currentTarget.value);
  };
  useEffect(() => {}, []);
  return (
    <div>
      {flags.accountSpecific.priceEstimation_davisLandings.checkKeyForPrice && (
        <div className="at-ordersheet-accountSpecific-options">
          {radioValues && (
            <div className={`at-ordersheet-accountSpecific-options-${groupID}`}>
              <AtRadio
                groupLabel={groupLabel}
                inline={true}
                onChange={changeRugOption}
                selectedValue={RugOption}
                radioLabels={radioLabels}
                radioValues={radioValues}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export const RugTypes = props => {
  const { flags } = window;
  const dispatchOrdersheetDetail = useDispatchordersheetDetail();
  const ordersheetDetailState = useOrdersheetDetailState();
  const { onOptionChange } = props;

  var { variables } = flags.accountSpecific.priceEstimation_davisLandings;
  const RugTypes = variables.RugTypes.options;
  const selectedRugType = ordersheetDetailState.accountSpecific.davisRugs.rugType;

  const [RugType, setRugType] = useState(selectedRugType === "" ? RugTypes[0] : selectedRugType);
  const changeRugTypes = event => {
    setRugType(event.currentTarget.value);
    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_DAVISRUGS_RUGTYPE,
      payload: event.currentTarget.value
    });
    if (onOptionChange) onOptionChange(event.currentTarget.value, undefined);
  };

  useEffect(() => {
    if (!selectedRugType || selectedRugType === "")
      dispatchOrdersheetDetail({
        type: ordersheetDetailActions.SET_DAVISRUGS_RUGTYPE,
        payload: RugTypes[0]
      });
  }, [RugTypes]);
  return (
    <DavisRugsOptions
      groupID="rugtypes"
      groupLabel="Rug Types"
      onOptionChange={changeRugTypes}
      selectedValue={RugType}
      radioLabels={RugTypes}
      radioValues={RugTypes}
    />
  );
};

export const RugTechniques = props => {
  const { flags } = window;
  const dispatchOrdersheetDetail = useDispatchordersheetDetail();
  const ordersheetDetailState = useOrdersheetDetailState();
  const { onOptionChange } = props;

  var { variables } = flags.accountSpecific.priceEstimation_davisLandings;
  const RugTechniques = variables.RugTechniques.options;
  const selectedRugTechnique = ordersheetDetailState.accountSpecific.davisRugs.rugTechnique;
  const [RugTechnique, setRugTechnique] = useState(
    !selectedRugTechnique ? RugTechniques[0] : selectedRugTechnique
  );
  useEffect(() => {
    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_DAVISRUGS_RUGTECHNIQUE,
      payload: RugTechnique
    });
  }, []);

  const changeRugTechniques = event => {
    setRugTechnique(event.currentTarget.value);
    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_DAVISRUGS_RUGTECHNIQUE,
      payload: event.currentTarget.value
    });
    if (onOptionChange) onOptionChange(undefined, event.currentTarget.value);
  };
  return (
    <DavisRugsOptions
      groupID="rugtechniques"
      groupLabel="Rug Techniques"
      onOptionChange={changeRugTechniques}
      selectedValue={RugTechnique}
      radioLabels={RugTechniques}
      radioValues={RugTechniques}
    />
  );
};
