import React from "react";
import classNames from "classnames";
import { ReactComponent as CheckIcon } from "./checked-square.svg";

const AtCheckbox = props => {
  const { label, checked, onChange, children, className, disabled =false, inputClassName=''} = props;
  return (
    <label className= {classNames("bp3-control bp3-checkbox ", className, {"bp3-disabled":disabled})}>
      <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled}
      className={inputClassName}
      />
      <span className={classNames("bp3-control-indicator", { checked })}>
        {checked && (
          <CheckIcon
            className="checkbox-icon"
            style={{
              position: "absolute",
              top: "-10px",
              left: "-10px",
              right: "-10px",
              bottom: "-10px",
              width: "1.25rem",
              height: "1.25rem",
              margin: "auto"
            }}
          />
        )}
      </span>
      {label}
      {children}
    </label>
  );
};

export default AtCheckbox;
