const calcSlope = (point, center) => {
    const m = (center[1] - point[1]) / (center[0] - point[0])
    return m
}

const getPivotPosOrigin = (pivot, wid, hgt) => {
    let ftToUnits = 305
    let tw = ((wid) / 2) * ftToUnits
    let th = ((hgt) / 2) * ftToUnits
    switch (pivot.toLowerCase()) {
        case 'tl':
            tw = tw * -1
            th = th * 1
            break;
        case 'tc':
            tw = 0
            th = th * 1
            break;
        case 'tr':
            tw = tw * 1
            th = th * 1
            break;
        case 'cl':
            tw = tw * -1
            th = 0
            break;
        case 'cc':
            tw = 0
            th = 0
            break;
        case 'cr':
            tw = tw * 1
            th = 0
            break;
        case 'bl':
            tw = tw * -1
            th = th * -1
            break;
        case 'bc':
            tw = 0
            th = th * -1
            break;
        case 'br':
            tw = tw * 1
            th = th * -1
            break;
        default:
            break;
    }
    let point = {
        x: tw,
        y: 0,
        z: th
    }
    return point
}

const getpointatdist = (point, m, d) => {
    let x1 = point[0] + d / Math.sqrt(1 + (m * m))
    let y1 = m * (x1 - point[0]) + point[1]
    return [x1, y1]
}
const calcRot = (rotation) => {
    const phi = rotation.x
    const theta = rotation.y
    const psi = rotation.z

    const m11 = Math.cos(theta) * Math.cos(psi)
    const m12 = -Math.cos(phi) * Math.sin(psi) + Math.sin(phi) * Math.sin(theta) * Math.cos(psi)
    const m13 = Math.sin(phi) * Math.sin(psi) + Math.cos(phi) * Math.sin(theta) * Math.cos(psi)

    const m21 = Math.cos(theta) * Math.sin(psi)
    const m22 = Math.cos(phi) * Math.cos(psi) + Math.sin(phi) * Math.sin(theta) * Math.sin(psi)
    const m23 = -Math.sin(phi) * Math.cos(psi) + Math.cos(phi) * Math.sin(theta) * Math.sin(psi)

    const m31 = -Math.sin(theta)
    const m32 = Math.cos(phi) * Math.cos(theta)
    const m33 = Math.cos(phi) * Math.cos(theta)

    const rotM = [
        m11, m12, m13, 0,
        m21, m22, m23, 0,
        m31, m32, m33, 0,
        0, 0, 0, 1
    ];
    return rotM
}

const multiplyMatrixAndPoint = (matrix, point) => {
    // Give a simple variable name to each part of the matrix, a column and row number
    let c0r0 = matrix[0], c1r0 = matrix[1], c2r0 = matrix[2], c3r0 = matrix[3];
    let c0r1 = matrix[4], c1r1 = matrix[5], c2r1 = matrix[6], c3r1 = matrix[7];
    let c0r2 = matrix[8], c1r2 = matrix[9], c2r2 = matrix[10], c3r2 = matrix[11];
    let c0r3 = matrix[12], c1r3 = matrix[13], c2r3 = matrix[14], c3r3 = matrix[15];

    // Now set some simple names for the point
    let x = point[0];
    let y = point[1];
    let z = point[2];
    let w = point[3];

    // Multiply the point against each part of the 1st column, then add together
    let resultX = (x * c0r0) + (y * c1r0) + (z * c2r0) + (w * c3r0);

    // Multiply the point against each part of the 2nd column, then add together
    let resultY = (x * c0r1) + (y * c1r1) + (z * c2r1) + (w * c3r1);

    // Multiply the point against each part of the 3rd column, then add together
    let resultZ = (x * c0r2) + (y * c1r2) + (z * c2r2) + (w * c3r2);

    // Multiply the point against each part of the 4th column, then add together
    let resultW = (x * c0r3) + (y * c1r3) + (z * c2r3) + (w * c3r3);

    return [resultX, resultY, resultZ, resultW];
}

export const pivotObject = (pv, object, position) => {
    if (pv.toLowerCase() === 'cc') {
        object.position.x = position[0]
        object.position.y = position[1]
        object.position.z = position[2]
        return;
    }
    const pivotPointOrigin = getPivotPosOrigin(pv, object.scale.x, object.scale.y)
    const rotationM = calcRot(object.rotation)
    const pivotPointOriginRotated = multiplyMatrixAndPoint(rotationM, [pivotPointOrigin.x, pivotPointOrigin.y, pivotPointOrigin.z, 1])

    let distance = Math.sqrt(pivotPointOriginRotated[0] * pivotPointOriginRotated[0] +
        pivotPointOriginRotated[1] * pivotPointOriginRotated[1] +
        pivotPointOriginRotated[2] * pivotPointOriginRotated[2])

    let pivotPoint2D, slope;
    // if (pivotPointOriginRotated[0] > -1 && pivotPointOriginRotated[0] < 1) {
    //     pivotPoint2D = [pivotPointOriginRotated[1], pivotPointOriginRotated[2]]
    //     slope = calcSlope(pivotPoint2D, [0, 0])
    // }
    // else if (pivotPointOriginRotated[1] > -1 && pivotPointOriginRotated[1] < 1) {
    //     pivotPoint2D = [pivotPointOriginRotated[0], pivotPointOriginRotated[2]]
    //     slope = calcSlope(pivotPoint2D, [0, 0])
    // }
    // else if (pivotPointOriginRotated[2] > -1 && pivotPointOriginRotated[2] < 1) {
    //     pivotPoint2D = [pivotPointOriginRotated[0], pivotPointOriginRotated[1]]
    //     slope = calcSlope(pivotPoint2D, [0, 0])
    // }

    // const pivotPointRotAbs = pivotPointOriginRotated.slice(0, 3).map(a => Math.abs(a))
    // const min = Math.min(...pivotPointRotAbs)
    // const minIndex = pivotPointRotAbs.indexOf(min)
    // if (minIndex === 0)
    //     pivotPoint2D = [pivotPointOriginRotated[1], pivotPointOriginRotated[2]]
    // else if (minIndex === 1)
    //     pivotPoint2D = [pivotPointOriginRotated[0], pivotPointOriginRotated[2]]
    // else if (minIndex === 2)
    //     pivotPoint2D = [pivotPointOriginRotated[0], pivotPointOriginRotated[1]]

    pivotPoint2D = [pivotPointOriginRotated[0], pivotPointOriginRotated[1]]

    slope = calcSlope(pivotPoint2D, [0, 0])

    slope = slope * -1

    let negativeDistances;
    if (slope > 0) {
        negativeDistances = ['bl', 'bc', 'br', 'cl']

    }
    else {
        negativeDistances = ['bl', 'bc', 'br', 'cr']
    }
    if (negativeDistances.includes(pv.toLowerCase())) {
        distance = distance * -1
    }

    let centerPoint = [position[0], position[1], position[2]]
    let centerPoint2D, newCenterPoint2D, newCenterPoint;

    // if (centerPoint[0] > -1 && centerPoint[0] < 1) {
    //   centerPoint2D = [centerPoint[1], centerPoint[2]]
    //   newCenterPoint2D = window.getpointatdist(centerPoint2D, slope, distance)
    //   newCenterPoint = [centerPoint[0], newCenterPoint2D[0], newCenterPoint2D[1]]
    // }
    // else if (centerPoint[1] > -1 && centerPoint[1] < 1) {
    centerPoint2D = [centerPoint[0], centerPoint[2]]
    if (slope === Number.POSITIVE_INFINITY) {
        newCenterPoint2D = [centerPoint2D[0], centerPoint2D[1] + distance]
    }
    else if (slope === Number.NEGATIVE_INFINITY) {
        newCenterPoint2D = [centerPoint2D[0], centerPoint2D[1] - distance]
    }
    else {
        newCenterPoint2D = getpointatdist(centerPoint2D, slope, distance)
    }
    newCenterPoint = [newCenterPoint2D[0], centerPoint[1], newCenterPoint2D[1]]
    // }
    // else if (centerPoint[2] > -1 && centerPoint[2] < 1) {
    //   centerPoint2D = [centerPoint[0], centerPoint[1]]
    //   newCenterPoint2D = window.getpointatdist(centerPoint2D, slope, distance)
    //   newCenterPoint = [newCenterPoint2D[0], newCenterPoint2D[1], centerPoint[2]]
    // }

    object.position.x = newCenterPoint[0]
    object.position.y = newCenterPoint[1]
    object.position.z = newCenterPoint[2]
    return;
}