/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

import styled from "styled-components";
import {
  colorListActions,
  useColorListState,
  useDispatchColorList
} from "../../../reducers/colors.reducer";
import {
  Popover,
  Menu,
  InputGroup,
  MenuItem,
  Position,
  Button,
  MenuDivider,
  Portal,
  Switch
} from "@blueprintjs/core";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { NestedAreaSwatch } from "../../molecules/AreaSwatch/NestedAreaSwatch";
import AtButton from "../../atoms/AtButton";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import strings from "../../../strings";
import classNames from "classnames";
import ColorPreview from "./ColorPreview";
import SwatchBox from "./SwatchBox";
import SimilarColors from "./SimilarColors";
import { compare, previewMaterial } from "../../../utils/colorutils";
import { getcurrentCollectionFilter } from "../../../utils/treeutils";
import AtIcon from "../../atoms/AtIcon";
import SwatchBoxPaulig from "./SwatchBoxPaulig";
import { isMobileDevice } from "../../../utils/utils";
const SwatchContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: auto;
  width: auto;
  /* for firefox */
  scrollbar-width: thin;
  /*height= size of each swatch + 2 * size of space between them + size of scroll bar  */
  height: ${props => `${(props.swatchSize + 2 * props.swatchSpace) * props.colorRows + 1.25}rem`};
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  padding: ${props => `${props.swatchSpace}rem`};
`;
const getSortingOptions = colorString => {
  const sortingOptions = [
    {
      name: colorString.sortingBySequence,
      key: null
    },
    {
      name: colorString.sortingByName,
      key: "ColorName"
    },
    {
      name: colorString.sortingByColor,
      key: "H"
    },
    {
      name: colorString.sortingBySharpness,
      key: "S"
    },
    {
      name: colorString.sortingByBrightness,
      key: "L"
    }
  ];

  return sortingOptions;
};
const getSortingOrders = colorString => {
  const sortingOrders = [
    { name: colorString.ascending, value: 1 },
    { name: colorString.descending, value: -1 }
  ];
  return sortingOrders;
};

const ColorSelectionBox = props => {
  const {
    onColorSwatchClick,
    swatchSpace = 0.15,
    className = "",
    selectColorOnClick = true,
    selectionColor,
    style,
    renderTexturedPreview = true,
    onClose,
    ...otherprops
  } = props;
  // console.log("selectionColor", selectionColor)

  const state = useColorListState();
  const dispatch = useDispatchColorList();
  const designDetailState = useDesignDetailState();
  const { designDetails, selectedColor, yarnIndex, colorNumbers, fullpath } = designDetailState;
  const dispatchDesignDetail = useDispatchDesignDetail();
  const {
    collections,
    collectionIndex,
    loading,
    activeColor,
    filteredCollection,
    colorTextures
  } = state;
  const [sortingOrder, setSortingOrder] = useState(1);
  const sortingOptions = getSortingOptions(strings.color);
  const [sortingKey, setSortingKey] = useState(sortingOptions[0].key);
  const { colorSelectionBox: CsbFlags } = window.flags;
  const {
    customColorOptions,
    collectionFilter,
    colorPaletteLabels,
    swatchSize,
    defaultColorDatabaseIndex
  } = CsbFlags;
  const [colorCollection, setColorCollection] = useState();
  const [hoveredBox, setHoveredBox] = useState(null);
  const [selectedCustomColor, setSelectedCustomColor] = useState({
    Color: "#ffffff",
    ColorName: "R255 G255 B255"
  });
  const [selectedCustomColorOption, setSelectedCustomColorOption] = useState(null);
  useEffect(() => {
    if (defaultColorDatabaseIndex || defaultColorDatabaseIndex === 0) {
      setSelectedCustomColorOption(null);
      dispatch({
        type: colorListActions.SELECT_COLLECTION,
        payload: defaultColorDatabaseIndex
      });
    }
  }, []);
  useEffect(() => {
    if (!filteredCollection) return;
    // const collection = filteredCollection;
    const { NumRows, ColorRows } = filteredCollection;
    //sort the colors
    const colorRowsSorted = [...ColorRows].sort((a, b) => {
      if (!sortingKey) return a - b;
      return compare(a, sortingKey, b, sortingOrder);
    });
    let sortagain = [];
    //if the sorting is by sharpness or brightness, it needs to be sorted again along the rows with decreasing lightness
    if (sortingKey === "H" || sortingKey === "S") {
      for (let i = 0; i < ColorRows.length; i += NumRows) {
        const a = [...colorRowsSorted].slice(i, i + NumRows);
        a.sort((a, b) => compare(a, "L", b, -1));
        sortagain = [...sortagain, ...a];
      }
    } else {
      sortagain = [...colorRowsSorted];
    }
    // console.log(sortagain);
    const collection = { ...filteredCollection, ColorRows: sortagain };
    setColorCollection(collection);
  }, [collectionIndex, sortingKey, sortingOrder, filteredCollection]);

  const handleColorSwatchClick = (color, e) => {
    e.stopPropagation();
    if (onColorSwatchClick) onColorSwatchClick(color, e);
    if (selectColorOnClick)
      dispatch({
        type: colorListActions.SET_ACTIVE_COLOR,
        payload: color
      });
  };
  const handleColorSearch = e => {
    if (CsbFlags.searchColorInAllLists) {
      dispatch({ type: colorListActions.SEARCH_COLOR_IN_ALL, payload: e.target.value });
    } else {
      dispatch({ type: colorListActions.SEARCH_COLOR, payload: e.target.value });
    }
  };
  const handleCarving = e => {
    dispatchDesignDetail({ type: designDetailActions.TOGGLE_COLOR_CARVING });
  };
  const isCarvingAllowed = selectedColorIndex => {
    let carvingAllowed = flaginCurrentMode(window.InterfaceElements.IsAdmin, CsbFlags.showCarving); // CsbFlags.showCarving || window.InterfaceElements.IsAdmin;
    return carvingAllowed;
  };
  const selectCustomColorOption = colorIndex => {
    let selectedCustomDb = customColorOptions[colorIndex];
    setSelectedCustomColorOption(selectedCustomDb);
    if (activeColor)
      setSelectedCustomColor({ Color: activeColor.Color, ColorName: activeColor.ColorName });
  };
  const handleCustomColorPicked = e => {
    const tex =
      selectedCustomColorOption && selectedCustomColorOption.texture
        ? selectedCustomColorOption.texture
        : -1;
    let color = {
      Color: selectedCustomColor.Color,
      ColorName: selectedCustomColor.ColorName,
      Texture: tex
    };
    onColorSwatchClick(color, e);
    //handleColorSwatchClick(selectedCustomColor,e)
  };
  const handleCustomColorSelected = color => {
    const { rgb, hex } = color;
    setSelectedCustomColor({ Color: hex, ColorName: `R${rgb.r} G${rgb.g} B${rgb.b}` });
  };
  const renderCollectionDropdown = () => {
    const showThisCollection = collection => {
      if (collectionFilter.length === 0) return true;
      else {
        let showThis = false;
        let collectionsToshow = getcurrentCollectionFilter(collectionFilter, fullpath);
        if (!collectionsToshow || !collectionsToshow.length || !collectionsToshow[0].collection) {
          showThis = true;
        } else {
          collectionsToshow[0].collection.forEach(element => {
            if (element.toLowerCase() === collection.Name.toLowerCase()) {
              showThis = true;
            }
          });
        }

        return showThis;
      }
    };
    return (
      collections && (
        <Menu>
          {collections.map((collection, index) => {
            if (showThisCollection(collection)) {
              return (
                <MenuItem
                  key={index}
                  text={collection.Name}
                  onClick={e => {
                    setSelectedCustomColorOption(null);
                    dispatch({
                      type: colorListActions.SELECT_COLLECTION,
                      payload: index
                    });
                  }}
                />
              );
            }
          })}
          {customColorOptions.map((customColorOption, index) => (
            <MenuItem
              key={index}
              text={customColorOption.text}
              onClick={e => selectCustomColorOption(index)}
            ></MenuItem>
          ))}
        </Menu>
      )
    );
  };
  const renderSortingOptions = sortingOrders => (
    <Menu>
      {sortingOrders.map((order, index) => (
        <MenuItem
          active={order.value === sortingOrder}
          key={index}
          text={order.name}
          onClick={() => setSortingOrder(order.value)}
        />
      ))}
      <MenuDivider />
      {sortingOptions.map((option, index) => (
        <MenuItem
          active={option.key === sortingKey}
          key={index}
          text={option.name}
          onClick={() => setSortingKey(option.key)}
        />
      ))}
    </Menu>
  );
  const LabelTemplateFlipo = props => {
    const { label, labelTitle } = props;
    let labelTitle_translated = getCurrentLangString(labelTitle);
    labelTitle_translated = labelTitle_translated === "" ? "Premounted" : labelTitle_translated;
    return (
      <div className="labelTemplate-flipo">
        <div className="labelTemplate-flipo__titletext">{labelTitle_translated}</div>
        <div className="labelTemplate-flipo__text">{label}</div>
      </div>
    );
  };
  const getCurrentLangString = textObj => {
    const currentLang = strings.getLanguage();
    return textObj && textObj[currentLang] ? textObj[currentLang] : "";
  };
  const renderLabelTemplate = props => {
    const { templateName } = props;
    switch (templateName) {
      case "flipo":
        return <LabelTemplateFlipo {...props}></LabelTemplateFlipo>;
      default:
        return null;
    }
  };

  const isCurrentFolderLabel = index => {
    const colorCollectionName = colorCollection.Name;
    const currentFolderLabels = colorPaletteLabels.colorLabelFilter.filter(
      labelFilter => labelFilter.colorListName.toLowerCase() === colorCollectionName.toLowerCase()
    );
    const showThisLabel =
      currentFolderLabels.length > 0 && currentFolderLabels[0].labelIndeces.indexOf(index) >= 0
        ? true
        : false;
    return showThisLabel;
  };
  const showColorLabelInfo = () => {
    const colorCollectionName = colorCollection.Name;
    const currentFolderLabels = colorPaletteLabels.colorLabelFilter.filter(
      labelFilter => labelFilter.colorListName.toLowerCase() === colorCollectionName.toLowerCase()
    );
    const showLabel = currentFolderLabels.length > 0 && currentFolderLabels[0].showColorLabelInfo;
    return showLabel;
  };
  const dynamicCSBWidth = collectionIndex => {
    if (
      CsbFlags.dynamicCSBWidth &&
      CsbFlags.dynamicCSBWidth.length &&
      CsbFlags.dynamicCSBWidth.indexOf(collectionIndex) >= 0
    ) {
      return "dynamicCSBWidth" + collectionIndex;
    }
    return "";
  };

  const getStyles = (hoveredBox, isMobileDevice, swatchSize) => {
    if (isMobileDevice) {
      return {
        top: `${hoveredBox.elem.getBoundingClientRect().bottom + window.scrollY - 140}px`,
        left: `${hoveredBox.elem.getBoundingClientRect().left +
          window.scrollX +
          hoveredBox.elem.getBoundingClientRect().width -
          40}px`
      };
    }

    return window.innerHeight - hoveredBox.elem.getBoundingClientRect().bottom < 130
      ? {
          top: `${hoveredBox.elem.getBoundingClientRect().bottom + window.scrollY - 80}px`,
          left: `${hoveredBox.elem.getBoundingClientRect().left +
            window.scrollX +
            hoveredBox.elem.getBoundingClientRect().width +
            2}px`
        }
      : {
          top: `${hoveredBox.elem.getBoundingClientRect().top +
            window.scrollY +
            swatchSize * 16 +
            5}px`,
          left: `${hoveredBox.elem.getBoundingClientRect().left + window.scrollX}px`
        };
  };

  return (
    !loading && (
      <div
        className={classNames(
          "color-selection-box",
          "dialog",
          dynamicCSBWidth(collectionIndex),
          className
        )}
        style={{
          maxHeight: window.innerHeight,
          ...style
        }}
        {...otherprops}
      >
        <AtIcon
          className="at-close-button"
          onClick={e => {
            if (onClose) onClose(e);
          }}
          icon="close"
        ></AtIcon>

        {selectedColor !== -1 &&
          designDetails.DesignColors[selectedColor] &&
          designDetails.DesignColors[selectedColor].YarnDetails.length > 1 && (
            <NestedAreaSwatch
              yarnIndex={yarnIndex}
              colorIndex={selectedColor}
              designColor={designDetails.DesignColors[selectedColor]}
              colorNumber={colorNumbers[selectedColor]}
              selectedyarn={yarnIndex}
            />
          )}
        {CsbFlags.showColorFilter && (
          <div className="at-color-search-area">
            <InputGroup
              placeholder={strings.color.searchColorByName}
              onInput={handleColorSearch}
            ></InputGroup>
          </div>
        )}
        {colorCollection && CsbFlags.showSimilarColors && (
          <SimilarColors
            activeColor={activeColor ? activeColor : selectionColor}
            colorList={colorCollection.ColorRows}
            collectionName={colorCollection.Name}
            colorTextures={colorTextures}
            swatchSize={swatchSize}
            swatchSpace={swatchSpace}
            onColorSwatchClick={handleColorSwatchClick}
          />
        )}
        {designDetailState.selectedColor !== -1 &&
          isCarvingAllowed(designDetailState.selectedColor) && (
            <div id="color_palette_carving">
              <Switch
                style={{ float: "left" }}
                checked={
                  designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
                    .Carving
                }
                label="Carving"
                alignIndicator="right"
                onChange={handleCarving}
              />
            </div>
          )}
        {CsbFlags.showColorPaletteDropDown && (
          <div className="color-palette-dropdown">
            <div className="at-color-palette-options">
              <Popover
                disabled={collections.length + customColorOptions.length < 2}
                className="at-sorting-options"
                position={Position.BOTTOM_LEFT}
                content={renderCollectionDropdown()}
                minimal
                usePortal={false}
                captureDismiss
              >
                <span className="at-color-palette-options__selected">
                  {!selectedCustomColorOption
                    ? collections[collectionIndex].Name
                    : selectedCustomColorOption.text}
                  {collections.length + customColorOptions.length > 1 && (
                    <Button
                      className="at-color-palette-options__caret"
                      minimal
                      icon="caret-down"
                    ></Button>
                  )}
                </span>
              </Popover>
              <Popover
                className="at-color-palette-options-sort"
                position={Position.BOTTOM_RIGHT}
                content={renderSortingOptions(getSortingOrders(strings.color))}
                minimal
                usePortal={false}
                captureDismiss
              >
                <Button small minimal icon="sort"></Button>
              </Popover>
            </div>
          </div>
        )}
        {colorCollection && (
          <div className="color-palette-area">
            <div className="color-palette-area__wrapper">
              {colorPaletteLabels.labels.length > 0 && (
                <div className="color-palette-area-colorLabels">
                  {colorPaletteLabels.labels.map((label, index) => (
                    <div
                      key={index}
                      className={classNames("color-palette-area-colorLabel", {
                        shown: isCurrentFolderLabel(index)
                      })}
                    >
                      {renderLabelTemplate({
                        templateName: colorPaletteLabels.labelTemplate || "flipo",
                        label,
                        labelTitle: colorPaletteLabels.labelTitle
                      })}
                    </div>
                  ))}
                </div>
              )}
              {selectedCustomColorOption ? (
                <>
                  <SketchPicker
                    style={{
                      margin: "auto"
                    }}
                    className="at-sketch-picker"
                    color={selectedCustomColor.Color}
                    disableAlpha={true}
                    presetColors={[]}
                    width="16rem"
                    onChangeComplete={handleCustomColorSelected}
                  />
                  <div className="color-palette-custom-select">
                    <AtButton
                      id="color-palette_custom__color"
                      title={strings.color.selectColor}
                      tertiary={true}
                      intent="primary"
                      text={strings.color.selectColor}
                      onClick={handleCustomColorPicked}
                    ></AtButton>
                  </div>
                </>
              ) : (
                <SwatchContainer
                  className="colorSwatchBox"
                  swatchSpace={swatchSpace}
                  swatchSize={swatchSize}
                  colorRows={colorCollection.NumRows}
                >
                  {colorCollection.ColorRows.map((colorRow, index) =>
                    window.flags.accountSpecific.isPaulig ? (
                      <SwatchBoxPaulig
                        key={index}
                        swatchSize={swatchSize}
                        colorRow={colorRow}
                        onColorSwatchClick={e => {
                          handleColorSwatchClick(colorRow, e);
                        }}
                        active={colorRow === activeColor}
                      />
                    ) : (
                      <SwatchBox
                        key={index}
                        swatchSize={swatchSize}
                        swatchSpace={swatchSpace}
                        colorRow={colorRow}
                        onColorSwatchClick={e => {
                          handleColorSwatchClick(colorRow, e);
                        }}
                        active={colorRow === activeColor}
                        handleHover={elem => setHoveredBox({ elem, colorRow })}
                        showColorPoms={window.flags.colorSelectionBox.showColorPomsImages}
                        colorPomsShape="square"
                      />
                    )
                  )}
                  {/* {state.filteredCollection.ColorRows.map((colorRow, index) => (
                  <SwatchBox
                    key={index}
                    swatchSize={swatchSize}
                    swatchSpace={swatchSpace}
                    colorRow={colorRow}
                    onColorSwatchClick={e => {
                      onColorSwatchClick(colorRow, e);
                    }}
                    active={colorRow === activeColor}
                    handleHover={elem => setHoveredBox({ elem, colorRow })}
                  />
                ))} */}
                  <Portal container={document.getElementById("app-main")}>
                    {hoveredBox && hoveredBox.elem && (
                      <ColorPreview
                        renderTexturedPreview={renderTexturedPreview}
                        material={previewMaterial({
                          texture: hoveredBox.colorRow.Texture,
                          hoveredBox,
                          selectedColor,
                          yarnIndex,
                          DesignColors: designDetails.DesignColors
                        })}
                        colorTextures={colorTextures}
                        colorName={hoveredBox.colorRow.ColorName}
                        color={hoveredBox.colorRow.Color}
                        colorTab={colorCollection.Name}
                        style={getStyles(hoveredBox, isMobileDevice, swatchSize)}
                        showColorPoms={window.flags.colorSelectionBox.showColorPomsImages}
                        colorPomsShape="square"
                      />
                    )}
                  </Portal>
                </SwatchContainer>
              )}
            </div>
            {colorPaletteLabels.colorLabelInfo &&
              colorPaletteLabels.colorLabelInfo !== "" &&
              showColorLabelInfo() && (
                <div className="color-palette-area-colorLabelInfo">
                  {" "}
                  {getCurrentLangString(colorPaletteLabels.colorLabelInfo)}
                </div>
              )}
          </div>
        )}
      </div>
    )
  );
};

export default ColorSelectionBox;
