import { InputGroup, Popover } from "@blueprintjs/core";
import React, { useState } from "react";
import AppProvider from "../../../api/appProvider";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";
import AtButton from "../../atoms/AtButton";

const PriceEstimationBtn = props => {
  const [priceEstimationKey, setPriceEstimationKey] = useState("");
  const [incorrectPinAlert, setIncorrectPinAlert] = useState(false);
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const { customFields } = designDetailState;
  const dispatchUiState = useUiDispatch();
  const handleOpenOrdersheet = e => {
    if (customFields.fields) {
      setOpenOrderSheet(true);
      return;
    }
    AppProvider.fetchCustomFieldOrdersheet()
      .then(data => {
        setCustomFields(data);
        setOpenOrderSheet(true);
      })
      .catch(err => {});
  };

  const setCustomFields = fields => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS, payload: fields });
  };
  const setOpenOrderSheet = show =>
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: show });
  const setAllowPriceEstimation_davisrugs = show => {
    dispatchUiState({ type: mainUiActions.SET_ALLOWPRICEESTIMATION_DAVISRUGS, payload: show });
  };
  const handlePriceEstimation = e => {
    e.preventDefault();
    const matchkey =
      priceEstimationKey.toLowerCase() ===
      window.flags.accountSpecific.priceEstimation_davisLandings.priceEstimationKey.toLowerCase();
    if (matchkey) {
      setAllowPriceEstimation_davisrugs(true);
      handleOpenOrdersheet(e);
    } else {
      setIncorrectPinAlert(true);
    }
  };
  return (
    <Popover
      boundary="viewport"
      minimal
      position="top-right"
      content={
        <div className="at-price-est-area">
          <div id="pinInputLabel">Price Estimate Pin</div>
          <div className="at-price-est-area__pin-inp">
            <form onSubmit={handlePriceEstimation}>
              <InputGroup
                className="at-price-est-area__pin-inp__input"
                tag="Price Estimate Pin"
                onChange={e => setPriceEstimationKey(e.target.value)}
                value={priceEstimationKey}
                autoFocus
              />
              <AtButton id="pricePinSave" text="OK" type="submit" intent="primary" tertiary />
            </form>
          </div>
          {incorrectPinAlert && (
            <div id="pinMsg" className="bp3-callout bp3-intent-warning bp3-text-small">
              Wrong Pin number!
            </div>
          )}
        </div>
      }
    >
      <AtButton
        className="at-sidebar__footer__price-est"
        intent="primary"
        tertiary
        text="Estimate Price"
      />
    </Popover>
  );
};

export default PriceEstimationBtn;
