import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../Thumbnail";
import LazyThumbnail from "../../pages/DesignBrowser/LazyThumbnail";

const ThumbList = props => {
  const {
    thumbList,
    activeId,
    onThumbnailClick,
    onRemoveClicked,
    className='',
    lazyDesignThumbnail = false
  } = props;
    
  return (
    <div className={`thumb-container ${className}`}>
      {thumbList.map((thumb, index) =>
        lazyDesignThumbnail ? (
          <LazyThumbnail
            {...props}
            node={thumb}
            key={index}
            onRemoveClicked={() => onRemoveClicked && onRemoveClicked(thumb, index)}
            onThumbnailClick={() => {
              if (!onThumbnailClick) return;
              onThumbnailClick(thumb, index);
            }}
            active={thumb.id === activeId}
          />
        ) : (
          <Thumbnail
            {...props}
            thumb={thumb}
            key={index}
            onRemoveClicked={() => onRemoveClicked && onRemoveClicked(thumb, index)}
            onThumbnailClick={() => {
              if (!onThumbnailClick) return;
              onThumbnailClick(thumb, index);
            }}
            active={thumb.id === activeId}
          />
        )
      )}
    </div>
  );
};

ThumbList.propTypes = {
  thumbList: PropTypes.array.isRequired,
  activeId: PropTypes.string,
  onThumbnailClick: PropTypes.func
};

export default ThumbList;
