/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { AtSlider } from "../../molecules/Slider";
import { useMount } from "react-use";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import AppProvider from "../../../api/appProvider";

import { calculateZoomLevelFromZoom, makeDoubleClick, getTileCenter } from "./visualizationutils";
import { AreaSwatch } from "../../molecules/AreaSwatch";
import AtIcon from "../../atoms/AtIcon";
import { usePrevious } from "../../../hooks";

import { downloadImageData, createCanvas } from "../../../utils/canvasutils";
import { useDebouncedCallback } from "use-debounce/lib";
import { pageViews, useUiState } from "../../../reducers/mainui.reducer";
import { useDispatchColorList, colorListActions } from "../../../reducers/colors.reducer";
import { AtSpinner } from "../../atoms/AtSpinner";
import classNames from "classnames";
import { getZoomParameters, resolveZoomValue } from "../../../middleware/visualization.middleware";

const leaflet = window.L;
let map;
let tilePoints;

const options = {
  tileSize: 256
};
let layer;
let needsPanning = true;
let minZoom = 0.75,
  maxZoom = 3,
  step = 0.25;
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
const getRenderedDesignWW = designProps => {
  const { zoom = 1 } = designProps;

  return AppProvider.getRenderedDesign({ ...designProps, applyKLRatio: false }).then(
    designCanvas => {
      // return [{ x: 0, y: 0, z: 1, src: designCanvas.toDataURL() }];
      switch (zoom) {
        case 1:
          return [{ x: 0, y: 0, z: 1, src: designCanvas.toDataURL() }];
        case 2:
          const canvasArr = [
            { x: 0, y: 0, z: 2 },
            { x: 1, y: 0, z: 2 },
            { x: 0, y: 1, z: 2 },
            { x: 1, y: 1, z: 2 }
          ];
          canvasArr.forEach(item => {
            //TODO: this block is the same for 2x and 4x and a reusable function can be made
            const newWidth = designCanvas.width / 2;
            const newHeight = designCanvas.height / 2;
            const canvas = createCanvas(designCanvas.width / 2, designCanvas.height / 2);

            const imgData = designCanvas
              .getContext("2d")
              .getImageData(
                (item.x / 2) * designCanvas.width,
                (item.y / 2) * designCanvas.height,
                newWidth,
                newHeight
              );
            canvas.getContext("2d").putImageData(imgData, 0, 0);
            item.src = canvas.toDataURL();
          });
          return canvasArr;
        case 4:
          const canvasArr4x = [
            { x: 0, y: 0, z: 4 },
            { x: 1, y: 0, z: 4 },
            { x: 2, y: 0, z: 4 },
            { x: 3, y: 0, z: 4 },
            { x: 0, y: 1, z: 4 },
            { x: 1, y: 1, z: 4 },
            { x: 2, y: 1, z: 4 },
            { x: 3, y: 1, z: 4 },
            { x: 0, y: 2, z: 4 },
            { x: 1, y: 2, z: 4 },
            { x: 2, y: 2, z: 4 },
            { x: 3, y: 2, z: 4 },
            { x: 0, y: 3, z: 4 },
            { x: 1, y: 3, z: 4 },
            { x: 2, y: 3, z: 4 },
            { x: 3, y: 3, z: 4 }
          ];
          canvasArr4x.forEach(item => {
            const newWidth = designCanvas.width / 4;
            const newHeight = designCanvas.height / 4;
            const canvas = createCanvas(designCanvas.width / 4, designCanvas.height / 4);

            const imgData = designCanvas
              .getContext("2d")
              .getImageData(
                (item.x / 4) * designCanvas.width,
                (item.y / 4) * designCanvas.height,
                newWidth,
                newHeight
              );
            canvas.getContext("2d").putImageData(imgData, 0, 0);
            item.src = canvas.toDataURL();
          });
          return canvasArr4x;
        default:
          break;
      }
    }
  );
};
export function initMap({ onDesignClick, onDesignloaded, onDrag, zoomStep = 0.25 }) {
  map = leaflet.map("map", {
    crs: leaflet.CRS.Simple,
    minZoom,
    maxZoom,
    doubleClickZoom: false,
    keyboard: false,
    scrollWheelZoom: false,
    touchZoom: true,
    fadeAnimation: false,
    bounceAtZoomLimits: false,
    zoomControl: false,
    attributionControl: false,
    wheelDebounceTime: 500,
    zoomSnap: zoomStep,
    zoomDelta: zoomStep,
    dragging:
      window.flags?.homeTemplate === pageViews.CREATEYOURRUG ? !leaflet.Browser.mobile : true,
    tap: window.flags?.homeTemplate === pageViews.CREATEYOURRUG ? !leaflet.Browser.mobile : true
  });
  map.on("drag", onDrag);
  const handleClick = e => {
    const zoom = map.getZoom();
    let designPoint = e.layerPoint.add(map.getPixelOrigin());
    const zoomScale = map.getZoomScale(zoom, 1);
    const { heightRatio = 1, designDetails, dpi } = layer.options;
    const { KLRatio = 1 } = designDetails;
    const zoomlevel = calculateZoomLevelFromZoom(zoom);
    designPoint = {
      x: (designPoint.x / zoomScale / heightRatio) * KLRatio * dpi,
      y: (designPoint.y / zoomScale / heightRatio) * KLRatio * dpi
    };
    if (onDesignClick) onDesignClick({ designPoint, ...e, zoomlevel });
  };
  const handleDoubleClick = e => {};

  const stopTriggerClickOnDoubleClick = e => {
    var funcToRun = makeDoubleClick(handleDoubleClick, handleClick);
    funcToRun(e);
  };
  map.on("click", stopTriggerClickOnDoubleClick);
}
leaflet.GridLayer.VisualizationWall2Wall = leaflet.GridLayer.extend({
  initialize: function(options) {
    leaflet.Util.setOptions(this, options);
  },
  options: {},
  getTileSize: function() {
    const { designDetails, dpi } = this.options;
    return new leaflet.Point(
      designDetails.Width / (designDetails.KLRatio * dpi),
      designDetails.Height / dpi
    );
  },
  _initTile: function(tile) {
    leaflet.DomUtil.addClass(tile, "leaflet-tile");
    tile.onselectstart = leaflet.Util.falseFn;
    tile.onmousemove = leaflet.Util.falseFn;

    // update opacity on tiles in IE7-8 because of filter inheritance problems
    if (leaflet.Browser.ielt9 && this.options.opacity < 1) {
      leaflet.DomUtil.setOpacity(tile, this.options.opacity);
    }

    if (leaflet.Browser.android && !leaflet.Browser.android23) {
      tile.style.WebkitBackfaceVisibility = "hidden";
    }
  },

  updateCurrentLevel: function(zoom) {
    this._removeTilesAtZoom(Math.round(zoom));
    this._update();
  },
  removeCurrentlevel: function(zoom) {
    this._removeTilesAtZoom(Math.round(zoom));
  },
  // getAbs: function(point, zoomlevel, flag) {
  //   if (flag) return point;
  //   return Math.abs(point % zoomlevel);
  // },
  getAbs: function(point, zoomlevel, flag) {
    if (flag) return point;
    const absPoint = Math.abs(point % zoomlevel);
    if (point >= 0) return absPoint;
    return (zoomlevel - absPoint) % zoomlevel;
  },
  createTile: function(coords, done) {
    // var tile = document.createElement("div");
    // tile.innerHTML = [coords.x, coords.y, coords.z].join(", ");
    // tile.style.outline = "1px solid red";

    // setTimeout(function() {
    //   done(null, tile); // Syntax is 'done(error, tile)'
    // }, 500 + Math.random() * 1500);

    // return tile;
    const tile = document.createElement("img");
    tile.setAttribute("role", "presentation");
    let i, point;
    const xlock = this.options.lockAxis === "x";
    const ylock = this.options.lockAxis === "y";
    if (coords.z === 1) {
      i = tilePoints.findIndex(
        item =>
          item.x === this.getAbs(coords.x, 1, xlock) &&
          item.y === this.getAbs(coords.y, 1, ylock) &&
          item.z === 1
      );
    }
    if (coords.z === 2) {
      i = tilePoints.findIndex(
        item =>
          item.x === this.getAbs(coords.x, 2, xlock) &&
          item.y === this.getAbs(coords.y, 2, ylock) &&
          item.z === 2
      );
    }
    if (coords.z === 3) {
      // i = tilePoints.findIndex(item => item.z === 4);
      i = tilePoints.findIndex(
        item =>
          item.x === this.getAbs(coords.x, 4, xlock) &&
          item.y === this.getAbs(coords.y, 4, xlock) &&
          item.z === 4
      );
    }
    if (i !== -1) {
      // if (coords.x !== 0) {
      //   tile.src = "";
      // } else {
      // }
      point = tilePoints[i];
      if (point && point.src) {
        tile.src = "";
        const dpi = this.options.dpi;
        const setTileSrc = () => {
          tile.src = point.src;
          tile.onload = () => {
            const { width, height } = tile;
            tile.style.height = `${height / dpi + 1}px`;
            tile.style.width = `${width / (this.options.designDetails.KLRatio * dpi) + 1}px`;
            done(null, tile);
          };
          tile.onerror = error => {
            done(error, tile);
          };
        };
        setTimeout(() => {
          setTileSrc();
        }, 2);
      }
    }

    return tile;
  }
});
const Visualization = props => {
  const { wallToWallLockAxis } = props;
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchColorList = useDispatchColorList();
  const uiState = useUiState();
  const { designDetails, fullpath, designName, hash } = designDetailState;
  const [zoom, setZoom] = useState(resolveZoomValue());
  const previousZoom = usePrevious(zoom);
  const previousFullpath = usePrevious(fullpath);
  const [customizing, setCustomizing] = useState(false);
  minZoom = getZoomParameters().minZoom;
  maxZoom = getZoomParameters().maxZoom;
  step = getZoomParameters().step;
  const dpi = window.flags.designView.dpi;

  useEffect(() => {
    if (!layer) return;
    layer.options.lockAxis = wallToWallLockAxis;
    layer.redraw();
  }, [wallToWallLockAxis]);
  const handleZoomChange = zoom => {
    if (zoom < minZoom || zoom > maxZoom) return;
    toggleScout(false);
    setZoom(zoom);
  };
  const toggleScout = value => {
    dispatchDesignDetails({ type: designDetailActions.TOOGLE_CUSTOSCOUT, payload: value });
  };
  const setLoading = value => {
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: value
    });
  };
  const handleDownloadDesign = () => {
    const zoomlevel = calculateZoomLevelFromZoom(zoom);
    return AppProvider.getRenderedDesign({
      designDetails,
      designName,
      fullpath,
      hash,
      zoom: zoomlevel === 4 ? 2 : zoomlevel
    }).then(canvas => {
      const widthToBeDownload = window.flags.visualizations.widthSaveImageWWPx;
      const heightToBeDownload = window.flags.visualizations.heightSaveImageWWPx;
      if (widthToBeDownload && heightToBeDownload) {
        const imageData = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height);
        // Create a new canvas with a width and height of 1000px
        const newCanvas = document.createElement("canvas");
        newCanvas.width = widthToBeDownload;
        newCanvas.height = heightToBeDownload;

        // Get the context of the new canvas
        const newContext = newCanvas.getContext("2d");

        // Calculate the number of times the image should be repeated horizontally and vertically
        const repeatX = Math.ceil(widthToBeDownload / imageData.width);
        const repeatY = Math.ceil(heightToBeDownload / imageData.height);

        // Repeat the image using the drawImage() method
        for (let x = 0; x < repeatX; x++) {
          for (let y = 0; y < repeatY; y++) {
            newContext.drawImage(canvas, x * imageData.width, y * imageData.height);
          }
        }
        // Append the new canvas to the DOM
        // document.body.appendChild(newCanvas);
        downloadImageData(newCanvas, `${designName}.jpg`, "jpg");
      } else {
        downloadImageData(canvas, `${designName}.jpg`, "jpg");
      }
    });
  };
  window.downloadRenderedDesign = handleDownloadDesign;
  const handleDesignClick = e => {
    const { containerPoint, designPoint } = e;

    dispatchDesignDetails({
      type: designDetailActions.SET_CUSTOSCOUT_COORDS,
      payload: {
        containerPoint,
        designPoint
      }
    });
  };
  const [debouncedDesignClick] = useDebouncedCallback(handleDesignClick, 300);
  useMount(() => {
    needsPanning = true;
    initMap({
      onDesignClick: debouncedDesignClick,
      onDrag: () => toggleScout(false),
      zoomStep: step
    });
    map.on("zoomend", e => {
      if (window.InterfaceElements.IsJpeg) return;
      handleZoomChange(map.getZoom());
    });
  });
  useEffect(() => {
    let la = true;
    const { fullpath, custoScout, designDetails } = designDetailState;
    if (!fullpath) return;
    const { designPoint } = custoScout;
    const { Width, Height, KLRatio } = designDetails;
    if (!designPoint || !Width) return;
    const { x, y } = designPoint;
    const xlock = wallToWallLockAxis === "x";
    const ylock = wallToWallLockAxis === "y";
    const desx = xlock ? x : x % Width;
    const desy = Math.round(ylock ? y / KLRatio : (y / KLRatio) % Height);
    if (desx < 0 || desy < 0 || desx > Width || desy > Height) {
      toggleScout(false);
      return;
    }
    toggleScout(false);
    AppProvider.getColorAt({
      file: fullpath,
      x: Math.round(desx),
      y: Math.round(desy),
      Width,
      Height
    }).then(color => {
      if (!la || color === 255) return;
      dispatchDesignDetails({ type: designDetailActions.SELECT_DESIGN_COLOR, payload: color });
      toggleScout(true);
    });
    return () => {
      la = false;
    };
  }, [designDetailState.custoScout.designPoint]);

  useEffect(() => {
    let la = true;
    const { designDetails, fullpath, hash } = designDetailState;
    // const last = history.length ? history[history.length - 1] : {};
    // const { hash: prevHash } = last;
    if (!fullpath) return;
    const loadVisualization = async () => {
      let zoomlevel = calculateZoomLevelFromZoom(zoom);
      let prevZoomLevel = calculateZoomLevelFromZoom(previousZoom);
      if (previousFullpath !== fullpath) {
        setLoading(true);

        map.eachLayer(function(layer) {
          map.removeLayer(layer);
        });
        const zoomlevel = calculateZoomLevelFromZoom(zoom);
        let heightRatio = 1;

        tilePoints = [
          { x: 0, y: 0, z: 1 },
          { x: 0, y: 0, z: 2 },
          { x: 1, y: 0, z: 2 },
          { x: 0, y: 1, z: 2 },
          { x: 1, y: 1, z: 2 },
          { x: 0, y: 0, z: 4 },
          { x: 1, y: 0, z: 4 },
          { x: 2, y: 0, z: 4 },
          { x: 3, y: 0, z: 4 },
          { x: 0, y: 1, z: 4 },
          { x: 1, y: 1, z: 4 },
          { x: 2, y: 1, z: 4 },
          { x: 3, y: 1, z: 4 },
          { x: 0, y: 2, z: 4 },
          { x: 1, y: 2, z: 4 },
          { x: 2, y: 2, z: 4 },
          { x: 3, y: 2, z: 4 },
          { x: 0, y: 3, z: 4 },
          { x: 1, y: 3, z: 4 },
          { x: 2, y: 3, z: 4 },
          { x: 3, y: 3, z: 4 }
        ];

        layer = new leaflet.GridLayer.VisualizationWall2Wall({
          designDetails,
          heightRatio,
          lockAxis: wallToWallLockAxis,
          dpi
        });

        layer.addTo(map);
        const renderedTilePoints = await getRenderedDesignWW({
          designDetails,
          fullpath,
          zoom: zoomlevel,
          hash
        });
        if (la)
          renderedTilePoints.forEach(point => {
            const { x, y, z } = point;
            const ind = tilePoints.findIndex(item => item.x === x && item.y === y && item.z === z);
            tilePoints[ind].src = point.src;
            tilePoints[ind].hash = hash;
          });
        map.setZoom(zoom);
        layer.redraw();

        const { Width, Height, KLRatio } = designDetails;
        const tileCenter = getTileCenter({
          Width,
          Height,
          KLRatio,
          heightRatio,
          tileSize: options.tileSize,
          dpi
        });
        if (needsPanning) {
          needsPanning = false;
          map.panTo([-tileCenter.y, tileCenter.x]);
        } else {
          const center = map.getCenter();
          map.panTo([center.lat, tileCenter.x]);
        }

        layer.on("load", () => {
          setLoading(false);
        });
        setLoading(false);
      } else {
        if (previousZoom !== zoom) {
          map.setZoom(zoom);
          if (prevZoomLevel !== zoomlevel) {
            const points = tilePoints.filter(point => point.z === zoomlevel && point.hash !== hash);
            if (points.length) {
              setCustomizing(true);
              points.forEach(pnt => {
                const i = tilePoints.findIndex(
                  item => item.x === pnt.x && item.y === pnt.y && item.z === pnt.z
                );
                tilePoints[i].src = null;
              });
              layer.updateCurrentLevel(zoom);
              const renderedTilePoints = await getRenderedDesignWW({
                designDetails,
                fullpath,
                zoom: zoomlevel,
                hash
              });
              if (la)
                renderedTilePoints.forEach(point => {
                  const { x, y, z } = point;
                  const ind = tilePoints.findIndex(
                    item => item.x === x && item.y === y && item.z === z
                  );
                  tilePoints[ind].src = point.src;
                  tilePoints[ind].hash = hash;
                });

              layer.updateCurrentLevel(zoom);
              setTimeout(() => {
                setCustomizing(false);
              }, 1000);
              if (designDetailState.loading) setLoading(false);
            }
          }
        } else {
          setLoading(true);

          layer.removeCurrentlevel(zoom);
          const renderedTilePoints = await getRenderedDesignWW({
            designDetails,
            fullpath,
            zoom: zoomlevel,
            hash
          });
          if (la) {
            renderedTilePoints.forEach(point => {
              const { x, y, z } = point;
              const ind = tilePoints.findIndex(
                item => item.x === x && item.y === y && item.z === z
              );
              tilePoints[ind].src = point.src;
              tilePoints[ind].hash = hash;
            });
          }

          layer.updateCurrentLevel(zoom);
          setLoading(false);
        }
      }
    };
    const debouncedLoad = debounce(() => {
      loadVisualization();
    }, 400);
    debouncedLoad();
    return () => {
      la = false;
    };
  }, [hash, zoom]);

  const getActualZoomFactor = () => {
    const zoomFactor =
      map && layer ? map.getZoomScale(map.getZoom(), 1) * layer.options.heightRatio * dpi : zoom;
    return zoomFactor;
  };
  return (
    <div
      className="tile-container"
      style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}
      ref={containerRef}
    >
      <div id="map" ref={canvasRef}></div>
      <div className={classNames("at-vis-spinner", { "at-vis-spinner--hidden": !customizing })}>
        <div className="at-vis-spinner__text">Updating design... </div>
        <AtSpinner size="sm" />
      </div>

      <AtSlider
        isIdle={uiState.isIdle}
        value={zoom}
        zoomFactor={getActualZoomFactor()}
        // onValueChange={handleZoomChange}
        onRelease={handleZoomChange}
        min={minZoom}
        max={maxZoom}
        stepSize={step}
      />
      {!designDetailState.loading &&
        designDetailState.selectedColor !== -1 &&
        designDetailState.custoScout.show && (
          <div
            style={{
              position: "absolute",
              left: `${designDetailState.custoScout.containerPoint.x}px`,
              top: `${designDetailState.custoScout.containerPoint.y}px`,
              zIndex: 100
            }}
            className="at-custo-assistant-scout ui"
          >
            <AreaSwatch
              designColor={
                designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
              }
              colorIndex={designDetailState.selectedColor}
              popoverPosition="auto"
              colorNumber={designDetailState.colorNumbers[designDetailState.selectedColor]}
              handleClick={e => e.stopPropagation()}
              onColorPieClick={(color, e) => {
                dispatchColorList({ type: colorListActions.SELECT_COLOR, payload: color });
              }}
            />
            <AtIcon
              icon="close"
              onClick={() =>
                dispatchDesignDetails({
                  type: designDetailActions.TOOGLE_CUSTOSCOUT,
                  payload: false
                })
              }
            />
          </div>
        )}
    </div>
  );
};

export default Visualization;
