/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AtDialog from "../../molecules/AtDialog";
import { H5, InputGroup, Button, FormGroup, TextArea } from "@blueprintjs/core";
import AtIcon from "../../atoms/AtIcon";
import { AtSpinnerOverlay } from "../../atoms/AtSpinner";
import AtButton from "../../atoms/AtButton";
import PdfRenderer from "../../atoms/PdfRenderer";
import strings from "../../../strings";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";
import { getFromSessionStorage } from "../../../utils/domUtils";
import { sendOrdersheetEmail } from "../../../middleware/ordersheet.middleware";
import { getEmailMessages } from "../../../utils/stringUtils";
import { useDesignListState } from "../../../reducers/designlist.reducer";
import AppProvider from "../../../api/appProvider";
const OrdersheetPreview = props => {
  const dispatchUiState = useUiDispatch();
  const state = useDesignListState();
  const {
    ordersheetPath,
    onClose,
    fullpath: designPath,
    orderSheetDetails,
    formFields,
    saveOrdersheet
  } = props;

  const replaceLastDimension = filename => {
    // Match the last occurrence of digits followed by 'x' and more digits
    let regex = /(\d+\s*x\s*\d+)(?![\s\S]*\1)/;
    return filename.replace(regex, "1 x 2");
  };

  const customerName =
    formFields.find(item => item.fieldName.toLowerCase() === "customer name") || {};
  let customerEmail =
    formFields.find(
      item =>
        item.fieldName.toLowerCase() === "email address" ||
        item.fieldName.toLowerCase() === "customer email"
    ) || {};

  if (window.flags.ordersheet.getEmailDetailsFromSStorage && !customerEmail.fieldValue) {
    customerEmail = {
      fieldName: "customer email",
      fieldValue: getFromSessionStorage("customerEmail") || ""
    };
  }
  // console.log("customerEmail", customerEmail);
  let customerPhone =
    formFields.find(
      item =>
        item.fieldName.toLowerCase() === "phone number" ||
        item.fieldName.toLowerCase() === "contact number"
    ) || {};

  if (window.flags.ordersheet.getEmailDetailsFromSStorage && !customerPhone.fieldValue) {
    customerPhone = {
      fieldName: "customer email",
      fieldValue: getFromSessionStorage("customerPhone") || ""
    };
  }
  // console.log("customerPhone", customerPhone);
  const instructions =
    formFields.find(item => item.fieldName.toLowerCase() === "additional instructions") || {};
  // console.log("instructions", instructions);
  const [email, setEmail] = useState(customerEmail.fieldValue);
  const [name, setName] = useState(customerName.fieldValue);
  const [phone, setPhone] = useState(customerPhone.fieldValue || "");
  const [note, setNote] = useState(instructions.fieldValue);
  const [poNumber, setPoNumber] = useState("");
  const [emailState, setEmailState] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { email: emailTexts, ordersheet: ordersheetFlags } = window.flags;

  useEffect(() => {
    setName(customerName.fieldValue);
  }, [customerName.fieldValue]);
  useEffect(() => {
    setEmail(customerEmail.fieldValue);
    // console.log("customerEmail.fieldValue", customerEmail.fieldValue);
  }, [customerEmail.fieldValue]);
  useEffect(() => {
    setPhone(customerPhone.fieldValue);
  }, [customerPhone.fieldValue]);
  useEffect(() => {
    setNote(instructions.fieldValue);
  }, [instructions.fieldValue]);
  const emailMessages = getEmailMessages(strings.ordersheet.emailMessages);
  const onDialogClose = () => {
    setEmailState("");
    if (onClose) onClose();
  };
  const validateEmail = email => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  };

  const handleSubmitEmail = ({ sendCustomerOnly = 0 }) => {
    setEmailState(emailMessages.sending);
    if (!name || !email || name.trim() === "" || email.trim() === "") {
      setEmailState(emailMessages.incompleteInfo);
    } else if (!validateEmail(email)) {
      setEmailState(emailMessages.invalidEmail);
    } else {
      if (ordersheetFlags.isContactNumberMandatory && phone.trim() === "") {
        setEmailState(emailMessages.incompletePhoneInfo);
        return;
      }
      if (ordersheetFlags.isNotesMandatory && note.trim() === "") {
        setEmailState(emailMessages.incompleteNotesInfo);
        return;
      }
      if (window.flags.accountSpecific.dalyn.isPOMandatory) {
        if (poNumber.trim() === "") {
          setEmailState(emailMessages.incompletePOInfo);
          return;
        } else {
          const po = formFields.find(item => item.fieldName.toLowerCase() === "po number");
          po.fieldValue = poNumber;
        }
      }
      sendOrdersheetEmail({
        emailTexts,
        name,
        email,
        phone,
        note,
        designPath,
        orderSheetDetails,
        formFields,
        ordersheetFlags,
        emailCustomerOnly: sendCustomerOnly == 0 ? 0 : 1
      })
        .then(response => {
          if (response === true) {
            setEmailState(emailMessages.sent);
            if (ordersheetFlags.closeAfterEmailSent) {
              onDialogClose();
              //set toast
              dispatchUiState({
                type: mainUiActions.SET_TOAST_PROPS,
                payload: emailMessages.sent
              });
            }
            const isStrikeOffTrue = formFields.some(
              field => field.fieldName === "Strikeoff" && field.fieldValue === "Yes"
            );
            if (
              window.flags.accountSpecific.tamarian.sendStrikeOffInOrdersheet &&
              isStrikeOffTrue
            ) {
              const fullpath = replaceLastDimension(state.activeShape.fullPath);
              const orderSheetDetailsNew = {
                ...orderSheetDetails,
                PhysicalHeight: 2,
                PhysicalWidth: 1,
                Width: 110,
                Height: 96,
                Unit: "ft"
              };
              AppProvider.getDesignThumbnails({ designs: [{ fullPath: fullpath }] }).then(
                async res => {
                  await AppProvider.fetchTileDetails({ file: fullpath });
                  setTimeout(() => {
                    sendOrdersheetEmail({
                      emailTexts,
                      name,
                      email,
                      phone,
                      note,
                      designPath: fullpath,
                      orderSheetDetails: orderSheetDetailsNew,
                      formFields,
                      ordersheetFlags,
                      emailCustomerOnly: sendCustomerOnly == 0 ? 0 : 1
                    })
                      .then(response => {
                        console.log("returnnewPromise -> response", response);
                      })
                      .catch(err => {
                        console.log("returnnewPromise -> err", err);
                      });
                  }, [2000]);
                }
              );
            }
          } else {
            setEmailState(emailMessages.fail);
          }
        })
        .catch(err => {
          setEmailState(emailMessages.fail);
        });
      // var emailBody_customer = null,
      //   emailBody_company = null;
      // if (AddCustomTextInDefaultEmail) {
      //   var nextline = escape("<br/>");
      //   let phoneNum = phone ? phone : "";

      //   var frontLine =
      //     "An order for " +
      //     getNameFromFullpath(designPath) +
      //     " has been placed through exploRUG with the following details:-";
      //   var frontLine_customer, frontLine_company;

      //   frontLine_customer = customerEmailFrontText
      //     ? customerEmailFrontText.replace("_______", getNameFromFullpath(designPath))
      //     : frontLine;
      //   frontLine_company = companyEmailFrontText
      //     ? customerEmailFrontText.replace("_______", getNameFromFullpath(designPath))
      //     : frontLine;

      //   var bodyPart =
      //     nextline +
      //     nextline +
      //     "%0D%0A %0D%0A CUSTOMER NAME | " +
      //     name.trim() +
      //     nextline +
      //     "%0D%0A %0D%0A CUSTOMER EMAIL | " +
      //     escape(email.trim()) +
      //     nextline +
      //     " %0D%0A %0D%0A CUSTOMER PHONE | " +
      //     phoneNum +
      //     nextline +
      //     "CUSTOMER NOTE | " +
      //     escape(note) +
      //     nextline +
      //     nextline;

      //   var lastLine = " Details about the custom order are specified in the attached Order Sheet.";
      //   var lastLine_customer = customerEmailBody ? customerEmailBody : lastLine;
      //   lastLine_customer = lastLine_customer + nextline;

      //   footer = footer ? footer : "";
      //   emailBody_customer = frontLine_customer + bodyPart + lastLine_customer + footer;

      //   var lastline_company = companyEmailBody ? companyEmailBody : lastLine;
      //   lastline_company = lastline_company + nextline;

      //   emailBody_company = frontLine_company + bodyPart + lastline_company + footer;
      // }

      // AppProvider.sendOrdersheetEmail({
      //   file: designPath,
      //   props: orderSheetDetails,
      //   name: name,
      //   email: email,
      //   phone: phone,
      //   note: note,
      //   customFields: formFields,
      //   sameColorDifferentSymbol: window.flags.colorAreaSwatch.differentSymbolForAllColors,
      //   pilenames: ordersheetFlags.showPileHeightNames
      //     ? window.TextureOptions.DefaultPileheightNames
      //     : "",
      //     materialNames: getMaterialNameArray(),
      //   setCustomEmail: setCustomEmail,
      //   customerEmailBody: emailBody_customer,
      //   customerEmailSubject: customerEmailSubject,
      //   customerEmailFromAddress: customerEmailFromAddress
      //     ? escape(customerEmailFromAddress)
      //     : customerEmailFromAddress,
      //   companyEmailBody: emailBody_company,
      //   companyEmailSubject: companyEmailSubject,
      //   companyEmailFromAddress: companyEmailFromAddress
      //     ? escape(companyEmailFromAddress)
      //     : companyEmailFromAddress
      // })
      //   .then(response => {
      //     if (response === true) {
      //       setEmailState(emailMessages.sent);
      //       if (ordersheetFlags.closeAfterEmailSent) {
      //         onDialogClose();
      //         //set toast
      //         dispatchUiState({
      //           type: mainUiActions.SET_TOAST_PROPS,
      //           payload: emailMessages.sent
      //         });
      //       }
      //     } else {
      //       setEmailState(emailMessages.fail);
      //     }
      //   })
      //   .catch(err => {
      //     setEmailState(emailMessages.fail);
      //   });
    }
  };
  return (
    <AtDialog size="lg" {...props} onClose={onDialogClose}>
      <div className="at-ordersheet-preview">
        <div className="col-8 at-ordersheet-preview__view-section">
          <PdfRenderer file={ordersheetPath} onRenderComplete={() => setIsLoading(false)} />
          <AtSpinnerOverlay show={isLoading} />
        </div>
        <div className="col-4 at-ordersheet-preview__form-section">
          <div className="at-ordersheet-dialog-buttons">
            <Button onClick={onDialogClose} minimal icon={<AtIcon icon="close"></AtIcon>} />
          </div>
          <div className="at-ordersheet-form-area">
            <H5 className="at-section-title"> {strings.order} </H5>
            <FormGroup label={strings.ordersheet.yourName} labelInfo="*">
              <InputGroup
                type="string"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup label={strings.ordersheet.yourEmailAddress} labelInfo="*">
              <InputGroup
                type="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup
              label={strings.ordersheet.yourPhoneNumber}
              labelInfo={ordersheetFlags.isContactNumberMandatory ? "*" : ""}
            >
              <InputGroup
                type="tel"
                value={phone}
                onChange={e => {
                  setPhone(e.target.value);
                }}
              />
            </FormGroup>
            {window.flags.accountSpecific.dalyn.isPOMandatory && (
              <FormGroup label="PO Number" labelInfo={"*"}>
                <InputGroup
                  type="tel"
                  value={poNumber}
                  onChange={e => {
                    setPoNumber(e.target.value);
                  }}
                />
              </FormGroup>
            )}
            <FormGroup
              label={strings.ordersheet.notes}
              labelInfo={ordersheetFlags.isNotesMandatory ? "*" : ""}
            >
              <TextArea
                style={{ width: "100%" }}
                type="string"
                value={note}
                onChange={e => {
                  setNote(e.target.value);
                }}
              />
            </FormGroup>
            {window.flags.accountSpecific.priceEstimation_davisLandings
              .ordersheetPreviewButtonsLayout && (
              <div id="davisRugs-OrdersheetPreviewButton">
                <AtButton
                  intent="primary"
                  onClick={saveOrdersheet}
                  text="Save pdf to my documents"
                ></AtButton>
                <AtButton
                  intent="primary"
                  onClick={() => handleSubmitEmail({ sendCustomerOnly: 1 })}
                  text="Email pdf to myself"
                ></AtButton>
              </div>
            )}
            <AtButton
              intent="primary"
              onClick={handleSubmitEmail}
              text={strings.ordersheet.sendEmail}
              id="sendEmailOrdersheetPreview"
            ></AtButton>
            {emailState && (
              <div
                className={`bp3-callout bp3-intent-${emailState.intent} at-ordersheet-form-area-message `}
              >
                {emailState.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </AtDialog>
  );
};
export default OrdersheetPreview;
