import React, { Fragment } from "react";
import SearchBar from "../../molecules/SearchBar";
import AtButton from "../../atoms/AtButton";
import { Popover, Menu, MenuItem, Position, Tag } from "@blueprintjs/core";
import {
  useDispatchDesignList,
  designListActions,
  useDesignListState
} from "../../../reducers/designlist.reducer";
import { useMount } from "react-use";
import AtCheckbox from "../../atoms/AtCheckbox";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import { CDN_domain } from "../../../api/appProvider";
const initialFilterOptions = [
  {
    PhysicalWidth: 70,
    PhysicalHeight: 120,
    Unit: "cm",
    thresHoldWidth: 70,
    thresHoldHeight: 120,
    text: "Small",
    selected: false
  },
  {
    PhysicalWidth: 180,
    PhysicalHeight: 270,
    Unit: "cm",
    thresHoldWidth: 40,
    thresHoldHeight: 30,
    text: "Medium",
    selected: false
  },
  {
    PhysicalWidth: 500,
    PhysicalHeight: 500,
    Unit: "cm",
    thresHoldWidth: 280,
    thresHoldHeight: 200,
    text: "Large",
    selected: false
  }
];

const DesignSearchBar = props => {
  const { hasFilterBySize } = window.flags.designListTree;
  const { handleFilter, ...otherProps } = props;
  const designListState = useDesignListState();
  const { sizeFilter, tagFilter } = designListState;
  const uiState = useUiState();
  const uiDispatch = useUiDispatch();
  const dispatch = useDispatchDesignList();
  useMount(() => {
    if (!hasFilterBySize) return;
    dispatch({ type: designListActions.SET_SIZE_FILTER, payload: initialFilterOptions });
  });
  
  // var wordCloudTag = getFromSessionStorage("wordcloudtag");
  // const [wordTag, setWordTag] = useState(getFromSessionStorage("wordcloudtag") || "")

  // useEffect(()=>{
  //   console.log('wordTag', wordCloudTag, ',', wordTag);

  //   setWordTag(wordTag);
  //   dispatch({
  //     type: designListActions.SET_TAG_FILTER,
  //     payload: wordTag
  //   });

  // },[getFromSessionStorage("wordcloudtag")]);

  return (
    <div className="at-design-search-container">
      {window.flags.showWordCloud && window.WordCloudData !== "" && (
        <div className="at-design-search-container-wordcloud">
          <AtButton
            intent="secondary"
            tertiary
            id="at-wordCloud"
            tooltip="Sort by Category"
            onClick={() => {
              if (!uiState.showWordCloud)
                uiDispatch({
                  type: mainUiActions.SET_SHOW_WORDCLOUD,
                  payload: true
                });
            }}
          >
            <img
              src={CDN_domain + `v3assets/word-cloud/word-cloud-thumb.png`}
              alt="word-cloud"
            />
          </AtButton>
        </div>
      )}

      <div className="at-design-search-container-text">
        <div className="at-search-container">
          <SearchBar {...otherProps} />
          {sizeFilter.length && hasFilterBySize ? (
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  {sizeFilter.map((option, index) => (
                    <MenuItem
                      shouldDismissPopover={false}
                      key={index}
                      text={
                        <AtCheckbox
                          label={option.text}
                          checked={option.selected}
                          onChange={e => {
                            const f = JSON.parse(JSON.stringify(sizeFilter));
                            f[index].selected = !option.selected;
                            dispatch({ type: designListActions.SET_SIZE_FILTER, payload: f });
                            const filter = f.find(item => !!item.selected);
                            handleFilter(filter, e);
                          }}
                        />
                      }
                    />
                  ))}
                </Menu>
              }
              minimal
            >
              <AtButton icon="filter" minimal />
            </Popover>
          ) : (
            <></>
          )}
        </div>
        {sizeFilter.length && hasFilterBySize ? (
          sizeFilter.map((option, index) =>
            !!option.selected ? (
              <Tag
                key={index}
                style={{ margin: "0.25rem" }}
                onRemove={e => {
                  const f = JSON.parse(JSON.stringify(sizeFilter));
                  f[index].selected = false;
                  dispatch({ type: designListActions.SET_SIZE_FILTER, payload: f });
                  const filter = f.find(item => !!item.selected);
                  handleFilter(filter, e);
                }}
                intent="primary"
                round
              >
                {option.text}
              </Tag>
            ) : (
              <Fragment key={index}></Fragment>
            )
          )
        ) : (
          <></>
        )}
        {tagFilter && tagFilter !== "" && (
          <Tag
            style={{ margin: "0.25rem" }}
            onRemove={e => {
              dispatch({ type: designListActions.SET_TAG_FILTER, payload: "" });
              uiDispatch({ type: mainUiActions.SET_SHOW_WORDCLOUD, payload: false });
            }}
            intent="primary"
            round
          >
            {tagFilter}
          </Tag>
        )}
        {window.flags.showWordCloud && window.WordCloudData !== "" && (
          <AtButton
            intent="primary"
            tertiary
            onClick={() => {
              if (!uiState.showWordCloud)
                uiDispatch({
                  type: mainUiActions.SET_SHOW_WORDCLOUD,
                  payload: true
                });
            }}
          >
            {tagFilter && tagFilter !== "" ? "Replace Category" : "Sort by Category"}
          </AtButton>
        )}
      </div>
    </div>
  );
};

export default DesignSearchBar;
