import React, { useState, useEffect } from "react";
import {
  useUiState,
  useUiDispatch,
  mainUiActions,
  pageViews
} from "../../../reducers/mainui.reducer";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import FadeIn from "react-fade-in";
import BrandLogo from "../../molecules/BrandLogo";
import classNames from "classnames";
import { loadingTexts } from "../../../utils/stringUtils";

const LoadingOverlay = props => {
  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const { loading } = useDesignDetailState();
  const [loadingText] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  // useEffect(() => {
  //   setInterval(() => {
  //     if (loadingText + 1 === loadingTexts.length) setLoadingText(0);
  //     setLoadingText(loadingText + 1);
  //   }, 1000);
  // });
  let pageView = window.flags.homeTemplate === pageViews.CREATEYOURRUG;
  useEffect(() => {
    if (loading === true) return;
    dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 100 });
    setTimeout(() => {
      dispatchUiState({ type: mainUiActions.SET_SHOW_LOAD_OVERLAY, payload: false });
      {
        pageView && (document.getElementsByTagName("body")[0].style.overflow = "auto");
      }
    }, 100);
  }, [loading, dispatchUiState]);
  return (
    <FadeIn>
      <div
        className={`at-loading-overlay overlay ${loading ? "loading" : ""}`}
        id={pageView ? "fadeindelayCYR" : "overlayHome"}
      >
        <div className="at-loading-logo">
          <BrandLogo onLoad={() => setShowLoader(true)} onError={() => setShowLoader(true)} />
        </div>
        <div
          className={classNames("bp3-progress-bar bp3-no-stripes at-initial-loading-bar", {
            hidden: !showLoader
          })}
        >
          <div
            className="bp3-progress-meter"
            style={{ width: `${uiState.loadingOverlayPercent}%` }}
          />
        </div>
        <div
          className={classNames("bp3-text-large", {
            hidden: !showLoader
          })}
        >
          {loadingTexts[loadingText]}
        </div>
      </div>
    </FadeIn>
  );
};
export default LoadingOverlay;
