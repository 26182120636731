export var onlyPomBoxColors = [];
var silkMatIndices;
const getOnlyPomboxColors = ({ colorlist }) => {
  ///get color box which are in pombox but not in pomchain
  onlyPomBoxColors = [];
  if (colorlist.length > 1) {
    const pomBoxColorList = colorlist.filter(list => list.Name.toLowerCase() === "pom box");
    var pomBoxColors = pomBoxColorList[0].ColorRows;
    const pomChainColorList = colorlist.filter(list => list.Name.toLowerCase() === "pom chain");
    var pomChainColors = pomChainColorList[0].ColorRows;
    var pomChainColorNames = pomChainColors.map(color => {
      return color.ColorName.split(" ")[0];
    });

    const onlyPomBoxColorsList = pomBoxColors.filter(color => {
      if (pomChainColorNames.indexOf(color.ColorName.split(" ")[0]) < 0) return color;
    });
    onlyPomBoxColors = onlyPomBoxColorsList.map(color => color.ColorName);
    // const onlyPomChainColorsList = pomChainColors.map(color => color.ColorName);
    // console.log("getOnlyPomboxColors -> onlyPomChainColorsList", onlyPomChainColorsList);
    // console.log("getOnlyPomboxColors -> onlyPomBoxColors", onlyPomBoxColors);
  }
  return onlyPomBoxColors;
};
const getOnlyPomChainColors = ({ colorlist }) => {
  let onlyPomChainColors = [];
  if (colorlist.length > 1) {
    const pomChainColorList = colorlist.filter(list => list.Name.toLowerCase() === "pom chain");
    var pomChainColors = pomChainColorList[0].ColorRows;
    onlyPomChainColors = pomChainColors.map(color => color.ColorName);
  }
  return onlyPomChainColors;
};
function findWithAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}
const getPrice_DavisRugs = ({
  designColors,
  area,
  RugType,
  RugTypes, // options: ["Area Rug", "Strike off"]
  RugTechnique,
  RugTechniques, // options: ["CYP Tuft", "Hand Tuft", "Handknot"]
  qualityindex,
  stocksilk,
  silkMaterialIndices,
  onlyPomBoxColors,
  onlyPomChainColors
}) => {
  silkMatIndices = silkMaterialIndices;
  var price = 0,
    add = 0;

  var rugPadArea = area;
  var rugPadRate = rugPadArea > 100 ? 1.25 : 1.75;

  var unique_currentColorNames = designColors.filter(onlyUnique);
  area = area < 35 ? 35 : area;

  let rate = 1;
  if (isSimilar(RugType, RugTypes[1])) {
    // // if rug type is Strike off
    // Strike off
    var StrikeOffPrice = calculateStrikeOffPrice({
      unique_currentColorNames,
      designColors,
      RugTechnique,
      RugTechniques,
      stocksilk
    });
    price = StrikeOffPrice;
    var totalRugPadPrice = Math.round(price + rugPadRate * rugPadArea);
    return { price, rugPadRate, totalRugPadPrice };
  } else if (isSimilar(RugType, RugTypes[0])) {
    // if rug type is Area Rug

    if (isSimilar(RugTechnique, RugTechniques[0])) {
      // if rug technique is cyp tuft

      var rate_sqft = [
        {
          area: 53,
          rate: [34.5, 41.5, 47.75]
        },
        {
          area: 90,
          rate: [32, 38.5, 43.75]
        },
        {
          area: 120,
          rate: [30, 36, 41.5]
        },
        {
          area: 180,
          rate: [28.5, 34.25, 39.75]
        }
      ];

      var rate_values = rate_sqft.find(elem => area <= elem.area);
      rate_values = rate_values ? rate_values : { rate: [28, 33.75, 38.75] }; //rate_values is undefined if area is>180
      rate = rate_values.rate[qualityindex];

      unique_currentColorNames.forEach(function(color, index) {
        var currentColor = color.ColorName.split(" ")[0];
        var added = false;

        if (isSilk(color.Material)) {
          if (stocksilk.indexOf(currentColor) < 0) {
            add += 200;
            added = true;
          }
        }
        // const isOnlyPomBoxColor =
        //   onlyPomBoxColors.length && onlyPomBoxColors.indexOf(color.ColorName) >= 0;
        const isOnlyPomChainColor =
          onlyPomChainColors.length && onlyPomChainColors.indexOf(color.ColorName) >= 0;

        //if (!added && isOnlyPomBoxColor) {
        if (!added && !isOnlyPomChainColor) {
          add += 200;
        }
      });
    } else if (isSimilar(RugTechnique, RugTechniques[1])) {
      if (hasWoolAndSilk(designColors).silk) rate = 39.5;
      else rate = 37.5;
    } else if (isSimilar(RugTechnique, RugTechniques[2])) {
      var silkAmount = 0;
      var totalKnots = 0;
      designColors.forEach(function(element, index) {
        totalKnots += element.Knots;
        const thisYarn = element.YarnDetails[0];
        if (isSilk(thisYarn.Material)) {
          silkAmount += element.Knots;
        }
      });
      const silkPercent = (silkAmount * 100) / totalKnots;

      if (qualityindex === 0) {
        //"residential"
        // var silkPercent_rate = [
        //   { silk: 0, rate: 34.75 },
        //   { silk: 5, rate: 37.5 },
        //   { silk: 10, rate: 40.5 },
        //   { silk: 15, rate: 43.5 },
        //   { silk: 20, rate: 46.5 },
        //   { silk: 25, rate: 49.5 },
        //   { silk: 30, rate: 52.5 },
        //   { silk: 35, rate: 55.5 },
        //   { silk: 40, rate: 58.5 },
        //   { silk: 45, rate: 61.5 },
        //   { silk: 50, rate: 64.5 },
        //   { silk: 55, rate: 67.5 },
        //   { silk: 60, rate: 70.5 },
        //   { silk: 65, rate: 73.5 },
        //   { silk: 70, rate: 76.5 },
        //   { silk: 75, rate: 78.25 }
        // ];
        var silkPercent_rate = [
          { silk: 0, rate: 37.5 },
          { silk: 5, rate: 40.25 },
          { silk: 10, rate: 43.5 },
          { silk: 15, rate: 46.5 },
          { silk: 20, rate: 49.5 },
          { silk: 25, rate: 53.0 },
          { silk: 30, rate: 56.0 },
          { silk: 35, rate: 59.5 },
          { silk: 40, rate: 62.5 },
          { silk: 45, rate: 65.5 },
          { silk: 50, rate: 69.0 },
          { silk: 55, rate: 72.0 },
          { silk: 60, rate: 75.25 },
          { silk: 65, rate: 78.5 },
          { silk: 70, rate: 81.5 },
          { silk: 75, rate: 83.5 }
        ];
        var rate_value = silkPercent_rate.find(elem => silkPercent <= elem.silk);
        rate = rate_value ? rate_value.rate : 83.5;
      } else if (qualityindex === 1) {
        // "heavyresidential)
        return "--";
      } else {
        return "--";
      }
    }
  }
  price = Math.round((area * rate + add) * 1);
  var rugPadPrice = rugPadArea * rugPadRate;
  if (rugPadPrice < 42) {
    rugPadPrice = 42;
  }
  var totalRugPadPrice = Math.round(price + rugPadPrice);
  return { price, rugPadRate, totalRugPadPrice };
};
const calculateStrikeOffPrice = ({
  unique_currentColorNames,
  designColors,
  RugTechnique,
  RugTechniques,
  stocksilk
}) => {
  var price;
  if (isSimilar(RugTechnique, RugTechniques[0])) {
    price = 100;
    unique_currentColorNames.forEach(function(color, index) {
      const thisYarn = color.YarnDetails[0];
      var currentColor = thisYarn.ColorName.split(" ")[0];
      if (isSilk(thisYarn.Material)) {
        // if material is silk
        if (stocksilk.indexOf(currentColor) < 0) {
          price += 75;
        }
      }
    });
  } else if (isSimilar(RugTechnique, RugTechniques[1])) {
    //hand tuft
    const materialsPresent = hasWoolAndSilk(designColors);
    price =
      materialsPresent.wool && materialsPresent.silk ? 125 : materialsPresent.wool ? 100 : 175;
  } else if (isSimilar(RugTechnique, RugTechniques[2])) {
    //hand knot
    const materialsPresent = hasWoolAndSilk(designColors);
    price =
      materialsPresent.wool && materialsPresent.silk ? 150 : materialsPresent.wool ? 100 : 175;
  }

  // console.log("TCL: price", price);
  return price;
};

export const onlyUnique = (value, index, self) => {
  var valueIndex = findWithAttr(self, "ColorName", value.ColorName);
  if (
    isSilk(self[valueIndex].YarnDetails[0].Material) !== isSilk(self[index].YarnDetails[0].Material)
  )
    return value;
  else return valueIndex === index;
};

function isSilk(mat) {
  if (silkMatIndices.indexOf(mat) >= 0) return true;
  else return false;
}
const isSimilar = (text1, text2) => {
  if (!text1 || !text2) return false;
  else return text1.toLowerCase() === text2.toLowerCase();
};

const hasWoolAndSilk = designColorsArr => {
  var wool = 0,
    silk = 0;
  designColorsArr.forEach(function(element, index) {
    const thisYarn = element.YarnDetails[0];
    if (isSilk(thisYarn.Material)) {
      silk = 1;
    } else wool = 1;
  });
  return { wool, silk };
};

const DavisPriceEstimation = {
  isSimilar,
  getOnlyPomboxColors,
  getOnlyPomChainColors,
  getPrice_DavisRugs
};
export default DavisPriceEstimation;
