import React, { useState, useEffect, useRef } from "react";
import "./_animatesubject.scss";
import styled from "styled-components";

const transformoriginoptions = ["center right", "center left", "bottom center", "center center"];
const extended = 110;
let isblurbackground = false;

const AnimatedImgWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-size: 200%;
  ${!isblurbackground ? "background:black;" : ""}
  &.active {
    animation: ${props => props.animationDur}s ease-out wrapper forwards;
    @keyframes wrapper {
      0% {
        opacity: 1;
      }
      95% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
const AnimatedImg = styled.img`
  ${props =>
    props.fitbyH ? `height: ${extended}%;width: auto;` : `height: auto;width: ${extended}%;`}
 
  
  /*&.slide {
    bottom: ${props => (props.yS < 0 ? props.yS - 10 + "px" : "0")};
    left: ${props => (props.xS > 0 ? "-" + (props.xS + 10) + "px" : "0")};
    
  }*/

  &.zoomin {
    transform-origin: ${transformoriginoptions[Math.floor(Math.random() * 4)]};
    transform: scale(1.1, 1.1);
  }

  &.zoomout {
      ${props => (props.fitbyH ? "height: 100%;width: auto;" : "height: auto;width: 100%;")}
    transform-origin: ${transformoriginoptions[Math.floor(Math.random() * 4)]};
  }

  &.active.slide {

    animation: ${props => props.animationDur}s ease-out slide forwards;
      @keyframes slide {
        0% {
          transform: translate(0px, 0px);
          opacity: 1;
        }
        95% {
          opacity: 1;
        }
        100% {
          transform: translate(80px, 40px);
          opacity: 0;
        }
      }

  }
  &.active.zoomin {
      animation: ${props => props.animationDur}s ease-out zoomin forwards;
      @keyframes zoomin {
        0% {
          transform: scale(1.1, 1.1);
          opacity: 1;
        }
        95% {
          opacity: 1;
        }
        100% {
          transform: scale(1, 1);
          opacity: 0;
        }
      }
  }
    &.active.zoomout {
      animation: ${props => props.animationDur}s ease-out zoomout forwards;
      @keyframes zoomout {
        0% {
          transform: scale(1, 1);
          opacity: 1;
        }
        95% {
          opacity: 1;
        }
        100% {
          transform: scale(1.1, 1.1);
          opacity: 0;
        }
      }

    }
  
`;

function AnimateSubject(props) {
  const { subject, animationState, animationClass, duration } = props;

  const [thisAnimState, setthisAnimState] = useState();

  const [fitbyH, setfitbyH] = useState(false);
  const [xys, setxys] = useState({ x: 0, y: 0 });

  const imgRef = useRef(null);

  useEffect(() => {
    if (thisAnimState === "change") {
      setTimeout(() => {
        setthisAnimState("standby");
      }, 900);
    }
  }, [thisAnimState]);

  useEffect(() => {
    if (animationState === "standby") {
      setthisAnimState("change");
    } else {
      setthisAnimState(animationState);
    }
    // if(animationState === "backup")setthisAnimState(animationState +" "+thisAnimState);
    // if (!(thisAnimState === "active")) setthisAnimState(animationState);
    // else {
    //   setthisAnimState(thisAnimState + " " + animationState);
    //   // setTimeout(() => {
    //   //   setthisAnimState(animationState);
    //   // }, 1000);
    // }

    if (animationState === "standby" && animationClass === "slide") {
      let tempx = (Math.random() < 0.5 ? -1 : 1) * Math.floor(Math.random() * (100 - 80) + 80);
      let tempy = (Math.random() < 0.5 ? -1 : 1) * Math.floor(Math.random() * (60 - 40) + 40);

      setxys({ x: tempx, y: tempy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationState]);

  const imgLoadedFunc = () => {
    let imgWindowW = imgRef.current.parentElement.offsetWidth;
    let imgWindowH = imgRef.current.parentElement.offsetHeight;
    let intrinsicH = imgWindowW / (imgRef.current.width / imgRef.current.height);
    let intrinsicW;
    let aspectratio = imgRef.current.width / imgRef.current.height;
    if (subject.fullDesign) {
      setfitbyH(true);
    } else {
      let isroomPortrait = aspectratio < 1.35;
      if (!isroomPortrait && intrinsicH > imgWindowH) {
        intrinsicW = imgWindowW;
        setfitbyH(false)
      } else {
        intrinsicH = imgWindowH;
        intrinsicW = imgWindowH / (imgRef.current.height / imgRef.current.width);
        let ar = intrinsicW / intrinsicH;
        if (ar >= 0.8 && ar <= 1.2) setfitbyH(false)
        else {
          setfitbyH(true);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <AnimatedImgWrapper
        className={`${thisAnimState} animateddiv`}
        animationDur={duration}
        src={subject.src}
      >
        {isblurbackground && <div className="blur"></div>}
        <AnimatedImg
          src={subject.src}
          alt={"designs "}
          ref={imgRef}
          onLoad={imgLoadedFunc}
          className={`${thisAnimState} ${animationClass} animatesubjectimg`}
          fitbyH={fitbyH}
          animationDur={duration}
          xS={xys.x}
          yS={xys.y}
        ></AnimatedImg>
      </AnimatedImgWrapper>
    </React.Fragment>
  );
}

export default React.memo(AnimateSubject);
