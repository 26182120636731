import React, { useState } from "react";
import AppProvider from "../../../api/appProvider";
import PropTypes from "prop-types";
import { Toaster, Toast } from "@blueprintjs/core";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";

const TearSheet = props => {
  const { type = "tertiary" } = props;
  const designDetailState = useDesignDetailState();
  const [toastProps, setToastProps] = useState(false);
  const handleTearSheet = () => {
    setToastProps(true);
    AppProvider.fetchCatalog({
      props: designDetailState.designDetails,
      file: designDetailState.fullpath,
      catalogName: window.flags.ordersheet.defaultTearsheetIndex
    }).then(file => {
      AppProvider.downloadCatalog({ file });
    });
  };

  return (
    <>
      <Toaster position="bottom">
        {toastProps && (
          <Toast
            message={strings.toastMessages.tearSheetDownload}
            intent="success"
            onDismiss={() => setToastProps(false)}
          />
        )}
      </Toaster>
      <div className="cyr-tearsheet-btn">
        <AtButton text={strings.ordersheet.tearSheet} onClick={handleTearSheet} intent={type} />
      </div>
    </>
  );
};

TearSheet.propTypes = {
  type: PropTypes.string
};
TearSheet.defaultProps = {
  type: "tertiary"
};

export default TearSheet;
