import classNames from "classnames";
import React, { useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { loadingTexts } from "../../../utils/stringUtils";
import BrandLogo from "../../molecules/BrandLogo";

const InitialLogoPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const page = sessionStorage.getItem("page");
  return (
      <FadeIn>
        <div className={`at-loading-overlay overlay loading initial-logopage`}>
          {page ? (
            <div className="at-loading-logo">
              <BrandLogo
                logourl={`https://users.explorug.com/images/${page}/logo.jpg`}
                onLoad={() => setShowLoader(true)}
                onError={() => setShowLoader(true)}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            className={classNames("bp3-progress-bar bp3-no-stripes at-initial-loading-bar", {
              hidden: !showLoader
            })}
          >
            <div className="bp3-progress-meter" />
          </div>
          <div
            className={classNames("bp3-text-large", {
              hidden: !showLoader
            })}
          >
            {loadingTexts[0]}
          </div>
        </div>
      </FadeIn>
  );
};

export default InitialLogoPage;
