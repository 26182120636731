import React, { useEffect } from "react";
import { useMount } from "react-use";
import ReactGA from "react-ga";
import AppProvider from "../../../api/appProvider";
import { useDebouncedCallback } from "use-debounce/lib";
import Tree from "../Tree";
import classNames from "classnames";
import strings from "../../../strings";
import {
  useUiDispatch,
  mainUiActions,
  pageViews,
  useUiState
} from "../../../reducers/mainui.reducer";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";

import { H6 } from "@blueprintjs/core";
import {
  arrangeTree,
  updateNodeProps,
  findInTree,
  getAllFiles,
  updateFilesInEveryNode,
  findSimilarDesigns,
  getDesignName
} from "../../../utils/treeutils";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions as actions,
  designListActions
} from "../../../reducers/designlist.reducer";
import DesignThumblist from "../DesignThumblist";
import { getFromSessionStorage } from "../../../utils/domUtils";
const thumbnailType = "portrait";
const DesignList = props => {
  const { handleThumbnailClick, hidden, primaryList = true, className = "" } = props;

  const { initDesignPath, initDesignFolder } = window.initialData;
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();
  const state = useDesignListState();
  const dispatch = useDispatchDesignList();

  const { selectedFile, selectedFolder } = state;

  const {
    expandSelectedFolderInit,
    hasFilterBySize,
    keepFoldersExpanded
  } = window.flags.designListTree;
  useMount(() => {
    if (state.tree || !primaryList) return;
    const initDesignList = async () => {
      const nestedDesignList = await AppProvider.fetchDesignList({ struct: true });
      const initDesignName = window.flags.initDesignNameInUrl ? getDesignName(initDesignPath) : "";
      const initDesignPathInTree = findInTree({
        rawTree: nestedDesignList,
        initDesignPath,
        initDesignFolder,
        initDesignName
      });

      //code to get rooms control list of specific folder in talisman. Need to use to update their roomscontrol.
      //do NOT remove.
      // const testtree=  searchFoldersInTree(nestedDesignList[0].Children, "sudhirkadam");
      // console.log("initDesignList ->", testtree);
      // let ctrlHtml = '[';
      // testtree[0].Children.forEach(element => {
      //   console.log("initDesignList -> element.type", element.Type)

      //   if(element.Type==='file'){
      //     const design = (element.FullPath.replace('Designs','').replace('.ctf',''));
      //     ctrlHtml+='{ "rooms": [],"design": "'+design+'"},'
      //   }
      //   else{
      //     if(element.Children.length){
      //       element.Children.forEach(variationFile => {
      //         if(variationFile.Type==='file'){
      //           const design = (variationFile.FullPath.replace('Designs','').replace('.ctf',''));
      //           ctrlHtml+='{ "rooms": [],"design": "'+design+'"},'
      //         }
      //       });
      //     }
      //   }
      // });
      // console.log("initDesignList -> ctrlHtml", ctrlHtml)

      //expand the collection panels if present
      //keepFoldersExpanded overwrites expandSekectedFolderInit
      if (initDesignPath && initDesignPath !== "" && !initDesignPathInTree) {
        const message = strings.toastMessages.initDesignNotFound.replace(
          "{initDesignPath}",
          initDesignPath
        );
        dispatchUiState({
          type: mainUiActions.SET_TOAST_PROPS,
          payload: { message, intent: "warning", timeout: 7000 }
        });
      }

      const { copiedNode: tree, selectedFile, selectedFolder } = arrangeTree({
        tree: nestedDesignList,
        initDesignPath: initDesignPathInTree,
        expandSelectedFolder: expandSelectedFolderInit,
        designfromFolder: initDesignFolder,
        keepFoldersExpanded
      });
      window.getDesignTree = () => {
        console.log("initDesignList -> tree", JSON.stringify(tree));
      };

      const { files } = selectedFolder;
      const items = await AppProvider.getDesignThumbnails({ designs: [selectedFile] });
      let updatedSelectedFile = selectedFile;
      const item = items[0];
      const index = files.findIndex(fileInNode => item.fullPath === fileInNode.fullPath);
      if (index !== -1) {
        files[index] = item;
        updatedSelectedFile = item;
      }
      const updatedSelectedFolder = { ...selectedFolder, files };
      let designTree;
      designTree = updateNodeProps(tree, updatedSelectedFolder, "files");
      if (hasFilterBySize) {
        const x = getAllFiles(designTree);
        const thumbs = await AppProvider.getDesignThumbnails({ designs: x });
        designTree = updateFilesInEveryNode(designTree, thumbs);
      }

      dispatch({
        type: actions.SET_TREE,
        payload: {
          tree: designTree,
          selectedFolder: updatedSelectedFolder,
          selectedFile: updatedSelectedFile
        }
      });

      var tagFromUrl = getFromSessionStorage("wordcloudtag") || "";
      if (tagFromUrl !== "") {
        tagFromUrl = tagFromUrl.replace(/_/g, "&");
        dispatch({
          type: actions.SET_TAG_FILTER,
          payload: tagFromUrl
        });
      }
    };
    initDesignList();
  });

  useEffect(() => {
    setTimeout(() => {
      if (state.selectedFile.name != undefined && window.flags.homeTemplate === pageViews.HOME) {
        const getQuery = document.querySelectorAll(`[title="${state.selectedFile.name}"]`)[0];
        if (getQuery) {
          const off = getQuery.offsetTop + getQuery.parentNode.offsetTop;
          document.getElementsByClassName("at-scroll-on-hover")[0].scrollTo(0, off - 100);
        }
      }
    }, [500]);
  }, []);

  const handleNodeExpand = node => {
    setTimeout(() => {
      if (window.flags.homeTemplate === pageViews.HOME) {
        const getQuery = document.querySelectorAll(`[datatitle="${node.name}"]`)[0];
        if (getQuery) {
          var off = getQuery.offsetTop + getQuery.parentNode.offsetTop;
          if (window.flags.designListTree.separateThumbnailColumn) {
            off = 0;
          }
          document.getElementsByClassName("at-scroll-on-hover")[0].scrollTo(0, off);
        }
      }
    }, [500]);
    dispatch({
      type: actions.EXPAND_NODE,
      payload: { node, collapseOthers: !keepFoldersExpanded }
    });
  };
  const handleNodeCollapse = node => {
    dispatch({
      type: actions.COLLAPSE_NODE,
      payload: node
    });
  };
  const [debouncedThumbnailClick] = useDebouncedCallback((node, e) => {
    if (!!handleThumbnailClick) handleThumbnailClick(node, e);
  }, 800);
  const onThumbnailClick = (node, e) => {
    if (!node.designProps) return;
    if (state.selectedFile && node.id === state.selectedFile.id) {
      console.info("clicked in active design thumbnail");
      return;
    }
    ReactGA.ga("send", "event", {
      eventCategory: "View", //required
      eventAction: "Clicked Design Thumbnail", //required
      eventLabel: `Clicked on ${node.fullPath}`
    });
    let requiredVariation = {};
    if (window.flags.designVariations.preserveVariations) {
      if (state.activeVariation.id) {
        const similarDesigns = findSimilarDesigns(state.tree, node);
        if (similarDesigns) {
          const va = state.activeVariation.name.replace(state.selectedFile.name, "");
          if (va)
            requiredVariation = similarDesigns.files.find(file => {
              if (file.name.includes(va)) return true;
              return false;
            });
        }
      }
    }

    if (uiState.showWordCloud)
      dispatchUiState({ type: mainUiActions.SET_SHOW_WORDCLOUD, payload: false });
    dispatch({
      type: actions.SELECT_DESIGN,
      payload: { selectedFile: node, activeVariation: requiredVariation }
    });
    debouncedThumbnailClick(node, e);
  };
  const handleExpandDesigns = () => {
    dispatchUiState({ type: mainUiActions.SET_PAGE_VIEW, payload: pageViews.FULL_PAGE_THUMB });
  };

  useEffect(() => {
    if (window.flags.showFullPageThumbOnBack) {
      window.history.pushState("forward", null, "#");
      window.onpopstate = e => {
        if (
          uiState.pageView === pageViews.HOME &&
          flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.showAllDesignsButton)
        ) {
          handleExpandDesigns();
          e.preventDefault();
          e.stopPropagation();
        }
      };
    }
  }, []);
  const showAllFolders = (initDesignFolder, selectedFolder) => {
    let showAll = true;
    if (initDesignFolder && selectedFolder && initDesignFolder !== 2) {
      //show current folder only if initDesignFolder = true
      showAll = false;
    }
    return showAll;
  };
  const handleInfoCircleClick = story => {
    if (story !== uiState.folderStoryDialogContent) {
      dispatchUiState({
        type: mainUiActions.SET_FOLDER_STORY_CONTENT,
        payload: story
      });
    }
    dispatchUiState({
      type: mainUiActions.SET_SHOW_FOLDER_STORY_DIALOG,
      payload: true
    });
  };
  return (
    <>
      {state.tree && (
        <div
          id="designslist"
          style={{ marginTop: "0.5rem" }}
          className={classNames("at-section-container", "at-fullheight-container", className, {
            hidden: hidden
          })}
        >
          {flaginCurrentMode(
            window.InterfaceElements.IsAdmin,
            window.flags.showAllDesignsButton
          ) && (
            <div
              id="show-all-designs"
              className="bp3-tree-node-content"
              onClick={handleExpandDesigns}
            >
              <H6>{strings.design.showAllDesigns}</H6>
            </div>
          )}
          <Tree
            selectedFile={state.selectedFile}
            treeContent={
              showAllFolders(initDesignFolder, selectedFolder) ? state.tree : [selectedFolder]
            }
            onNodeExpand={handleNodeExpand}
            onNodeCollapse={handleNodeCollapse}
            onNodeClick={handleNodeExpand}
            onThumbnailClick={onThumbnailClick}
            thumbnailType={thumbnailType}
            lazyDesignThumbnail
            renderThumbnails={!window.flags.designListTree.separateThumbnailColumn}
            handleInfoCircleClick={handleInfoCircleClick}
          />
          {window.flags.designListTree.separateThumbnailColumn && selectedFolder.files && (
            <DesignThumblist
              files={selectedFolder.files}
              selectedFile={selectedFile}
              onThumbnailClick={onThumbnailClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DesignList;
