import React from "react";
import AtButton from "../../atoms/AtButton";

const AccountLinks = props => {
  const { accountlinks = [] } = props;
  const handleClick = link => {
    if (link && link !== "") window.open(link, "_blank");
  };
  return (
    <div className="at-accountlinks">
      {accountlinks.length &&
        accountlinks.map((accountLink, index) => (
          <AtButton
            key={index}
            intent="primary"
            tertiary={true}
            onClick={() => handleClick(accountLink.link)}
          >
            {accountLink.text}
          </AtButton>
        ))}
    </div>
  );
};

AccountLinks.propTypes = {};

export default AccountLinks;
