import React from "react";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";

import AppProvider from "../../../api/appProvider";
import { MenuItem, Menu, Popover } from "@blueprintjs/core";

import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import { visViewModes, useVisualizationState } from "../../../reducers/visualizations.reducer";
const DownloadImageButton = props => {
  const designDetailState = useDesignDetailState();
  const { flags } = window;
  const visState = useVisualizationState();

  const {
    handleDownloadImage,
    buttonID = "at-des-details__title-saveImageIcon",
    separateDownloadIconPath = ""
  } = props;
  const { designDetails, fullpath } = designDetailState;

  const handleSaveFileLocally = () => {
    AppProvider.saveFileLocally({
      file: fullpath,
      props: designDetails
    });
  };

  return (
    <>
      {window.InterfaceElements.IsAdmin ? (
        <Popover
          minimal
          position="bottom-left"
          portalContainer={document.getElementById("app-main")}
          interactionKind="hover"
          className={`${buttonID}${"-popover"}`}
          content={
            <Menu>
              <MenuItem text={strings.tools.saveAsImage} onClick={handleDownloadImage} />
              <MenuItem text={strings.tools.downloadCTF} onClick={handleSaveFileLocally} />
            </Menu>
          }
        >
          {separateDownloadIconPath !== "" ? (
            <AtButton id={buttonID}>
              <img
                alt="stepwise-buttons-icon"
                src={window.flags.separateDownloadIconPath + "Icon_feather-download.png"}
              />
            </AtButton>
          ) : (
            <AtButton id={buttonID} minimal icon="save" />
          )}
        </Popover>
      ) : (
        flaginCurrentMode(window.InterfaceElements.IsAdmin, flags.showSaveImage) &&
        (separateDownloadIconPath !== "" ? (
          <AtButton
            id={buttonID}
            title={strings.tools.saveAsImage}
            minimal
            intent={null}
            onClick={handleDownloadImage}
          >
            <img
              alt="stepwise-buttons-icon"
              src={window.flags.separateDownloadIconPath + "Icon_feather-download.png"}
            />
          </AtButton>
        ) : (
          <AtButton
            id={buttonID}
            title={strings.tools.saveAsImage}
            minimal
            intent={null}
            icon="save"
            onClick={handleDownloadImage}
            className={
              visState.viewMode == visViewModes.ROOM_VIEW ? "roomViewSave" : "designViewSave"
            }
          />
        ))
      )}
    </>
  );
};

export default DownloadImageButton;
