import React, { useState, useEffect } from "react";
import AtDropdown from "../../atoms/AtDropdown";
import { InputGroup } from "@blueprintjs/core";
import { replaceAll } from "../../../utils/utils";
import AtCheckbox from "../../atoms/AtCheckbox";
import { getDefaultFieldValue } from "../../../middleware/ordersheet.middleware";

const AddedCustomFields = props => {
  const { formFields, setFormFields } = props;
  const addedFields = window.flags.ordersheet.addedCustomFields;

  const handlefieldChange = ({ ordersheetFieldId, ordersheetFieldValue }) => {
    const fields = formFields.fields.map(element => {
      if (element.fieldName === ordersheetFieldId) {
        element.fieldValue = ordersheetFieldValue;
      }
      return element;
    });
    setFormFields({ ...formFields, fields });
  };
  return (
    <>
      {formFields &&
        addedFields.map((element, index) => (
          <FormFields
            key={index}
            type={element.type}
            defaultFieldVal={getDefaultFieldValue(element, formFields)}
            handlefieldChange={handlefieldChange}
            element={element}
          />
        ))}
    </>
  );
};

export default AddedCustomFields;

const FormFieldCheckbox = props => {
  const { defaultFieldVal, handlefieldChange, element } = props;
  const [fieldValue, setFieldValue] = useState(defaultFieldVal);
  const handleInputChange = (e, element) => {
    setFieldValue(e.target.checked);
    handlefieldChange({
      ordersheetFieldId: element.ordersheetFieldId,
      ordersheetFieldValue: e.target.checked ? element.options[1] : element.options[0] //
    });
  };

  return (
    <div
      className="bp3-form-group at-addedField"
      id={`at_addedField_${replaceAll(element.label, " ", "_")}`}
    >
      <AtCheckbox
        className="at_addedField_checkbox"
        inputClassName="at-addedField__input"
        checked={fieldValue && fieldValue !== "" ? true : false}
        onChange={e => {
          handleInputChange(e, element);
        }}
        labelClassName="at-addedField__label"
        label={element.label}
      />
    </div>
  );
};
const FormFieldDate = props => {
  const { defaultFieldVal, handlefieldChange, element } = props;
  const [fieldValue, setFieldValue] = useState(defaultFieldVal);

  function formatDate(inputDate) {
    // Parse the input date as a JavaScript Date object
    var dateObject = new Date(inputDate);

    // Extract year, month, and day
    var year = dateObject.getFullYear();
    var month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    var day = String(dateObject.getDate()).padStart(2, "0");

    // Format the date as mm-dd-yyyy
    var formattedDate = month + "/" + day + "/" + year;

    return formattedDate;
  }

  const handleInputChange = (e, element) => {
    var val = e.target.value;
    if (element?.formatMDY) {
      val = formatDate(val);
    }
    setFieldValue(val);
    handlefieldChange({
      ordersheetFieldId: element.ordersheetFieldId,
      ordersheetFieldValue: val
    });
  };

  return (
    <div className="bp3-form-group" id={`at_addedField_${replaceAll(element.label, " ", "_")}`}>
      <label className="" htmlFor={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}>
        {element.label}
      </label>
      <div>
        <input
          type="date"
          id="at-formdate"
          name="at-date"
          onChange={e => handleInputChange(e, element)}
        />
      </div>
    </div>
  );
};

const FormFieldTextInput = props => {
  const { defaultFieldVal, handlefieldChange, element } = props;
  const [fieldValue, setFieldValue] = useState(defaultFieldVal);
  const handleInputChange = (e, element) => {
    setFieldValue(e.target.value);
    handlefieldChange({
      ordersheetFieldId: element.ordersheetFieldId,
      ordersheetFieldValue: e.target.value
    });
  };

  return (
    <div
      className="bp3-form-group at-addedField"
      id={`at_addedField_${replaceAll(element.label, " ", "_")}`}
    >
      <input
        id={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}
        className="at-addedField__input"
        type="text"
        value={fieldValue}
        onChange={e => handleInputChange(e, element)}
      ></input>
      <label
        className="at-addedField__label"
        htmlFor={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}
      >
        {element.label}
      </label>
    </div>
  );
};

const FormFieldSelectInput = props => {
  const { defaultFieldVal, handlefieldChange, element } = props;
  const defValue = defaultFieldVal || "";

  const selectedOption = element.options.findIndex(elem => defValue.indexOf(elem) >= 0) || 0; //get selected option
  let fieldValArr = defValue.split("; "); //<select> value and text value separated by '; '
  fieldValArr.shift(); //removing 1st <select> value
  const defaultInputTextvalue = fieldValArr.join("; "); //input text value

  const [fieldValue, setFieldValue] = useState(defValue);
  const [inputTextValue, setInputTextValue] = useState(defaultInputTextvalue);
  const [selectInputValue, setSelectInputValue] = useState(element.options[selectedOption]);

  const handleInputChange = e => {
    setInputTextValue(e.target.value);

    setFieldValue(selectInputValue + "; " + e.target.value);
  };

  const handleSelectOptionChange = value => {
    value = inputTextValue !== "" && value !== "" ? value + "; " : value;
    setSelectInputValue(value);
    setFieldValue(value + inputTextValue);
  };
  useEffect(() => {
    handlefieldChange({
      ordersheetFieldId: element.ordersheetFieldId,
      ordersheetFieldValue: fieldValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);
  return (
    <div
      className="bp3-form-group at-addedFieldSelect"
      id={`at_addedField_${replaceAll(element.label, " ", "_")}`}
    >
      <label
        className="bp3-label at-addedFieldSelect__label"
        htmlFor={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}
      >
        {element.label}
      </label>
      <div className="at-addedFieldSelect__dropdown">
        <AtDropdown
          dropdownItems={element.options}
          defaultItemIndex={selectedOption}
          onChange={i => handleSelectOptionChange(element.options[i])}
          addPlaceholder={element.addPlaceholder}
          placeholderText={element.placeholderText}
        />
      </div>
      <div className="bp3-form-content">
        <InputGroup
          id={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}
          className="at-addedFieldSelect__input"
          value={inputTextValue}
          onChange={e => handleInputChange(e)}
        />
      </div>
    </div>
  );
};

const FormFieldSelect = props => {
  const { defaultFieldVal, handlefieldChange, element } = props;
  const defValue = defaultFieldVal || "";

  const selectedOption =
    element.options.findIndex(elem => {
      return defValue.indexOf(elem) >= 0;
    }) || 0; //get selected option

  const handleSelectOptionChange = value => {
    handlefieldChange({
      ordersheetFieldId: element.ordersheetFieldId,
      ordersheetFieldValue: value
    });
  };
  return (
    <div
      className="bp3-form-group at-addedFieldSelect"
      id={`at_addedField_${replaceAll(element.label, " ", "_")}`}
    >
      <label
        className="bp3-label at-addedFieldSelect__label"
        htmlFor={`at_addedFieldInput_${replaceAll(element.label, " ", "_")}`}
      >
        {element.label}
      </label>
      <div className="at-addedFieldSelect__dropdown">
        <AtDropdown
          dropdownItems={element.options}
          defaultItemIndex={selectedOption}
          onChange={i => handleSelectOptionChange(element.options[i])}
          addPlaceholder={element.addPlaceholder}
          placeholderText={element.placeholderText}
        />
      </div>
    </div>
  );
};

const FormFields = props => {
  const { type } = props;
  switch (type) {
    case "checkbox":
      return <FormFieldCheckbox {...props} />;
    case "text":
      return <FormFieldTextInput {...props} />;
    case "select with input":
      return <FormFieldSelectInput {...props} />;
    case "select":
      return <FormFieldSelect {...props} />;
    case "date":
      return <FormFieldDate {...props} />;

    default:
      return <></>;
  }
};
