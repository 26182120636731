import React, { useEffect, useRef, useState } from "react";
import { InputGroup, FormGroup, Card, H6, H4 } from "@blueprintjs/core";
import AppProvider, { CDN_domain } from "../../../api/appProvider";
import AtButton from "../../atoms/AtButton";
import ReactDelayRender from "react-delay-render";
import Fade from "../../higher-order-components/Fade";
import { AtSpinner } from "../../atoms/AtSpinner";
import { useMount } from "react-use";

import classNames from "classnames";
import { getCustomClass } from "../../../utils/domUtils";
import Axios from "axios";
// const filemanagerDomains = [
//   "https://v3.explorug.com",
//   "https://v3rc/explorug.com",
//   "https://v3dev.explorug.com",
//   "http://localhost:3000"
// ];
const LoginPage = props => {
  const [isMobile, setIsMobile] = useState(false);
  const [showRugPopup, setShowRugPopup] = useState(false);
  const popupRef = useRef();
  const images = [
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Micrograph in Loft Xenonim S4.jpg",
      title: "Loft Xenonim"
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Apostlute in Bedroom Nanoscape S2D.jpg",
      title: "Bedroom Nanoscape"
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Atlantio in Kitchen Dining Pharong.jpg",
      title: "Kitchen Dining Pharong"
    },
    {
      link: "https://emotion.explorug.com/MyRugStudioAssets/Indephon in Living Room Mapolis S2.jpg",
      title: "Living Room Mapolis"
    },
    {
      link:
        "https://emotion.explorug.com/MyRugStudioAssets/Solology in Living Room Vianalon S1V2.jpg",
      title: "Living Room Vianalon"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState();

  useEffect(() => {
    setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowRugPopup(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSigningIn, setisSigningIn] = useState(false);

  const loginPage = sessionStorage.getItem("login") || "";
  const [logoSrc, setLogoSrc] = useState("https://explorug.net/new/explorug-logo.png");

  const [isLoading, setIsLoading] = useState(true);
  useMount(() => {
    if (loginPage === "") {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const image = new Image();
    image.src = CDN_domain + "v3assets/loginPageLogos/" + loginPage + ".jpg";
    image.onload = () => {
      setLogoSrc(image.src);
      setIsLoading(false);
    };
    image.onerror = () => {
      console.log("logo not found");
      setIsLoading(false);
    };
  });

  function handleSubmit(event) {
    event.preventDefault();
    setisSigningIn(true);
    AppProvider.fetchApiKey({ username, password })
      .then(key => {
        if (!key) {
          setIsError(true);
          setisSigningIn(false);
        } else {
          // if (inIframe()) {
          //   for (let domain of filemanagerDomains) {
          //     window.top.postMessage({ apiKey: key }, domain);
          //   }
          //   return;
          // }
          //replace forcelogin param if present and reload
          // window.history.replaceState &&
          //   window.history.replaceState(
          //     null,
          //     "",
          //     window.location.pathname +
          //       window.location.search.replace(/[?&]forcelogin+/, "").replace(/^&/, "?")
          //   );
          window.location.href = window.location.href
            .replace(/[?&]forcelogin+/, "")
            .replace(/^&/, "?");
          // window.location.reload();
        }
      })
      .catch(err => {
        setIsError(true);
        setisSigningIn(false);
      });
  }

  const getSpecificLoginPageClass = loginPage => {
    let loginPageClass = "";
    if (loginPage && loginPage !== "") {
      loginPageClass = `separate-loginpage ${loginPage.replace(/ /g, "")}-loginpage`;
    }
    return loginPageClass;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3500);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [images.length]);

  const handleSubmitTryNow = () => {
    const url = "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
    var formdata = new FormData();
    formdata.append("product", "explorug");
    formdata.append("event", "promotionLinkClicked,RugStudioLoginPage");
    Axios.post(url, formdata).then(success => {});
    window.open("https://myrug.studio/", "__blank");
  };

  return (
    <div className={classNames("app-main", getCustomClass())}>
      {showRugPopup && ( //this is for modal content to be shown in loginPage if required.
        <div className="promotionalModal">
          <div className={classNames("promotionalContainer", { isMobile })} ref={popupRef}>
            <span className="icon-close" onClick={() => setShowRugPopup(false)}>
              &times;
            </span>

            <div className={classNames("logo", { isMobile })}>
              <img
                src="https://emotion.explorug.com/MyRugStudioAssets/svg/utils/logo.svg"
                alt="myrugstudio"
              ></img>
            </div>
            <div className={classNames("about", { isMobile })}>
              <span>Discover our diverse 3D environments</span>
              <span>and find the perfect room for your rug.</span>
            </div>
            <div className={"carousel"}>
              <ul className="list">
                {images.map((image, index) => {
                  let className = "hide";
                  if (index === currentIndex) {
                    className = "act";
                  } else if (index === (currentIndex - 2 + image.length) % image.length) {
                    className = "prev old-prev";
                  } else if (index === (currentIndex - 1 + images.length) % images.length) {
                    className = "prev";
                  } else if (index === (currentIndex + 1) % images.length) {
                    className = "next";
                  } else if (index === (currentIndex + 2) % images.length) {
                    className = "next new-next";
                  }

                  return (
                    <li key={index} className={classNames(className, { isMobile })}>
                      <img src={image.link} alt={`Slide ${index + 1}`} />
                    </li>
                  );
                })}
              </ul>

              <div className={classNames("controller-button", { isMobile })}>
                <button
                  onClick={() => {
                    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
                    clearInterval(intervalId);
                  }}
                >
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 18L9 12L15 6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
                    clearInterval(intervalId);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18L15 12L9 6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className={classNames("description", { isMobile })}>
                {images[currentIndex].title}
              </div>
            </div>

            <div className={classNames("upgrade", { isMobile })}>
              Upgrade your exploRUG with more rooms
            </div>
            <div className={classNames("contact", { isMobile })}>
              <button onClick={handleSubmitTryNow}>Try Now</button>

              <div>
                For any details, contact us at{" "}
                <u onClick={() => (window.location = "mailto:info@explorug.net")}>
                  info@explorug.net
                </u>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classNames("at-login-screen", getSpecificLoginPageClass(loginPage))}>
        <div className="at-promotionals">
          <Card>
            <H4>A refreshed experience</H4>
            <p>
              exploRUG now provides a better experience for your clients.
              <br />
              Besides the look and the features, this update also has significant performance
              improvements.
            </p>
            <AtButton
              text="Here's a walkthrough"
              intent="primary"
              tertiary
              onClick={() =>
                window.open(
                  "https://alternativetechnology.zendesk.com/hc/en-us/articles/900001316283-exploRUG-v3-Walkthrough"
                )
              }
            />
          </Card>
        </div>
        <div className="login-field">
          <div>
            <img
              className={classNames({ hidden: isLoading })}
              id="login-page-logo"
              src={logoSrc}
              alt="explorug logo"
              width="200"
            ></img>
            <H4 className="" style={{ margin: "1rem 0 1.5rem 0" }}>
              Sign in to your explorug
            </H4>
            <form onSubmit={handleSubmit} style={{ width: "20rem" }}>
              <FormGroup label="Username">
                <InputGroup
                  className="at-login-input"
                  type="text"
                  onChange={e => setUserName(e.target.value)}
                  autoFocus
                  onFocus={e => e.target.select()}
                />
              </FormGroup>
              <FormGroup label="Password">
                <InputGroup
                  className="at-login-input"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  onFocus={e => e.target.select()}
                />
              </FormGroup>
              <Fade show={isError}>
                <div className={` bp3-callout bp3-intent-danger`} style={{ marginBottom: "1rem" }}>
                  Please recheck your username and password
                </div>
              </Fade>
              <AtButton intent="primary" type="submit">
                <AtSpinner
                  size="xs"
                  className={(isSigningIn ? "show " : "") + "signing-spinner "}
                />
                <H6 className={(isSigningIn ? "shift-right " : "") + "signin-span-text "}>
                  {isSigningIn ? "Signing in" : "Sign in"}
                </H6>
              </AtButton>
            </form>
          </div>
          <div className="privacy-policy" id="div-privacy">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://explorug.net/PrivacyPolicy.php"
            >
              Privacy Policy
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://explorug.net/termsofuse.php">
              Terms of Use
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReactDelayRender({ delay: 0 })(LoginPage);
