/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from "react";
import RoomViewHelper, { defaultRoomType } from "./roomviewhelper";
import { useMount } from "react-use";
import InputCanvas from "../../atoms/InputCanvas";
import { Popover } from "@blueprintjs/core";

import AppProvider from "../../../api/appProvider";
import Fade from "../../higher-order-components/Fade";

import CircularButton from "./../../atoms/CircularButton";
import DayNightSlider from "./../../molecules/DayNightSlider";
import { AtSpinnerOverlay } from "./../../atoms/AtSpinner";
import ColorSelectionBox from "./../../organisms/ColorSelectionBox";
import { resizeKeepingAspect } from "../../../utils/utils";

import { useWindowSize } from "./../../../hooks";

import {
  useRoomConfigState,
  useDispatchRoomConfig,
  roomConfigReducerHandlers
} from "./roomconfig.reducer";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { createCanvas } from "../../../utils/canvasutils";
import {
  roomViewActions,
  useDispatchRoomView,
  useRoomViewState
} from "../../../reducers/roomview.reducer";
import { AreaSwatch } from "../../molecules/AreaSwatch";
import AtIcon from "../../atoms/AtIcon";
import { colorListActions, useDispatchColorList } from "../../../reducers/colors.reducer";

let roomViewHelper = new RoomViewHelper();

let designRendered = false;
let designRendering = false;
function usePrevious(value) {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
const showOptions = false;

const getDominantColor = designDetails => {
  if (!designDetails || !designDetails.DesignColors) return null;
  const smartKnot = designDetails.DesignColors.map(c => c.Knots).sort((a, b) => b - a);
  const smartIndex = smartKnot.length > 2 ? (smartKnot.length === 3 ? 1 : 2) : smartKnot.length - 1;
  const domC = designDetails.DesignColors.find(item => item.Knots === smartKnot[smartIndex]);
  if (domC) return domC.Color;
  else return null;
};

const IllustrationView = props => {
  const {
    activeColor,
    handleActiveClick,
    roomData,
    handleAvailableSizesChange,
    selectedSize,
    onRenderingComplete
  } = props;
  const { Name: roomName, Files: files, config, baseUrl } = roomData;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const [inputoncarpet, setinputoncarpet] = useState(false);
  const [shotReady, setshotReady] = useState(false);
  const [transition, settransition] = useState(-1);
  const [dimensions, setdimensions] = useState({});
  const [showWand, setshowWand] = useState(false);
  const [inputmoved, setinputmoved] = useState(false);
  const [renderfromsavedstate, setrenderfromsavedstate] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState(null);
  const [appliedActiveColor, setappliedActiveColor] = useState(null);

  const roomconfigstate = useRoomConfigState();
  const dispatchRoomConfig = useDispatchRoomConfig();

  const dispatchRoomView = useDispatchRoomView();
  const roomviewState = useRoomViewState();
  const dispatchColorList = useDispatchColorList();

  const { carpetOptions } = roomviewState;
  const { rotation: carpetRotation, position: carpetPosition } = carpetOptions;

  const containerRef = useRef(null);
  const bgCanvasRef = useRef(null);
  const threeCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const shadowCanvasRef = useRef(null);
  const transitionCanvasRef = useRef(null);
  const inputCanvasRef = useRef(null);
  const objCanvasContainerRef = useRef(null);
  const inputCanvasContainerRef = useRef(null);
  const {
    designDetails,
    fullpath: designPath,
    hash,
    updateDesignTiles,
    updateNormapTiles
  } = designDetailState;

  const prevDesignDetails = usePrevious(designDetails);
  const prevRoomDetails = usePrevious(roomData);
  const prevDesignPath = usePrevious(designPath);

  const windowsize = useWindowSize();

  useMount(() => {
    const canvasConfig = {
      bgCanvas: bgCanvasRef.current,
      threeCanvas: threeCanvasRef.current,
      maskCanvas: maskCanvasRef.current,
      shadowCanvas: shadowCanvasRef.current,
      transitionCanvas: transitionCanvasRef.current,
      container: containerRef.current,
      inputCanvas: inputCanvasRef.current,
      objCanvasContainer: objCanvasContainerRef.current
    };
    roomViewHelper.initCanvas(canvasConfig);
  });

  useEffect(() => {
    sizeContainers({});
  }, [windowsize]);
  useEffect(() => {
    if (!selectedSize) return;
    roomViewHelper.change3dObjectScale(selectedSize);
  }, [selectedSize]);
  useEffect(() => {
    if (shotReady) {
      settransition(0);
    }
    if (shotReady && onRenderingComplete) onRenderingComplete();
  }, [shotReady]);

  const carpetRotFunc = async () => {
    await roomViewHelper.updateCarpetRotation(carpetRotation);
    await roomViewHelper.updateShadow();
  };
  useEffect(() => {
    if (!shotReady) return;
    if (!activeColor) return;
    roomViewHelper.updateBackground({ dominantColorHex: activeColor.Color });
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
    setappliedActiveColor(activeColor.Color);
  }, [activeColor]);

  useEffect(() => {
    if (!shotReady || designDetailState.loading || carpetRotation.length !== 3) return;
    carpetRotFunc();
  }, [carpetRotation]);

  const carpetPosFunc = async () => {
    await roomViewHelper.updateCarpetPosition(carpetPosition);
    await roomViewHelper.updateShadow();
  };

  useEffect(() => {
    if (!shotReady || designDetailState.loading || carpetPosition.length !== 3) return;
    carpetPosFunc();
  }, [carpetPosition]);

  useEffect(() => {
    console.count("Load Room view");
    let la = true;
    const loadRoom = async () => {
      try {
        let temproomconfigstate;
        if (prevRoomDetails !== roomData) {
          if (shotReady) await makeTransitionCanvas();
          else settransition(1);
          roomViewHelper.clearAll();
          dispatchRoomConfig({
            type: "RESET"
          });
          temproomconfigstate = roomConfigReducerHandlers.setRoomElements({
            state: { ...roomconfigstate },
            payload: {
              name: config.name,
              dims: config.dims,
              activeshot: roomData.shot ? roomData.shot : null,
              roomType: config.roomType,
              hasShadow: config.hasShadow,
              mouseControls: config.mouseControls,
              realTimeDynamicRendering: config.realTimeDynamicRendering,
              roomElements: config.roomElements
            }
          });
          dispatchRoomConfig({
            type: "SET_ROOM_ELEMENTS",
            precalc: { ...temproomconfigstate }
          });
          roomViewHelper.initConfig({ baseUrl, config, files });
          sizeContainers({ illustrationDims: config.dims });

          const renderScene = await initShot({
            shot: temproomconfigstate.activeshot,
            light: temproomconfigstate.activelight,
            view: temproomconfigstate.activeview,
            realTimeDynamicRendering: temproomconfigstate.realTimeDynamicRendering
          });
          if (!la || !renderScene) return;
          for (const objectname in temproomconfigstate.roomelements.objects) {
            const object = temproomconfigstate.roomelements.objects[objectname];
            if (object.passive && object.colorCode) {
              roomViewHelper.selectColorCode(object.colorCode);
            }
          }
          const dominantColorHex = getDominantColor(designDetails);
          roomViewHelper.updateBackground({ dominantColorHex });
          roomViewHelper.updateMask();
          setappliedActiveColor(dominantColorHex);

          if (prevDesignDetails === designDetails) {
            const availSizes = await roomViewHelper.updatethreeCanvas(
              temproomconfigstate.activeshot
            );
            if (!la) return;
            if (prevRoomDetails.config.designinroomformat !== roomData.config.designinroomformat) {
              designRendered = false;
              designRendering = false;
            }
            if (!designRendering) {
              designRendering = true;

              await roomViewHelper.renderDesign({ designDetails, designPath, hash });
              designRendered = true;
              if (availSizes && handleAvailableSizesChange) {
                handleAvailableSizesChange(availSizes);
              }
            }
          }
          roomViewHelper.updateShadow();
          updateAllObjectsInScene({ ...temproomconfigstate.objectstate });
          if (la && designRendered) setshotReady(true);
        }
        if (prevDesignDetails !== designDetails) {
          setshotReady(false);
          designRendered = false;
          designRendering = false;
          if (prevDesignPath !== designPath) {
            const dominantColorHex = getDominantColor(designDetails);
            roomViewHelper.updateBackground({ dominantColorHex });
            roomViewHelper.updateMask();
            setappliedActiveColor(dominantColorHex);

            const tileData = await AppProvider.fetchTileDetails({
              file: designPath
            });
            if (!la) return;
            dispatchDesignDetails({
              type: designDetailActions.SET_TILE_DETAILS,
              payload: tileData
            });
            roomViewHelper.setTileDetails(tileData);
            const availSizes = await roomViewHelper.updatethreeCanvas(
              temproomconfigstate ? temproomconfigstate.activeshot : roomconfigstate.activeshot
            );
            if (!la) return;
            designRendering = true;

            await roomViewHelper.renderDesign({ designDetails, designPath, hash });
            if (availSizes && handleAvailableSizesChange) {
              handleAvailableSizesChange(availSizes);
            }
            await roomViewHelper.updateShadow();
            designRendered = true;
            if (la && designRendered) setshotReady(true);
            if (!la) return;
          } else {
            await roomViewHelper.updateTiles({
              designDetails,
              updateDesignTiles,
              updateNormapTiles,
              hash
            });
            await roomViewHelper.updateShadow();
            designRendered = true;
          }
        }
        if (la && designRendered) setshotReady(true);
      } catch (error) {
        console.error(error);
        return;
      }
    };
    if (roomData && designDetails) loadRoom();
  }, [roomData, designDetails]);

  const sizeContainers = ({ illustrationDims }) => {
    const { width, height } = containerRef.current.getBoundingClientRect();
    roomViewHelper.resize({ width: width, height: height });
    const dimensionPixels = resizeKeepingAspect(
      illustrationDims ? illustrationDims : roomconfigstate.dims,
      { width: width, height: height },
      "fit_inside"
    );
    setdimensions({ width: `${dimensionPixels.width}px`, height: `${dimensionPixels.height}px` });
  };

  const initShot = async ({ shot, light, view, realTimeDynamicRendering }) => {
    const isinitialized = roomViewHelper.preinitShot({
      shot: shot,
      light: light,
      view: view
    });
    if (realTimeDynamicRendering === undefined || realTimeDynamicRendering === null)
      realTimeDynamicRendering = roomconfigstate.realTimeDynamicRendering;
    if (!realTimeDynamicRendering) {
      const isrenderedfromsavedstate = await roomViewHelper.renderSavedRoomState();
      if (isrenderedfromsavedstate) {
        setrenderfromsavedstate(true);
        setshotReady(true);
        return Promise.resolve(false);
      }
    }
    setrenderfromsavedstate(false);
    if (!isinitialized) {
      setshotReady(false);
      await Promise.all(roomViewHelper.initShot());
    }
    return Promise.resolve(true);
  };

  const changeShot = async newshot => {
    try {
      await makeTransitionCanvas();

      const temproomconfigstate = roomConfigReducerHandlers.changeShot({
        state: { ...roomconfigstate },
        payload: { activeshot: newshot }
      });
      dispatchRoomConfig({
        type: "CHANGE_SHOT",
        precalc: { ...temproomconfigstate }
      });
      await initShot({ shot: temproomconfigstate.activeshot });
      roomViewHelper.updateBackground();
      roomViewHelper.updateMask();

      await roomViewHelper.updateCameraShot(temproomconfigstate.activeshot);
      roomViewHelper.updateShadow();
      roomViewHelper.updateGizmo();
      updateAllObjectsInScene({ ...temproomconfigstate.objectstate });

      setshotReady(true);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const changeLight = async newlightname => {
    await makeTransitionCanvas();

    const temproomconfigstate = roomConfigReducerHandlers.changeLight({
      state: { ...roomconfigstate },
      payload: { activelight: newlightname }
    });
    dispatchRoomConfig({
      type: "CHANGE_LIGHT",
      precalc: { ...temproomconfigstate }
    });
    await initShot({ light: newlightname, view: temproomconfigstate.activeview });
    settransition(1);
    setTimeout(() => {
      settransition(0);
    }, 1000);
    roomViewHelper.updateBackground();
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
    updateAllObjectsInScene({ ...temproomconfigstate.objectstate });

    setshotReady(true);
  };

  const changeView = async temproomconfigstate => {
    await makeTransitionCanvas();

    await initShot({ view: temproomconfigstate.activeview });
    setTimeout(() => {
      settransition(0);
    }, 1000);
    roomViewHelper.updateBackground();
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
    updateAllObjectsInScene({ ...temproomconfigstate.objectstate });
    setshotReady(true);
  };

  const makeTransitionCanvas = async (roomview = roomViewHelper) => {
    let sceneobjectstate = Object.fromEntries(
      Object.entries(roomconfigstate.objectstate).filter(object => {
        return (
          object[1].isobjectinscene &&
          (object[1].mystic ? (object[1].mystic === "show" ? true : false) : true)
        );
      })
    );
    const sortedobjectsarr = Object.entries(sceneobjectstate)
      .sort((a, b) => a[1].zindex - b[1].zindex)
      .map(el => {
        return { [el[0]]: el[1] };
      });
    const sortedobjects = sortedobjectsarr.reduce((obj, item) => {
      obj[Object.keys(item)[0]] = Object.values(item)[0];
      return obj;
    }, {});
    await roomview.makeTransitionCanvas({ objects: sortedobjects });
    if (roomview === roomViewHelper) settransition(1);
  };

  const updateAllObjectsInScene = objectstate => {
    let sceneobjectstate = Object.fromEntries(
      Object.entries(objectstate).filter(object => object[1].isobjectinscene)
    );
    roomViewHelper.updateObjects({ ...sceneobjectstate });
  };

  const mouseObjectTracker = e => {
    if (
      !inputoncarpet &&
      roomconfigstate.roomelements.objects &&
      Object.keys(roomconfigstate.roomelements.objects).length > 0
    ) {
      const { left, top, width, height } = document
        .getElementById("inputcontainer")
        .getBoundingClientRect();
      const { x, y } = { x: e.clientX - left, y: e.clientY - top };
      const resolutionx = roomViewHelper.dimension.width / width;
      const resolutiony = roomViewHelper.dimension.height / height;
      let ishovering = false;
      try {
        Object.keys(roomconfigstate.objectstate).forEach((objectname, index) => {
          let objectindex = roomconfigstate.objectstate[objectname].order - 1;
          let c = objCanvasContainerRef.current.children[objectindex].getContext("2d");
          let p = c.getImageData(x * resolutionx, y * resolutiony, 20, 20).data;
          const alphaArr = p.filter((p1each, index) => (index + 1) % 4 === 0);
          const isObjectCheck = element => element > 250;
          const isobject = alphaArr.some(isObjectCheck);
          if (isobject) {
            document
              .getElementById("objcontainer")
              .children[objectindex].classList.add("objcontainer__objcanvas--trans");
            ishovering = true;
            setshowWand(true);
          } else {
            document
              .getElementById("objcontainer")
              .children[objectindex].classList.remove("objcontainer__objcanvas--trans");
          }
        });
        if (!ishovering) setshowWand(false);
        // else setshowWand(false);
      } catch (err) {}
    }
  };

  const mouseObjectClick = e => {
    if (!inputmoved) {
      if (
        roomconfigstate.roomelements.objects &&
        Object.keys(roomconfigstate.roomelements.objects).length > 0
      ) {
        // const { left, top, width, height } = document
        //   .getElementById("inputcontainer")
        //   .getBoundingClientRect();
        // const { x, y } = { x: e.clientX - left, y: e.clientY - top };
        // const resolution = roomViewHelper.dimension.width / width;
        // const resolutionx = roomViewHelper.dimension.width / width;
        // const resolutiony = roomViewHelper.dimension.height / height;

        try {
          Object.keys(roomconfigstate.objectstate).forEach((objectname, index) => {
            let objectindex = roomconfigstate.objectstate[objectname].order - 1;
            const isobject =
              (
                " " +
                document.getElementById("objcontainer").children[objectindex].className +
                " "
              ).indexOf("objcontainer__objcanvas--trans") > -1;
            if (isobject) {
              const temproomconfigstate = roomConfigReducerHandlers.toggleMystic({
                state: { ...roomconfigstate },
                payload: { object: objectname }
              });
              dispatchRoomConfig({
                type: "TOGGLE_MYSTIC",
                precalc: { ...temproomconfigstate }
              });
              if (temproomconfigstate.activeview !== roomconfigstate.activeview)
                changeView(temproomconfigstate);
            }
          });
        } catch (err) {}
      }
    }
    setinputmoved(false);
  };

  const handleInputStart = e => {
    let inputoncarpettmp = roomViewHelper.mouseDownTouchStart(e);
    setinputoncarpet(inputoncarpettmp);
    if (inputoncarpettmp) {
      roomViewHelper.updateShadow({ clear: true });
    }
    setMouseDownPosition(e);
  };

  const handleinputMove = e => {
    setinputmoved(true);
    if (inputoncarpet) {
      roomViewHelper.mouseDownTouchMove(e);
      setshowWand(false);
      removeTransState();
    }
  };

  const handleInputEnd = e => {
    if (inputoncarpet) {
      setinputoncarpet(false);
    }
    const opt = roomViewHelper.mouseDownTouchEnd(e);
    const { showColorSelectionBox, texCoordinates, rotation, position } = opt;
    let option = carpetOptions;
    if (rotation) option = { ...option, rotation };
    if (position) option = { ...option, position };
    if (position || rotation)
      dispatchRoomView({
        type: roomViewActions.SET_CARPET_OPTION,
        payload: option
      });
    if (
      texCoordinates &&
      mouseDownPosition &&
      e.x === mouseDownPosition.x &&
      e.y === mouseDownPosition.y
    ) {
      dispatchDesignDetails({
        type: designDetailActions.SET_CUSTOSCOUT_COORDS,
        payload: {
          containerPoint: e,
          designPoint: texCoordinates
        }
      });
    } else if (mouseDownPosition) toggleScout(false);

    setMouseDownPosition(null);
    if (!showColorSelectionBox) return;
    else handleActiveClick && handleActiveClick();
  };

  useEffect(() => {
    const { designPoint } = designDetailState.custoScout;
    if (!designPoint) return;
    AppProvider.getColorAt({
      file: designDetailState.fullpath,
      x: designPoint.x,
      y: designPoint.y,
      Width: designDetailState.designDetails.Width,
      Height: designDetailState.designDetails.Height
    }).then(color => {
      if (color === 255) return;
      dispatchDesignDetails({ type: designDetailActions.SELECT_DESIGN_COLOR, payload: color });
      toggleScout(true);
    });
  }, [designDetailState.custoScout.designPoint]);

  const toggleScout = value => {
    dispatchDesignDetails({ type: designDetailActions.TOOGLE_CUSTOSCOUT, payload: value });
  };
  const handleColorSwatchClick = (color, objectname) => {
    const temproomconfigstate = roomConfigReducerHandlers.setActiveColor({
      state: { ...roomconfigstate },
      payload: { object: objectname, color: color.Color }
    });
    dispatchRoomConfig({
      type: "SET_ACTIVE_COLOR",
      precalc: { ...temproomconfigstate }
    });
    roomViewHelper.updateObjects({ [objectname]: temproomconfigstate.objectstate[objectname] });
  };

  const removeTransState = (objectname = "all") => {
    if (objectname === "all") {
      var elems = document.getElementsByClassName("objcontainer__objcanvas--trans");

      elems.length > 0 &&
        Array.from(elems).forEach(element => {
          element.classList.remove("objcontainer__objcanvas--trans");
        });
      return;
    }
    let objectindex = roomconfigstate.objectstate[objectname].order - 1;
    document
      .getElementById("objcontainer")
      .children[objectindex].classList.remove("objcontainer__objcanvas--trans");
    setshowWand(false);
  };
  const handleLightSwitch = objectname => {
    const temproomconfigstate = roomConfigReducerHandlers.changeLightSwitch({
      state: { ...roomconfigstate },
      payload: { object: objectname }
    });
    dispatchRoomConfig({
      type: "TOGGLE_LIGHT_SWITCH",
      precalc: { ...temproomconfigstate }
    });
    if (temproomconfigstate.activeview !== roomconfigstate.activeview)
      changeView(temproomconfigstate);
  };

  window.downloadRendered3dIll = async () => {
    if (designDetailState.loading) return;
    const mime = "jpg";
    const downloadName = `${designDetailState.designName} in ${roomName}.${mime}`;
    const { dims, roomType = defaultRoomType } = config;
    const { width, height } = dims;
    const bgCanvas = createCanvas(width, height);
    const threeCanvas = createCanvas(width, height);
    const maskCanvas = createCanvas(width, height);
    const shadowCanvas = createCanvas(width, height);
    const container = { clientWidth: width, clientHeight: height };
    const inputCanvas = createCanvas(width, height);
    const transitionCanvas = createCanvas(width, height);
    const objCanvasContainer = document.createElement("div");
    roomconfigstate.roomelements.objects &&
      Object.keys(roomconfigstate.roomelements.objects).forEach((object, index) => {
        if (object.passive) return;
        const objCanvas = createCanvas(width, height);
        objCanvasContainer.appendChild(objCanvas);
      });
    const canvasConfig = {
      bgCanvas,
      threeCanvas,
      maskCanvas,
      shadowCanvas,
      container,
      inputCanvas,
      transitionCanvas,
      objCanvasContainer
    };
    const rh = new RoomViewHelper();
    rh.initCanvas(canvasConfig);
    rh.initConfig({ baseUrl, config, files, sizeFromConfig: true });
    const isinitialized = rh.preinitShot({
      shot: roomconfigstate.activeshot,
      light: roomconfigstate.activelight,
      view: roomconfigstate.activeview
    });
    if (!isinitialized) {
      await Promise.all(roomViewHelper.initShot());
    }
    if (!appliedActiveColor) {
      rh.updateBackground();
    } else {
      rh.selectedColorCode = roomViewHelper.selectedColorCode;
      rh.updateBackground({ dominantColorHex: appliedActiveColor });
    }
    rh.updateMask();
    await rh.updatethreeCanvas(roomconfigstate.activeshot);
    rh.setTileDetails(designDetailState.tileDetails);
    await rh.renderDesign({
      designDetails: designDetailState.designDetails,
      designPath: designDetailState.fullpath,
      hash: designDetailState.hash
    });
    if (roomType === defaultRoomType) {
      const objectConfig = roomViewHelper.threeView.getObjectConfig();
      if (objectConfig) {
        rh.threeView.carpetMesh.position.copy(objectConfig.position);
        rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
        rh.threeView.render();
      }
    }

    await rh.updateShadow();
    rh.updateObjects({ ...roomconfigstate.objectstate });
    await makeTransitionCanvas(rh);
    rh.downloadRendering(downloadName, mime);
    return Promise.resolve();
  };
  return (
    <React.Fragment>
      {showOptions &&
        roomconfigstate.roomelements.lights &&
        Object.keys(roomconfigstate.roomelements.lights).length > 0 && (
          <DayNightSlider handledayState={val => changeLight(val)} />
        )}
      <div
        className={(showWand ? "studiocontainer--wand " : "") + "studiocontainer"}
        ref={containerRef}
        onMouseOut={() => setshowWand(false)}
      >
        <canvas
          className={"studiocontainer__canvas studiocontainer__bgcanvas"}
          ref={bgCanvasRef}
          style={{
            zIndex: 1
          }}
        ></canvas>
        <canvas
          className={"studiocontainer__canvas studiocontainer__threecanvas"}
          ref={threeCanvasRef}
          style={{
            zIndex: 2,
            pointerEvents: roomconfigstate.mouseControls ? "all" : "none"
          }}
        ></canvas>
        <canvas
          className={"studiocontainer__canvas studiocontainer__maskcanvas"}
          ref={maskCanvasRef}
          style={{
            zIndex: 3
          }}
        ></canvas>
        <canvas
          className={"studiocontainer__canvas studiocontainer__shadowcanvas"}
          ref={shadowCanvasRef}
          style={{
            zIndex: 4,
            display: roomconfigstate.hasShadow ? "block" : "none"
          }}
        ></canvas>
        <div
          className={"studiocontainer__objcontainer objcontainer"}
          id={"objcontainer"}
          ref={objCanvasContainerRef}
          style={{
            display:
              roomconfigstate.objectstate && Object.keys(roomconfigstate.objectstate).length > 0
                ? "block"
                : "none"
          }}
        >
          {roomconfigstate.objectstate &&
            Object.keys(roomconfigstate.objectstate).map((object, index) => {
              return (
                <canvas
                  key={index}
                  style={{
                    display: roomconfigstate.objectstate[object].isobjectinscene ? "block" : "none",
                    zIndex: roomconfigstate.objectstate[object].zindex,
                    opacity: roomconfigstate.objectstate[object].mystic
                      ? roomconfigstate.objectstate[object].mystic === "show"
                        ? 1
                        : 0
                      : 1
                  }}
                  className={"objcontainer__objcanvas"}
                />
              );
            })}
        </div>
        <div
          className={"studiocontainer__inputcontainer inputcontainer"}
          id={"inputcontainer"}
          ref={inputCanvasContainerRef}
          onMouseMove={mouseObjectTracker}
          onClick={mouseObjectClick}
          style={{
            width: dimensions.width,
            height: dimensions.height,
            display: roomconfigstate.pointerinputcanvas ? "block" : "none"
          }}
        >
          {roomconfigstate.pointerinputcanvas ? (
            <InputCanvas
              className={"inputcontainer__inputcanvas"}
              zIndex={50}
              pointerEvent
              ref={inputCanvasRef}
              onStart={handleInputStart}
              onMove={handleinputMove}
              onEnd={handleInputEnd}
            />
          ) : (
            <InputCanvas
              className={"inputcontainer__inputcanvas"}
              zIndex={50}
              ref={inputCanvasRef}
              onStart={handleInputStart}
              onMove={handleinputMove}
              onEnd={handleInputEnd}
            />
          )}
        </div>
        <canvas
          className={
            (renderfromsavedstate ? "transitioncanvas__renderfromsavedstate " : "") +
            (transition === 1 ? "studiocontainer__transitioncanvas--fadeCross" : "") +
            " studiocontainer__transitioncanvas"
          }
          ref={transitionCanvasRef}
          style={{
            zIndex: 10
          }}
        ></canvas>
        <div
          style={{
            width: dimensions.width,
            height: dimensions.height,
            margin: "auto",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 11,
            pointerEvents: "none",
            position: "absolute"
          }}
        >
          {designDetailState.custoScout.show && designDetailState.custoScout.containerPoint && (
            <div
              style={{
                position: "absolute",
                left: `${designDetailState.custoScout.containerPoint.x}px`,
                top: `${designDetailState.custoScout.containerPoint.y}px`,
                zIndex: 100
              }}
              className="at-custo-assistant-scout ui"
            >
              <AreaSwatch
                designColor={
                  designDetailState.designDetails.DesignColors[designDetailState.selectedColor]
                }
                colorIndex={designDetailState.selectedColor}
                popoverPosition="auto"
                colorNumber={designDetailState.colorNumbers[designDetailState.selectedColor]}
                handleClick={e => e.stopPropagation()}
                onColorPieClick={(color, e) => {
                  dispatchColorList({ type: colorListActions.SELECT_COLOR, payload: color });
                }}
              />
              <AtIcon
                icon="close"
                onClick={() =>
                  dispatchDesignDetails({
                    type: designDetailActions.TOOGLE_CUSTOSCOUT,
                    payload: false
                  })
                }
              />
            </div>
          )}
        </div>
        {showOptions && (
          <Fade
            show={shotReady || transition === 1}
            className={"studiocontainer__optionscontainer optionscontainer"}
          >
            <div
              className={"optionscontainer__pseudoWrapper "}
              id={"photoscene-optionscontainer"}
              style={{
                width: dimensions.width,
                height: dimensions.height,
                display: shotReady ? "block" : "none"
              }}
            >
              {/* teleportation points in a shot */}
              {roomconfigstate.teleportpoints &&
                Object.keys(roomconfigstate.teleportpoints).map((shotkey, index) => {
                  return (
                    <div
                      key={index}
                      title={shotkey}
                      className={"optionscontainer__shotteleporter"}
                      onMouseOver={() => removeTransState()}
                      style={{
                        left: `${roomconfigstate.teleportpoints[shotkey][0]}%`,
                        top: `${roomconfigstate.teleportpoints[shotkey][1]}%`
                      }}
                    >
                      <CircularButton
                        icon={"viewing-height"}
                        onClick={() => changeShot(shotkey)}
                        className="circular-button"
                        size="small"
                      />
                    </div>
                  );
                })}

              {/* Active objects trigger points in a shot */}

              {Object.keys(roomconfigstate.activedata) &&
                Object.keys(roomconfigstate.activedata).map((eachactiveObj, index) => {
                  return roomconfigstate.objectstate[eachactiveObj].mystic !== "hide" ? (
                    <div
                      key={index}
                      className={"optionscontainer__activetrigger"}
                      style={{
                        left: `${roomconfigstate.activedata[eachactiveObj][0]}%`,
                        top: `${roomconfigstate.activedata[eachactiveObj][1]}%`
                      }}
                      onMouseOver={() => removeTransState(eachactiveObj)}
                    >
                      <Popover
                        content={
                          <div
                            style={{
                              left: `${roomconfigstate.activedata[eachactiveObj][0]}%`,
                              top: `${roomconfigstate.activedata[eachactiveObj][1]}%`
                            }}
                          >
                            <ColorSelectionBox
                              portalContainer={document.getElementById("photoscene-main")}
                              onColorSwatchClick={(color, e) =>
                                handleColorSwatchClick(color, eachactiveObj)
                              }
                              onClose={() => {
                                console.log("");
                              }}
                            />
                          </div>
                        }
                        position="auto"
                        boundary="window"
                        minimal
                        portalContainer={document.getElementById("photoscene-main")}
                        onClose={() => {}}
                        onOpened={() => {}}
                        captureDismiss
                      >
                        <div
                          className={"activetrigger__inelem"}
                          style={{
                            background: `${roomconfigstate.objectstate[eachactiveObj].active}`
                          }}
                        />
                      </Popover>
                    </div>
                  ) : (
                    false
                  );
                })}

              {/* Light Sources Switch buttons in a Light Setting */}
              {Object.keys(roomconfigstate.lightswitchpoints).map((lightsourcekey, index) => {
                return (
                  <div
                    key={index}
                    title={lightsourcekey}
                    className={
                      (roomconfigstate.objectstate[lightsourcekey].mystic === "show"
                        ? ""
                        : "mysticsource ") +
                      "optionscontainer__lightSwitch optionscontainer__lightSwitch" +
                      (roomconfigstate.objectstate[lightsourcekey].lightSwitch === "on"
                        ? "__on"
                        : "__off ")
                    }
                    style={{
                      left: `${roomconfigstate.lightswitchpoints[lightsourcekey][0]}%`,
                      top: `${roomconfigstate.lightswitchpoints[lightsourcekey][1]}%`
                    }}
                    onClick={() => handleLightSwitch(lightsourcekey)}
                    onMouseOver={() => removeTransState(lightsourcekey)}
                  ></div>
                );
              })}
            </div>
          </Fade>
        )}
        <AtSpinnerOverlay show={!shotReady} />
      </div>
    </React.Fragment>
  );
};

export default IllustrationView;
