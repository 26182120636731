/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import AppProvider from "../../../api/appProvider";
import { useWindowSize } from "react-use";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import {
  mainUiActions,
  pageViews,
  useUiDispatch,
  useUiState
} from "../../../reducers/mainui.reducer";
import strings from "../../../strings";
import { downloadImageData } from "../../../utils/canvasutils";
import { getPathOffile } from "../../../utils/treeutils";
import { convertUnit, getExtension, readJSON } from "../../../utils/utils";
import PerspectiveView from "./perspective";
function usePrevious(value) {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const pView = new PerspectiveView();

const InteractivePerspectiveView = props => {
  const { handleRendering, view = "InteractivePerspectiveView" } = props;
  const [{ config, baseUrl }, setroomData] = useState({});
  const [shotReady, setshotReady] = useState(false);
  const [isDesignRendered, setisDesignRendered] = useState(false);
  const [pViewInit, setpViewInit] = useState(false);
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchUiState = useUiDispatch();
  const uiState = useUiState();

  const {
    designDetails,
    fullpath: designPath,
    hash,
    updateDesignTiles,
    updateNormapTiles,
    designDimsOrig
  } = designDetailState;
  const prevDesignPath = usePrevious(designPath);
  const prevView = usePrevious(view);

  const windowsize = useWindowSize();

  const perspectiveCanvasRef = useRef(null);
  const containerRef = useRef(null);

  let designUnit = window.initialData.unit,
    designWidth = window.initialData.designWidth,
    designHeight = window.initialData.designHeight,
    customDesignUrl = window.initialData.customDesignUrl;

  useEffect(() => {
    if (!view) return;
    const loadData = async () => {
      let roomName = strings.designViews.perspective;
      let baseUrl = `v3assets/${view}/`;
      let configUrl;
      if (view === "InteractivePerspectiveView") {
        if (window.flags.perspectiveView && window.flags.perspectiveView.configUrl) {
          configUrl = window.flags.perspectiveView.configUrl;
        } else {
          const rooms = await AppProvider.fetchRoomList({});
          const filteredRooms = rooms.find(
            item =>
              item.Type === "file" &&
              getExtension(item.FullPath) === "crf3d" &&
              (getPathOffile(item.FullPath).toLowerCase() === "rooms/perspectiveview" ||
                getPathOffile(item.FullPath).toLowerCase() === "rooms/interactiveperspectiveview")
          );
          if (filteredRooms) {
            const roomData = await AppProvider.fetchRoomDetails({ file: filteredRooms.FullPath });
            const s = roomData.Dir.split("/");
            if (s[1] === "Assets") {
              const ss = s.slice(2);
              baseUrl = `${AppProvider.assetsDomain}/${ss.join("/")}`;
            } else {
              baseUrl = `${AppProvider.domain}${roomData.Dir}`;
            }
          }
          configUrl = `${baseUrl}/config.json`;
        }
      } else {
        configUrl = `${baseUrl}/config.json`;
      }
      const config = await readJSON(configUrl);
      if (view !== "InteractivePerspectiveView") {
        roomName = config.name;
      }
      if (prevView !== view) {
        setpViewInit(false);
        setisDesignRendered(false);
      }
      setroomData({ roomName, config, baseUrl });
    };
    loadData();
  }, [view]);

  useEffect(() => {
    if (handleRendering) handleRendering(shotReady);
    if (!shotReady) {
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
    } else {
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
    }
  }, [shotReady]);

  const handleShowInfoDialog = () => {
    dispatchUiState({
      type: mainUiActions.SET_INFO_DIALOG,
      payload: true
    });
  };

  const renderCustomDesignUrl = async () => {
    setshotReady(false);
    try {
      await pView.renderDesignFromCustomUrl({
        customUrl: customDesignUrl,
        physicalWidth: designWidth,
        physicalHeight: designHeight,
        unit: designUnit
      });
      setisDesignRendered(true);
      setshotReady(true);
    } catch (error) {
      console.log("load loading custom design -> error", error);
      setisDesignRendered(false);
      setshotReady(false);
    }
    if (window.flags.postToParentWhenLoaded) window.parent.postMessage("explorug loaded", "*");
  };

  useEffect(() => {
    if (designDetailState.loading) return;
    let la = true;
    const load = async () => {
      if (!la) return;
      if (customDesignUrl && customDesignUrl !== "") {
        if (!la) return;
        await renderCustomDesignUrl();
      } else if (designWidth && designHeight) {
        if (designUnit && designUnit !== designDetailState.designDetails.Unit) {
          designWidth = convertUnit(designUnit, designDetailState.designDetails.Unit, designWidth);
          designHeight = convertUnit(
            designUnit,
            designDetailState.designDetails.Unit,
            designHeight
          );
        }
        console.log("changePhysicalWidHgt -> ", designWidth, designHeight);
        changePhysicalWidHgt(parseFloat(designWidth), parseFloat(designHeight), designUnit);
      }
    };
    const loadFunc = async () => {
      if (!pViewInit) {
        await pView.init({ canvas: perspectiveCanvasRef.current, config: config, baseUrl });
        setpViewInit(true);

        if (uiState.pageView === pageViews.CONTROLLER) {
          window.cameraControls.maxDistance = Infinity;
          window.cameraControls.dollyTo(4000);
        }
      }
      load();
    };
    if (config && baseUrl) {
      loadFunc();
    }

    return () => {
      la = false;
    };
  }, [customDesignUrl, designWidth, designHeight]);

  useEffect(() => {
    if (!config) return;
    let la = true;
    const loadDesign = async () => {
      if (designDetails && !customDesignUrl) {
        let desDetails = { ...designDetails };
        if (desDetails.IsIrregular && config.disableIrregular) {
          handleShowInfoDialog();
          return;
        }
        if (designDimsOrig) desDetails = { ...desDetails, ...designDimsOrig };
        if (!isDesignRendered || prevDesignPath !== designPath) {
          setisDesignRendered(false);
          const tileData = await AppProvider.fetchTileDetails({
            file: designPath
          });
          if (!la) return;
          dispatchDesignDetails({
            type: designDetailActions.SET_TILE_DETAILS,
            payload: tileData
          });
          pView.tileDetails = tileData;
          await pView.renderDesign({
            designDetails: desDetails,
            designPath,
            hash
          });
          setisDesignRendered(true);
          setshotReady(true);
        } else {
          await pView.updateTiles({
            designDetails: desDetails,
            updateDesignTiles,
            updateNormapTiles,
            hash
          });
          setshotReady(true);
        }
      } else if (customDesignUrl) {
        await renderCustomDesignUrl();
      }
    };
    const loadFunc = async () => {
      if (!pViewInit) {
        await pView.init({ canvas: perspectiveCanvasRef.current, config: config, baseUrl });
        setpViewInit(true);
        if (uiState.pageView === pageViews.CONTROLLER) {
          window.cameraControls.maxDistance = Infinity;
          const viewPoint = windowsize.width > 900 ? 4000 : 8000;
          window.cameraControls.dollyTo(viewPoint);
        }
        window.cameraControls.dollyTo(4000);
        // console.log(window.cameraControls.maxDistance);
      }
      setshotReady(false);
      if (designDetails) {
        loadDesign();
      }
    };
    loadFunc();

    return () => {
      la = false;
    };
  }, [designDetails, config]);

  const changePhysicalWidHgt = (designWidth, designHeight, designUnit) => {
    if (!designWidth || !designHeight) return;

    if (
      parseFloat(designWidth) &&
      parseFloat(designHeight) &&
      (parseFloat(designWidth) !== designDetailState.designDetails.PhysicalWidth ||
        parseFloat(designHeight) !== designDetailState.designDetails.PhysicalHeight)
    ) {
      dispatchDesignDetails({
        type: designDetailActions.SET_RUG_PHYSICAL_SIZE,
        payload: {
          PhysicalWidth: parseFloat(designWidth),
          PhysicalHeight: parseFloat(designHeight),
          Unit: designUnit ? designUnit : designDetailState.designDetails.Unit
        }
      });
    }
  };

  window.downloadRenderedPerspectiveIll = async () => {
    if (designDetailState.loading) return;
    const roomName = strings.designViews.perspective;
    const mime = "jpg";
    const downloadName = `${designDetailState.designName} in ${roomName}.${mime}`;
    downloadImageData(perspectiveCanvasRef.current, downloadName, mime);
    return Promise.resolve();
  };
  window.getPerspectiveCanvas = () => {
    return perspectiveCanvasRef.current;
  };

  const resize = () => {
    if (!config) return;
    const { width, height } = containerRef.current.getBoundingClientRect();
    pView.resizeRenderer({ width, height });
  };

  useEffect(() => {
    resize();
  }, [windowsize]);

  return (
    <React.Fragment>
      <div
        className={`perspective-view__container ` + (shotReady ? "" : "hide")}
        ref={containerRef}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <canvas className="perspective-view__canvas" ref={perspectiveCanvasRef} />
      </div>
    </React.Fragment>
  );
};

export default InteractivePerspectiveView;
