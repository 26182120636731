import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CircularButton from "../../atoms/CircularButton";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";
const Thumbnail = props => {
  const {
    className,
    thumb,
    onRemoveClicked,
    onThumbnailClick,
    hasRemove,
    imageRotated,
    aspect = "portrait",
    showTitle = true,
    active,
    thumbType,
    datatitle = "",
    doubleImage = false,
    secondImageClass = ""
  } = props;
  let { thumbUrl, name, variations } = thumb;
  const { Thumb, Name } = thumb;
  thumbUrl = thumbUrl ? thumbUrl : Thumb;
  name = name ? name : Name;
  const [isHovering, setIsHovering] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const thumbImg = useRef(null);
  const ref = useRef(null);

  useEffect(() => {
    if (thumbUrl) {
      setIsloading(true);
    }
  }, [thumbUrl]);

  const rendername = (name, hasVariations = false) => {
    return (
      <>
        {name &&
          name.split("~").map((item, i) => {
            return (
              <p key={i}>
                {item}{" "}
                {hasVariations
                  ? window.flags.designVariations.showTitlewithSymbolForDesignVariations
                  : ""}
              </p>
            );
          })}
      </>
    );
  };

  const getBgImage = () => {
    if (
      !flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.designThumbsAsBg) ||
      thumbType === "ROOM_THUMBS" ||
      thumbType === "MY_ROOM"
    )
      return "none";
    const thumbSrc = thumbImg.current !== null ? "url(" + thumbImg.current.src + ")" : "none";
    return thumbSrc;
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={classNames("designThumb-template0 thumb-item", { active: active }, className)}
      onClick={e => {
        if (onThumbnailClick) onThumbnailClick(e);
      }}
      ref={ref}
      datatitle={datatitle !== "" ? datatitle : null}
    >
      <div
        className={classNames("thumb-image-container", aspect, {
          "bp3-skeleton": !thumbUrl || isloading
        })}
        style={{
          backgroundImage: getBgImage(),
          backgroundSize: thumbImg?.current?.width < 100 ? "auto" : "contain"
        }}
      >
        <img
          className={classNames(`thumb-image fit-width`, {
            rotated: imageRotated,
            "thumb-hidden": getBgImage() !== "none"
          })}
          ref={thumbImg}
          src={thumbUrl}
          onLoad={() => setIsloading(false)}
          alt="thumbnail"
        />
        {doubleImage && thumbImg?.current?.src && (
          <img
            className={classNames(`thumb-image fit-width`, secondImageClass, {
              rotated: imageRotated,
              "thumb-hidden": getBgImage() !== "none"
            })}
            src={thumbImg.current.src}
            alt="thumbnail"
          />
        )}
      </div>
      {hasRemove && isHovering && (
        <CircularButton
          icon="close"
          size="small"
          className="close-button"
          onClick={e => {
            e.stopPropagation();
            if (onRemoveClicked) onRemoveClicked(e);
          }}
        />
      )}

      {showTitle && (
        <span className="thumb-title" title={name}>
          {rendername(name, variations == null || name == "Original" ? false : true)}
        </span>
      )}
    </div>
  );
};

Thumbnail.propTypes = {
  className: PropTypes.string,
  thumb: PropTypes.object,
  hasRemove: PropTypes.bool,
  onRemoveClicked: PropTypes.func,
  onThumbnailClick: PropTypes.func,
  thumbnailType: PropTypes.oneOf(["portrait", "landscape"]),
  showTitle: PropTypes.bool
};
export default Thumbnail;
