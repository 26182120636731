import React, { useState } from "react";
import {
  Popover,
  Menu,
  MenuItem,
  Icon,
  MenuDivider,
  PopoverInteractionKind
} from "@blueprintjs/core";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import ColorSelectionBox from "../../organisms/ColorSelectionBox";
import AtIcon from "../../atoms/AtIcon";
import CircularColorSVG from "../../atoms/CircularColorSVG";
import strings from "../../../strings";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";
import { useColorListState } from "../../../reducers/colors.reducer";
import { useUiState } from "../../../reducers/mainui.reducer";
import { hextoLum } from "../../../utils/colorutils";
import { useDesignListState } from "../../../reducers/designlist.reducer";

export const getMaterialName = ind => {
  // let materialName;
  if (ind < 4)
    return getMatPileheightText({
      overwriteTextureNames: strings.color.defaultTextureNames,
      index: ind,
      defaultText: window.TextureOptions.DefaultTextureNames[ind]
    });
  if (ind <= 30)
    return getMatPileheightText({
      overwriteTextureNames: strings.color.additionalTextureNames,
      index: ind - 4,
      defaultText: window.TextureOptions.AdditionalTextureNames[ind - 4]
    });
  else {
    const custTexIndex = window.TextureOptions.CustomTextureIndices.findIndex(item => item === ind);
    return getMatPileheightText({
      overwriteTextureNames: strings.color.customTextureNames,
      index: custTexIndex,
      defaultText: window.TextureOptions.CustomTextureNames[custTexIndex]
    });
  }
};
export const getPileheightText = ind => {
  var pileheightNamesToOverwrite = null;
  if (window.flags.colorAreaSwatch.setCustomPileHgtNameOnSelection) {
    pileheightNamesToOverwrite = strings.color?.customPileheightNameOnSelection;
  }
  const defaultPileheightNames =
    pileheightNamesToOverwrite?.defaultPileheightNames || strings.color.defaultPileheightNames;
  const customPileheightNames =
    pileheightNamesToOverwrite?.customPileheightNames || strings.color.customPileheightNames;
  if (ind <= 200) {
    let closest = window.TextureOptions.DefaultPileheightValues.reduce((prev, curr) =>
      Math.abs(curr - ind) < Math.abs(prev - ind) ? curr : prev
    );

    const index = window.TextureOptions.DefaultPileheightValues.findIndex(item => item === closest);
    return getMatPileheightText({
      overwriteTextureNames: defaultPileheightNames,
      index: index,
      defaultText: window.TextureOptions.DefaultPileheightNames[index]
    });
  } else {
    const index = window.TextureOptions.CustomPileheightIndices.findIndex(item => item === ind);
    return getMatPileheightText({
      overwriteTextureNames: customPileheightNames,
      index: index,
      defaultText: window.TextureOptions.CustomPileheightNames[index]
    });
  }
};
const getMatPileheightText = ({ overwriteTextureNames, index, defaultText }) => {
  if (!overwriteTextureNames) return defaultText;
  let text = defaultText;
  if (hasOverwriteString(overwriteTextureNames, index)) text = overwriteTextureNames[index];
  return text;
};
const getMaterialPrefix = ind => {
  const matName = getMaterialNamePart(ind, 0);

  return matName;
};
const getMaterialSuffix = ind => {
  const matName = getMaterialNamePart(ind, 1);
  return matName;
};
const getMaterialNamePart = (ind, index) => {
  const materialName = getMaterialName(ind);
  const partOfName = materialName
    .split(`${materialName.includes("-") ? "-" : " "}`) //if wool loop ko satta standard wool loop yesto standard thapnaparema use "-" in texture file
    [index].toLowerCase();
  const matArray =
    index === 0
      ? window.flags.colorAreaSwatch.multipleMatDropdowns.first
      : window.flags.colorAreaSwatch.multipleMatDropdowns.second;
  const matName = matArray.filter(elem => elem.toLowerCase().lastIndexOf(partOfName) !== -1);
  return matName[0];
};
const hasMaterialLangOptions = ({ language = window.flags.language, overwriteTextureNames }) => {
  var hasLangOptions =
    language.langOptions.length && language.langOptions[0] !== "" && overwriteTextureNames
      ? true
      : false;
  return hasLangOptions;
};
const hasOverwriteString = (namesArray, index) => {
  //to check if the string that overwrites text exists and is not blank
  if (namesArray && namesArray[index] && namesArray[index] !== "") return true;
  else return false;
};
const hasMultipleLangOptions = ({ language = window.flags.language, overwriteTextureNames }) => {
  let hasValidOptions =
    hasMaterialLangOptions({ language, overwriteTextureNames }) &&
    overwriteTextureNames.length &&
    !overwriteTextureNames.includes(undefined) &&
    !overwriteTextureNames.includes("");
  return hasValidOptions;
};
const MaterialDropdownMultiple = props => {
  const {
    handleMultipleMaterialChange,
    matDropdownOptions,
    getMaterialNamePart,
    designColor
  } = props;

  const currentMatPart = getMaterialNamePart(designColor.YarnDetails[0].Material);
  const handleMatChange = ({ materialName }) => {
    handleMultipleMaterialChange({ materialName });
  };
  return (
    <div className="color-details-multiple-material">
      <Popover
        content={
          <Menu className="multiple-material-menu">
            {matDropdownOptions && (
              <>
                {matDropdownOptions.map((textureName, i) => (
                  <MenuItem
                    className="multiple-material-menuitem"
                    key={i}
                    text={textureName}
                    onClick={e => {
                      handleMatChange({ materialName: textureName });
                    }}
                  />
                ))}
              </>
            )}
          </Menu>
        }
        minimal
        position="bottom-left"
        portalContainer={document.getElementById("app-main")}
      >
        <div className="color-details-material-info">
          <>
            <span className="color-details-material-text">{currentMatPart}</span>
            {<Icon className="color-details-material-icon" icon="caret-down" />}
          </>
        </div>
      </Popover>
    </div>
  );
};
export const AreaSwatch = React.forwardRef((props, ref) => {
  const {
    className,
    colorIndex,
    onColorSelectionClose,
    onColorSelectionOpen,
    handleClick,
    onColorPieClick,
    style,
    designColor,
    colorNumber,
    totalKnots,
    popoverPosition = "left",
    showPile = true,
    showColorSelection = true,
    showColorPercent,
    isOneActMainColor,
    handleOneActMainColorChange
  } = props;

  const dispatchDesignDetails = useDispatchDesignDetail();
  const designDetailState = useDesignDetailState();
  const colorListState = useColorListState();
  const state = useDesignListState();
  const { colorTextures, activeColor } = colorListState;
  const { designDetails, lockedColorIndexArray } = designDetailState;
  const { colorAreaSwatch: casFlags, colorSeparatedTexture, language } = window.flags;
  const showPlyDropdown = flaginCurrentMode(window.InterfaceElements.IsAdmin, casFlags.hasMixply); /// window.InterfaceElements.IsAdmin;
  const { mixPlyOptions } = casFlags;
  let colorPercentShown =
    showColorPercent === undefined ? casFlags.showColorPercent : showColorPercent;

  const [openCSB, setOpenCSB] = useState(false);
  const [colorLock, setColorLock] = useState(false);
  const uiState = useUiState();

  const {
    DefaultTextureVisible,
    AdditionalTextureVisible,
    CustomTextureIndices,
    CustomPileheightIndices,
    DefaultPileheightValues,
    DefaultPileheightVisible
  } = window.TextureOptions;

  let {
    DefaultTextureNames,
    AdditionalTextureNames,
    CustomTextureNames = [],
    CustomPileheightNames,
    DefaultPileheightNames
  } = window.TextureOptions;

  DefaultTextureNames = hasMultipleLangOptions({
    language,
    overwriteTextureNames: strings.color.defaultTextureNames
  })
    ? strings.color.defaultTextureNames
    : DefaultTextureNames;

  AdditionalTextureNames = hasMultipleLangOptions({
    language,
    overwriteTextureNames: strings.color.additionalTextureNames
  })
    ? strings.color.additionalTextureNames
    : AdditionalTextureNames;

  CustomTextureNames = hasMultipleLangOptions({
    language,
    overwriteTextureNames: strings.color.customTextureNames
  })
    ? strings.color.customTextureNames
    : CustomTextureNames;

  CustomPileheightNames = hasMultipleLangOptions({
    language,
    overwriteTextureNames: strings.color.customPileheightNames
  })
    ? strings.color.customPileheightNames
    : CustomPileheightNames;

  DefaultPileheightNames = hasMultipleLangOptions({
    language,
    overwriteTextureNames: strings.color.defaultPileheightNames
  })
    ? strings.color.defaultPileheightNames
    : DefaultPileheightNames;

  const origDesignColors = designDetailState.history.length
    ? designDetailState.history[0].designDetails.DesignColors
    : designDetails.DesignColors;

  const handleColorSwatchClick = (color, e) => {
    if (designColor.YarnDetails.length <= 1) {
      if (isOneActMainColor === true) {
        if (handleOneActMainColorChange) handleOneActMainColorChange(color);
      } else {
        dispatchDesignDetails({
          type: designDetailActions.CHANGE_COLOR,
          payload: color
        });
      }
    } else {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_YARN_COLOR,
        payload: color
      });
    }
  };

  const handleMultipleMaterialChange_first = ({ materialName }) => {
    const currentMatName = getMaterialName(designColor.YarnDetails[0].Material);
    const matSuffix = currentMatName.split(`${currentMatName.includes("-") ? "-" : " "}`)[1];
    const newMatName =
      materialName +
      `${materialName.split(" ").length === 1 && matSuffix.split(" ").length === 1 ? " " : "-"}` +
      matSuffix;
    handleMultipleMaterialChange(newMatName);
  };
  const handleMultipleMaterialChange_second = ({ materialName }) => {
    const currentMatName = getMaterialName(designColor.YarnDetails[0].Material);
    const matPrefix = currentMatName.split(`${currentMatName.includes("-") ? "-" : " "}`)[0];
    const newMatName =
      matPrefix +
      `${matPrefix.split(" ").length === 1 && materialName.split(" ").length === 1 ? " " : "-"}` +
      materialName;
    handleMultipleMaterialChange(newMatName);
  };
  const handleMultipleMaterialChange = newMatName => {
    const totalMatOptions = [
      ...window.TextureOptions.DefaultTextureNames,
      ...window.TextureOptions.AdditionalTextureNames
    ];
    const material = totalMatOptions.indexOf(newMatName);
    const materialName = newMatName;
    handleMaterialChange({ material, materialName });
  };

  const handleMaterialChange = ({ material, materialName }) => {
    const isMaterialSameForAll = casFlags.sameMaterialForAll;
    dispatchDesignDetails({
      type: designDetailActions.CHANGE_COLOR_TEXTURE,
      payload: { material, materialName, colorIndex, isMaterialSameForAll }
    });

    // if (designColor.YarnMaterials.length <= 1) {
    //   dispatchDesignDetails({
    //     type: designDetailActions.CHANGE_COLOR_TEXTURE,
    //     payload: { material, materialName, colorIndex }
    //   });
    // } else {
    //   dispatchDesignDetails({
    //     type: designDetailActions.CHANGE_YARN_MATERIAL,
    //     payload: { material, materialName, colorIndex }
    //   });
    // }
  };
  const selectYarn = yarnIndex => {
    if (designColor.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_DESIGN_COLOR,
        payload: colorIndex
      });
      if (onColorPieClick) onColorPieClick(designColor);
    } else {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_YARN,
        payload: { colorIndex, yarnIndex }
      });
      if (onColorPieClick) onColorPieClick(designColor.YarnDetails[yarnIndex]);
    }
  };
  const handleYarnClick = (e, yarnIndex) => {
    selectYarn(yarnIndex);
  };
  const handleMixPly = plyNumber => {
    dispatchDesignDetails({
      type: designDetailActions.MIX_PLY,
      payload: { colorIndex, plyNumber }
    });
  };
  const colorTitle =
    designColor.YarnDetails.length <= 1
      ? designColor.YarnDetails[0].ColorName
      : strings.color.mixedColors;
  const shouldShowDropDown = () => {
    const materials = designColor.YarnDetails.map(item => item.Material);
    return materials.every(item => item === materials[0]);
  };
  const allMaterialOptionsHidden = () => {
    return DefaultTextureVisible.every(item => item === false);
  };

  const checkExclusiveMaterial = i => {
    var exclusiveMaterials = casFlags.exclusiveMatArr;
    if (casFlags.donotShowMatArr.length != 0) {
      exclusiveMaterials = !flaginCurrentMode(
        window.InterfaceElements.IsAdmin,
        casFlags.exclusiveMatArrMode
      )
        ? casFlags.exclusiveMatArr
        : [];
    }

    const origColorMaterial = origDesignColors[colorIndex].YarnDetails[0].Material;
    let associatedYarnMaterial = parseFloat(designColor.YarnDetails[0].AssociatedMat);

    associatedYarnMaterial =
      associatedYarnMaterial || associatedYarnMaterial === 0
        ? associatedYarnMaterial
        : origColorMaterial;
    let showThisMat =
      associatedYarnMaterial === i || //show this mat if it is associated material of the color
      !isAnExclusiveMat(exclusiveMaterials, i) || // hide if it is in exclusive mat array list
      isExclusiveWithinArr(exclusiveMaterials, associatedYarnMaterial, i) //show if current mat should appear with another exclusive mat eg: for exclusivematArr = [[1,2], 3], mat 2 should appear with mat 1. if i ==2 (i.e current mat), and  1 is associatedMat then,  it should appear
        ? true
        : false;
    return showThisMat;
  };

  const checkspecificMaterialIndexForSpecificFolder = i => {
    const specificMaterialForSpecificFolder = casFlags.specificMaterialForSpecificFolder;
    var isPresent = true;
    if (specificMaterialForSpecificFolder.length == 0) {
      isPresent = true;
      return isPresent;
    } else {
      const currentFolder = state.selectedFile.location.split("/").pop();
      const currentArray = specificMaterialForSpecificFolder.filter(
        item => item[0] === currentFolder
      );
      if (currentArray.length == 0) isPresent = true;
      else {
        if (!currentArray[0][1].includes(i)) isPresent = false;
      }
    }
    return isPresent;
  };
  const isAnExclusiveMat = (exclusiveMaterials, matOption) => {
    let isExclusive = exclusiveMaterials.indexOf(matOption) !== -1 ? true : false;
    exclusiveMaterials.forEach(element => {
      if (Array.isArray(element) && element.indexOf(matOption) !== -1) {
        isExclusive = true;
      }
    });
    if (
      casFlags.donotShowMatArr.length > 0 &&
      !flaginCurrentMode(window.InterfaceElements.IsAdmin, casFlags.exclusiveMatArrMode) //show in multiple only, done for adnc
    ) {
      let associatedYarnMaterial = designColor.YarnDetails[0].Material;
      if (!casFlags.donotShowMatArr.includes(associatedYarnMaterial)) {
        isExclusive = true;
      }
    }
    return isExclusive;
  };

  const isExclusiveWithinArr = (exclusiveMaterials, designColorMaterial, matOption) => {
    var showThisMat = false;
    for (var i = 0; i < exclusiveMaterials.length; i++) {
      if (Array.isArray(exclusiveMaterials[i])) {
        if (
          exclusiveMaterials[i].indexOf(designColorMaterial) !== -1 &&
          exclusiveMaterials[i].indexOf(matOption) !== -1
        ) {
          showThisMat = true;
        }
      }
    }
    return showThisMat;
  };
  const checkAdditionalTextureVisible = i => {
    let showThis = true;
    if (AdditionalTextureVisible && AdditionalTextureVisible.length) {
      showThis = AdditionalTextureVisible[i];
    }
    return showThis;
  };
  const materialDropdown = (
    <div className="color-details-material">
      <Popover
        disabled={!shouldShowDropDown() || allMaterialOptionsHidden()}
        boundary="viewport"
        content={
          <Menu className="material-menu">
            {(DefaultTextureNames || AdditionalTextureNames) && (
              <>
                {CustomTextureNames && CustomTextureNames.length > 0 && (
                  <MenuDivider
                    className="color-details-material-menudivider"
                    title={strings.color.basicMaterialTitle}
                  />
                )}

                {DefaultTextureNames &&
                  DefaultTextureNames.map(
                    (textureName, i) =>
                      DefaultTextureVisible[i] &&
                      checkspecificMaterialIndexForSpecificFolder(i) &&
                      checkExclusiveMaterial(i) && (
                        <MenuItem
                          className="material-default-menuitem"
                          key={i}
                          text={textureName}
                          onClick={e => {
                            handleMaterialChange({ material: i, materialName: textureName });
                          }}
                        />
                      )
                  )}
                {AdditionalTextureNames &&
                  AdditionalTextureNames.map(
                    (textureName, i) =>
                      checkAdditionalTextureVisible(i) &&
                      checkExclusiveMaterial(4 + i) && (
                        <MenuItem
                          className="material-additional-menuitem"
                          key={i}
                          text={textureName}
                          onClick={e => {
                            handleMaterialChange({ material: 4 + i, materialName: textureName });
                          }}
                        />
                      )
                  )}
              </>
            )}

            {CustomTextureNames && CustomTextureNames.length > 0 && (
              <>
                <MenuDivider
                  className="color-details-material-menudivider"
                  title={strings.color.mixedConstructionsTitle}
                />
                {CustomTextureNames.map((textureName, i) => (
                  <MenuItem
                    className="material-custom-menuitem"
                    key={i}
                    text={textureName}
                    onClick={e => {
                      handleMaterialChange({
                        material: CustomTextureIndices[i],
                        materialName: textureName
                      });
                    }}
                  />
                ))}
              </>
            )}
          </Menu>
        }
        minimal
        position="bottom-left"
        portalContainer={document.getElementById("app-main")}
      >
        <div className="color-details-material-info">
          {shouldShowDropDown() ? (
            <>
              <span className="color-details-material-text">
                {getMaterialName(designColor.YarnDetails[0].Material)}
              </span>
              {!allMaterialOptionsHidden() && (
                <Icon className="color-details-material-icon" icon="caret-down" />
              )}
            </>
          ) : (
            <span className="color-details-material-mixed">{strings.color.mixedTextures}</span>
          )}
        </div>
      </Popover>
    </div>
  );

  const multipleMaterialDropdown = (
    <>
      <MaterialDropdownMultiple
        designColor={designColor}
        handleMultipleMaterialChange={handleMultipleMaterialChange_first}
        matDropdownOptions={casFlags.multipleMatDropdowns.first}
        getMaterialNamePart={getMaterialPrefix}
      />

      <MaterialDropdownMultiple
        designColor={designColor}
        handleMultipleMaterialChange={handleMultipleMaterialChange_second}
        matDropdownOptions={casFlags.multipleMatDropdowns.second}
        getMaterialNamePart={getMaterialSuffix}
      />
    </>
  );
  const handleColorDivClick = () => {
    if (!activeColor || (activeColor && designColor.YarnDetails[0].Color !== activeColor.Color)) {
      if (designColor.YarnDetails.length === 1) {
        selectYarn(0);
      }
    }
    setOpenCSB(!openCSB);
  };

  const handleLockIconClick = index => {
    const arr = designDetailState.lockedColorIndexArray;
    if (arr.includes(index)) {
      arr.splice(arr.indexOf(index), 1);
    } else {
      arr.push(index);
      arr.sort();
    }
    dispatchDesignDetails({
      type: designDetailActions.SET_LOCKED_COLOR_INDEX_ARRAY,
      payload: arr
    });
    setColorLock(!colorLock);
  };

  return (
    <div
      ref={ref}
      className={classNames(
        "area-swatch bp3-text-small",
        {
          "no-custom": sessionStorage.getItem("customize") === "false"
        },
        {
          disabled: !showColorSelection
        },
        className
      )}
      style={style}
      onClick={handleClick}
    >
      {!window.flags.colorAreaSwatch.colorNumberPosition && (
        <>
          <span className="color-index">
            {colorNumber ? colorNumber : colorIndex + 1}
            {window.flags.colorAreaSwatch.showLockIcon && (
              <div onClick={() => handleLockIconClick(colorIndex)} style={{ cursor: "pointer" }}>
                <AtIcon
                  className="color-details-name__lock"
                  icon={lockedColorIndexArray.includes(colorIndex) ? "locked" : "unlocked"}
                  size={1}
                  color={lockedColorIndexArray.includes(colorIndex) ? "#30363B" : "#C7CBCE"}
                />
              </div>
            )}
          </span>
        </>
      )}
      <Popover
        className={classNames("color-area-popover", { marginAuto: uiState.isOneActFolder })}
        content={
          <ColorSelectionBox
            selectionColor={designColor.YarnDetails[0]}
            onColorSwatchClick={e => {
              if (handleColorSwatchClick) handleColorSwatchClick(e);
              if (window.flags.colorSelectionBox.closeCSBAfterColorSelected) {
                setOpenCSB(false);
              }
            }}
            onClose={() => {
              setOpenCSB(false);
              if (onColorSelectionClose) onColorSelectionClose();
            }}
          />
        }
        minimal
        position={popoverPosition}
        portalContainer={document.getElementById("app-main")}
        boundary="window"
        onClose={() => {
          setOpenCSB(false);
          if (onColorSelectionClose) onColorSelectionClose();
        }}
        onOpened={onColorSelectionOpen}
        disabled={!showColorSelection}
        captureDismiss
        isOpen={openCSB}
      >
        <div className="color-pie" onClick={handleColorDivClick}>
          <Popover
            className="at-colorTipPopover"
            interactionKind={PopoverInteractionKind.HOVER}
            content={<div className="at-colorboxTooltipmsg">Click to select colors</div>}
            minimal
            position="top"
            portalContainer={document.getElementById("app-main")}
            boundary="viewport"
            disabled={!casFlags.toolTipOnColorBoxHover}
          >
            <CircularColorSVG
              className="color-pie__color"
              yarnDetails={designColor.YarnDetails}
              handleClick={handleYarnClick}
              colorTextures={colorTextures}
              shape={casFlags.shape}
              showTexture={casFlags.showTexture}
              showColorPoms={window.flags.colorAreaSwatch.showColorPomsImages}
              colorPomsShape="round"
            />
          </Popover>

          {totalKnots && (
            <span
              className={`color-pie__percentage ${colorPercentShown ? "" : "hidden"} ${window.flags
                .colorAreaSwatch.colorNumberPosition === 1 && "atBottom"}`}
              style={{
                color: hextoLum(designColor.YarnDetails[0].Color) < 50 ? "#fff" : "#000",
                opacity: "0.7"
              }}
            >
              {((designColor.Knots / totalKnots) * 100).toFixed(1)}%
            </span>
          )}
          {window.flags.colorAreaSwatch.colorNumberPosition === 1 && (
            <span
              className="color-index color-pie__colorindex"
              style={{
                color: hextoLum(designColor.YarnDetails[0].Color) < 50 ? "#fff" : "#000",
                opacity: "0.9"
              }}
            >
              {colorNumber ? colorNumber : colorIndex + 1}
            </span>
          )}
        </div>
      </Popover>

      <div
        className={classNames(
          "color-details-area",
          {
            hidden: uiState.isOneActFolder
          },
          className
        )}
      >
        {designColor && (
          <Popover
            minimal
            disabled={!showPlyDropdown}
            position="bottom-left"
            portalContainer={document.getElementById("app-main")}
            content={
              <Menu className="mixply-menu">
                {mixPlyOptions.map(plyOption => (
                  <MenuItem
                    key={plyOption.ply}
                    onClick={() => handleMixPly(plyOption.ply)}
                    text={plyOption.text}
                  ></MenuItem>
                ))}
              </Menu>
            }
          >
            <div className="color-details-name">
              <span className="color-details-name__title" title={colorTitle}>
                {casFlags.colorNamePrefix}
                {colorTitle}
              </span>
              {designColor.Carving && (
                <AtIcon
                  className="color-details-name__scissor"
                  icon="carving"
                  title="Carving"
                  size={1}
                />
              )}
              {showPlyDropdown && (
                <Icon className="color-details-name__mixplyDropdown" icon="caret-down" />
              )}
            </div>
          </Popover>
        )}

        {colorSeparatedTexture && (
          <>
            {casFlags.showMultipleMatDropdowns ? multipleMaterialDropdown : materialDropdown}
            {showPile && (
              <div className="color-details-pileheight">
                <Popover
                  boundary="viewport"
                  content={
                    <Menu className="pileheight-menu">
                      {DefaultPileheightNames && DefaultPileheightNames.length > 0 && (
                        <>
                          {CustomPileheightNames && CustomPileheightNames.length > 0 && (
                            <MenuDivider
                              className="color-details-pileheight-menudivider"
                              title="Basic"
                            />
                          )}

                          {DefaultPileheightNames.map(
                            (pileHeightText, i) =>
                              (!DefaultPileheightVisible || DefaultPileheightVisible[i]) && (
                                <MenuItem
                                  className="pileheight-default-menuitem"
                                  key={i}
                                  text={pileHeightText}
                                  onClick={e => {
                                    dispatchDesignDetails({
                                      type: designDetailActions.CHANGE_PILE,
                                      payload: {
                                        pileHeight: DefaultPileheightValues[i],
                                        colorIndex
                                      }
                                    });
                                  }}
                                />
                              )
                          )}
                        </>
                      )}
                      {CustomPileheightNames && CustomPileheightNames.length > 0 && (
                        <>
                          <MenuDivider
                            className="color-details-pileheight-menudivider"
                            title="Mixed"
                          />
                          {CustomPileheightNames.map((pileHeightText, i) => (
                            <MenuItem
                              className="pileheight-custom-menuitem"
                              key={i}
                              text={pileHeightText}
                              onClick={e => {
                                dispatchDesignDetails({
                                  type: designDetailActions.CHANGE_PILE,
                                  payload: { pileHeight: CustomPileheightIndices[i], colorIndex }
                                });
                              }}
                            />
                          ))}
                        </>
                      )}
                    </Menu>
                  }
                  minimal
                  position="bottom-left"
                  portalContainer={document.getElementById("app-main")}
                >
                  <div className="color-details-pileheight-info" style={{ display: "flex" }}>
                    <span
                      className="color-details-pileheight-text"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word"
                      }}
                    >
                      {getPileheightText(designColor.PileHeight)}
                    </span>
                    <Icon icon="caret-down" />
                  </div>
                </Popover>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});
