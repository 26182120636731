import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useColorListState } from "../../../reducers/colors.reducer";
import { clearCanvas } from "../../../utils/canvasutils";
import { getPomUrl } from "../../../utils/colorutils";
import { overlayTextureinCanvas, readImage } from "../../../utils/domUtils";
const ColorBox = props => {
  const {
    color,
    material,
    handleClick,
    shape,
    strokeColor = "#808080",
    colorTextures,
    showTexture = false,
    style,
    colorName = "",
    showColorPoms = false,
    colorPomsShape = "round",
    ...otherprops
  } = props;
  const isPaulig = window.flags.accountSpecific.isPaulig;
  const canvasSize = 80;
  const canvasRef = useRef();
  const [showColorSwatchBg, setShowColorSwatchBg] = useState(true);

  const colorList = useColorListState();
  useEffect(() => {
    if (showTexture) {
      const ctx = canvasRef.current.getContext("2d");
      clearCanvas(canvasRef.current, canvasSize, canvasSize);
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvasSize, canvasSize);
      if (!!showTexture) {
        const { tabs, baseUrl } = window.flags.colorSelectionBox.customColorPreviews;
        const colorTab =
          colorList && colorList.collections
            ? colorList.collections.find(colorTab => {
                if (!tabs.includes(colorTab.Name)) return false;
                return colorTab.ColorRows.find(
                  colorRow => colorRow.Color === color && colorRow.ColorName === colorName
                );
              })
            : null;

        if (colorTab) {
          const textureImageUrl = `${baseUrl}/${colorTab.Name}/${colorName}.jpg`;
          readImage(textureImageUrl)
            .then(colorImage => {
              ctx.drawImage(colorImage, 0, 0, canvasSize, canvasSize);
            })
            .catch(err => {});
        } else {
          const textureImage = colorTextures.find(item => item.index === material) || {};
          const { image } = textureImage;
          if (image)
            overlayTextureinCanvas({
              image,
              color,
              canvasSize,
              canvas: canvasRef.current
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, material, colorName]);
  return !showTexture ? (
    <div
      onClick={e => handleClick(e, 0)}
      className={classNames(
        "color-pie__color",
        { "no-background": !showColorSwatchBg },
        {
          "hide-color-pom": showColorSwatchBg
        }
      )}
      style={{
        border: `1px solid ${strokeColor}`,
        backgroundColor: color,
        backgroundImage: `${
          isPaulig
            ? `url("https://v3.explorug.com/Files/Customers/Paulig/colors/${colorName}.jpg")`
            : "none"
        }`,
        backgroundSize: "contain",
        ...style
      }}
    >
      {/* {showColorPoms && (
        <img className={classNames(`color-pom-image`)} ref={colorPomImgRef} alt="color pom" />
      )} */}
      {showColorPoms && (
        <img
          className={classNames(`color-pom-image`)}
          alt="color pom"
          src={getPomUrl(color, material, colorPomsShape)}
          onError={() => {
            setShowColorSwatchBg(true);
          }}
          onLoad={() => setShowColorSwatchBg(false)}
        />
      )}
    </div>
  ) : (
    <canvas
      className="color-pie__color"
      style={{ border: `1px solid ${strokeColor}`, ...style }}
      ref={canvasRef}
      width={canvasSize}
      height={canvasSize}
      onClick={e => handleClick(e, 0)}
      {...otherprops}
    />
  );
};
const Mixply1 = props => {
  const { shape, yarnDetails, ...otherprops } = props;
  let color = "",
    material = 0,
    colorName = "";
  if (yarnDetails[0]) {
    color = yarnDetails[0].Color;
    material = yarnDetails[0].Material;
    colorName = yarnDetails[0].ColorName;
  }
  switch (shape) {
    case "CIRCLE":
      return (
        <ColorBox
          color={color}
          material={material}
          colorName={colorName}
          style={{ borderRadius: "50%" }}
          {...otherprops}
        />
      );
    case "SQUARE":
      return <ColorBox color={color} material={material} colorName={colorName} {...otherprops} />;
    default:
      return null;
  }
};

Mixply1.propTypes = {};

export default Mixply1;
