import React, { useState, useEffect } from "react";
import ThumbList from "../../molecules/ThumbList";
import AppProvider from "../../../api/appProvider";
import PropTypes from "prop-types";
import AtIcon from "../../atoms/AtIcon";
import strings from "../../../strings";
import { useMount } from "react-use";
import {
  useDesignListState,
  useDispatchDesignList,
  designListActions
} from "../../../reducers/designlist.reducer";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";
const FAVORITE_DESIGNS = "favorite designs";
const Favorites = () => {
  const { initDesignPath, eCatalogDetails } = window.initialData;

  const [favs, setFavs] = useState(null);
  const { selectedFile, favorites } = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const uiDispatch = useUiDispatch();
  // const { defaultQualityIndex } = window.flags;
  const { CurrentUserID } = window.InterfaceElements;
  useMount(() => {
    let favoriteDesigns = JSON.parse(localStorage.getItem(FAVORITE_DESIGNS)) || [];
    let getPageName = sessionStorage.getItem("page");
    if (getPageName == "undefined") {
      getPageName = sessionStorage.getItem("username");
    }
    let favoriteDesignsFromPage =
      JSON.parse(localStorage.getItem(FAVORITE_DESIGNS + "-" + getPageName)) || [];
    let favorites = [...favoriteDesigns, ...favoriteDesignsFromPage];
    favorites = favorites.filter(item => item.currentUserID === CurrentUserID);
    // *for backward compatibility;
    // old node:
    // const favNode = {
    //   currentUserID: window.window.InterfaceElements.CurrentUserID,
    //   label: newName,
    //   id: uuid.v4(),
    //   Thumb: null,
    //   fullpath: designDetailState.fullpath,
    //   designDetails: designDetailState.designDetails,
    //   customizationFlag: designDetailState.customizationFlag,
    //   hash: designDetailState.hash,
    //   view: visState.activeNode.FullPath
    // };
    favorites = favorites.map(item => ({
      ...item,
      name: item.label || item.name,
      designProps: item.designDetails || item.designProps,
      fullPath: item.fullpath || item.fullPath
    }));
    if (eCatalogDetails && initDesignPath) {
      //TODO: remove tolowercase after backend issue is fixed
      favorites = favorites.filter(
        item => item.fullPath.toLowerCase() === initDesignPath.toLowerCase()
      );
    }
    dispatchDesignList({ type: designListActions.SET_FAVORITES, payload: favorites });
  });
  const handleThumbnailClick = fav => {
    const { name, designProps, fullPath, thumbUrl, id } = fav;
    if (!designProps) return;
    uiDispatch({ type: mainUiActions.SET_SHOW_WORDCLOUD, payload: false });
    dispatchDesignList({
      type: designListActions.SELECT_DESIGN,
      payload: { name, designProps, fullPath, thumbUrl, fav: true, id }
    });
    // dispathcDesignDetails({
    //   type: designDetailActions.SET_DETAILS,
    //   payload: {
    //     designDetails: designProps,
    //     customizationFlag,
    //     label: name,
    //     fullpath: fullPath,
    //     defaultQualityIndex,
    //     hash
    //   }
    // });
  };
  const handleRemoveFavorite = fav => {
    let getPageName = sessionStorage.getItem("page");
    if (getPageName == "undefined") {
      getPageName = sessionStorage.getItem("username");
    }
    const toRemove = favorites.find(
      item => item.currentUserID === CurrentUserID && item.id === fav.id
    );
    const newList = favorites.filter(item => item !== toRemove);
    dispatchDesignList({ type: designListActions.SET_FAVORITES, payload: newList });
    localStorage.setItem(FAVORITE_DESIGNS + "-" + getPageName, JSON.stringify(newList));
  };
  useEffect(() => {
    const noUrlThumbs = favorites.filter(favs => favs.thumbUrl == null);
    const haveUrlThumbs = favorites.filter(favs => favs.thumbUrl != null);
    if (noUrlThumbs.length > 0) {
      AppProvider.getDesignThumbnails({ designs: noUrlThumbs }).then(thumblist => {
        const f = thumblist.map((item, index) => {
          return { ...item, designProps: favorites.find(f => f.id === item.id).designProps };
        });
        f.map(item => {
          haveUrlThumbs.unshift(item);
        });
        setFavs(haveUrlThumbs);
      });
    } else {
      setFavs(favorites);
    }
  }, [favorites, CurrentUserID]);
  return (
    favs && (
      <>
        <div className="at-scroll-on-hover">
          {favs.length > 0 ? (
            <ThumbList
              className="at-fav-thumbs"
              hasRemove
              onRemoveClicked={handleRemoveFavorite}
              thumbList={favs}
              activeId={selectedFile.id}
              onThumbnailClick={(t, i) => handleThumbnailClick(favs[i], i)}
            ></ThumbList>
          ) : (
            <div className="lonely-area">
              <div className="lonely-area__broken-heart">
                <AtIcon icon="broken-heart" color="#808080" />
              </div>
              {strings.favorite.emptyFavText1}
              <br />
              {strings.favorite.emptyFavText2}
            </div>
          )}
        </div>
      </>
    )
  );
};

Favorites.propTypes = { activeNode: PropTypes.object };
Favorites.defaultProps = {
  activeNode: {
    id: 0
  }
};
export default Favorites;
