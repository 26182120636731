import React, { useState, useEffect } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiState, useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import { Button, H5, InputGroup, FormGroup } from "@blueprintjs/core";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import AppProvider from "../../../api/appProvider";
import { useDesignDetailState } from "../../../reducers/designdetails.reducer";
import { AtSpinner } from "../../atoms/AtSpinner";
import Fade from "../../higher-order-components/Fade";
import strings from "../../../strings";
//TODO: similar designs
const RenameDialog = () => {
  const designDetailState = useDesignDetailState();
  const [designName, setDesignName] = useState(designDetailState.designName);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const uiState = useUiState();

  const dispatchUiState = useUiDispatch();
  const handleCloseDialog = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_RENAME_DIALOG, payload: false });
  };
  useEffect(() => {
    setDesignName(designDetailState.designName);
  }, [designDetailState.designName]);
  const handleSave = e => {
    e.preventDefault();
    const { fullpath, designDetails } = designDetailState;
    setIsSaving(true);

    AppProvider.saveOnServer({
      file: fullpath,
      props: designDetails,
      name: designName
    })
      .then(() => {
        setIsSaving(false);
        handleCloseDialog();
        setTimeout(() => {
          dispatchUiState({
            type: mainUiActions.SET_TOAST_PROPS,
            payload: {
              message: strings.tools.designSaveSuccess,
              intent: "success"
            }
          });
        }, 500);
      })
      .catch(err => {
        setIsError(true);
        setIsSaving(false);
      });
  };
  return (
    <AtDialog
      onClose={handleCloseDialog}
      isOpen={uiState.showRenameDialog}
      className="at-confirmation-dialog"
      size="xs"
    >
      <div className="at-dialog-area">
        <Button
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon={<AtIcon icon="close"></AtIcon>}
        />
        <H5 className="at-dialog-heading">{strings.tools.renameDesign}</H5>
        <div className="at-dialog-content">
          <form onSubmit={handleSave}>
            <FormGroup>
              <InputGroup
                autoFocus
                onFocus={e => {
                  e.target.select();
                }}
                value={designName}
                onChange={e => {
                  setDesignName(e.target.value);
                }}
              />
            </FormGroup>
            <div className="at-confirmation-btns-area">
              <Fade show={isError}>
                <div className={` bp3-callout bp3-intent-danger`} style={{ marginBottom: "1rem" }}>
                  Could not save the design.
                </div>
              </Fade>
              <AtButton type="submit" disabled={isSaving} intent="primary">
                <AtSpinner size="xs" className={(isSaving ? "show " : "") + "signing-spinner "} />
                <span className={(isSaving ? "shift-right " : "") + "signin-span-text "}>
                  {isSaving ? "Saving" : "Save"}
                </span>
              </AtButton>
              <AtButton intent="danger" onClick={handleCloseDialog} text={strings.tools.cancel} />
            </div>
          </form>
        </div>
      </div>
    </AtDialog>
  );
};

export default RenameDialog;
