import React from "react";
import { errorTypes } from "../../../utils/utils";
import { H1 } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import strings from "../../../strings";
const ErrorTem = props => {
  const { title, content, buttonText, onButtonClick, ...otherProps } = props;
  return (
    <div id="at-error-page" {...otherProps}>
      {title && <H1 className="error-title">{title}</H1>}
      {content && <span className="error-body">{content}</span>}
      {buttonText && (
        <AtButton
          intent="primary"
          className="error-button"
          text={buttonText}
          onClick={onButtonClick}
        />
      )}
    </div>
  );
};
const ErrorPage = props => {
  const { errorType } = props;
  switch (errorType) {
    case errorTypes.INTERFACE_ERROR:
      return (
        <ErrorTem
          title="Oops..."
          content={
            <>
              It’s not you, it’s us. We’ve been notified of this issue.
              <br /> Please check again soon.
            </>
          }
          buttonText="Try Again"
          onButtonClick={() => window.location.reload()}
        />
      );
    case errorTypes.INVALID_LINK:
      return (
        <ErrorTem
          title="We’re sorry."
          content={<>{strings.errorPage.linkNotWorking}</>}
          buttonText="View other designs"
          onButtonClick={() => {
            window.sessionStorage.removeItem("catalogid");
            window.sessionStorage.removeItem("mode");
            window.location.reload();
          }}
        />
      );
    case errorTypes.NO_CONNECTION:
      return (
        <ErrorTem
          title="Lost connection"
          content="Your internet seems to be slow."
          buttonText="Reconnect"
          onButtonClick={() => window.location.reload()}
        />
      );
    case errorTypes.SESSION_TIMEOUT:
      return (
        <ErrorTem
          title="Welcome Back"
          content="We logged you out because you were away for a while."
          buttonText="Login to continue"
          onButtonClick={() => {
            sessionStorage.removeItem("apikey");
            sessionStorage.removeItem("page");
            sessionStorage.removeItem("relogin");
            window.location.reload();
          }}
        />
      );
    case errorTypes.URL_INCOMPLETE:
      return (
        <ErrorTem
          title="INCOMPLETE URL"
          content="Please mention the design to open."
          buttonText="Refresh"
          onButtonClick={() => window.location.reload()}
        />
      );
    default:
      return <ErrorTem />;
  }
};

export default ErrorPage;
