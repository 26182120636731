import React from "react";
import {
  updateNodeProps,
  expandTreeNode,
  updateSingleFileProp,
  findFolderofFile
} from "../utils/treeutils";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_TREE = "SET_TREE";
const UPDATE_NODE_FILES = "UPDATE_NODE_FILES";
const EXPAND_NODE = "EXPAND_NODE";
const COLLAPSE_NODE = "COLLAPSE_NODE";
const SELECT_DESIGN = "SELECT_DESIGN";
const SET_FAVORITES = "SET_FAVORITES";
const ADD_FAVORITE = "ADD_FAVORITE";
const UPDATE_FILE_PROP = "UPDATE_FILE_PROP";
const ADD_SIMILAR_DESIGN = "ADD_SIMILAR_DESIGN";
const SET_SIZE_FILTER = "SET_SIZE_FILTER";
const SET_TAG_FILTER = "SET_TAG_FILTER";
const SET_ACTIVE_VARIATION = "SET_ACTIVE_VARIATION";
const SET_ACTIVE_COLOR_SCHEME = "SET_ACTIVE_COLOR_SCHEME";
const SET_ACTIVE_SHAPE = "SET_ACTIVE_SHAPE";
const designListActions = {
  SET_TREE,
  UPDATE_NODE_FILES,
  EXPAND_NODE,
  COLLAPSE_NODE,
  SET_FAVORITES,
  ADD_FAVORITE,
  SELECT_DESIGN,
  UPDATE_FILE_PROP,
  ADD_SIMILAR_DESIGN,
  SET_SIZE_FILTER,
  SET_TAG_FILTER,
  SET_ACTIVE_VARIATION,
  SET_ACTIVE_COLOR_SCHEME,
  SET_ACTIVE_SHAPE
};
const initialDesignListState = {
  tree: null,
  selectedFile: {},
  selectedFolder: {},
  favorites: [],
  similarDesignsFolder: { children: [], files: [] },
  filteredTree: null,
  sizeFilter: [],
  tagFilter: '',
  activeShape: {},
  activeColorScheme: {},
  activeVariation: {}
};
const designListReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TREE:
      return setDesignTree(state, payload);
    case EXPAND_NODE:
      return changeNodeExpanded(state, payload, true);
    case COLLAPSE_NODE:
      return changeNodeExpanded(state, payload, false);
    case SELECT_DESIGN:
      return selectDesign(state, payload);
    case UPDATE_FILE_PROP:
      return updateFileProp(state, payload);
    case ADD_SIMILAR_DESIGN:
      return addSimilarDesign(state, payload);
    case SET_FAVORITES:
      return { ...state, favorites: action.payload };
    case ADD_FAVORITE:
      return { ...state, favorites: [...state.favorites, action.payload] };
    case UPDATE_NODE_FILES:
      return updateFilesinNode(state, payload);
    case SET_SIZE_FILTER:
      return { ...state, sizeFilter: payload };
    case SET_TAG_FILTER:
      return { ...state, tagFilter: payload };
    case SET_ACTIVE_COLOR_SCHEME:
      return { ...state, activeColorScheme: payload };
    case SET_ACTIVE_SHAPE:
      return { ...state, activeShape: payload };
    case SET_ACTIVE_VARIATION:
      return { ...state, activeVariation: payload };
    default:
      return state;
  }
};
//TODO: complete this function
function addSimilarDesign(state) {
  return state;
}
function updateFilesinNode(state, payload) {
  const tree = updateNodeProps(state.tree, payload, "files");
  return { ...state, tree };
}
function updateFileProp(state, payload) {
  const tree = updateSingleFileProp(state.tree, payload);
  return { ...state, tree };
}
function selectDesign(state, payload) {
  let similarDesignsFolder = { children: [], files: [] };
  let selectedFile;
  let selectedFolder = { ...state.selectedFolder };
  //if payload contains selectdFile as an object
  if (payload.selectedFile) {
    selectedFile = { ...payload.selectedFile };
  } else {
    selectedFile = payload;
  }
  //if payload contains active variation
  let activeVariation = { ...selectedFile };

  if (payload.activeVariation) {
    activeVariation = payload.activeVariation;
  }
  //if payload contains active color scheme
  let activeColorScheme_ = state.activeColorScheme;
  if(payload.activeColorScheme){
    activeColorScheme_ = payload.activeColorScheme;
  }


  //if the selected node is not in selected folder, find and change the selected folder
  if (state.selectedFolder.files) {
    const fileinselectedFolder = state.selectedFolder.files.find(
      item => item.id === selectedFile.id
    );
    if (!fileinselectedFolder) {
      selectedFolder = findFolderofFile(state.tree, selectedFile) || {};
    }
  }
  else{
    selectedFolder =  state.tree ? findFolderofFile(state.tree, selectedFile) || {}: {};
  }
  //find the similar designs for the selected design
  if (selectedFolder.children && selectedFolder.children.length) {
    const similarDesignsCandidates =
      selectedFolder.children.filter(item => item.name.charAt(0) === ".") || [];
    if (similarDesignsCandidates.length) {
      const condition = item =>
        item.name.substr(1).toLowerCase() === selectedFile.name.toLowerCase();
      similarDesignsFolder = similarDesignsCandidates.find(condition) || {
        children: [],
        files: []
      };
    }
  }
  return { ...state, selectedFile, similarDesignsFolder, selectedFolder, activeVariation, activeColorScheme:activeColorScheme_ };
}
function changeNodeExpanded(state, payload, isExpanded) {
  let newNode;
  const { node, collapseOthers,changeSelectedFolder=true } = payload;
  if (node) {
    newNode = { ...node, isExpanded };
  } else {
    newNode = { ...payload, isExpanded };
  }
  let newTree;
  if (!!isExpanded) {
    newTree = expandTreeNode(state.tree, newNode, collapseOthers);
    return { ...state, tree: newTree, selectedFolder: changeSelectedFolder?newNode:state.selectedFolder };
  } else {
    newTree = updateNodeProps(state.tree, newNode, "isExpanded");
    return { ...state, tree: newTree, selectedFolder: changeSelectedFolder?newNode:state.selectedFolder };
  }
}
function setDesignTree(state, payload) {
  const { selectedFolder, selectedFile, tree } = payload;
  if (selectedFolder && selectedFile) {
    const similarDesignsCandidates =
      selectedFolder.children.filter(item => item.name.charAt(0) === ".") || [];
    const similarDesignsFolder = similarDesignsCandidates.find(
      item => item.name.substr(1).toLowerCase() === selectedFile.name.toLowerCase()
    ) || { children: [], files: [] };

    return { ...state, selectedFolder, selectedFile, tree, similarDesignsFolder };
  }
  return { ...state, tree };
}
function DesignListStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(designListReducer, initialDesignListState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useDesignListState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchDesignList() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export { DesignListStateProvider, useDesignListState, useDispatchDesignList, designListActions };
