import React from 'react';
import { useDesignListState } from '../../../reducers/designlist.reducer';
import { useDispatchVisualization, useVisualizationState, visualizationsActions, visViewModes } from '../../../reducers/visualizations.reducer';
import strings from '../../../strings';
import Thumbnail from '../Thumbnail';

const DesignTopViewThumb = props => {
  const visState = useVisualizationState();
  const dispatchVisState = useDispatchVisualization();
  const designListState = useDesignListState();
  const { selectedFile } = designListState;

  return (
    <Thumbnail
    thumb={{
      ...selectedFile,
      name: strings.designViews.deepZoom
    }}
    aspect="landscape"
    active={visState.viewMode === visViewModes.DESIGN_VIEW_DEEPZOOM}
    onThumbnailClick={() => {
      dispatchVisState({
        type: visualizationsActions.SET_VIEW_MODE,
        payload: visViewModes.DESIGN_VIEW_DEEPZOOM
      });
    }}
  />
  );
};

DesignTopViewThumb.propTypes = {
  
};

export default DesignTopViewThumb;