import React, { useState, useEffect } from "react";
import classNames from "classnames";
import AtButton from "./../../../atoms/AtButton";
import { Slider, H4 } from "@blueprintjs/core";
import AudioPlayer from "./../AudioPlayer";
import { defaultMusicArr } from "./../AudioPlayer/constants";

const BottomController = props => {
  const { handlePlay, handleSettings,startPlay, handleDuration, pause } = props;
  const [play, setplay] = useState(false);
  const [sliderVal, setsliderVal] = useState(10);

  useEffect(() => {
    handlePlay && handlePlay(play);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play]);

  useEffect(() => {
    if (startPlay) {
      setplay(true);
    }
  }, [startPlay]);

  useEffect(() => {
    if (play === pause) {
      setplay(!pause);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pause]);

  const onSliderChange = val => {
    setsliderVal(val);
    handleDuration(val);
  };

  const playBtnClass = classNames("play-btn", { "btn-paused": !play });
  const panelWrapperClass = classNames("bottompanel-wrapper hideOnIdle", { hide: !startPlay });

  return (
    <div className="at-cinematic-bottompanel">
      <div className={panelWrapperClass}>
        <div className="bottompanel-wrapper__left-area">
          {/* <Checkbox
            defaultVal={true}
            inline
            label="Show Logo"
            onChange={handleLogo}
            className={"logo-checkbox"}
          /> */}
          <div className={"sliderBlock"}>
            <H4>Animation Duration</H4>
            <span className={"right"}>{`${sliderVal}s`}</span>
            <Slider
              className="animation-duration-slider"
              onChange={onSliderChange}
              value={sliderVal}
              labelStepSize={10}
              labelRenderer={true}
              max={16}
              min={6}
            />
            <span className={"left sub"}>6</span>
            <span className={"right sub"}>16</span>
          </div>
        </div>
        <div className="bottompanel-wrapper__center-area">
          <div onClick={() => setplay(play => !play)} className={"play-btn-circular"}>
            <div className={playBtnClass}></div>
          </div>
          {/* <CircularButton onClick={() => setplay(play => !play)} className="play-btn-circular">
            <div className={playBtnClass}></div>
          </CircularButton> */}
        </div>
        <div className="bottompanel-wrapper__right-area">
          <AudioPlayer audioArr={[...defaultMusicArr]} startPlay={startPlay && play} />

          <AtButton icon={"settings"} onClick={handleSettings}></AtButton>
        </div>
      </div>
    </div>
  );
};

export default BottomController;
