import React from "react";
import AtButton from "../../atoms/AtButton";
import { AtSpinner } from "../../atoms/AtSpinner";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import strings from "../../../strings";
import { H6 } from "@blueprintjs/core";
import { useUiDispatch, mainUiActions } from "../../../reducers/mainui.reducer";
import AppProvider from "../../../api/appProvider";

const OrdersheetButton = props => {
  const { text = strings.ordersheet.placeOrder, ...otherProps } = props;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const { customFields } = designDetailState;
  const { loading } = customFields;
  const dispatchUiState = useUiDispatch();
  const handleOpenOrdesheet = e => {
    if (customFields.fields) {
      setOpenOrderSheet(true);
      return;
    }
    setCustomFieldsLoading(true);
    AppProvider.fetchCustomFieldOrdersheet()
      .then(data => {
        setCustomFieldsLoading(false);
        setCustomFields(data);
        setOpenOrderSheet(true);
      })
      .catch(err => {})
      .then(() => {
        setCustomFieldsLoading(false);
      });
  };
  const setCustomFieldsLoading = value => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS_LOADING, payload: value });
  };
  const setCustomFields = fields => {
    dispatchDesignDetails({ type: designDetailActions.SET_CUSTOM_FIELDS, payload: fields });
  };
  const setOpenOrderSheet = show =>
    dispatchUiState({ type: mainUiActions.SET_SHOW_ORDERSHEET, payload: show });

  return (
    <AtButton
      id="at-ordersheetbtn"
      intent="primary"
      tertiary={
        window.sessionStorage.getItem("mode") === "stripedesign" || window.flags.showAddToCart
      }
      onClick={handleOpenOrdesheet}
      {...otherProps}
    >
      <AtSpinner size="xs" className={(loading ? "show " : "") + "signing-spinner "} />
      <H6 className={(loading ? "shift-right " : "") + "signin-span-text "}>{text}</H6>
    </AtButton>
  );
};

export default OrdersheetButton;
