import React, { useEffect, useState } from "react";
import AtDialog from "../../molecules/AtDialog";
import { useUiDispatch, mainUiActions, useUiState } from "../../../reducers/mainui.reducer";
import AtButton from "../../atoms/AtButton";
import { H5, InputGroup, ProgressBar } from "@blueprintjs/core";
import ImageDropContainer from "../ImageDropContainer";
import ColorThief from "colorthief";
import { getPomUrl, rgbToHex, sortByKnots } from "../../../utils/colorutils";
import classNames from "classnames";
import { readImage } from "../../../utils/domUtils";
import AppProvider from "../../../api/appProvider";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { useColorListState } from "../../../reducers/colors.reducer";

const colorThief = new ColorThief();
const state = {
  Preload: 0,
  Loading: 1,
  AfterLoad: 2
};
const ImageInputDialog = ({ portalContainerId = "app-main" }) => {
  const dispatchUiState = useUiDispatch();
  const designDetailsState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const colorListState = useColorListState();
  const uiState = useUiState();
  const [isOpen, setIsOpen] = useState(uiState.showImageInputDialog);
  const [imageUrl, setImageUrl] = useState("");
  const [currentState, setCurrentState] = useState(state.Preload);
  const [palette_hex, setPalette_hex] = useState([]);
  const [showColorSwatchBg, setShowColorSwatchBg] = useState(true);
  const [progressBarValue, setProgressBarValue] = useState(0.2);
  const [lastOpenedImage, setLastOpenedImage] = useState(null);
  const [newColorPalette, setNewColorPalette] = useState(null);

  const { designDetails } = designDetailsState;
  const { filteredCollection, collections } = colorListState;
  const handleCloseDialog = () => {
    setIsOpen(false);
    dispatchUiState({ type: mainUiActions.SET_SHOW_IMAGE_INPUT_DIALOG, payload: false });
  };

  useEffect(() => {
    setIsOpen(uiState.showImageInputDialog);
    if (!uiState.showImageInputDialog) return;
    if (lastOpenedImage) {
      loadImageGetPalette(lastOpenedImage);
    }
  }, [uiState.showImageInputDialog]);

  const applycolors = result => {
    if (!result) return;
    var colorPalette = sortByKnots(result, designDetails.DesignColors);
    console.log("colorPalette", colorPalette);
    dispatchDesignDetails({
      type: designDetailActions.CHANGE_COLOR_PALETTE,
      payload: colorPalette
    });
  };
  const getColorsfromImage = myImage => {
    const colorsNum = designDetails.DesignColors?.length;
    const palette = colorThief.getPalette(myImage, colorsNum);
    console.log(palette);

    if (palette) {
      const unmapped_paletteHex = palette.map(color => rgbToHex([...color]));
      var pipeSeparatedColorsList = "";
      for (let i = 0; i < unmapped_paletteHex.length; i++) {
        pipeSeparatedColorsList += unmapped_paletteHex[i].replace("#", "") + "|";
      }
      pipeSeparatedColorsList = pipeSeparatedColorsList.slice(0, -1);
      const colorTab = filteredCollection?.Name || collections[0]?.Name;
      AppProvider.mapColorsList({ pipeSeparatedColorsList, colorTab }).then(res => {
        if (res) {
          setNewColorPalette(res);
          const mapped_paletteHex = res.map(color => color.Value.replace("#", "")); // res.split("|");
          setPalette_hex(mapped_paletteHex);
        }
      });
    }
  };
  const loadImageGetPalette = url => {
    setProgressBarValue(0.5);

    readImage(url)
      .then(myImage => {
        setProgressBarValue(1);

        setCurrentState(state.AfterLoad);
        setLastOpenedImage(myImage.src);
        getColorsfromImage(myImage);
      })
      .catch(() => {});
  };
  const onImageChange = imageFile => {
    setProgressBarValue(0);

    if (!imageFile) return;
    setCurrentState(state.Loading);
    console.time();
    var reader = new FileReader();
    reader.readAsDataURL(imageFile);

    reader.onloadend = function(e) {
      loadImageGetPalette(e.target.result);
    };
  };
  const uploadImageFromUrl = () => {
    if (imageUrl) loadImageGetPalette(imageUrl);
  };
  return (
    <AtDialog
      portalContainer={document.getElementById(portalContainerId)}
      onClose={handleCloseDialog}
      isOpen={isOpen}
      size="sm"
      className="at-file-input-dialog"
    >
      <div className="at-dialog-area at-file-input-dialog-area">
        <AtButton
          className="at-close-dialog-button"
          onClick={handleCloseDialog}
          minimal
          icon="close"
        />
        <H5 className="at-dialog-heading">{"Extract colors from image"}</H5>
        <div className="at-dialog-content">
          {!currentState && (
            <>
              <div className="image-input-url">
                <label className="bp3-label">Load image from URL:</label>
                <InputGroup
                  value={imageUrl}
                  autoComplete="on"
                  onChange={e => {
                    setImageUrl(e.target.value);
                  }}
                />
                <AtButton minimal intent="primary" onClick={uploadImageFromUrl}>
                  Upload
                </AtButton>
              </div>

              <ImageDropContainer
                onImageChange={onImageChange}
                html={"Click here to upload <br/>or<br/> Drag and Drop your image file here"}
              />
            </>
          )}
          {currentState === 1 && (
            <div>
              <label className="bp3-label">Loading your image: </label>
              <ProgressBar
                value={progressBarValue}
                stripes={false}
                className="brand-progress-bar"
              />
            </div>
          )}

          {currentState === 2 && (
            <div className="after-image-load-area">
              <div className="image-palette-wrapper">
                <div className="uploaded-image">
                  {lastOpenedImage && <img src={lastOpenedImage} alt="uploaded" />}
                </div>
                <div className="palette-colors">
                  <div className="palette-from-image">
                    {palette_hex.length &&
                      palette_hex.map((paletteColor, index) => (
                        <div
                          key={index}
                          className={classNames(
                            "color-pie__color",
                            {
                              "no-background": !showColorSwatchBg
                            },
                            {
                              "hide-color-pom": showColorSwatchBg
                            }
                          )}
                          style={{
                            backgroundColor: paletteColor
                          }}
                        >
                          <img
                            className={classNames(`color-pom-image`)}
                            alt="color pom"
                            src={getPomUrl(paletteColor, 0)}
                            onError={({ currentTarget }) => {
                              setShowColorSwatchBg(true);
                            }}
                            onLoad={() => setShowColorSwatchBg(false)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="image-palette-footer-area">
                <AtButton intent="primary" onClick={() => applycolors(newColorPalette)}>
                  Apply
                </AtButton>
                <AtButton intent="tertiary" onClick={() => setCurrentState(state.Preload)}>
                  Load new image
                </AtButton>
                <AtButton intent="tertiary" onClick={handleCloseDialog}>
                  Cancel
                </AtButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </AtDialog>
  );
};

export default ImageInputDialog;
