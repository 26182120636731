import React from "react";
// import PropTypes from "prop-types";
import { Classes } from "@blueprintjs/core";
import TreeNode from "./TreeNode";
import classNames from "classnames";
const Tree = props => {
  const {
    treeContent,
    onNodeClick,
    onNodeCollapse,
    onThumbnailClick,
    onNodeExpand,
    className
  } = props;
  const renderNodes = (treeNodes, level) => {
    if (!treeNodes || !treeNodes.length) {
      return null;
    }
    const nodeItems = treeNodes
      .filter(node => node.children.length || node.files.length)
      .map((node, index) => {
        return (
          node.name.charAt(0) !== "." && (
            <TreeNode
              key={index}
              node={node}
              onClick={e => onNodeClick(node, e)}
              onCollapse={e => onNodeCollapse(node, e)}
              onExpand={e => onNodeExpand(node, e)}
              onThumbnailClick={onThumbnailClick}
              {...props}
            >
              {renderNodes(node.children, node.level)}
            </TreeNode>
          )
        );
      });
    return (
      <ul className={classNames(Classes.TREE_NODE_LIST, `${Classes.TREE_NODE_LIST}-${level}`)}>
        {nodeItems}
      </ul>
    );
  };
  return (
    <div className={classNames(Classes.TREE_NODE, className)}>{renderNodes(treeContent, 0)}</div>
  );
};

Tree.propTypes = {};

export default Tree;
