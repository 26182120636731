import { Menu, MenuItem, Popover } from "@blueprintjs/core";
import React from "react";
import { mainUiActions, useUiDispatch } from "../../../reducers/mainui.reducer";
import strings from "../../../strings";
import AtButton from "../../atoms/AtButton";

const SaveOnServer = props => {
  const dispatchUiState = useUiDispatch();
  
  const handleSaveAsCopy = () => {
    dispatchUiState({ type: mainUiActions.SET_SHOW_RENAME_DIALOG, payload: true });
  };
  const handleReplaceOriginal = () => {
    dispatchUiState({
      type: mainUiActions.SET_CONFIRMATION_DIALOG,
      payload: true
    });
  };

 
  return (
    <Popover
      minimal
      position="top-left"
      portalContainer={document.getElementById("app-main")}
      interactionKind="hover"
      content={
        <Menu>
          <MenuItem text={strings.tools.saveAndCopyOnServer} onClick={handleSaveAsCopy} />
          <MenuItem text={strings.tools.saveAndReplaceOnserver} onClick={handleReplaceOriginal} />
        </Menu>
      }
    >
      <AtButton intent="primary" tertiary text={strings.tools.saveOnServer}></AtButton>
    </Popover>
  );
};

SaveOnServer.propTypes = {};

export default SaveOnServer;
