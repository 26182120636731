import React, { useEffect, useState } from "react";
import AtRadio from "../../atoms/AtRadio";
import {
  useDispatchordersheetDetail,
  ordersheetDetailActions,
  useOrdersheetDetailState
} from "../../../reducers/ordersheetdetails.reducer";

const AllInOneMatSelection = props => {
  const dispatchOrdersheetDetail = useDispatchordersheetDetail();
  const ordersheetDetailState = useOrdersheetDetailState();

  const { groupLabel = "", handleChange, selectedValue, radioLabels, radioValues, formFieldAssociated='' } = props;


 // const selectedAllinOneMat = ordersheetDetailState.accountSpecific.allInOneMaterialSelectionValue;
     
  const getValueFromFormField = ()=>{
    if(formFieldAssociated ==='') return '';
    if(ordersheetDetailState.formFields && ordersheetDetailState.formFields.fields&& ordersheetDetailState.formFields.fields.length>0){
      const formfield = ordersheetDetailState.formFields.fields.filter((formfield)=> formfield.fieldName.toLowerCase()===  formFieldAssociated.toLowerCase());
      return formfield[0].fieldValue ||'';
    }
    else return "";
  }
  const chosenVal = getValueFromFormField();
  let selectedAllinOneMat = chosenVal ===''? 
  ordersheetDetailState.accountSpecific.allInOneMaterialSelectionValue:  chosenVal;
 
 
  const [RugOption, setRugOption] = useState(
    selectedAllinOneMat === "" ? selectedValue || radioValues[0] : selectedAllinOneMat
  );

  const onChange = event => {
    setRugOption(event.currentTarget.value);
    dispatchOrdersheetDetail({
      type: ordersheetDetailActions.SET_MATERIAL_SELECTIONVALUE,
      payload: event.currentTarget.value
    });
    if (handleChange) handleChange(event.currentTarget.value);
  };
  useEffect(() => {
    if (!selectedAllinOneMat || selectedAllinOneMat === "")
      dispatchOrdersheetDetail({
        type: ordersheetDetailActions.SET_MATERIAL_SELECTIONVALUE,
        payload: selectedValue || radioValues[0]
      });
  }, [radioValues]);
  return (
    <div>
      <AtRadio
        groupLabel={groupLabel}
        inline={true}
        onChange={onChange}
        selectedValue={RugOption}
        radioLabels={radioLabels}
        radioValues={radioValues}
      />
    </div>
  );
};

AllInOneMatSelection.propTypes = {};

export default AllInOneMatSelection;
