import uuid from "uuid";
import HttpClient from "./httpClient";
import { readJSON, convertTilePointToName, makeid } from "../utils/utils";
import { loadCssFile, readImage, getJsonFromUrl } from "../utils/domUtils";
import { createCanvas } from "../utils/canvasutils";
import { MD5 } from "../utils/md5";
import { createUriSafe } from "../utils/utils";

export const build = "v3";

const LOCAL_SERVER = "http://localhost:61960";
const REMOTE_SERVER = `https://${build}.explorug.com`;
const isLocalEnv = process.env.REACT_APP_LOCAL_SERVER === "true";
export const domain = isLocalEnv ? LOCAL_SERVER : REMOTE_SERVER;
export const assetsDomain =
  build === "v3" && domain === REMOTE_SERVER
    ? "https://assets.explorug.com" //"https://d1tvaiszosdaib.cloudfront.net"
    : `${domain}/Assets`;
export const CDN_domain =
  process.env.PUBLIC_URL.trim() !== "" ? process.env.PUBLIC_URL + "/" : "./";
export const CDN_Images_Domain = "https://images.explorug.com";
//'./';
// build === "v3" && domain === REMOTE_SERVER
//   ? "https://cdn.explorug.com/explorug/v3RC/" //"https://d1tvaiszosdaib.cloudfront.net"
//   : `./`;
//const cdn = process.env.PUBLIC_URL
//console.log("process.env", process.env.PUBLIC_URL, "cdn", CDN_domain, "assetsDomain", assetsDomain);

export const pomsUrlPrefix = "https://v3.explorug.com/permanentcache/colorpoms/";
export const pomsUrlPrefixPaulig = "https://v3.exploRUG.com/Files/Customers/Paulig/colors/";
const myroomServerUrl = "https://www.myownrug.com/segment_image";
const errorReportDomain = "https://alternative.com.np/SendErrorReport.php";
const s3ServerRoomViewBucketUrl = "https://explorug.com/archanastools/awsupload/default.aspx";
export const myrugStudioSaveUrl = "https://explorug.com/archanastools/Utilities/";
let provider = "appproviderv3.aspx";
const API_KEY = "apikey";

export const sessKeys = {
  API_KEY: API_KEY,
  PAGE: "page",
  RELOGIN: "relogin",
  INIT_DESIGN: "initdesign",
  INIT_VIEW: "initview",
  INIT_SHOT: "initshot",
  MODE: "mode",
  CAT_ID: "catalogid",
  DES_COLORS: "designcolors",
  ORIG_BORDER_DESIGN: "origborderdesign"
};

let customDesignUrlMemory = {};
let blobMemory = {};

let apikey;
let cacheLocation = "";
export const isDevlopmentEnv =
  process.env.NODE_ENV === "development" || domain === LOCAL_SERVER || build !== "v3";
const getCacheLocationFromUrl = url => url.split("/")[2];
const processPath = (path, thumbFromCDN = true) => {
  const s = path.split("/").map(encodeURIComponent);
  if (s[1] === "Assets" && thumbFromCDN) {
    const ss = s.slice(2);
    return `${assetsDomain}/${ss.join("/")}`;
  } else {
    return `${domain}${createUriSafe(path)}`;
  }
};
export const getIcons = () => {
  HttpClient.get(CDN_domain + "v3assets/symbol-defs.svg").then(response => {
    window.explorugIcons = response.data;
    return response.data;
  });
};
const getPhysicalLocation = () =>
  HttpClient.get("https://ip.alternative.com.np/get/alita").then(response => {
    const { city, country_name, ip } = response.data;
    return { city, country_name, ip };
  });
const postErrorReport = (error, data, isMyroom = false) => {
  // console.log("stack -> error", error.stack)
  // console.log("postErrorReport -> error.message", error.name, error.message)
  // console.log("respoonse ", error.response)
  const sendError = !error.response || error.response.status === 500;
  if (!isDevlopmentEnv && sendError) {
    const params = new URLSearchParams();
    const pageJSON = getPageName();
    var page = pageJSON.page || pageJSON.username;
    let dataString = `<br/>page: ${page}`;
    const postData = (city = "") => {
      for (let pair of data.entries()) {
        dataString = `${dataString}<br/>${pair[0]}: ${pair[1]}`;
      }
      if (!isMyroom) {
        const parsedResponse = error.response.data.replace(
          "Description: </b>",
          `Description: </b>${dataString}<br/>`
        );
        params.append("MessageIs", parsedResponse.replace(/#/g, "_"));
      } else {
        params.append("Error", "Couldn't load myroom image <br/> " + dataString + "<br/>");
        if (error.message) params.append("Message", error.message);
        if (error.stack) params.append("ErrorStack", error.stack);
      }
      params.append("Subject", `explorug error - ${page} - ${city}`);
      HttpClient.post(errorReportDomain, params);
    };
    var refererUrl =
      window.location !== window.parent.location ? document.referrer : document.location.href;
    //console.log(refererUrl);
    let TopReferer = "";
    try {
      const topUrl = window.top.location.href;
      if (topUrl) TopReferer = topUrl;
    } catch (error) {
      console.error(error);
    }
    getPhysicalLocation()
      .then(({ city, country_name, ip }) => {
        dataString = `${dataString}<br/>city: ${city}`;
        dataString = `${dataString}<br/>country name: ${country_name}`;
        dataString = `${dataString}<br/>ip address: ${ip}`;
        dataString = `${dataString}<br/>Referer: ${refererUrl}`;
        if (TopReferer !== "") dataString = `${dataString}<br/>Topmost Referer: ${TopReferer}`;

        postData(city);
      })
      .catch(err => {
        postData();
      });
  }
};

export const getPageName = () => {
  let page, username;
  let relogin = true;
  page = getJsonFromUrl().page;
  if (!page) {
    page = sessionStorage.getItem("page");
    username = sessionStorage.getItem("username");
    relogin = sessionStorage.getItem("relogin");
  }
  return { page, relogin, username };
};
const getApiKey = () => {
  if (!apikey) apikey = sessionStorage.getItem(API_KEY);
  return apikey;
};

const postHttpClient = (data, config, sendErrorReport = true) => {
  return new Promise((resolve, reject) => {
    HttpClient.post(`${domain}/${provider}`, data, config)
      .then(response => resolve(response.data))
      .catch(error => {
        if (sendErrorReport) postErrorReport(error, data);
        reject(error);
      });
  });
};
const postWithRetry = (data, numOfRetries = 5) => {
  return new Promise((resolve, reject) => {
    let numtries = 0;
    const fetchData = () => {
      postHttpClient(data, {}, false)
        .then(resolve)
        .catch(error => {
          numtries++;
          if (numtries <= numOfRetries) fetchData();
          else {
            postErrorReport(error, data);
            reject(error);
          }
        });
    };
    fetchData();
  });
};

const fetchPdfForRugStudio = ({
  roomPath,
  description,
  qrurl,
  roomName,
  room,
  isWatermark = false,
  paypalName,
  paypalEmail,
  purchasedDate
}) => {
  let data = new FormData();
  data.append("roomPath", roomPath);
  data.append("description", description);
  data.append("qrurl", qrurl);
  data.append("roomName", roomName);
  data.append("room", room);
  data.append("isWatermark", isWatermark);
  data.append("paypalName", paypalName);
  data.append("paypalEmail", paypalEmail);
  data.append("purchasedDate", purchasedDate);
  return new Promise((resolve, reject) => {
    HttpClient.post(myrugStudioSaveUrl + "MyRugSave.aspx", data).then(response =>
      resolve(response.data)
    );
  });
};

const sendEmailMyRugStudio = ({
  file,
  qrurl,
  roomName,
  isWatermark,
  paypalName,
  paypalEmail,
  purchasedDate
}) => {
  let data = new FormData();
  data.append("mode", "email");
  data.append("file", file);
  data.append("qrurl", qrurl);
  data.append("roomName", roomName);
  data.append("isWatermark", isWatermark);
  data.append("paypalName", paypalName);
  data.append("paypalEmail", paypalEmail);
  data.append("purchasedDate", purchasedDate);
  return new Promise((resolve, reject) => {
    HttpClient.post(myrugStudioSaveUrl + "MyRugSave.aspx", data).then(response =>
      resolve(response.data)
    );
  });
};

const fetchApiKey = ({ username, password, encrypted = false }) => {
  let data = new FormData();
  data.append("action", "login");
  data.append("username", username);
  data.append("password", password);
  if (encrypted) {
    data.append("encrypted", encrypted);
  }
  return new Promise((resolve, reject) => {
    postWithRetry(data)
      .then(res => {
        const key = res.Key;
        if (!key) reject("INVALUD CREDENTIALS");
        else {
          sessionStorage.setItem("username", username);
          sessionStorage.setItem(API_KEY, key);
          sessionStorage.setItem("relogin", false);
          sessionStorage.setItem("multiplelogin", true);
          resolve(key);
        }
      })
      .catch(reject);
  });
};
const autoLogin = () => {
  const { page, relogin } = getPageName();
  const { forcelogin } = getJsonFromUrl();
  const username = sessionStorage.getItem("username") || "";
  const password = sessionStorage.getItem("password") || "";

  return new Promise((resolve, reject) => {
    if (forcelogin) {
      reject("NO_LOGIN_PAGE");
      return;
    }
    if (JSON.parse(relogin) && page && page !== "undefined") {
      const url = `${domain}/login/app${page}.aspx`;
      HttpClient.post(url)
        .then(response => {
          if (!response.data) {
            reject("INVALID_CREDENTIALS");
            return;
          }
          if (!response.data?.Key || response.data.Key === "null") {
            reject("INVALID_CREDENTIALS");
            console.log("key is null");
            return;
          }
          sessionStorage.setItem("relogin", false);
          sessionStorage.setItem(API_KEY, response.data.Key);
          sessionStorage.setItem("multiplelogin", false);
          resolve(response.data);
          return;
        })
        .catch(reject);
    } else if (username && username !== "" && password && password !== "") {
      fetchApiKey(username, password, true)
        .then(key => {
          return;
        })
        .catch(reject("NO_LOGIN_PAGE"));
    } else {
      const key = sessionStorage.getItem(API_KEY);
      if (key) resolve(key);
      else reject("NO_LOGIN_PAGE");
      return;
    }
  });
};
const fetchInterfaceElements = () => {
  let data = new FormData();
  data.append("action", "interfaceelements");
  data.append("key", getApiKey());
  return postHttpClient(data).then(interfaceElements => {
    const { InterfaceElements, TextureOptions } = interfaceElements;
    if (!InterfaceElements || !TextureOptions) {
      return { SessionTimeout: true };
    }
    window.InterfaceElements = InterfaceElements;
    window.TextureOptions = TextureOptions;

    const { LogoUrl } = InterfaceElements;
    cacheLocation = getCacheLocationFromUrl(LogoUrl);
    if (InterfaceElements.CustomizeCssUrl && InterfaceElements.CustomizeCssUrl !== "") {
      loadCssFile({ cssUrl: `${domain}${InterfaceElements.CustomizeCssUrl}` });

      if (process.env.NODE_ENV === "development") {
        loadCssFile({ cssUrl: `./temp/Style.css` });
      }
    }
    if (!InterfaceElements.CustomizeJsUrl || InterfaceElements.CustomizeJsUrl === "") {
      return { ...interfaceElements, flags: {} };
    }
    return HttpClient.get(`${domain}${InterfaceElements.CustomizeJsUrl}?t=${makeid(8)}`)
      .then(flags => {
        return { ...interfaceElements, flags: flags.data };
      })
      .catch(err => {
        return { ...interfaceElements, flags: {} };
      });
  });
  // return postHttpClient(data)
};
const isAuthenticated = () => {
  if (sessionStorage.getItem(API_KEY)) return true;
  else return false;
};
const fetchDesignList = (params = {}) => {
  const { struct } = params;
  let data = new FormData();
  data.append("action", "designlist");
  data.append("key", getApiKey());
  if (struct) {
    data.append("mode", "struct");
  }
  return new Promise((resolve, reject) => {
    let numtries = 0;
    post();
    function post() {
      postHttpClient(data)
        .then(resolve)
        .catch(err => {
          if (err.code === "ECONNABORTED" && numtries < 5) {
            numtries++;
            post();
          } else {
            reject(err);
          }
        });
    }
  });
};
const fetchColorList = params => {
  let data = new FormData();
  data.append("action", "colorlist");
  data.append("key", getApiKey());
  return postHttpClient(data);
};

const createColorPoms = color => {
  let data = new FormData();
  data.append("action", "colorpom");
  data.append("key", getApiKey());
  data.append("color", color);
  return postHttpClient(data);
};

const fetchRoomList = params => {
  let data = new FormData();
  data.append("action", "roomlist");
  data.append("key", getApiKey());
  return postHttpClient(data);
};

const fetchCustomSizedDesign = ({ widthRatio, heightRatio, file, newname }) => {
  let data = new FormData();
  data.append("action", "customsize");
  data.append("key", getApiKey());
  data.append("sx", widthRatio);
  data.append("sy", heightRatio);
  data.append("file", file);
  data.append("newname", newname);
  return postHttpClient(data);
};

const fetchDesignTiles = ({ file, zoom = 2, tiles, props, hash, felt = 0 }) => {
  felt = felt ? 1 : 0;
  let data = new FormData();
  data.append("action", "designtiles");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("felt", felt);
  if (window.flags.accountSpecific.isPaulig) {
    data.append("paulig", 1);
  }
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));

  return postWithRetry(data).then(processPath);
};

const fetchPileTiles = ({ file, zoom = 2, tiles, props, hash }) => {
  let data = new FormData();
  data.append("action", "piletiles");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));
  return postWithRetry(data).then(processPath);
};
const fetchVisualizationTiles = ({ file, zoom, tiles, props, felt = 0 }) => {
  felt = felt ? 1 : 0;
  let data = new FormData();
  data.append("action", "visualizationtiles");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("felt", felt);
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  if (window.flags.visualizations.setPileAngleInVisualizationTiles != null) {
    data.append("pileangle", window.flags.visualizations.setPileAngleInVisualizationTiles);
  }
  if (window.flags.accountSpecific.isPaulig) {
    data.append("paulig", 1);
  }
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));
  //return postHttpClient(data).then(processPath);
  return postWithRetry(data, 3).then(processPath);
};
const fetchTileDetails = ({ file, backColor = "" }) => {
  let data = new FormData();
  data.append("action", "tiledetails");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("backcolor", backColor);
  if (window.InterfaceElements.IsJpeg) {
    return Promise.resolve({
      tileTransparency1: [],
      tileTransparency2: [],
      tileTransparency4: [],
      colorTileData1: [],
      colorTileData2: [],
      colorTileData4: []
    });
  }
  return postHttpClient(data).then(tilePaths => {
    const {
      ColorTiles1X,
      ColorTiles2X,
      ColorTiles4X,
      TileTransparency1X,
      TileTransparency2X,
      TileTransparency4X
    } = tilePaths;
    // const arr = decode("H4sIAAAAAAAEAO3RsXEFIQxFUUpXaSpFJRASMNiBx/67QuiyQ+h96Ung6uvr3bt37969+3cb4A3cwAW85NzBK7iCS+6j5N7ADVzBS+4dvIEbuIKX3Dt4AzdwBS+5D/AGbuAKLrn/PG/tHbyCG7iCl9wHeAOv4AYu4CX3Ad7AK7iBK3jJfYB38Apu4AouuX+eH3sHb+AV3MAFvOQ+wDt4A6/gBi7gJfcB3sEbeAU3cAUX8JJ7B2/gFdzAFVzAS+4DvIM38Apu4Aou4CX3Ad7BG3gFN3A9dAEvufs83n0e7z6Pd5/Hu8/j3X/fu/++d88PfcrjfMrjfMrjfMrj3MAVXA594rvPee4+57n7nOfuc55nbuAKLuAzP/Ig382DfDcP8j3yIN/NDVzB5dADvnqU7+pRvice5bt6lO/qdugKLoce8cXDvBcP8z3wMO/Fw7wP3MAVXA495H2P8388zrvvcf6Px3n33cD10OXQY/7zRf5tX+T/80X+bV/k33YD10OXQ1/wrq/O8+ur8+z66jy7vjrPrhu4Hroc+oo3fXm+8g1Ue2ogACAAAA==")
    // const arr1 = new Uint8Array(arr)
    // console.log(arr1)
    // console.log(arr)
    // var output = pako.inflate(arr1);
    // console.log(output)
    // const op = toBits(output)
    // console.log(op)
    let promises = [
      readJSON(`${domain}${createUriSafe(ColorTiles1X)}`),
      readJSON(`${domain}${createUriSafe(ColorTiles2X)}`),
      readJSON(`${domain}${createUriSafe(ColorTiles4X)}`)
    ];
    const isIrregular = TileTransparency1X && TileTransparency1X !== "";
    if (isIrregular) {
      promises.push(
        readJSON(`${domain}${createUriSafe(TileTransparency1X)}`),
        readJSON(`${domain}${createUriSafe(TileTransparency2X)}`),
        readJSON(`${domain}${createUriSafe(TileTransparency4X)}`)
      );
    }
    return Promise.all(promises).then(tileDataArray => {
      let transparencyData = {
        tileTransparency1: [],
        tileTransparency2: [],
        tileTransparency4: []
      };
      if (isIrregular) {
        transparencyData = {
          tileTransparency1: tileDataArray[3],
          tileTransparency2: tileDataArray[4],
          tileTransparency4: tileDataArray[5]
        };
      }
      return {
        colorTileData1: tileDataArray[0],
        colorTileData2: tileDataArray[1],
        colorTileData4: tileDataArray[2],
        ...transparencyData
      };
    });
  });
};
const fetchRoomDetails = ({ file }) => {
  let data = new FormData();
  data.append("action", "3droomdetails");
  data.append("key", getApiKey());
  data.append("file", file);
  return postHttpClient(data).then(data => ({ ...data, baseUrl: processPath(data.Dir) }));
};
const fetchRenderedDesignOrRoom = ({ backColor = "#00000000", file, props, view = "", at }) => {
  let data = new FormData();
  data.append("action", "rendering");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("view", view);
  data.append("backcolor", backColor);
  data.append("props", JSON.stringify(props));
  if (at) data.append("at", at);
  return postWithRetry(data).then(data => {
    return { ...data, Design: `${domain}${data.Design}`, View: `${domain}${data.View}` };
  });
};

const changeColorCombination = ({ numColors, colorTab, category }) => {
  let data = new FormData();
  data.append("action", "colorcombination");
  data.append("key", getApiKey());
  data.append("numcolors", numColors);
  data.append("tab", colorTab);
  data.append("category", category);
  return postHttpClient(data);
};
const publishDesign = ({ file, props, view = "", title, desc, mode = "", fullurl = "" }) => {
  var data = new FormData();
  data.append("action", "publishdesign");
  data.append("key", getApiKey());
  if (mode === "CYR") {
    data.append("mode", mode);
    data.append("fullurl", fullurl);
  }
  data.append("file", file);
  data.append("view", view);
  if (hasText(title)) data.append("title", title);
  if (hasText(desc)) data.append("desc", desc);
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(data => {
    return { ...data, CatalogCache: `${domain}/${data.CatalogCache}` };
  });
};
const eCatalogDetails = ({ catalogId }) => {
  var data = new FormData();
  data.append("action", "ecatalogdetails");
  data.append("catalogid", catalogId);
  data.append("key", getApiKey());
  return postHttpClient(data);
};
const calculatePriceOfRug = ({ props, file }) => {
  var data = new FormData();
  data.append("action", "price");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("materialsilk", window.flags.colorAreaSwatch.identifyMaterialSilk);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};
const fetchCustomFieldOrdersheet = () => {
  var data = new FormData();
  data.append("action", "customfields");
  data.append("key", getApiKey());
  return postHttpClient(data);
};
// const fetchOrderSheet = ({ file, props, customFields, materialNames = "", watermark, mode }) => {
//   const data = new FormData();
//   data.append("action", "ordersheet");
//   data.append("key", getApiKey());
//   data.append("file", file);
//   data.append("props", JSON.stringify(props));
//   data.append("view", "");
//   data.append("customfields", customFieldsToString(customFields));
//   data.append("materialnames", JSON.stringify(materialNames));
//   data.append("materialsilk", "");
//   if (watermark) data.append("watermark", watermark);
//   if (mode) data.append("mode", mode);
//   return postHttpClient(data).then(data =>
//     mode !== "postback" ? data : { ...data, propsid: decodeURIComponent(data.propsid) }
//   );
// };
const getPileNamesStr = pilenamesArr => {
  var str = "";
  if (pilenamesArr && pilenamesArr.length) {
    pilenamesArr.forEach(element => {
      str = str + element + "|";
    });
    str = str.substr(0, str.length - 1);
  }
  return str;
};
const fetchOrderSheet = ({
  file,
  props,
  customFields,
  materialNames = "",
  watermark,
  mode,
  uppercasing = false,
  date = "",
  zoom = 1,
  view = "",
  pilenames = "",
  pilevalues = "",
  rx = "",
  ry = "",
  sameColorDifferentSymbol = false
}) => {
  let PhysicalWidth = props.PhysicalWidth;
  let PhysicalHeight = props.PhysicalHeight;
  if (PhysicalWidth === undefined || PhysicalWidth === "") {
    PhysicalWidth = null;
  }
  if (PhysicalHeight === undefined || PhysicalHeight === "") {
    PhysicalHeight = null;
  }
  if (uppercasing) {
    file = file.toUpperCase();
  }
  if (PhysicalWidth && PhysicalHeight && props.Unit === "ft") {
    function roundOff12Inches(f) {
      var sizeVal = f;
      var ft = Math.floor(f);
      var inch = Math.round(12 * (f - ft));
      if (inch === 12) {
        ft++;
        inch = 0;
        sizeVal = ft;
      }
      return sizeVal;
    }
    PhysicalWidth = roundOff12Inches(PhysicalWidth);
    PhysicalHeight = roundOff12Inches(PhysicalHeight);
  }
  const designProps = { ...props, PhysicalWidth, PhysicalHeight };
  const data = new FormData();
  data.append("action", "ordersheet");
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("props", JSON.stringify(designProps));
  data.append("view", view);
  data.append("customfields", customFieldsToString(customFields));
  data.append("materialnames", JSON.stringify(materialNames));
  data.append("materialsilk", window.flags.colorAreaSwatch.identifyMaterialSilk);
  data.append("rx", rx);
  data.append("ry", ry);
  if (window.flags.accountSpecific.isPaulig) {
    data.append("paulig", 1);
  }
  if (pilenames && pilenames !== "") {
    pilenames = getPileNamesStr(pilenames);
    data.append("pilenames", pilenames);
  }
  if (pilevalues && pilevalues !== "") {
    pilevalues = getPileNamesStr(pilevalues);
    data.append("pilevalues", pilevalues);
  }
  //console.log("window.TextureOptions.DefaultPileheightNames", window.TextureOptions.DefaultPileheightNames)
  if (sameColorDifferentSymbol) data.append("sn", 1);

  data.append("zoom", zoom);
  if (date !== "") {
    data.append("dateformat", date);
  }
  if (watermark) data.append("watermark", watermark);
  if (mode) data.append("mode", mode);
  return postHttpClient(data).then(data =>
    mode !== "postback" ? data : { ...data, propsid: data.propsid }
  );
};

const fetchNewProps = ({ props }) => {
  let PhysicalWidth = props.PhysicalWidth;
  let PhysicalHeight = props.PhysicalHeight;
  if (PhysicalWidth === undefined || PhysicalWidth === "") {
    PhysicalWidth = null;
  }
  if (PhysicalHeight === undefined || PhysicalHeight === "") {
    PhysicalHeight = null;
  }
  if (PhysicalWidth && PhysicalHeight && props.Unit === "ft") {
    function roundOff12Inches(f) {
      var sizeVal = f;
      var ft = Math.floor(f);
      var inch = Math.round(12 * (f - ft));
      if (inch === 12) {
        ft++;
        inch = 0;
        sizeVal = ft;
      }
      return sizeVal;
    }
    PhysicalWidth = roundOff12Inches(PhysicalWidth);
    PhysicalHeight = roundOff12Inches(PhysicalHeight);
  }
  const designProps = { ...props, PhysicalWidth, PhysicalHeight };
  const data = new FormData();
  data.append("action", "propsid");
  data.append("key", getApiKey());
  data.append("props", JSON.stringify(designProps));
  return postHttpClient(data);
};

const downloadCatalog = ({ file, filename = "" }) => {
  var url = `${domain}/${provider}?action=downloadfile&key=${getApiKey()}&file=${file}${
    filename !== "" ? "&filename=" + filename.replace(/[^a-z0-9 .]/gi, "_") : ""
  }`;
  const link = document.createElement("a");
  link.href = url;
  link.click();
};
const saveFileLocally = ({ file, props }) => {
  const data = new FormData();
  data.append("action", "savefilelocally");
  data.append("key", getApiKey());
  data.append("file", file);
  if (props) data.append("props", JSON.stringify(props));
  HttpClient.post(`${domain}/${provider}`, data, {
    responseType: "blob"
  }).then(response => {
    let url = URL.createObjectURL(response.data);
    const link = document.createElement("a");

    link.href = url;
    const farr = file.split("/").filter(item => item && item !== "");
    link.download = farr[farr.length - 1];
    link.click();
  });
};
const sendOrdersheetEmail = ({
  file,
  props,
  name,
  email,
  phone = "",
  note = "",
  customFields = [],
  view = "",
  setCustomEmail,
  customerEmailBody,
  customerEmailSubject,
  customerEmailFromAddress,
  companyEmailBody,
  companyEmailSubject,
  companyEmailFromAddress,
  sameColorDifferentSymbol = false,
  pilenames = "",
  pilevalues = "",
  materialNames = "",
  emailCustomerOnly = 0
}) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("view", view);
  data.append("materialsilk", window.flags.colorAreaSwatch.identifyMaterialSilk);
  data.append("customfields", customFieldsToString(customFields));
  if (sameColorDifferentSymbol) data.append("sn", 1);
  if (pilenames && pilenames !== "") {
    pilenames = getPileNamesStr(pilenames);
    data.append("pilenames", pilenames);
  }
  if (pilevalues && pilevalues !== "") {
    pilevalues = getPileNamesStr(pilevalues);
    console.log("pilevalues", pilevalues);
    data.append("pilevalues", pilevalues);
  }
  if (materialNames) data.append("materialnames", JSON.stringify(materialNames));
  data.append("mode", "email"); // mode=postback for posting back to iframe
  data.append("name", name);
  data.append("email", email);
  data.append("phone", phone);
  data.append("note", note);
  if (emailCustomerOnly != 0) {
    data.append("EmailCustomerOnly", emailCustomerOnly);
  }
  //add template
  if (setCustomEmail) {
    if (hasText(companyEmailBody)) data.append("EmailBodyTemplate", companyEmailBody);
    if (hasText(companyEmailFromAddress)) data.append("EmailFromAddress", companyEmailFromAddress);
    if (hasText(companyEmailSubject)) data.append("EmailSubject", companyEmailSubject);
    if (hasText(customerEmailBody)) data.append("CustomerEmailBodyTemplate", customerEmailBody);
    if (hasText(customerEmailFromAddress))
      data.append("CustomerEmailFromAddress", customerEmailFromAddress);
    if (hasText(customerEmailSubject)) data.append("customerEmailSubject", customerEmailSubject);
  }
  return postHttpClient(data);
};
const hasText = variable => {
  if (variable && variable !== "") {
    return true;
  } else {
    return false;
  }
};
const getColorAt = ({ file, x, y, Width, Height }) => {
  const data = new FormData();
  data.append("action", "colorat");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("x", Math.round(x));
  data.append("y", Math.round(y));
  data.append("width", Width);
  data.append("height", Height);
  return postHttpClient(data);
};
const getCatalogThumb = id => {
  return `${domain}/templates/catalogtemplates/${id}.thumb.jpg`;
};
const getColorTexturePreviewImage = ({ color, colorIndex = 0, collectionName = "" }) => {
  // return value in css background format
  if (colorIndex > 3) colorIndex = 0;
  const colorCode = color.substring(1);
  const url = `${domain}/Assets/${cacheLocation}/colors/${colorCode}-${collectionName}-${colorIndex}.jpg`;
  if (cacheLocation !== "") return `url(${url})`;
  else return color;
};
const fetchCatalog = ({ file, props, catalogName, view = "" }) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("catalogname", catalogName);
  data.append("view", view);
  return postHttpClient(data).then(data => data.Value);
};
const sendUserIDToApp = ({ file, props, mode = "save", userid }) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  if (window.flags.visualizations.renderingTextureResize) {
    data.append("texturemode", "resize");
  }
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("view", "");
  data.append("mode", mode);
  data.append("userid", userid);
  return postHttpClient(data).then(data => data.Value);
};
const DESIGN = "design";
const ROOM = "room";
const fetchList = (listType = DESIGN) => {
  if (listType === DESIGN) return AppProvider.fetchDesignList({});
  else if (listType === ROOM) return AppProvider.fetchRoomList({});
};
const getCustomConstructions = () => {
  const data = new FormData();
  data.append("key", getApiKey());
  data.append("action", "customconstructions");
  return postHttpClient(data).then(data => {
    if (data) return data.map(item => ({ ...item, Num: parseInt(item.Num) }));
    else return [];
  });

  // data ? data.map(item => ({ ...item, Num: parseInt(item.Num) })) : [])
};

const uploadMyRoom = roomImage => {
  const data = new FormData();
  data.append("key", getApiKey());
  data.append("action", "uploadmyroom");
  data.append("myroom", roomImage);
  return postHttpClient(data);
};
const uploadMyRoomMask = ({ maskUrl, roomId }) => {
  const data = new FormData();
  data.append("key", getApiKey());
  data.append("action", "uploadmyroommask");
  data.append("myroommask", maskUrl);
  data.append("roomid", roomId);
  return postHttpClient(data);
};
const getRoomMask = image => {
  const data = new FormData();
  data.append("image", image);
  return new Promise((resolve, reject) => {
    return HttpClient.post(myroomServerUrl, data)
      .then(response => {
        if (response.status === 201) resolve(response.data);
        else {
          reject(response.data);
        }
      })
      .catch(error => {
        postErrorReport(error, data, true);
        reject(error);
      });
  });
};
const uploadRoomviewBlob = ({ blob, filename = "" }) => {
  const data = new FormData();
  data.append("file", blob);
  data.append("filename", filename);
  data.append("mode", "roomview");
  return new Promise((resolve, reject) => {
    return HttpClient.post(s3ServerRoomViewBucketUrl, data)
      .then(response => {
        if (response.status === 200) resolve(response.data);
        else {
          reject(response.data);
        }
      })
      .catch(error => {
        postErrorReport(error, data, true);
        reject(error);
      });
  });
};

const getProcessedRoomMask = ({
  roomId,
  file,
  props,
  floorpoints,
  notfloorpoints,
  carpetpoints
}) => {
  const data = new FormData();
  data.append("key", getApiKey());
  data.append("action", "processmyroom");
  data.append("mode", "mask");
  data.append("roomid", roomId);
  data.append("file", file);
  data.append("props", JSON.stringify(props));

  data.append("floorpoints", JSON.stringify(floorpoints));
  data.append("notfloorpoints", JSON.stringify(notfloorpoints));
  data.append("carpetpoints", JSON.stringify(carpetpoints));

  return postHttpClient(data, { responseType: "blob" });
};

const saveAsRoom = ({ mode, roomId, file, props, floorpoints, notfloorpoints, carpetpoints }) => {
  const data = new FormData();
  data.append("key", getApiKey());
  data.append("action", "processmyroom");
  data.append("mode", mode);
  data.append("roomid", roomId);
  data.append("file", file);
  data.append("props", JSON.stringify(props));

  data.append("floorpoints", JSON.stringify(floorpoints));
  data.append("notfloorpoints", JSON.stringify(notfloorpoints));
  data.append("carpetpoints", JSON.stringify(carpetpoints));

  return postHttpClient(data);
};

const getDesignThumbnails = ({ designs }) => {
  const { customThumbPath, smartResizeOff = 0 } = window.flags.designListTree;

  const { thumbFromCDN = true, showThumbTexture = false } = window.flags;
  const fullpaths = designs.map(item => item.fullPath);

  let data = new FormData();
  data.append("action", "designthumbs");
  data.append("key", getApiKey());
  data.append("files", JSON.stringify(fullpaths));
  data.append("smartresizeoff", smartResizeOff);
  if (showThumbTexture) data.append("texture", 1);
  return postWithRetry(data).then(thumbList => {
    return designs.map(childFile => {
      if (typeof thumbList === "string") {
        console.log("returnpostWithRetry -> thumbList", thumbList);
      }
      const item =
        typeof thumbList === "object"
          ? thumbList.find(item => item.Name === childFile.fullPath)
          : null;
      let add = {};
      if (item) {
        const hash = MD5(JSON.stringify(item.Props));
        const path = processPath(item.Thumb, thumbFromCDN);
        let thumbUrl = `${path}?t=${hash}`;
        if (customThumbPath) {
          const spl = item.Name.split("/").slice(1);
          const p = spl.join("/");
          thumbUrl = `${customThumbPath}/${p}`;
        }
        add = { thumbUrl, designProps: { ...item.Props, outline: item.Outline } };
        cacheLocation = getCacheLocationFromUrl(item.Thumb);
      }
      return { ...childFile, ...add };
    });
  });
};
const getRoomThumbnails = ({ rooms }) => {
  const fullpaths = rooms.map(item => item.FullPath);
  let data = new FormData();
  data.append("action", "roomthumbs");
  data.append("key", getApiKey());
  data.append("files", JSON.stringify(fullpaths));
  return postWithRetry(data).then(thumbList => {
    return rooms.map(room => {
      const item = thumbList.find(item => item.Name === room.FullPath);
      let add = { label: room.Name };
      if (item) {
        const path = processPath(item.Thumb, window.flags.thumbFromCDN);
        let thumbUrl = `${path}`;
        add = { ...add, Thumb: thumbUrl, id: uuid.v4() };
        cacheLocation = getCacheLocationFromUrl(item.Thumb);
      }
      return { ...room, ...add };
    });
  });
};
const fetch1xDesign = ({ file, props }) => {
  let data = new FormData();
  data.append("action", "rendereddesign");
  data.append("key", getApiKey());
  data.append("file", file);
  if (props) data.append("props", JSON.stringify(props));
  return postWithRetry(data, 3).then(processPath);
};

const fetchRenderedDesignWithZoom = async ({ file, zoom, props }) => {
  let data = new FormData();
  data.append("action", "rendereddesign");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("zoom", zoom);
  if (props) data.append("props", JSON.stringify(props));
  return postWithRetry(data, 3).then(processPath);
};
const get1xDesign = async ({ file }) => {
  // console.log("get1xDesign -> file", file);
  return new Promise((resolve, reject) => {
    fetch1xDesign({ file }).then(design => {
      // console.log("fetch1xDesign -> design", design);
      resolve(design);
    });
  });
};
const getRenderedDesign = async ({
  designDetails,
  fullpath,
  hash,
  zoom = 1,
  felt = 0,
  watermarkOptions = {},
  applyKLRatio = true
}) => {
  const tileSize = 256;
  return new Promise((resolve, reject) => {
    let { Width, Height, KLRatio } = designDetails;
    const canvasWidth = Width * zoom;
    const canvasHeight = Height * zoom;
    if (!applyKLRatio) KLRatio = 1;
    const canvas = createCanvas(canvasWidth, canvasHeight * KLRatio);

    let xTotal = Math.floor((canvasWidth - 1) / 256) + 1;
    let yTotal = Math.floor((canvasHeight - 1) / 256) + 1;
    let tilepoints = [];
    for (let x = 0; x < xTotal; x++) {
      for (let y = 0; y < yTotal; y++) {
        tilepoints.push({ x, y, z: zoom, name: convertTilePointToName(x, y) });
      }
    }
    const context = canvas.getContext("2d");
    fetchVisualizationTiles({
      file: fullpath,
      zoom,
      felt,
      props: designDetails,
      tiles: tilepoints.map(item => item.name)
    }).then(basePath => {
      let tileImagesLoaded = 0;
      tilepoints.forEach((tilePoint, index) => {
        const img = document.createElement("img");
        img.setAttribute("crossOrigin", "Anonymous");
        const { name } = tilePoint;
        let filename = `${basePath}/${name}.rendered.jpg`;
        if (hash && hash !== "") {
          filename = `${filename}?t=${hash}`;
        }
        img.src = filename;
        tilePoint.image = img;
        img.onload = () => {
          drawSingleTileInDesignCanvas(index);
          if (tileImagesLoaded + 1 === tilepoints.length) {
            drawWaterMarkIfNeeded();
          }
          tileImagesLoaded++;
        };
      });
      const drawSingleTileInDesignCanvas = index => {
        const tilepoint = tilepoints[index];
        const startX = tilepoint.x * tileSize;
        const startY = tilepoint.y * tileSize * KLRatio;
        context.drawImage(
          tilepoint.image,
          startX,
          startY,
          tilepoint.image.width,
          tilepoint.image.height * KLRatio
        );
      };
    });

    function drawWaterMarkIfNeeded() {
      const { hasWatermark = false, logoUrl, width: watWid, opacity, position } = watermarkOptions;
      if (!hasWatermark || !logoUrl) {
        resolve(canvas);
        return;
      }
      readImage(logoUrl).then(logoImage => {
        const width = watWid * 3 * zoom;
        const height = (logoImage.height * width) / logoImage.width;

        let padding = 15;
        const padx = position[1] === 0.0 ? -padding : position[1] === 1.0 ? padding : 0;
        const pady = position[0] === 0.0 ? -padding : position[0] === 1.0 ? padding : 0;
        const startx = position[1] * (canvasWidth - width) - padx;
        const starty = position[0] * (canvasHeight - height) - pady;

        context.globalAlpha = opacity;
        context.drawImage(logoImage, startx, starty, width, height);
        resolve(canvas);
      });
    }
  });
};
const readTextureImages = textures => {
  // const url = `${domain}/Assets/${cacheLocation}/colors/${colorCode}-${collectionName}-${colorIndex}.jpg`;
  const textureImages = textures.map(
    item => `https://assets.explorug.com/${cacheLocation}/textures/${item}.jpg`
  );
  let promises = [];
  textureImages.forEach((textureUrl, index) => {
    promises.push(readImage(textureUrl).then(image => ({ image, index: textures[index] })));
  });
  return Promise.all(promises);
};
const getStripedDesign = () => {
  let data = new FormData();
  data.append("action", "getstripedesign");
  data.append("key", getApiKey());
  return postHttpClient(data).then(data => `Designs/${data}`);
};
const saveOnServer = ({ file, name, props }) => {
  let data = new FormData();
  data.append("action", "saveonserver");
  data.append("key", getApiKey());
  data.append("file", file);
  if (name) {
    data.append("name", name);
    data.append("copy", true);
  }
  data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(data => `Designs/${data}`);
};
const clearCache = ({ mode, files }) => {
  if (!mode) console.warn("CLEAR CACHE MODE NOT SPECIFIED");
  let data = new FormData();
  data.append("action", "clearcache");
  data.append("key", getApiKey());
  data.append("mode", mode);
  if (files) data.append("files", JSON.stringify(files));
  return postHttpClient(data);
};
const isStripedDesignMode = sessionStorage.getItem("mode") === "stripedesign";
const isEcatMode = sessionStorage.getItem("mode") === "ecat";

const getInstanceUrl = ({ file, props, view = "" }) => {
  var data = new FormData();
  data.append("action", "instanceurl");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("view", view);
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};
const openFileManager = () => {
  window.localStorage.setItem("key", getApiKey());
  window.localStorage.setItem("isjpeg", window.InterfaceElements.IsJpeg);
  const x = window.location.pathname.split("/").filter(item => item !== "");
  x.pop();
  const urlArr = [window.location.origin, ...x, "filemanager/index.html"];
  const urlStr = urlArr.join("/");
  window.open(urlStr);
};
const mapColors = ({ props, colorTab }) => {
  var data = new FormData();
  data.append("action", "mapcolors");
  data.append("key", getApiKey());
  data.append("tab", colorTab);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};
const mapColorsList = ({ pipeSeparatedColorsList, colorTab }) => {
  var data = new FormData();
  data.append("action", "mapcolorlist");
  data.append("key", getApiKey());
  data.append("tab", colorTab);
  data.append("colors", pipeSeparatedColorsList);
  return postHttpClient(data);
};
const fetchWordCloud = () => {
  var data = new FormData();
  data.append("action", "designtags");
  data.append("key", getApiKey());
  return postHttpClient(data);
};
const postToVirtualExhibiton = (data, config, sendErrorReport = true) => {
  return new Promise((resolve, reject) => {
    HttpClient.post(`https://ramrodesigns.com/virtual-exibition/designpurchase.php`, data, config)
      .then(response => resolve(response.data))
      .catch(error => {
        if (sendErrorReport) postErrorReport(error, data);
        reject(error);
      });
  });
};
const checkBuyAvailable = ({ name, key, client, status }) => {
  var data = new FormData();
  //data.append("action", "mapcolors");
  data.append("name", name);
  data.append("key", key);
  data.append("client", client);
  data.append("status", status);
  return postToVirtualExhibiton(data);
};
// const createCorsUrl = (url, removeBgForCustomDesign = 0) => {
//   return `${domain}/${provider}?action=downloadimage&key=${getApiKey()}&url=${encodeURIComponent(
//     url
//   )}&removebg=${removeBgForCustomDesign ? 1 : 0}`;

const createCorsUrl = async (
  url,
  removeBgForCustomDesign = 0,
  createBlobUrl = true,
  checkBlobMemory = true
) => {
  if (createBlobUrl) {
    let blobUrl = null;
    if (checkBlobMemory) {
      blobUrl = blobMemory[url];
    }
    if (!blobUrl) {
      let blob = await fetch(
        `${domain}/${provider}?action=downloadimage&key=${getApiKey()}&url=${encodeURIComponent(
          url
        )}&removebg=${removeBgForCustomDesign ? 1 : 0}`
      ).then(r => r.blob());
      blobUrl = URL.createObjectURL(blob);
      blobMemory[url] = blobUrl;
    }

    return blobUrl;
  } else {
    return `${domain}/${provider}?action=downloadimage&key=${getApiKey()}&url=${encodeURIComponent(
      url
    )}&removebg=${removeBgForCustomDesign ? 1 : 0}`;
  }
};

const createCorsUrlJSON = async (
  url,
  removeBgForCustomDesign = 0,
  createNewCustomUrl = true,
  checkCustomUrlMemory = true
) => {
  const inMemoryData = customDesignUrlMemory[url];

  if (!createNewCustomUrl && checkCustomUrlMemory && inMemoryData) {
    return inMemoryData;
  }

  var data = new FormData();
  data.append("action", "downloadimagejson");
  data.append("key", getApiKey());
  data.append("url", url);
  data.append("removebg", removeBgForCustomDesign ? 1 : 0);

  return postHttpClient(data).then(customUrlData => {
    const customUrlObj = {
      Image: CDN_Images_Domain + customUrlData.Image,
      Outline: customUrlData.Outline
    };
    customDesignUrlMemory[url] = customUrlObj;
    return customUrlObj;
  });
};

const getArQr = ({ file, props }) => {
  var data = new FormData(); //action=arqr&file=...&props=...&key=...
  data.append("action", "arqr");
  data.append("key", getApiKey());
  data.append("file", file);
  data.append("zoom", 2);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};

const AppProvider = {
  fetchApiKey,
  fetchPdfForRugStudio,
  sendEmailMyRugStudio,
  fetchDesignList,
  fetchCustomSizedDesign,
  fetchColorList,
  createColorPoms,
  fetchRoomList,
  fetchRoomDetails,
  fetchRenderedDesignOrRoom,
  publishDesign,
  isAuthenticated,
  fetchInterfaceElements,
  domain,
  assetsDomain,
  autoLogin,
  changeColorCombination,
  calculatePriceOfRug,
  fetchCustomFieldOrdersheet,
  fetchOrderSheet,
  fetchNewProps,
  downloadCatalog,
  sendOrdersheetEmail,
  getColorAt,
  eCatalogDetails,
  getCatalogThumb,
  fetchCatalog,
  sendUserIDToApp,
  getColorTexturePreviewImage,
  fetchList,
  getCustomConstructions,
  uploadMyRoom,
  uploadMyRoomMask,
  getRoomMask,
  getProcessedRoomMask,
  saveAsRoom,
  fetchTileDetails,
  fetchDesignTiles,
  fetchPileTiles,
  getDesignThumbnails,
  getRoomThumbnails,
  fetchVisualizationTiles,
  getStripedDesign,
  getRenderedDesign,
  get1xDesign,
  fetchRenderedDesignWithZoom,
  saveOnServer,
  isStripedDesignMode,
  readTextureImages,
  isEcatMode,
  clearCache,
  getInstanceUrl,
  openFileManager,
  mapColors,
  mapColorsList,
  saveFileLocally,
  checkBuyAvailable,
  createCorsUrl,
  createCorsUrlJSON,
  fetchWordCloud,
  uploadRoomviewBlob,
  getArQr
};
export default AppProvider;
const customFieldsToString = fields => {
  let customFieldsString = "";
  fields.forEach(field => {
    customFieldsString = customFieldsString.concat(`${field.fieldName}|${field.fieldValue}|`);
  });
  return customFieldsString;
};
// const partial = function (len, x, _end) {
//   if (len === 32) { return x; }
//   return (_end ? x | 0 : x << (32 - len)) + len * 0x10000000000;
// }
// const toBits = function (bytes) {
//   var out = [], i, tmp = 0;
//   for (i = 0; i < bytes.length; i++) {
//     tmp = tmp << 8 | bytes[i];
//     if ((i & 3) === 3) {
//       out.push(tmp);
//       tmp = 0;
//     }
//   }
//   if (i & 3) {
//     out.push(partial(8 * (i & 3), tmp));
//   }
//   return out;
// }

//colorhex-tabName-materialIndex
//tabname==null?""
